import { graphql } from 'react-relay'

export const assignBeds = graphql`
  mutation workarea_assignBeds_Mutation($exchangeNames: [String!]!) {
    workAreaMutation {
      assignBeds(exchangeNames: $exchangeNames) {
        id
      }
    }
  }
`
