import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ConfirmDialog } from '../../components/common/ConfirmDialog'
import { hideConfirmLogout } from '../../ducks/views'

const mapStateToProps = state => ({
  showingConfirmLogout: state.viewsAppState.showingConfirmLogout,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      hideConfirmLogout,
    },
    dispatch
  ),
})

@connect(
  mapStateToProps,
  mapDispatchToProps
)
export class ConfirmLogout extends Component {
  static propTypes = {
    showingConfirmLogout: PropTypes.bool,
    actions: PropTypes.shape({
      hideConfirmLogout: PropTypes.func,
    }),
  }

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func,
      }),
    }),
  }

  logout = () => {
    this.context.router.history.push('/logout')
  }

  render() {
    const {
      showingConfirmLogout,
      actions: { hideConfirmLogout },
    } = this.props

    return (
      <ConfirmDialog
        open={showingConfirmLogout}
        title="Confirm Logout"
        message="Are you sure you want to sign out of Care Advance?"
        confirmText="Logout"
        cancelText="Cancel"
        onCancelClick={hideConfirmLogout}
        onConfirmClick={this.logout}
      />
    )
  }
}
