/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PlanListFilterQueryVariables = {||};
export type PlanListFilterQueryResponse = {|
  +viewer: {|
    +workspace: {|
      +admits: {|
        +notSet: number,
        +noAdmission: number,
        +toBeAdmitted: number,
        +total: number,
      |},
      +barriers: {|
        +total: number,
        +barriers: $ReadOnlyArray<{|
          +code: string,
          +value: string,
        |}>,
      |},
      +discharges: {|
        +anticipated: number,
        +dischargeOrderWithDelay: number,
        +notSet: number,
        +pending: number,
        +ready: number,
        +recent: number,
        +total: number,
      |},
      +losReview: {|
        +decisionDue: number,
        +currentOE: number,
        +forecastOE: number,
        +noTargetLengthOfStay: number,
        +pastPlannedDischarge: number,
        +total: number,
      |},
      +patients: {|
        +available: number,
        +census: number,
        +observationOrIn: number,
        +observationOrOut: number,
        +reserved: number,
        +transfersOut: number,
        +total: number,
      |},
      +attention: {|
        +readmission: number,
        +readmissionRisk: number,
        +total: number,
      |},
      +progression: {|
        +notSet: number,
        +onTime: number,
        +atRisk: number,
        +highRisk: number,
        +total: number,
      |},
    |}
  |}
|};
export type PlanListFilterQuery = {|
  variables: PlanListFilterQueryVariables,
  response: PlanListFilterQueryResponse,
|};
*/


/*
query PlanListFilterQuery {
  viewer {
    workspace {
      admits {
        notSet
        noAdmission
        toBeAdmitted
        total
      }
      barriers {
        total
        barriers {
          code
          value
        }
      }
      discharges {
        anticipated
        dischargeOrderWithDelay
        notSet
        pending
        ready
        recent
        total
      }
      losReview {
        decisionDue
        currentOE
        forecastOE
        noTargetLengthOfStay
        pastPlannedDischarge
        total
      }
      patients {
        available
        census
        observationOrIn
        observationOrOut
        reserved
        transfersOut
        total
      }
      attention {
        readmission
        readmissionRisk
        total
      }
      progression {
        notSet
        onTime
        atRisk
        highRisk
        total
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notSet",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Workspace",
        "kind": "LinkedField",
        "name": "workspace",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdmitsMeta",
            "kind": "LinkedField",
            "name": "admits",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noAdmission",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "toBeAdmitted",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BarriersMeta",
            "kind": "LinkedField",
            "name": "barriers",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierMeta",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DischargesMeta",
            "kind": "LinkedField",
            "name": "discharges",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "anticipated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dischargeOrderWithDelay",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pending",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ready",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recent",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LOSReviewMeta",
            "kind": "LinkedField",
            "name": "losReview",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "decisionDue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentOE",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forecastOE",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "noTargetLengthOfStay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pastPlannedDischarge",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientsMeta",
            "kind": "LinkedField",
            "name": "patients",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "available",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "census",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "observationOrIn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "observationOrOut",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reserved",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transfersOut",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AttentionMeta",
            "kind": "LinkedField",
            "name": "attention",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readmission",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "readmissionRisk",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProgressionMeta",
            "kind": "LinkedField",
            "name": "progression",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "onTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "atRisk",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highRisk",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlanListFilterQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PlanListFilterQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "20a5abf0718698aabbb0de9ed8bbdfba",
    "id": null,
    "metadata": {},
    "name": "PlanListFilterQuery",
    "operationKind": "query",
    "text": "query PlanListFilterQuery {\n  viewer {\n    workspace {\n      admits {\n        notSet\n        noAdmission\n        toBeAdmitted\n        total\n      }\n      barriers {\n        total\n        barriers {\n          code\n          value\n        }\n      }\n      discharges {\n        anticipated\n        dischargeOrderWithDelay\n        notSet\n        pending\n        ready\n        recent\n        total\n      }\n      losReview {\n        decisionDue\n        currentOE\n        forecastOE\n        noTargetLengthOfStay\n        pastPlannedDischarge\n        total\n      }\n      patients {\n        available\n        census\n        observationOrIn\n        observationOrOut\n        reserved\n        transfersOut\n        total\n      }\n      attention {\n        readmission\n        readmissionRisk\n        total\n      }\n      progression {\n        notSet\n        onTime\n        atRisk\n        highRisk\n        total\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1a35565afafe2af9e158693cc54f0ba3';

module.exports = node;
