/* eslint-disable no-fallthrough */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { NameFormat } from '../common'
import { BedUnitLink } from '../common'
import { CodeImage } from '../image/CodeImage'
import { CountdownTimer } from '../date'
import { ProgressionIssue } from './'
import { getPlannedLabel, hasEstimatedTransferDateIssue, getTransferLabelAcronym } from '../../util/configs'
import CLDatePicker from '../plan/CLDatePicker'

import './compliance.scss'

import classNames from 'classnames'
import DatePickerButton from '../plan/DatePickerButton'
import ClockIcon from "mdi-react/ClockIcon";

export const Compliance = (
  {
    item,
    onViewPlanClick,
    onSetPlan,
    onSetDRG,
    onSetWDD,
    onSetPDC,
    onProgressionIndicatorChange,
    index,
  },
  context
) => {
  const c3Classes = context.muiTheme.c3Classes

  const { dept, bed, visit, patient, compliance } = item
  const inpatient = visit.admissionType === 'InPatient'

  let planDischargeIssue = makePlannedDCIssue(bed, compliance, onSetPDC, item)

  const drg = visit.drgAttributes
  const psText =
    item.progressionIndicator === 'OnTime'
      ? 'On Time'
      : item.progressionIndicator === 'AtRisk'
      ? 'At Risk'
      : item.progressionIndicator === 'HighRisk'
      ? 'High Risk'
      : 'Not Set'

  const overtime =
    visit.currentDayOfStay && visit.currentDayOfStay >= visit.tlos

  const noPlanIssue = compliance.noPlan
    ? makeComplianceIssue('No Plan', compliance.noPlanIssue, onSetPlan, item)
    : makeInComplianceIssue(
        makePlanTemplateNames(item.appliedTemplates, compliance.noPlan)
      )

  const noDRGIssue = compliance.noDRG
    ? makeComplianceIssue('No DRG', compliance.noDRGIssue, onSetDRG, item)
    : drg
    ? makeInComplianceIssue(`${drg.code} ${drg.level}`)
    : null

  const noWDDIssue = compliance.noWDD
    ? makeComplianceIssue(
        'No D/C Arrangements',
        compliance.noWDDIssue,
        onSetWDD,
        item
      )
    : makeInComplianceIssue(visit.workingDischargeDisposition.value)

  const noPSIssue = (
    <ProgressionIssue
      issue={compliance.noPSIssue}
      value={psText}
      onChange={newValue => onProgressionIndicatorChange(item.id, newValue)}
    />
  )

  const onClick = (bedId, planId, visitId) => {
    onViewPlanClick(bedId, planId, visitId, index)
  }

  const currentTime = moment()
  let displayFormat = `[${getTransferLabelAcronym()}: ]MMM D`
  if(!visit.anticipatedTransferTimeAutoPopulated){
    displayFormat += ` HH:mm`
  }
  const estimatedTransferTime = visit.anticipatedTransferTime
    ? moment(visit.anticipatedTransferTime).format(displayFormat)
    : `No ${getTransferLabelAcronym()}`

  return (
    <div className="paper compliance-item">
      <div className={classNames('row', 'line', 'primary-patient-info-line')}>
        <div className={classNames('patient-info')}>
          <BedUnitLink
            bed={bed}
            unit={dept.name}
            planId={item.id}
            visitId={visit.id}
            onClick={onClick}
            extraClasses="col-xs-4"
          />
        </div>
        <div className={classNames('col-xs-5', 'patient-info')}>
          <span>
            <span className={classNames(c3Classes.attrLeft)}>
              <CodeImage type="AdmissionType" code={visit.admissionType} />
            </span>
            <span className={classNames(c3Classes.spacerLeft)}>
              <CodeImage type="Gender" code={patient.gender || 'nil'} />
            </span>
            <span className={classNames(c3Classes.attrLeft)}>
              {patient.age}&nbsp;
            </span>
            <span className={classNames(c3Classes.attrLeft)}>
              <NameFormat person={patient} />
            </span>
          </span>
        </div>
        <div className={classNames('col-xs-3 ', 'patient-info')}>
          {inpatient && visit.currentDayOfStay && (
            <span>
              <span className={classNames({ overtime: overtime })}>
                Day {visit.currentDayOfStay} of {visit.tlos || '?'}
              </span>
            </span>
          )}
          {inpatient && !visit.currentDayOfStay && <span>Not Admitted</span>}
          {!inpatient && visit.decisionTime && (
            <span>
              Decision Due: <CountdownTimer date={visit.decisionTime} />
            </span>
          )}
          {!inpatient && !visit.decisionTime && (
            <span className="decision-time-not-set">Decision Time Not Set</span>
          )}
          <span style={{position: 'absolute'}} className={classNames('col-xs-3', {
              pastTransfer: hasEstimatedTransferDateIssue(visit)
            })}>
            {estimatedTransferTime}
          </span>
        </div>
      </div>
      <div className={classNames('row', 'line', 'secondary-patient-info-line')}>
        <span className={classNames('col-xs-12', 'patient-problem')}>
          {visit.visitNumber} | {patient.mrn} | {chooseProblem(visit)}
        </span>
      </div>
      <div className={classNames('row', 'line', 'primary-compliance-line')}>
        <div className={classNames('col-xs-12', 'compliance-info')}>
          {noPlanIssue}
          {noDRGIssue}
          {noWDDIssue}
          {noPSIssue}
          {planDischargeIssue}
        </div>
      </div>
    </div>
  )
}

Compliance.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
}

Compliance.propTypes = {
  item: PropTypes.object,
  onViewPlanClick: PropTypes.func,
  onSetPlan: PropTypes.func,
  onSetDRG: PropTypes.func,
  onSetWDD: PropTypes.func,
  onSetPDC: PropTypes.func,
  onProgressionIndicatorChange: PropTypes.func,
  index: PropTypes.number,
}

/*
  Helper Functions
*/

function makePlannedDCIssue(bed, compliance, onSetPDC, item) {
  const isDisabled =
    !compliance.planDischargeIssue ||
    bed.status === 'Reserved' ||
    bed.status === 'Swapped'
  const hintText = getPlannedLabel() + ' Discharge'
  const { visit } = item

  const handleConfirmRemove = () => {
    onSetPDC(null, visit.id, false)
  }

  const systemSetDischargeTime = visit.anticipatedDischargeTime && !visit.anticipatedDischargeUpdatedBy
  const dischargeDateInPast = visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).isBefore( visit.anticipatedDischargeTimeAutoPopulated ? moment().startOf('day') : moment() )
  const dischargeDateWithinTwoDays = visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).subtract(2, 'd').startOf('day').isBefore(moment())

  return (
    <div style={{ display: 'inline-block' }}>
      {!dischargeDateInPast && dischargeDateWithinTwoDays && <ClockIcon className="clockIconSmall"/>}
      <div
        className={classNames({ complianceIssue: compliance.planDischargeIssue })}
        style={{ display: 'inline-block' }}>
        {/*{!dischargeDateInPast && dischargeDateWithinTwoDays && <ClockIcon className="clockIcon"/>}*/}
        <CLDatePicker
          disabled={isDisabled}
          //forces the component to rerender when the date is removed
          key={"Compliance:EDD:"+visit.id+":"+visit.anticipatedDischargeTime}
          onSetPDC={onSetPDC}
          visit={visit}
          timeAutoPopulated={visit.anticipatedDischargeTimeAutoPopulated}
          dateTime={visit.anticipatedDischargeTime}
          id={visit.id}
          timeAutoPopulatedByMinutes={0}
          dateOnlySelectionType='midnight'
          customInput={
            <DatePickerButton
              noChange={isDisabled}
              timeAutoPopulated={visit.anticipatedDischargeTimeAutoPopulated}
              dateTime={visit.anticipatedDischargeTime}
              handleConfirmRemove={handleConfirmRemove}
              label={getPlannedLabel() + ' D/C'}
              dateUpdatedBy={systemSetDischargeTime ? ' (External System)' : ''}
            />
          }
        />
      </div>
    </div>

  )
}

function makeComplianceIssue(text, issue, onClick, item) {
  return (
    <div
      className={classNames('compliance', issue ? 'compliance-issue' : null)}
      onClick={() => onClick(item)}>
      {text}
    </div>
  )
}

function makeInComplianceIssue(text) {
  return <div className="compliance">{text}</div>
}

function makePlanTemplateNames(appliedTemplates, noPlan) {
  let activeTemplates = appliedTemplates.filter(template => !template.removed)
  // todo I think the new way workspaces are merged causes the need to do ...
  return activeTemplates.length > 0
    ? activeTemplates[0].name + (activeTemplates.length > 1 ? '...' : '')
    : noPlan
    ? 'No Plan'
    : '...'
}

function chooseProblem(visit) {
  //Todo: order should be PatientProblem > DRG Description > Admitting Complaint
  return (
    visit.patientProblem ||
    (visit.drgAttributes && visit.drgAttributes.title) ||
    visit.admittingComplaint
  )
}
