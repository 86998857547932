import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Drawer from 'material-ui/Drawer'
import FlatButton from 'material-ui/FlatButton'
import { Grid } from '@material-ui/core'

import DueDateFilter from './DueDateFilter'
import TrackFilter from './TrackFilter'
import StatusFilter from './StatusFilter'

class TodoFilterDrawer extends Component {
  closeDrawer = () => {
    this.props.applyDraftFilter({
      ...this.props.filter
    })

    this.props.handlerDrawerToggle()
  }

  resetFilter = () => {
    this.props.resetFilter()
    this.props.handlerDrawerToggle()
  }

  applyFilter = () => {
    this.props.applyFilter(this.props.draftFilter)
    this.props.handlerDrawerToggle()
  }

  handleDueDateChange = event => {
    const dueDateVal = event.target.value

    this.props.applyDraftFilter({
      ...this.props.draftFilter,
      dueDate: dueDateVal
    })
  }

  handleTrackChange = event => {
    const track = event.target.value

    this.props.applyDraftFilter({
      ...this.props.draftFilter,
      track: track
    })
  }

  handleStatusChange = event => {
    const status = event.target.value

    this.props.applyDraftFilter({
      ...this.props.draftFilter,
      status: status
    })
  }

  render () {
    const {
      open,
      handlerDrawerToggle,
      draftFilter
    } = this.props

    return (
      <Drawer
        containerClassName="filter-drawer"
        width={window.innerWidth}
        docked
        swipeAreaWidth={0}
        open={open}
        onRequestChange={handlerDrawerToggle}>
        <div style={{width: '100%'}}>
          <div className="filter-header">
            <FlatButton
              className="drawer-button"
              label="CANCEL"
              primary
              onClick={this.closeDrawer}
            />
            <FlatButton
              className="drawer-button"
              label="RESET"
              primary
              onClick={this.resetFilter}
            />
            <FlatButton
              className="drawer-button"
              label="APPLY"
              primary
              onClick={this.applyFilter}
            />
          </div>
          <div className="filter-body">
            <Grid container spacing={8}>
              <Grid item xs={4}>
                <DueDateFilter
                  onChange={this.handleDueDateChange}
                  selected={draftFilter.dueDate}/>
              </Grid>

              <Grid item xs={4}>
                <TrackFilter
                  onChange={this.handleTrackChange}
                  selected={draftFilter.track}/>
              </Grid>

              <Grid item xs={4}>
                <StatusFilter
                  onChange={this.handleStatusChange}
                  selected={draftFilter.status}/>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    )
  }
}

TodoFilterDrawer.propTypes = {
  applyDraftFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  draftFilter: PropTypes.object.isRequired,
  handlerDrawerToggle: PropTypes.func.isRequired,
  open: PropTypes.bool
}

export default TodoFilterDrawer
