import { graphql } from 'react-relay'
import cloneDeep from 'lodash.clonedeep'

export const updateBarrierStatus = graphql`
  mutation barrier_updateBarrierStatus_Mutation(
    $barrierId: UUID!
    $status: BarrierStatus!
    $bedRequestStatus: BedRequestStatus
  ) {
    barrierMutation {
      updateBarrierStatus(barrierId: $barrierId, status: $status, bedRequestStatus: $bedRequestStatus) {
        ...barrier_FullBarrier
        @arguments(skipOwnerKnownUser: true)
      }
    }
  }
`

export const updateBarrierOwner = graphql`
  mutation barrier_updateBarrierOwner_Mutation(
    $barrierId: UUID!
    $ownerId: UUID
  ) {
    barrierMutation {
      updateBarrierOwner(barrierId: $barrierId, ownerId: $ownerId) {
        ...barrier_FullBarrier
      }
    }
  }
`

export const updateBarrierSourceType = graphql`
  mutation barrier_updateBarrierSourceType_Mutation(
    $barrierId: UUID!
    $sourceTypeCode: String
  ) {
    barrierMutation {
      updateBarrierSourceType(barrierId: $barrierId, sourceTypeCode: $sourceTypeCode) {
        ...barrier_FullBarrier
        @arguments(skipOwnerKnownUser: true)
      }
    }
  }
`

export const updateBarrierSourceName = graphql`
  mutation barrier_updateBarrierSourceName_Mutation(
    $barrierId: UUID!
    $sourceName: String
  ) {
    barrierMutation {
      updateBarrierSourceName(barrierId: $barrierId, sourceName: $sourceName) {
        ...barrier_FullBarrier
        @arguments(skipOwnerKnownUser: true)
      }
    }
  }
`

export const escalateBarrier = graphql`
  mutation barrier_escalateBarrier_Mutation($barrierId: UUID!) {
    barrierMutation {
      escalateBarrier(barrierId: $barrierId) {
        ...barrier_FullBarrier
        @arguments(skipOwnerKnownUser: true)
      }
    }
  }
`

export const deescalateBarrier = graphql`
  mutation barrier_deescalateBarrier_Mutation($barrierId: UUID!) {
    barrierMutation {
      deescalateBarrier(barrierId: $barrierId) {
        ...barrier_FullBarrier
        @arguments(skipOwnerKnownUser: true)
      }
    }
  }
`

export const pinBarrier = graphql`
  mutation barrier_pinBarrier_Mutation($itemId: UUID!) {
    pinMutation {
      pin(itemId: $itemId, itemType: Barrier) {
        id
        barriers {
          ...barrier_FullBarrier
          @arguments(skipOwnerKnownUser: true)
        }
      }
    }
  }
`

export const createBarrier = graphql`
  mutation barrier_createBarrier_Mutation(
    $planId: UUID!
    $barrier: BarrierInput!
    $note: String
    $ownerId: UUID
    $pinned: Boolean
    $escalated: Boolean
    $barrierSourceTypeCode: String
    $barrierSourceName: String
  ) {
    barrierMutation {
      createBarrier(
        planId: $planId,
        barrier: $barrier,
        note: $note,
        ownerId: $ownerId
        pinned: $pinned
        escalated: $escalated
        barrierSourceTypeCode: $barrierSourceTypeCode,
        barrierSourceName: $barrierSourceName
      ) {
        id
        barriers {
          ...barrier_FullBarrier
        }
      }
    }
  }
`

export const createBarrierFromTemplate = graphql`
  mutation barrier_createBarrierFromTemplate_Mutation(
    $planId: UUID!
    $templateId: UUID!
    $note: String
    $ownerId: UUID
    $pinned: Boolean
    $escalated: Boolean
    $barrierSourceTypeCode: String
    $barrierSourceName: String
    $bedRequestStatus: BedRequestStatus
  ) {
    barrierMutation {
      createBarrierFromTemplate(
        planId: $planId
        templateId: $templateId
        note: $note
        ownerId: $ownerId
        pinned: $pinned
        escalated: $escalated
        barrierSourceTypeCode: $barrierSourceTypeCode
        barrierSourceName: $barrierSourceName
        bedRequestStatus: $bedRequestStatus
      ) {
        id
        barriers {
          ...barrier_FullBarrier
        }
      }
    }
  }
`

const FullBarrier = graphql`
  fragment barrier_FullBarrier on Barrier
  @argumentDefinitions(skipOwnerKnownUser: {type: "Boolean!", defaultValue: false})
  {
    id
    name
    description
    status
    pinned
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
    category {
      code
      value
      sourceTypes {
        id
        code
        value
        active
        activeDirectorySourceName
        hasConfiguredList
        sourceDetails {
          id
          code
          value
          active
        }
      }
    }
    escalation {
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
      }
    }
    ownerMessageStatus
    ownerMessageStatusDate
    ownerKnownUser @skip(if: $skipOwnerKnownUser) {
      id
      emailReceiver
      instantMessageReceiver
    }
    ownerMessageDeliveryDate
    owner
    ownerRole {
      code
      value
    }
    sourceName
    sourceTypeCode
    notes {
      id
      text
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
      pinned
    }
    escalation {
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
      }
    }
  }
`

export const sendBarrierInfoMessage = graphql`
  mutation barrier_sendBarrierInfoMessage_Mutation (
    $barrierId: UUID!
    $recipientId: UUID!
    $messageNote: String
  ) {
    barrierMutation {
      sendBarrierInfoMessage(barrierId: $barrierId, recipientId: $recipientId, messageNote: $messageNote) {
        id
      }
    }
  }
`
export const barrierHistory = graphql`
  query barrier_barrierHistory_Query(
    $barrierId: ID!
    $onlySentBarrierInfo: Boolean
  ) {
    node(id: $barrierId) {
      ... on Barrier {
        id
        barrierHistory(onlySentBarrierInfo: $onlySentBarrierInfo) {
          id
          timeSent
          senderFirstName
          senderLastName
          recipientFirstName
          recipientLastName
          note
          requestDirection
          messageSubType
          deliveryMethod
        }
      }
    }
  }
`

export const barrierSourceTypes = graphql`
  query barrier_barrierSourceTypes_Query($barrierCategoryCode: String!) {
    barrierSourceTypes(barrierCategoryCode: $barrierCategoryCode) {
      id
      code
      value
      active
      activeDirectorySourceName
      hasConfiguredList
      sourceDetails {
        code
        value
        active
      }
    }
  }
`


export const addWatchers = graphql`
  mutation barrier_addWatchers_Mutation(
    $barrierId: UUID!,
    $userIds: [UUID!]!
  ) {
    barrierMutation {
      addWatchers(barrierId: $barrierId, userIds: $userIds) {
        id
        knownUser {
          id
          firstName
          lastName
          title
        }
      }
    }
  }
`

export const removeWatchers = graphql`
  mutation barrier_removeWatchers_Mutation(
    $barrierId: UUID!,
    $userIds: [UUID!]!
  ) {
    barrierMutation {
      removeWatchers(barrierId: $barrierId, userIds: $userIds) {
        id
        knownUser {
          id
          firstName
          lastName
          title
        }
      }
    }
  }
`

export const addOrRemoveWatcherUpdater = (mutationName, variables) => (proxyStore) => {
  const store = proxyStore.getRootField("barrierMutation")

  if (store){
    const payload = store.getLinkedRecords(mutationName, variables)

    const rootStore = proxyStore.get("client:root")
    const barrierNode = rootStore.getLinkedRecord('node', { id: `Barrier:${variables.barrierId}` })
    barrierNode.setLinkedRecords(payload, 'watchers')
  }
}

export const barrierOwnerOptimisticResponse = (barrier, owner, knownUser) => {
  const barrierCopy = cloneDeep(barrier)
  delete barrierCopy.__typename
  delete barrierCopy?.escalation?.auditMeta?.updated
  delete barrierCopy?.escalation?.auditMeta?.updatedBy

  let optimisticResponse

  if (owner) {
    optimisticResponse = {
      barrierMutation: {
        updateBarrierOwner: {
          ...barrierCopy,
          owner: owner,
          ownerMessageDeliveryDate: new Date().toISOString(),
          ownerMessageStatus: "UNACKNOWLEDGED",
          ownerMessageStatusDate: new Date().toISOString(),
          ownerKnownUser: {
            id: knownUser?.id,
            instantMessageReceiver: knownUser?.instantMessageReceiver,
            emailReceiver: knownUser?.emailReceiver
          },
          ownerRole: {
            value: knownUser?.title,
            code: knownUser?.title,
            id: `CodeTablePair:${knownUser?.title}`
          }
        }
      }
    }
  } else {
    optimisticResponse = {
      barrierMutation: {
        updateBarrierOwner: {
          ...barrierCopy,
          owner: null,
          ownerMessageDeliveryDate: null,
          ownerMessageStatus: null,
          ownerMessageStatusDate: null,
          ownerKnownUser: null,
          ownerRole: null
        }
      }
    }
  }

  return optimisticResponse
}

export const barrierSourceNameOptimisticResponse = (barrier, sourceName) => {
  const barrierCopy = { ...barrier }
  delete barrierCopy.__typename
  delete barrierCopy.ownerKnownUser

  return {
    barrierMutation: {
      updateBarrierSourceName: {
        ...barrierCopy,
        sourceName: sourceName
      }
    }
  }
}
