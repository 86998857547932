import { graphql } from 'react-relay'

export const addBoardTemplateEntry = graphql`
  mutation boardTemplate_addBoardTemplateEntry_Mutation($boardTemplateInput: BoardTemplateInput!) {
    boardTemplateMutation {
      addBoardTemplateEntry(boardTemplateInput: $boardTemplateInput) {
        ...BoardTemplateRow_rowData
      }
    }
  }
`

export const modifyBoardTemplateEntry = graphql`
  mutation boardTemplate_modifyBoardTemplateEntry_Mutation($boardTemplateId: UUID!, $boardTemplateInput: BoardTemplateInput!) {
    boardTemplateMutation {
      modifyBoardTemplateEntry(boardTemplateId: $boardTemplateId, boardTemplateInput: $boardTemplateInput) {
        ...BoardTemplateRow_rowData
      }
    }
  }
`

export const deleteBoardTemplateEntry = graphql`
  mutation boardTemplate_deleteBoardTemplateEntry_Mutation($boardTemplateId: UUID!) {
    boardTemplateMutation {
      deleteBoardTemplateEntry(boardTemplateId: $boardTemplateId) {
        ...BoardTemplateRowList_list
      }
    }
  }
`
