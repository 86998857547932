import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody } from 'material-ui/Table'

import { SeverityRow } from './SeverityRow'
import { EmptyMessageRow } from './EmptyMessageRow'

import { countSeverityFilters } from '../../containers/drg/drgFilter'

export const SeverityResults = ({ severities, searchFilters }) => {
  const rows = severities
    ? severities.map((severity, i) => (
        <SeverityRow
          key={i}
          severity={severity}
          searchTerm={searchFilters.searchTerm}
        />
      ))
    : []

  const emptyRowText =
    countSeverityFilters(searchFilters) > 1
      ? 'No Severity hints matching these filters'
      : 'Please use the search and filter options above to find the appropriate Severity'

  return (
    <Table height="100%">
      <TableBody>
        {rows.length ? rows : <EmptyMessageRow text={emptyRowText} />}
      </TableBody>
    </Table>
  )
}

SeverityResults.propTypes = {
  severities: PropTypes.array,
  searchFilters: PropTypes.shape({
    searchTerm: PropTypes.string,
    cc: PropTypes.bool,
    mcc: PropTypes.bool,
    dc: PropTypes.string,
  }).isRequired,
}
