import { AuthState } from './types'

import { baseAuthURL } from '../util/base-url'

export const authTokenKey = 'authToken'
export const refreshTokenKey = 'refreshToken'
const storageInterface = sessionStorage

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('ca.auth.auth-service')
let userInfo = {}

export function authenticateCredentials(
  username: string = '',
  password: string = ''
) {
  const computedBasicAuth = btoa(`${username.trim()}:${password.trim()}`)
  const params = {
    method: 'GET',
    headers: { Authorization: `Basic ${computedBasicAuth}` },
  }

  // Call server to authenticate
  return (
    fetch(`${baseAuthURL}/login`, params)
      // Extract Object from json response
      .then(r => r.json())
      // Handle response
      .then((response: AuthState) => {
        if (response.token) {
          // Check access permissions
          if (response.identity.assignedApplications.includes('C3_FULL')) {
            // Save tokens to sessionStorage
            storageInterface.setItem(authTokenKey, response.token)
            storageInterface.setItem(refreshTokenKey, response.refreshToken)

            userInfo = {
              firstName: response.identity.firstName,
              lastName: response.identity.lastName,
              userName: response.identity.userName,
            }

            // Return the response
            return response
          } else {
            // Not authorized to application
            logger.error('User is missing the C3_FULL permission', response)
            // Return error response
            throw {
              errors: [
                {
                  message:
                    'You have not been granted permission to run Care Advance. See your System Administrator.',
                },
              ],
            }
          }
        } else {
          logger.error(
            'Login response did not contain a token',
            response.message
          )
          // Return error response
          throw { errors: [{ message: response.message }] }
        }
      })
      .catch(error => {
        if (error.errors) {
          throw error
        } else {
          logger.error('Error During Login', error)
          // Return the errors
          throw { errors: [error] }
        }
      })
  )
}

export function refreshAuthenticationToken() {
  return retrieveRefreshToken().then(refreshToken => {
    const params = {
      method: 'GET',
      headers: { Authorization: `Bearer ${refreshToken}` },
    }

    // Call Server to refresh the token
    return (
      fetch(`${baseAuthURL}/token`, params)
        // Extract Object from json response
        .then(r => r.json())
        // Handle response
        .then((response: AuthState) => {
          if (response.token) {
            // Check access permissions
            if (response.identity.assignedApplications.includes('C3_FULL')) {
              // Save tokens to sessionStorage
              storageInterface.setItem(authTokenKey, response.token)
              storageInterface.setItem(refreshTokenKey, response.refreshToken)

              userInfo = {
                firstName: response.identity.firstName,
                lastName: response.identity.lastName,
                userName: response.identity.userName,
              }

              // Return the response
              return response
            } else {
              // User was authorized to use application, no is not any longer
              logger.error(
                'After Token refresh, user is now missing the C3_FULL permission, and will be logged out',
                response
              )

              // Return error response
              throw {
                errors: [
                  {
                    message:
                      'You have not been granted permission to run Care Advance. See your System Administrator.',
                  },
                ],
              }
            }
          } else {
            let userMessage = response.message
            if (userMessage.toLowerCase().includes('jwt expired')) {
              userMessage = 'Session Expired. Please log in again.'
            }
            // For some reason, the response didn't contain a token. Time to try again
            logger.error(
              'Token refresh response did not contain a token. User will be logged out.',
              response.message
            )

            // Return error response
            throw { errors: [{ message: response.message }] }
          }
        })
        .catch(error => {
          if (error.errors) {
            throw error
          } else {
            logger.error(
              'Error During Token Refresh. User will be logged out.',
              error
            )
            // Return the errors
            throw { errors: [error] }
          }
        })
    )
  })
}

export function destroyCredentials() {
  storageInterface.removeItem(authTokenKey)
  storageInterface.removeItem(refreshTokenKey)
  return Promise.resolve()
}

export function setRefreshToken(token) {
  storageInterface.setItem(refreshTokenKey, token)
}

export function retrieveAuthToken() {
  return Promise.resolve(storageInterface.getItem(authTokenKey))
}
export function retrieveRefreshToken() {
  return Promise.resolve(storageInterface.getItem(refreshTokenKey))
}

export const getUserInfo = () => {
  return userInfo
}
