import React from 'react'
import PropTypes from 'prop-types'

import './BarrierNotes.scss'

const BarrierNotes = ({ historyData }) => {

  const getNoteText = () => {
    let noteText
    if (historyData.note === 'RESOLVED_BY_USER' && historyData.messageSubType === 'BARRIER_RESOLVED') {
      noteText = `Barrier was resolved by ${historyData.senderFirstName} ${historyData.senderLastName}.`
    } else if (historyData.note === 'VISIT_DISCHARGED' && historyData.messageSubType === 'BARRIER_RESOLVED') {
      noteText = 'Barrier was resolved by the system due to patient discharge.'
    } else {
      noteText = historyData.note
    }
    return noteText
  }

  return historyData.note ? (
    <div className={'BarrierNotes'}>
      {getNoteText()}
    </div>
  ) : null
}

BarrierNotes.propTypes = {
  historyData: PropTypes.shape({
    note: PropTypes.string,
    messageSubType: PropTypes.string,
    senderFirstName: PropTypes.string,
    senderLastName: PropTypes.string
  }).isRequired
}

export default BarrierNotes
