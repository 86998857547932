import React from 'react'
import PropTypes from 'prop-types'
import { Highlighter } from './Highlighter'
import { TableRow, TableRowColumn } from 'material-ui/Table'
import FlatButton from 'material-ui/FlatButton'

export const DrgRow = ({
  rowNumber,
  drg,
  searchTerm,
  onSelectDrg,
  onShowSeverities,
  workingDischargeDispositionGroup
}) => {
  const handleShowSeverities = drg => {
    onShowSeverities(drg)
  }

  return (
    <TableRow style={{ paddingBottom: 5, paddingTop: 5 }}>
      <TableRowColumn style={{ width: 50, paddingLeft: 4, paddingRight: 4 }}>
        {drg.family.map(item => {
          return (
            <div key={item.code}>
              <FlatButton
                style={{
                  marginBottom: 2,
                  width: 45,
                  minWidth: 45,
                  maxWidth: 45,
                }}
                title="Click to select this DRG"
                label={item.code}
                labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
                onClick={e => onSelectDrg(item)}
              />
            </div>
          )
        })}
      </TableRowColumn>
      <TableRowColumn style={{ width: 60, paddingLeft: 4, paddingRight: 4 }}>
        {drg.family.map(item => {
          return (
            <div key={item.code}>
              <FlatButton
                style={{
                  marginBottom: 2,
                  width: 55,
                  minWidth: 55,
                  maxWidth: 55,
                }}
                title={
                  item.level !== 'BASE'
                    ? 'Click to search for CC/MCC for this set of DRGs'
                    : ''
                }
                label={item.level}
                labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
                disabled={item.level === 'BASE'}
                onClick={e => handleShowSeverities(drg)}
              />
            </div>
          )
        })}
      </TableRowColumn>
      <TableRowColumn style={{ width: 42, paddingLeft: 8, paddingRight: 4 }}>
        {drg.family.map((item, index) => (
          <div
            key={item.code}
            style={{ height: 36, paddingTop: 8, textAlign: 'center' }}>
            <b>{getLosForDrg(item, workingDischargeDispositionGroup).elos.toFixed(2)}</b>
          </div>
        ))}
      </TableRowColumn>
      <TableRowColumn style={{ width: 34, paddingLeft: 8, paddingRight: 4 }}>
        {drg.family.map((item, index) => (
          <div
            key={item.code}
            style={{ height: 36, paddingTop: 8, textAlign: 'center' }}>
            <b>{getLosForDrg(item, workingDischargeDispositionGroup).tlos}</b>
          </div>
        ))}
      </TableRowColumn>
      <TableRowColumn style={{ width: 50, paddingLeft: 8, paddingRight: 4 }}>
        <Highlighter value={drg.type} searchTerm={searchTerm} />
      </TableRowColumn>
      <TableRowColumn
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          width: 100,
          padding: 5,
        }}>
        <Highlighter
          value={drg.majorDiagnosisCategory}
          searchTerm={searchTerm}
        />
      </TableRowColumn>
      <TableRowColumn
        style={{ whiteSpace: 'normal', wordWrap: 'break-word', padding: 5 }}>
        <Highlighter value={drg.title} searchTerm={searchTerm} />
      </TableRowColumn>
      , padding: 5
      <TableRowColumn
        style={{ whiteSpace: 'normal', wordWrap: 'break-word', padding: 5 }}>
        <Highlighter
          value={beautifyText(drg.commonPrincipalDX)}
          searchTerm={searchTerm}
        />
      </TableRowColumn>
      <TableRowColumn
        style={{ whiteSpace: 'normal', wordWrap: 'break-word', padding: 5 }}>
        <Highlighter
          value={beautifyText(drg.commonProcedures)}
          searchTerm={searchTerm}
        />
      </TableRowColumn>
    </TableRow>
  )
}

DrgRow.propTypes = {
  rowNumber: PropTypes.number.isRequired,
  drg: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
  onSelectDrg: PropTypes.func.isRequired,
  onShowSeverities: PropTypes.func.isRequired,
  workingDischargeDispositionGroup: PropTypes.string
}

function beautifyText(text) {
  return (text || '')
    .replace(/^-/, '&middot; ')
    .replace(/\n'?-/g, '<br />&middot; ')
    .replace(/\n/g, '<br />')
}

function getLosForDrg(drg, workingDischargeDispositionGroup) {
  let losObject = {tlos: drg.otherTargetLos, elos: drg.otherExpectedLos}

  switch(workingDischargeDispositionGroup) {
    case "SNF":
      losObject.tlos = drg.snfTargetLos;
      losObject.elos = drg.snfExpectedLos
      break;
    case "REHAB":
      losObject.tlos = drg.rehabTargetLos;
      losObject.elos = drg.rehabExpectedLos;
      break;
    case "HOSPICE_MED":
      losObject.tlos = drg.hospiceMedTargetLos;
      losObject.elos = drg.hospiceMedExpectedLos;
      break;
    case "HOSPICE_HOME":
      losObject.tlos = drg.hospiceHomeTargetLos;
      losObject.elos = drg.hospiceHomeExpectedLos;
      break;
    case "HOME_HEALTH":
      losObject.tlos = drg.homeHealthTargetLos;
      losObject.elos = drg.homeHealthExpectedLos;
      break;
    case "HOME":
      losObject.tlos = drg.homeTargetLos;
      losObject.elos = drg.homeExpectedLos;
      break;
    case "ACUTE_CARE_HOSPITAL":
      losObject.tlos = drg.acuteCareHospitalTargetLos;
      losObject.elos = drg.acuteCareHospitalExpectedLos;
      break;
    case "OTHER":
      losObject.tlos = drg.otherTargetLos;
      losObject.elos = drg.otherExpectedLos;
      break;
  }
  return losObject;
}
