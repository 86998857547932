import { onError } from 'apollo-link-error'

import { logout } from '../ducks/user'
import { showErrorsPg } from '../ducks/errorspg'

import { LogManager, Logger } from '../log'
import { reduxStore } from '..'
const logger: Logger = LogManager.getLogger('c3.index')

// Tell Apollo to dispatch to redux whenever we get non-auth error responses
export const handleNetworkErrors = onError(
  ({ operation, response, networkError, graphQLErrors }) => {
    if (networkError) {
      if (networkError.result.error === 'authorization') {
        if (
          networkError.result.message.startsWith('JWT expired') ||
          networkError.result.message.startsWith('JWT signature')
        ) {
          logger.error(
            'Security Token has expired. User logged out.',
            networkError
          )
          reduxStore.dispatch(
            logout({
              errors: [
                {
                  key: 'TokenExpired',
                  code: networkError.statusCode,
                  message:
                    'Your session has expired. To continue, please log-in.',
                },
              ],
            })
          )
        } else {
          logger.error(
            'User was not authorized to perform that action.',
            networkError
          )
          reduxStore.dispatch(
            showErrorsPg([
              {
                key: 'NotAuthorized',
                code: networkError.statusCode,
                message: 'You are not authorized to perform that action',
              },
            ])
          )
        }
      } else {
        logger.error('Network error occurred.', networkError)
      }
    }
  }
)

// Dispatch an error to the redux store if there are any errors
export const handleGraphQlErrors = onError(
  ({ operation, response, networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      logger.error(
        'An error occurred during the graphql query/mutation.',
        graphQLErrors
      )
      reduxStore.dispatch(
        showErrorsPg(
          graphQLErrors.map(error => ({
            key: 'GraphqlError',
            code: 400,
            message: error.message,
          }))
        )
      )
    }
  }
)
