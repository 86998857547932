import React from 'react'
import PropTypes from 'prop-types'

import './kpi.scss'

import FlatButton from 'material-ui/FlatButton'
import {
  blue800 as BarColor,
  cyan500 as SelectedColor,
  grey200 as ClearColor,
} from 'material-ui/styles/colors'

import ClearIcon from 'mdi-react/CloseCircleIcon'

import { BarChart } from '../charts'

const UnitLabel = 'Unit'
const DrgLabel = 'DRG'
const DispositionLabel = 'Arrangements'
const maxPad = 40

export class ExcessDaysSection extends React.Component {
  static propTypes = {
    workspaces: PropTypes.array,
    selected: PropTypes.object,
    capped: PropTypes.bool,
    dimension: PropTypes.string,
    onDimensionChange: PropTypes.func,
    onBarChartBarClick: PropTypes.func,
    onBarChartLabelClick: PropTypes.func,
    onClearSelectionClick: PropTypes.func,
  }

  constructor() {
    super()
  }

  handleDimensionChange = dimension => {
    this.props.onDimensionChange(dimension)
  }

  padData(data) {
    // Since we dont want to show items with 0 excess days we need to set their names to begin with RAF
    // so that when they are sorted we can trim down the number of items to (maxPad) 40 or less
    /*data.forEach((item, index) => {
      if (item.excessDays < 0) {
        item.name = `RAF${index}`
        item.items = []
      }
    })*/

    // The reason why we pad the data to (maxPad) 40 data items is that
    // this evens out the bar graph spacing with hidden placeholders
    // With more time we can find a better way to do this
    for (let i = data.length; i < maxPad; i++) {
      data.push({
        name: `RAF${i}`,
        excessDays: 0,
        items: [],
      })
    }
    return data
  }

  makeData(workspaces, groupBy) {
    let obj = {}
    let key = {}
    let description = {}
    let notIncludedInLosCalculation = ''

    workspaces.forEach(workspace => {
      const deptName = workspace.department.name
      if (!workspace.department.includedInLosCalculation) {
        if (!notIncludedInLosCalculation.includes(deptName)) {
          notIncludedInLosCalculation = `${notIncludedInLosCalculation} ${deptName}`
        }
      }
      let name
      if (groupBy === 'Unit') {
        name = deptName
        description[name] = ''
        key[name] = name
      } else if (groupBy === 'DRG') {
        if (workspace.visit.drgAttributes) {
          name = `${workspace.visit.drgAttributes.code} ${
            workspace.visit.drgAttributes.level
          }`
          description[name] = ` : ${workspace.visit.drgAttributes.title}`
          key[name] = workspace.visit.drgAttributes.code
        } else {
          name = 'Not Set'
          description[name] = ''
          key[name] = 'NotSet'
        }
      } else {
        name = workspace.visit.workingDischargeDisposition.value
        description[name] = ''
        key[name] = workspace.visit.workingDischargeDisposition.code
      }
      let arr = obj[name]
      if (!arr) {
        arr = obj[name] = []
      }
      arr.push(workspace)
    })

    let data = []
    const forecastLos = this.props.capped ? 'cappedForecast' : 'forecast'

    Object.keys(obj).forEach(name => {
      let count = 0

      obj[name].forEach(item => {
        count += Math.max(
          item.plan.meta.lengthOfStayMeta[forecastLos] - item.visit.tlos,
          0
        )
      })

      data.push({
        name: name,
        key: key[name],
        description: description[name],
        notIncludedInLosCalculation:
          groupBy === 'Unit'
            ? notIncludedInLosCalculation.includes(name)
            : false,
        excessDays: count,
        items: obj[name],
      })
    })
    return data
  }

  makeBarChartData(kpiData, selected) {
    //todo can set the color based on if the item is selected
    const data = kpiData.reduce((acc, item, index) => {
      // todo This does not work right yet
      const color =
        selected && selected.obj.name === item.name ? SelectedColor : BarColor
      acc.push({
        x: ++index,
        y: item.excessDays,
        color: color,
        obj: item,
      })
      return acc
    }, [])

    const labels = data.reduce((acc, item) => {
      acc.push(
        (item.obj.notIncludedInLosCalculation > 0 ? '*' : '') + item.obj.name
      )
      return acc
    }, [])

    return {
      data: data,
      labels: labels,
      selected: selected,
    }
  }

  makeBarChart(barChartData) {
    // Now data is padded to (maxPad) 40 items, the best width is 2000
    let width = 2000 //barChartData.data.length > 8 ? 2000 : 400

    const chart = (
      <BarChart
        data={barChartData.data}
        labels={barChartData.labels}
        selected={barChartData.selected}
        onBarClick={this.props.onBarChartBarClick}
        onLabelClick={this.props.onBarChartLabelClick}
      />
    )
    //  style={{width: width}}
    return chart
  }

  makeDimensionButton(dimension, selected) {
    return (
      <FlatButton
        label={dimension}
        style={{
          backgroundColor: selected ? SelectedColor : '',
          border: '1px solid lightgrey',
          lineHeight: '30px',
          maxHeight: 34,
          marginLeft: '5px',
          marginTop: '4px',
        }}
        labelStyle={{ textTransform: 'none' }}
        onClick={() => this.handleDimensionChange(dimension)}
      />
    )
  }

  makeClearButton(selected) {
    return (
      <ClearIcon
        style={{
          verticalAlign: '-12px',
          fill: selected ? '' : ClearColor,
          width: '34px',
          height: '34px',
          marginLeft: '15px',
        }}
        onClick={() => this.props.onClearSelectionClick()}
      />
    )
  }

  render() {
    const { workspaces, selected, dimension } = this.props
    let data
    if (dimension === UnitLabel) {
      data = this.padData(this.makeData(workspaces, UnitLabel))
    } else if (dimension === DrgLabel) {
      data = this.padData(this.makeData(workspaces, DrgLabel))
    } else {
      data = this.padData(this.makeData(workspaces, DispositionLabel))
    }

    data = data
      .sort((a, b) => {
        const aRAF = a.name.startsWith('RAF')
        const bRAF = b.name.startsWith('RAF')
        return aRAF && bRAF ? 0 : bRAF ? -1 : b.excessDays - a.excessDays
      })
      .slice(0, maxPad)
    data.forEach(
      item => (item.name = item.name.startsWith('RAF') ? '' : item.name)
    )
    const barChartData = this.makeBarChartData(data, selected)

    let selectedText = selected ? selected.obj.name : 'None'
    if (selected && selected.obj.description) {
      selectedText += selected.obj.description
    }

    return (
      <div className="excess-days-section">
        <div className="row dimension">
          <span className="title">Forecast Excess Days By:</span>
          {this.makeDimensionButton(UnitLabel, dimension === UnitLabel)}
          {this.makeDimensionButton(DrgLabel, dimension === DrgLabel)}
          {this.makeDimensionButton(
            DispositionLabel,
            dimension === DispositionLabel
          )}
          <span className="selected">{`Selected: ${selectedText}`}</span>
          {this.makeClearButton(selected)}
        </div>
        <div className="row excess-days-chart">
          {this.makeBarChart(barChartData, selected)}
        </div>
      </div>
    )
  }
}
