/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BedRequestStatus = "Anticipated" | "AnticipatedDischarge" | "AnticipatedUnitTransfer" | "Cancelled" | "CheckedIn" | "DischargeOrderWithDelay" | "Discharged" | "IntentToAdmit" | "Pending" | "PendingDischarge" | "PendingUnitTransfer" | "Ready" | "ReadyForDischarge" | "ReadyForUnitTransfer" | "Transferred" | "%future added value";
export type BedRequestType = "Admission" | "UnitTransfer" | "%future added value";
export type visit_updateBedRequestStatus_MutationVariables = {|
  visitId: any,
  status: string,
  anticipatedDischargeTime?: ?any,
  anticipatedTransferTime?: ?any,
|};
export type visit_updateBedRequestStatus_MutationResponse = {|
  +visitMutation: ?{|
    +updateBedRequestStatus: ?{|
      +id: string,
      +bedRequestStatus: ?BedRequestStatus,
      +anticipatedDischargeTime: ?any,
      +anticipatedDischargeTimeAutoPopulated: ?boolean,
      +anticipatedTransferTime: ?any,
      +anticipatedTransferTimeAutoPopulated: ?boolean,
      +bedRequests: $ReadOnlyArray<{|
        +id: string,
        +bedRequestType: BedRequestType,
        +bedRequestStatus: BedRequestStatus,
        +updatedWhen: any,
        +bedExchangeName: ?string,
      |}>,
    |}
  |}
|};
export type visit_updateBedRequestStatus_Mutation = {|
  variables: visit_updateBedRequestStatus_MutationVariables,
  response: visit_updateBedRequestStatus_MutationResponse,
|};
*/


/*
mutation visit_updateBedRequestStatus_Mutation(
  $visitId: Long!
  $status: String!
  $anticipatedDischargeTime: ZonedDateTime
  $anticipatedTransferTime: ZonedDateTime
) {
  visitMutation {
    updateBedRequestStatus(visitId: $visitId, status: $status, anticipatedDischargeTime: $anticipatedDischargeTime, anticipatedTransferTime: $anticipatedTransferTime) {
      id
      bedRequestStatus
      anticipatedDischargeTime
      anticipatedDischargeTimeAutoPopulated
      anticipatedTransferTime
      anticipatedTransferTimeAutoPopulated
      bedRequests {
        id
        bedRequestType
        bedRequestStatus
        updatedWhen
        bedExchangeName
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anticipatedDischargeTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anticipatedTransferTime"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bedRequestStatus",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VisitMutation",
    "kind": "LinkedField",
    "name": "visitMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "anticipatedDischargeTime",
            "variableName": "anticipatedDischargeTime"
          },
          {
            "kind": "Variable",
            "name": "anticipatedTransferTime",
            "variableName": "anticipatedTransferTime"
          },
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          },
          {
            "kind": "Variable",
            "name": "visitId",
            "variableName": "visitId"
          }
        ],
        "concreteType": "Visit",
        "kind": "LinkedField",
        "name": "updateBedRequestStatus",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anticipatedDischargeTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anticipatedDischargeTimeAutoPopulated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anticipatedTransferTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anticipatedTransferTimeAutoPopulated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BedRequest",
            "kind": "LinkedField",
            "name": "bedRequests",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bedRequestType",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedWhen",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bedExchangeName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateBedRequestStatus_Mutation",
    "selections": (v6/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateBedRequestStatus_Mutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "59c003a4e8e2cc359a43836861acb616",
    "id": null,
    "metadata": {},
    "name": "visit_updateBedRequestStatus_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateBedRequestStatus_Mutation(\n  $visitId: Long!\n  $status: String!\n  $anticipatedDischargeTime: ZonedDateTime\n  $anticipatedTransferTime: ZonedDateTime\n) {\n  visitMutation {\n    updateBedRequestStatus(visitId: $visitId, status: $status, anticipatedDischargeTime: $anticipatedDischargeTime, anticipatedTransferTime: $anticipatedTransferTime) {\n      id\n      bedRequestStatus\n      anticipatedDischargeTime\n      anticipatedDischargeTimeAutoPopulated\n      anticipatedTransferTime\n      anticipatedTransferTimeAutoPopulated\n      bedRequests {\n        id\n        bedRequestType\n        bedRequestStatus\n        updatedWhen\n        bedExchangeName\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3853f7d7b35e964f10887fa713c7a74e';

module.exports = node;
