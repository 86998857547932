import React from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from './ErrorBoundary'
import { Route } from 'react-router-dom'

const ErrorBoundaryRoute = ({
  component: Component,
  render: Render,
  ...props
}) =>
  (Component && (
    <Route
      component={prop => (
        <ErrorBoundary>
          <Component {...prop} />
        </ErrorBoundary>
      )}
      {...props}
    />
  )) ||
  (Render && (
    <Route
      render={prop => (
        <ErrorBoundary>
          <Render {...prop} />
        </ErrorBoundary>
      )}
      {...props}
    />
  ))

ErrorBoundaryRoute.propTypes = {
  component: PropTypes.func,
  render: PropTypes.func,
}

export default ErrorBoundaryRoute
