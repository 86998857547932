import React from 'react'
import Client from '../simple-graphql-client'
import { baseGraphQLURL } from '../../util/base-url'

type Props = {
  graphQLClient: any,
}

// The client handles its authorization header specifically
// for CL usage by checking the local storage for an auth token.
const createClient = () =>
  Client({
    url: `${baseGraphQLURL}/graphql`,
  })

/**
 * A very simple HOC that places a 'graphGLClient' instance on props.
 */
export function withGraphql(WrappedComponent) {
  return class WithGraphQL extends React.Component<Props> {
    constructor(props) {
      super(props)
      this.state = {
        graphQLClient: createClient(),
      }
    }

    render() {
      const props = { ...this.props, graphQLClient: this.state.graphQLClient }
      return <WrappedComponent {...props} />
    }
  }
}
