import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import { lightBlue900 as TitleColor } from 'material-ui/styles/colors'

import { createStyleSheet } from 'jss-theme-reactor'
import { Date } from '../../components/date'
import { AuditUser } from '../../components/audit'
import { getPlannedLabel } from '../../util/configs'

const styleSheet = createStyleSheet('InfoSecondary', theme => {
  return {
    title: {
      paddingTop: '5px',
      color: TitleColor,
      fontSize: '90%',
    },
  }
})

export const InfoSecondary = ({ visit }, context) => {
  const classes = context.styleManager.render(styleSheet)

  const formatTime = time =>
    time ? moment(time).format('MMM D HH:mm') : ' Not Set '

  const isValidAnticipatedDischargeTime = (visit.anticipatedDischargeUpdatedWhen !== null)

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-1">
          <div className={classes.title}>Admission</div>
        </div>
        <div className="col-xs-1">
          <div className={classes.title}>Target D/C</div>
        </div>
        <div className="col-xs-1">
          <div className={classes.title}>MRN</div>
        </div>
        <div className="col-xs-1">
          <div className={classes.title}>Visit ID</div>
        </div>
        {isValidAnticipatedDischargeTime &&
          <div className="col-xs-3">
            <div className={classes.title}>{getPlannedLabel()} Discharge</div>
          </div>
        }
        {visit.workingDischargeDispositionUpdatedWhen &&
          <div className="col-xs-3">
            <div className={classes.title}>D/C Arrangement</div>
          </div>
        }
      </div>

      <div className="row">
        <div className="col-xs-1">
          <div>{formatTime(visit.admissionTime)}</div>
        </div>
        <div className="col-xs-1">
          <div>{formatTime(visit.targetDischargeTime)}</div>
        </div>
        <div className="col-xs-1">
          <div>{visit.patient.mrn}</div>
        </div>
        <div className="col-xs-1">
          <div>{visit.visitNumber}</div>
        </div>
        {isValidAnticipatedDischargeTime && (
          <div className="col-xs-3">
            Updated: <Date dateString={visit.anticipatedDischargeUpdatedWhen} /> By:{' '}
            <AuditUser user={visit.anticipatedDischargeUpdatedBy} emptyText="External System" />
          </div>
        )}
        {visit.workingDischargeDispositionUpdatedWhen && (
          <div className="col-xs-3">
            Updated: <Date dateString={visit.workingDischargeDispositionUpdatedWhen} /> By:{' '}
            <AuditUser user={visit.workingDischargeDispositionUpdatedBy} emptyText="External System" />
          </div>
        )}
      </div>
    </div>
  )
}

InfoSecondary.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

InfoSecondary.propTypes = {
  visit: PropTypes.object.isRequired,
}
