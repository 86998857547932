import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { refreshAuthToken, logout } from '../ducks/user'

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('ca.containers.TokenRefresher')

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        refreshAuthToken,
        logout,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class TokenRefresher extends Component {
  /**
   * Milliseconds before the expiration timer to use as a buffer to ensure we get a new token
   * @type {number}
   */
  /*eslint-disable react/sort-comp*/
  static refreshBufferTime: number = 20000

  static propTypes = {
    authState: PropTypes.shape({
      expiration: PropTypes.number,
      token: PropTypes.string,
      refreshToken: PropTypes.string,
      identity: PropTypes.object,
    }),
    actions: PropTypes.shape({
      refreshAuthToken: PropTypes.func,
      logout: PropTypes.func,
    }),
  }

  componentDidMount() {
    // Setup initial timer
    if (this.props.authState && this.props.authState.token) {
      this.refreshTimeout = setTimeout(
        this.handleTokenRefresh,
        this.props.authState.expiration - TokenRefresher.refreshBufferTime
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    // Setup initial timer or update timer
    // If the authToken changed, restart the timer
    if (
      nextProps.authState &&
      nextProps.authState.token &&
      (!this.props.authState ||
        !this.props.authState.token ||
        this.props.authState.token !== nextProps.authState.token)
    ) {
      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout)
        delete this.refreshTimeout
      }

      this.refreshTimeout = setTimeout(
        this.handleTokenRefresh,
        nextProps.authState.expiration - TokenRefresher.refreshBufferTime
      )
    }
  }

  componentWillUnmount() {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      delete this.refreshTimeout
    }
  }

  handleTokenRefresh = () => {
    delete this.refreshTimeout
    logger.debug('Refreshing auth token')
    this.props.actions.refreshAuthToken().catch(error => {
      logger.error(
        'An error occurred during token refresh. Please sign in again.',
        error
      )
      this.props.actions.logout(error)
    })
  }

  render() {
    return null
  }
}
