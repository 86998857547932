/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type action_removeAction_MutationVariables = {|
  actionId: any
|};
export type action_removeAction_MutationResponse = {|
  +actionMutation: ?{|
    +removeAction: ?{|
      +id: string,
      +actions: $ReadOnlyArray<?{|
        +id: string
      |}>,
      +milestones: $ReadOnlyArray<?{|
        +id: string,
        +actions: $ReadOnlyArray<?{|
          +id: string
        |}>,
      |}>,
    |}
  |}
|};
export type action_removeAction_Mutation = {|
  variables: action_removeAction_MutationVariables,
  response: action_removeAction_MutationResponse,
|};
*/


/*
mutation action_removeAction_Mutation(
  $actionId: UUID!
) {
  actionMutation {
    removeAction(actionId: $actionId) {
      id
      actions {
        id
      }
      milestones {
        id
        actions {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "actionId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ActionMutation",
    "kind": "LinkedField",
    "name": "actionMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "actionId",
            "variableName": "actionId"
          }
        ],
        "concreteType": "Plan",
        "kind": "LinkedField",
        "name": "removeAction",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PlanAction",
            "kind": "LinkedField",
            "name": "actions",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Milestone",
            "kind": "LinkedField",
            "name": "milestones",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MilestoneAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "action_removeAction_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "action_removeAction_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5ad1f21262a44675d1cb2a5f17f12b9c",
    "id": null,
    "metadata": {},
    "name": "action_removeAction_Mutation",
    "operationKind": "mutation",
    "text": "mutation action_removeAction_Mutation(\n  $actionId: UUID!\n) {\n  actionMutation {\n    removeAction(actionId: $actionId) {\n      id\n      actions {\n        id\n      }\n      milestones {\n        id\n        actions {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c13da5f005b4861f7f8c28ce534ad610';

module.exports = node;
