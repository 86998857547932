import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { evaluateRouteAuth } from './routing-utils'

import { updateCurrentRoute } from '../ducks/views'
import ErrorBoundaryRoute from '../components/error/ErrorBoundaryRoute'
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateCurrentRoute,
    },
    dispatch
  ),
})
const mapStateToProps = state => ({ authState: state.authState })

@connect(
  mapStateToProps,
  mapDispatchToProps
)
export class RouteWhenAllowed extends Component {
  static propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    redirect: PropTypes.string,
    title: PropTypes.string,
    viewType: PropTypes.string,
    component: PropTypes.func.isRequired,
    auth: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        directory: PropTypes.string,
        applications: PropTypes.arrayOf(PropTypes.string),
        permissions: PropTypes.arrayOf(PropTypes.string),
        personnelTypes: PropTypes.arrayOf(PropTypes.string),
      }),
    ]),
    nav: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    authState: PropTypes.shape({
      token: PropTypes.string,
      identity: PropTypes.object,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    actions: PropTypes.shape({
      updateCurrentRoute: PropTypes.func,
    }),
  }

  componentWillMount() {
    const { path, title, viewType } = this.props
    this.props.actions.updateCurrentRoute({
      path,
      title,
      viewType,
    })
  }

  render() {
    const {
      exact,
      path,
      redirect,
      title,
      component,
      auth,
      authState,
      location,
    } = this.props

    return (
      <ErrorBoundaryRoute
        path={path}
        exact={exact}
        location={location}
        render={props => {
          const errors = evaluateRouteAuth(auth, authState, path || title)
          const wasLogout =
            props.location && props.location.pathname.includes('logout')
          const hosLauncher =
            authState &&
            authState.identity &&
            authState.identity.assignedApplications.includes(
              'PATIENT_LOGISTICS'
            )

          // CA-879 - close when opened via HOS Launcher
          // window.opener will set set when we were opened by some other window
          // and that would be the HOS Launcher
          // also check for session timeout with error of 'NotLoggedIn'
          if (
            (wasLogout && hosLauncher && window.opener) ||
            (errors &&
              errors.length > 0 &&
              errors[0].key === 'NotLoggedIn' &&
              window.opener)
          ) {
            window.close()
            return null
          }

          return errors.length === 0 ? (
            redirect ? (
              <Redirect to={redirect} />
            ) : (
              React.createElement(component, props)
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: !wasLogout && props.location,
                  errors:
                    !wasLogout && props.location.pathname !== '/' ? errors : [],
                },
              }}
            />
          )
        }}
      />
    )
  }
}
