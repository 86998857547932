import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import {createFragmentContainer, graphql} from 'react-relay'

const BarrierCategoryTable = ({ list, onEdit }) => {

  const getSourceTypeText = (sourceType) => {
    let text = sourceType.value
    if (!sourceType.active) {
      text += ' - Deactivated'
    }
    return text
  }

  return (
    <StyledTable>
      <colgroup>
        <col width="5%"/>
        <col width="25%"/>
        <col width="25%"/>
        <col width="14%"/>
        <col width="14%"/>
        <col width="17%"/>
      </colgroup>
      <thead>
      <tr>
        <th/>
        <th>Code</th>
        <th>Description</th>
        <th>Order</th>
        <th>Active</th>
        <th>Source Types</th>
      </tr>
      </thead>
      <tbody>
        {list.map((entry, index) => (
          <tr key={index}>
            <td>
              <IconButton onClick={() => onEdit(entry)}>
                <Edit />
              </IconButton>
            </td>
            <td>{entry.code}</td>
            <td>{entry.value}</td>
            <td>{entry.ordinal}</td>
            <td>{entry.active?"Y":"N"}</td>
            <td>
              {entry.sourceTypes && (
                <StyledFlexDiv>
                  {entry.sourceTypes.map(type => <StyledSourceType key={type.code} $isActive={type.active}>{getSourceTypeText(type)}</StyledSourceType>)}
                </StyledFlexDiv>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  thead {
    font-size: 16px;
    background-color: #d3d3d3;
  }

  tbody {
    background-color: white;
  }

  tr {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  th {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  td {
    padding-left: 15px;
    padding-right: 15px;
  }


  &, th, td {
    border: 1px solid rgb(224, 224, 224);
  }
`

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSourceType = styled.span`
  ${(props) => {
    switch (props.$isActive) {
      case false:
        return css`
          color: red;
        `
      default:
        return
    }
  }}
`

BarrierCategoryTable.propTypes = {
  list: PropTypes.array,
  onEdit: PropTypes.func
}

export default createFragmentContainer(BarrierCategoryTable, {
  list: graphql`
    fragment BarrierCategoryTable_list on BarrierCategory @relay(plural: true) {
      id
      code
      value
      ordinal
      active
      sourceTypes {
        id
        code
        value
        active
      }
    }
  `,
})
