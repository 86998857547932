/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'

import NavigationClose from 'material-ui/svg-icons/navigation/close'

import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import { CodeTableSelect } from '../../containers'

import { StatefulTextField } from '../../components/common/StatefulTextField'

import { updateWorkingDischargeDisposition } from '../../graphql/relay/queries/visit'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'
import { TransactionErrorDialog } from '../../components/error'


class EditWorkingDCDisposition extends Component {
  static propTypes = {
    visit: PropTypes.object.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    grid: PropTypes.object,
    refetch: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      wddCode: props.visit.workingDischargeDisposition.code,
      vendorText: props.visit.dischargeVendor,
      isChanged: false,
      canChangeDCArrangement: true
    }
  }

  handleSave = () => {
    const visitId = this.props.visit.id
    if(this.props.visit.bedRequestStatus === 'Discharged'){
      this.setState({canChangeDCArrangement: false})
      return
    }

    handleMutation(
      environment,
      updateWorkingDischargeDisposition,
      {
        visitId,
        dispositionCode: this.state.wddCode,
        dischargeVendor: this.state.vendorText || '',
      },
      ()=>{
        this.props.onRequestClose()
        this.props.refetch ? this.props.refetch() : this.props.refresh ? this.props.refresh() :
          this.props.grid && this.props.grid.forceUpdateGrid()
      }
    )
  }

  handleCodeChange = (event, key, code) => {
    this.setState({
      wddCode: code,
      isChanged: true,
    })
  }

  handleVendorChange = (event, vendorText) => {
    this.setState({
      vendorText: vendorText,
      isChanged: true,
    })
  }

  render() {
    const { visit, onRequestClose } = this.props

    const title = `Update D/C Arrangements for ${visit.patient.lastName}, ${
      visit.patient.firstName
    }`

    const { wddCode, vendorText, isChanged } = this.state

    const applyButtonStyle = isChanged && { labelStyle: { color: 'white' } }

    const actions = [
      <FlatButton
        label="Apply"
        key="apply"
        {...applyButtonStyle}
        disabled={!isChanged}
        onClick={this.handleSave}
      />,
    ]

    return (
      <div>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div className="row">
          <div style={{marginLeft: "10px"}} className="col-xs-6">
            <CodeTableSelect
              noNullValue
              componentProps={{
                fullWidth: true,
                floatingLabelShrinkStyle: { whiteSpace: 'nowrap' },
                floatingLabelText: 'D/C Arrangements',
              }}
              codeTableType="WorkingDischargeDisposition"
              value={wddCode}
              onChange={this.handleCodeChange}
            />
          </div>

          <div className="col-xs-5">
            <StatefulTextField
              textFieldProps={{
                style: { textAlign: 'left', marginTop: '2px' },
                fullWidth: true,
                floatingLabelText: 'Note',
                multiLine: true,
                rows: 3,
                rowsMax: 3,
              }}
              text={vendorText}
              onChange={this.handleVendorChange}
              clearOnTextEntered={false}
              showCharacterCount
              blurOnEnter
              maxNumberOfChars={255}
            />
          </div>
        </div>

        {!this.state.canChangeDCArrangement && <TransactionErrorDialog
          errors={[{
              "key": "validationForDCArrangement",
              "message": "Cannot update D/C Arrangements or Note - Patient has already been discharged.",
              "id": "validationForDCArrangement"
          }]}
          onRequestClose={()=> this.setState({canChangeDCArrangement: true})}
        />}
      </div>
    )
  }
}

export default EditWorkingDCDisposition
