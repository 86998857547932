import {graphql} from 'react-relay'

export const BARRIER_OWNER_ROLE = 'BARRIER_OWNER_ROLE'
export const BARRIER_SOURCE_ROLE = 'BARRIER_SOURCE_ROLE'
export const IRIS_USER_ROLE = 'IRIS_USER_ROLE'

export const searchKnownUsers = graphql`
  query knownUser_searchKnownUsers_Query(
    $knownUserInput: KnownUserInput!) {
    knownUsers(knownUserInput: $knownUserInput) {
      id
      firstName
      lastName
      instantMessageReceiver
      emailReceiver
      title
    }
  }
`

export const buildKnownUserInput = ({ searchText, roles, instantMessageRegistered, isOwner}) => {
  const knownUserInput = {
    searchText: searchText,
  }

  if (roles !== undefined) {
    knownUserInput.roles = roles
  }
  if (instantMessageRegistered !== undefined) {
    knownUserInput.instantMessageRegistered = instantMessageRegistered
  }
  if (isOwner !== undefined) {
    knownUserInput.isOwner = isOwner
  }

  return knownUserInput
}
