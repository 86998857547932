import React, {Component} from 'react'
import {showDrawer} from '../../ducks/views'
import {drawers} from '../../containers/drawer-component-mapping'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import IconButton from 'material-ui/IconButton'
import OpenDrawer from 'material-ui/svg-icons/content/add-circle-outline'
import PropTypes from 'prop-types'
import { showErrorsPg } from '../../ducks/errorspg'
import { deleteVisibilityBoardAddressEntry } from '../../graphql/relay/queries/visibilityBoardAddress'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import {environment} from "../../graphql/relay/env"
import VisibilityBoardAddressRowList from "../../components/visibilityBoardAddress/VisibilityBoardAddressRowList"

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
        showErrorsPg
      },
      dispatch
    ),
  }
}

const rowStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingTop: "15px",
  paddingBottom: "15px"
}

const columnStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "5px",
  paddingBottom: "5px"
}


class VisibilityBoardAddressEditor extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    listBoards: PropTypes.arrayOf(PropTypes.object),
    listFacilities: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
      showErrorsPg: PropTypes.func,
    }),
  }
  constructor(props) {
    super(props)
  }

  onEditHandler = (rowData) => {
    this.props.actions.showDrawer(drawers.EDIT_VISIBILITY_BOARD_ADDRESS_ENTRY, {
      title: "Edit " + this.props.title,
      data: {
        ...rowData,
        boards: this.props.listBoards
      }
    })
  }

  onDeleteHandler = (rowData) => {
    const variables = {
      visibilityBoardAddressId: rowData.id
    }

    const updateAfterDeletion = (proxyStore) => {
      const store = proxyStore.getRootField("visibilityBoardAddressMutation")
      const payload = store.getLinkedRecords("deleteVisibilityBoardAddressEntry", variables)
      const rootStore = proxyStore.get("client:root")
      const visibilityBoardAddressList = rootStore.getLinkedRecords('visibilityBoardAddresses')
      rootStore.setLinkedRecords(payload,'visibilityBoardAddresses')
    }

    handleMutation(
      environment,
      deleteVisibilityBoardAddressEntry,
      variables,
      undefined,
      undefined,
      undefined,
      updateAfterDeletion
    )
  }

  onAddHandler = () => {
    this.props.actions.showDrawer(drawers.ADD_VISIBILITY_BOARD_ADDRESS_ENTRY, {
      title: "Add " + this.props.type,
      data: {
        boards: this.props.listBoards,
      }
    })
  }


  render() {
    return (
      <div style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
        <h1 style={{display: "inline"}}>{this.props.title}</h1>
        <IconButton
          style={{padding: 8, width: 40, height: 40 }}
          onClick={this.onAddHandler}>
          <OpenDrawer />
        </IconButton>
        <table style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}>
          <colgroup>
            <col width="2%"/>
            <col width="32%"/>
            <col width="32%"/>
            <col width="32%"/>
            <col width="2%"/>
          </colgroup>
          <thead style={{fontSize: "16px"}}>
            <tr style={rowStyle}>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
              <th style={columnStyle} bgcolor="#d3d3d3">Address</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Description</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Board Name</th>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
            </tr>
          </thead>
          <tbody>
            <VisibilityBoardAddressRowList list={this.props.list} onEdit={this.onEditHandler} onDelete={this.onDeleteHandler}/>
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(VisibilityBoardAddressEditor)
