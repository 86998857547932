/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type VisibilityBoardAddressRow_rowData$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VisibilityBoardAddressRowList_list$ref: FragmentReference;
declare export opaque type VisibilityBoardAddressRowList_list$fragmentType: VisibilityBoardAddressRowList_list$ref;
export type VisibilityBoardAddressRowList_list = $ReadOnlyArray<{|
  +$fragmentRefs: VisibilityBoardAddressRow_rowData$ref,
  +$refType: VisibilityBoardAddressRowList_list$ref,
|}>;
export type VisibilityBoardAddressRowList_list$data = VisibilityBoardAddressRowList_list;
export type VisibilityBoardAddressRowList_list$key = $ReadOnlyArray<{
  +$data?: VisibilityBoardAddressRowList_list$data,
  +$fragmentRefs: VisibilityBoardAddressRowList_list$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VisibilityBoardAddressRowList_list",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VisibilityBoardAddressRow_rowData"
    }
  ],
  "type": "VisibilityBoardAddress",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'de9493140ef70dcd2d4f189ca3ee8c13';

module.exports = node;
