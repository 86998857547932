import React from 'react'
import PropTypes from 'prop-types'

import { Paper } from 'material-ui'
import { yellow600 as NoteColor } from 'material-ui/styles/colors'

import { MoveToBed } from './'
import { NameFormat } from '../common'
import { CodeImage } from '../image/CodeImage'
import { BedUnitLink } from '../common'

import { Date } from '../date'
import { AuditUser } from '../audit'
import { is_Safari } from '../../util/utils'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('ConflictedPatient', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    noteIcon: {
      fill: NoteColor,
    },
    headerPlanList: {
      margin: '0 .5em .5em 0',
      padding: '1em 1em .5em 1em',
    },
    bedName: {
      cursor: 'pointer',
      marginTop: is_Safari ? '.1em' : '.1em',
      overflow: 'visible',
      whiteSpace: 'nowrap',
      zIndex: 1200,
    },
    patientInfo: {
      marginTop: is_Safari ? '.1em' : '.1em',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'default',
    },
    secondaryLinePullRight: {
      marginRight: '.5em',
      marginTop: '.7em',
      float: 'right',
    },
    iconMenuTitle: {
      position: 'relative',
      top: '5px',
    },
  }
})

export const ConflictedPatient = (
  {created, createdBy, unitName, bed, visit, plan, availableBeds, onViewPlanClick, onMoveToBed },
  context
) => {
  const classes = context.styleManager.render(styleSheet)
  const c3Classes = context.muiTheme.c3Classes

  const patient = visit.patient

  return (
    <Paper className={classNames(classes.headerPlanList)}>
      <div className={classNames('row')}>
        <BedUnitLink
          bed={bed}
          unit={unitName}
          planId={plan.id}
          visitId={visit.id}
          onClick={() => onViewPlanClick(bed.exchangeName, plan.id, visit.id)}
          extraClasses="col-xs-5"
        />
        <div className={classNames('col-xs-6', classes.patientInfo)}>
          <span className={classNames(c3Classes.spacer)}>
            <CodeImage type="AdmissionType" code={visit.admissionType} />
          </span>
          <span className={classNames(c3Classes.attr)}>
            <CodeImage type="Gender" code={patient.gender || 'nil'} />
          </span>
          <span className={classNames(c3Classes.attr)}>{patient.age} </span>
          <span className={classNames(c3Classes.attr)}>
            <NameFormat person={patient} />
          </span>
        </div>
      </div>

      <div className={classNames('row')}>
        <div className={classNames('col-xs-12', classes.mrnVisitInfo)}>
          <span className={classNames(c3Classes.attr)}>
            MRN: {patient.mrn}&nbsp;&nbsp;&nbsp;
          </span>
          <span className={classNames(c3Classes.attr)}>
            Visit ID: {visit.visitNumber}
          </span>
        </div>
      </div>

      <div className={classNames('row')}>
        <div className={classNames('col-xs-1')}>
          <span className={classNames(classes.iconMenuTitle)}>
            <MoveToBed
              items={availableBeds}
              onChange={item => onMoveToBed(item.bed, visit)}
            />
          </span>
        </div>
        <div className={classNames('col-xs-11')}>
          <div className={classNames(classes.secondaryLinePullRight)}>
            Created: <Date dateString={created} /> By:{' '}
            <AuditUser user={createdBy} emptyText="System" />
          </div>
        </div>
      </div>
    </Paper>
  )
}

ConflictedPatient.contextTypes = {
  styleManager: PropTypes.object.isRequired,
  muiTheme: PropTypes.object.isRequired,
}

ConflictedPatient.propTypes = {
  created: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  unitName: PropTypes.string.isRequired,
  bed: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  availableBeds: PropTypes.array.isRequired,
  onViewPlanClick: PropTypes.func,
  onMoveToBed: PropTypes.func,
}
