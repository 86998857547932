/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type note_FullPlanNote$ref = any;
export type note_updatePlanNote_MutationVariables = {|
  noteId: any,
  text: string,
|};
export type note_updatePlanNote_MutationResponse = {|
  +noteMutation: ?{|
    +updateNote: ?{|
      +id: string,
      +notes: $ReadOnlyArray<?{|
        +$fragmentRefs: note_FullPlanNote$ref
      |}>,
    |}
  |}
|};
export type note_updatePlanNote_Mutation = {|
  variables: note_updatePlanNote_MutationVariables,
  response: note_updatePlanNote_MutationResponse,
|};
*/


/*
mutation note_updatePlanNote_Mutation(
  $noteId: UUID!
  $text: String!
) {
  noteMutation {
    updateNote(noteId: $noteId, text: $text) {
      id
      notes {
        ...note_FullPlanNote
        id
      }
    }
  }
}

fragment note_FullPlanNote on PlanNote {
  id
  text
  auditMeta {
    created
    createdBy {
      userName
      firstName
      lastName
      id
    }
    updated
    updatedBy {
      userName
      firstName
      lastName
      id
    }
  }
  pinned
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "noteId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "noteId",
    "variableName": "noteId"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "note_updatePlanNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutation",
        "kind": "LinkedField",
        "name": "noteMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateNote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "note_FullPlanNote"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "note_updatePlanNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutation",
        "kind": "LinkedField",
        "name": "noteMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateNote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "updatedBy",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pinned",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "db58708323c55a791815e049f16358d6",
    "id": null,
    "metadata": {},
    "name": "note_updatePlanNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation note_updatePlanNote_Mutation(\n  $noteId: UUID!\n  $text: String!\n) {\n  noteMutation {\n    updateNote(noteId: $noteId, text: $text) {\n      id\n      notes {\n        ...note_FullPlanNote\n        id\n      }\n    }\n  }\n}\n\nfragment note_FullPlanNote on PlanNote {\n  id\n  text\n  auditMeta {\n    created\n    createdBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n    updated\n    updatedBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n  }\n  pinned\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b2dc246313afb1e5d0ea506f9efe8fa';

module.exports = node;
