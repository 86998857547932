/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateTimeToDecisionWithTime_MutationVariables = {|
  visitId: any,
  decisionTime: any,
|};
export type visit_updateTimeToDecisionWithTime_MutationResponse = {|
  +visitMutation: ?{|
    +updateTimeToDecisionWithTime: ?{|
      +id: string,
      +decisionTime: ?any,
      +meta: {|
        +dischargeToday: boolean,
        +tlosExceeded: boolean,
        +plannedDischargeExceeded: boolean,
        +decisionDue: boolean,
        +timeElapsedSinceAdmission: any,
      |},
    |}
  |}
|};
export type visit_updateTimeToDecisionWithTime_Mutation = {|
  variables: visit_updateTimeToDecisionWithTime_MutationVariables,
  response: visit_updateTimeToDecisionWithTime_MutationResponse,
|};
*/


/*
mutation visit_updateTimeToDecisionWithTime_Mutation(
  $visitId: Long!
  $decisionTime: ZonedDateTime!
) {
  visitMutation {
    updateTimeToDecisionWithTime(visitId: $visitId, decisionTime: $decisionTime) {
      id
      decisionTime
      meta {
        dischargeToday
        tlosExceeded
        plannedDischargeExceeded
        decisionDue
        timeElapsedSinceAdmission
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "decisionTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "decisionTime",
    "variableName": "decisionTime"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeToday",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlosExceeded",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedDischargeExceeded",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionDue",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeElapsedSinceAdmission",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateTimeToDecisionWithTime_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateTimeToDecisionWithTime",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateTimeToDecisionWithTime_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateTimeToDecisionWithTime",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe29e9c5b4fc36782675f504113c23d8",
    "id": null,
    "metadata": {},
    "name": "visit_updateTimeToDecisionWithTime_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateTimeToDecisionWithTime_Mutation(\n  $visitId: Long!\n  $decisionTime: ZonedDateTime!\n) {\n  visitMutation {\n    updateTimeToDecisionWithTime(visitId: $visitId, decisionTime: $decisionTime) {\n      id\n      decisionTime\n      meta {\n        dischargeToday\n        tlosExceeded\n        plannedDischargeExceeded\n        decisionDue\n        timeElapsedSinceAdmission\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5d34da23721bf9b95dbebd05a1221260';

module.exports = node;
