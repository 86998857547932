/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BarrierCategoryTable_list$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BarrierCategoryEditor_list$ref: FragmentReference;
declare export opaque type BarrierCategoryEditor_list$fragmentType: BarrierCategoryEditor_list$ref;
export type BarrierCategoryEditor_list = $ReadOnlyArray<{|
  +ordinal: number,
  +$fragmentRefs: BarrierCategoryTable_list$ref,
  +$refType: BarrierCategoryEditor_list$ref,
|}>;
export type BarrierCategoryEditor_list$data = BarrierCategoryEditor_list;
export type BarrierCategoryEditor_list$key = $ReadOnlyArray<{
  +$data?: BarrierCategoryEditor_list$data,
  +$fragmentRefs: BarrierCategoryEditor_list$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BarrierCategoryEditor_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BarrierCategoryTable_list"
    }
  ],
  "type": "BarrierCategory",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3bd76de4f9be341f04fac3b8b2bea800';

module.exports = node;
