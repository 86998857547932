import { graphql } from 'react-relay'

export const addVisibilityBoardAddressEntry = graphql`
  mutation visibilityBoardAddress_addVisibilityBoardAddressEntry_Mutation($visibilityBoardAddressInput: VisibilityBoardAddressInput!) {
    visibilityBoardAddressMutation {
      addVisibilityBoardAddressEntry(visibilityBoardAddressInput: $visibilityBoardAddressInput) {
        ...VisibilityBoardAddressRow_rowData
      }
    }
  }
`

export const modifyVisibilityBoardAddressEntry = graphql`
  mutation visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation($visibilityBoardAddressId: UUID!, $visibilityBoardAddressInput: VisibilityBoardAddressInput!) {
    visibilityBoardAddressMutation {
      modifyVisibilityBoardAddressEntry(visibilityBoardAddressId: $visibilityBoardAddressId, visibilityBoardAddressInput: $visibilityBoardAddressInput) {
        ...VisibilityBoardAddressRow_rowData
      }
    }
  }
`

export const deleteVisibilityBoardAddressEntry = graphql`
  mutation visibilityBoardAddress_deleteVisibilityBoardAddress_Mutation($visibilityBoardAddressId: UUID!) {
    visibilityBoardAddressMutation {
      deleteVisibilityBoardAddressEntry(visibilityBoardAddressId: $visibilityBoardAddressId) {
        ...VisibilityBoardAddressRowList_list
      }
    }
  }
`
