import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'
import SendIcon from '../../../image/img/send.svg'

import './SendBarrierButton.scss'

const SendBarrierButton = ({ disabled, onSendBarrier }) => {
  return (
    <div className="SendButtonWrapper">
      <div>
        <RaisedButton
          label="Send"
          className="SendButton"
          primary
          onClick={onSendBarrier}
          disabled={disabled}
          buttonStyle={{ width: '175px' }}
          icon={<img src={SendIcon} className={'SendIcon'} />}/>
      </div>
    </div>
  )
}

SendBarrierButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSendBarrier: PropTypes.func.isRequired
}

export default SendBarrierButton
