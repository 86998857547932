/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateReadmission_MutationVariables = {|
  visitId: any,
  readmission: boolean,
|};
export type visit_updateReadmission_MutationResponse = {|
  +visitMutation: ?{|
    +updateReadmission: ?{|
      +id: string,
      +readmission: ?boolean,
      +readmissionVerifiedBy: ?{|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +readmissionUpdatedWhen: ?any,
    |}
  |}
|};
export type visit_updateReadmission_Mutation = {|
  variables: visit_updateReadmission_MutationVariables,
  response: visit_updateReadmission_MutationResponse,
|};
*/


/*
mutation visit_updateReadmission_Mutation(
  $visitId: Long!
  $readmission: Boolean!
) {
  visitMutation {
    updateReadmission(visitId: $visitId, readmission: $readmission) {
      id
      readmission
      readmissionVerifiedBy {
        userName
        firstName
        lastName
        id
      }
      readmissionUpdatedWhen
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "readmission"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "readmission",
    "variableName": "readmission"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmission",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmissionUpdatedWhen",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateReadmission_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateReadmission",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "readmissionVerifiedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateReadmission_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateReadmission",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "readmissionVerifiedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4289f6571b0adaa1bf9b8d2be04fbebb",
    "id": null,
    "metadata": {},
    "name": "visit_updateReadmission_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateReadmission_Mutation(\n  $visitId: Long!\n  $readmission: Boolean!\n) {\n  visitMutation {\n    updateReadmission(visitId: $visitId, readmission: $readmission) {\n      id\n      readmission\n      readmissionVerifiedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      readmissionUpdatedWhen\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '627cbb9a59f549836c3686df145baa3d';

module.exports = node;
