import React from 'react'
import PropTypes from 'prop-types'
import { Compliance } from './Compliance'
import {
  getScrollToIndex,
  saveIndexPosition,
  is_iPad,
  is_iPhone,
} from '../../util/utils'
import { List, AutoSizer } from 'react-virtualized'

class ComplianceSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollToIndex: getScrollToIndex(props.history),
      iPad: is_iPad || is_iPhone,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.list.forceUpdateGrid()
  }

  onViewPlanClick = (bedId, planId, visitId, index) => {
    saveIndexPosition(index, this.props.history)
    this.props.onViewPlanClick(bedId, planId, visitId)
  }

  rowRenderer = ({ key, index, style, isScrolling, isVisible }) => {
    const {
      workspaces,
      onSetPlan,
      onSetDRG,
      onSetWDD,
      onSetPDC,
      onProgressionIndicatorChange,
    } = this.props
    const item = workspaces[index]

    return (
      <div style={{ ...style }} id={index} key={key}>
        <Compliance
          item={item}
          onViewPlanClick={this.onViewPlanClick}
          onProgressionIndicatorChange={onProgressionIndicatorChange}
          onSetPlan={onSetPlan}
          onSetDRG={onSetDRG}
          onSetPDC={onSetPDC}
          onSetWDD={onSetWDD}
          index={index}
        />
      </div>
    )
  }

  render() {
    const { workspaces, onResize } = this.props
    const { scrollToIndex, iPad } = this.state
    const filterContainer =
      document.getElementsByClassName('compliance-view-filter-container')[0]
    const otherHeight = filterContainer ? filterContainer.clientHeight : 0
    const sectionHeight = window.innerHeight - otherHeight - 50 // 50 is AppHeader magic height
    return (
      <div
        className="compliance-section"
        style={{ height: sectionHeight }}>
        <AutoSizer onResize={onResize}>
          {({ height, width }) => (
            <List
              ref={list => (this.list = list)}
              className={iPad ? 'compliance-section-list' : ''}
              height={height}
              width={width}
              rowCount={workspaces.length}
              rowHeight={83}
              rowRenderer={this.rowRenderer}
              overscanRowCount={0}
              scrollToIndex={scrollToIndex}
            />
          )}
        </AutoSizer>
      </div>
    )
  }
}

ComplianceSection.propTypes = {
  workspaces: PropTypes.array.isRequired,
  onViewPlanClick: PropTypes.func,
  onSetPlan: PropTypes.func,
  onSetDRG: PropTypes.func,
  onSetWDD: PropTypes.func,
  onSetPDC: PropTypes.func,
  onProgressionIndicatorChange: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  onResize: PropTypes.func,
}

export { ComplianceSection }
