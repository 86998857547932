import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BarrierIcon from 'mdi-react/GateIcon'
import Stairs from 'mdi-react/StairsIcon'
import moment from 'moment'
import { CodeImage, PatientInfoImage } from '../image/CodeImage'
import { NameFormat } from '../common'
import { getBedRequestStatusName } from '../../util/utils'
import { getPlannedLabel, isJvionEnabled, getConfig, hasEstimatedTransferDateIssue, getTransferLabelAcronym} from '../../util/configs'
import {
  calcForecastWorkingOE,
  calcForecastExcessDays,
} from '../../util/workspace'
import { red400 as Red } from 'material-ui/styles/colors'

import classNames from 'classnames'
import { CountdownTimer } from '../date/CountdownTimer'
import { ProgressionIndicator } from '../plan/ProgressionIndicator'
import { MilestoneSummaryStatusIcon } from '../milestone/MilestoneSummaryStatusIcon'
import { BedUnitLink } from '../common'
import './escalation.scss'

import VectorSummary from '../../plugins/jvion/VectorSummary'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setDetailTabIndex } from '../../ducks/views'
import sortBy from 'lodash.sortby'
import BarrierMessageStatusIcon from '../barrier/BarrierMessageStatusIcon/BarrierMessageStatusIcon'
import {
  APP_DELIVERY,
  EMAIL_DELIVERY,
  NEEDS_REASSIGNMENT_MSG_STATUS,
  UNACKNOWLEDGED_MSG_STATUS
} from '../barrier/BarrierConstants'
import ClockIcon from "mdi-react/ClockIcon";


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setDetailTabIndex,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class PlanHeader extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      setDetailTabIndex: PropTypes.func,
    }),

    capped: PropTypes.bool,
    viewType: PropTypes.oneOf(['EscalationList', 'KPI']).isRequired,
    workspace: PropTypes.shape({
      facility: PropTypes.object,
      department: PropTypes.object,
      bed: PropTypes.object.isRequired,
      visit: PropTypes.object.isRequired,
      plan: PropTypes.object.isRequired,
    }),
    routeTo: PropTypes.func.isRequired,
    onNavigate: PropTypes.func,
  }

  handleBedNameClick = () => {
    const {
      workspace: { visit, bed, plan, department },
      routeTo,
      onNavigate,
      actions: { setDetailTabIndex },
    } = this.props

    onNavigate && onNavigate(`${bed.name}-${department.name}`)
    setDetailTabIndex(0)
    routeTo(`/bed/${encodeURIComponent('Bed:' + bed.exchangeName.replace(/%/g, '%25'))}`, {  // Had to manually encode % to get it to show correctly in URL
      visitId: visit.id,
      bedId: bed.exchangeName,
      planId: plan.id,
    })
  }

  renderRow1 = () => {
    const { workspace, capped} = this.props
    const { visit, bed, plan, department } = workspace

    const patientProblem =
      visit.patientProblem ||
      (visit.drgAttributes && visit.drgAttributes.title) ||
      visit.admittingComplaint ||
      ''

    const oeThresholdCaution =
      Number(getConfig('ca.oe.threshold.caution.double'))

    const oeThresholdDanger =
      Number(getConfig('ca.oe.threshold.danger.double'))

    const inpatient = visit.admissionType === 'InPatient'
    const forecastWorkingOE = calcForecastWorkingOE(capped, workspace)
    const unknownOE = forecastWorkingOE === '?'
    const onTrackOE = forecastWorkingOE <= oeThresholdCaution
    const cautiousOE = !onTrackOE && forecastWorkingOE < oeThresholdDanger
    const dangerOE = forecastWorkingOE >= oeThresholdDanger

    const forecastExcessDays = calcForecastExcessDays(capped, workspace)

    const forecastExcessDaysOut =
      inpatient && visit.tlos && forecastExcessDays > 0
        ? `(+ ${forecastExcessDays})`
        : ''

    const forcastWorkingOEOut =
      inpatient && visit.tlos ? (
        <span
          className={classNames('OE', {
            unknownOE: unknownOE,
            onTrackOE: onTrackOE,
            cautiousOE: cautiousOE,
            dangerOE: dangerOE,
          })}>
          {`O/E ${forecastWorkingOE}`}
        </span>
      ) : (
        ''
      )

    const jvionOut = isJvionEnabled() ? (
      <span style={{ marginLeft: '6px' }}>
        <VectorSummary
          bedId={bed.exchangeName}
          planId={plan.id}
          visitId={visit.id}
          onClick={this.handleBedNameClick}
          patientVector={visit.vector ? visit.vector : null}
        />
      </span>
    ) : (
      ''
    )

    const isAdmit = department.type === 'AdmissionSource'

    const toAdmitOut = (brStatus, toBeAdmitted) => {
      let result = ''
      // bedRequestStatus will be null if not set for AdmissionSource bed
      if (brStatus && brStatus !== 'Cancelled') {
        result = getBedRequestStatusName(brStatus)
      } else {
        result =
          toBeAdmitted === null
            ? 'Not Set'
            : toBeAdmitted
            ? 'To Be Admitted'
            : 'No Admission'
      }
      return result
    }

    const getBedRequestStatus = (bed, bedRequests) => {
      const match = bedRequests.find(br => br.bedExchangeName === bed.exchangeName)
      return match ? match.bedRequestStatus : ''
    }

    const matchedBedRequestStatus = getBedRequestStatus(bed, visit.bedRequests)

    return (
      <div className={classNames('row')}>
        <BedUnitLink
          bed={bed}
          unit={department.name}
          planId={plan.id}
          visitId={visit.id}
          onClick={this.handleBedNameClick}
          extraClasses="col-xs-3"
        />
        <div className={classNames('col-xs-3', 'text')}>
          <ProgressionIndicator
            value={plan.progressionIndicator}
            style={{
              width: '20px',
              height: '20px',
            }}
          />
          <span style={{ verticalAlign: 3 }}>
            {isAdmit
              ? toAdmitOut(
                ((visit.bedRequests &&
                visit.bedRequests.length > 0 &&
                visit.bedRequests[0].bedRequestStatus) || ''),
                plan.toBeAdmitted
              )
              : getBedRequestStatusName(matchedBedRequestStatus)}
          </span>
        </div>

        <div className={classNames('col-xs-3', 'text')}>{patientProblem}</div>

        <div className={classNames('col-xs-3', 'col4')}>
          {forecastExcessDaysOut}
          {forcastWorkingOEOut}
          {jvionOut}
        </div>
      </div>
    )
  }

  renderRow3 = () => {
    const {
      viewType,
      workspace: { visit, plan },
    } = this.props

    const insurances = sortBy(visit.insurancePlans, ['priority'])
    const noInsurances = insurances.length === 0
    // CA-1148
    const primaryInsurance = insurances[0]
    const secondaryInsurance = insurances[1]

    const displayFormat = `[${getPlannedLabel()} D/C: ]MMM D ${visit.anticipatedDischargeTimeAutoPopulated?'':'HH:mm'}`

    const dischargeTime = visit.anticipatedDischargeTime
      ? moment(visit.anticipatedDischargeTime).format(displayFormat)
      : `No ${getPlannedLabel()} D/C`
    const dischargeTimeInPast = moment(visit.anticipatedDischargeTime).isBefore(
      moment()
    )

    const systemSetDischargeTime = visit.anticipatedDischargeTime && !visit.anticipatedDischargeUpdatedBy

    const inpatient = visit.admissionType === 'InPatient'
    const drgCode =
      visit.drgAttributes && visit.drgAttributes.code
        ? `${visit.drgAttributes.code} ${visit.drgAttributes.level}`
        : `No DRG  `
    const systemSetDRG =
      inpatient &&
      visit.drgAttributes &&
      visit.drgAttributes.code &&
      !visit.drgUpdatedBy

    const drgInfo = inpatient ? (
      <span style={{ verticalAlign: 3 }}>
        {drgCode}
        {systemSetDRG ? '(External System)' : ''}
      </span>
    ) : (
      <CodeImage type="AdmissionType" code={visit.admissionType} />
    )

    const noPlan = plan.milestones.length === 0

    const planInfo = noPlan ? (
      <span style={{ verticalAlign: 3 }}>{`  No Plan`}</span>
    ) : (
      <MilestoneSummaryStatusIcon
        milestoneStatuses={{
          onTime: plan.meta.milestoneMeta.onTime,
          overdue: plan.meta.milestoneMeta.overdue,
          completed: plan.meta.milestoneMeta.completed,
        }}
        className="milestoneSummaryIcon"
      />
    )

    const kpiView = viewType === 'KPI'

    const insuranceInfo = (
      <div>
        {noInsurances && 'No Insurance Plan(s) '}
        {primaryInsurance && primaryInsurance.planName}
        {secondaryInsurance && ` | ${secondaryInsurance.planName}`}
      </div>
    )

    const getOwnerMessageDeliveryMethod = (barrier) => {
      if(barrier.ownerKnownUser) {
        if(barrier.ownerKnownUser.instantMessageReceiver) {
          return APP_DELIVERY
        }
        if(barrier.ownerKnownUser.emailReceiver) {
          return EMAIL_DELIVERY
        }
      }
      return null
    }

    const needsReassignmentBarrierCount = plan.barriers.filter(b => b.status === 'Open' && b.ownerMessageStatus === NEEDS_REASSIGNMENT_MSG_STATUS && getOwnerMessageDeliveryMethod(b) === APP_DELIVERY)
      .length
    const unacknowledgedBarrierCount = plan.barriers.filter(b => b.status === 'Open' && b.ownerMessageStatus === UNACKNOWLEDGED_MSG_STATUS && getOwnerMessageDeliveryMethod(b) === APP_DELIVERY)
      .length
    const openBarriersCount = plan.barriers.filter(b => b.status === 'Open')
      .length
    const escalatedBarriersCount = plan.barriers.filter(
      b => b.escalation !== null
    ).length

    const dischargeDateInPast = visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).isBefore( visit.anticipatedDischargeTimeAutoPopulated ? moment().startOf('day') : moment() )
    const dischargeDateWithinTwoDays = visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).subtract(2, 'd').startOf('day').isBefore(moment())

    const barrierInfo = (
      <div className="iconSection">
        { unacknowledgedBarrierCount > 0 ? (
          <BarrierMessageStatusIcon
            messageStatus={UNACKNOWLEDGED_MSG_STATUS}
            messageStatusCount={unacknowledgedBarrierCount}
          />
        ) : (
          ''
        )}
        { needsReassignmentBarrierCount > 0 ? (
          <BarrierMessageStatusIcon
            messageStatus={NEEDS_REASSIGNMENT_MSG_STATUS}
            messageStatusCount={needsReassignmentBarrierCount}
          />
        ) : (
          ''
        )}
        {escalatedBarriersCount > 0 ? (
          <span className="iconWrapper">
            <Stairs className="escalationIcon" style={{ fill: Red }} />{' '}
            {escalatedBarriersCount}
          </span>
        ) : (
          ''
        )}
        <BarrierIcon className="barrierIcon" />
        {openBarriersCount}
      </div>
    )

    return (
      <div className={classNames('row')}>
        <div className={classNames('col-xs-3', 'text')}>
          {drgInfo}
          {planInfo}
        </div>
        <div
          className={classNames('col-xs-3', 'row3', {
            pastDischarge: dischargeTimeInPast,
          })}>
          {!dischargeDateInPast && dischargeDateWithinTwoDays && <ClockIcon className="clockIcon"/>}
          {dischargeTime}
          {systemSetDischargeTime ? ' (External System)' : ''}
        </div>
        <div className={classNames('col-xs-3', 'text')}>
          {visit.dischargeVendor || 'No Note'}
        </div>
        <div className={classNames('col-xs-3', 'text', 'col4')}>
          {kpiView ? barrierInfo : insuranceInfo}
        </div>
      </div>
    )
  }

  renderRow2 = () => {
    const {
      workspace: { visit, plan },
    } = this.props

    const physician = visit.attendingPhysician
    const physicianName =
      physician && `${physician.lastName}, ${physician.firstName}`

    const dischargeDisposition =
      visit.workingDischargeDisposition.code === 'NotSet'
        ? 'No D/C Arrangements'
        : visit.workingDischargeDisposition.value

    const inpatient = visit.admissionType === 'InPatient'

    const currentTime = moment()
    let displayFormat = `[${getTransferLabelAcronym()}: ]MMM D`
    if(!visit.anticipatedTransferTimeAutoPopulated){
      displayFormat += ` HH:mm`
    }
    const estimatedTransferTime = visit.anticipatedTransferTime
      ? moment(visit.anticipatedTransferTime).format(displayFormat)
      : `No ${getTransferLabelAcronym()}`

    const currentDayOfStay = visit.currentDayOfStay ? (
      <span>
        <span
          className={classNames({
            overtime: visit.currentDayOfStay >= visit.tlos,
          })}>
          {`Day ${visit.currentDayOfStay} of ${visit.tlos || '?'}`}
        </span>
      </span>
    ) : (
      'Not Admitted'
    )

    const decisionDue = visit.admissionTime && visit.decisionTime ? (
      <span>
        Decision Due: <CountdownTimer date={visit.decisionTime} />
      </span>
    ) : (
      'Decision Time Not Set'
    )

    return (
      <div className={classNames('row', 'row2')}>
        <div className={classNames('col-xs-3', 'text')}>
          <PatientInfoImage
            type="Gender"
            code={visit.patient.gender || 'nil'}
          />
          {`${visit.patient.age ? visit.patient.age : ''} `}
          <NameFormat person={visit.patient} format="lfm" />
        </div>
        <div className={classNames('col-xs-3', 'text')}>
          <span>
            {inpatient ? currentDayOfStay : decisionDue}
            <span style={{position: 'absolute'}} className={classNames('col-xs-3', {
                pastTransfer: hasEstimatedTransferDateIssue(visit),
              })}>
              {estimatedTransferTime}
            </span>
          </span>
        </div>
        <div className={classNames('col-xs-3', 'text')}>
          {dischargeDisposition}
        </div>
        <div className={classNames('col-xs-3', 'text', 'col4')}>
          {physicianName || 'No Attending Physician'}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className="plan-header"
        style={{ padding: '12px', backgroundColor: 'white' }}>
        {this.renderRow1()}
        {this.renderRow2()}
        {this.renderRow3()}
      </div>
    )
  }
}
