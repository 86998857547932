import React from 'react'

import DialogTitle from '@material-ui/core/DialogTitle'
import MuiDialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Dialog = (props) => {
  const {
    open,
    title,
    onClose,
    children
  } = props

  const handleModalClose = (event, reason) => {
    event.stopPropagation()
    if (reason === 'escapeKeyDown') {
      onClose()
    }
  }

  return (
    <MuiDialog
      open={open}
      onClose={handleModalClose}
      onClick={(event) => event.stopPropagation()}
      fullWidth>
      <StyledDialogTitle disableTypography>
        <StyledTitleText variant="h5">{title}</StyledTitleText>
        <StyledCloseButton onClick={onClose}>
          <DialogCloseIcon/>
        </StyledCloseButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
    </MuiDialog>
  )
}

const StyledDialogTitle = styled(DialogTitle)`
  margin-top: 10px;
`

const StyledTitleText = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #4A4A4A;
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

const DialogCloseIcon = styled(CloseIcon)`
  font-size: 24px;
  color: #4A4A4A;
`

const StyledDialogContent = styled(DialogContent)`
  margin-bottom: 20px;
`

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element
}

export default Dialog
