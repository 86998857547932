import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { has } from 'lodash'
import TextField from '../../../common/TextField'
import KnownPersonnelTypeAhead from '../../knownPersonnelTypeAhead/KnownPersonnelTypeAhead'
import {
  BARRIER_SOURCE_ROLE,
  buildKnownUserInput,
  searchKnownUsers,
} from '../../../../graphql/relay/queries/knownUser'
import { fetchQuery } from 'react-relay'
import { environment } from '../../../../graphql/relay/env'
import Autocomplete from '@material-ui/lab/Autocomplete'

class BarrierSourceDetailsInput extends Component {
  constructor(props) {
    super(props)
    this.state={
      selectedKnownPersonnel: '',
      selectedConfigValue: '',
      selectedSourceName: ''
    }
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.barrierSourceType?.code !== nextProps.barrierSourceType?.code) {
      this.ResetSelection()
    }
    return true
  }

  ResetSelection = () => {
   this.setState({
     selectedKnownPersonnel: '',
     selectedConfigValue: '',
     selectedSourceName: ''
   })
  }

  // -------------------------
  // AD Name Lookup
  // -------------------------
  renderAdLookUp = () => {
    const { onSelect, barrierSourceType } = this.props
    const { selectedKnownPersonnel } = this.state

    const searchKnownPersonnel = async (value) => {
      const knownUserInput = buildKnownUserInput({ searchText: value, roles: [BARRIER_SOURCE_ROLE] })
      const response = await fetchQuery(environment, searchKnownUsers, { knownUserInput })
      return response.knownUsers
    }

    const onKnownPersonnelSelected = (name, knownUser) => {
      // console.log('onKnownPersonnelSelected:', knownUser)
      this.setState({selectedKnownPersonnel: name})
      if (typeof onSelect === 'function') {
        onSelect(name)
      }
    }

    return (
      <KnownPersonnelTypeAhead
        label="Barrier Source Name (Optional)"
        variant="outlined"
        size="small"
        selectedValue={selectedKnownPersonnel}
        isShowIrisIcon={false}
        onSearchKnownPersonnel={searchKnownPersonnel}
        onResultSelected={onKnownPersonnelSelected} />
    )
  }

  // -------------------------
  // Config Value Selection
  // -------------------------
  renderConfigValueSelection = () => {
    const { onSelect, barrierSourceType } = this.props
    const { selectedConfigValue } = this.state

    const onSourceConfigChanged = (event, sourceDetail) => {
      const selectedValue = sourceDetail.code
      // console.log('onSourceConfigChanged:', selectedValue)
      this.setState({selectedConfigValue: selectedValue})
      if (typeof onSelect === 'function') {
        onSelect(selectedValue)
      }
    }

    return (
      <Autocomplete
        size="small"
        required
        autoComplete
        // autoSelect   // disabling a Tab selection
        autoHighlight
        clearOnEscape
        openOnFocus
        options={barrierSourceType.sourceDetails.filter(item => item.active)}
        getOptionLabel={(option) => option.value}
        onChange={onSourceConfigChanged}
        renderInput={(params) =>
          <TextField
            {...params}
            label="Barrier Source Details (Optional)"
            variant="outlined"
          />}
      />
    )
  }

  // -------------------------
  // Free Text Input
  // -------------------------
  renderTextField = () => {
    const { onSelect } = this.props
    const { selectedSourceName } = this.state

    const onSourceNameChange = (value) => {
      // console.log('onSourceNameChange:', value)
      this.setState({selectedSourceName: value})
      if (typeof onSelect === 'function') {
        onSelect(value)
      }
    }

    return (
      <TextField
        label="Barrier Source Details (Optional)"
        value={selectedSourceName}
        onEnter={onSourceNameChange}
        variant="outlined"
        size="small"
        maxChars={140}
        trackDirty
        showCharacterCount
        fullWidth
      />
    )
  }

  // -------------------------
  // Placeholder TextField Display
  // -------------------------
  renderPlaceHolder = () => (
    <TextField
      variant="outlined"
      label="Barrier Source Details (Optional)"
      value="- Please select a source type first -"
      size="small"
      fullWidth
      style={{opacity: 0.5}}
      inputProps={
        { readOnly: true }
      }
    />
  )


  render() {
    const { className, barrierSourceType } = this.props
    const renderBarrierDetailsInput = () => {
      debugger
      if (!has(barrierSourceType, 'code')) {
        return this.renderPlaceHolder()
      } else if (barrierSourceType.activeDirectorySourceName) {
        return this.renderAdLookUp()
      } else if (barrierSourceType.hasConfiguredList) {
        return this.renderConfigValueSelection()
      } else {
        return this.renderTextField()
      }
    }

    return (
      <div className={className}>
        {renderBarrierDetailsInput()}
      </div>
    )
  }
}

BarrierSourceDetailsInput.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  barrierSourceType: PropTypes.shape({
    code: PropTypes.string,
    sourceDetails: PropTypes.array,
    activeDirectorySourceName: PropTypes.bool,
    hasConfiguredList: PropTypes.bool
  }),
}

export default BarrierSourceDetailsInput
