import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ToDoTable from './todoTable/ToDoTable'
import AddToDo from './addToDo/AddToDo'
import moment from 'moment'

import { updateActionStatus } from '../../graphql/relay/queries/action'
import { handleMutation } from '../../graphql/relay/queries/mutation'

import { environment } from '../../graphql/relay/env'
import MilestoneActionsHeader from './todoTable/MilestoneActionsHeader'

class CombinedMilestoneActionSection extends Component {

  onUpdateActionStatus = (actionId, status) => {
    const variables = { actionId: actionId, status: status }
    handleMutation(environment, updateActionStatus, variables)
  }

  prepareMilestoneActions = (milestones) => {
    return milestones
      .map(milestone => ({
        ...milestone,
        actions: milestone.actions.map(action => ({
          ...action,
          track: milestone.track,
          dueDate: milestone.dueDate ? moment(milestone.dueDate) : null,
          milestoneName: milestone.name,
          planName: milestone.planTemplateName
        }))
      }))
      .reduce((acc, m) => acc.concat(m.actions), [])
  }

  prepareToDoActions = (actions) => {
    return actions.map(action => ({
      ...action,
      track: 'T',
      dueDate: moment(action.auditMeta.created),
    }))
  }

  mergeAndSortMilestonesAndToDos = (milestones, actions) => {
    return milestones
      .concat(actions)
      .sort((a, b) => {
        const initDateSortGranularity = this.props.visit.admissionType === 'Observation' ? 'hour' : 'day'
        const aDueDate = a.dueDate || moment() // Handle null due dates so they all sort together
        const bDueDate = b.dueDate || moment()
        const aTrack = a.track
        const bTrack = b.track
        const aText = a.text
        const bText = b.text

        // Sort order:
        //    1. Due Date Day (no time)
        //    2. Track (Discharge, Progression, user entered To Do)
        //    3. Due Date with time
        //    4. To do text
        if (aDueDate.isBefore(bDueDate, initDateSortGranularity)) return -1
        if (aDueDate.isAfter(bDueDate, initDateSortGranularity)) return 1
        if (aTrack < bTrack) return -1
        if (aTrack > bTrack) return 1
        if (aDueDate.isBefore(bDueDate)) return -1
        if (aDueDate.isAfter(bDueDate)) return 1
        if (aText < bText) return -1
        if (aText > bText) return 1
        return 0
      })
  }

  filterActions = (todos) => {
    const { dueDate, track, status } = this.props.filter

    const todayTime = moment()

    // Filter on due dates
    let dueDateFilteredArr
    if (dueDate === 'Current') {
      const filterOutNATimeSpan = moment().subtract(90, 'seconds')
      dueDateFilteredArr = todos.filter(action => {
        const dueDateTime = action.dueDate
        const updatedTime = moment(action.auditMeta.updated)

        return (!dueDateTime || // Always show empty due dates
          (action.status === 'NA' && updatedTime.isAfter(filterOutNATimeSpan)) || // Show N/A Status if they were acted on in time period defined above
          (dueDateTime.isSame(todayTime, 'day') && action.status !== 'NA') || // Show Due Today if not N/A
          (dueDateTime.isBefore(todayTime, 'day') && action.status === 'Open') || // Show Before today if Open Status
          (dueDateTime.isBefore(todayTime, 'day') && action.status === 'Completed' && moment(action.auditMeta.updated).isSame(todayTime, 'day'))) // Show before today if Completed Status today
      })
    } else if (dueDate === 'Future') {
      dueDateFilteredArr = todos.filter(action => !action.dueDate || action.dueDate.isAfter(todayTime))
    } else {
      dueDateFilteredArr = todos
    }

    let trackFilteredArr = dueDateFilteredArr
    if (track === 'Discharge') {
      trackFilteredArr = trackFilteredArr.filter(action => action.track === 'Discharge')
    } else if (track === 'Progression') {
      trackFilteredArr = trackFilteredArr.filter(action => action.track === 'Progression')
    } else if (track === 'User') {
      trackFilteredArr = trackFilteredArr.filter(action => action.track === 'T')
    } else {
      trackFilteredArr = dueDateFilteredArr
    }

    let statusFilteredArr = trackFilteredArr
    if (status === 'Open') {
      statusFilteredArr = statusFilteredArr.filter(action => action.status === 'Open')
    } else if (status === 'NA') {
      statusFilteredArr = statusFilteredArr.filter(action => action.status === 'NA')
    } else if (status === 'Completed') {
      statusFilteredArr = statusFilteredArr.filter(action => action.status === 'Completed')
    } else {
      statusFilteredArr = trackFilteredArr
    }

    return statusFilteredArr
  }

  produceSingleToDoList = (milestones, actions) => {
    let newMilestones = this.prepareMilestoneActions(milestones)
    let newActions = this.prepareToDoActions(actions)
    let combinedMilestonesAndToDos = this.mergeAndSortMilestonesAndToDos(newMilestones, newActions)
    return combinedMilestonesAndToDos
  }

  render() {
    const {
      plan,
      visit,
      onApplyPlan,
      onRemovePlan,
      filter,
      draftFilter,
      setDraftFilter,
      setFilter,
      resetFilter,
      plan: { milestones, actions },
      selectedItem,
      onCreatePlanAction,
      onTodoTextClick,
      onUpdateActionText,
      onRemoveAction,
      onUpdatePinned,
      userActions
    } = this.props

    const todos = this.produceSingleToDoList(milestones, actions)
    const filteredActions = this.filterActions(todos)

    const appliedTemplatesWithActions = plan.appliedTemplates.filter(template =>
      todos
        .filter(todo => todo.planName === template.name)
        .length > 0
    )

    return (
      <div>
        <MilestoneActionsHeader
          appliedTemplates={appliedTemplatesWithActions || []}
          admissionType={visit.admissionType}
          admissionTime={visit.admissionTime}
          targetDischargeTime={visit.targetDischargeTime}
          decisionTime={visit.decisionTime}
          plannedDischargeTime={visit.anticipatedDischargeTime}
          showTimesOnHeader
          windowSize={5}
          startingPosition={visit.currentDayOfStay}
          onApplyPlan={onApplyPlan}
          onRemovePlan={onRemovePlan}
          filter={filter}
          applyFilter={setFilter}
          resetFilter={resetFilter}
          draftFilter={draftFilter}
          applyDraftFilter={setDraftFilter}
          userActions={userActions}
          onCreatePlanAction={onCreatePlanAction}
        />
        <ToDoTable
          todos={filteredActions}
          onUpdateActionStatus={(id, newValue) => this.onUpdateActionStatus(id, newValue)}
          selectedItem={selectedItem}
          onTodoTextClick={onTodoTextClick}
          onUpdateActionText={onUpdateActionText}
          onRemoveAction={onRemoveAction}
          admissionType={visit.admissionType}
          onUpdatePinned={onUpdatePinned}
          hasPlan={plan.appliedTemplates && plan.appliedTemplates.length > 0}
          hasManualToDos={actions.length > 0}
          appliedTemplates={appliedTemplatesWithActions || []}
        />
        <AddToDo onCreatePlanAction={onCreatePlanAction} />
      </div>
    )
  }
}

CombinedMilestoneActionSection.propTypes = {
  plan: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  onApplyPlan: PropTypes.func.isRequired,
  onRemovePlan: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  draftFilter: PropTypes.object.isRequired,
  setDraftFilter: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  selectedItem: PropTypes.object.isRequired,
  onCreatePlanAction: PropTypes.func.isRequired,
  onTodoTextClick: PropTypes.func,
  onUpdateActionText: PropTypes.func,
  onRemoveAction: PropTypes.func,
  onUpdatePinned: PropTypes.func,
  userActions: PropTypes.array
}

export default CombinedMilestoneActionSection
