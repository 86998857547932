/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeliveryMethod = "EMAIL" | "RESOLVE" | "%future added value";
export type MessageSubType = "BARRIER_ACKNOWLEDGED" | "BARRIER_INFO_SENT_RECIPIENT_NOTIFIED" | "BARRIER_NOTE_ADDED" | "BARRIER_OWNER_ASSIGNED_OWNER_NOTIFIED" | "BARRIER_REASSIGNMENT_NEEDED" | "BARRIER_REOPENED" | "BARRIER_RESOLVED" | "%future added value";
export type barrier_barrierHistory_QueryVariables = {|
  barrierId: string,
  onlySentBarrierInfo?: ?boolean,
|};
export type barrier_barrierHistory_QueryResponse = {|
  +node: ?{|
    +id?: string,
    +barrierHistory?: $ReadOnlyArray<{|
      +id: string,
      +timeSent: any,
      +senderFirstName: string,
      +senderLastName: string,
      +recipientFirstName: string,
      +recipientLastName: string,
      +note: ?string,
      +requestDirection: string,
      +messageSubType: MessageSubType,
      +deliveryMethod: ?DeliveryMethod,
    |}>,
  |}
|};
export type barrier_barrierHistory_Query = {|
  variables: barrier_barrierHistory_QueryVariables,
  response: barrier_barrierHistory_QueryResponse,
|};
*/


/*
query barrier_barrierHistory_Query(
  $barrierId: ID!
  $onlySentBarrierInfo: Boolean
) {
  node(id: $barrierId) {
    __typename
    ... on Barrier {
      id
      barrierHistory(onlySentBarrierInfo: $onlySentBarrierInfo) {
        id
        timeSent
        senderFirstName
        senderLastName
        recipientFirstName
        recipientLastName
        note
        requestDirection
        messageSubType
        deliveryMethod
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "onlySentBarrierInfo"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "barrierId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "onlySentBarrierInfo",
      "variableName": "onlySentBarrierInfo"
    }
  ],
  "concreteType": "BarrierHistoryItem",
  "kind": "LinkedField",
  "name": "barrierHistory",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeSent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestDirection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messageSubType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deliveryMethod",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "barrier_barrierHistory_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "type": "Barrier",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "barrier_barrierHistory_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/)
            ],
            "type": "Barrier",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2bedf5f9e74912755636e08dc59642c",
    "id": null,
    "metadata": {},
    "name": "barrier_barrierHistory_Query",
    "operationKind": "query",
    "text": "query barrier_barrierHistory_Query(\n  $barrierId: ID!\n  $onlySentBarrierInfo: Boolean\n) {\n  node(id: $barrierId) {\n    __typename\n    ... on Barrier {\n      id\n      barrierHistory(onlySentBarrierInfo: $onlySentBarrierInfo) {\n        id\n        timeSent\n        senderFirstName\n        senderLastName\n        recipientFirstName\n        recipientLastName\n        note\n        requestDirection\n        messageSubType\n        deliveryMethod\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4a6c701bcdfde07a57e7b5d6d7e32923';

module.exports = node;
