import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AlertImage, CodeImage, EmptyImage } from './image/CodeImage'
import { getAlertsFor } from "../util/configs"

export class Alerts extends Component {
  static propTypes = {
    visit: PropTypes.object.isRequired,
    unitName: PropTypes.string.isRequired,
  }

  findHighestIso = () => {
    const alertCodes = [
      'NeutropenicIsolation',
      'AirborneIsolation',
      'DropletIsolation',
      'EntericIsolation',
      'ContactIsolation',
      'MdrIsolation',
    ]
    const { patientAlerts } = this.props.visit

    //Iterate from the highest isolation alert to the lowest
    for (let i = 0; i < alertCodes.length; i++) {
      const alert = patientAlerts.find(
        curAlert => curAlert.alertType === alertCodes[i]
      )
      if (alert && alert.value === 'Yes') {
        return (
          <AlertImage
            key={alert.alertType}
            type={alert.alertType}
            code={alert.value}
          />
        )
      }
    }

    // none of the items in alertCodes were found, now check for an IsolationOrganism
    const alert = patientAlerts.find(
      curAlert => curAlert.alertType === 'IsolationOrganism'
    )
    if (alert && alert.value !== 'None') {
      return (
        <AlertImage
          key="IsolationOrganism"
          type="IsolationOrganism"
          code="Yes"
        />
      )
    }

    return null
  }

  getIconComponentForConfig = config => {
    const {
      patientAlerts,
      readmission,
      readmissionRisk,
      patient,
    } = this.props.visit
    let result = undefined

    const configSplit = config.split(':')
    const iconType = configSplit[0] ? configSplit[0].trim() : ''
    const visibleValues = configSplit[1] ? configSplit[1].trim() : ''

    let iconComp = undefined,
      alert
    switch (iconType) {
      case 'InterpreterNeeded':
        iconComp = (
          <CodeImage
            key="InterpreterNeeded"
            type="InterpreterNeeded"
            code={patient.language.code === null ||
            patient.language.code === patient.language.defaultValue ? 'No' : 'Yes'}
         />
        )
        break

      case 'HighestIsolationAlert':
        iconComp = this.findHighestIso()
        break

      case 'ReadmissionRisk':
        iconComp = (
          <CodeImage
            key="ReadmissionRisk"
            type="ReadmissionRisk"
            code={readmissionRisk ? 'Yes' : 'No'}
          />
        )
        break

      case 'Readmission':
        iconComp = (
          <CodeImage
            key="Readmission"
            type="Readmission"
            code={readmission ? 'Yes' : 'No'}
          />
        )
        break

      // if we get here it's not "special" just a regular alert that should be in patientAlerts
      default:
        alert = patientAlerts.find(alert => alert.alertType === iconType)
        if (alert) {
          iconComp = (
            <AlertImage
              key={alert.alertType}
              type={alert.alertType}
              code={alert.value}
            />
          )
        }
    }

    if (visibleValues) {
      // config used colon to list values to display for, won't take a slot if doesn't match one of visibleValues
      if (iconComp && visibleValues.indexOf(iconComp.props.code) !== -1) {
        result = iconComp
      }
    } else {
      // use slot even if it displays blank image
      if (!iconComp) {
        // blank image with no tooltip - probably an alert not set in the visit
        result = <EmptyImage />
      } else {
        result = iconComp
      }
    }

    return result
  }

  getDisplayedIconComponents = () => {
    const { unitName } = this.props
    // find config for unit
    const configArray = getAlertsFor(unitName)

    return configArray.reduce((acc, config) => {
      let iconComp = this.getIconComponentForConfig(config)
      if (iconComp) {
        acc.push(iconComp)
      }
      return acc
    }, [])
  }

  render() {
    return (
      <div style={{height: '40px', display: 'table-cell', verticalAlign: 'middle', float: 'right'}}>
         {this.getDisplayedIconComponents()}
      </div>
    )
  }
}
