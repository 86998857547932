/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type pin_FullPlanNote$ref = any;
export type pin_pinPlanNote_MutationVariables = {|
  itemId: any
|};
export type pin_pinPlanNote_MutationResponse = {|
  +pinMutation: ?{|
    +pin: ?{|
      +id: string,
      +notes: $ReadOnlyArray<?{|
        +id: string,
        +pinned: boolean,
        +$fragmentRefs: pin_FullPlanNote$ref,
      |}>,
    |}
  |}
|};
export type pin_pinPlanNote_Mutation = {|
  variables: pin_pinPlanNote_MutationVariables,
  response: pin_pinPlanNote_MutationResponse,
|};
*/


/*
mutation pin_pinPlanNote_Mutation(
  $itemId: UUID!
) {
  pinMutation {
    pin(itemId: $itemId, itemType: PlanNote) {
      id
      notes {
        id
        pinned
        ...pin_FullPlanNote
      }
    }
  }
}

fragment pin_FullPlanNote on PlanNote {
  id
  text
  auditMeta {
    created
    createdBy {
      userName
      firstName
      lastName
      id
    }
    updated
    updatedBy {
      userName
      firstName
      lastName
      id
    }
  }
  pinned
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "itemId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "itemId",
    "variableName": "itemId"
  },
  {
    "kind": "Literal",
    "name": "itemType",
    "value": "PlanNote"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "pin_pinPlanNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PinMutation",
        "kind": "LinkedField",
        "name": "pinMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "pin",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "pin_FullPlanNote"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "pin_pinPlanNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PinMutation",
        "kind": "LinkedField",
        "name": "pinMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "pin",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "updatedBy",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b0b5463949b1c8f80d11d335466a200",
    "id": null,
    "metadata": {},
    "name": "pin_pinPlanNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation pin_pinPlanNote_Mutation(\n  $itemId: UUID!\n) {\n  pinMutation {\n    pin(itemId: $itemId, itemType: PlanNote) {\n      id\n      notes {\n        id\n        pinned\n        ...pin_FullPlanNote\n      }\n    }\n  }\n}\n\nfragment pin_FullPlanNote on PlanNote {\n  id\n  text\n  auditMeta {\n    created\n    createdBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n    updated\n    updatedBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n  }\n  pinned\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5fc3b8fc2ad5b4a4942e45b50f44c391';

module.exports = node;
