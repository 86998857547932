/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BarrierSourceTypeTable_barrierSourceTypeConfigs$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BarrierSourceTypeEditor_barrierSourceTypeConfigs$ref: FragmentReference;
declare export opaque type BarrierSourceTypeEditor_barrierSourceTypeConfigs$fragmentType: BarrierSourceTypeEditor_barrierSourceTypeConfigs$ref;
export type BarrierSourceTypeEditor_barrierSourceTypeConfigs = $ReadOnlyArray<{|
  +ordinal: number,
  +$fragmentRefs: BarrierSourceTypeTable_barrierSourceTypeConfigs$ref,
  +$refType: BarrierSourceTypeEditor_barrierSourceTypeConfigs$ref,
|}>;
export type BarrierSourceTypeEditor_barrierSourceTypeConfigs$data = BarrierSourceTypeEditor_barrierSourceTypeConfigs;
export type BarrierSourceTypeEditor_barrierSourceTypeConfigs$key = $ReadOnlyArray<{
  +$data?: BarrierSourceTypeEditor_barrierSourceTypeConfigs$data,
  +$fragmentRefs: BarrierSourceTypeEditor_barrierSourceTypeConfigs$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BarrierSourceTypeEditor_barrierSourceTypeConfigs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BarrierSourceTypeTable_barrierSourceTypeConfigs"
    }
  ],
  "type": "BarrierSourceType",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '58951d2934e69f3e3a0b21b0b4b50721';

module.exports = node;
