import React from 'react'
import PropTypes from 'prop-types'

import './escalation.scss'

import Checkbox from 'material-ui/Checkbox'
import Subheader from 'material-ui/Subheader'

const FacilityFilter = ({ onChange, currentUnits, facilities}) => {

  const checkboxes = values => {
    return values.map((facility, index) => {
      if(!facility.name || getAllUnitByFacility(facility.name)?.length < 1){
        return
      }
      let facilityChecked = hasUnitCheckedForFacility(facility.name)

      return (<Checkbox
        key={facility.name + '_' + index}
        onClick={() => onCheckChange(facility.name, !facilityChecked)}
        style={{width: '150px'}}
        inputStyle={{marginRight: '0px'}}
        labelStyle={{marginLeft: '0px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
        label={facility.name}
        checked={facilityChecked}
      />)
    })
  }

  const onCheckChange = (name, checked) => {
    let unitNames = getAllUnitByFacility(name)

    let newUnits = currentUnits.map(unit => {
      if ( unitNames.includes(unit.name) ) {
        return { ...unit, checked: checked }
      }
      return {...unit}
    })

    // now ensure All item 0 reflects all other items
    newUnits[0].checked = newUnits.slice(1).every(item => item.checked)

    onChange(newUnits)
  }

  const getAllUnitByFacility = (name) => {
    if(!name){
      return
    }
    let selectedFacilty = facilities.find(facility => facility.name === name),
    facilityUnits = selectedFacilty?.departments?.nodes,
    unitNames = facilityUnits.map(element => element.name) || []

    return unitNames
  }

  const hasUnitCheckedForFacility = (name) => {
    let unitNames = getAllUnitByFacility(name)
    return currentUnits.some(unit => {
      return unit.checked && unitNames.includes(unit.name)
    })
  }

  return (
    <div >
      <Subheader className="filter-subheader">Facility</Subheader>
        <div style={{height: 30, flexDirection:"row"}} className="filter-items-units">
              {checkboxes(facilities)}
        </div>
    </div>
  )
}

FacilityFilter.propTypes = {
  onChange: PropTypes.func,
  currentUnits: PropTypes.array,
  facilities: PropTypes.array,
}

export default FacilityFilter
