import { graphql } from 'react-relay'

export const newEntrySourceTypeUpdater = (proxyStore, variables) => {
  const store = proxyStore.getRootField("barrierSourceMutation")
  const payload = store.getLinkedRecord("addBarrierSourceTypeEntry", variables)

  updateStore(proxyStore, variables, payload)
}

export const updateEntrySourceTypeUpdater = (proxyStore, variables) => {
  updateStore(proxyStore, variables)
}

const updateStore = (proxyStore, variables, payload) => {
  const rootStore = proxyStore.get("client:root")
  const barrierSourceTypes = rootStore.getLinkedRecords('barrierSourceTypes')

  updateSourceDetails(barrierSourceTypes)

  const newArray = payload ? [payload, ...barrierSourceTypes] : barrierSourceTypes
  const sortedArray = newArray.sort((a,b) => a.getValue('ordinal') - b.getValue('ordinal'))
  rootStore.setLinkedRecords(sortedArray,'barrierSourceTypes',{barrierCategoryCode: variables.barrierCategoryCode})
}

const updateSourceDetails = (barrierSourceTypes, updatedSourceDetail) => {
  barrierSourceTypes.forEach(sourceType => {
    if (sourceType) {
      const sourceDetailList = sourceType.getLinkedRecords('sourceDetails')
      if (sourceDetailList && sourceDetailList.length > 0) {
        if (updatedSourceDetail) {
          const { code, value, ordinal, active } = updatedSourceDetail
          sourceDetailList.forEach(detail => {
            if (detail.getValue('code') === code) {
              detail.setValue(value, 'value')
              detail.setValue(ordinal, 'ordinal')
              detail.setValue(active, 'active')
            }
          })
        }

        const sortedList = sourceDetailList.sort((a,b) => a.getValue('ordinal') - b.getValue('ordinal'))
        sourceType.setLinkedRecords(sortedList, 'sourceDetails')
      }
    }
  })
}

export const updaterForBarrierDetailInSourceTypes = (proxyStore, barrierDetail) => {
  const rootStore = proxyStore.get("client:root")
  const barrierSourceTypeList = rootStore.getLinkedRecords('barrierSourceTypes')
  updateSourceDetails(barrierSourceTypeList, barrierDetail)
  rootStore.setLinkedRecords(barrierSourceTypeList,'barrierSourceTypes')
}

export const addBarrierSourceTypeEntry = graphql`
  mutation barrierSourceType_addBarrierSourceTypeEntry_Mutation(
    $barrierSourceTypeInput: BarrierSourceTypeInput!
  ) {
    barrierSourceMutation {
      addBarrierSourceTypeEntry(barrierSourceTypeInput: $barrierSourceTypeInput) {
        id
        code
        value
        ordinal
        active
        activeDirectorySourceName
        hasConfiguredList
        sourceDetails {
          code
          value
          active
          ordinal
        }
      }
    }
  }
`

export const modifyBarrierSourceTypeEntry = graphql`
  mutation barrierSourceType_modifyBarrierSourceTypeEntry_Mutation(
    $barrierSourceTypeInput: BarrierSourceTypeInput!
  ) {
    barrierSourceMutation {
      modifyBarrierSourceTypeEntry(barrierSourceTypeInput: $barrierSourceTypeInput) {
        id
        code
        value
        ordinal
        active
        activeDirectorySourceName
        hasConfiguredList
        sourceDetails {
          code
          value
          active
          ordinal
        }
      }
    }
  }
`
