/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import './escalation.scss'
import FlatButton from 'material-ui/FlatButton'
import Drawer from 'material-ui/Drawer'
import Subheader from 'material-ui/Subheader'
import AdmissionFilter from './AdmissionFilter'
import LosFilter from './LosFilter'
import BarriersFilter from './BarriersFilter'
import CodeTypeDropdownFilter from './BarrierFilters/CodeTypeDropdownFilter'
import UnitsFilter from './UnitsFilter'
import { filterConstants } from '../../util/filters'
import { CodeTableGQL } from './../../graphql/queries'
import { Grid } from '@material-ui/core'
import { LoadingIndicator } from '../../components/loader'
import {getQueryString, getInitialState} from '../../ducks/escalation-filters'
import { getConfig , parseTimeValue} from "../../util/configs"
import {getBedRequestStatusName} from "../../util/utils";

@graphql(CodeTableGQL.queries.barrierCategories, { name: 'barrierCategories' })
class FilterDrawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filter: { ...props.filter, units: [...props.filter.units] },
    }

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.filter !== this.props.filter) {
      this.setState({
        filter: { ...nextProps.filter, units: [...nextProps.filter.units] },
      })
    }
  }

  onFilterAdmissionChange = event => {

    //fetch threshold time for the medically clear status
    var thresholdTime = getConfig("ca.obs/out.patient.los.threshold.time")
    //convert time to seconds
    thresholdTime = parseTimeValue( thresholdTime )
    //convert time to hours
    thresholdTime = parseInt(thresholdTime / 3600000 )
    //validate time
    thresholdTime = (0 >=thresholdTime<=48) ? thresholdTime : 0

    this.setState({
      filter: {
        ...this.state.filter,
        losPreview: {
          value: '',
          days: 1,
          decisionTimeDueOnly: false,
          decisionTimeDue: thresholdTime
        },
        admissionType: event.target.value,
      },
    })
  }

  onFilterBarrierChange = event => {
    if (event.target.value === filterConstants.barriers.withoutBarriers) {
      const initialFilterState = getInitialState().filter
      let barrierCategories = this.barrierCategories.map(item => {
        return { ...item, checked: true }
      })

      this.setState({
        filter: {
          ...this.state.filter,
          barriers: { selected: event.target.value },
          barrierCategories: barrierCategories,
          barrierOwnerRadio: {
            value: initialFilterState.barrierOwnerRadio.value
          },
          barrierOwners: [],
          barrierMessageStatuses: initialFilterState.barrierMessageStatuses
        },
      })
    } else {
      this.setState({
        filter: {
          ...this.state.filter,
          barriers: { selected: event.target.value },
        },
      })
    }
  }

  onFilterDepartmentChange = units => {
    this.setState({
      filter: {
        ...this.state.filter,
        units: units,
      },
    })
  }

  onFilterBarrierCategoryChange = (event, index) => {
    const values = this.updateDropdownFilterValues(event, index, this.barrierCategories)

    this.setState({
      filter: {
        ...this.state.filter,
        barrierCategories: values,
      },
    })
  }

  onFilterBarrierCategoryClose = () => {
    if (this.state.filter.barrierCategories.every(category => !category.checked)) {
      this.setState(prevState => {
        return {
          filter: {
            ...this.state.filter,
            barrierCategories: prevState.filter.barrierCategories.map(category => ({...category, checked: true}))
          }
        }
      })
    }
  }

  onFilterBarrierOwnerRadioChange = (selectedVal) => {
    if (selectedVal !== this.state.filter.barrierOwnerRadio.value) {
      let filterVals
      if (selectedVal !== filterConstants.barrierOwnerRadio.search.value && this.state.filter.barrierOwners.length > 0) {
        filterVals = {
          filter: {
            ...this.state.filter,
            barrierOwnerRadio: {
              value: selectedVal
            },
            barrierOwners: []
          }
        }
      } else {
        filterVals = {
          filter: {
            ...this.state.filter,
            barrierOwnerRadio: {
              value: selectedVal
            },
          }
        }
      }

      if (selectedVal === filterConstants.barrierOwnerRadio.noOwners.value) {
        const initialFilterState = getInitialState().filter
        filterVals.filter.barrierMessageStatuses = initialFilterState.barrierMessageStatuses
      }

      this.setState(filterVals)
    }
  }

  onFilterBarrierOwnerChange = (value) => {
    const barrOwnerValue = value ? [value] : []
    const barrOwnerRadioValue = barrOwnerValue.length > 0 ? filterConstants.barrierOwnerRadio.search.value : filterConstants.barrierOwnerRadio.allValue.value
    this.setState({
      filter: {
        ...this.state.filter,
        barrierOwners: barrOwnerValue,
        barrierOwnerRadio: {
          value: barrOwnerRadioValue
        }
      }
    })
  }

  onFilterBarrierMessageStatusChange = (event, index) => {
    const values = this.updateDropdownFilterValues(event, index, this.state.filter.barrierMessageStatuses)

    this.setState({
      filter: {
        ...this.state.filter,
        barrierMessageStatuses: values
      },
    })
  }

  onFilterBarrierMessageStatusClose = () => {
    if (this.state.filter.barrierMessageStatuses.every(msgStatus => !msgStatus.checked)) {
      this.setState(prevState => {
        return {
          filter: {
            ...this.state.filter,
            barrierMessageStatuses: prevState.filter.barrierMessageStatuses.map(msgStatus => ({...msgStatus, checked: true}))
          }
        }
      })
    }
  }

  onFilterLosChange = (value, days) => {
    let newLosPreview = {
      value: value,
      decisionTimeDueOnly: false,
    }
    switch(value) {
      case filterConstants.los.forecastMore_1_20:
        newLosPreview.oeForecastTarget = days
        break
      case filterConstants.los.currentMore_1_00:
        newLosPreview.oeCurrentTarget = days
        break
      case filterConstants.los.forecastExcessDayGreater:
      case filterConstants.los.losDaysGreater:
        newLosPreview.days = days
        break
    }
    this.setState({
      filter: {
        ...this.state.filter,
        losPreview: newLosPreview,
      },
    })
  }

  onFilterLosDecisionTimeDueChange = (event, isInputChecked, value) => {
    this.setState({
      filter: {
        ...this.state.filter,
        losPreview: {
          ...this.state.filter.losPreview,
          decisionTimeDue: value
        },
      },
    })

  }

  onFilterBedRequestStatusChange = (event, index) => {
    const values = this.updateDropdownFilterValues(event, index, this.state.filter.bedRequestStatuses)

    this.setState({
      filter: {
        ...this.state.filter,
        bedRequestStatuses: values,
      },
    })
  }

  onBedRequestStatusDropdownClick = (event, index) => {
    var statuses = this.state.filter.bedRequestStatuses;
    for (let i = 0 ; i < statuses.length ; i++) {
      let status = statuses[i]
      if (status.isBedRequestStatusCode) {
        status.value = getBedRequestStatusName(status.code)
      }
    }

    this.setState({
      filter: {
        ...this.state.filter,
        bedRequestStatuses: statuses,
      },
    })
  }

  onFilterBedRequestStatusClose = () => {
    if (this.state.filter.bedRequestStatuses.every(dcStatus => !dcStatus.checked)) {
      this.setState(prevState => {
        return {
          filter: {
            ...this.state.filter,
            bedRequestStatuses: prevState.filter.bedRequestStatuses.map(dcStatus => ({...dcStatus, checked: true}))
          }
        }
      })
    }
  }

  updateDropdownFilterValues = (event, index, filterValues) => {
    const selectedValues = event.target.value
    const clickedValue = index.props.value

    let values = filterValues.map(status => {
      if (clickedValue.code === 'ALL') {
        return { ...status, checked: !clickedValue.checked }
      }

      return {
        ...status,
        checked: selectedValues.some(value => value.code === status.code),
      }
    })

    // Now we can set checked for ALL item based on others
    if (filterValues !== this.state.filter.barrierMessageStatuses) {
      values[0].checked = values.slice(1).every(status => status.checked)
    } else if (filterValues === this.state.filter.barrierMessageStatuses && !values.slice(1).every(status => status.checked)) {
      values[0].checked = false
    }

    return values
  }




  onChangeSort = (value) => {
    this.setState({
      filter: {
        ...this.state.filter
      }
    })
  }

  get barrierCategories() {
    let { filter } = this.state
    let { barrierCategories } = this.props

    if (filter.barrierCategories.length === 1) {
      if (barrierCategories && !barrierCategories.loading) {
        let categories = filter.barrierCategories.slice()

        barrierCategories.codeTables.forEach(category => {
          if (category.active) {
            categories.push({
              ...category,
              checked: true,
            })
          }
        })

        return categories
      }
    }

    return filter.barrierCategories
  }

  getDefaultSortOrder = () => {
    const initialFilterState = getInitialState().filter
    const appliedFilter = this.state.filter
    const admissionType = this.state.filter.admissionType
    const sortField = this.state.filter.sortField
    const inpatientSortFields = this.state.filter.sortOptions.filter(option => option.type === 'inpatient')
    let defaultSortField

    // We need to reset for non-inpatient filters when previously sorted with an inpatient sort order
    if (admissionType !== 'inpatient' && inpatientSortFields.find(element => element.code === sortField)) {
      defaultSortField = initialFilterState.sortField
    }

    if (!this.filtersArraysEqual(initialFilterState.barrierMessageStatuses, appliedFilter.barrierMessageStatuses)) {
      defaultSortField = 'barrier_message_status_time'
    } else if (!this.objectsEqual(initialFilterState.losPreview, appliedFilter.losPreview)) {
      switch(appliedFilter.losPreview.value) {
        case filterConstants.los.forecastMore_1_20:
          defaultSortField = 'forecast_oe'
          break
        case filterConstants.los.currentMore_1_00:
          defaultSortField = 'current_oe'
          break
        case filterConstants.los.forecastExcessDayGreater:
          defaultSortField = 'forecast_excess'
          break
        case filterConstants.los.losDaysGreater:
          defaultSortField = 'los_days_greater'
          break
      }
    }

    return defaultSortField
  }

  filtersArraysEqual = (initialFilter, appliedFilter) => {
    let filtersSame = true
    for (let i = 0; i < initialFilter.length; i++) {
      const objsEqual = this.objectsEqual(initialFilter[i], appliedFilter[i])
      if (!objsEqual) {
        filtersSame = false
        break
      }
    }
    return filtersSame
  }

  objectsEqual = (o1, o2) =>
    Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every(p => o1[p] === o2[p]);

  applyFilter = () => {
    const defaultSortOrder = this.getDefaultSortOrder()
    let appliedFilters = this.state.filter
    if (defaultSortOrder) {
      appliedFilters = { ...this.state.filter, sortField: defaultSortOrder, invokedAsQuickFilter: false }
      this.setState({
        filter: appliedFilters,
      })
    }

    this.props.applyFilter(appliedFilters)
    this.props.applyQuery(getQueryString(appliedFilters))
  }

  resetFilter = () => {
    this.props.resetFilter(this.state.filter)
    this.props.applyQuery('')
    this.setState({
      filter: {...getInitialState().filter}
    })
  }

  closeDrawer = () => {
    this.props.handleDrawerToggle()
  }

  render() {
    const { open, handleDrawerToggle, departments, facilities } = this.props
    const { filter } = this.state

    return (
      <React.Fragment>
        {departments ? (
          <Drawer
            containerClassName="filter-drawer"
            width={window.innerWidth}
            docked
            swipeAreaWidth={0}
            open={open}
            onRequestChange={handleDrawerToggle}>
            <div style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  borderBottom: '1px solid lightgrey',
                }}>
                <span style={{width: '200px', fontSize:18, fontStyle: 'bold', marginTop: 5, marginLeft: 20}}>
                  Manage Filters
                </span>
                <FlatButton
                  className="drawer-button"
                  label="CANCEL"
                  primary
                  onClick={this.closeDrawer}
                />
                <FlatButton
                  className="drawer-button"
                  label="RESET"
                  primary
                  onClick={this.resetFilter}
                />
                <FlatButton
                  className="drawer-button"
                  label="APPLY"
                  primary
                  onClick={this.applyFilter}
                />
              </div>
              <div
                style={{
                  padding: 10,
                  overflowY: 'auto',
                  height: '100%',
                  width: '100%',
                }}>
                <Grid container spacing={8} style={{height: '100%'}} >

                  <Grid item xs={3}>
                    <AdmissionFilter
                      onChange={this.onFilterAdmissionChange}
                      valueSelected={filter.admissionType}
                      defaultSelected={
                        filterConstants.admissionType.defaultSelected
                      }
                    />
                    <LosFilter
                      onChange={this.onFilterLosChange}
                      onLosDecisionTimeDueChange={
                        this.onFilterLosDecisionTimeDueChange
                      }
                      valueSelected={filter.losPreview}
                      defaultSelected={filterConstants.los.defaultSelected}
                      admissionType={filter.admissionType}
                      onChangeSort={this.onChangeSort}
                    />
                    <Subheader className="filter-subheader">DC or Transfer Status</Subheader>
                    <CodeTypeDropdownFilter
                      onOpen={this.onBedRequestStatusDropdownClick}
                      onChange={this.onFilterBedRequestStatusChange}
                      onClose={this.onFilterBedRequestStatusClose}
                      valueSelected={this.state.filter.bedRequestStatuses}
                      allSelectedText={'All Statuses'} />
                  </Grid>

                  <Grid item xs={6}>
                    <UnitsFilter
                      onChange={this.onFilterDepartmentChange}
                      currentUnits={
                        filter.units.length === 1 ? departments : filter.units
                      }
                      facilities={facilities}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <BarriersFilter
                      onChange={this.onFilterBarrierChange}
                      valueSelected={filter.barriers}
                      defaultSelected={filterConstants.barriers.defaultSelected}
                      onFilterBarrierCategoryChange={
                        this.onFilterBarrierCategoryChange
                      }
                      onFilterBarrierCategoryClose={this.onFilterBarrierCategoryClose}
                      barrierCategoryValueSelected={
                        filter.barrierCategories.length === 1
                          ? this.barrierCategories
                          : filter.barrierCategories
                      }
                      selectedBarrierOwnerRadio={filter.barrierOwnerRadio.value}
                      onFilterBarrierOwnerRadioChange={this.onFilterBarrierOwnerRadioChange}
                      barrierOwnerValueSelected={filter.barrierOwners}
                      onFilterBarrierOwnerChange={this.onFilterBarrierOwnerChange}
                      onFilterBarrierMessageStatusChange={this.onFilterBarrierMessageStatusChange}
                      onFilterBarrierMessageStatusClose={this.onFilterBarrierMessageStatusClose}
                      barrierMessageStatusValueSelected={filter.barrierMessageStatuses}
                    />
                  </Grid>

                </Grid>

              </div>
            </div>
          </Drawer>
        ) : (
          <Drawer
            containerClassName="filter-drawer"
            width={400}
            docked={false}
            openSecondary
            open={open}
            onRequestChange={handleDrawerToggle}>
            <LoadingIndicator/>
          </Drawer>
        )}
      </React.Fragment>
    )
  }
}

FilterDrawer.propTypes = {
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.bool,
  applyFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  applyQuery: PropTypes.func,
  filter: PropTypes.object,
  departments: PropTypes.array,
  facilities: PropTypes.array,
  //departments: PropTypes.oneOfType[(PropTypes.array, PropTypes.boolean)],
  barrierCategories: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    codeTables: PropTypes.array,
  }),
}

export default FilterDrawer
