/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type careTeam_PlanCareTeam$ref = any;
export type careTeam_updateCareTeamMemberName_MutationVariables = {|
  planId: any,
  memberId: any,
  name?: ?string,
|};
export type careTeam_updateCareTeamMemberName_MutationResponse = {|
  +careTeamMemberMutation: ?{|
    +updateCareTeamMemberName: ?{|
      +$fragmentRefs: careTeam_PlanCareTeam$ref
    |}
  |}
|};
export type careTeam_updateCareTeamMemberName_Mutation = {|
  variables: careTeam_updateCareTeamMemberName_MutationVariables,
  response: careTeam_updateCareTeamMemberName_MutationResponse,
|};
*/


/*
mutation careTeam_updateCareTeamMemberName_Mutation(
  $planId: UUID!
  $memberId: UUID!
  $name: String
) {
  careTeamMemberMutation {
    updateCareTeamMemberName(planId: $planId, memberId: $memberId, name: $name) {
      ...careTeam_PlanCareTeam
      id
    }
  }
}

fragment careTeam_PlanCareTeam on Plan {
  id
  careTeamMembers {
    id
    name
    primary
    role {
      id
      code
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "memberId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "memberId",
    "variableName": "memberId"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "planId",
    "variableName": "planId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "careTeam_updateCareTeamMemberName_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CareTeamMemberMutation",
        "kind": "LinkedField",
        "name": "careTeamMemberMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateCareTeamMemberName",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "careTeam_PlanCareTeam"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "careTeam_updateCareTeamMemberName_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CareTeamMemberMutation",
        "kind": "LinkedField",
        "name": "careTeamMemberMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateCareTeamMemberName",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CareTeamMember",
                "kind": "LinkedField",
                "name": "careTeamMembers",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CodeTablePair",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d438fa8a5e143e6df99d1068b56cbd6",
    "id": null,
    "metadata": {},
    "name": "careTeam_updateCareTeamMemberName_Mutation",
    "operationKind": "mutation",
    "text": "mutation careTeam_updateCareTeamMemberName_Mutation(\n  $planId: UUID!\n  $memberId: UUID!\n  $name: String\n) {\n  careTeamMemberMutation {\n    updateCareTeamMemberName(planId: $planId, memberId: $memberId, name: $name) {\n      ...careTeam_PlanCareTeam\n      id\n    }\n  }\n}\n\nfragment careTeam_PlanCareTeam on Plan {\n  id\n  careTeamMembers {\n    id\n    name\n    primary\n    role {\n      id\n      code\n      value\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e95f5b66edaee4ed409f1ba0449a962e';

module.exports = node;
