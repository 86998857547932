import moment from 'moment'
import {getInitialPageSize, getStatusOverrides} from "./configs"
import { datadogRum } from '@datadog/browser-rum'
import { getUserInfo } from '../auth/auth-service'
const ua = navigator.userAgent
const v = navigator.vendor

export const is_Windows = /Windows/.test(ua)
export const is_Windows7 = /Windows NT 6/.test(ua)
export const is_Windows10 = /Windows NT 10/.test(ua)

export const is_Edge = /Edge/.test(ua)

export const is_Chrome = !is_Edge && /Chrome/.test(ua)
export const is_iOS_Chrome = !is_Edge && /CriOS/.test(ua)
export const is_Android = /Android/.test(ua)

export const is_iPad = /iPad/.test(ua)
export const is_iPhone = /iPhone/.test(ua)
export const is_Mac = /Macintosh/.test(ua)

export const is_Safari =
  !is_iOS_Chrome && /Safari/.test(ua) && /Apple Computer/.test(v)

// This is not purely correct but I don't care about none supported browsers like Firefox, etc
export const is_IE = is_Windows && !is_Edge && !is_Chrome
export const is_IEorEdge = is_Windows && !is_Chrome

export const is_PhoneorTablet = is_Android || is_iPad || is_iPhone

/**
 * So here is how the pin text is determined.
 * 1. If the item has a name with a hashtag, then use it first.
 * 2. If the item has a text/description with a hashtag, then use it next.
 * 3. If not hashtag if found then use the name first then by the text/description
 * 4. Once the text has been found then limit the text to 15 characters.
 * 5. If the found text is greater than 15 characters, then display the first 12 characters followed by ...
 * @param name
 * @param text
 * @returns {string}
 */
export const getPinnableText = (maxChars, name, text) => {
  let label
  if (name && name.includes('#')) {
    label = getHashtags(name)
  } else if (text && text.includes('#')) {
    label = getHashtags(text)
  } else {
    label = name ? name : text
  }
  return label.length > maxChars ? label.slice(0, maxChars - 3) + '...' : label
}

export const getHashtags = text => {
  let result = text.match(/(#[a-z0-9\-_]*)/gi)
  return result.join(' ').replace(/[#]/g, '')
}



const deepFind = (obj, path) => {
  let paths = path.split('.'),
    current = obj,
    i

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] === undefined) {
      return undefined
    } else {
      current = current[paths[i]]
    }
  }
  return current
}

export const pushUniqueWithPropertyToArray = (items, uniqueProperty, array) => {
  // The assumption is that either a string is pushed or an object with uniqueProperty is pushed
  let isString = false
  if (Array.isArray(items)) {
    isString = items.length > 0 ? typeof items[0] === 'string' : false
    items.forEach(
      item =>
        array.findIndex(i =>
          isString
            ? i === item
            : deepFind(i, uniqueProperty) === deepFind(item, uniqueProperty)
        ) === -1 && array.push(item)
    )
  } else {
    isString = typeof items === 'string'
    array.findIndex(i =>
      isString
        ? i === items
        : deepFind(i, uniqueProperty) === deepFind(items, uniqueProperty)
    ) === -1 && array.push(items)
  }
}

export const pushUniqueToArray = (items, array) => {
  if (Array.isArray(items)) {
    items.forEach(item => !array.includes(item) && array.push(item))
  } else {
    !array.includes(items) && array.push(items)
  }
}

export const pushToArray = (items, array) => {
  if (Array.isArray(items)) {
    items.forEach(item => array.push(item))
  } else {
    array.push(items)
  }
}

export const pushToArrays = (items, array1, array2) => {
  array1 && pushToArray(items, array1)
  array2 && pushToArray(items, array2)
}

export const accumulateProviders = (visit, bed) => {
  const providers = [...visit.consultingPhysicians]

  if (bed.providers && bed.providers.length > 0) {
    providers.push(...bed.providers)
  }
  if (visit.attendingPhysician) {
    providers.push(visit.attendingPhysician)
  }
  if (visit.admittingPhysician) {
    providers.push(visit.admittingPhysician)
  }
  if (visit.provider1) {
    providers.push(visit.provider1)
  }
  if (visit.provider2) {
    providers.push(visit.provider2)
  }

  return providers
}

export function normalizeProviders(providers) {
  return providers.map(provider => ({
    ...provider,
    name: getProviderName(provider),
  }))
}

function getProviderName({ name, firstName, lastName }) {
  if (name) return name
  let result = ''
  result += lastName ? lastName : '****'
  result += ', '
  result += firstName ? firstName : '****'
  return result
}

function toRegularForm(text) {
  const admissionStatuses = new Set(['Anticipated', 'Pending', 'Ready'])
  text  += admissionStatuses.has(status) ? 'Admit' : ''
  return (
    text
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function(str) {
        return str.toUpperCase()
      })
  )
}

const leadingZero = function(num) {
  return num >= 10 ? '' + num : '0' + num
}

export const buildElapsedTime = function(date, format, granularity) {
  if (date) {
    const now = moment()
    const then = moment(date)

    let countdownText = null
    if (format === 'time') {
      const timeParts = []
      switch (granularity) {
        case 'second':
          timeParts.unshift(Math.abs(then.diff(now, 'second')) % 60)
          break
        case 'minute':
          timeParts.unshift(Math.abs(then.diff(now, 'minute')) % 60)
          break
        case 'hour':
          timeParts.unshift(Math.abs(then.diff(now, 'hour')))
      }
      countdownText = timeParts.map(leadingZero).join(':')
    } else {
      const days = then.diff(now, 'day')
      if (Math.abs(days) >= 2) {
        countdownText = Math.abs(days) + 'd'
      } else {
        const hours = then.diff(now, 'hour')
        if (Math.abs(hours) > 0) {
          countdownText = Math.abs(hours)
          if (Math.abs(then.diff(now, 'minute')) % 60 > 30) {
            countdownText = countdownText + '.5'
          }
          countdownText = countdownText + 'h'
        } else {
          const minutes = then.diff(now, 'minute')
          if (Math.abs(minutes) < 30) {
            countdownText = Math.abs(minutes) + 'm'
          } else {
            countdownText = '0.5h'
          }
        }
      }
    }

    return countdownText
  }
}

const scrollInto = (elementId, pathname, pageNumber, history) => {
  if (elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId)

      if (element) {
        element.scrollIntoView({ block: 'center' })
        // CA-2374 highlighing didn't work for KpiView
        // const className = element.className
        //
        // if (className.indexOf('highlighter') === -1) {
        //   element.className = element.className + ' highlighter'
        //
        //   setTimeout(() => {
        //     element.className = className
        //   }, 5000)
        }

        // and clearing history here caused a rerender and scroll lost
        // if (pageNumber === 0) {
        //   history && history.replace(pathname)
        // } else {
        //   history && history.replace(`${pathname}?page=${pageNumber}`)
        // }
      // }
    }, 0)
  }
}

export const scrollPageNumber = history => {
  const { pathname, search } = history.location
  let pageNumber = getQueryStringParameter(search, 'page')
  const id = getQueryStringParameter(search, 'id')

  pageNumber = pageNumber ? parseInt(pageNumber, 10) : 0

  if (id && history) {
    scrollInto(id, pathname, pageNumber, history)
  }

  return pageNumber
}

export const onScrollNavigate = (id, history) => {
  const { pathname, search } = history.location
    history.replace(`${pathname}?id=${id}`)
}

export const getNumToLoad = (scrollIndex, paginationLoads) => {
  const pageSize = getInitialPageSize()
  return scrollIndex ? scrollIndex + 10 :
    paginationLoads > 1 ? (paginationLoads * pageSize) + 10 :
      pageSize
}
export const getPaginationsFromIndex = (scrollIndex, paginationLoads) => {
  const pageSize = getInitialPageSize()
  return scrollIndex ? (scrollIndex / pageSize) + 1 : paginationLoads
}


// CA-2506 Changed to support react-window
export const highlightSelected = (element, history) => {
    setTimeout(() => {
      if (element) {
        const className = element.className

        if (className.indexOf('highlighter') === -1) {
          element.className = element.className + ' highlighter'

          setTimeout(() => {
            element.className = className
          }, 3000)
        }
        // clear the index, if we replace pathname it causes 2nd render without index
        // and then we loose the scroll position
        history.location.search = ''
        // const { pathname } = history.location
        // history.replace(pathname)

      }
    }, 0)
}

export const getScrollToIndex = history => {
  const { search } = history.location
  const index = getQueryStringParameter(search, 'i')
  return index ? parseInt(index, 10) : undefined
}

export const saveIndexPosition = (index, history) => {
  const { pathname } = history.location
  history.replace(`${pathname}?i=${index}`)
}

export const getQueryStringParameter = (search, paramName) => {
  const pairs = search.substring(1).split('&')
  for (let i = 0; i < pairs.length; i++) {
    let split = pairs[i].split('=')

    if (split[0] === paramName) {
      return split[1]
    }
  }
}

/**
 * Initialization datadog RUM to monitor and measure web traffic.
 * @param {Object} datadogConfig = {
      enabled,
      envName,
      sampleRate
    }
 */
export const initializeDatadogRum = (datadogConfig = {}) => {
  // Initialize datadog when it is enabled
  if (datadogConfig.enabled !== true) {
    return
  }

  const envName = datadogConfig.envName,
    sampleRate = parseInt(datadogConfig.sampleRate)

  //Check for invalid env name and sample rate percentage (Range: 0 to 100)
  if (!envName || (!(sampleRate >= 0 && sampleRate <= 100))) {
    return
  }
  //Here means, dynamic value - env name and sample rate are valid. So initialize datadog RUM
  datadogRum.init({
    clientToken: 'pub0ccc976089dfc5fb3b784a748f5774d8',
    applicationId: 'efce8b92-17c1-482e-b085-bb7c4f1533cd',
    site: 'datadoghq.com',
    service: 'care-advance',
    env: envName,
    sampleRate: sampleRate,
    sessionReplaySampleRate: 0,
    trackInteractions: false,
    trackResources: true,
    trackLongTasks: true,
  })

  const {firstName, lastName, userName} = getUserInfo()
  if (!userName || !firstName || !lastName) {
    datadogRum.clearUser()
    return
  }
  datadogRum.setUser({
    id: userName,
    name: `${firstName} ${lastName}`
  })
}

export const getBedRequestStatusName = (statusCode) => {
  const bedRequestStatusList = {
    "AnticipatedDischarge": "Potential DC",
    "DischargeOrderWithDelay": "DC Ordered With Delay",
    "PendingDischarge": "DC Ordered",
    "ReadyForDischarge": "Ready For DC",
    "PendingUnitTransfer": "Unit Transfer Ordered",
    "ReadyForUnitTransfer": "Ready For Unit Transfer",
    "AnticipatedUnitTransfer": "Potential Unit Transfer",
    "IntentToAdmit": "Intent To Admit",
    "Pending": "Ordered",
    "Ready": "Ready",
    "Anticipated": "Potential"
  }
  let statusOverride = getStatusOverrides("BedRequestStatus." + statusCode);
  let statusName = statusOverride !== '' ? statusOverride : bedRequestStatusList[statusCode]
  if(!statusName){
    statusName = toRegularForm(statusCode)
  }
  return ` ${statusName} `
}
