import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './escalation.scss'

import Chip from 'material-ui/Chip'
import { department } from './../../types'
import { filterConstants } from '../../util/filters'
import {isNumber} from 'lodash'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  chip: {
    '&, & *': {
      whiteSpace: 'normal !important'
    }
  },
}))

const FilterHeader = ({ resultsLength, filter, nameBedQuery }) => {
  const classes = useStyles()

  const formatUnits = (units) => {
    let unitString = ''
    const checkedPercent = units.filter(item => item.checked).length / units.length
    const label = checkedPercent > .6 ? "All Units Except:" : "Units:"
    const target = checkedPercent > .6 ? false : true
    units.map(unit => unitString += unit.checked === target && unit.name !== 'ALL' ? unit.name + ', ' : '')
    return (
      <div className="filter-chip" >
        <Chip className={classes.chip}>{label} {unitString.substr(0, unitString.length -2)}</Chip>
      </div>
    )
  }

  const formatLosReview = filter => {
    let losPreview = filter.losPreview
    if (filter.admissionType === filterConstants.admissionType.outObsValue && isNumber(losPreview.decisionTimeDue)) {
      return (
          <div className="filter-chip">
            <Chip>{`LOS > ${losPreview.decisionTimeDue}h`}</Chip>
          </div>
      )
    }

    if (losPreview.value) {
      switch (losPreview.value) {
        case filterConstants.los.forecastMore_1_20:
          return (
            <div className="filter-chip">
              <Chip>{`Forecast O/E > ${losPreview.oeForecastTarget}`}</Chip>
            </div>
          )

        case filterConstants.los.currentMore_1_00:
          return (
            <div className="filter-chip">
              <Chip>{`Current O/E > ${losPreview.oeCurrentTarget}`}</Chip>
            </div>
          )

        case filterConstants.los.forecastExcessDayGreater:
          return (
            <div className="filter-chip">
              <Chip>{`Excess days > ${losPreview.days}`}</Chip>
            </div>
          )

        case filterConstants.los.losDaysGreater:
          return (
            <div className="filter-chip">
              <Chip>{`LOS days > ${losPreview.days}`}</Chip>
            </div>
          )
      }
    }

    return null
  }

  const formatCategories = (categories) => {
    let categoriesString = ''
    const checkedPercent = categories.filter(item => item.checked).length / categories.length
    const label = checkedPercent > .6 ? "All Categories Except:" : checkedPercent === 0 ? "No Categories" : "Categories:"
    const target = checkedPercent > .6 ? false : true
    categories.map(category => categoriesString += category.checked === target && category.code !== 'ALL' ? category.value + ', ' : '')
    return (
      <div className="filter-chip" >
        <Chip className={classes.chip}>{label} {categoriesString.substr(0, categoriesString.length -2)}</Chip>
      </div>
    )
  }

  const formatRoles = (roles) => {
    let rolesString = '', label = "All Roles"
    const checkedPercent = roles.filter(item => item.role !== 'ALL' &&item.checked).length / (roles.length - 1)
    if(checkedPercent !== 1) {
      label = checkedPercent > .6 ? "All Roles Except:" : checkedPercent === 0 ? "No Roles" : "Roles:"
      const target = checkedPercent > .6 ? false : true
      roles.map(role => rolesString += role.checked === target && role.code !== 'ALL' ? role.value + ', ' : '')
    }
    return (
      <div className="filter-chip" >
        <Chip className={classes.chip}>{label} {rolesString.substr(0, rolesString.length -2)}</Chip>
      </div>
    )
  }

  const formatBarrierOwnerRadio = (barrierOwnerRadio) => {
    if (barrierOwnerRadio.value === filterConstants.barrierOwnerRadio.noOwners.value) {
      return (
        <div className="filter-chip" >
          <Chip>{filterConstants.barrierOwnerRadio.noOwners.label}</Chip>
        </div>
      )
    }
  }

  const formatOwners = (owners) => {
    return (
      <div className="filter-chip" >
        <Chip>Owner: {owners[0]}</Chip>
      </div>
    )
  }

  const formatMessageStatus = (messageStatuses) => {
    const messageStatusFilters = messageStatuses
      .filter(status => status.code !== 'ALL' && status.checked)
      .map(status => status.value)
      .join(', ')
    return (
      <div className="filter-chip" >
        <Chip className={classes.chip}>Message Status: {messageStatusFilters}</Chip>
      </div>
    )
  }

  const formatAdmissionType = type => {
    return type === 'inpatient' ? "Inpatient" : type === 'obsout' ?  "Obs/Out" : ''
  }

  const formatDischargeStatus = statuses => {
    if(statuses[0].checked) {
      return null
    }
    let statusString = ''
    const label = "DC or Transfer Status:"
    statuses.map(status => statusString += status.checked && status.code !== 'ALL' ? status.value + ', ' : '')
    return statusString ? (
      <div className="filter-chip" >
        <Chip className={classes.chip}>{label} {statusString.substr(0, statusString.length -2)}</Chip>
      </div>
    ) : null
  }

  const resultLabel = () => {
    const results = parseInt(resultsLength)
    return results === 0  ? "No results. Change Filters or Bed Assignments" :
      results === 1 ? "1 result" : `${results} results`
  }

  const resultLabelWidth = () => ((parseInt(resultsLength) === 0) ? '600px' : '90px')

  const filterChips = filter => {
    return (
      <div style={{ flex: 'auto', display: 'flex', flexWrap: 'wrap' }}>

        {filter.admissionType !==
        filterConstants.admissionType.defaultSelected && (
          <div className="filter-chip">
            <Chip>{formatAdmissionType(filter.admissionType)}</Chip>
          </div>
        )}
        {formatLosReview(filter)}

        {formatDischargeStatus(filter.bedRequestStatuses)}

        {filter.units &&
        !filter.units.some(
          unit => unit.exchangeName === 'All' && unit.checked
        ) &&
        formatUnits(filter.units)}

        <Barriers barriers={filter.barriers} />

        {!filter.barrierCategories.some(
          category => category.code === 'ALL' && category.checked
        ) && formatCategories(filter.barrierCategories)}

        {!filter.barrierOwnerRoles.some(
          role => role.code === 'ALL' && role.checked
        ) &&
        (formatRoles(filter.barrierOwnerRoles))}

        {formatBarrierOwnerRadio(filter.barrierOwnerRadio)}

        {filter.barrierOwners.length > 0 &&
        (formatOwners(filter.barrierOwners))}

        {!filter.barrierMessageStatuses.some(
          msgStatus => msgStatus.code === 'ALL' && msgStatus.checked
        ) &&
        (formatMessageStatus(filter.barrierMessageStatuses))}

      </div>
    )
  }

  const nameBedChip = nameBedQuery => {
    return (
      <div style={{ flex: 'auto', display: 'flex', flexWrap: 'wrap' }}>
        <div className="filter-chip">
          <Chip>{`Name/Bed: ${nameBedQuery}`}</Chip>
        </div>
      </div>
      )
  }

  return (
    <div className="list" style={{ flexWrap: 'nowrap' }}>
        <div style={{ paddingRight: 10, paddingTop: 5, width: `${resultLabelWidth()}`}}>
          {resultLabel()}
        </div>

      <div style={{paddingTop: 5, paddingBottom: 5, marginRight: 5, width: '100%'}}>
        {nameBedQuery ? nameBedChip(nameBedQuery) : filterChips(filter)}
      </div>
    </div>
  )
}

const Barriers = ({ barriers }) => {
  let label

  switch (barriers.selected) {
    case filterConstants.barriers.withoutBarriers:
      label = 'No Open Barriers'
      break

    case filterConstants.barriers.withOpenBarriers:
      label = 'Open Barriers'
      break

    case filterConstants.barriers.withOpenEscalatedBarriers:
      label = 'Escalated Barriers'
      break
  }

  if (label) {
    return (
      <div className="filter-chip">
        <Chip>{label}</Chip>
      </div>
    )
  }

  return null
}

Barriers.propTypes = { barriers: PropTypes.object }

FilterHeader.propTypes = {
  resultsLength: PropTypes.string,
  filter: PropTypes.object,
  nameBedQuery: PropTypes.string,
}

export default FilterHeader
