/*eslint-disable react/no-set-state */
import React from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import IconButton from 'material-ui/IconButton'
import ModeEdit from '../icons/ModeEdit'
import Delete from '../icons/Delete'
import PropTypes from 'prop-types'
import { ConfirmDialog } from '../common'

class VisibilityBoardAddressRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteMode: false
    }
  }

  handleConfirmDeleteBoardAddress = () => {
    this.props.onDelete(this.props.rowData)
    this.setState({isDeleteMode: false})
  }

  handleCancelDeleteBoardAddress = () => {
    this.setState({isDeleteMode: false})
  }

  render() {
    const { rowData, onEdit } = this.props
    const {
      address,
      description,
      visibilityBoard
    } = rowData

    const rowStyle = {
      border: "1px rgb(224, 224, 224)",
    }

    const columnStyle = {
      border: "1px solid rgb(224, 224, 224)",
      paddingLeft: "15px",
      paddingRight: "15px"
    }

    return (
      <React.Fragment>
        <tr style={rowStyle}>
          <td style={columnStyle} bgcolor="white">
            <IconButton onClick={() => onEdit(rowData)}>
              <ModeEdit/>
            </IconButton>
          </td>
          <td style={columnStyle} bgcolor="white">{address}</td>
          <td style={columnStyle} bgcolor="white">{description}</td>
          <td style={columnStyle} bgcolor="white">{visibilityBoard.name}</td>
          <td style={columnStyle} bgcolor="white">
            <IconButton onClick={() => this.setState({isDeleteMode: true})}>
              <Delete/>
            </IconButton>
          </td>
        </tr>
        { this.state.isDeleteMode !== false &&
          <ConfirmDialog
            open={this.state.isDeleteMode !== false}
            title={`Delete Visibility Board Address Entry: ${ address }`}
            confirmText="Delete"
            cancelText="Cancel"
            message="The above Visibility Board Address mapping will be deleted and this action cannot be undone."
            onCancelClick={this.handleCancelDeleteBoardAddress}
            onConfirmClick={this.handleConfirmDeleteBoardAddress}
          />
        }
      </React.Fragment>
    )
  }
}

VisibilityBoardAddressRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default createFragmentContainer(VisibilityBoardAddressRow, {
 rowData: graphql`
    fragment VisibilityBoardAddressRow_rowData on VisibilityBoardAddress {
      id
      address
      description
      visibilityBoard {
        id
        name
      }
    }
  `,
})
