/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CodeTableSelect } from '../../containers'

import { createStyleSheet } from 'jss-theme-reactor'
import AutoComplete from 'material-ui/AutoComplete'
import FlatButton from 'material-ui/FlatButton'

const styleSheet = createStyleSheet('MemberNameInput', theme => ({
  addButton: {
    marginTop: '7px',
  },
  inputFloat: {
    float: 'left',
    marginLeft: '10px',
  },
}))

export class CareTeamMemberRow extends Component {
  static propTypes = {
    planId: PropTypes.string.isRequired,
    memberId: PropTypes.string,
    careTeamMembers: PropTypes.array.isRequired,
    handleCreateCareTeamMember: PropTypes.func.isRequired,
    handleAssignCareTeamMember: PropTypes.func.isRequired,
    handleUpdateCareTeamMemberName: PropTypes.func.isRequired,
    handleUpdateCareTeamMemberRole: PropTypes.func.isRequired,
    handleRemoveCareTeamMember: PropTypes.func.isRequired,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    newRoleCode: '',
    newRoleName: '',
    memberId: '',
    dataSource: this.props.careTeamMembers,
  }

  handleRoleCodeChange = value => {
    const { careTeamMembers } = this.props

    const filteredDataSource = careTeamMembers.filter(
      member => member.role.code === value
    )

    this.setState({
      newRoleCode: value || '',
      dataSource: filteredDataSource,
    })
  }

  handleRoleNameChange = value => {
    // CA-1262 compromise
    if (value.length <= 50) {
      this.setState({ newRoleName: value || '' })
    }
  }

  handleCareTeamMemberSelect = (searchText, index) => {
    const { dataSource } = this.state

    if (index === -1) {
      this.setState({ newRoleName: searchText })
    } else {
      const memberSelected = dataSource[index]
      this.setState({
        newRoleName: memberSelected.name,
        newRoleCode: memberSelected.role.code,
        memberId: memberSelected.id,
      })
    }
  }

  handleCreateCareTeamMember = (planId, roleCode, roleName) => {
    const {
      handleCreateCareTeamMember,
      handleAssignCareTeamMember,
      careTeamMembers,
    } = this.props
    const { memberId } = this.state

    if (memberId) {
      handleAssignCareTeamMember(planId, memberId)
    } else {
      handleCreateCareTeamMember(planId, roleCode, roleName)
    }

    this.setState({
      newRoleName: '',
      newRoleCode: '',
      memberId: null,
      dataSource: careTeamMembers,
    })
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const { planId } = this.props

    const { newRoleCode, newRoleName, dataSource } = this.state

    const dataSourceConfig = {
      text: 'name',
      value: 'id',
    }

    const normalizedDataSource = dataSource.map(member => ({
      ...member,
      name: `${member.name}, ${member.role.value}`,
    }))

    return (
      <div className="clearfix">
        <div className={classNames(classes.inputFloat)}>
          <CodeTableSelect
            label="Role"
            value={newRoleCode}
            componentProps={{
              hintText: 'Role',
              floatingLabelStyle: { display: 'none' },
              style: { maxHeight: 50 },
            }}
            codeTableType="CareTeamMemberRole"
            noNullValue
            onChange={(event, index, newValue) => {
              this.handleRoleCodeChange(newValue)
            }}
          />
        </div>
        <div className={classNames(classes.inputFloat)}>
          <AutoComplete
            hintText="Care Team Member Name"
            openOnFocus
            filter={AutoComplete.caseInsensitiveFilter}
            dataSource={normalizedDataSource}
            dataSourceConfig={dataSourceConfig}
            searchText={newRoleName}
            onUpdateInput={(searchText, careTeamMembers, params) =>
              this.handleRoleNameChange(searchText)
            }
            onNewRequest={(chosenRequest, index) => {
              this.handleCareTeamMemberSelect(chosenRequest, index)
            }}
            menuStyle={{ maxHeight: 300, overflow: 'auto' }}
          />
        </div>
        <div className={classes.addButton}>
          <FlatButton
            disabled={!newRoleCode || !newRoleName}
            onClick={() =>
              this.handleCreateCareTeamMember(planId, newRoleCode, newRoleName)
            }>
            Add
          </FlatButton>
        </div>
      </div>
    )
  }
}
