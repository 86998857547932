/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BedRequestStatus = "Anticipated" | "AnticipatedDischarge" | "AnticipatedUnitTransfer" | "Cancelled" | "CheckedIn" | "DischargeOrderWithDelay" | "Discharged" | "IntentToAdmit" | "Pending" | "PendingDischarge" | "PendingUnitTransfer" | "Ready" | "ReadyForDischarge" | "ReadyForUnitTransfer" | "Transferred" | "%future added value";
export type visit_checkoutFromBed_MutationVariables = {|
  visitId: any,
  bedExchangeName: string,
|};
export type visit_checkoutFromBed_MutationResponse = {|
  +visitMutation: ?{|
    +checkoutFromBed: ?{|
      +id: string,
      +bedRequestStatus: ?BedRequestStatus,
      +homeBedExchangeName: ?string,
      +bedConflict: ?{|
        +created: any,
        +createdBy: ?{|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
        +exchangeName: ?string,
      |},
    |}
  |}
|};
export type visit_checkoutFromBed_Mutation = {|
  variables: visit_checkoutFromBed_MutationVariables,
  response: visit_checkoutFromBed_MutationResponse,
|};
*/


/*
mutation visit_checkoutFromBed_Mutation(
  $visitId: Long!
  $bedExchangeName: String!
) {
  visitMutation {
    checkoutFromBed(visitId: $visitId, bedExchangeName: $bedExchangeName) {
      id
      bedRequestStatus
      homeBedExchangeName
      bedConflict {
        created
        createdBy {
          userName
          firstName
          lastName
          id
        }
        exchangeName
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bedExchangeName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "bedExchangeName",
    "variableName": "bedExchangeName"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bedRequestStatus",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homeBedExchangeName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_checkoutFromBed_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "checkoutFromBed",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BedConflict",
                "kind": "LinkedField",
                "name": "bedConflict",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_checkoutFromBed_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "checkoutFromBed",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BedConflict",
                "kind": "LinkedField",
                "name": "bedConflict",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3705d728fe8adf73587506c365d6ccb",
    "id": null,
    "metadata": {},
    "name": "visit_checkoutFromBed_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_checkoutFromBed_Mutation(\n  $visitId: Long!\n  $bedExchangeName: String!\n) {\n  visitMutation {\n    checkoutFromBed(visitId: $visitId, bedExchangeName: $bedExchangeName) {\n      id\n      bedRequestStatus\n      homeBedExchangeName\n      bedConflict {\n        created\n        createdBy {\n          userName\n          firstName\n          lastName\n          id\n        }\n        exchangeName\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5dc4ba788b20c7c6403beb5551238c46';

module.exports = node;
