import { Environment, Network, RecordSource, Store } from 'relay-runtime'
// import RelayQueryResponseCache from 'relay-runtime/lib/RelayQueryResponseCache'
import { retrieveAuthToken } from '../../auth'

// CA-2483 - see for background of why ttl is 10 msecs, not using cache now
// const cache = new RelayQueryResponseCache({ size: 250, ttl: 100})

const fetchQuery = (operation, variables, cacheConfig) => {
  // const queryID = operation.text
  // const isMutation = operation.operationKind === 'mutation'
  // const isQuery = operation.operationKind === 'query'
  // const forceFetch = cacheConfig && cacheConfig.force

  // Try to get data from cache on queries
  // const fromCache = cache.get(queryID, variables)
  // if (isQuery && fromCache !== null && !forceFetch) {
  //   return fromCache
  // }

  return retrieveAuthToken().then(authToken => {
    return fetch('/api/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    }).then(response => {
      return response.json()
    })
    // .then(json => {
    //     if (isQuery && json) {
    //       cache.set(queryID, variables, json)
    //     }
    //     if (isMutation) {
    //       cache.clear()
    //     }
    //     return json
    // })
  })
}

export const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})
