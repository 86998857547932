import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import {DrawerHeaderBar} from '../../../components/drawer'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import TextField from '../../../components/common/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import sortBy from 'lodash.sortby'
import { handleMutation } from '../../../graphql/relay/queries/mutation'
import {environment} from "../../../graphql/relay/env"
import {
  newEntryBarrierCategoryUpdater,
  updateEntryBarrierCategoryUpdater
} from "../../../graphql/relay/queries/barrierCategory";

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {},
      dispatch
    ),
  }
}

const BarrierCategoryDrawer = (
  {
    title,
    isEdit,
    data,
    mutation,
    onRequestClose,
    onError}) => {

  const [code, setCode] = useState(data.code)
  const [value, setValue] = useState(data.value)
  const [ordinal, setOrdinal] = useState(data.ordinal.toString())
  const [active, setActive] = useState(data.active)
  const [sourceTypes, setSourceTypes] = useState(cloneDeep(data.sourceTypes))

  const handleCodeBlur = (event) => {
    const value = event.target.value
    const trimmedValue = value.trim()

    setCode(trimmedValue)
  }

  const onClickApply = () => {
    const variables = {
      barrierCategoryInput: {
        code,
        description: value,
        sortOrder: ordinal,
        active,
        sourceTypeCodes: sourceTypes.filter(type => type.checked).map(type => type.code)
      }
    }

    let updater
    if (!isEdit) {
      updater = (proxyStore) => {
        newEntryBarrierCategoryUpdater(proxyStore, variables)
      }
    } else {
      updater = (proxyStore) => {
        updateEntryBarrierCategoryUpdater(proxyStore, variables)
      }
    }

    handleMutation(
      environment,
      mutation,
      variables,
      () => {
        onRequestClose && onRequestClose()
      },
      null,
      onError,
      updater
    )
  }

  const handleChangeSourceTypeCheckbox = (event) => {
    const newSourceTypes = [...sourceTypes]
    const matchingSource = newSourceTypes.find(source => source.code === event.target.name)
    matchingSource.checked = event.target.checked
    setSourceTypes(newSourceTypes)
  }

  const noValueChanged = (code === data.code)
      && (value === data.value)
      && (ordinal === data.ordinal.toString())
      && (active === data.active)
      && isEqual(sortBy(sourceTypes), sortBy(data.sourceTypes))

  const anyValueUnPopulated = !code || !value || !ordinal

  const applyDisabled = isEdit ? (noValueChanged || anyValueUnPopulated) : anyValueUnPopulated

  const drawerActions = [
    <StyledApplyButton
      key="apply"
      disabled={applyDisabled}
      onClick={onClickApply}
    >Apply</StyledApplyButton>,
  ]

  return (
    <div>
      <DrawerHeaderBar
        title={title}
        iconElementLeft={
          <IconButton onClick={onRequestClose}>
            <NavigationClose />
          </IconButton>
        }
        actions={drawerActions}
      />
      <FieldSection>
        <FieldSectionLeft>
          <StyledTextFieldWrapper>
            <TextField
              value={code}
              label={'Code (Required)'}
              maxChars={30}
              disabled={isEdit}
              onChange={event => setCode(event.target.value)}
              onBlur={handleCodeBlur}
              fullWidth
              showCharacterCount
              />
          </StyledTextFieldWrapper>
          <StyledTextFieldWrapper>
            <TextField
              value={value}
              label={'Description (Required)'}
              maxChars={255}
              onChange={event => setValue(event.target.value)}
              fullWidth
              showCharacterCount
            />
          </StyledTextFieldWrapper>
          <StyledTextFieldWrapper>
            <TextField
              value={ordinal}
              label={'Sort Order (Required)'}
              maxChars={3}
              onChange={event => setOrdinal(event.target.value)}
              fullWidth
              type={'number'}
            />
          </StyledTextFieldWrapper>
          <FormControlLabel
            control={<Switch color={'primary'} checked={active} onChange={event => setActive(event.target.checked)} />}
            label="Active"
          />
        </FieldSectionLeft>
        <FieldSectionRight>
          <FormLabel component="legend">Barrier Source Types</FormLabel>
          <FormGroup>
            {sourceTypes.map(sourceType => (
              <StyledSourceType
                key={sourceType.code}
                control={<Checkbox color={'primary'} checked={sourceType.checked} onChange={handleChangeSourceTypeCheckbox} name={sourceType.code} />}
                label={sourceType.active ? sourceType.value : `${sourceType.value} - Deactivated`}
                $isActive={sourceType.active}
              />
            ))}
          </FormGroup>
        </FieldSectionRight>
      </FieldSection>
    </div>
  )
}

const FieldSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0 12px 24px;
  height: 474px;
  overflow-y: scroll;
`

const FieldSectionLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-right: 20px;
`

const FieldSectionRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`

const StyledTextFieldWrapper = styled.div`
  margin-bottom: 20px;
`

const StyledApplyButton = styled(Button)`
  && {
    font-weight: 500;
    text-transform: uppercase;

    ${props => !props.disabled && css`
      color: white;
    `}
  }
`

const StyledSourceType = styled(FormControlLabel)`
  ${(props) => {
  switch (props.$isActive) {
    case false:
      return css`
          color: red;
        `
    default:
      return
  }
}}
`

BarrierCategoryDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    code: PropTypes.string,
    value: PropTypes.string,
    ordinal: PropTypes.number,
    active: PropTypes.bool,
    sourceTypes: PropTypes.array
  }),
  mutation: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onError: PropTypes.func
}

export default connect(null, mapDispatchToProps)(BarrierCategoryDrawer)
