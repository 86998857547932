import { graphql } from 'react-relay'

export const updatePatientProblem = graphql`
  mutation visit_updatePatientProblem_Mutation(
    $visitId: Long!
    $patientProblem: String!
  ) {
    visitMutation {
      updatePatientProblem(visitId: $visitId, patientProblem: $patientProblem) {
        id
        patientProblem
      }
    }
  }
`

export const updateBedRequestStatus = graphql`
  mutation visit_updateBedRequestStatus_Mutation(
    $visitId: Long!
    $status: String!
    $anticipatedDischargeTime: ZonedDateTime
    $anticipatedTransferTime: ZonedDateTime
  ) {
    visitMutation {
      updateBedRequestStatus(
        visitId: $visitId
        status: $status
        anticipatedDischargeTime: $anticipatedDischargeTime
        anticipatedTransferTime: $anticipatedTransferTime
      ) {
        id
        bedRequestStatus
        anticipatedDischargeTime
        anticipatedDischargeTimeAutoPopulated
        anticipatedTransferTime
        anticipatedTransferTimeAutoPopulated
        bedRequests {
          id
          bedRequestType
          bedRequestStatus
          updatedWhen
          bedExchangeName
        }
      }
    }
  }
`

export const updateTimeToDecisionWithTime = graphql`
  mutation visit_updateTimeToDecisionWithTime_Mutation(
    $visitId: Long!
    $decisionTime: ZonedDateTime!
  ) {
    visitMutation {
      updateTimeToDecisionWithTime(
        visitId: $visitId
        decisionTime: $decisionTime
      ) {
        id
        decisionTime
        meta {
          dischargeToday
          tlosExceeded
          plannedDischargeExceeded
          decisionDue
          timeElapsedSinceAdmission
        }
      }
    }
  }
`

export const updateTimeToDecisionWithOffset = graphql`
  mutation visit_updateTimeToDecisionWithOffset_Mutation(
    $visitId: Long!
    $hoursOffset: Int!
  ) {
    visitMutation {
      updateTimeToDecisionWithOffset(
        visitId: $visitId
        hoursOffset: $hoursOffset
      ) {
        id
        decisionTime
        meta {
          dischargeToday
          tlosExceeded
          plannedDischargeExceeded
          decisionDue
          timeElapsedSinceAdmission
        }
        plan {
            id
            milestones {
                id
                dueDate
            }
        }
      }
    }
  }
`

export const removeTimeToDecision = graphql`
  mutation visit_removeTimeToDecision_Mutation($visitId: Long!) {
    visitMutation {
      removeTimeToDecision(visitId: $visitId) {
        id
        decisionTime
        meta {
          dischargeToday
          tlosExceeded
          plannedDischargeExceeded
          decisionDue
          timeElapsedSinceAdmission
        }
        plan {
          id
          milestones {
            id
            dueDate
          }
        }
      }
    }
  }
`
//FIXME refetch????
export const updateAnticipatedDischargeTime = graphql`
  mutation visit_updateAnticipatedDischargeTime_Mutation(
    $visitId: Long!
    $anticipatedDischargeTime: ZonedDateTime
    $anticipatedDischargeTimeAutoPopulated: Boolean!
  ) {
    visitMutation {
      updateAnticipatedDischargeTime(
        visitId: $visitId
        anticipatedDischargeTime: $anticipatedDischargeTime
        anticipatedDischargeTimeAutoPopulated: $anticipatedDischargeTimeAutoPopulated
      ) {
        id
        anticipatedDischargeTime
        anticipatedDischargeUpdatedWhen
        anticipatedDischargeUpdatedBy {
          userName
          firstName
          lastName
        }
        anticipatedDischargeTimeAutoPopulated
        meta {
          dischargeToday
          tlosExceeded
          plannedDischargeExceeded
          decisionDue
          timeElapsedSinceAdmission
        }
        plan {
          id
          milestones {
            id
            dueDate
          }
        }
      }
    }
  }
`

export const updateWorkingDischargeDisposition = graphql`
  mutation visit_updateWorkingDischargeDisposition_Mutation(
    $visitId: Long!
    $dispositionCode: String!
    $dischargeVendor: String!
  ) {
    visitMutation {
      updateWorkingDischargeDisposition(
        visitId: $visitId
        dispositionCode: $dispositionCode
        dischargeVendor: $dischargeVendor
      ) {
        id
        workingDischargeDispositionUpdatedWhen
        workingDischargeDispositionUpdatedBy {
          id
          userName
          firstName
          lastName
        }
        workingDischargeDisposition {
          id
          code
          value
        }
        dischargeVendor
      }
    }
  }
`

export const updateDrg = graphql`
  mutation visit_updateDrg_Mutation($visitId: Long!, $drgCode: String!) {
    visitMutation {
      updateDrg(visitId: $visitId, drgCode: $drgCode) {
        id
        targetDischargeTime
        readmissionRisk
        readmissionRiskVerifiedBy {
          userName
          firstName
          lastName
        }
        readmissionRiskUpdatedWhen
        tlos
        drgAttributes {
          code
          title
          level
          targetLos
          modifyTargetLos
        }
        drgUpdatedBy {
          userName
          firstName
          lastName
        }
        drgUpdatedWhen
        meta {
          dischargeToday
          tlosExceeded
          plannedDischargeExceeded
          decisionDue
        }
        plan {
          id
          milestones {
            id
            dueDate
          }
        }
      }
    }
  }
`

export const updateReadmissionRisk = graphql`
  mutation visit_updateReadmissionRisk_Mutation(
    $visitId: Long!
    $readmissionRisk: Boolean!
  ) {
    visitMutation {
      updateReadmissionRisk(
        visitId: $visitId
        readmissionRisk: $readmissionRisk
      ) {
        id
        readmissionRisk
        readmissionRiskVerifiedBy {
          userName
          firstName
          lastName
        }
        readmissionRiskUpdatedWhen
      }
    }
  }
`

export const updateReadmission = graphql`
  mutation visit_updateReadmission_Mutation(
    $visitId: Long!
    $readmission: Boolean!
  ) {
    visitMutation {
      updateReadmission(visitId: $visitId, readmission: $readmission) {
        id
        readmission
        readmissionVerifiedBy {
          userName
          firstName
          lastName
        }
        readmissionUpdatedWhen
      }
    }
  }
`

export const checkoutFromBed = graphql`
  mutation visit_checkoutFromBed_Mutation(
    $visitId: Long!
    $bedExchangeName: String!
  ) {
    visitMutation {
      checkoutFromBed(visitId: $visitId, bedExchangeName: $bedExchangeName) {
        id
        bedRequestStatus
        homeBedExchangeName
        bedConflict {
          created
          createdBy {
            userName
            firstName
            lastName
          }
          exchangeName
        }
      }
    }
  }
`

export const checkInToBed = graphql`
  mutation visit_checkInToBed_Mutation(
    $visitId: Long!
    $bedExchangeName: String!
  ) {
    visitMutation {
      checkInToBed(visitId: $visitId, bedExchangeName: $bedExchangeName) {
        id
        bedRequestStatus
        homeBedExchangeName
        bedConflict {
          created
          createdBy {
            userName
            firstName
            lastName
          }
          exchangeName
        }
      }
    }
  }
`

export const updateTargetLos =  graphql`
  mutation visit_updateTargetLos_Mutation(
    $visitId: Long!, $targetLos: Int!) {
    visitMutation {
      updateTargetLos(visitId: $visitId, targetLos: $targetLos) {
        id
        tlos
        plan {
          id
          milestones {
            id
            dueDate
          }
        }
      }
    }
  }
`

export const updateEstimatedTransferDate = graphql`
  mutation visit_updateEstimatedTransferDate_Mutation(
    $visitId: Long!
    $anticipatedTransferTime: ZonedDateTime
    $anticipatedTransferTimeAutoPopulated: Boolean!
  ) {
    visitMutation {
      updateEstimatedTransferDate(
        visitId: $visitId
        anticipatedTransferTime: $anticipatedTransferTime
        anticipatedTransferTimeAutoPopulated: $anticipatedTransferTimeAutoPopulated
      ) {
        id
        anticipatedTransferTime
        anticipatedTransferTimeAutoPopulated
      }
    }
  }
`
