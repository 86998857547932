import React from 'react'

import HistoryIcon from '../../image/img/barrier-message-history-button.svg'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'

import './BarrierHistoryButton.scss'

const BarrierHistoryButton = ({ onViewBarrierInfoHistory }) => {
  return (
    <Tooltip title={'Message History'} placement={'bottom-end'}>
      <div
        className={'BarrierHistoryButton'}
        onClick={onViewBarrierInfoHistory}>
        <img src={HistoryIcon} className={'HistoryIcon'} />
      </div>
    </Tooltip>
  )
}

BarrierHistoryButton.propTypes = {
  onViewBarrierInfoHistory: PropTypes.func.isRequired,
}

export default BarrierHistoryButton
