import { graphql } from 'react-relay'

export const createPlanNote = graphql`
  mutation note_createPlanNote_Mutation($planId: UUID!, $note: NoteInput!) {
    noteMutation {
      createPlanNote(planId: $planId, note: $note) {
        id
        notes {
          ...note_FullPlanNote
        }
      }
    }
  }
`

export const updatePlanNote = graphql`
  mutation note_updatePlanNote_Mutation($noteId: UUID!, $text: String!) {
    noteMutation {
      updateNote(noteId: $noteId, text: $text) {
        id
        notes {
          ...note_FullPlanNote
        }
      }
    }
  }
`

export const removePlanNote = graphql`
  mutation note_removePlanNote_Mutation($noteId: UUID!) {
    noteMutation {
      removeNote(noteId: $noteId) {
        id
        notes {
          ...note_FullPlanNote
        }
      }
    }
  }
`

export const createBarrierNote = graphql`
  mutation note_createBarrierNote_Mutation($barrierId: UUID!, $text: String!) {
    noteMutation {
      createBarrierNote(barrierId: $barrierId, text: $text) {
        id
        barriers {
          ...note_FullBarrier
        }
      }
    }
  }
`

export const updateBarrierNote = graphql`
  mutation note_updateBarrierNote_Mutation($noteId: UUID!, $text: String!) {
    noteMutation {
      updateNote(noteId: $noteId, text: $text) {
        id
        barriers {
          ...note_FullBarrier
        }
      }
    }
  }
`
export const removeBarrierNote = graphql`
  mutation note_removeBarrierNote_Mutation($noteId: UUID!) {
    noteMutation {
      removeNote(noteId: $noteId) {
        id
        barriers {
          ...note_FullBarrier
        }
      }
    }
  }
`

const FullPlanNote = graphql`
  fragment note_FullPlanNote on PlanNote {
    id
    text
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
    pinned
  }
`

const FullBarrier = graphql`
  fragment note_FullBarrier on Barrier {
    id
    name
    description
    status
    pinned
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
    category {
      code
      value
    }
    escalation {
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
      }
    }
    owner
    ownerRole {
      code
      value
    }
    notes {
      id
      text
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
      pinned
    }
    escalation {
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
      }
    }
  }
`
