import React from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'

import { AssignedLocation } from './'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('UnitFacilitiesRow', theme => {

  return {
    container: {
      padding: '.3em',
      marginBottom: '.6em',
      overflow: 'auto',
      width: 'calc(100% - 3px)',
      paddingTop: '7px',
      backgroundColor: '#fff',
      boxShadow:
        'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    },
    unitCol: {
      width: '160px',
      float: 'left',
      textAlign: 'center',
      lineHeight: '63px',
      fontSize: '1.2em'
    },
    rowCol: {
      float: 'left'
    },
  }
})

export const UnitFacilitiesRow = (
  {
    facilities,
    facilitySelections,
    disabled = false,
    onFacilityClick = () => {}
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)
  const originalFacilitySet = new Set(facilitySelections)

  const isFacilitySelected = facRef => originalFacilitySet.has(facRef)

  const onClickHandler = (facId) => onFacilityClick(facId, !isFacilitySelected(facId))

  return (
    <div className={classNames(classes.container)}>
      <div className={classNames(classes.unitCol)}>
        FACILITIES
      </div>
      <div className={classNames(classes.rowCol)}>
      {(sortBy(facilities, [facility => facility.ordinal]).map(fac => {
        return (
          <AssignedLocation
            key={fac.exchangeName}
            name={fac.name}
            disabled={disabled}
            type="facility"
            selected={isFacilitySelected(fac.exchangeName)}
            onClick={() => {onClickHandler(fac.exchangeName)}}
          />
        )
      }))}
      </div>
    </div>
  )
}

UnitFacilitiesRow.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

UnitFacilitiesRow.propTypes = {
  facilities: PropTypes.array.isRequired,
  facilitySelections: PropTypes.array.isRequired,
  onFacilityClick: PropTypes.func,
}
