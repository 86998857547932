import React from 'react'
import PropTypes from 'prop-types'
import BarrierIcon from 'mdi-react/GateIcon'
import ActionIcon from 'mdi-react/CalendarCheckIcon'
import { Chip } from 'material-ui'
import Avatar from 'material-ui/Avatar'

import {
  red400 as BarrierColor,
  blue600 as ActionColor,
  white as BarrierBgColor,
} from 'material-ui/styles/colors'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('Barrier', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    chip: {
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
    barrierIcon: {
      fill: BarrierColor,
      marginLeft: 0,
      position: 'relative',
    },
    actionIcon: {
      fill: ActionColor,
    },
  }
})

export const ItemCount = (
  {
    gap = false,
    small = false,
    count,
    type,
    avatarStyle = { verticalAlign: -2, marginTop: 4 },
    countStyle = {},
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)

  let labelPadding
  labelPadding = '0'

  const style = {
    backgroundColor: 'transparent',
    display: 'inline-block',
    width: small ? '21px' : '24px',
    height: small ? '21px' : '24px',
    ...(gap ? { marginLeft: '20px' } : {}),
  }
  const labelStyle = {
    ...countStyle,
    height: small ? '21px' : '24px',
    lineHeight: small ? '21px' : '24px',
    padding: labelPadding,
  }

  return (
    <Chip
      className={classNames(classes.chip)}
      style={style}
      labelStyle={labelStyle}>
      <Avatar
        style={{
          ...avatarStyle,
          marginRight: 2,
          width: 22,
          height: 22,
          padding: 0,
        }}
        backgroundColor={BarrierBgColor}
        icon={
          type === 'barrier' ? (
            <div className={classNames(classes.barrierIcon)}>
              <BarrierIcon />
            </div>
          ) : (
            <div className={classNames(classes.actionIcon)}>
              <ActionIcon />
            </div>
          )
        }
      />
      {count}
    </Chip>
  )
}

ItemCount.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

ItemCount.propTypes = {
  gap: PropTypes.bool,
  small: PropTypes.bool,
  count: PropTypes.number,
  type: PropTypes.string,
  avatarStyle: PropTypes.object,
  countStyle: PropTypes.object,
}
