import React from 'react'
import {compose, mapProps, withState} from 'recompose'
import {connect} from 'react-redux'
import CombinedMilestoneActionSection from './CombinedMilestoneActionSection'
import {
  updateMilestoneStatus,
  removePlanTemplate,
} from '../../graphql/relay/queries/plan'
import {environment} from '../../graphql/relay/env'
import {drawers} from '../../containers/drawer-component-mapping'
import {
  showDrawer
} from '../../ducks/views'
import { setFilter, setDraftFilter, resetFilter } from '../../ducks/plan-todo-filter'
import { selectItem } from '../../ducks/app-selections'
import { bindActionCreators } from 'redux'
import {
  createPlanAction,
  removeAction,
  updateActionText
} from '../../graphql/relay/queries/action'
import {
  pinMilestoneAction,
  unpinMilestoneAction,
  pinPlanAction,
  unpinPlanAction
} from '../../graphql/relay/queries/pin'
import { handleMutation } from '../../graphql/relay/queries/mutation'

export const CombinedMilestoneActionSectionGQL = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  mapProps(props => newProps(props))
)(CombinedMilestoneActionSection)

const newProps = props => {
  const {
    actions: {
      showDrawer,
      setFilter,
      setDraftFilter,
      resetFilter,
      selectItem
    },
    plan,
    visit,
    filter,
    draftFilter,
    selected,
    userActions,
  } = props

  const onUpdateMilestoneStatus = (milestone, status) => {
    const variables = { milestoneId: milestone.id, status: status }
    handleMutation(environment, updateMilestoneStatus, variables)
  }

  const onApplyPlan = () => {
    const {
      id,
      patient,
      decisionTime,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
    } = visit

    const title = `Apply Plan for ${patient.lastName}, ${patient.firstName}`

    showDrawer(drawers.CHOOSE_PLAN, {
      title,
      id,
      plan,
      patient,
      decisionTime,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
    })
  }

  const onRemovePlan = (templateId) => {
    const variables = { planId: plan.id, templateId: templateId }

    handleMutation(environment, removePlanTemplate, variables)
  }

  const onCreatePlanAction = text => {
    const variables = { planId: plan.id, action: { text: text, pinned: false } }
    handleMutation(
      environment,
      createPlanAction,
      variables
    )
  }

  const selectedItem = selected.currentItem

  const onTodoTextClick = (e, item) => {
    e.stopPropagation()
    selectItem(item)
  }

  const onUpdateActionText = (actionId, text) => {
    if (text && text.length > 0) {
      const variables = { actionId: actionId, text: text }
      handleMutation(environment, updateActionText, variables)
    } else {
      const variables = { actionId: actionId }
      handleMutation(environment, removeAction, variables)
    }
  }

  const onRemoveAction = actionId => {
    const variables = { actionId: actionId }
    handleMutation(environment, removeAction, variables)
  }

  const onUpdatePinned = action => {
    let mutationMethod
    if (action.track === 'T') {
      mutationMethod = action.pinned ? unpinPlanAction : pinPlanAction
    } else {
      mutationMethod = action.pinned ? unpinMilestoneAction : pinMilestoneAction
    }

    const variables = {
      itemId: action.id,
    }

    handleMutation(environment, mutationMethod, variables)
  }

  return {
    ...props,
    onUpdateMilestoneStatus,
    onApplyPlan,
    onRemovePlan,
    onCreatePlanAction,
    setFilter,
    setDraftFilter,
    resetFilter,
    filter,
    draftFilter,
    selectedItem,
    onTodoTextClick,
    onUpdateActionText,
    onRemoveAction,
    onUpdatePinned,
    userActions
  }
}

function mapStateToProps(state) {
  return {
    filter: state.planTodoFilterState.filter,
    draftFilter: state.planTodoFilterState.draftFilter,
    selected: state.selectAppState
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
        setFilter,
        setDraftFilter,
        resetFilter,
        selectItem
      },
      dispatch
    ),
  }
}
