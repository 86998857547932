/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ComplianceListFilterQueryVariables = {||};
export type ComplianceListFilterQueryResponse = {|
  +viewer: {|
    +workspace: {|
      +variance: $ReadOnlyArray<{|
        +facility: {|
          +exchangeName: string
        |},
        +department: ?{|
          +exchangeName: string,
          +name: string,
        |},
        +totalIssues: ?number,
        +drgs: ?number,
        +progression: ?number,
        +plans: ?number,
        +dischargeArrangements: ?number,
        +plannedDischarges: ?number,
      |}>
    |}
  |}
|};
export type ComplianceListFilterQuery = {|
  variables: ComplianceListFilterQueryVariables,
  response: ComplianceListFilterQueryResponse,
|};
*/


/*
query ComplianceListFilterQuery {
  viewer {
    workspace {
      variance {
        facility {
          exchangeName
          id
        }
        department {
          exchangeName
          name
          id
        }
        totalIssues
        drgs
        progression
        plans
        dischargeArrangements
        plannedDischarges
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalIssues",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drgs",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progression",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plans",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeArrangements",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedDischarges",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ComplianceListFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarianceMeta",
                "kind": "LinkedField",
                "name": "variance",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Facility",
                    "kind": "LinkedField",
                    "name": "facility",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "department",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ComplianceListFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Workspace",
            "kind": "LinkedField",
            "name": "workspace",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VarianceMeta",
                "kind": "LinkedField",
                "name": "variance",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Facility",
                    "kind": "LinkedField",
                    "name": "facility",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "department",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v1/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80b898565ccf27b83fc84f6bd99c33a7",
    "id": null,
    "metadata": {},
    "name": "ComplianceListFilterQuery",
    "operationKind": "query",
    "text": "query ComplianceListFilterQuery {\n  viewer {\n    workspace {\n      variance {\n        facility {\n          exchangeName\n          id\n        }\n        department {\n          exchangeName\n          name\n          id\n        }\n        totalIssues\n        drgs\n        progression\n        plans\n        dischargeArrangements\n        plannedDischarges\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f67f1173d820c86e506a1bda6dff528';

module.exports = node;
