/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ActionStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type action_updateActionStatus_MutationVariables = {|
  actionId: any,
  status: ActionStatus,
|};
export type action_updateActionStatus_MutationResponse = {|
  +actionMutation: ?{|
    +updateActionStatus: ?{|
      +id: string,
      +actions: $ReadOnlyArray<?{|
        +id: string,
        +status: ActionStatus,
        +pinned: boolean,
        +auditMeta: ?{|
          +created: any,
          +createdBy: {|
            +userName: string,
            +firstName: string,
            +lastName: string,
          |},
          +updated: any,
          +updatedBy: {|
            +userName: string,
            +firstName: string,
            +lastName: string,
          |},
        |},
      |}>,
      +milestones: $ReadOnlyArray<?{|
        +id: string,
        +dueDate: ?any,
        +actions: $ReadOnlyArray<?{|
          +id: string,
          +status: ActionStatus,
          +pinned: boolean,
          +auditMeta: ?{|
            +updated: any,
            +updatedBy: {|
              +userName: string,
              +firstName: string,
              +lastName: string,
            |},
          |},
        |}>,
      |}>,
    |}
  |}
|};
export type action_updateActionStatus_Mutation = {|
  variables: action_updateActionStatus_MutationVariables,
  response: action_updateActionStatus_MutationResponse,
|};
*/


/*
mutation action_updateActionStatus_Mutation(
  $actionId: UUID!
  $status: ActionStatus!
) {
  actionMutation {
    updateActionStatus(actionId: $actionId, status: $status) {
      id
      actions {
        id
        status
        pinned
        auditMeta {
          created
          createdBy {
            userName
            firstName
            lastName
            id
          }
          updated
          updatedBy {
            userName
            firstName
            lastName
            id
          }
        }
      }
      milestones {
        id
        dueDate
        actions {
          id
          status
          pinned
          auditMeta {
            updated
            updatedBy {
              userName
              firstName
              lastName
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "actionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "actionId",
    "variableName": "actionId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "updatedBy",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueDate",
  "storageKey": null
},
v13 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "updatedBy",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "action_updateActionStatus_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateActionStatus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "action_updateActionStatus_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateActionStatus",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e587fa1ee1b363d8974b8079f41bc9c",
    "id": null,
    "metadata": {},
    "name": "action_updateActionStatus_Mutation",
    "operationKind": "mutation",
    "text": "mutation action_updateActionStatus_Mutation(\n  $actionId: UUID!\n  $status: ActionStatus!\n) {\n  actionMutation {\n    updateActionStatus(actionId: $actionId, status: $status) {\n      id\n      actions {\n        id\n        status\n        pinned\n        auditMeta {\n          created\n          createdBy {\n            userName\n            firstName\n            lastName\n            id\n          }\n          updated\n          updatedBy {\n            userName\n            firstName\n            lastName\n            id\n          }\n        }\n      }\n      milestones {\n        id\n        dueDate\n        actions {\n          id\n          status\n          pinned\n          auditMeta {\n            updated\n            updatedBy {\n              userName\n              firstName\n              lastName\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe6819f3fa5252294587f014434b28fc';

module.exports = node;
