import React, {Component} from 'react'
import CodeTableRowList from '../../components/codeTable/CodeTableRowList'
import {showDrawer} from '../../ducks/views'
import {drawers} from '../drawer-component-mapping'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import IconButton from 'material-ui/IconButton'
import OpenDrawer from 'material-ui/svg-icons/content/add-circle-outline'
import PropTypes from 'prop-types'
import {createCodeTableEntry, modifyCodeTableEntry} from '../../graphql/relay/queries/codeTable'
import {createFragmentContainer, graphql} from 'react-relay'


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer
      },
      dispatch
    ),
  }
}

const rowStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingTop: "15px",
  paddingBottom: "15px"
}

const columnStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "5px",
  paddingBottom: "5px"
}


class CodeTableEditor extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    addDisabled: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.shape({
      showDrawer: PropTypes.func
    }),
  }
  constructor(props) {
    super(props)
  }

  onEditHandler = (rowData) => {
    const drawerTitle = `Edit a ${this.props.title}`

    const data = {
      ...rowData,
      active: rowData.active,
      codeTableType: this.props.type
    }

    this.openDrawer(drawerTitle, true, data, modifyCodeTableEntry)
  }

  onAddHandler = () => {
    const drawerTitle = `Add a ${this.props.title}`

    const data = {
      code: '',
      value: '',
      ordinal: this.getMaxSortOrder(),
      active: true,
      codeTableType: this.props.type,
    }

    this.openDrawer(drawerTitle, false, data, createCodeTableEntry)
  }

  openDrawer = (drawerTitle, isEdit, data, mutation) => {
    this.props.actions.showDrawer(drawers.CODE_TABLE_DRAWER, {
      title: drawerTitle,
      isEdit,
      data,
      mutation
    })
  }

  getMaxSortOrder = () => {
    const sortArray = this.props.list.map(item => item.ordinal)
    const max = Math.max(...sortArray)
    return max ? max + 1 : 1
  }


  render() {
    return (
      <div style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h1 style={{display: "inline", margin: 0 }}>{this.props.title}</h1>
          {this.props.addDisabled !== "Y" && (<IconButton
            style={{padding: 8, width: 40, height: 40 }}
            onClick={this.onAddHandler}>
            <OpenDrawer />
          </IconButton>)}
        </div>
        <table style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}>
          <colgroup>
            <col width="5%"/>
            <col width="30%"/>
            <col width="50%"/>
            <col width="50%"/>
            <col width="50%"/>
          </colgroup>
          <thead style={{fontSize: "16px"}}>
            <tr style={rowStyle}>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
              <th style={columnStyle} bgcolor="#d3d3d3">Code</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Description</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Order</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Active</th>
            </tr>
          </thead>
          <tbody>
            <CodeTableRowList list={this.props.list} onEdit={this.onEditHandler}/>
          </tbody>
        </table>
      </div>
    )
  }
}

const CodeTableEditorRedux = connect(null, mapDispatchToProps)(CodeTableEditor)
export default createFragmentContainer(CodeTableEditorRedux, {
  list: graphql`
    fragment CodeTableEditor_list on CodeTable @relay(plural: true) {
      ...CodeTableRowList_list
      ordinal
    }
  `
})
