import gql, {injectForGqlFile} from '../util/gql'

export const WorkspaceGQL = {
  fragments: {
    FullWorkspacePlanVisit: gql`
      fragment FullWorkspacePlanVisit on Workspace {
        visit {
          ...FullVisit
        }
        bed {
          ...WorkspaceBed
        }
        plan {
          ...FullPlan
        }
      }
    `,
    SummaryWorkspacePlanVisit: gql`
      fragment SummaryWorkspacePlanVisit on Workspace {
        visit {
          ...FullVisit
        }
        bed {
          exchangeName
        }
        plan {
          ...SummaryPlan
        }
      }
    `,
    ComplianceWorkspace: gql`
      fragment ComplianceWorkspace on Workspace {
        visit {
          ...ComplianceVisit
        }
        bed {
          exchangeName
        }
        plan {
          id
          progressionIndicator
          toBeAdmitted
          milestones {
            id
          }
          appliedTemplates {
            id
            name
          }
          careTeamMembers {
            ...FullCareTeamMember
          }
        }
      }
    `,
    EscalationListWorkspace: gql`
      fragment EscalationListWorkspace on Workspace {
        visit {
          id
          visitNumber
          homeBedExchangeName
          admissionType
          admissionTime
          patientProblem
          admittingComplaint
          bedRequestStatus
          visitStatus
          anticipatedDischargeTime
          targetDischargeTime
          decisionTime
          tlos
          currentDayOfStay
          insurancePlans {
            planName
            priority
          }
          meta {
            ...FullVisitMeta
          }
          drgAttributes {
            ...FullDrgAttributes
          }
          drgUpdatedBy {
            ...FullAuditUser
          }
          patient {
            ...FullPatient
          }
          workingDischargeDisposition {
            ...CodeValue
          }
          dischargeVendor
          attendingPhysician {
            ...FullPersonnel
          }
        }
        bed {
          exchangeName
        }
        plan {
          id
          progressionIndicator
          toBeAdmitted
          meta {
            ...FullPlanMeta
          }
          milestones {
            id
          }
          barriers {
            ...FullBarrier
          }
          careTeamMembers {
            ...FullCareTeamMember
          }
        }
      }
    `,
    KPIListWorkspace: gql`
      fragment KPIListWorkspace on Workspace {
        visit {
          id
          visitNumber
          homeBedExchangeName
          admissionType
          admissionTime
          patientProblem
          admittingComplaint
          bedRequestStatus
          visitStatus
          anticipatedDischargeTime
          targetDischargeTime
          decisionTime
          tlos
          currentDayOfStay
          insurancePlans {
            planName
            priority
          }
          meta {
            ...FullVisitMeta
          }
          drgAttributes {
            ...FullDrgAttributes
          }
          drgUpdatedBy {
            ...FullAuditUser
          }
          patient {
            ...FullPatient
          }
          workingDischargeDisposition {
            ...CodeValue
          }
          dischargeVendor
          attendingPhysician {
            ...FullPersonnel
          }
        }
        bed {
          exchangeName
        }
        plan {
          id
          progressionIndicator
          appliedTemplates {
            name
          }
          meta {
            ...FullPlanMeta
          }
          milestones {
            id
          }
          barriers {
            ...FullBarrier
          }
          careTeamMembers {
            ...FullCareTeamMember
          }
        }
      }
    `,
    PlanListWorkspace: gql`
      fragment PlanListWorkspace on Workspace {
        visit {
          id
          visitNumber
          homeBedExchangeName
          admissionType
          admissionTime
          patientProblem
          readmissionRisk
          readmission
          admittingComplaint
          bedRequestStatus
          visitStatus
          anticipatedDischargeTime
          targetDischargeTime
          decisionTime
          decisionTimeEditable
          decisionTimeUseMidnight
          tlos
          currentDayOfStay
          patientAlerts {
            id
            alertType
            value
          }
          insurancePlans {
            planName
            priority
          }
          meta {
            ...FullVisitMeta
          }
          drgAttributes {
            ...FullDrgAttributes
          }
          drgUpdatedBy {
            ...FullAuditUser
          }
          patient {
            ...FullPatient
          }
          workingDischargeDisposition {
            ...CodeValue
          }
          dischargeVendor
          attendingPhysician {
            ...FullPersonnel
          }
        }
        bed {
          exchangeName
        }
        plan {
          id
          progressionIndicator
          toBeAdmitted
          meta {
            ...FullPlanMeta
          }
          milestones {
            id
            actions {
              id
              text
              pinned
            }
          }
          barriers {
            ...FullBarrier
          }
          actions {
            id
            text
            status
            pinned
          }
          notes {
            id
            text
            pinned
          }
          careTeamMembers {
            ...FullCareTeamMember
          }
        }
      }
    `,
    NavigatorWorkspace: gql`
      fragment NavigatorWorkspace on Workspace {
        visit {
          id
          visitNumber
          admissionType
          bedRequestStatus
          visitStatus
          readmissionRisk
          readmission
          tlos
          meta {
            ...FullVisitMeta
          }
          patient {
            id
            mrn
            firstName
            lastName
          }
          attendingPhysician {
            ...FullPersonnel
          }
        }
        bed {
          exchangeName
        }
        plan {
          id
          progressionIndicator
          toBeAdmitted
          milestones {
            id
          }
          barriers {
            ...FullBarrier
          }
          careTeamMembers {
            ...FullCareTeamMember
          }
          meta {
            ...FullPlanMeta
          }
        }
      }
    `,
  },
  queries: {
    FullWorkspacePlanVisitByVisitIds: gql`
      query FullWorkspacePlanVisitByVisitIds($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...FullWorkspacePlanVisit
        }
      }
    `,
    WorkspacePlan: gql`
      query FullWorkspacePlan($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          plan {
            ...FullPlan
          }
          visit {
            id
          }
        }
      }
    `,
    SummaryWorkspacePlanVisitByVisitIds: gql`
      query SummaryWorkspacePlanVisitByVisitIds($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...SummaryWorkspacePlanVisit
        }
      }
    `,
    PlanListWorkspaces: gql`
      query PlanListWorkspaces($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...PlanListWorkspace
        }
      }
    `,
    NavigatorWorkspaces: gql`
      query NavigatorWorkspaces($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...NavigatorWorkspace
        }
      }
    `,
    EscalationListWorkspaces: gql`
      query EscalationListWorkspaces($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...EscalationListWorkspace
        }
      }
    `,
    KPIListWorkspaces: gql`
      query KPIListWorkspaces($visitIds: [Long]!) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...KPIListWorkspace
        }
      }
    `,
    ComplianceWorkspaces: gql`
      query ComplianceWorkspaces($visitIds: [Long!]) {
        workspaces(visitIds: $visitIds, limitToOpenBarriers: false) {
          ...ComplianceWorkspace
        }
      }
    `,
  },
  mutations: {},
}

injectForGqlFile(WorkspaceGQL)
