/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BarrierCategoryInput = {|
  code: string,
  description: string,
  sortOrder: number,
  active: boolean,
  sourceTypeCodes?: ?$ReadOnlyArray<?string>,
|};
export type barrierCategory_modifyBarrierCategoryEntry_MutationVariables = {|
  barrierCategoryInput: BarrierCategoryInput
|};
export type barrierCategory_modifyBarrierCategoryEntry_MutationResponse = {|
  +barrierCategoryMutation: ?{|
    +modifyBarrierCategoryEntry: ?{|
      +id: string,
      +code: string,
      +value: string,
      +ordinal: number,
      +active: boolean,
      +sourceTypes: ?$ReadOnlyArray<?{|
        +id: string,
        +code: string,
        +value: string,
      |}>,
    |}
  |}
|};
export type barrierCategory_modifyBarrierCategoryEntry_Mutation = {|
  variables: barrierCategory_modifyBarrierCategoryEntry_MutationVariables,
  response: barrierCategory_modifyBarrierCategoryEntry_MutationResponse,
|};
*/


/*
mutation barrierCategory_modifyBarrierCategoryEntry_Mutation(
  $barrierCategoryInput: BarrierCategoryInput!
) {
  barrierCategoryMutation {
    modifyBarrierCategoryEntry(barrierCategoryInput: $barrierCategoryInput) {
      id
      code
      value
      ordinal
      active
      sourceTypes {
        id
        code
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierCategoryInput"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BarrierCategoryMutation",
    "kind": "LinkedField",
    "name": "barrierCategoryMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "barrierCategoryInput",
            "variableName": "barrierCategoryInput"
          }
        ],
        "concreteType": "BarrierCategory",
        "kind": "LinkedField",
        "name": "modifyBarrierCategoryEntry",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordinal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BarrierSourceType",
            "kind": "LinkedField",
            "name": "sourceTypes",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "barrierCategory_modifyBarrierCategoryEntry_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "barrierCategory_modifyBarrierCategoryEntry_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0af1431bfcdc9d58263839e33b95e260",
    "id": null,
    "metadata": {},
    "name": "barrierCategory_modifyBarrierCategoryEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation barrierCategory_modifyBarrierCategoryEntry_Mutation(\n  $barrierCategoryInput: BarrierCategoryInput!\n) {\n  barrierCategoryMutation {\n    modifyBarrierCategoryEntry(barrierCategoryInput: $barrierCategoryInput) {\n      id\n      code\n      value\n      ordinal\n      active\n      sourceTypes {\n        id\n        code\n        value\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c668a95ee7f73edc0c6c85df59b75cdb';

module.exports = node;
