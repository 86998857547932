/*eslint-disable react/no-set-state */
import React from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import IconButton from 'material-ui/IconButton'
import ModeEdit from '../icons/ModeEdit'
import Delete from '../icons/Delete'
import PropTypes from 'prop-types'
import { ConfirmDialog } from '../common'

class BoardTemplateRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteMode: false
    }
  }

  handleConfirmDeleteBoard = () => {
    this.props.onDelete(this.props.rowData)
    this.setState({isDeleteMode: false})
  }

  handleCancelDeleteBoard = () => {
    this.setState({isDeleteMode: false})
  }

  render() {
    const { rowData, onEdit, onDelete } = this.props
    const {
      id,
      name,
      description,
      boardType,
      columns
    } = rowData

    const rowStyle = {
      border: "1px rgb(224, 224, 224)",
    }

    const columnStyle = {
      border: "1px solid rgb(224, 224, 224)",
      paddingLeft: "15px",
      paddingRight: "15px"
    }

    const ellipsisDiv = {
      width: "50px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    }

    return (
      <React.Fragment>
        <tr style={rowStyle}>
          <td style={columnStyle} bgcolor="white">
            <IconButton onClick={() => onEdit(rowData)}>
              <ModeEdit/>
            </IconButton>
          </td>
          <td style={columnStyle} bgcolor="white">{name}</td>
          <td style={columnStyle} bgcolor="white">{description}</td>
          <td style={columnStyle} bgcolor="white">{boardType}</td>
          <td style={columnStyle} bgcolor="white">{columns.length}</td>
          <td style={columnStyle} bgcolor="white">
            {(() => {
              switch (id) {
                case "BoardTemplate:b2ffa97b-1e59-40b6-a3f3-402f8119331b":
                  return <IconButton disabled><Delete/></IconButton>
                default:
                  return <IconButton onClick={() => this.setState({isDeleteMode: true})}><Delete/></IconButton>
              }
            })()}
          </td>
        </tr>
        { this.state.isDeleteMode !== false &&
          <ConfirmDialog
            open={this.state.isDeleteMode !== false}
            title={`Delete Board Template: ${ name }`}
            confirmText="Delete"
            cancelText="Cancel"
            message="The above Board Template will be deleted and this action cannot be undone."
            onCancelClick={this.handleCancelDeleteBoard}
            onConfirmClick={this.handleConfirmDeleteBoard}
          />
        }
      </React.Fragment>
    )
  }
}

BoardTemplateRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default createFragmentContainer(BoardTemplateRow, {
 rowData: graphql`
    fragment BoardTemplateRow_rowData on BoardTemplate {
      id
      name
      boardType
      description
      template
      columns {
        field
      }
    }
  `,
})
