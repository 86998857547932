import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'
import NoteIcon from 'mdi-react/NoteIcon'
import { yellow600 as NoteColor } from 'material-ui/styles/colors'
import { Date } from '../date'
import { AuditUser } from '../audit'
import { StatefulTextField } from '../common'
import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { Pin } from '../plan/Pin'

const styleSheet = createStyleSheet('Note', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    noteIcon: {
      fill: NoteColor,
      marginLeft: '13px',
    },
    item: {
      padding: '0 .5em 0 .1em',
      margin: '-1px',
      border: '1px solid #d3d3d3',
    },
    primaryLine: {
      width: 'calc(100% - 100px)',
      position: 'relative',
      top: '-2px',
      marginLeft: '10px',
    },
    primaryLineExpanded: {
      display: 'inline-block',
      width: 'calc(100% - 60px)',
      position: 'relative',
      marginLeft: '10px',
    },
    secondaryLine: {
      minHeight: '1.5em',
    },
    secondaryLinePullRight: {
      marginTop: '10px',
      marginRight: '5px',
      float: 'right',
    },
    createdBy: {
      marginRight: '1em',
    },
    button: {
      border: '1px solid lightgrey !important',
    },
    expanded: {
      padding: '0 .5em .5em .5em',
      marginTop: '0.5em',
      marginBottom: '0.5em',
    },
    topHalf: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
  }
})

export const Note = (
  {
    item,
    expanded,
    onClick,
    onUpdatePlanNote,
    onRemovePlanNote,
    onUpdatePinned,
    showNoteIcon,
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)

// Safari 3.0+ "[object HTMLElementConstructor]"
  const isSafari =  Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0

  return (
    <div
      className={classNames(classes.item, expanded ? classes.expanded : null)}
      onClick={onClick ? e => onClick(e, item) : () => {}}>
      <div className={classes.topHalf}>
        {showNoteIcon && <NoteIcon className={classNames(classes.noteIcon)} />}
        <Pin item={item} onClick={onUpdatePinned} showButtonBorder />
        {!expanded && (
          <span className={classNames(classes.primaryLine)}>{item.text}</span>
        )}
        {expanded && (
          <span className={classNames(classes.primaryLineExpanded)}>
            <StatefulTextField
              text={item.text}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Note will be deleted',
                multiLine: isSafari,
              }}
              onEnter={value => {
                if (value && value.length > 0) {
                  onUpdatePlanNote(item.id, value)
                } else {
                  onRemovePlanNote(item.id)
                }
              }}
              maxNumberOfChars={255}
              allowEmptyText
              clearOnTextEntered={false}
              showCharacterCount
              blurOnEnter
            />
          </span>
        )}
      </div>
      {expanded && (
        <div className={classNames(classes.secondaryLine)}>
          <FlatButton
            className={classNames(classes.button)}
            label={'Delete'}
            onClick={() => onRemovePlanNote(item.id)}
          />
          <div className={classNames(classes.secondaryLinePullRight)}>
            Updated: <Date dateString={item.auditMeta.updated} /> By:{' '}
            <AuditUser user={item.auditMeta.updatedBy} />
          </div>
          <div
            className={classNames(
              classes.secondaryLinePullRight,
              classes.createdBy
            )}>
            Created: <Date dateString={item.auditMeta.created} /> By:{' '}
            <AuditUser user={item.auditMeta.createdBy} />
          </div>
        </div>
      )}
    </div>
  )
}

Note.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

Note.propTypes = {
  item: PropTypes.object,
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onUpdatePlanNote: PropTypes.func.isRequired,
  onRemovePlanNote: PropTypes.func.isRequired,
  onUpdatePinned: PropTypes.func.isRequired,
  showNoteIcon: PropTypes.bool,
}
