import gql from '../util/gql'

export const KpiTrendGQL = {
  fragments: {},
  queries: {
    reportQuery: gql`
      query reportQuery {
        reportQuery {
          kpiTrends {
            name
            inPatientVolume
            obsPatientVolume
            kpis {
              name
              min
              max
              average
              ytdAverage
              graphData
            }
          }
        }
      }
    `,
  },
  mutations: {},
}
