import { graphql } from 'react-relay'

export const upgradeProgressionIndicator = graphql`
  mutation plan_updateprogressionindicator_Mutation(
    $planId: UUID!
    $progressionIndicator: ProgressionIndicator!
  ) {
    planMutation {
      updateProgressionIndicator(
        planId: $planId
        progressionIndicator: $progressionIndicator
      ) {
        id
        progressionIndicator
      }
    }
  }
`

export const updateMilestoneStatus = graphql`
  mutation plan_updateMilestoneStatus_Mutation(
    $milestoneId: UUID!
    $status: MilestoneStatus!
  ) {
    planMutation {
      updateMilestoneStatus(milestoneId: $milestoneId, status: $status) {
        id
        appliedTemplates {
          id
          name
          description
        }
        milestones {
          id
          name
          description
          track
          dueDate
          completedDate
          status
          planTemplateName
        }
        meta {
          milestoneMeta {
            onTime
            overdue
            completed
          }
        }
      }
    }
  }
`

export const updateToBeAdmitted = graphql`
  mutation plan_updatetobeadmitted_Mutation(
    $planId: UUID!
    $toBeAdmitted: Boolean!
  ) {
    planMutation {
      updateToBeAdmitted(planId: $planId, toBeAdmitted: $toBeAdmitted) {
        id
        toBeAdmitted
      }
    }
  }
`

export const updateWorkingDischargeDisposition = graphql`
  mutation plan_updateWorkingDischargeDisposition_Mutation(
    $visitId: Long!
    $dispositionCode: String!
    $dischargeVendor: String!
  ) {
    visitMutation {
      updateWorkingDischargeDisposition(
        visitId: $visitId
        dispositionCode: $dispositionCode
        dischargeVendor: $dischargeVendor
      ) {
        id
      }
    }
  }
`
export const applyPlanTemplate = graphql`
  mutation plan_applyPlanTemplate_Mutation($planId: UUID!, $templateId: UUID!) {
    planMutation {
      applyPlanTemplate(planId: $planId, templateId: $templateId) {
        id
        appliedTemplates {
          id
          name
          description
          documentURL
          removed
        }
        careTeamMembers {
          id
          name
          primary
          role {
            id
            code
            value
          }
        }
        progressionIndicator
        toBeAdmitted
        meta {
          milestoneMeta {
            onTime
            overdue
            completed
          }
          lengthOfStayMeta {
            forecast
            cappedForecast
          }
          actualOE
        }
        milestones {
          id
          name
          description
          track
          dueDate
          completedDate
          status
          planTemplateName
          actions {
            id
            text
            status
            pinned
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
            milestoneTemplateName
          }
        }
        barriers {
          id
          name
          description
          status
          pinned
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
          category {
            code
            value
          }
          escalation {
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
            }
          }
          owner
          ownerRole {
            code
            value
          }
          notes {
            id
            text
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
            pinned
          }
          escalation {
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
            }
          }
        }
        actions {
          id
          text
          status
          pinned
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
        }
        notes {
          id
          text
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
          pinned
        }
      }
    }
  }
`

export const removePlanTemplate = graphql`
  mutation plan_removePlanTemplate_Mutation($planId: UUID!, $templateId: UUID!) {
    planMutation {
      removePlanTemplate(planId: $planId, templateId: $templateId) {
        id
        appliedTemplates {
          id
          name
          description
          documentURL
          removed
        }
        careTeamMembers {
          id
          name
          primary
          role {
            id
            code
            value
          }
        }
        progressionIndicator
        toBeAdmitted
        meta {
          milestoneMeta {
            onTime
            overdue
            completed
          }
          lengthOfStayMeta {
            forecast
            cappedForecast
          }
          actualOE
        }
        milestones {
          id
          name
          description
          track
          dueDate
          completedDate
          status
          planTemplateName
          actions {
            id
            text
            status
            pinned
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
            milestoneTemplateName
          }
        }
        barriers {
          id
          name
          description
          status
          pinned
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
          category {
            code
            value
          }
          escalation {
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
            }
          }
          owner
          ownerRole {
            code
            value
          }
          notes {
            id
            text
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
            pinned
          }
          escalation {
            auditMeta {
              created
              createdBy {
                userName
                firstName
                lastName
              }
            }
          }
        }
        actions {
          id
          text
          status
          pinned
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
        }
        notes {
          id
          text
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
          pinned
        }
      }
    }
  }
`

const FullPlan = graphql`
  fragment plan_FullPlan on Plan {
    id
    appliedTemplates {
      id
      name
      description
      documentURL
    }
    careTeamMembers {
      id
      name
      primary
      role {
        id
        code
        value
      }
    }
    progressionIndicator
    toBeAdmitted
    meta {
      milestoneMeta {
        onTime
        overdue
        completed
      }
      lengthOfStayMeta {
        forecast
        cappedForecast
      }
      actualOE
    }
    milestones {
      id
      name
      description
      track
      dueDate
      completedDate
      status
      planTemplateName
      actions {
        id
        text
        status
        pinned
        auditMeta {
          created
          createdBy {
            userName
            firstName
            lastName
          }
          updated
          updatedBy {
            userName
            firstName
            lastName
          }
        }
        milestoneTemplateName
      }
    }
    barriers {
      id
      name
      description
      status
      pinned
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
      category {
        code
        value
      }
      escalation {
        auditMeta {
          created
          createdBy {
            userName
            firstName
            lastName
          }
        }
      }
      owner
      ownerRole {
        code
        value
      }
      notes {
        id
        text
        auditMeta {
          created
          createdBy {
            userName
            firstName
            lastName
          }
          updated
          updatedBy {
            userName
            firstName
            lastName
          }
        }
        pinned
      }
      escalation {
        auditMeta {
          created
          createdBy {
            userName
            firstName
            lastName
          }
        }
      }
    }
    actions {
      id
      text
      status
      pinned
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
    }
    notes {
      id
      text
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
      pinned
    }
  }
`

export const handleUpdateWorkingDischargeDispositionChange = undefined
