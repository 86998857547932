import React from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

export const AlertDialog = ({
  open,
  title = 'Invalid value',
  message,
  cancelText = 'OK',
  onCancelClick,
}) => {
  const actions = [
    <FlatButton
      key="cancel"
      onClick={() => onCancelClick()}
      label={cancelText}
    />,
  ]

  return (
    <Dialog open={open} modal title={title} actions={actions}>
      {message}
    </Dialog>
  )
}

AlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  onCancelClick: PropTypes.func,
}
