/* eslint-disable no-fallthrough, react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

export class CountdownTimer extends Component {
  static propTypes = {
    date: PropTypes.string,
    format: PropTypes.oneOf(['time', 'hos']),
    granularity: PropTypes.oneOf(['hour', 'minute', 'second']),
    tick: PropTypes.bool,
  }

  static defaultProps = {
    date: '',
    format: 'hos',
    granularity: 'minute',
    tick: true,
  }

  constructor() {
    super()
  }

  componentWillMount() {
    this.tick()
  }

  shouldComponentUpdate(nextProps) {
    const propUpdate =
      this.props.date !== nextProps.date ||
      this.props.granularity !== nextProps.granularity
    if (propUpdate) {
      this.tick()
    }
    return propUpdate
  }

  componentWillUnmount() {
    this.tickTimeout && clearTimeout(this.tickTimeout)
  }

  tick = () => {
    this.forceUpdate()

    const { granularity, tick } = this.props

    if (this.tickTimeout) {
      clearTimeout(this.tickTimeout)
      this.tickTimeout = null
    }

    if (tick) {
      const now = moment()
      const nextTick = now
        .clone()
        .add(1, granularity)
        .startOf(granularity)

      this.tickTimeout = setTimeout(
        this.tick,
        nextTick.diff(now, 'milliseconds')
      )
    }
  }

  render() {
    const { date, format, granularity } = this.props

    if (date) {
      const now = moment()
      const then = moment(date)
      const isPast = now.isAfter(then)
      const isToday = now.diff(then, 'day') === 0

      let countdownText = null
      if (format === 'time') {
        const timeParts = []
        switch (granularity) {
          case 'second':
            timeParts.unshift(Math.abs(then.diff(now, 'second')) % 60)
          case 'minute':
            timeParts.unshift(Math.abs(then.diff(now, 'minute')) % 60)
          case 'hour':
            timeParts.unshift(Math.abs(then.diff(now, 'hour')))
        }
        countdownText = timeParts.map(leadingZero).join(':')
      } else {
        const msecs = Math.abs(then.diff(now))
        const secs = msecs / 1000
        const hours = Math.floor(secs / 3600)
        countdownText = isPast ? '-' : ''

        if (hours === 0) {
          countdownText += Math.floor((secs - hours * 3600) / 60) + 'm'
        } else if (hours <= 48) {
          countdownText += hours + 'h'
        } else {
          countdownText += Math.ceil(hours / 24) + 'd'
        }
      }

      return (
        <span
          className={classNames('countdown-timer', {
            'is-past': isPast,
            'is-today': isToday,
          })}>
          {countdownText}
        </span>
      )
    }

    return null
  }
}

function leadingZero(num) {
  return num >= 10 ? '' + num : '0' + num
}
