import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, compose } from 'react-apollo'
import moment from 'moment'
import sortBy from 'lodash.sortby'

import { Card, CardHeader, CardText } from 'material-ui/Card'
import IconButton from 'material-ui/IconButton'
import OpenInNew from 'material-ui/svg-icons/action/open-in-new'

import { is_IEorEdge } from '../../util/utils'
import { PlanDocumentLink } from '../../components/plan/PlanDocumentLink'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { fixVariableIds } from '../../graphql/relay/temp'

const styleSheet = createStyleSheet('PlanTemplate', theme => ({
  dlHorizontal: {
    marginTop: '1px',
    marginBottom: '1px',
    '& dt': {
      float: 'left',
      width: '80px',
      overflow: 'hidden',
      clear: 'left',
      // textAlign: 'right',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: '#707478',
      fontWeight: 700,
    },
    '& dd': {
      marginLeft: '10px',
      '&.after': {
        content: 'a',
        whiteSpace: 'pre',
      },
    },
  },
  dl: {
    '&.before': {
      boxSizing: 'border-box',
    },
    '&.after': {
      boxSizing: 'border-box',
    },
  },
  milestoneTitle: {
    boxSizing: 'border-box',
    color: 'rgba(0, 0, 0, 0.541176)',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '48px',
    width: '100%',
  },
  overflowScroll: {
    overflowY: 'auto',
    height: '406px',
  },
  ieHack: {
    height: '200px',
    display: 'inline-block',
    boxSizing: 'border-box',
  },
  milestoneLists: {
    overflowY: 'auto',
    paddingBottom: '100px',
    paddingLeft: '1px',
    paddingRight: '15px',
  },
  message: {
    paddingLeft: '15px',
    marginBottom: 0,
    color: 'red',
  },
  templateName: {
    paddingTop: '10px',
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: '15px',
    marginBottom: 0,
  },
  noMilestones: {
    backgroundColor: 'rgb(230, 230, 230)',
    border: '2px inset #efeaea',
    height: '68px',
    textAlign: 'center',
    color: '#948d8d',
    paddingTop: '22px',
  },
}))

import { PlanGQL } from '../../graphql/queries'
@compose(
  graphql(PlanGQL.queries.projectedMilestonesFromTemplate, {
    name: 'data',
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: fixVariableIds({
        planId: props.planId,
        planTemplateId: props.planTemplateId,
      }),
    }),
  })
)
class PlanTemplate extends Component {
  static propTypes = {
    planId: PropTypes.string,
    planTemplateId: PropTypes.string,
    admissionTime: PropTypes.string,
    targetDischargeTime: PropTypes.string,
    anticipatedDischargeTime: PropTypes.string,
    decisionTime: PropTypes.string,
    selectedTemplateId: PropTypes.string,
    selectedTemplateDescription: PropTypes.string,
    selectedTemplateUrl: PropTypes.string,
    planTemplatesAndCategories: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      planTemplates: PropTypes.array,
    }),
    data: PropTypes.shape({
      loading: PropTypes.bool,
      projectedMilestonesFromTemplate: PropTypes.array,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  createTimeLabel(milestone) {
    if (this.props.data.loading) {
      return 'Loading...'
    }
    const projectedMilestone = this.props.data.projectedMilestonesFromTemplate.find(
      item => item.name === milestone.name
    )
    if (projectedMilestone && projectedMilestone.dueDate) {
      return moment(projectedMilestone.dueDate).format('MMM D HH:mm')
    }

    const offset = milestone.offset
    let label = ''
    const formatOffset = () => {
      let result = parseInt(offset.days) > 0 ? `${offset.days} day(s), ` : ''
      result =
        result + (parseInt(offset.hours) > 0 ? `${offset.hours} hour(s), ` : '')
      result =
        result +
        (parseInt(offset.minutes) > 0 ? `${offset.minutes} minute(s)` : '')

      const li = result.lastIndexOf(',')

      if (li === result.length - 2) {
        result = result.substr(0, li)
      }

      return result
    }

    if (offset.basis === 'Admission') {
      if (this.props.admissionTime) {
        label = moment(this.props.admissionTime)
          .local()
          .add({
            days: offset.days,
            hours: offset.hours,
            minutes: offset.minutes,
          })
          .format('MMM D HH:mm')
      } else {
        label = formatOffset()
        label = label + ' after admission'
      }
    } else if (offset.basis === 'Discharge') {
      if (
        this.props.anticipatedDischargeTime ||
        this.props.targetDischargeTime ||
        this.props.decisionTime
      ) {
        label = moment(
          this.props.anticipatedDischargeTime ||
            this.props.targetDischargeTime ||
            this.props.decisionTime
        )
          .local()
          .subtract({
            days: offset.days,
            hours: offset.hours,
            minutes: offset.minutes,
          })
          .format('MMM D HH:mm')
      } else {
        label = formatOffset()
        label = label + ' before discharge'
      }
    } else {
      // Variable
      label = 'Variable with offset: ' + formatOffset()
    }

    return label
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    let showMessage = false
    if (!this.props.data.loading) {
      this.props.data.projectedMilestonesFromTemplate.forEach(
        item => (showMessage = item.dueDateCutoff ? true : showMessage)
      )
    }
    const link = this.props.selectedTemplateUrl
    const showPlanDocument = () => window.open(link, link)

    // This is mostly the old code from ChoosePlan abstracted out into this component
    // so that we can attached the qraphql call to get the projectedMilestonesFromTemplate data
    // I did not attempt to refactor this which it is badly needed
    return (
      <div className={classNames('col-xs-8', 'col-lg-9')}>
        <div className={classNames('row', classes.templateName)}>
          {this.props.selectedTemplateUrl !== '' ? (
            <PlanDocumentLink
              key={this.props.selectedTemplateDescription}
              templateInfo={{
                name: this.props.selectedTemplateDescription,
                documentURL: this.props.selectedTemplateUrl,
              }}
            />
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
        {showMessage && (
          <div className={classNames(classes.message)}>
            Some milestones have due dates outside the patient's LOS window and
            will be re-calculated to fit in the window if applied.
          </div>
        )}
        <div className={classNames(classes.milestoneLists)}>
          <div className="col-sm-6 col-xs-12">
            {this.props.selectedTemplateId !== '' && (
              <span className={classNames(classes.milestoneTitle)}>
                Progression Milestones
              </span>
            )}
            <div className={classNames(classes.overflowScroll)}>
              {this.props.planTemplatesAndCategories.planTemplates &&
                this.props.planTemplatesAndCategories.planTemplates
                  .filter(x => x.id === this.props.selectedTemplateId)
                  .map(template =>
                    sortBy(
                      template.milestoneTemplates.filter(
                        x => x.active && x.track === 'Progression'
                      ),
                      x =>
                        x.offset.basis === 'Admission'
                          ? moment(this.props.admissionTime)
                              .local()
                              .add({
                                days: x.offset.days,
                                hours: x.offset.hours,
                                minutes: x.offset.minutes,
                              })
                          : // it's a Variable offset sort in order of offset
                            moment()
                              .local()
                              .add({
                                days: x.offset.days,
                                hours: x.offset.hours,
                                minutes: x.offset.minutes,
                              })
                    ).map(milestone => (
                      <div key={milestone.id}>
                        <Card key={`Card_${milestone.id}`}>
                          <CardHeader
                            title={milestone.name}
                            subtitle={this.createTimeLabel(milestone)}
                            actAsExpander
                            showExpandableButton
                          />
                          <CardText
                            expandable
                            style={{
                              padding: '0 15px',
                            }}>
                            <dl className={classNames(classes.dlHorizontal)}>
                              <dt>Description:&nbsp;</dt>
                              <dd>{milestone.description}</dd>
                              <br />
                              <dt>Actions:&nbsp;</dt>
                              <br />
                              {milestone.actions &&
                                milestone.actions.length > 0 &&
                                milestone.actions.map(action => (
                                  <dd key={`Card_${milestone.id}_${action}`}>
                                    {action}
                                  </dd>
                                ))}
                              {milestone.actions &&
                                milestone.actions.length === 0 && (
                                  <dd key={`Card_${milestone.id}_noActions`}>
                                    No actions defined
                                  </dd>
                                )}
                            </dl>
                          </CardText>
                        </Card>
                        <br />
                      </div>
                    ))
                  )}
              {this.props.planTemplatesAndCategories.planTemplates &&
                this.props.selectedTemplateId !== '' &&
                this.props.planTemplatesAndCategories.planTemplates
                  .filter(x => x.id === this.props.selectedTemplateId)[0]
                  .milestoneTemplates.filter(
                    x => x.active && x.track === 'Progression'
                  ).length === 0 && (
                  <div className={classNames(classes.noMilestones)}>
                    No progression milestones defined
                  </div>
                )}
            </div>
            {is_IEorEdge && this.props.selectedTemplateId !== '' && (
              <div className={classNames(classes.ieHack)} />
            )}
          </div>

          <div className="col-sm-6 col-xs-12">
            {this.props.selectedTemplateId !== '' && (
              <span className={classNames(classes.milestoneTitle)}>
                Discharge Milestones
              </span>
            )}
            <div className={classNames(classes.overflowScroll)}>
              {this.props.planTemplatesAndCategories.planTemplates &&
                this.props.planTemplatesAndCategories.planTemplates
                  .filter(x => x.id === this.props.selectedTemplateId)
                  .map(template =>
                    sortBy(
                      template.milestoneTemplates.filter(
                        x => x.active && x.track === 'Discharge'
                      ),
                      x =>
                        x.offset.basis === 'Admission'
                          ? moment(this.props.admissionTime)
                              .local()
                              .add({
                                days: x.offset.days,
                                hours: x.offset.hours,
                                minutes: x.offset.minutes,
                              })
                          : moment(
                              this.props.anticipatedDischargeTime ||
                                this.props.targetDischargeTime ||
                                this.props.decisionTime
                            )
                              .local()
                              .subtract({
                                days: x.offset.days,
                                hours: x.offset.hours,
                                minutes: x.offset.minutes,
                              })
                    ).map(milestone => (
                      <div key={milestone.id}>
                        <Card key={`Card_${milestone.id}`}>
                          <CardHeader
                            title={milestone.name}
                            subtitle={this.createTimeLabel(milestone)}
                            actAsExpander
                            showExpandableButton
                          />
                          <CardText
                            expandable
                            style={{
                              padding: '0 15px',
                            }}>
                            <dl className={classNames(classes.dlHorizontal)}>
                              <dt>Description:&nbsp;</dt>
                              <dd>{milestone.description}</dd>
                              <br />
                              <dt>Actions:&nbsp;</dt>
                              <br />
                              {milestone.actions &&
                                milestone.actions.length > 0 &&
                                milestone.actions.map(action => (
                                  <dd key={`Card_${milestone.id}_${action}`}>
                                    {action}
                                  </dd>
                                ))}
                              {milestone.actions &&
                                milestone.actions.length === 0 && (
                                  <dd key={`Card_${milestone.id}_noActions`}>
                                    No actions defined
                                  </dd>
                                )}
                            </dl>
                          </CardText>
                        </Card>
                        <br />
                      </div>
                    ))
                  )}
              {this.props.planTemplatesAndCategories.planTemplates &&
                this.props.selectedTemplateId !== '' &&
                this.props.planTemplatesAndCategories.planTemplates
                  .filter(x => x.id === this.props.selectedTemplateId)[0]
                  .milestoneTemplates.filter(
                    x => x.active && x.track === 'Discharge'
                  ).length === 0 && (
                  <div className={classNames(classes.noMilestones)}>
                    No discharge milestones defined
                  </div>
                )}
            </div>
            {is_IEorEdge && this.props.selectedTemplateId !== '' && (
              <div className={classNames(classes.ieHack)} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default PlanTemplate
