/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserRole = "BARRIER_OWNER_ROLE" | "BARRIER_SOURCE_ROLE" | "IRIS_USER_ROLE" | "%future added value";
export type KnownUserInput = {|
  searchText: string,
  roles?: ?$ReadOnlyArray<UserRole>,
  instantMessageRegistered?: ?boolean,
  isOwner?: ?boolean,
|};
export type knownUser_searchKnownUsers_QueryVariables = {|
  knownUserInput: KnownUserInput
|};
export type knownUser_searchKnownUsers_QueryResponse = {|
  +knownUsers: ?$ReadOnlyArray<?{|
    +id: string,
    +firstName: string,
    +lastName: string,
    +instantMessageReceiver: boolean,
    +emailReceiver: boolean,
    +title: ?string,
  |}>
|};
export type knownUser_searchKnownUsers_Query = {|
  variables: knownUser_searchKnownUsers_QueryVariables,
  response: knownUser_searchKnownUsers_QueryResponse,
|};
*/


/*
query knownUser_searchKnownUsers_Query(
  $knownUserInput: KnownUserInput!
) {
  knownUsers(knownUserInput: $knownUserInput) {
    id
    firstName
    lastName
    instantMessageReceiver
    emailReceiver
    title
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "knownUserInput"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "knownUserInput",
        "variableName": "knownUserInput"
      }
    ],
    "concreteType": "KnownUser",
    "kind": "LinkedField",
    "name": "knownUsers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "instantMessageReceiver",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailReceiver",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "knownUser_searchKnownUsers_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "knownUser_searchKnownUsers_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a47ee0b3cde8c291f723ff8c17d309e2",
    "id": null,
    "metadata": {},
    "name": "knownUser_searchKnownUsers_Query",
    "operationKind": "query",
    "text": "query knownUser_searchKnownUsers_Query(\n  $knownUserInput: KnownUserInput!\n) {\n  knownUsers(knownUserInput: $knownUserInput) {\n    id\n    firstName\n    lastName\n    instantMessageReceiver\n    emailReceiver\n    title\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c791bf1694bda4e69e41b5a91b8599b';

module.exports = node;
