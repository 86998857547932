import { graphql } from 'react-relay'

export const newEntryBarrierCategoryUpdater = (proxyStore, variables) => {
  const store = proxyStore.getRootField("barrierCategoryMutation")
  const payload = store.getLinkedRecord("addBarrierCategory", variables)

  updateStore(proxyStore, variables, payload)
}

export const updateEntryBarrierCategoryUpdater = (proxyStore, variables) => {
  updateStore(proxyStore, variables)
}

const updateStore = (proxyStore, variables, payload) => {
  const rootStore = proxyStore.get("client:root")
  const codeTableList = rootStore.getLinkedRecords('barrierCategories')

  updateSourceTypes(codeTableList)

  const newArray = payload ? [payload, ...codeTableList] : codeTableList
  const sortedArray = newArray.sort((a,b) => a.getValue('ordinal') - b.getValue('ordinal'))
  rootStore.setLinkedRecords(sortedArray,'barrierCategories')
}

const updateSourceTypes = (barrierCategories) => {
  barrierCategories.forEach(category => {
    if (category) {
      const barrierSourceTypes = category.getLinkedRecords('barrierSourceTypes')
      if (barrierSourceTypes && barrierSourceTypes.length > 0) {
        const sortedList = barrierSourceTypes.sort((a,b) => a.getValue('ordinal') - b.getValue('ordinal'))
        category.setLinkedRecords(sortedList, 'barrierSourceTypes')
      }
    }
  })
}

export const addBarrierCategory = graphql`
  mutation barrierCategory_addBarrierCategory_Mutation(
    $barrierCategoryInput: BarrierCategoryInput!
  ) {
    barrierCategoryMutation {
      addBarrierCategory(barrierCategoryInput: $barrierCategoryInput) {
        id
        code
        value
        ordinal
        active
        sourceTypes {
          id
          code
          value
        }
      }
    }
  }
`

export const modifyBarrierCategoryEntry = graphql`
  mutation barrierCategory_modifyBarrierCategoryEntry_Mutation(
    $barrierCategoryInput: BarrierCategoryInput!
  ) {
    barrierCategoryMutation {
      modifyBarrierCategoryEntry(barrierCategoryInput: $barrierCategoryInput) {
        id
        code
        value
        ordinal
        active
        sourceTypes {
          id
          code
          value
        }
      }
    }
  }
`
