import { retrieveAuthToken } from '../auth'
import moment from 'moment'

// this code is based on this found on the web
//  https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
const showFile = (blob, name) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  let newBlob = new Blob([blob], {type: 'application/pdf'})

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Open in a new window and set the tab title
  // have to wait to add title because browser remove the head
  // as it creates embed for newBlob
  const data = window.URL.createObjectURL(newBlob)
  let newWindow = window.open(data, name)
  setTimeout(
    () =>
      newWindow.document
        .getElementsByTagName('html')[0]
        .appendChild(document.createElement('head'))
        .appendChild(document.createElement('title'))
        .appendChild(document.createTextNode(`${name}`)),
    500
  )

  // CA-2792 - calling revokeObjectURL to clean up was no longer working in CA 2.1.0
  // You could no longer download the file if you wanted, the MDN docs say the ObjectURL is
  // cleaned up when document unloaded, so this should be safe
  // see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL

  // setTimeout(function() {
  //   // For Firefox it is necessary to delay revoking the ObjectURL
  //   window.URL.revokeObjectURL(data)
  // }, 100)
}

const downloadFile = (blob, name) => {
  let fileDownload = require('js-file-download')
  fileDownload(blob, name)
}

const getReport = (url, name, download) => {
  // We first get the authToken from session storage
  retrieveAuthToken().then(authToken => {
    fetch(url, {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    })
      .then(res => res.blob())
      .then(blob => download ? downloadFile(blob, name) : showFile(blob, name))
  })
}

export const censusReport = (notesParam, bedParam) => {
  getReport(`/api/census?notes=${notesParam}&reportType=${bedParam}`, `Census Report ${fileNameTimestamp()}.pdf`)
}

export const snapReport = (notesParam, bedParam) => {
  getReport(`/api/snap?notes=${notesParam}&reportType=${bedParam}`, `SNAP Report ${fileNameTimestamp()}.pdf`)
}

export const hipaaAuditReport = (startDate, endDate, userName, mrn, visitNumber) => {
  let userNameParam = userName !== null && userName !== '' ? `&userName=${userName}` : ''
  let mrnParam = mrn !== null && mrn !== '' ? `&mrn=${mrn}` : ''
  let visitNumberParam = visitNumber !== null && visitNumber !== '' ? `&visitNumber=${visitNumber}` : ''

  getReport(`/api/hipaa?startDate=${startDate}&endDate=${endDate}${userNameParam}${mrnParam}${visitNumberParam}`,
    `HIPAA Audit Report ${fileNameTimestamp()}.csv`, 'download')
}

const fileNameTimestamp = () => {
  return moment().format("MM-DD-YYYY HHmm")
}
