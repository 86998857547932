/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BarrierCategoryTable_list$ref: FragmentReference;
declare export opaque type BarrierCategoryTable_list$fragmentType: BarrierCategoryTable_list$ref;
export type BarrierCategoryTable_list = $ReadOnlyArray<{|
  +id: string,
  +code: string,
  +value: string,
  +ordinal: number,
  +active: boolean,
  +sourceTypes: ?$ReadOnlyArray<?{|
    +id: string,
    +code: string,
    +value: string,
    +active: boolean,
  |}>,
  +$refType: BarrierCategoryTable_list$ref,
|}>;
export type BarrierCategoryTable_list$data = BarrierCategoryTable_list;
export type BarrierCategoryTable_list$key = $ReadOnlyArray<{
  +$data?: BarrierCategoryTable_list$data,
  +$fragmentRefs: BarrierCategoryTable_list$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BarrierCategoryTable_list",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BarrierSourceType",
      "kind": "LinkedField",
      "name": "sourceTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "BarrierCategory",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6d0ce13f6fff33c0693f465fa7234d72';

module.exports = node;
