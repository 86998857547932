/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateAnticipatedDischargeTime_MutationVariables = {|
  visitId: any,
  anticipatedDischargeTime?: ?any,
  anticipatedDischargeTimeAutoPopulated: boolean,
|};
export type visit_updateAnticipatedDischargeTime_MutationResponse = {|
  +visitMutation: ?{|
    +updateAnticipatedDischargeTime: ?{|
      +id: string,
      +anticipatedDischargeTime: ?any,
      +anticipatedDischargeUpdatedWhen: ?any,
      +anticipatedDischargeUpdatedBy: ?{|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +anticipatedDischargeTimeAutoPopulated: ?boolean,
      +meta: {|
        +dischargeToday: boolean,
        +tlosExceeded: boolean,
        +plannedDischargeExceeded: boolean,
        +decisionDue: boolean,
        +timeElapsedSinceAdmission: any,
      |},
      +plan: ?{|
        +id: string,
        +milestones: $ReadOnlyArray<?{|
          +id: string,
          +dueDate: ?any,
        |}>,
      |},
    |}
  |}
|};
export type visit_updateAnticipatedDischargeTime_Mutation = {|
  variables: visit_updateAnticipatedDischargeTime_MutationVariables,
  response: visit_updateAnticipatedDischargeTime_MutationResponse,
|};
*/


/*
mutation visit_updateAnticipatedDischargeTime_Mutation(
  $visitId: Long!
  $anticipatedDischargeTime: ZonedDateTime
  $anticipatedDischargeTimeAutoPopulated: Boolean!
) {
  visitMutation {
    updateAnticipatedDischargeTime(visitId: $visitId, anticipatedDischargeTime: $anticipatedDischargeTime, anticipatedDischargeTimeAutoPopulated: $anticipatedDischargeTimeAutoPopulated) {
      id
      anticipatedDischargeTime
      anticipatedDischargeUpdatedWhen
      anticipatedDischargeUpdatedBy {
        userName
        firstName
        lastName
        id
      }
      anticipatedDischargeTimeAutoPopulated
      meta {
        dischargeToday
        tlosExceeded
        plannedDischargeExceeded
        decisionDue
        timeElapsedSinceAdmission
        id
      }
      plan {
        id
        milestones {
          id
          dueDate
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anticipatedDischargeTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anticipatedDischargeTimeAutoPopulated"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "anticipatedDischargeTime",
    "variableName": "anticipatedDischargeTime"
  },
  {
    "kind": "Variable",
    "name": "anticipatedDischargeTimeAutoPopulated",
    "variableName": "anticipatedDischargeTimeAutoPopulated"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anticipatedDischargeTime",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anticipatedDischargeUpdatedWhen",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anticipatedDischargeTimeAutoPopulated",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeToday",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlosExceeded",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedDischargeExceeded",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionDue",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeElapsedSinceAdmission",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestones",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateAnticipatedDischargeTime_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateAnticipatedDischargeTime",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "anticipatedDischargeUpdatedBy",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateAnticipatedDischargeTime_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateAnticipatedDischargeTime",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "anticipatedDischargeUpdatedBy",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e273bd86b6a673cd73deee815cb64fd0",
    "id": null,
    "metadata": {},
    "name": "visit_updateAnticipatedDischargeTime_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateAnticipatedDischargeTime_Mutation(\n  $visitId: Long!\n  $anticipatedDischargeTime: ZonedDateTime\n  $anticipatedDischargeTimeAutoPopulated: Boolean!\n) {\n  visitMutation {\n    updateAnticipatedDischargeTime(visitId: $visitId, anticipatedDischargeTime: $anticipatedDischargeTime, anticipatedDischargeTimeAutoPopulated: $anticipatedDischargeTimeAutoPopulated) {\n      id\n      anticipatedDischargeTime\n      anticipatedDischargeUpdatedWhen\n      anticipatedDischargeUpdatedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      anticipatedDischargeTimeAutoPopulated\n      meta {\n        dischargeToday\n        tlosExceeded\n        plannedDischargeExceeded\n        decisionDue\n        timeElapsedSinceAdmission\n        id\n      }\n      plan {\n        id\n        milestones {\n          id\n          dueDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff1cee576cf725f9a85d62c64629602d';

module.exports = node;
