import moment from 'moment'

import { calcForecastWorkingOE, calcForecastExcessDays } from './workspace'
import { workspace } from './../types'
import {
  currentDayOfStayCompareDescending,
  visitDecisionTypeCompareAscending,
  forecastExcessDaysCompareDescending,
  actualOECompareDescending,
  forecastWorkingOECompareDescending,
  departmentNameBedNameCompareAscending,
} from './sort'

export const filterConstants = {
  admissionType: {
    defaultSelected: 'all',
    allValue: 'all',
    inpatientValue: 'inpatient', //'InPatient',
    outObsValue: 'obsout', //'Obs/Out',
  },
  los: {
    defaultSelected: 'forecastMore_1_20',
    forecastMore_1_20: 'forecastMore_1_20',
    currentMore_1_00: 'currentMore_1_00',
    forecastExcessDayGreater: 'forecastExcessDayGreater',
    losDaysGreater: 'losDaysGreater',

  },
  barriers: {
    defaultSelected: 'all',
    allValue: 'all',
    withOpenBarriers: 'open', //'WithOpenBarriers',
    withOpenEscalatedBarriers: 'escalated', //'WithOpenEscalatedBarriers',
    withoutBarriers: 'none', //'WithoutBarriers',
  },
  barrierOwnerRadio: {
    defaultSelected: {
      value: 'all',
      label: 'All'
    },
    allValue: {
      value: 'all',
      label: 'All'
    },
    noOwners: {
      value: 'none',
      label: 'No Owner'
    },
    search: {
      value: 'search',
      label: 'Search By Owner Name'
    }
  }
}

export function filterWorkspaces(filter, workspaces) {
  // let tStart = performance.now()

  let result = workspaces.filter(workspace =>
    filterWorkspace(filter, workspace)
  )
  // let tEnd = performance.now()
  // console.log('Took', (tEnd - tStart).toFixed(4), 'milliseconds')

  if (
    filter.losPreview.value === filterConstants.los.forecastExcessDayGreater
  ) {
    result.sort(forecastExcessDaysCompareDescending)
  } else if (filter.losPreview.value === filterConstants.los.losDaysGreater) {
    result.sort(currentDayOfStayCompareDescending)
  } else if (
    filter.admissionType === filterConstants.admissionType.outObsValue
  ) {
    result.sort(visitDecisionTypeCompareAscending)
  } else if (filter.losPreview.value === filterConstants.los.currentMore_1_00) {
    result.sort(actualOECompareDescending)
  } else if (
    filter.losPreview.value === filterConstants.los.forecastMore_1_20 ||
    filter.admissionType === filterConstants.admissionType.inpatientValue
  ) {
    result.sort(forecastWorkingOECompareDescending)
  } else {
    result.sort(departmentNameBedNameCompareAscending)
  }

  return result
}

function filterWorkspace(filter, workspace) {
  let include = filterByVisitStatus(workspace, filter)
  include = include && filterByAdmissionType(workspace, filter)
  include = include && filterBarriers(workspace, filter.barriers)
  include = include && filterBarrierDetails(workspace, filter)
  include = include && filterUnits(workspace, filter.units)

  return include
}

function filterByVisitStatus(workspace, filter) {
  let include = false
  if (filter.visitStatus) {
    include =
      workspace.visit && workspace.visit.visitStatus !== filter.visitStatus
  }

  return include
}

function filterByAdmissionType(workspace, filter) {
  let include = true

  switch (filter.admissionType) {
    case filterConstants.admissionType.inpatientValue:
      include = workspace.visit && workspace.visit.admissionType === 'InPatient'

      include =
        include && filterByLosForecastCurrent(workspace, filter.losPreview)
      break

    case filterConstants.admissionType.outObsValue:
      include =
        workspace.visit &&
        (workspace.visit.admissionType === 'OutPatient' ||
          workspace.visit.admissionType === 'Observation')

      include =
        include && filterByLosDecisionTimeDueOnly(workspace, filter.losPreview)
      break
  }

  return include
}

function filterUnits(workspace, units) {
  if (!units.some(unit => unit.exchangeName === 'All' && unit.checked)) {
    return units.some(
      unit =>
        unit.checked && unit.exchangeName === workspace.department.exchangeName
    )
  }

  return true
}

function filterBarriers(workspace, barrierFilter) {
  switch (barrierFilter.selected) {
    case filterConstants.barriers.withoutBarriers:
      return workspace.plan.barriers.length === 0

    case filterConstants.barriers.withOpenBarriers:
      return workspace.plan.barriers.some(barrier => barrier.status === 'Open')

    case filterConstants.barriers.withOpenEscalatedBarriers:
      return workspace.plan.barriers.find(barrier => !!barrier.escalation)

    default:
      return true
  }
}

function filterBarrierDetails(
  workspace,
  { barrierCategories, barrierOwnerRoles, barrierOwners }
) {
  if (
    !barrierCategories.some(
      barrier => barrier.code === 'ALL' && barrier.checked
    ) ||
    !barrierOwnerRoles.some(role => role.code === 'ALL' && role.checked) ||
    !barrierOwners.some(owner => owner.owner === 'ALL' && owner.checked)
  ) {
    return workspace.plan.barriers.some(barrier => {
      return (
        filterBarrierCategories(barrier, barrierCategories) &&
        filterBarrierOwnerRoles(barrier, barrierOwnerRoles) &&
        filterBarrierOwnerNames(barrier, barrierOwners)
      )
    })
  }

  return true
}

function filterBarrierCategories(barrier, barrierCategoriesFilter) {
  if (
    !barrierCategoriesFilter.some(
      category => category.code === 'ALL' && category.checked
    )
  ) {
    return barrierCategoriesFilter.some(
      category => category.checked && category.code === barrier.category.code
    )
  }

  return true
}

function filterBarrierOwnerRoles(barrier, barrierOwnerRolesFilter) {
  const nothingChecked = noFiltersChecked(barrierOwnerRolesFilter)
  if (
    !barrierOwnerRolesFilter.some(role => role.code === 'ALL' && role.checked)
  ) {
    return (
      barrierOwnerRolesFilter.some(
        role =>
          role.checked &&
          barrier.ownerRole &&
          role.code === barrier.ownerRole.code
      ) ||
      (nothingChecked && !barrier.ownerRole)
    )
  }

  return true
}

function filterBarrierOwnerNames(barrier, barrierOwnerNamesFilter) {
  const nothingChecked = noFiltersChecked(barrierOwnerNamesFilter)
  if (
    !barrierOwnerNamesFilter.some(
      barrierOwner => barrierOwner.owner === 'ALL' && barrierOwner.checked
    )
  ) {
    return (
      barrierOwnerNamesFilter.some(
        filter => filter.checked && barrier.owner === filter.owner
      ) ||
      (nothingChecked && !barrier.owner)
    )
  }

  return true
}

function noFiltersChecked(filters) {
  return filters.every(filter => !filter.checked)
}

function filterByLosForecastCurrent(workspace, losPreview) {
  workspace.forecastWorkingOE = calcForecastWorkingOE(false, workspace)
  switch (losPreview.value) {
    case filterConstants.los.forecastMore_1_20:
      return workspace.forecastWorkingOE > losPreview.oeForecastTarget

    case filterConstants.los.currentMore_1_00:
      return (
        workspace.plan.meta &&
        workspace.plan.meta.actualOE > losPreview.oeCurrentTarget
      )

    case filterConstants.los.forecastExcessDayGreater:
      workspace.forecastExcessDays = calcForecastExcessDays(false, workspace)
      return workspace.forecastExcessDays > losPreview.days

    case filterConstants.los.losDaysGreater:
      return workspace.visit.currentDayOfStay > losPreview.days

    default:
      return true
  }
}

function filterByLosDecisionTimeDueOnly(workspace, losPreview) {
  if (losPreview.decisionTimeDueOnly) {
    return workspace.visit.meta.decisionDue
  }

  return true
}

export function getUniqueDepartments(departments) {
  return departments.reduce(
    (acc, department) => {
      acc.push({
        type: department.type,
        exchangeName: department.exchangeName,
        name: department.name,
        checked: true,
      })

      return acc
    },
    [
      {
        type: 'Unit',
        exchangeName: 'All',
        name: 'ALL',
        checked: true,
      },
    ]
  )
}

export function getUniqueBarrierOwners(workspaces) {
  return workspaces.reduce(
    (acc, workspace) => {
      workspace.plan &&
        workspace.plan.barriers.forEach(barrier => {
          if (
            barrier.owner &&
            !acc.some(owner => owner.owner === barrier.owner)
          ) {
            acc.push({ owner: barrier.owner, checked: true })
          }
        })

      return acc
    },
    [{ owner: 'ALL', checked: true }]
  )
}
