import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'material-ui/Checkbox'
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'
import MenuItem from 'material-ui/MenuItem'
import FlatButton from 'material-ui/FlatButton'

export const DrgSearchInput = ({
  mdcs,
  onDrgChanged,
  onMedChanged,
  onSurgChanged,
  onMdcChanged,
  onSearchTermChanged,
  searchFilters,
  onReset,
}) => {
  let searchText = null
  const doReset = () => {
    searchText.input.value = ''
    searchText.setState({ hasValue: false })
    onReset()
  }

  return (
    <div
      className="paper drg-search-input"
      style={{ display: 'flex', paddingLeft: 10 }}>
      <TextField
        style={{ width: '90px' }}
        hintText="DRG Code"
        onChange={onDrgChanged}
        value={searchFilters.drgCode}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <span onClick={onMedChanged}>
          <Checkbox
            label="MED"
            style={{ width: 'auto' }}
            defaultChecked={searchFilters.med}
          />
        </span>
        <span onClick={onSurgChanged}>
          <Checkbox
            label="SURG"
            style={{ width: 'auto' }}
            defaultChecked={searchFilters.surg}
          />
        </span>
      </div>

      <div
        style={{
          flex: 'auto',
          paddingRight: 10,
          paddingLeft: 10,
          display: 'flex',
        }}>
        <SelectField
          style={{ flex: 'auto', paddingRight: 10 }}
          value={searchFilters.majorDiagnosisCategory}
          onChange={onMdcChanged}
          hintText="Major Diagnosis Category"
          floatingLabelFixed>
          {mdcs.map(mdc => (
            <MenuItem key={mdc} value={mdc} primaryText={mdc} />
          ))}
        </SelectField>
      </div>
      <TextField
        ref={ref => (searchText = ref)}
        style={{ flex: 'auto' }}
        type="search"
        hintText="Search Title, DXs, Procedures"
        onChange={onSearchTermChanged}
        defaultValue={searchFilters.searchTerm}
      />

      <FlatButton label="Reset" onClick={doReset} />
    </div>
  )
}

DrgSearchInput.propTypes = {
  mdcs: PropTypes.array.isRequired,
  onDrgChanged: PropTypes.func.isRequired,
  onMdcChanged: PropTypes.func.isRequired,
  onMedChanged: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSearchTermChanged: PropTypes.func.isRequired,
  onSurgChanged: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
}
