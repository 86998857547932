import React, { useState, useRef, useEffect, useReducer, Component } from 'react'
import PropTypes from 'prop-types'
// import TextField from '@material-ui/core/TextField'
import TextField from '../../common/TextField'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import classNames from 'classnames'
import './AddNewBarrier.scss'
import {
  BarrierCategoryPicker,
  BarrierCategoryTemplatesPicker,
  BarrierActionButtons,
  BarrierOwnerPicker,
  BarrierSourceTypePicker,
  BarrierSourceDetailsInput
} from './Options'
import { handleMutation } from '../../../graphql/relay/queries/mutation'

import { environment } from '../../../graphql/relay/env'
import {
  createBarrier,
  createBarrierFromTemplate,
  escalateBarrier,
  updateBarrierOwner,
  pinBarrier
} from '../../../graphql/relay/queries/barrier'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import AlertIcon from 'mdi-react/AlertIcon'
import Snackbar from 'material-ui/Snackbar'
import { isEmpty, isEqual } from 'lodash'
import InputAdornment from '@material-ui/core/InputAdornment'

class AddNewBarrier extends Component {

  constructor(props) {
    super(props)
    this.state = {
      // addNewBarrierId: (Math.random() + 1).toString(36).substring(7),
      newBarrierNote: '',
      newBarrierCategory: null,
      newBarrierTemplate: null,
      newBarrierKnownUser: null,
      newBarrierPinned: false,
      newBarrierEscalated: false,
      newBarrierSourceType: null,
      newBarrierSourceName: '',
      openBarrierOptions: false,
      openUnsavedExitConfirmation: false,
      openMissingRequiredFieldsAlert: false,
      notifyNewBarrierSuccess: false,
      // newBarrierNoteError: false,
      newBarrierCategoryError: false,
      newBarrierTemplateError: false
    }

  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      newBarrierNote,
      newBarrierCategory,
      newBarrierTemplate,
      newBarrierKnownUser,
      newBarrierPinned,
      newBarrierEscalated,
      newBarrierSourceType,
      newBarrierSourceName,
      openBarrierOptions,
      openUnsavedExitConfirmation,
      openMissingRequiredFieldsAlert,
      notifyNewBarrierSuccess
    } = this.state

    if (!isEqual(newBarrierNote, nextState.newBarrierNote)) { return true }
    else if (!isEqual(newBarrierCategory, nextState.newBarrierCategory)) { return true }
    else if (!isEqual(newBarrierTemplate, nextState.newBarrierTemplate)) { return true }
    else if (!isEqual(newBarrierKnownUser, nextState.newBarrierKnownUser)) { return true }
    else if (!isEqual(newBarrierPinned, nextState.newBarrierPinned)) { return true }
    else if (!isEqual(newBarrierEscalated, nextState.newBarrierEscalated)) { return true }
    else if (!isEqual(newBarrierSourceType, nextState.newBarrierSourceType)) { return true }
    else if (!isEqual(newBarrierSourceName, nextState.newBarrierSourceName)) { return true }
    else if (!isEqual(openBarrierOptions, nextState.openBarrierOptions)) { return true }
    else if (!isEqual(openUnsavedExitConfirmation, nextState.openUnsavedExitConfirmation)) { return true }
    else if (!isEqual(openMissingRequiredFieldsAlert, nextState.openMissingRequiredFieldsAlert)) { return true }
    else if (!isEqual(notifyNewBarrierSuccess, nextState.notifyNewBarrierSuccess)) { return true }

    return false
  }

  onBarrierInputKeyUp = (event) => {
    if (event.key === 'Enter') {
      this.checkAndSaveNewBarrier()
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      this.checkAndCloseNewBarrier()
    }
  }

  showBarrierOptions = () => {
    this.setState({
      openBarrierOptions: true
    })
  }

  createNewBarrier = () => {
    const  {
      planId,
      rerender,
      onSave,
      visit,
      onCreateBarrierFromTemplate
    } = this.props

    const {
      newBarrierNote,
      newBarrierCategory,
      newBarrierTemplate,
      newBarrierKnownUser,
      newBarrierPinned,
      newBarrierEscalated,
      newBarrierSourceType,
      newBarrierSourceName,
    } = this.state

    try {
      let barrierGqlVariables = {
        planId,
        // note: newBarrierNote
      }
      if (newBarrierNote.trim() !== '') {
        barrierGqlVariables.note = newBarrierNote
      }

      if (!isEmpty(newBarrierSourceType)) {
        barrierGqlVariables.barrierSourceTypeCode = newBarrierSourceType.code
      }
      if (!isEmpty(newBarrierSourceName)) {
        barrierGqlVariables.barrierSourceName = newBarrierSourceName
      }
      if (!isEmpty(newBarrierKnownUser)) {
        barrierGqlVariables.ownerId = newBarrierKnownUser.id
      }

      // we only need to set the pinned value if newBarrierPinned is true
      if (newBarrierPinned) {
        barrierGqlVariables.pinned = newBarrierPinned
      }

      // we only need to set the escalated value if newBarrierEscalated is true
      if (newBarrierEscalated) {
        barrierGqlVariables.escalated = newBarrierEscalated
      }

      const isCustomBarrier = (newBarrierTemplate && newBarrierTemplate.id === null)

      // CUSTOM Barrier
      if (isCustomBarrier) {
        barrierGqlVariables.barrier = {
          name: newBarrierTemplate.name,
          description: '',  // for custom barrier, the description field is empty
          categoryCode: newBarrierCategory.code      // category code
        }
      }
      // Template Barrier
      else if (newBarrierTemplate) {
        barrierGqlVariables.templateId = newBarrierTemplate.id
      }

      const handleResponse = (response) => {
        this.setState({ notifyNewBarrierSuccess: true })
        if (typeof onSave == 'function') {
          onSave(response)
        }
        this.clearAllAndReset()
      }

      if(!isCustomBarrier){
        barrierGqlVariables.bedRequestStatus = visit.bedRequestStatus
        onCreateBarrierFromTemplate(barrierGqlVariables, handleResponse)
        return
      }

      handleMutation(
        environment,
        createBarrier,
        barrierGqlVariables,
        handleResponse,
        rerender,
        (err) => {console.error(err)}
      )
    } catch (err) {
      console.error(err)
    }
  }

  checkAndCloseNewBarrier = () => {
    const { onCancel } = this.props

    const {
      newBarrierNote,
      newBarrierCategory,
      newBarrierTemplate,
      newBarrierKnownUser,
      newBarrierPinned,
      newBarrierEscalated,
      newBarrierSourceType,
      newBarrierSourceName,
    } = this.state

    if (!isEmpty(newBarrierNote)
      || !isEmpty(newBarrierCategory)
      || !isEmpty(newBarrierTemplate)
      || !isEmpty(newBarrierKnownUser)
      || newBarrierPinned
      || newBarrierEscalated
      || !isEmpty(newBarrierSourceType)
      || !isEmpty(newBarrierSourceName)
    ) {
      this.setState({
        openUnsavedExitConfirmation: true
      })
    } else {
      this.setState({
        openBarrierOptions: false
      })
      if (typeof onCancel == 'function') {
        onCancel()
      }
    }
  }

  get canSave() {
    const {
      newBarrierNote,
      newBarrierCategory,
      newBarrierTemplate,
    } = this.state

    let errors ={}
    let hasError = false

    // remove barrier note from the requirement fields
    // if (isEmpty(newBarrierNote)) {
    //   errors.newBarrierNoteError = true
    //   hasError = true
    // }
    if (isEmpty(newBarrierCategory)) {
      errors.newBarrierCategoryError = true
      hasError = true
    }
    if (isEmpty(newBarrierTemplate)) {
      errors.newBarrierTemplateError = true
      hasError = true
    }
    if (hasError) {
      this.setState(errors)
    }
    return !hasError
  }

  checkAndSaveNewBarrier = () => {
    if (this.canSave) {
      this.createNewBarrier()
    } else {
      this.setState({
        openMissingRequiredFieldsAlert: true
      })
    }
  }

  clearBarrierNote = () => {
    this.setState({
      newBarrierNote: ''
    })
  }

  clearAllAndReset = () => {
    this.setState({
      addNewBarrierId: (Math.random() + 1).toString(36).substring(7),
      newBarrierNote: '',
      newBarrierCategory: null,
      newBarrierTemplate: null,
      newBarrierKnownUser: null,
      newBarrierPinned: false,
      newBarrierEscalated: false,
      newBarrierSourceType: null,
      newBarrierSourceName: '',
      openBarrierOptions: false,
      openUnsavedExitConfirmation: false,
      openMissingRequiredFieldsAlert: false,
      // newBarrierNoteError: false,
      newBarrierCategoryError: false,
      newBarrierTemplateError: false
    })
  }

  render() {
    const {
      addNewBarrierId,
      newBarrierNote,
      newBarrierCategory,
      newBarrierTemplate,
      newBarrierSourceType,
      openBarrierOptions,
      openUnsavedExitConfirmation,
      openMissingRequiredFieldsAlert,
      notifyNewBarrierSuccess,
      // newBarrierNoteError,
      newBarrierCategoryError,
      newBarrierTemplateError
    } = this.state

    const { onCancel, alwaysExpand } = this.props

    const shouldExpand = alwaysExpand || openBarrierOptions
    const optionItemClasses = classNames('item', 'col-lg-3', 'col-md-6', 'col-sm-12')
    const optionEmptyClasses = classNames('item', 'col-lg-3', 'col-md-6', 'col-sm-none')

    return (
      <div key={addNewBarrierId}>
        <form className={classNames('addNewBarrierForm')} noValidate autoComplete="off">
          <div className={"newBarrierNote"}>
          <TextField
            // error={newBarrierNoteError}
            variant="outlined"
            label="Add new barrier note"
            size="small"
            fullWidth
            maxChars={255}
            showCharacterCount
            value={newBarrierNote}
            onClick={this.showBarrierOptions}
            onChange={e => {
              this.setState({
                newBarrierNote: e.target.value
              })
            }}
            onKeyUp={this.onBarrierInputKeyUp}
            InputProps={{
              endAdornment: newBarrierNote && (
                <IconButton
                  tabIndex={-1}
                  aria-label="Clear"
                  style={{ opacity: 0.5 }}
                  onClick={this.clearBarrierNote}
                ><CancelRoundedIcon /></IconButton>
              )
            }}
          />
          </div>
          <div
            className={classNames('newBarrierOptionsContainer')}
            style={{
              marginTop: shouldExpand ? 18 : 8
            }}
          >
            <Collapse timeout={500} in={shouldExpand}>
              <Paper elevation={1} className={classNames('newBarrierOptions')}>
                <div className={classNames('row')}>
                  {/*Barrier Category*/}
                  <BarrierCategoryPicker
                    className={optionItemClasses}
                    hasError={newBarrierCategoryError}
                    onSelect={value => {
                      this.setState({
                        newBarrierCategory: value,
                        newBarrierSourceType: null    // reset the value in case the category changes
                      })
                    }}
                  />

                  {/*Barrier Category Templates*/}
                  <BarrierCategoryTemplatesPicker
                    className={classNames(optionItemClasses, 'newBarrierCategoryTemplate')}
                    hasError={newBarrierTemplateError}
                    categoryId={newBarrierCategory?.id}
                    onSelect={value => {
                      this.setState({
                        newBarrierTemplate: value
                      })
                    }}
                  />

                  <div className={classNames('item', 'col-lg-3', 'col-md-6', 'col-sm-4', 'col-xs-8')} />
                  {/* Empty space filler */}

                  {/*Barrier Pin & Escalate Buttons*/}
                  <BarrierActionButtons
                    className={classNames('item', 'col-lg-3', 'col-md-6', 'col-sm-2',  'col-xs-4')}
                    style={{paddingLeft: 0}}
                    onEscalateChange={(isSelected) => {
                      this.setState({ newBarrierEscalated: isSelected })
                    }}
                    onPinnedChange={(isSelected) => {
                      this.setState({ newBarrierPinned: isSelected })
                    }}
                  />
                </div>

                {/*<div className={classNames('break')} />*/}
                {/* Row separator */}

                <div className={classNames('row')} style={{marginTop: '11px'}}>
                  {/*Barrier Owner*/}
                  <BarrierOwnerPicker
                    className={optionItemClasses}
                    onSelect={value => {
                      this.setState({
                        newBarrierKnownUser: value
                      })
                    }}
                  />

                  {/*Barrier Source Type (Require barrier category code)*/}
                  {(newBarrierTemplate && newBarrierCategory) ? (
                    <BarrierSourceTypePicker
                      className={optionItemClasses}
                      barrierCategoryCode={newBarrierCategory.code}
                      onSelect={ value => {
                        this.setState({
                          newBarrierSourceType: value
                        })
                      }}
                    />
                  ) : (<div className={optionEmptyClasses} />)}

                  {/*Barrier Source Details (Require newBarrierSourceType from <BarrierSourceTypePicker>)*/}
                  { (newBarrierTemplate && newBarrierCategory && newBarrierSourceType !== null) ? (
                    <BarrierSourceDetailsInput
                      className={optionItemClasses}
                      barrierSourceType={newBarrierSourceType}
                      onSelect={value => {
                        this.setState({
                          newBarrierSourceName: value
                        })
                      }}
                    />
                  ) : (<div className={optionEmptyClasses} />)}

                  <div className={optionItemClasses} style={{textAlign: 'right'}}>
                    <Button
                      onClick={this.checkAndCloseNewBarrier}
                      color="primary"
                      className={classNames('formActionButton')}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.checkAndSaveNewBarrier}
                      variant="contained"
                      color="primary"
                      className={classNames('formActionButton')}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Paper>
            </Collapse>
          </div>

          {/*Unsaved barrier input exit confirmation dialog*/}
          <Dialog
            open={openUnsavedExitConfirmation}
            style={{ "fontSize": "14px" }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <AlertIcon
                fontSize="medium"
                style={{
                  fill: '#ef6c00',
                  position: 'relative',
                  top: '6px',
                  marginRight: '4px'
                }}
              />You have unsaved changes. Are you sure you want to cancel?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                If you cancel without saving, all unsaved changes will be deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({
                  openUnsavedExitConfirmation: false
                })
              }} color="primary" autoFocus>
                Go back
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    openUnsavedExitConfirmation: false
                  })
                  setTimeout(this.clearAllAndReset, 100)
                  if (typeof onCancel == 'function') {
                    onCancel()
                  }
                }}
                color="primary">
                Ignore & Leave
              </Button>
            </DialogActions>
          </Dialog>

          {/*Required fields alert dialog*/}
          <Dialog
            open={openMissingRequiredFieldsAlert}
            onClick={() => {
              this.setState({
                openMissingRequiredFieldsAlert: false
              })
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Missing required fields</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have not completed the barrier entry. To complete the entry you must select a barrier category and a barrier name.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                this.setState({
                  openMissingRequiredFieldsAlert: false
                })
              }} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </form>
        <Snackbar
          open={notifyNewBarrierSuccess}
          message="Successfully created a new barrier"
          autoHideDuration={3000}
          onRequestClose={() => {
            this.setState({
              notifyNewBarrierSuccess: false
            })
          }}
          bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
        />
      </div>
    )
  }
}

AddNewBarrier.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

AddNewBarrier.propTypes = {
  planId: PropTypes.string.isRequired,
  rerender: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  alwaysExpand: PropTypes.bool,
  visit: PropTypes.object.isRequired,
  onCreateBarrierFromTemplate: PropTypes.func.isRequired,
}

export default AddNewBarrier


