/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AcceptedRecommendationId = {|
  patientId: string,
  code: string,
  encounterId: string,
  conditionName: string,
|};
export type query_accept_MutationVariables = {|
  acceptedRecommendationId: AcceptedRecommendationId,
  status?: ?boolean,
  loadId: number,
|};
export type query_accept_MutationResponse = {|
  +acceptedRecommendationMutation: ?{|
    +accept: ?{|
      +acceptedRecommendationId: ?{|
        +patientId: string,
        +encounterId: string,
        +conditionName: string,
        +code: string,
      |},
      +description: ?string,
      +predictionDate: ?string,
      +status: ?boolean,
      +loadId: ?string,
      +riskBracket: ?string,
      +auditMeta: ?{|
        +createdBy: {|
          +firstName: string,
          +lastName: string,
        |},
        +created: any,
        +updatedBy: {|
          +firstName: string,
          +lastName: string,
        |},
        +updated: any,
      |},
    |}
  |}
|};
export type query_accept_Mutation = {|
  variables: query_accept_MutationVariables,
  response: query_accept_MutationResponse,
|};
*/


/*
mutation query_accept_Mutation(
  $acceptedRecommendationId: AcceptedRecommendationId!
  $status: Boolean
  $loadId: Int!
) {
  acceptedRecommendationMutation {
    accept(acceptedRecommendationId: $acceptedRecommendationId, status: $status, loadId: $loadId) {
      acceptedRecommendationId {
        patientId
        encounterId
        conditionName
        code
      }
      description
      predictionDate
      status
      loadId
      riskBracket
      auditMeta {
        createdBy {
          firstName
          lastName
          id
        }
        created
        updatedBy {
          firstName
          lastName
          id
        }
        updated
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acceptedRecommendationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loadId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v3 = [
  {
    "kind": "Variable",
    "name": "acceptedRecommendationId",
    "variableName": "acceptedRecommendationId"
  },
  {
    "kind": "Variable",
    "name": "loadId",
    "variableName": "loadId"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AcceptedRecommendationIdOut",
  "kind": "LinkedField",
  "name": "acceptedRecommendationId",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encounterId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "conditionName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "predictionDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "loadId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "riskBracket",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = [
  (v10/*: any*/),
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v15 = [
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "query_accept_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AcceptedRecommendationMutation",
        "kind": "LinkedField",
        "name": "acceptedRecommendationMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AcceptedRecommendation",
            "kind": "LinkedField",
            "name": "accept",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditMeta",
                "kind": "LinkedField",
                "name": "auditMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "updatedBy",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "query_accept_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AcceptedRecommendationMutation",
        "kind": "LinkedField",
        "name": "acceptedRecommendationMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AcceptedRecommendation",
            "kind": "LinkedField",
            "name": "accept",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditMeta",
                "kind": "LinkedField",
                "name": "auditMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "updatedBy",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a6d317f046dca6d39766e99bf22b08dd",
    "id": null,
    "metadata": {},
    "name": "query_accept_Mutation",
    "operationKind": "mutation",
    "text": "mutation query_accept_Mutation(\n  $acceptedRecommendationId: AcceptedRecommendationId!\n  $status: Boolean\n  $loadId: Int!\n) {\n  acceptedRecommendationMutation {\n    accept(acceptedRecommendationId: $acceptedRecommendationId, status: $status, loadId: $loadId) {\n      acceptedRecommendationId {\n        patientId\n        encounterId\n        conditionName\n        code\n      }\n      description\n      predictionDate\n      status\n      loadId\n      riskBracket\n      auditMeta {\n        createdBy {\n          firstName\n          lastName\n          id\n        }\n        created\n        updatedBy {\n          firstName\n          lastName\n          id\n        }\n        updated\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '57603e522e6a198d9cbe72216dc0371e';

module.exports = node;
