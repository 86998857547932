/* eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DatePicker from 'material-ui/DatePicker'
import { ComplianceDatePicker } from '../compliance'
import TimePicker from 'material-ui/TimePicker'
import {
  red400 as PastColor,
  grey200 as ClearColor,
} from 'material-ui/styles/colors'
import IconButton from 'material-ui/IconButton'
import Clear from '../icons/Clear'
import { ConfirmDialog } from '../../components/common'

import { deepDiffShouldComponentUpdate } from '../../util/should-component-update'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'

const styleSheet = createStyleSheet('DateTimePicker', theme => ({
  relativelyPositioned: {
    position: 'relative',
  },
  iconButtonStyle: {
    position: 'absolute !important',
    bottom: '16px',
    right: 0,
    backgroundColor: `${ClearColor} !important`,
    borderRadius: '50%',
    width: '24px !important',
    height: '24px !important',
    '& svg': {
      position: 'absolute',
      top: '4px !important',
      right: 0,
      paddingBottom: '8px',
      color: 'gray',
    },
  },
}))

/**
 * A composition of a DatePicker and TimePicker that gives the effect of a combined Date Time field.
 * If Material UI ever comes out with one, we should scrap this one.
 */
export class DateTimePicker extends Component {
  static propTypes = {
    hintText: PropTypes.string,
    floatingLabelText: PropTypes.string,
    highlightIfPast: PropTypes.bool,
    allowPastDates: PropTypes.bool,
    disabled: PropTypes.bool,
    showRelativeTime: PropTypes.bool,
    displayFormat: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    dirty: PropTypes.bool,
    compliance: PropTypes.object,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  static defaultProps = {
    displayFormat: 'MM/DD/YYYY HH:mm',
  }

  state = {
    confirmOpen: false,
  }

  componentWillMount() {
    this.setState({ datePickerKey: this.props.value })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ datePickerKey: nextProps.value })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
    // return deepDiffShouldComponentUpdate(
    //   this.props,
    //   nextProps,
    //   this.state,
    //   nextState,
    //   {
    //     debug: true,
    //     key: 'DateTimePicker'
    //   }
    // )
  }

  shallowCompareAndPrint(oldObj, newObj) {
    let changed = false
    Object.keys(oldObj).forEach(key => {
      if (oldObj[key] !== newObj[key]) {
        changed = true
        // console.log(
        //   `props[${key}] changed from ${oldObj[key]} to ${newObj[key]}`
        // )
      }
    })
    return changed
  }

  handleClearClick = () => {
    this.setState({ confirmOpen: true })
  }

  handleCancelConfirmRemove = () => {
    this.setState({ confirmOpen: false })
  }

  handleConfirmRemove = () => {
    this.setState({ confirmOpen: false })
    this.props.onChange(null)
  }

  oldVersion(classes) {
    const {
      hintText,
      floatingLabelText = hintText,
      highlightIfPast = false,
      allowPastDates = true,
      showRelativeTime = false,
      value,
      error,
      onChange,
      dirty,
      disabled,
      displayFormat,
    } = this.props

    let datePart = value && moment(value).format('YYYY-MM-DD')
    let timePart = value && moment(value).format('HH:mm:ss[z]')
    let timePicker = null
    const isPast = highlightIfPast ? moment(value).isBefore(moment()) : false
    const disableDate = date =>
      allowPastDates
        ? false
        : moment(date).startOf('day') < moment().startOf('day')

    /*
     * TODO: This uses a key on the DatePicker to force the DatePicker to re-render.
     * However, it should be replaced with a div that does the rendering and the
     * actual DatePicker should be hidden and invoked on click of the div
     * (Exactly how the TimePicker is invoked)
     */

    return (
      <div className={classNames(classes.relativelyPositioned)}>
        <DatePicker
          key={this.state && this.state.datePickerKey}
          id={'DatePicker-' + hintText}
          disabled={disabled}
          hintText={hintText}
          textFieldStyle={{ width: '100%', cursor: 'pointer' }}
          shouldDisableDate={disableDate}
          inputStyle={{ color: isPast ? PastColor : '' }}
          floatingLabelText={floatingLabelText}
          value={value ? moment(value).toDate() : null}
          formatDate={date => {
            const d = moment(date)
            return (
              `${d.format(displayFormat)}` +
              (showRelativeTime ? ` (${d.fromNow()})` : '')
            )
          }}
          errorText={dirty && error}
          autoOk
          disableYearSelection
          onChange={(_, date) => {
            datePart = moment(date).format('YYYY-MM-DD')
            timePicker.openDialog()
          }}
        />

        {value && !disabled && (
          <IconButton
            className={classNames(classes.iconButtonStyle)}
            onClick={() => {
              this.handleClearClick()
            }}>
            <Clear />
          </IconButton>
        )}

        <TimePicker
          id={'TimePicker-' + hintText}
          ref={_timePicker => (timePicker = _timePicker)}
          format="24hr"
          style={{ display: 'none' }}
          value={value ? moment(value).toDate() : null}
          autoOk
          onChange={(_, date) => {
            timePart = moment(date).format('HH:mm:ss')
            onChange(moment(`${datePart}T${timePart}`).toISOString())
          }}
        />

        <ConfirmDialog
          open={this.state.confirmOpen}
          message={`Are you sure you want to remove the ${hintText} time?`}
          onCancelClick={this.handleCancelConfirmRemove}
          onConfirmClick={this.handleConfirmRemove}
        />
      </div>
    )
  }

  newVersion(classes) {
    const {
      hintText,
      floatingLabelText = hintText,
      highlightIfPast = false,
      allowPastDates = true,
      showRelativeTime = false,
      value,
      error,
      onChange,
      dirty,
      disabled,
      displayFormat,
      compliance,
    } = this.props

    let datePart = value && moment(value).format('YYYY-MM-DD')
    let timePart = value && moment(value).format('HH:mm:ss[z]')
    let timePicker = null
    const isPast = highlightIfPast ? moment(value).isBefore(moment()) : false
    const disableDate = date =>
      allowPastDates
        ? false
        : moment(date).startOf('day') < moment().startOf('day')

    /*
     * TODO: This uses a key on the DatePicker to force the DatePicker to re-render.
     * However, it should be replaced with a div that does the rendering and the
     * actual DatePicker should be hidden and invoked on click of the div
     * (Exactly how the TimePicker is invoked)
     */

    return (
      <div className={classNames(classes.relativelyPositioned)}>
        <ComplianceDatePicker
          key={this.state && this.state.datePickerKey}
          id={'DatePicker-' + hintText}
          disabled={disabled}
          hintText={hintText}
          // textFieldStyle={{width: '100%', cursor: 'pointer'}}
          shouldDisableDate={disableDate}
          // inputStyle={{color: isPast ? PastColor : ''}}
          floatingLabelText={floatingLabelText}
          value={value ? moment(value).toDate() : null}
          formatDate={date => {
            const d = moment(date)
            return (
              `${d.format(displayFormat)}` +
              (showRelativeTime ? ` (${d.fromNow()})` : '')
            )
          }}
          errorText={dirty && error}
          autoOk
          disableYearSelection
          onClear={this.handleClearClick}
          showClear={!!value}
          disableClear={!value || disabled}
          isPast={isPast}
          compliance={compliance}
          onChange={(_, date) => {
            datePart = moment(date).format('YYYY-MM-DD')
            timePicker.openDialog()
          }}
        />

        <TimePicker
          id={'TimePicker-' + hintText}
          ref={_timePicker => (timePicker = _timePicker)}
          format="24hr"
          style={{ display: 'none' }}
          value={value ? moment(value).toDate() : null}
          autoOk
          onChange={(_, date) => {
            timePart = moment(date).format('HH:mm:ss')
            onChange(moment(`${datePart}T${timePart}`).toISOString())
          }}
        />

        <ConfirmDialog
          open={this.state.confirmOpen}
          message={`Are you sure you want to remove the ${hintText} time?`}
          onCancelClick={this.handleCancelConfirmRemove}
          onConfirmClick={this.handleConfirmRemove}
        />
      </div>
    )
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)
    // disabled={isReserved || isSwapped || isDischarged}
    // return window.aaa ? this.newVersion(classes) : this.oldVersion(classes)
    return this.newVersion(classes)
  }
}
