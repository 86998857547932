/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CollapsedBarrier } from './CollapsedBarrier'
import { ExpandedBarrier } from './ExpandedBarrier'

export class Barrier extends Component {
  static propTypes = {
    rerender: PropTypes.func,
    item: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    visit: PropTypes.object.isRequired,
    expanded: PropTypes.bool.isRequired,
    onUpdateBarrierStatus: PropTypes.func.isRequired,
    onUpdateBarrierOwner: PropTypes.func.isRequired,
    onSearchKnownPersonnel: PropTypes.func.isRequired,
    onEscalateBarrier: PropTypes.func.isRequired,
    onDeescalateBarrier: PropTypes.func.isRequired,
    onCreateBarrierNote: PropTypes.func,
    onUpdateBarrierNote: PropTypes.func,
    onRemoveBarrierNote: PropTypes.func,
    onUpdatePinned: PropTypes.func.isRequired,
    onViewBarrierInfoHistory: PropTypes.func,
    onViewSendBarrierDialog: PropTypes.func,
    onViewManageWatchersDialog: PropTypes.func,
    onBarrierClick: PropTypes.func.isRequired,
    showBarrierIcon: PropTypes.bool,
    barrierEscalationRoles: PropTypes.array,
    onUpdateSourceType: PropTypes.func.isRequired,
    onUpdateSourceName: PropTypes.func.isRequired,
    deliveryMethods: PropTypes.array
  }

  render() {
    const {
      item,
      plan,
      visit,
      expanded,
      onUpdatePinned,
      onEscalateBarrier,
      onDeescalateBarrier,
      onUpdateBarrierStatus,
      onUpdateBarrierOwner,
      onSearchKnownPersonnel,
      onCreateBarrierNote,
      onUpdateBarrierNote,
      onViewBarrierInfoHistory,
      onViewSendBarrierDialog,
      onViewManageWatchersDialog,
      onRemoveBarrierNote,
      onBarrierClick,
      showBarrierIcon,
      rerender,
      barrierEscalationRoles,
      onUpdateSourceType,
      onUpdateSourceName,
      deliveryMethods
    } = this.props

    return expanded ? (
      <ExpandedBarrier
        barrier={item}
        plan={plan}
        visit={visit}
        onUpdatePinned={onUpdatePinned}
        onEscalateBarrier={onEscalateBarrier}
        onDeescalateBarrier={onDeescalateBarrier}
        onUpdateBarrierStatus={onUpdateBarrierStatus}
        onUpdateBarrierOwner={onUpdateBarrierOwner}
        onSearchKnownPersonnel={onSearchKnownPersonnel}
        onCreateBarrierNote={onCreateBarrierNote}
        onUpdateBarrierNote={onUpdateBarrierNote}
        onRemoveBarrierNote={onRemoveBarrierNote}
        onViewBarrierInfoHistory={onViewBarrierInfoHistory}
        onViewSendBarrierDialog={onViewSendBarrierDialog}
        onViewManageWatchersDialog={onViewManageWatchersDialog}
        onBarrierClick={onBarrierClick}
        showBarrierIcon={showBarrierIcon}
        rerender={rerender}
        barrierEscalationRoles={barrierEscalationRoles}
        onUpdateSourceType={onUpdateSourceType}
        onUpdateSourceName={onUpdateSourceName}
        deliveryMethods={deliveryMethods}
      />
    ) : (
      <CollapsedBarrier
        barrier={item}
        visit={visit}
        onUpdatePinned={onUpdatePinned}
        onEscalateBarrier={onEscalateBarrier}
        onDeescalateBarrier={onDeescalateBarrier}
        onUpdateBarrierStatus={onUpdateBarrierStatus}
        onUpdateBarrierOwner={onUpdateBarrierOwner}
        onBarrierClick={onBarrierClick}
        showBarrierIcon={showBarrierIcon}
        rerender={rerender}
      />
    )
  }
}
