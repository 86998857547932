import gql, {injectForGqlFile} from '../util/gql'

//Note: The note below is not true for this push but leaving comment in for now
//Note: In AllFullFacility assignedBedsOnly is set to true but should be false, but right now this takes 2 minutes for the server to return data :(

export const WorkAreaGQL = {
  fragments: {
    FullFacility: gql`
      fragment FullFacility on Facility {
        name
        exchangeName
        ordinal
        departments(assignedBedsOnly: $assignedBedsOnly) {
          ...FullDepartment
        }
      }
    `,
    AssignedFullFacility: gql`
      fragment AssignedFullFacility on Facility {
        name
        exchangeName
        ordinal
        departments(assignedBedsOnly: true) {
          ...FullDepartment
        }
      }
    `,
    AllFullFacility: gql`
      fragment AllFullFacility on Facility {
        name
        exchangeName
        ordinal
        departments(assignedBedsOnly: false) {
          ...FullDepartment
        }
      }
    `,
    SimpleFacility: gql`
      fragment SimpleFacility on Facility {
        name
        exchangeName
        ordinal
      }
    `,
    FullDepartment: gql`
      fragment FullDepartment on Department {
        type
        exchangeName
        name
        beds {
          ...FullBed
        }
        includedInLosCalculation
      }
    `,
    SimpleDepartment: gql`
      fragment SimpleDepartment on Department {
        type
        exchangeName
        name
        includedInLosCalculation
      }
    `,
    FullBed: gql`
      fragment FullBed on Bed {
        exchangeName
        name
        assigned
        status

        # Visits are only accessible as ids at the bed level
        visitId
        dischargedVisitId
        conflictingVisitIds
        providers {
          ...FullPersonnel
        }
      }
    `,
    WorkspaceBed: gql`
      fragment WorkspaceBed on Bed {
        exchangeName
        name
        # don't want assigned here as it can be different from what's in cache
        # and cause superfluous server calls
        status

        # Visits are only accessible as ids at the bed level
        visitId
        dischargedVisitId
        conflictingVisitIds
        providers {
          ...FullPersonnel
        }
      }
    `,
    BedProviders: gql`
      fragment BedProviders on Bed {
        exchangeName
        providers {
          ...FullPersonnel
        }
      }
    `,
  },
  queries: {
    AllFacilityBeds: gql`
      query AllFacilityBeds {
        facilities {
          ...AllFullFacility
        }
      }
    `,
    AssignedFacilityBeds: gql`
      query AssignedFacilityBeds {
        facilities {
          ...AssignedFullFacility
        }
      }
    `,
    FacilityBeds: gql`
      query FacilityBeds($assignedBedsOnly: Boolean!) {
        facilities {
          ...FullFacility
        }
      }
    `,
  },
  mutations: {
    assignBeds: gql`
      mutation assignBeds($exchangeNames: [String]!) {
        workAreaMutation {
          assignBeds(exchangeNames: $exchangeNames) {
            ...AllFullFacility
          }
        }
      }
    `,
  },
}

injectForGqlFile(WorkAreaGQL)
