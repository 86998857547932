/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type careTeam_PlanCareTeam$ref = any;
export type careTeam_assignCareTeamMember_MutationVariables = {|
  planId: any,
  memberId: any,
|};
export type careTeam_assignCareTeamMember_MutationResponse = {|
  +careTeamMemberMutation: ?{|
    +assignCareTeamMember: ?{|
      +$fragmentRefs: careTeam_PlanCareTeam$ref
    |}
  |}
|};
export type careTeam_assignCareTeamMember_Mutation = {|
  variables: careTeam_assignCareTeamMember_MutationVariables,
  response: careTeam_assignCareTeamMember_MutationResponse,
|};
*/


/*
mutation careTeam_assignCareTeamMember_Mutation(
  $planId: UUID!
  $memberId: UUID!
) {
  careTeamMemberMutation {
    assignCareTeamMember(planId: $planId, memberId: $memberId) {
      ...careTeam_PlanCareTeam
      id
    }
  }
}

fragment careTeam_PlanCareTeam on Plan {
  id
  careTeamMembers {
    id
    name
    primary
    role {
      id
      code
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "memberId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "memberId",
    "variableName": "memberId"
  },
  {
    "kind": "Variable",
    "name": "planId",
    "variableName": "planId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "careTeam_assignCareTeamMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CareTeamMemberMutation",
        "kind": "LinkedField",
        "name": "careTeamMemberMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "assignCareTeamMember",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "careTeam_PlanCareTeam"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "careTeam_assignCareTeamMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CareTeamMemberMutation",
        "kind": "LinkedField",
        "name": "careTeamMemberMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "assignCareTeamMember",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CareTeamMember",
                "kind": "LinkedField",
                "name": "careTeamMembers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primary",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CodeTablePair",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8bfabe5c584da9f6d64088b3019a388",
    "id": null,
    "metadata": {},
    "name": "careTeam_assignCareTeamMember_Mutation",
    "operationKind": "mutation",
    "text": "mutation careTeam_assignCareTeamMember_Mutation(\n  $planId: UUID!\n  $memberId: UUID!\n) {\n  careTeamMemberMutation {\n    assignCareTeamMember(planId: $planId, memberId: $memberId) {\n      ...careTeam_PlanCareTeam\n      id\n    }\n  }\n}\n\nfragment careTeam_PlanCareTeam on Plan {\n  id\n  careTeamMembers {\n    id\n    name\n    primary\n    role {\n      id\n      code\n      value\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0202e2c04f045497a67a81321199298a';

module.exports = node;
