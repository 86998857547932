import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Paper } from 'material-ui'
import { accumulateProviders } from '../../util/utils'
import { VisitDetailsRow } from './VisitDetailsRow'

import {
  CareTeamMembers,
  InsuranceInfo,
  ReadmissionRisk,
  ReadmissionVisit,
  CurrentLocation,
} from '../../components/visit'

import { InfoSecondary } from './InfoSecondary'
import { InfoDRG } from './InfoDRG'

export class VisitDetails extends Component {
  static propTypes = {
    workspace: PropTypes.shape({
      visit: PropTypes.object.required,
      plan: PropTypes.object.required,
      bed: PropTypes.object.required,
      department: PropTypes.object.required,
    }),
    plEnabled: PropTypes.bool.isRequired,
    onReadmissionRiskChange: PropTypes.func.isRequired,
    onReadmissionChange: PropTypes.func.isRequired,
    onDRGNumberChange: PropTypes.func.isRequired,
    onSearchDRG: PropTypes.func.isRequired,
    onMoveToHoldingArea: PropTypes.func,
  }

  render() {
    const {
      workspace,
      onReadmissionRiskChange,
      onReadmissionChange,
      onDRGNumberChange,
      onSearchDRG,
      onMoveToHoldingArea,
      plEnabled,
    } = this.props

    const { visit, bed, plan, department } = workspace
    const isInPatient = visit.admissionType === 'InPatient'

    return (
      <Paper style={{ paddingRight: 10, paddingLeft: 10, overflow: 'hidden' }}>
        <VisitDetailsRow>
          <InfoSecondary visit={visit} />
        </VisitDetailsRow>
        <VisitDetailsRow label="Admitting Complaint" height="120px">
          <span style={{ paddingLeft: '15px' }}>
            {visit.admittingComplaint}
          </span>
        </VisitDetailsRow>

        {isInPatient && (
          <VisitDetailsRow height="undefined" label="DRG">
            <InfoDRG
              visit={visit}
              onDRGNumberUpdate={onDRGNumberChange}
              onSearchDRG={onSearchDRG}
            />
          </VisitDetailsRow>
        )}

        <VisitDetailsRow height="undefined" label="Readmission Visit">
          <ReadmissionVisit
            visit={visit}
            readmissionVisit={visit.readmission}
            readmissionVerifiedBy={visit.readmissionVerifiedBy}
            readmissionUpdatedWhen={visit.readmissionUpdatedWhen}
            onReadmissionChange={onReadmissionChange}
          />
        </VisitDetailsRow>

        <VisitDetailsRow height="undefined" label="Readmission Risk">
          <ReadmissionRisk
            visit={visit}
            readmissionRisk={visit.readmissionRisk}
            readmissionRiskVerifiedBy={visit.readmissionRiskVerifiedBy}
            readmissionRiskUpdatedWhen={visit.readmissionRiskUpdatedWhen}
            onReadmissionRiskChange={onReadmissionRiskChange}
          />
        </VisitDetailsRow>

        <VisitDetailsRow height="undefined" label="Care Team Members">
          <CareTeamMembers
            providers={accumulateProviders(visit, bed)}
            careTeamMembers={plan.careTeamMembers}
            planId={plan.id}
            visitId={visit.id}
            workspace={workspace}
            bedExchangeName={bed.exchangeName}
          />
        </VisitDetailsRow>

        <VisitDetailsRow height="undefined" label="Insurance Information">
          <InsuranceInfo insurancePlans={visit.insurancePlans} />
        </VisitDetailsRow>
        {!plEnabled && visit.bedRequestStatus !== 'Transferred' && (
          <VisitDetailsRow height="undefined" label="Current Location">
            <CurrentLocation
              department={department}
              bed={bed}
              visit={visit}
              onMoveToHoldingArea={onMoveToHoldingArea}
            />
          </VisitDetailsRow>
        )}
      </Paper>
    )
  }
}
