/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { compose, mapProps } from 'recompose'

import '../views.scss'
import { PlanHeaderGQL } from '../../containers/plan/PlanHeaderGQL'

import DetailTabs from './DetailTabs'
import { isJvionEnabled } from '../../util/configs'
import {connect} from "react-redux"
import { clearErrorPg } from '../../ducks/errorspg'
import {bindActionCreators} from "redux"
import {TransactionErrorDialog} from "../../components/error"


export class PatientPlanComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillReceiveProps(nextProps) {
    let workspace = {}
    workspace.visit = nextProps.visit
    workspace.bed = workspace.visit.bed || workspace.visit.node.bed
    workspace.department = workspace.bed.department
    workspace.plan = workspace.visit.plan || workspace.visit.node.plan

    this.setState({ workspace: workspace })
  }

  handleViewPlanList = () => {
    this.props.history.push(`/my-patient-list`, {})
  }


  noWorkspace = () => {
    return (
      <h3 style={{textAlign: 'center'}}>
        <div onClick={this.handleViewPlanList}>
          <div>Patient is no longer at this location</div>
          <a>(Click to return to My Patient List)</a>
        </div>
      </h3>
    )
  }

  render() {
    if (!this.props.visit) {
      return this.noWorkspace()
    }
    const {actions: { clearErrorPg }, userSetting, barrierEscalationRoles, deliveryMethods} = this.props

    let workspace = {}
    // CA-2824 - handle different graphs when querying by bed or visit
    // we use bed for everything except recent discharges
    workspace.visit = this.props.visit

    workspace.bed = workspace.visit ? workspace.visit.bed : null
    workspace.department = workspace.bed ? workspace.bed.department : null
    workspace.plan = workspace.visit ? workspace.visit.plan : null

    return (
      <div className="patient-plan">
        <PlanHeaderGQL
        workspace={workspace}
        refetch={this.props.refetch}
        />
        <DetailTabs
          userSetting={userSetting}
          barrierEscalationRoles={barrierEscalationRoles}
          deliveryMethods={deliveryMethods}
          workspace={workspace}
          jvionEnabled={isJvionEnabled()}
          refetch={this.props.refetch}
        />
        <TransactionErrorDialog
          errors={this.props.errors}
          onRequestClose={clearErrorPg}
        />
      </div>
    )
  }
}

PatientPlanComp.propTypes = {
  actions: PropTypes.shape({
    clearErrorPg: PropTypes.func,
  }),

  refetch: PropTypes.func,
  visit: PropTypes.shape({
    node: PropTypes.object,
  }),
  userSetting: PropTypes.shape({
    actions: PropTypes.array,
    barriers: PropTypes.array,
    notes: PropTypes.array,
  }),
  barrierEscalationRoles: PropTypes.array,
  deliveryMethods: PropTypes.array,
  workspace: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        bedId: PropTypes.string,
        visitId: PropTypes.string,
        planId: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool,
  errors: PropTypes.array,

}

function mapStateToProps(state) {
  return {
    errors: state.errorStatePg.errorspg,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearErrorPg,
      },
      dispatch
    ),
  }
}

export const PatientPlan = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PatientPlanComp)


