/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type careTeam_careTeamMembers_QueryVariables = {||};
export type careTeam_careTeamMembers_QueryResponse = {|
  +careTeamMembers: ?$ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +role: {|
      +id: string,
      +code: ?string,
      +value: ?string,
    |},
  |}>
|};
export type careTeam_careTeamMembers_Query = {|
  variables: careTeam_careTeamMembers_QueryVariables,
  response: careTeam_careTeamMembers_QueryResponse,
|};
*/


/*
query careTeam_careTeamMembers_Query {
  careTeamMembers {
    id
    name
    role {
      id
      code
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CareTeamMember",
    "kind": "LinkedField",
    "name": "careTeamMembers",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CodeTablePair",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "careTeam_careTeamMembers_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "careTeam_careTeamMembers_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "262ddf0bebad627967f746363c11831d",
    "id": null,
    "metadata": {},
    "name": "careTeam_careTeamMembers_Query",
    "operationKind": "query",
    "text": "query careTeam_careTeamMembers_Query {\n  careTeamMembers {\n    id\n    name\n    role {\n      id\n      code\n      value\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5486614b6b26bbc65ecce58a953f6a70';

module.exports = node;
