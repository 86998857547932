import React from 'react'
import PropTypes from 'prop-types'

import AlertWarning from 'material-ui/svg-icons/alert/warning'
import AlertError from 'material-ui/svg-icons/alert/error'
import AlertErrorOutline from 'material-ui/svg-icons/alert/error-outline'
import ActionBookmark from 'material-ui/svg-icons/action/bookmark'
import ActionLightbulbOutline from 'material-ui/svg-icons/action/lightbulb-outline'

const icons = new Map([
  ['alert.warning', AlertWarning],
  ['alert.error', AlertError],
  ['alert.error-outline', AlertErrorOutline],
  ['action.bookmark', ActionBookmark],
  ['action.lightbulb-outline', ActionLightbulbOutline],
])

export function icon(str) {
  return icons.get(str) || AlertWarning
}

export const NamedSvgIcon = ({ name, children, ...props }) => {
  return React.createElement(icon(name), props, children)
}

NamedSvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
}
