/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type barrier_addWatchers_MutationVariables = {|
  barrierId: any,
  userIds: $ReadOnlyArray<any>,
|};
export type barrier_addWatchers_MutationResponse = {|
  +barrierMutation: ?{|
    +addWatchers: $ReadOnlyArray<?{|
      +id: any,
      +knownUser: ?{|
        +id: string,
        +firstName: string,
        +lastName: string,
        +title: ?string,
      |},
    |}>
  |}
|};
export type barrier_addWatchers_Mutation = {|
  variables: barrier_addWatchers_MutationVariables,
  response: barrier_addWatchers_MutationResponse,
|};
*/


/*
mutation barrier_addWatchers_Mutation(
  $barrierId: UUID!
  $userIds: [UUID!]!
) {
  barrierMutation {
    addWatchers(barrierId: $barrierId, userIds: $userIds) {
      id
      knownUser {
        id
        firstName
        lastName
        title
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BarrierMutation",
    "kind": "LinkedField",
    "name": "barrierMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "barrierId",
            "variableName": "barrierId"
          },
          {
            "kind": "Variable",
            "name": "userIds",
            "variableName": "userIds"
          }
        ],
        "concreteType": "BarrierWatcher",
        "kind": "LinkedField",
        "name": "addWatchers",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KnownUser",
            "kind": "LinkedField",
            "name": "knownUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "barrier_addWatchers_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "barrier_addWatchers_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8833f87e7833ca2b04f5aecdf610a6ed",
    "id": null,
    "metadata": {},
    "name": "barrier_addWatchers_Mutation",
    "operationKind": "mutation",
    "text": "mutation barrier_addWatchers_Mutation(\n  $barrierId: UUID!\n  $userIds: [UUID!]!\n) {\n  barrierMutation {\n    addWatchers(barrierId: $barrierId, userIds: $userIds) {\n      id\n      knownUser {\n        id\n        firstName\n        lastName\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a53ad3dfe420bbe947e67eb1ec02dea3';

module.exports = node;
