/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateReadmissionRisk_MutationVariables = {|
  visitId: any,
  readmissionRisk: boolean,
|};
export type visit_updateReadmissionRisk_MutationResponse = {|
  +visitMutation: ?{|
    +updateReadmissionRisk: ?{|
      +id: string,
      +readmissionRisk: ?boolean,
      +readmissionRiskVerifiedBy: ?{|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +readmissionRiskUpdatedWhen: ?any,
    |}
  |}
|};
export type visit_updateReadmissionRisk_Mutation = {|
  variables: visit_updateReadmissionRisk_MutationVariables,
  response: visit_updateReadmissionRisk_MutationResponse,
|};
*/


/*
mutation visit_updateReadmissionRisk_Mutation(
  $visitId: Long!
  $readmissionRisk: Boolean!
) {
  visitMutation {
    updateReadmissionRisk(visitId: $visitId, readmissionRisk: $readmissionRisk) {
      id
      readmissionRisk
      readmissionRiskVerifiedBy {
        userName
        firstName
        lastName
        id
      }
      readmissionRiskUpdatedWhen
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "readmissionRisk"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "readmissionRisk",
    "variableName": "readmissionRisk"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmissionRisk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmissionRiskUpdatedWhen",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateReadmissionRisk_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateReadmissionRisk",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "readmissionRiskVerifiedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateReadmissionRisk_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateReadmissionRisk",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "readmissionRiskVerifiedBy",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2cce460b28f356389e3354f51bd61512",
    "id": null,
    "metadata": {},
    "name": "visit_updateReadmissionRisk_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateReadmissionRisk_Mutation(\n  $visitId: Long!\n  $readmissionRisk: Boolean!\n) {\n  visitMutation {\n    updateReadmissionRisk(visitId: $visitId, readmissionRisk: $readmissionRisk) {\n      id\n      readmissionRisk\n      readmissionRiskVerifiedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      readmissionRiskUpdatedWhen\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9372446f774d8d481884eaa0aaf095a5';

module.exports = node;
