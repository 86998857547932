const planColors = [
  '#f9543f',
  '#2f93d4',
  '#f0f000',
  '#71eeb8',
  '#ffa448',
  '#a436b9'
]

const defaultPlanColor = '#404040'

export const getPlanDotColor = (appliedTemplates, planTemplateName) => {
  const planIndex = appliedTemplates.findIndex(template => template.name === planTemplateName)
  return planIndex < planColors.length ? planColors[planIndex] : defaultPlanColor
}
