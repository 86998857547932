/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import PropTypes from 'prop-types'
import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import IconButton from 'material-ui/IconButton'
import { StatefulTextField } from '../../components/common/StatefulTextField'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import { addBoardTemplateEntry } from '../../graphql/relay/queries/boardTemplate'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import {environment} from "../../graphql/relay/env"
import { bindActionCreators } from 'redux'
import { showErrorsPg } from '../../ducks/errorspg'
import {connect} from 'react-redux'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showErrorsPg
      },
      dispatch
    ),
  }
}

class AddBoardTemplateEntry extends Component {
  static propTypes = {
    data: PropTypes.exact({
      name: PropTypes.string.isRequired,
      boardType: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      template: PropTypes.string.isRequired
    }),
    onRequestClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      showErrorsPg: PropTypes.func
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      name: '',
      boardType: 'Visibility',
      description: '',
      template: '',
    }
  }

  handleError = () => {
    this.setState({
      isSubmitting: false
    })
  }

  handleNameUpdate = e => {
    this.setState({
      name: e.target.value,
    })
  }

  handleDescriptionUpdate = e => {
    this.setState({
      description: e.target.value,
    })
  }

  handleTemplateUpdate = e => {
    this.setState({
      template: e.target.value,
    })
  }

  onApplyHandler = () => {
    this.setState({
      isSubmitting: true
    })
    const variables = {
      boardTemplateInput: {
        name: this.state.name,
        boardType: this.state.boardType,
        description: this.state.description,
        template: this.state.template
      }
    }

    const updater = (proxyStore) => {
      const store = proxyStore.getRootField("boardTemplateMutation")
      const payload = store.getLinkedRecord("addBoardTemplateEntry", variables)
      const rootStore = proxyStore.get("client:root")
      const boardTemplateList = rootStore.getLinkedRecords('boardTemplates')
      rootStore.setLinkedRecords([payload, ...boardTemplateList],'boardTemplates')
    }

    handleMutation(
      environment,
      addBoardTemplateEntry,
      variables,
      () => {
        this.props.onRequestClose && this.props.onRequestClose()
      },
      undefined,
      this.handleError,
      updater
    )
  }

  render() {
    const { title, onRequestClose } = this.props

    const {
      name,
      boardType,
      description,
      template,
      nameOriginal,
      boardTypeOriginal,
      descriptionOriginal,
      templateOriginal
    } = this.state

    const noValueChanged = (name === nameOriginal)
      && (boardType === boardTypeOriginal)
      && (description === descriptionOriginal)
      && (template === templateOriginal)

    const anyValueUnPopulated = !name || !boardType || !description || !template

    const applyDisabled = noValueChanged || anyValueUnPopulated

    const applyButtonStyle = !applyDisabled && {
      labelStyle: {
        color: 'white',
      },
    }


    const actions = [
      <FlatButton
        key="apply"
        label="Apply"
        disabled={applyDisabled}
        {...applyButtonStyle}
        onClick={this.onApplyHandler}
      />,
    ]

    return (
      <div>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div className="row">
          <div className="col-xs-1" />
          <div className="col-xs-5">
            <StatefulTextField
              text={this.state.name}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Name (Required)',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Name'
              }}
              onChange={this.handleNameUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <StatefulTextField
              text={this.state.description}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Description (Required)',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Description'
              }}
              onChange={this.handleDescriptionUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <StatefulTextField
              text={this.state.boardType}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Board Type',
                disabled: true,
                floatingLabelText: 'Board Type'
              }}
              clearOnTextEntered={false}
              showCharacterCount
            />
          </div>
          <div className="col-xs-5">
            <StatefulTextField
              text={this.state.template}
              textFieldProps={{
                style: {
                  marginTop: '20px',
                },
                floatingLabelText: 'Template',
                multiLine: true,
                fullWidth: true,
                hintText: 'Template (Required)',
                disabled: this.state.isSubmitting,
              }}
              inputStyleOverride={{
                overflowY: 'scroll',
                height: '340px',
                backgroundColor: 'rgb(250,250,250)',
                paddingLeft: '5px',
                border: '1px solid #afa7a7',
              }}
              maxNumberOfChars={20000}
              onChange={this.handleTemplateUpdate}
              clearOnTextEntered={false}
              showCharacterCount
            />
          </div>
          <div className="col-xs-1" />
        </div>
      </div>
    )
  }

}
export default connect(null, mapDispatchToProps)(AddBoardTemplateEntry)
