/* eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'

import { hideDrawer } from '../../ducks/views'

import Paper from 'material-ui/Paper'

import { Modal } from '../common/Modal'

import { Logger, LogManager } from '../../log'
const logger = LogManager.getLogger('c3.components.drawer.Drawer')

const styleSheet = createStyleSheet('Drawer', theme => ({
  root: {
    width: '100%',
    margin: 0,
    position: 'fixed',
    bottom: '0',

    '&.show': {
      transform: 'translateY(-100%)',
    },

    background: theme.palette.background.contentFrame,

    zIndex: 1500,

    transition: 'transform 0.5s ease',
  },

  smallSize: {
    height: '200px',
  },
  standardSize: {
    height: '430px',
  },
  bigSize: {
    height: '550px',
  },
  fullSize: {
    // This places it below the Care Advance AppBar, but we can cover the AppBar if we want
    height: 'calc(100% - 50px)', // COVER THE APP BAR
    bottom: 'calc(-100% + 50px)',
    '&.show': {
      transform: 'translateY(-100%)',
    },
  },
}))

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        hideDrawer,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
class Drawer extends Component {
  static propTypes = {
    /**
     * Whether or not this drawer is open
     */
    open: PropTypes.bool.isRequired,

    /**
     * Component determines what to render in the drawer
     */
    component: PropTypes.object,

    /**
     * Additional classNames to apply to the drawer
     */
    className: PropTypes.string,

    /**
     * Text to use in the header of the drawer
     */
    title: PropTypes.string,

    /**
     * Which height to make the drawer (default: 'standard')
     */
    size: PropTypes.oneOf(['small', 'standard', 'big', 'full' /*, 'custom'*/]),

    /**
     * Whether to make this drawer be modal when open (default: true)
     */
    // modal: PropTypes.bool,

    /**
     * If the size is 'custom', the height should be a number of pixels
     */
    // height: PropTypes.number,

    /**
     * Whether or not to show the standard header (default: true)
     */
    showStandardHeader: PropTypes.bool,

    /**
     * Redux actions
     */
    actions: PropTypes.shape({
      hideDrawer: PropTypes.func,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  static defaultProps = {
    size: 'standard',
    showStandardHeader: true,
  }

  state = {
    closing: false,
  }

  componentWillReceiveProps(nextProps) {
    const { open: currentOpen, ...currentProps } = this.props
    const { open: nextOpen } = nextProps

    // If the drawer is closing, briefly hold onto the component until the drawer is closed
    if (currentOpen && !nextOpen) {
      this.prevProps = currentProps
      if (this.drawerDiv) {
        this.drawerDiv.addEventListener(
          'transitionend',
          this.drawerClosed,
          false
        )
      }
      this.setState({ closing: true })
    } else if (nextOpen) {
      // If the drawer is open or opening, clear out everything regarding the close logic
      this.drawerClosed()
    }
  }

  componentWillUnmount() {
    // Wipe any potential refs that we've still got hanging around
    delete this.prevProps
    delete this.drawerDiv
  }

  /**
   * Callback to invoke when the drawer has finished its closing transition
   */
  drawerClosed = event => {
    // There are other transitions, only listen for bottom
    if (!event || event.propertyName === 'transform') {
      // Clear the closing state of the drawer
      this.prevProps = null
      if (this.drawerDiv) {
        this.drawerDiv.removeEventListener(
          'transitionend',
          this.drawerClosed,
          false
        )
      }
      this.setState({ closing: false })
    }
  }

  /**
   * Callback to invoke when the user attempts to close the drawer
   */
  drawerShouldClose = () => {
    //() {
    // todo: This doesn't work if the child is wrapped with an @connect or @graphql
    // todo todo todo Need a strategy to fix this.
    if (
      this.drawerElement &&
      typeof this.drawerElement.shouldClose === 'function'
    ) {
      // Allow drawerShouldClose to be async
      return Promise.resolve(this.drawerElement.shouldClose()).then(
        yesClose => {
          if (yesClose) {
            this.props.actions.hideDrawer()
          }
        }
      )
    } else {
      return Promise.resolve(this.props.actions.hideDrawer())
    }
  }

  render() {
    const { closing } = this.state

    const {
      open,
      component,
      className,
      size,
      // height,
      ...otherProps
      // If this drawer is closing, extract from the old props
    } = closing ? this.prevProps : this.props

    const classes = this.context.styleManager.render(styleSheet)
    const sizeClass = classes[`${size}Size`]

    // If the drawer is in the process of closing, show whatever component was there when it was still open
    // This prevents the drawer from flashing white while it's sliding away
    const InnerDrawerComponent = component || null

    return (
      <Modal visible={!!component && !closing} onClick={this.drawerShouldClose}>
        <Paper>
          <div
            ref={el => (this.drawerDiv = el)}
            className={classNames(
              'drawer-view',
              classes.root,
              sizeClass,
              { show: open },
              className
            )}>
            {InnerDrawerComponent && (
              <InnerDrawerComponent
                innerRef={el => (this.drawerElement = el)}
                onRequestClose={this.drawerShouldClose}
                {...otherProps}
              />
            )}
          </div>
        </Paper>
      </Modal>
    )
  }
}

export default Drawer
