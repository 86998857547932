import gql, {injectForGqlFile} from '../util/gql'

export const PlanGQL = {
  fragments: {
    FullPlan: gql`
      fragment FullPlan on Plan {
        id
        appliedTemplates {
          id
          name
          description
          documentURL
          removed
        }
        careTeamMembers {
          ...FullCareTeamMember
        }
        progressionIndicator
        toBeAdmitted
        meta {
          ...FullPlanMeta
        }
        milestones {
          ...FullMilestone
        }
        barriers {
          ...FullBarrier
        }
        actions {
          ...FullPlanAction
        }
        notes {
          ...FullPlanNote
        }
      }
    `,
    // TODO This needs a little more thought:
    SummaryPlan: gql`
      fragment SummaryPlan on Plan {
        id
        appliedTemplates {
          id
          name
          description
        }
        progressionIndicator
        toBeAdmitted
        meta {
          ...FullPlanMeta
        }
        milestones {
          ...FullMilestone
        }
        barriers {
          ...FullBarrier
        }
        actions {
          ...FullPlanAction
        }
        notes {
          ...FullPlanNote
        }
      }
    `,

    FullPlanMeta: gql`
      fragment FullPlanMeta on PlanMeta {
        hasScratchpadNotes
        milestoneMeta {
          onTime
          overdue
          completed
        }
        lengthOfStayMeta {
          forecast
          cappedForecast
        }
        actualOE
      }
    `,

    PlanCareTeam: gql`
      fragment PlanCareTeam on Plan {
        id
        careTeamMembers {
          ...FullCareTeamMember
        }
      }
    `,
  },
  queries: {
    projectedMilestonesFromTemplate: gql`
      query projectedMilestonesFromTemplate(
        $planId: UUID!
        $planTemplateId: UUID!
      ) {
        projectedMilestonesFromTemplate(
          planId: $planId
          planTemplateId: $planTemplateId
        ) {
          id
          name
          description
          planTemplateName
          dueDate
          dueDateCutoff
          track
          offset {
            days
            hours
            minutes
            basis
          }
        }
      }
    `,
  },
  mutations: {
    updateProgressionIndicator: gql`
      mutation updateProgressionIndicator(
        $planId: UUID!
        $progressionIndicator: ProgressionIndicator!
      ) {
        planMutation {
          updateProgressionIndicator(
            planId: $planId
            progressionIndicator: $progressionIndicator
          ) {
            id
            progressionIndicator
          }
        }
      }
    `,
    applyPlanTemplate: gql`
      mutation applyPlanTemplate($planId: UUID!, $templateId: UUID!) {
        planMutation {
          applyPlanTemplate(planId: $planId, templateId: $templateId) {
            ...FullPlan
          }
        }
      }
    `,
    removePlanTemplate: gql`
      mutation removePlanTemplate($planId: UUID!, $templateId: UUID!) {
        planMutation {
          removePlanTemplate(planId: $planId, templateId: $templateId) {
            ...FullPlan
          }
        }
      }
    `,
    updateMilestoneStatus: gql`
      mutation updateMilestoneStatus(
        $milestoneId: UUID!
        $status: MilestoneStatus!
      ) {
        planMutation {
          updateMilestoneStatus(milestoneId: $milestoneId, status: $status) {
            id
            appliedTemplates {
              id
              name
              description
            }
            milestones {
              ...MilestoneWithoutActions
            }
            meta {
              milestoneMeta {
                onTime
                overdue
                completed
              }
            }
          }
        }
      }
    `,
    updateToBeAdmitted: gql`
      mutation updateToBeAdmitted($planId: UUID!, $toBeAdmitted: Boolean!) {
        planMutation {
          updateToBeAdmitted(planId: $planId, toBeAdmitted: $toBeAdmitted) {
            id
            toBeAdmitted
          }
        }
      }
    `,
  },
}

injectForGqlFile(PlanGQL)

