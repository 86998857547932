/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'

import { Paper } from 'material-ui'

import { AppInfoGQL } from '../graphql/queries'

import { FixedView } from './FixedView'

import { AppInfoRow } from '../components/app-info/AppInfoRow'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('AppInfo', () => ({
  preformatted: {
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
  },
  details: {
    padding: '10px',
    margin: '15px',
  },
  logoWrapper: {
    textAlign: 'center',
    paddingBottom: '10px',
  },
}))

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('c3.views.AppInfo')

@graphql(AppInfoGQL.queries.appInfo, {
  options: { fetchPolicy: 'network-only' },
})
class AppInfo extends Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.boolean,
      appInfo: PropTypes.shape({
        description: PropTypes.string,
        version: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string,
        credits: PropTypes.string,
        copyright: PropTypes.string,
        companyName: PropTypes.string,
        customerPortalLink: PropTypes.string,
      }),
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    webVersion: 'Loading...',
  }

  componentWillMount() {
    fetch('version.json')
      .then(res => res.json())
      .then(({ webVersion }) => this.setState({ webVersion }))
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const { webVersion } = this.state

    const {
      description,
      version,
      name,
      code,
      credits,
      copyright,
      companyName,
      customerPortalLink,
    } = this.props.data.appInfo || {}

    return (
      <FixedView
        container={
          <div className={classNames(classes.details)}>
            <Paper className={classNames(classes.details)}>
              <div className={classes.logoWrapper}>
                <img src="../components/image/img/Logo-IconTextMedium.png" />
              </div>
              {/*<AppInfoRow label="Customer Portal Link">*/}
              {/*<a href={customerPortalLink}>{customerPortalLink}</a>*/}
              {/*</AppInfoRow>*/}
              <AppInfoRow label="Care Advance UI version">{webVersion}</AppInfoRow>
              <AppInfoRow label="Care Advance Services version">{version}</AppInfoRow>
              <AppInfoRow label="Copyright">
                <div className={classNames(classes.preformatted)}>
                  {copyright}
                </div>
              </AppInfoRow>
              {/*<AppInfoRow label="Credits">*/}
              {/*<div className={classNames(classes.preformatted)}>*/}
              {/*{credits}*/}
              {/*</div>*/}
              {/*</AppInfoRow>*/}
            </Paper>
          </div>
        }
      />
    )
  }
}

export default AppInfo
