/* eslint-disable react/no-set-state */
import React from 'react'
import P from 'prop-types'
import type { ErrorInfo } from './ErrorInfo'

export type ErrorBoundaryType = {
  children: React.Node,
}

type ErrorBoundaryStateType = {
  hasError: boolean,
  error: ?Error,
  errorInfo: ?ErrorInfo,
}

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryType,
  ErrorBoundaryStateType
> {
  state: ErrorBoundaryStateType = {
    hasError: false,
    error: null,
    errorInfo: null,
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO Logger
    this.setState({ hasError: true, error, errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something Went Wrong!</h2>
          <h3>Error</h3>
          <pre>{JSON.stringify(this.state.error)}</pre>
          <h3>Info</h3>
          <pre>
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </pre>
        </div>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: P.oneOfType([P.element, P.arrayOf(P.element)]),
}
