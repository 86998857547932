/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type action_FullPlanAction$ref = any;
export type ActionInput = {|
  text: string,
  pinned?: ?boolean,
|};
export type action_createPlanAction_MutationVariables = {|
  planId: any,
  action: ActionInput,
|};
export type action_createPlanAction_MutationResponse = {|
  +actionMutation: ?{|
    +createPlanAction: ?{|
      +id: string,
      +actions: $ReadOnlyArray<?{|
        +$fragmentRefs: action_FullPlanAction$ref
      |}>,
    |}
  |}
|};
export type action_createPlanAction_Mutation = {|
  variables: action_createPlanAction_MutationVariables,
  response: action_createPlanAction_MutationResponse,
|};
*/


/*
mutation action_createPlanAction_Mutation(
  $planId: UUID!
  $action: ActionInput!
) {
  actionMutation {
    createPlanAction(planId: $planId, action: $action) {
      id
      actions {
        ...action_FullPlanAction
        id
      }
    }
  }
}

fragment action_FullPlanAction on PlanAction {
  id
  text
  status
  pinned
  auditMeta {
    created
    createdBy {
      userName
      firstName
      lastName
      id
    }
    updated
    updatedBy {
      userName
      firstName
      lastName
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "action"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "action",
    "variableName": "action"
  },
  {
    "kind": "Variable",
    "name": "planId",
    "variableName": "planId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "action_createPlanAction_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "createPlanAction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "action_FullPlanAction"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "action_createPlanAction_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "createPlanAction",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pinned",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "updatedBy",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73dba46701693804566ed9266c1a32b0",
    "id": null,
    "metadata": {},
    "name": "action_createPlanAction_Mutation",
    "operationKind": "mutation",
    "text": "mutation action_createPlanAction_Mutation(\n  $planId: UUID!\n  $action: ActionInput!\n) {\n  actionMutation {\n    createPlanAction(planId: $planId, action: $action) {\n      id\n      actions {\n        ...action_FullPlanAction\n        id\n      }\n    }\n  }\n}\n\nfragment action_FullPlanAction on PlanAction {\n  id\n  text\n  status\n  pinned\n  auditMeta {\n    created\n    createdBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n    updated\n    updatedBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2a2515fb91a028329125e42cf3e523c';

module.exports = node;
