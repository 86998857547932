import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from 'material-ui/IconButton'
import Stairs from 'mdi-react/StairsIcon'
import {
  blue800 as PinColor,
  grey500 as UnpinColor,
  grey500 as DeescalatedColor,
  red300 as EscalatedColor,
} from 'material-ui/styles/colors'
import PinIcon from 'mdi-react/PinIcon'

const iconStyle={
  border: '1px solid #b3b3b3',
    borderRadius: '5px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

class BarrierActionButtons extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEscalated: false,
      isPinned: false
    }
  }

  renderEscalateButton = () => {
    const { onEscalateChange } = this.props
    const { isEscalated } = this.state
    const color = isEscalated ? EscalatedColor : DeescalatedColor

    return (
      <IconButton
        color={color}
        style={{
          fill: color,
          padding: 8,
          width: 40,
          height: 40,
          display: 'inline-block',
        }}
        tabIndex={-1}   // disable tab stop
        iconStyle={iconStyle}
        onClick={() => {
          const newVal = !isEscalated
          this.setState({isEscalated : !isEscalated})
          onEscalateChange(newVal)
        }}>
        <Stairs height={28} width={28} />
      </IconButton>
    )
  }

  renderPinButton = () => {
    const { onPinnedChange } = this.props
    const { isPinned } = this.state
    return (
      <IconButton
        style={{
          fill: isPinned ? PinColor : UnpinColor,
          display: 'inline-block',
          padding: 8,
          width: 40,
          height: 40,
        }}
        tabIndex={-1}   // disable tab stop
        iconStyle={iconStyle}
        onClick={() => {
          const newVal = !isPinned
          this.setState({ isPinned: newVal })
          onPinnedChange(newVal)
        }}>
        <PinIcon height={28} width={28} />
      </IconButton>
    )
  }

  render() {
    const { className } = this.props

    return (
     <div className={className} style={{textAlign: 'right'}}>
       { this.renderPinButton() }
       { this.renderEscalateButton() }
     </div>
    )
  }
}

BarrierActionButtons.propTypes = {
  className: PropTypes.string,
  onEscalateChange: PropTypes.func,
  onPinnedChange: PropTypes.func,
}

export default BarrierActionButtons
