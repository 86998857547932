import React from 'react'

import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'
import Watcher from '../Watcher/Watcher'
import styled from 'styled-components'
import {watcherSort} from '../../../../util/sort'

const CurrentWatchers = (props) => {

  const {
    watchers,
    onRemoveWatcher
  } = props

  return (
    <div>
      <CurrentWatchersTitle>Current Watchers ({watchers.length})</CurrentWatchersTitle>
      {
        watchers
          ?.slice()
          ?.sort(watcherSort)
          ?.map(user => (
          <Watcher
            key={user.id}
            firstName={user.knownUser.firstName}
            lastName={user.knownUser.lastName}
            title={user.knownUser.title}
            isOwner={user.isOwner}
            onRemoveWatcher={() => onRemoveWatcher(user.knownUser.id, user.knownUser.firstName, user.knownUser.lastName)}
          />
        ))
      }
    </div>
  )
}

const CurrentWatchersTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  cursor: default;
  color: #4A4A4A;
`

CurrentWatchers.propTypes = {
  watchers: PropTypes.array.isRequired,
  onRemoveWatcher: PropTypes.func.isRequired
}

export default createFragmentContainer(CurrentWatchers, {
  watchers: graphql`
    fragment CurrentWatchers_watchers on BarrierWatcher @relay(plural: true) {
      id
      isOwner
      knownUser {
          id
          firstName
          lastName
          title
      }
    }
  `
})
