/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BarrierSourceTypeTable_barrierSourceTypeConfigs$ref: FragmentReference;
declare export opaque type BarrierSourceTypeTable_barrierSourceTypeConfigs$fragmentType: BarrierSourceTypeTable_barrierSourceTypeConfigs$ref;
export type BarrierSourceTypeTable_barrierSourceTypeConfigs = $ReadOnlyArray<{|
  +id: string,
  +code: string,
  +value: string,
  +ordinal: number,
  +active: boolean,
  +activeDirectorySourceName: boolean,
  +hasConfiguredList: boolean,
  +sourceDetails: ?$ReadOnlyArray<?{|
    +code: string,
    +value: string,
    +active: boolean,
    +ordinal: number,
  |}>,
  +$refType: BarrierSourceTypeTable_barrierSourceTypeConfigs$ref,
|}>;
export type BarrierSourceTypeTable_barrierSourceTypeConfigs$data = BarrierSourceTypeTable_barrierSourceTypeConfigs;
export type BarrierSourceTypeTable_barrierSourceTypeConfigs$key = $ReadOnlyArray<{
  +$data?: BarrierSourceTypeTable_barrierSourceTypeConfigs$data,
  +$fragmentRefs: BarrierSourceTypeTable_barrierSourceTypeConfigs$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordinal",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BarrierSourceTypeTable_barrierSourceTypeConfigs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeDirectorySourceName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasConfiguredList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BarrierSourceDetail",
      "kind": "LinkedField",
      "name": "sourceDetails",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v3/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "BarrierSourceType",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '0de878971efb406e62ac0e3aede7e27e';

module.exports = node;
