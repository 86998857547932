import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, IconMenu, MenuItem } from 'material-ui'

import { ActionStatusIcon } from './ActionStatusIcon'
import classNames from 'classnames'

const handleClick = e => {
  e.stopPropagation()
}

export const ActionStatus = ({ value, onChange, className }) => {
  return (
    <span onClick={handleClick}>
      <IconMenu
        iconButtonElement={
          <IconButton style={{ width: 24, height: 24, padding: 0 }}>
            <ActionStatusIcon value={value} />
          </IconButton>
        }
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onItemClick={(event, child) => onChange(child.props.value)}>
        <MenuItem
          value="Open"
          primaryText="Open"
          leftIcon={<ActionStatusIcon value="Open" />}
        />
        <MenuItem
          value="Completed"
          primaryText="Complete"
          leftIcon={<ActionStatusIcon value="Completed" />}
        />
        <MenuItem
          value="NA"
          primaryText="N/A"
          leftIcon={<ActionStatusIcon value="NA" />}
        />
      </IconMenu>
    </span>
  )
}

ActionStatus.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.object,
}
