/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import OnTimeIcon from 'mdi-react/CheckboxBlankCircleIcon'
import AtRiskIcon from 'mdi-react/AlertCircleIcon'
import HighRiskIcon from 'mdi-react/AlertCircleIcon'

import Popover from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import {
  red200 as ComplianceColor,
  red500 as HighRiskColor,
  yellow500 as AtRiskColor,
  green500 as OnTimeColor,
} from 'material-ui/styles/colors'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('ProgressionIssue', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    compliance: {
      display: 'inline-block',
      padding: '1px 5px 1px 5px',
      borderRadius: '3px',
      marginRight: '5px',
      cursor: 'default',
    },
    complianceIssue: {
      background: ComplianceColor,
    },
    canSet: {
      cursor: 'pointer',
    },
  }
})

export class ProgressionIssue extends Component {
  static propTypes = {
    issue: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = { open: false }
  }

  handleTouchTap = event => {
    // This prevents ghost click.
    event.preventDefault()

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
    })
  }

  handleChange = value => {
    this.setState({
      open: false,
    })
    this.props.onChange(value)
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const { issue, value } = this.props

    const text = issue ? 'No Progression Status' : value
    const canSet = issue || value === 'Not Set'

    return (
      <div
        className={classNames(
          classes.compliance,
          issue ? classes.complianceIssue : null,
          canSet ? classes.canSet : null
        )}
        onClick={this.handleTouchTap}>
        {text}
        {canSet && (
          <Popover
            open={this.state.open}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
            onRequestClose={this.handleRequestClose}>
            <Menu
              onItemClick={(event, child) =>
                this.handleChange(child.props.value)
              }>
              <MenuItem
                value="OnTime"
                primaryText="On Time"
                leftIcon={<OnTimeIcon style={{ fill: OnTimeColor }} />}
              />
              <MenuItem
                value="AtRisk"
                primaryText="At Risk"
                leftIcon={<AtRiskIcon style={{ fill: AtRiskColor }} />}
              />
              <MenuItem
                value="HighRisk"
                primaryText="High Risk"
                leftIcon={<HighRiskIcon style={{ fill: HighRiskColor }} />}
              />
            </Menu>
          </Popover>
        )}
      </div>
    )
  }
}
