/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { selectTimePeriod } from '../ducks/app-selections'
import {getConfig} from "../util/configs"

import FlatButton from 'material-ui/FlatButton'
import Popover from 'material-ui/Popover'
import Menu from 'material-ui/Menu'
import { LoadingIndicator } from '../components/loader'

const day7 = 'Day7'
const month1 = 'Day30'
const month3 = 'Day90'
const month6 = 'Day180'
const year1 = 'Day360'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'

const styleSheet = createStyleSheet('PlanList', () => ({
  kpiContainer: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  rowContainer: {
    height: '41px',
    fontSize: '12px',
  },
  rowLeftRight: {
    display: 'inline-block',
    width: 'calc(50%)',
  },
  rowFixedCenter: {
    display: 'inline-block',
    width: '162px',
  },
  rowFirst: {
    // borderTop: '1px solid lightgrey',
  },
  row: {
    borderBottom: '1px solid lightgrey',
    height: '40 !important',
    marginLeft: '0 !important',
    marginRight: '0 !important',
  },
  rowLast: {
    borderBottom: 'none',
  },
  colFirst: {
    // borderLeft: '1px solid lightgrey',
  },
  col: {
    borderRight: '1px solid lightgrey',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
  colLast: {
    borderRight: 'none',
  },
  colLabel: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    paddingLeft: '1rem !important',
    cursor: 'pointer',
  },
  colLabelWrap: {
    height: '40px',
    background: 'white',
    paddingLeft: '1rem !important',
  },
  column: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
  },
  sparkLine: {
    paddingLeft: '-2px',
    width: '160px',
    height: '38px',
  },
  upArrow: {
    position: 'absolute',
    top: '8px',
    left: '-4px',
    transform: 'rotate(90deg)',
  },
  downArrow: {
    position: 'absolute',
    top: '8px',
    left: '-4px',
    transform: 'rotate(90deg)',
  },
  good: {
    color: 'black',
    // fontWeight: '500',
  },
  bad: {
    color: 'red',
    fontWeight: '500',
  },
  neutral: {
    color: 'black',
    // fontWeight: '500',
  },
  popover: {
    padding: '1rem 2rem 2rem 2rem !important',
    maxWidth: '400px',
  },
  popoverTitle: {},
  popoverContent: {},
  kpiMeasuresColumn: {},
  kpiMeasuresColumn1: {},
  ytdColumn: {},
  selColumn: {},
  kpiMeasuresColumn2: {},
  kpiMeasuresText: {
    textAlign: 'center',
    position: 'relative',
    top: '-22px',
    width: '100%',
    background: 'white',
  },
  ytdAverageColumn: {},
  ytdTargetColumn: {},
  trend1Column: {},
  trend2Column: {},
  trend3Column: {},
  averageColumn: {},
  minColumn: {},
  maxColumn: {},
  volumeColumn: {},
}))

import {
  Sparklines,
  SparklinesLine,
  SparklinesReferenceLine,
} from 'react-sparklines'

import { graphql } from 'react-apollo'
import { KpiTrendGQL } from '../graphql/queries/kpi-trend'
import dataToPoints from '../util/dataToPoints'

function mapStateToProps(state) {
  return {
    selected: state.selectAppState,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectTimePeriod,
      },
      dispatch
    ),
  }
}

@graphql(KpiTrendGQL.queries.reportQuery, { name: 'kpi' })
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class KpiOverview extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      selectTimePeriod: PropTypes.func.isRequired,
    }),
    selected: PropTypes.object.isRequired,
    kpi: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.boolean,
      reportQuery: PropTypes.shape({
        kpiTrends: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            inPatientVolume: PropTypes.number,
            obsPatientVolume: PropTypes.number,
            kpis: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string,
                min: PropTypes.number,
                max: PropTypes.number,
                average: PropTypes.number,
                ytdAverage: PropTypes.number,
                graphData: PropTypes.arrayOf(PropTypes.number),
              })
            ),
          })
        ),
      }),
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  /*eslint-disable react/sort-comp */
  static getTargets() {
    let targets = {}

    targets.InLOS = parseFloat(getConfig(
      'ca.kpi.trends.discharged.acute.inpatient.los.target.double'))

    targets.ObsLOS = parseInt(getConfig(
      'ca.kpi.trends.observation.los.target.integer'))

    targets.ObsPercent = parseFloat(getConfig(
      'ca.kpi.trends.observation.percent.acute.obs.discharges.target.double'))

    targets.WorkingOE = parseFloat(getConfig(
      'ca.kpi.trends.working.oe.ratio.target.double'))

    targets.WorkingCMI = parseFloat(getConfig(
      'ca.kpi.trends.working.cmi.target.double'))

    return targets
  }

  constructor() {
    super()

    this.state = {
      open: false,
      popoverData: {
        label: '',
        description: '',
      },
    }

    // classes and data that are computed
    this.computedClasses = {
      ytdAverageClass: {},
      averageClass: {},
      minClass: {},
      maxClass: {},
      ytdTargetClass: {},
    }
  }


  handleTimePeriodClick = timePeriod => {
    this.props.actions.selectTimePeriod(timePeriod)
  }

  handleLabelClick = (event, data) => {
    // This prevents ghost click.
    event.preventDefault()

    this.setState({
      open: true,
      popoverData: { label: data.label, description: data.description },
      anchorEl: event.currentTarget,
    })
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
    })
  }

  formatPercentage(num) {
    return `${(num * 100).toFixed(1)}%`
  }

  formatNumber(num) {
    return num.toString()
  }

  formatFloat1(num) {
    return num.toFixed(1)
  }

  formatFloat2(num) {
    return num.toFixed(2)
  }

  formatVolume(num) {
    return num === null ? 'N/A' : num.toString()
  }

  makeSparkLine(graphData, target, limit, width, height, margin, min, max) {
    const referenceLineY = dataToPoints({
      data: [target],
      limit,
      width,
      height,
      margin,
      max,
      min,
    })[0].y

    // CA-953 - comment out SparklinesSpots - no one was sure what they meant
    return (
      <Sparklines data={graphData} limit={limit} width={width} height={height}>
        <SparklinesLine />
        {/*<SparklinesSpots />*/}
        <SparklinesReferenceLine type="custom" value={referenceLineY} />
      </Sparklines>
    )
  }

  makeSparkLineCell(classes, data) {
    return (
      <div className={classNames(classes.sparkLine)}>
        {this.makeSparkLine(
          data.graphData,
          this.computedClasses.target,
          data.limit,
          data.width,
          data.height,
          data.margin,
          data.min,
          data.max
        )}
      </div>
    )
  }

  makeColumnHeader1(classes, inPatientCount, obsPatientCount) {
    const {
      selected: { currentTimePeriod },
    } = this.props

    const buttonStyle = { minWidth: '40px' }
    const labelStyle = {
      paddingLeft: '8px',
      paddingRight: '8px',
      textTransform: 'none',
      fontSize: '12px',
    }

    return (
      <div className={classNames(classes.rowContainer)}>
        <div
          className={classNames(
            'row',
            classes.rowFirst,
            classes.row,
            classes.rowLeftRight
          )}>
          <div
            className={classNames(
              'col-xs-6',
              classes.column,
              classes.colFirst,
              classes.col
            )}
          />
          <div
            className={classNames('col-xs-3', classes.column, classes.col)}
          />
          <div
            className={classNames('col-xs-3', classes.column, classes.col)}
          />
        </div>
        <div
          className={classNames(
            'row',
            classes.rowFirst,
            classes.row,
            classes.rowLeftRight
          )}>
          <div
            className={classNames(
              'col-xs-12',
              classes.column,
              classes.col,
              classes.colLast
            )}>
            <FlatButton
              label="7 Days"
              style={buttonStyle}
              labelStyle={labelStyle}
              primary={currentTimePeriod === day7}
              onClick={() => this.handleTimePeriodClick(day7)}
            />
            <FlatButton
              label="30 Days"
              style={buttonStyle}
              labelStyle={labelStyle}
              primary={currentTimePeriod === month1}
              onClick={() => this.handleTimePeriodClick(month1)}
            />
            <FlatButton
              label="90 Days"
              style={buttonStyle}
              labelStyle={labelStyle}
              primary={currentTimePeriod === month3}
              onClick={() => this.handleTimePeriodClick(month3)}
            />
            <FlatButton
              label="180 Days"
              style={buttonStyle}
              labelStyle={labelStyle}
              primary={currentTimePeriod === month6}
              onClick={() => this.handleTimePeriodClick(month6)}
            />
            <FlatButton
              label="360 Days"
              style={buttonStyle}
              labelStyle={labelStyle}
              primary={currentTimePeriod === year1}
              onClick={() => this.handleTimePeriodClick(year1)}
            />
            <span
              style={{
                marginLeft: '20px',
                marginTop: '4px',
              }}>
              Acute D/C:{' '}
              <span style={{ fontWeight: 'bold' }}>{inPatientCount}</span>
            </span>
            <span
              style={{
                marginLeft: '20px',
                marginTop: '4px',
              }}>
              Obs D/C:{' '}
              <span style={{ fontWeight: 'bold' }}>{obsPatientCount}</span>
            </span>
          </div>
        </div>
      </div>
    )
  }

  makeColumnHeader2(classes) {
    return (
      <div className={classNames(classes.rowContainer)}>
        <div className={classNames('row', classes.row, classes.rowLeftRight)}>
          <div
            className={classNames(
              'col-xs-6',
              classes.column,
              classes.colFirst,
              classes.col
            )}>
            <span className={classNames(classes.kpiMeasuresText)}>
              Hospital KPMs
            </span>
          </div>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            <span className={classNames(classes.kpiMeasuresText)}>
              YTD Average
            </span>
          </div>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            <span className={classNames(classes.kpiMeasuresText)}>Target</span>
          </div>
        </div>
        <div className={classNames('row', classes.row, classes.rowLeftRight)}>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            Trend
          </div>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            Average
          </div>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            Min
          </div>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            Max
          </div>
        </div>
      </div>
    )
  }

  makeDataRow(classes, data, last) {
    // Steve S isn't sure he likes the arrows, it was in first div to left
    // of {data.label}
    // const arrow = data.downArrow
    //   ? <DownArrowIcon className={classNames(classes.downArrow)} />
    //   : <UpArrowIcon className={classNames(classes.upArrow)} />

    const cc = this.computedClasses

    return (
      <div className={classNames(classes.rowContainer)}>
        <div
          className={classNames(
            'row',
            classes.row,
            last ? classes.rowLast : null,
            classes.rowLeftRight
          )}>
          <div
            className={classNames(
              'col-xs-6',
              classes.colLabel,
              classes.col,
              classes.colFirst
            )}
            onClick={event => this.handleLabelClick(event, data)}>
            {data.label}
          </div>
          <div
            className={classNames(
              'col-xs-3',
              classes.column,
              classes.col,
              cc.ytdAverageClass
            )}>
            {cc.ytdAverageText}
          </div>
          <div
            className={classNames(
              'col-xs-3',
              classes.column,
              classes.col,
              cc.ytdTargetClass
            )}>
            {cc.targetText}
          </div>
        </div>
        <div
          className={classNames(
            'row',
            classes.row,
            last ? classes.rowLast : null,
            classes.rowLeftRight
          )}>
          <div className={classNames('col-xs-3', classes.column, classes.col)}>
            {this.makeSparkLineCell(classes, data)}
          </div>
          <div
            className={classNames(
              'col-xs-3',
              classes.column,
              classes.col,
              cc.averageClass
            )}>
            {cc.averageText}
          </div>
          <div
            className={classNames(
              'col-xs-3',
              classes.column,
              classes.col,
              cc.minClass
            )}>
            {cc.minText}
          </div>
          <div
            className={classNames(
              'col-xs-3',
              classes.column,
              classes.col,
              cc.maxClass
            )}>
            {cc.maxText}
          </div>
        </div>
      </div>
    )
  }

  makeViewData(data, label, description, downArrow) {
    return {
      label: label,
      description: description,
      downArrow: downArrow,
      limit: data.length,
      width: 160,
      height: 40,
      margin: 0,
      ...data,
    }
  }

  // CA-2544 - removed the formatter calls since they returned strings
  setDataClasses(classes, data, target, downArrow) {
    const cc = this.computedClasses
    if (downArrow) {
      cc.ytdAverageClass =
        data.ytdAverage <= target ? classes.good : classes.bad
      cc.averageClass =
        data.average <= target ? classes.good : classes.bad
      cc.minClass =
        data.min <= target ? classes.good : classes.bad
      cc.maxClass =
        data.max <= target ? classes.good : classes.bad
    } else {
      cc.ytdAverageClass =
        data.ytdAverage >= target ? classes.good : classes.bad
      cc.averageClass =
        data.average >= target ? classes.good : classes.bad
      cc.minClass =
        data.min >= target ? classes.good : classes.bad
      cc.maxClass =
        data.max >= target ? classes.good : classes.bad
    }

    cc.ytdTargetClass = classes.neutral
  }

  formatDataToFloat1(classes, data, target, downArrow) {
    const cc = this.computedClasses
    this.setDataClasses(classes, data, target, downArrow)
    cc.ytdAverageText = this.formatFloat1(data.ytdAverage)
    cc.target = target
    cc.targetText = this.formatFloat1(target)
    cc.averageText = this.formatFloat1(data.average)
    cc.minText = this.formatFloat1(data.min)
    cc.maxText = this.formatFloat1(data.max)
    return data
  }

  formatDataToFloat2(classes, data, target, downArrow) {
    const cc = this.computedClasses
    this.setDataClasses(classes, data, target, downArrow)
    cc.ytdAverageText = this.formatFloat2(data.ytdAverage)
    cc.target = target
    cc.targetText = this.formatFloat2(target)
    cc.averageText = this.formatFloat2(data.average)
    cc.minText = this.formatFloat2(data.min)
    cc.maxText = this.formatFloat2(data.max)
    return data
  }

  formatDataToNumber(classes, data, target, downArrow) {
    const cc = this.computedClasses
    this.setDataClasses(classes, data, target, downArrow)
    cc.ytdAverageText = this.formatFloat1(data.ytdAverage)
    cc.target = target
    cc.targetText = this.formatNumber(target)
    cc.averageText = this.formatFloat1(data.average)
    cc.minText = this.formatFloat1(data.min)
    cc.maxText = this.formatFloat1(data.max)
    return data
  }

  formatDataToPercentage(classes, data, target, downArrow) {
    const cc = this.computedClasses
    const compareTarget = target * 0.01
    this.setDataClasses(classes, data, compareTarget, downArrow)
    cc.ytdAverageText = this.formatPercentage(data.ytdAverage)
    cc.target = target * 0.01
    cc.targetText = `${target}%`
    cc.averageText = this.formatPercentage(data.average)
    cc.minText = this.formatPercentage(data.min)
    cc.maxText = this.formatPercentage(data.max)
    return data
  }

  render() {
    // get graphql data
    const { kpi } = this.props

    // get out if all data not ready yet
    if(kpi.loading) {
      return <LoadingIndicator />
    }

    const classes = this.context.styleManager.render(styleSheet)

    // Probably only seen by developers who don't have
    // the report db tables
    const error = kpi.error ? (
      <h3>Report generation failed at the server</h3>
    ) : null

    if (error) {
      return error
    }

    const targets = KpiOverview.getTargets()

    const {
      selected: { currentTimePeriod },
    } = this.props

    let currentData = kpi.reportQuery.kpiTrends.find(
      interval => interval.name === currentTimePeriod
    )

    return (
      <div
        style={{
          margin: '.5em',
          borderRadius: '3px',
          border: '1px solid lightgrey',
        }}>
        {this.makeColumnHeader1(
          classes,
          currentData.inPatientVolume,
          currentData.obsPatientVolume
        )}
        {this.makeColumnHeader2(classes)}
        {this.makeDataRow(
          classes,
          this.makeViewData(
            this.formatDataToFloat1(
              classes,
              currentData.kpis.find(stat => stat.name === 'InLOS'),
              targets.InLOS,
              true
            ),
            'CA D/C Acute Inpatient LOS',
            'Average midnight length of stay for patients discharged from an acute unit, in an inpatient status',
            true
          )
        )}
        {this.makeDataRow(
          classes,
          this.makeViewData(
            this.formatDataToNumber(
              classes,
              currentData.kpis.find(stat => stat.name === 'ObsLOS'),
              targets.ObsLOS,
              true
            ),
            'Obs LOS',
            'Average length of stay, in hours, patients discharged from an acute unit in an observation status',
            true
          )
        )}
        {this.makeDataRow(
          classes,
          this.makeViewData(
            this.formatDataToPercentage(
              classes,
              currentData.kpis.find(stat => stat.name === 'ObsPercent'),
              targets.ObsPercent,
              true
            ),
            'Obs as a % of Acute D/C + Obs D/C',
            '% of Patients discharged in from acute units, Observation Status, relative to the total of Observation and Inpatient discharges from acute units',
            true
          )
        )}
        {this.makeDataRow(
          classes,
          this.makeViewData(
            this.formatDataToFloat2(
              classes,
              currentData.kpis.find(stat => stat.name === 'WorkingOE'),
              targets.WorkingOE,
              true
            ),
            'Working O/E Ratio',
            "The sum of the observed midnight lengths of stay/the sum of the last target LOS's set for patients discharged from an acute unit in an inpatient status",
            true
          )
        )}
        {this.makeDataRow(
          classes,
          this.makeViewData(
            this.formatDataToFloat2(
              classes,
              currentData.kpis.find(stat => stat.name === 'WorkingCMI'),
              targets.WorkingCMI,
              false
            ),
            'Working CMI',
            'Average of the last working DRG Weight for all patients discharged from acute units in an inpatient status',
            false
          ),
          true
        )}
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleRequestClose}>
          <Menu className={classNames(classes.popover)}>
            <div>
              <h4 className={classNames(classes.popoverTitle)}>
                {this.state.popoverData.label}
              </h4>
              <div className={classNames(classes.popoverContent)}>
                {this.state.popoverData.description}
              </div>
            </div>
          </Menu>
        </Popover>
      </div>
    )
  }
}

export default KpiOverview
