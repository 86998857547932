import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { createStyleSheet } from 'jss-theme-reactor'

import Avatar from 'material-ui/Avatar'
import {
  cyan500 as RouteSelectedColor,
  grey50 as RouteUnselectedColor,
} from 'material-ui/styles/colors'

import { evaluateRouteAuth } from '../route/routing-utils'

const styleSheet = createStyleSheet('NavBar', theme => {
  return {
    root: {
      width: '50px',
      height: '100%',
      background: theme.palette.background.contentFrame,
    },
    listItem: {
      padding: 0,
      marginLeft: '.5em',
      marginBottom: '.5em',
    },
    row: {
      display: 'flex',
      justifyContent: 'center',
      margin: '5px',
    },
  }
})


const mapStateToProps = state => ({
  currentRoute: state.viewsAppState.currentRoute
})

@connect(mapStateToProps)
export class NavBar extends Component {
  static propTypes = {
    routes: PropTypes.array,
    authState: PropTypes.object,
    currentRoute: PropTypes.object,
  }
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true
    // return deepDiffShouldComponentUpdate(
    //   this.props,
    //   nextProps,
    //   this.state,
    //   nextState,
    //   {
    //     debug: true,
    //     key: 'NavBar'
    //   }
    // )
  }

  render() {
    const { routes, authState, currentRoute } = this.props
    const classes = this.context.styleManager.render(styleSheet)
    return (
      <div className={classes.root}>
        {routes
          .filter(route => routeIsVisible(route, authState))
          .map(route => (
            <Link key={route.path} to={route.path}>
              <div className={classes.row} key={route.path}>
                <Avatar
                  backgroundColor={
                    route.viewType === currentRoute.viewType
                      ? RouteSelectedColor
                      : RouteUnselectedColor
                  }>
                  {route.svgIcon && (
                    <route.svgIcon style={{ fill: route.navbarIconColor }} />
                  )}
                </Avatar>
              </div>
            </Link>
          ))}
      </div>
    )
  }
}

function routeIsVisible(route, authState) {
  return route.nav && evaluateRouteAuth(route.auth, authState).length === 0
}
