/* eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import IconButton from 'material-ui/IconButton/IconButton'
import Clear from '../icons/Clear'
import './datepickerbutton.scss'
import {ConfirmDialog} from "../common"

export default class DatePickerButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmOpen: false
    }
  }

  handleClick = () => {
    this.props.onClick()
  }

  handleClearClick = () => {
    if(this.props.handleClearClick && this.props.handleClearClick()){
      return
    }
    this.setState({confirmOpen: true})
  }

  handleCancelConfirmRemove = () => {
    this.setState({confirmOpen: false})
  }

  handleConfirmRemove = () => {
    this.setState({confirmOpen: false})
    this.props.handleConfirmRemove && this.props.handleConfirmRemove()
  }

  formatDate = (val, isTimeAutoPopulated) => {
    const date = moment(val)
    let dateFormat = 'MMM D'
    if(!isTimeAutoPopulated){
      dateFormat += ' HH:mm'
    }
    return (
      `${date.format(dateFormat)}`
    )
  }

   render() {
    const { noChange, timeAutoPopulated, dateTime, label, hasDateTimeIssue, dateUpdatedBy} = this.props
    const emptyFn = () => null
    const cursor = noChange ? { cursor: 'auto' } : { cursor: 'pointer' }
    const currentTime = moment()
    const configLabel = label + ': '
    const confirmLabel = label + ' time'

    let inPast = false
    //If hasDateTimeIssue set boolean as props, then no need to check inPast condition
    if(hasDateTimeIssue === true || hasDateTimeIssue === false){
      inPast = hasDateTimeIssue
    }
    else {
      inPast = dateTime && moment(dateTime).isBefore( timeAutoPopulated ? currentTime.startOf('day') : currentTime )
    }

    return !dateTime ?
    (
      <span style={cursor} onClick={noChange ? emptyFn : this.handleClick}>
        {`No ${label}`}
      </span>
    ) :
    (
      <div>
        <span
          style={cursor}
          className={noChange ? '' : inPast ? 'calendarSelectionPast' :
            'calendarSelection'}
            onClick={noChange ? emptyFn : this.handleClick}>
            {configLabel + this.formatDate(dateTime, timeAutoPopulated)}
            {dateUpdatedBy}
        </span>
        {!noChange && (
          <IconButton
            className="iconButtonStyle"
            onClick={() => this.handleClearClick()}>
            <Clear />
          </IconButton>
        )}
        {this.state.confirmOpen && (
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={`Are you sure you want to remove the ${confirmLabel}?`}
            onCancelClick={this.handleCancelConfirmRemove}
            onConfirmClick={this.handleConfirmRemove}
          />
        )}

      </div>
    )
  }
}

DatePickerButton.propTypes = {
  handleConfirmRemove: PropTypes.func,
  handleClearClick: PropTypes.func,
  onClick: PropTypes.func,
  // when next prop was named 'disabled' it would always be false
  noChange: PropTypes.bool,
  dateTime: PropTypes.string,
  timeAutoPopulated: PropTypes.bool,
  label: PropTypes.string.isRequired,
  hasDateTimeIssue: PropTypes.bool,
  dateUpdatedBy: PropTypes.string
}

DatePickerButton.defaultProps = {
  timeAutoPopulated: true,
}
