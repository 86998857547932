import React from 'react'
import PropTypes from 'prop-types'

import './escalation.scss'

import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton'
import Toggle from 'material-ui/Toggle'
import Slider from 'material-ui/Slider'
import Subheader from 'material-ui/Subheader'

import { filterConstants } from '../../util/filters'
import {getConfig} from "../../util/configs"


const LosFilter = ({
  onChange,
  onLosDecisionTimeDueChange,
  valueSelected,
  defaultSelected,
  admissionType,
  onChangeSort,
}) => {
  switch (admissionType) {
    case filterConstants.admissionType.inpatientValue:
      return (
        <LosFilterAdmissionTypeInpatient
          onChange={onChange}
          valueSelected={valueSelected}
          defaultSelected={defaultSelected}
          onChangeSort={onChangeSort}
        />
      )

    case filterConstants.admissionType.outObsValue:
      return (
        <LosFilterAdmissionTypeOutObs
          onChange={onLosDecisionTimeDueChange}
          valueSelected={valueSelected}
        />
      )

    default:
      return null
  }
}

const LosFilterAdmissionTypeInpatient = ({
  onChange,
  valueSelected,
  defaultSelected,
  onChangeSort,
}) => {
  const MAXOE = 3.0
  const isForecastOE = valueSelected.value === filterConstants.los.forecastMore_1_20
  const forecastOELabel = isForecastOE ? " > " + valueSelected.oeForecastTarget : ""

  const isCurrentOE = valueSelected.value === filterConstants.los.currentMore_1_00
  const currentOELabel = isCurrentOE ? " > " + valueSelected.oeCurrentTarget : ""
  const currentOE = parseFloat(getConfig('ca.oe.current.filter.double'))
  const forecastOE = parseFloat(getConfig('ca.oe.forecast.filter.double'))
  const currentOEDefault = currentOE <= MAXOE ? currentOE : 1.0
  const forecastOEDefault = forecastOE <= MAXOE ? forecastOE : 1.0

  const isForecastExcess = valueSelected.value === filterConstants.los.forecastExcessDayGreater
  const forecastExcessLabel = isForecastExcess ? " > " + valueSelected.days : ""

  const isLosGreater = valueSelected.value === filterConstants.los.losDaysGreater
  const losGreaterLabel = isLosGreater ? " > " + valueSelected.days : ""

  const showSort = isForecastOE || isCurrentOE || isForecastExcess || isLosGreater

  const onForecastOEChange = (event, value) => {
    onChange(filterConstants.los.forecastMore_1_20, value || forecastOEDefault)
  }

  const onCurrentOEChange = (event, value) => {
    onChange(filterConstants.los.currentMore_1_00, value || currentOEDefault)
  }

  const onExcessChange = (event, value) => {
    onChange(filterConstants.los.forecastExcessDayGreater, value || 1)
  }

  const onLosChange = (event, value) => {
    onChange(filterConstants.los.losDaysGreater, value || 1)
  }

  return (
    <div className="filter-container">
        <div key="los1">
          <Subheader className="filter-subheader">LOS Review</Subheader>

          <RadioButtonGroup
            name="filterLos"
            valueSelected={valueSelected.value}
            className="filter-items"
            defaultSelected={defaultSelected}>
            <RadioButton
              value={filterConstants.los.forecastMore_1_20}
              label={`Forecast O/E${forecastOELabel}`}
              onClick={onForecastOEChange}
            />
          </RadioButtonGroup>
            <div hidden={!isForecastOE}>
              <Slider
                disableFocusRipple
                min={.8}
                max={3}
                step={.1}
                value={valueSelected.oeForecastTarget}
                onChange={onForecastOEChange}
              />
              <div>
                <span style={{float: 'left', marginTop: '-48px', fontWeight: 'bold'}}>0.8</span>
                <span style={{float: 'right', marginTop: '-48px', fontWeight: 'bold'}}>3</span>
              </div>
            </div>

          <RadioButtonGroup
            name="filterLos"
            valueSelected={valueSelected.value}
            className="filter-items"
            defaultSelected={defaultSelected}>
          <RadioButton
              value={filterConstants.los.currentMore_1_00}
              label={`Current O/E${currentOELabel}`}
              onClick={onCurrentOEChange}
            />
          </RadioButtonGroup>
            <div hidden={!isCurrentOE}>
               <Slider
                min={.8}
                max={3}
                step={.1}
                value={valueSelected.oeCurrentTarget}
                onChange={onCurrentOEChange}
              />
              <div>
                <span style={{float: 'left', marginTop: '-48px', fontWeight: 'bold'}}>0.8</span>
                <span style={{float: 'right', marginTop: '-48px', fontWeight: 'bold'}}>3</span>
              </div>
            </div>

          <div >
            <div >
              <RadioButtonGroup
                name="filterLos"
                valueSelected={valueSelected.value}
                className="filter-items">
                <RadioButton
                  label={`Forecast Excess Days${forecastExcessLabel}`}
                  value={filterConstants.los.forecastExcessDayGreater}
                  onClick={onExcessChange}
                />
              </RadioButtonGroup>
            </div>
            <div hidden={!isForecastExcess}>
              <Slider
                min={1}
                max={9}
                step={1}
                value={valueSelected.days}
                onChange={onExcessChange}
              />
              <div>
                <span style={{float: 'left', marginTop: '-48px', fontWeight: 'bold'}}>1</span>
                <span style={{float: 'right', marginTop: '-48px', fontWeight: 'bold'}}>9</span>
              </div>
            </div>
          </div>

          <div >
            <div >
              <RadioButtonGroup
                name="filterLos"
                valueSelected={valueSelected.value}
                className="filter-items">
                <RadioButton
                  label={`LOS Days${losGreaterLabel}`}
                  value={filterConstants.los.losDaysGreater}
                  onClick={onLosChange}
                />
              </RadioButtonGroup>
            </div>
            <div hidden={!isLosGreater}>
              <Slider
                min={1}
                max={9}
                step={1}
                value={valueSelected.days}
                onChange={onLosChange}
              />
              <div>
                <span style={{float: 'left', marginTop: '-48px', fontWeight: 'bold'}}>1</span>
                <span style={{float: 'right', marginTop: '-48px', fontWeight: 'bold'}}>9</span>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

const LosFilterAdmissionTypeOutObs = ({ onChange, valueSelected }) => {

  const onDecisionTimeDueChange= (event, value) => {
    onChange(event,valueSelected.decisionTimeDueOnly, value)
  }

  return (
    <div className="filter-container">
      <Subheader className="filter-subheader">LOS Review</Subheader>
        <div style={{ marginLeft: '30px', marginTop: '15px', fontWeight: 'bold' }}>{`LOS > ${valueSelected.decisionTimeDue}h`}</div>
        <Slider
          min={0}
          max={48}
          step={1}
          value={valueSelected.decisionTimeDue}
          onChange={onDecisionTimeDueChange}
        />
        <div>
          <span style={{ float: 'left', marginTop: '-48px', fontWeight: 'bold' }}>0h</span>
          <span style={{ float: 'right', marginTop: '-48px', fontWeight: 'bold' }}>48h</span>
        </div>
      </div>
  )
}

LosFilterAdmissionTypeOutObs.propTypes = {
  onChange: PropTypes.func,
  valueSelected: PropTypes.object,
}

LosFilterAdmissionTypeInpatient.propTypes = {
  onChange: PropTypes.func,
  valueSelected: PropTypes.object,
  defaultSelected: PropTypes.string,
  admissionType: PropTypes.string,
  onChangeSort: PropTypes.func
}

LosFilter.propTypes = {
  onChange: PropTypes.func,
  onChangeSort: PropTypes.func,
  onLosDecisionTimeDueChange: PropTypes.func,
  valueSelected: PropTypes.object,
  defaultSelected: PropTypes.string,
  admissionType: PropTypes.string,
}

export default LosFilter
