import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import './jvion.scss'
import {branch, compose, mapProps, renderComponent} from "recompose"
import {graphql} from "react-apollo"
import {VectorGQL} from "./query"
import {isJvionSetRecommendations} from "../../util/configs"
import sortBy from "lodash.sortby"

class HistoricalVectorsComp extends React.Component  {

  renderRecommendation = (r, index) => {
    return (
      <div key={index} className="paper historical-vector">
        <div className="jvlabel">{r.description}</div>
        <div className="label-smaller">{`Predicted on: ${moment(
          r.predictionDate
        ).format('MMM D')}`}</div>
        <div className="label-smaller">{`Code: ${
          r.acceptedRecommendationId.code
        }`}</div>
        <div className="label-smaller" style={{ display: 'flex' }}>
          <div className="flex-column" style={{ flex: 'auto' }}>
            <div>Condition: {r.acceptedRecommendationId.conditionName}</div>
            <div>Risk Bracket: {r.riskBracket}</div>
          </div>
          <div className="flex-column">
            <div>
              Accepted on: {moment(r.auditMeta.updated).format('MMM D HH:mm')}
            </div>
            <div>{`Accepted by: ${r.auditMeta.updatedBy.lastName}, ${
              r.auditMeta.updatedBy.firstName
            }`}</div>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return sortBy(this.props.acceptedRecommendations, rec =>
      rec.riskBracket[0] === 'H' ? 1 :
        rec.riskBracket[0] === 'M' ? 2 : 3, ).map((r, index) =>
          this.renderRecommendation(r, index)
    )
  }

}

HistoricalVectorsComp.propTypes = {
  acceptedRecommendations: PropTypes.array,
  patientVector: PropTypes.object.required
}

export const renderWhileLoading = (component, propName, dataName) =>
  branch(props => {
    const prop = props[propName]
    return (prop && prop.loading) || (prop && !prop[dataName])
  }, renderComponent(component))

// CA-2502 - we'd love to use Relay to read the AcceptedRecommendations
// but it doesn't like the nested object in the AcceptedRecommendationQueryId
// So to get running for 2.0 release moved query here so we get data when this
// component is displayed
export const HistoricalVectors = compose(
  graphql(VectorGQL.queries.AcceptedRecommendations, {
    name: 'acceptedRecommendationData',
    skip: props =>
      !isJvionSetRecommendations() || !props.patientVector,
    options: props => {
      const { patientId, encounterId } = props.patientVector

      return {
        variables: {
          patientId: patientId,
          encounterId: encounterId,
        },
        fetchPolicy: 'network-only',
      }
    },
  }),

  renderWhileLoading(
    () => null,
    'acceptedRecommendationData',
    'acceptedRecommendations'
  ),

  mapProps(props => newProps(props))
)(HistoricalVectorsComp)

const newProps = props => {
  const {
    acceptedRecommendationData,
  } = props

  const acceptedRecommendations = acceptedRecommendationData
    ? acceptedRecommendationData.acceptedRecommendations
    : []

  return {
    ...props,
    acceptedRecommendations,
  }
}

