/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { updateScrollTop } from '../ducks/views'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateScrollTop,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class FixedView extends Component {
  static propTypes = {
    actions: PropTypes.shape({ updateScrollTop: PropTypes.func }),
    header: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    container: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    footer: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  constructor(props) {
    super(props)

    this.state = {
      fixedHeaderHeight: 0,
      fixedFooterHeight: 0,
    }
  }

  componentWillMount() {
    this.props.actions.updateScrollTop(0)
  }

  componentDidMount() {
    this.adjustFixedHeaderHeight()
  }

  componentDidUpdate() {
    this.adjustFixedHeaderHeight()
  }

  adjustFixedHeaderHeight() {
    const { header, footer } = this.props

    const fixedHeaderHeight =
      header && this.refs.fixedHeader ? this.refs.fixedHeader.clientHeight : 0
    const fixedFooterHeight =
      footer && this.refs.fixedFooter ? this.refs.fixedFooter.clientHeight : 0
    if (
      fixedHeaderHeight !== this.state.fixedHeaderHeight ||
      fixedFooterHeight !== this.state.fixedFooterHeight
    ) {
      this.setState({ fixedHeaderHeight, fixedFooterHeight })
    }
  }

  calcFixedContainerStyles() {
    const { header, footer } = this.props

    const heightOfAppBar = 50
    const heightOfHeader = header ? this.state.fixedHeaderHeight + 1 : 0 //Account for bottom border
    const heightOfFooter = footer ? this.state.fixedFooterHeight + 1 : 0 //Account for bottom border

    return {
      height: `calc(100% - ${heightOfHeader + heightOfFooter}px)`,
      marginTop: `${heightOfHeader}px`,
    }
  }

  makeFixedHeader() {
    const { header } = this.props

    return (
      <div ref="fixedHeader" className="fixed-header">
        {header}
      </div>
    )
  }

  makeFixedContainer() {
    const { container } = this.props

    return (
      <div
        id="fixedContainer"
        ref="fixedContainer"
        className="fixed-container"
        style={this.calcFixedContainerStyles()}>
        {container}
      </div>
    )
  }

  makeFixedFooter() {
    const { footer } = this.props

    return (
      <div ref="fixedFooter" className="fixed-footer">
        {footer}
      </div>
    )
  }

  render() {
    const { header, footer, children } = this.props

    return (
      <div className="fixed-view">
        {header && this.makeFixedHeader()}
        {this.makeFixedContainer()}
        {footer && this.makeFixedFooter()}
        {children}
      </div>
    )
  }
}
