import React from 'react'
import PropTypes from 'prop-types'

import { DRGNumber } from '../../components/DRGNumber'
import { Date2 } from '../../components/date'
import { AuditUser } from '../../components/audit'

import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('InfoDRG', theme => {
  return {
    updatedBy: {
      textAlign: 'right',
    },
  }
})

export const InfoDRG = ({ visit, onSearchDRG = () => {} }, context) => {
  const classes = context.styleManager.render(styleSheet)

  const { drgAttributes, drgUpdatedBy, drgUpdatedWhen } = visit
  return (
    <div
      className="container-fluid"
      style={{ position: 'relative', top: '5px' }}>
      <div className="row">
        <div className="col-xs-12" style={{ marginTop: '-11px' }}>
          <DRGNumber visit={visit} onSearchDRG={onSearchDRG} />
          {drgAttributes && (
            <span>
              {drgAttributes.level} - {drgAttributes.title}{' '}
            </span>
          )}
        </div>
      </div>
      {drgAttributes && (
        <div className="row">
          <div
            className="col-xs-12"
            style={{ marginTop: '-4px', marginBottom: '4px' }}>
            <div className={classes.updatedBy}>
              Updated: <Date2 dateString={drgUpdatedWhen} /> By:{' '}
              <AuditUser user={drgUpdatedBy} emptyText="External System" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

InfoDRG.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

InfoDRG.propTypes = {
  visit: PropTypes.object.isRequired,
  onSearchDRG: PropTypes.func.isRequired,
}
