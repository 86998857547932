/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type barrier_FullBarrier$ref = any;
export type BedRequestStatus = "Anticipated" | "AnticipatedDischarge" | "AnticipatedUnitTransfer" | "Cancelled" | "CheckedIn" | "DischargeOrderWithDelay" | "Discharged" | "IntentToAdmit" | "Pending" | "PendingDischarge" | "PendingUnitTransfer" | "Ready" | "ReadyForDischarge" | "ReadyForUnitTransfer" | "Transferred" | "%future added value";
export type barrier_createBarrierFromTemplate_MutationVariables = {|
  planId: any,
  templateId: any,
  note?: ?string,
  ownerId?: ?any,
  pinned?: ?boolean,
  escalated?: ?boolean,
  barrierSourceTypeCode?: ?string,
  barrierSourceName?: ?string,
  bedRequestStatus?: ?BedRequestStatus,
|};
export type barrier_createBarrierFromTemplate_MutationResponse = {|
  +barrierMutation: ?{|
    +createBarrierFromTemplate: ?{|
      +id: string,
      +barriers: $ReadOnlyArray<?{|
        +$fragmentRefs: barrier_FullBarrier$ref
      |}>,
    |}
  |}
|};
export type barrier_createBarrierFromTemplate_Mutation = {|
  variables: barrier_createBarrierFromTemplate_MutationVariables,
  response: barrier_createBarrierFromTemplate_MutationResponse,
|};
*/


/*
mutation barrier_createBarrierFromTemplate_Mutation(
  $planId: UUID!
  $templateId: UUID!
  $note: String
  $ownerId: UUID
  $pinned: Boolean
  $escalated: Boolean
  $barrierSourceTypeCode: String
  $barrierSourceName: String
  $bedRequestStatus: BedRequestStatus
) {
  barrierMutation {
    createBarrierFromTemplate(planId: $planId, templateId: $templateId, note: $note, ownerId: $ownerId, pinned: $pinned, escalated: $escalated, barrierSourceTypeCode: $barrierSourceTypeCode, barrierSourceName: $barrierSourceName, bedRequestStatus: $bedRequestStatus) {
      id
      barriers {
        ...barrier_FullBarrier
        id
      }
    }
  }
}

fragment barrier_FullBarrier on Barrier {
  id
  name
  description
  status
  pinned
  auditMeta {
    created
    createdBy {
      userName
      firstName
      lastName
      id
    }
    updated
    updatedBy {
      userName
      firstName
      lastName
      id
    }
  }
  category {
    code
    value
    sourceTypes {
      id
      code
      value
      active
      activeDirectorySourceName
      hasConfiguredList
      sourceDetails {
        id
        code
        value
        active
      }
    }
    id
  }
  escalation {
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
        id
      }
    }
    id
  }
  ownerMessageStatus
  ownerMessageStatusDate
  ownerKnownUser {
    id
    emailReceiver
    instantMessageReceiver
  }
  ownerMessageDeliveryDate
  owner
  ownerRole {
    code
    value
    id
  }
  sourceName
  sourceTypeCode
  notes {
    id
    text
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
        id
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
        id
      }
    }
    pinned
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "barrierSourceName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "barrierSourceTypeCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bedRequestStatus"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "escalated"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownerId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pinned"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateId"
},
v9 = [
  {
    "kind": "Variable",
    "name": "barrierSourceName",
    "variableName": "barrierSourceName"
  },
  {
    "kind": "Variable",
    "name": "barrierSourceTypeCode",
    "variableName": "barrierSourceTypeCode"
  },
  {
    "kind": "Variable",
    "name": "bedRequestStatus",
    "variableName": "bedRequestStatus"
  },
  {
    "kind": "Variable",
    "name": "escalated",
    "variableName": "escalated"
  },
  {
    "kind": "Variable",
    "name": "note",
    "variableName": "note"
  },
  {
    "kind": "Variable",
    "name": "ownerId",
    "variableName": "ownerId"
  },
  {
    "kind": "Variable",
    "name": "pinned",
    "variableName": "pinned"
  },
  {
    "kind": "Variable",
    "name": "planId",
    "variableName": "planId"
  },
  {
    "kind": "Variable",
    "name": "templateId",
    "variableName": "templateId"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v10/*: any*/)
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    (v12/*: any*/),
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditUser",
      "kind": "LinkedField",
      "name": "updatedBy",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "barrier_createBarrierFromTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BarrierMutation",
        "kind": "LinkedField",
        "name": "barrierMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "createBarrierFromTemplate",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Barrier",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "barrier_FullBarrier"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "barrier_createBarrierFromTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BarrierMutation",
        "kind": "LinkedField",
        "name": "barrierMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "createBarrierFromTemplate",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Barrier",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BarrierCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BarrierSourceType",
                        "kind": "LinkedField",
                        "name": "sourceTypes",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "activeDirectorySourceName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasConfiguredList",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "BarrierSourceDetail",
                            "kind": "LinkedField",
                            "name": "sourceDetails",
                            "plural": true,
                            "selections": [
                              (v10/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Escalation",
                    "kind": "LinkedField",
                    "name": "escalation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerMessageStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerMessageStatusDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KnownUser",
                    "kind": "LinkedField",
                    "name": "ownerKnownUser",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emailReceiver",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "instantMessageReceiver",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownerMessageDeliveryDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "owner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CodeTablePair",
                    "kind": "LinkedField",
                    "name": "ownerRole",
                    "plural": false,
                    "selections": [
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sourceName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sourceTypeCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BarrierNote",
                    "kind": "LinkedField",
                    "name": "notes",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d68649577c3502d947e068f8002a3bf2",
    "id": null,
    "metadata": {},
    "name": "barrier_createBarrierFromTemplate_Mutation",
    "operationKind": "mutation",
    "text": "mutation barrier_createBarrierFromTemplate_Mutation(\n  $planId: UUID!\n  $templateId: UUID!\n  $note: String\n  $ownerId: UUID\n  $pinned: Boolean\n  $escalated: Boolean\n  $barrierSourceTypeCode: String\n  $barrierSourceName: String\n  $bedRequestStatus: BedRequestStatus\n) {\n  barrierMutation {\n    createBarrierFromTemplate(planId: $planId, templateId: $templateId, note: $note, ownerId: $ownerId, pinned: $pinned, escalated: $escalated, barrierSourceTypeCode: $barrierSourceTypeCode, barrierSourceName: $barrierSourceName, bedRequestStatus: $bedRequestStatus) {\n      id\n      barriers {\n        ...barrier_FullBarrier\n        id\n      }\n    }\n  }\n}\n\nfragment barrier_FullBarrier on Barrier {\n  id\n  name\n  description\n  status\n  pinned\n  auditMeta {\n    created\n    createdBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n    updated\n    updatedBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n  }\n  category {\n    code\n    value\n    sourceTypes {\n      id\n      code\n      value\n      active\n      activeDirectorySourceName\n      hasConfiguredList\n      sourceDetails {\n        id\n        code\n        value\n        active\n      }\n    }\n    id\n  }\n  escalation {\n    auditMeta {\n      created\n      createdBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n    }\n    id\n  }\n  ownerMessageStatus\n  ownerMessageStatusDate\n  ownerKnownUser {\n    id\n    emailReceiver\n    instantMessageReceiver\n  }\n  ownerMessageDeliveryDate\n  owner\n  ownerRole {\n    code\n    value\n    id\n  }\n  sourceName\n  sourceTypeCode\n  notes {\n    id\n    text\n    auditMeta {\n      created\n      createdBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      updated\n      updatedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n    }\n    pinned\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '028e62654bfc648240ed6c4d7a38396c';

module.exports = node;
