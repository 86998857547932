import {
  compose,
  mapProps,
  branch,
  renderComponent,
  shouldUpdate,
} from 'recompose'
import { graphql } from 'react-apollo'
import { VectorGQL } from './query'
import { VectorSection } from './VectorSection'
import { isJvionSetRecommendations } from '../../util/configs'
import {
  handleJvionMutation,
  acceptRecommendation,
} from '../../plugins/jvion/relay/query'
import { environment } from '../../graphql/relay/env'

export const renderWhileLoading = (component, propName, dataName) =>
  branch(props => {
    const prop = props[propName]
    return (prop && prop.loading) || (prop && !prop[dataName])
  }, renderComponent(component))

export const VectorSectionGQL = compose(
  graphql(VectorGQL.queries.DataSet, {
    skip: props => !isJvionSetRecommendations(),
    name: 'jvionDataSet',
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  renderWhileLoading(() => null, 'jvionDataSet', 'jvionDataSet'),
  mapProps(props => newProps(props))
)(VectorSection)

const newProps = props => {
  const {
    jvionDataSet,
  } = props
  const patientVector = props.workspace.visit.vector
  const visitId = props.workspace.visit.id

  const onAcceptRecommendation = (acceptedRecommendationId, status, loadId) => {

    const variables = { acceptedRecommendationId, status, loadId, visitId }
    handleJvionMutation(
      environment,
      acceptRecommendation,
      variables,
      true,
      undefined
    )
  }

  return {
    ...props,
    jvionDataSet,
    patientVector,
    onAcceptRecommendation,
  }
}
