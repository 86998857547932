/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CurrentWatchers_watchers$ref = any;
export type ManageWatchersDialogQueryVariables = {|
  barrierId: string
|};
export type ManageWatchersDialogQueryResponse = {|
  +node: ?{|
    +watchers?: ?$ReadOnlyArray<?{|
      +knownUser: ?{|
        +id: string
      |},
      +$fragmentRefs: CurrentWatchers_watchers$ref,
    |}>
  |}
|};
export type ManageWatchersDialogQuery = {|
  variables: ManageWatchersDialogQueryVariables,
  response: ManageWatchersDialogQueryResponse,
|};
*/


/*
query ManageWatchersDialogQuery(
  $barrierId: ID!
) {
  node(id: $barrierId) {
    __typename
    ... on Barrier {
      watchers {
        ...CurrentWatchers_watchers
        knownUser {
          id
        }
      }
    }
    id
  }
}

fragment CurrentWatchers_watchers on BarrierWatcher {
  id
  isOwner
  knownUser {
    id
    firstName
    lastName
    title
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "barrierId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageWatchersDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierWatcher",
                "kind": "LinkedField",
                "name": "watchers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KnownUser",
                    "kind": "LinkedField",
                    "name": "knownUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CurrentWatchers_watchers"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Barrier",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ManageWatchersDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierWatcher",
                "kind": "LinkedField",
                "name": "watchers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOwner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KnownUser",
                    "kind": "LinkedField",
                    "name": "knownUser",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Barrier",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "91e27dcf6e4e0b2a5d4ed25c09aa060f",
    "id": null,
    "metadata": {},
    "name": "ManageWatchersDialogQuery",
    "operationKind": "query",
    "text": "query ManageWatchersDialogQuery(\n  $barrierId: ID!\n) {\n  node(id: $barrierId) {\n    __typename\n    ... on Barrier {\n      watchers {\n        ...CurrentWatchers_watchers\n        knownUser {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment CurrentWatchers_watchers on BarrierWatcher {\n  id\n  isOwner\n  knownUser {\n    id\n    firstName\n    lastName\n    title\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd62a1a52425596aa887684ae65d05883';

module.exports = node;
