/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { StatefulTextField } from '../common'
import { AuditUser } from '../audit'
import { Date } from '../date'
import { FlatButton } from 'material-ui'
import './barrier.scss'
import classNames from 'classnames'

export class BarrierNote extends Component {
  render() {
    const {
      note,
      onRemoveBarrierNote,
      onUpdateBarrierNote,
    } = this.props

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =  Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0

    return (
      <div className="barrier-note">
        <div className={classNames('text-block')}>
          <StatefulTextField
            text={note.text}
            textFieldProps={{
              fullWidth: true,
              hintText: 'Barrier Note will be deleted',
              // CA-1536 - BarrierNote should only be used by ExpandedBarrier
              // since it has to use multiLine per CA-1536
              // As of CA 1.2 that was the case
              // Using blurOnEnter below to make
              // Enter key Save when updating a BarrierNote
              //
              // textareaStyle is for CA-1556 - humor IE
              multiLine: isSafari,
              textareaStyle: {
                whiteSpace: 'normal',
                overflowX: 'auto',
              },
              style: { fontSize: '14px' }
            }}
            onEnter={value => {
              if (value && value.length > 0) {
                onUpdateBarrierNote(note.id, value)
              } else {
                onRemoveBarrierNote(note.id)
              }
            }}
            maxNumberOfChars={255}
            allowEmptyText
            clearOnTextEntered={false}
            showCharacterCount
            blurOnEnter
          />
        </div>
        <div className={classNames('meta-block')}>
          <AuditUser user={note.auditMeta.updatedBy} />
          &nbsp;
          <Date dateString={note.auditMeta.updated} />
          <FlatButton
            style={{ minWidth: 30 }}
            onClick={() => onRemoveBarrierNote(note.id)}
            icon={<DeleteIcon style={{ width: 20, height: 20 }} />}
          />
        </div>
      </div>
    )
  }
}

BarrierNote.propTypes = {
  note: PropTypes.object,
  onUpdateBarrierNote: PropTypes.func,
  onRemoveBarrierNote: PropTypes.func,
}
