/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type barrier_sendBarrierInfoMessage_MutationVariables = {|
  barrierId: any,
  recipientId: any,
  messageNote?: ?string,
|};
export type barrier_sendBarrierInfoMessage_MutationResponse = {|
  +barrierMutation: ?{|
    +sendBarrierInfoMessage: {|
      +id: string
    |}
  |}
|};
export type barrier_sendBarrierInfoMessage_Mutation = {|
  variables: barrier_sendBarrierInfoMessage_MutationVariables,
  response: barrier_sendBarrierInfoMessage_MutationResponse,
|};
*/


/*
mutation barrier_sendBarrierInfoMessage_Mutation(
  $barrierId: UUID!
  $recipientId: UUID!
  $messageNote: String
) {
  barrierMutation {
    sendBarrierInfoMessage(barrierId: $barrierId, recipientId: $recipientId, messageNote: $messageNote) {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "barrierId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messageNote"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipientId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BarrierMutation",
    "kind": "LinkedField",
    "name": "barrierMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "barrierId",
            "variableName": "barrierId"
          },
          {
            "kind": "Variable",
            "name": "messageNote",
            "variableName": "messageNote"
          },
          {
            "kind": "Variable",
            "name": "recipientId",
            "variableName": "recipientId"
          }
        ],
        "concreteType": "Barrier",
        "kind": "LinkedField",
        "name": "sendBarrierInfoMessage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "barrier_sendBarrierInfoMessage_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "barrier_sendBarrierInfoMessage_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "696f80e33b09c7699692efb25bbc5633",
    "id": null,
    "metadata": {},
    "name": "barrier_sendBarrierInfoMessage_Mutation",
    "operationKind": "mutation",
    "text": "mutation barrier_sendBarrierInfoMessage_Mutation(\n  $barrierId: UUID!\n  $recipientId: UUID!\n  $messageNote: String\n) {\n  barrierMutation {\n    sendBarrierInfoMessage(barrierId: $barrierId, recipientId: $recipientId, messageNote: $messageNote) {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '339d9b804bc855740843704f55b65625';

module.exports = node;
