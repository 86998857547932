import React from 'react'
import PropTypes from 'prop-types'

import {
  grey800 as GenericColor,
  grey900 as ClosedColor,
  grey100 as DisabledColor,
} from 'material-ui/styles/colors'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('AssignedLocation', theme => {
  const { typography, palette, transitions, shadows, c3Classes } = theme

  return {
    // A bunch of these styles are lifted from material ui
    // but their implementation was so much slower (using inline styles)
    locationButton: {
      margin: '2px 3px 1px 0 !important',
      border: `1px solid  ${c3Classes.color.border} !important`,
      padding: '0 16px',
      textTransform: 'uppercase !important',
      fontWeight: 500,
      fontSize: '14px !important',
      boxSizing: 'border-box',
      fontFamily: 'Roboto, sans-serif !important',
      tapHighlightColor: 'rgba(0, 0, 0, 0)',
      cursor: 'pointer',
      textDecoration: 'none',
      height: '60px',
      color: 'rgba(0, 0, 0, 0.87) !important',
      transition: 'background-color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      borderRadius: '2px',
      userSelect: 'none',
      overflow: 'hidden',
      textAlign: 'center',
      backgroundColor: 'rgba(0,0,0,0)',

      '&:focus': {
        outlineColor: `${GenericColor} !important`,
      },
    },
    unitButton: {
      minWidth: '150px !important',
    },
    bedButton: {
      minWidth: '50px !important',
      touchAction: 'none',
    },
    selected: {
      backgroundColor: `${c3Classes.background.assignedBed} !important`,
      tapHighlightColor: `${c3Classes.background.assignedBed} !important`,
      '&:focus': {
        outlineColor: `${c3Classes.background.assignedBed} !important`,
      },
    },
    added: {
      backgroundColor: `${c3Classes.background.assignedBedAdded} !important`,
      tapHighlightColor: `${c3Classes.background.assignedBedAdded} !important`,
      '&:focus': {
        outlineColor: `${c3Classes.background.assignedBedAdded} !important`,
      },
    },
    removed: {
      backgroundColor: `${c3Classes.background.assignedBedRemoved} !important`,
      tapHighlightColor: `${
        c3Classes.background.assignedBedRemoved
      } !important`,
      '&:focus': {
        outlineColor: `${c3Classes.background.assignedBedRemoved} !important`,
      },
    },
    disabled: {
      cursor: 'not-allowed',
      backgroundColor: `${DisabledColor} !important`,
    },
    closed: {
      color: ClosedColor,
      textDecoration: 'line-through',
    },
  }
})

export const AssignedLocation = (
  {
    name,
    type,
    closed = false,
    selected = false,
    added = false,
    removed = false,
    disabled = false,
    onClick = () => {},
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)

  const buttonClasses = classNames(
    classes.locationButton,
    { [classes.added]: added },
    { [classes.removed]: removed },
    { [classes.selected]: selected },
    { [classes.bedButton]: type === 'bed' || type === 'facility' },
    { [classes.unitButton]: type === 'Unit' || type === 'AdmissionSource' },
    { [classes.closed]: closed },
    { [classes.disabled]: disabled }
  )

  return (
    // We're using native HTML buttons here for performance
    <button disabled={disabled} className={buttonClasses} onClick={onClick}>
      {name}
    </button>
  )
}

AssignedLocation.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

AssignedLocation.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  closed: PropTypes.bool,
  selected: PropTypes.bool,
  added: PropTypes.bool,
  removed: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}
