/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EscalationFilterQueryVariables = {||};
export type EscalationFilterQueryResponse = {|
  +viewer: {|
    +beds: {|
      +nodes: $ReadOnlyArray<{|
        +department: {|
          +name: string
        |},
        +visit: ?{|
          +plan: ?{|
            +barriers: $ReadOnlyArray<?{|
              +owner: ?string
            |}>
          |}
        |},
      |}>
    |}
  |}
|};
export type EscalationFilterQuery = {|
  variables: EscalationFilterQueryVariables,
  response: EscalationFilterQueryResponse,
|};
*/


/*
query EscalationFilterQuery {
  viewer {
    beds(first: 10000, type: ESCALATIONS) {
      nodes {
        department {
          name
          id
        }
        visit {
          plan {
            barriers {
              owner
              id
            }
            id
          }
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "ESCALATIONS"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "owner",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EscalationFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "BedConnection",
            "kind": "LinkedField",
            "name": "beds",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bed",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "department",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Visit",
                    "kind": "LinkedField",
                    "name": "visit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Plan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Barrier",
                            "kind": "LinkedField",
                            "name": "barriers",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "beds(first:10000,type:\"ESCALATIONS\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EscalationFilterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "BedConnection",
            "kind": "LinkedField",
            "name": "beds",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bed",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "department",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Visit",
                    "kind": "LinkedField",
                    "name": "visit",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Plan",
                        "kind": "LinkedField",
                        "name": "plan",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Barrier",
                            "kind": "LinkedField",
                            "name": "barriers",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "beds(first:10000,type:\"ESCALATIONS\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2061141af91b83a62d78591f680d9f57",
    "id": null,
    "metadata": {},
    "name": "EscalationFilterQuery",
    "operationKind": "query",
    "text": "query EscalationFilterQuery {\n  viewer {\n    beds(first: 10000, type: ESCALATIONS) {\n      nodes {\n        department {\n          name\n          id\n        }\n        visit {\n          plan {\n            barriers {\n              owner\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '14cffd227ec63acc41e77c5dbf7059b8';

module.exports = node;
