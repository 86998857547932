import React from 'react'
import PropTypes from 'prop-types'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import { grey400 as RoleColor } from 'material-ui/styles/colors'

export const OwnerRole = ({ value, onChange = () => {} }) => {
  return (
    <SelectField
      fullWidth
      labelStyle={{ color: value ? '' : RoleColor }}
      hintText="Owner Role"
      value={value ? value.code : 'Role'}
      onChange={(event, index, value) => onChange(value)}>
      <MenuItem value="Role" primaryText="Role" />
      <MenuItem value="Nurse" primaryText="Nurse" />
      <MenuItem value="Physician" primaryText="Physician" />
    </SelectField>
  )
}

OwnerRole.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

// style={{border:'1px solid red'}}
