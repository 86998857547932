import React, { Component } from 'react'
import {FixedView} from "./FixedView"
import { TransactionErrorDialog } from '../components/error'
import { clearErrorPg } from '../ducks/errorspg'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { QueryRenderer, graphql } from 'react-relay'
import { environment } from '../graphql/relay/env'
import VisibilityBoardEditor from "../containers/visibilityBoard/VisibilityBoardEditor"
import VisibilityBoardAddressEditor from "../containers/visibilityBoardAddress/VisibilityBoardAddressEditor"
import BoardTemplateEditor from "../containers/boardTemplate/BoardTemplateEditor"
import CodeTableEditor from "../containers/codeTable/CodeTableEditor"
import PropTypes from 'prop-types'
import HipaaAuditReport from '../components/reports/HipaaAuditReport'
import { LoadingIndicator } from '../components/loader'
import BarrierSourceTypeEditor from '../containers/codeTable/barrierSourceTypeEditor/BarrierSourceTypeEditor'
import BarrierCategoryEditor from '../containers/codeTable/barrierCategoryEditor/BarrierCategoryEditor'

function mapStateToProps(state) {
  return {
    errors: state.errorStatePg.errorspg
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearErrorPg
      },
      dispatch
    ),
  }
}

const query = graphql`
  query AdminPageQuery($ct1: CodeType!,
   $ct5: CodeType!, $ct6: CodeType!, $ct7: CodeType!, $ct8: CodeType!, $ct9: CodeType!, $barrierSourceDetail: CodeType!) {
    facilities {
      exchangeName
      name
      departments(first: 10000) {
        nodes {
          exchangeName
          name
          beds(first: 10000) {
            nodes {
              exchangeName
              name
            }
          }
        }
      }
    }
    visibilityBoardAddresses {
      ...VisibilityBoardAddressRowList_list
    }
    visibilityBoardsList: visibilityBoards {
      id
      name
    }
    visibilityBoards {
      ...VisibilityBoardRowList_list
    }
    templatesList: boardTemplates {
      id
      name
    }
    boardTemplates {
      ...BoardTemplateRowList_list
    }
    conditions: codeTables(codeTableType: $ct1) {
      ...CodeTableEditor_list
    }
    barrierCategories: barrierCategories {
      ...BarrierCategoryEditor_list
    }
    careTeamMemberRoles: codeTables(codeTableType: $ct5) {
      ...CodeTableEditor_list
    }
    planCategories: codeTables(codeTableType: $ct6) {
      ...CodeTableEditor_list
    }
    workingDischargeDispositions: codeTables(codeTableType: $ct7) {
      ...CodeTableEditor_list
    }
    barrierNeedsReassignmentReasons: codeTables(codeTableType: $ct8) {
      ...CodeTableEditor_list
    }
    deliveryMethods: codeTables(codeTableType: $ct9) {
      ...CodeTableEditor_list
    }
    barrierSourceTypes: barrierSourceTypes {
      ...BarrierSourceTypeEditor_barrierSourceTypeConfigs
    }
    barrierSourceTypesForBarCat: barrierSourceTypes {
      ...BarrierCategoryEditor_barrierSourceTypeConfigs
    },
    barrierSourceDetailsForBarSrcType: codeTables(codeTableType: $barrierSourceDetail) {
      ...BarrierSourceTypeEditor_barrierSourceDetailConfigs
    }
    barrierSourceDetails: codeTables(codeTableType: $barrierSourceDetail) {
      ...CodeTableEditor_list
    }
  }
`
class Admin extends Component {
  static propTypes = {
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        code: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
      })
    ),
    actions: PropTypes.shape({
      clearErrorPg: PropTypes.func
    }),
  }
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={query}
        variables={
          {
            ct1: "Condition",
            ct5: "CareTeamMemberRole",
            ct6: "PlanCategory",
            ct7: "WorkingDischargeDisposition",
            ct8: "BarrierNeedsReassignmentReason",
            ct9: "DeliveryMethod",
            barrierSourceDetail: "BarrierSourceDetail"
          }
        }
        render={({error, props}) => {
          if (error) {
            return <div>{error.message}</div>
          } else if (props) {
            return (
              <FixedView
                container={
                  <div>
                    <HipaaAuditReport />
                    <VisibilityBoardAddressEditor
                      list={props.visibilityBoardAddresses}
                      listBoards={props.visibilityBoardsList}
                      type="Visibility Board Address"
                      title="Visibility Board Address"/>
                    <VisibilityBoardEditor
                      list={props.visibilityBoards}
                      listTemplates={props.templatesList}
                      listFacilities={props.facilities}
                      type="Visibility Board"
                      title="Visibility Board"/>
                    <BoardTemplateEditor list={props.boardTemplates} type="Board Template" title="Board Template"/>
                    <CodeTableEditor list={props.deliveryMethods} type="DeliveryMethod" title="Message Delivery Methods" addDisabled="Y"/>
                    <CodeTableEditor list={props.conditions} type="Condition" title="Condition"/>
                    <BarrierCategoryEditor list={props.barrierCategories} barrierSourceTypeConfigs={props.barrierSourceTypesForBarCat} />
                    <BarrierSourceTypeEditor barrierSourceTypeConfigs={props.barrierSourceTypes} barrierSourceDetailConfigs={props.barrierSourceDetailsForBarSrcType} />
                    <CodeTableEditor list={props.barrierSourceDetails} type="BarrierSourceDetail" title="Barrier Source Details"/>
                    <CodeTableEditor list={props.careTeamMemberRoles} type="CareTeamMemberRole" title="Care Team Member Role"/>
                    <CodeTableEditor list={props.planCategories} type="PlanCategory" title="Plan Category"/>
                    <CodeTableEditor list={props.workingDischargeDispositions} type="WorkingDischargeDisposition" title="Discharge Arrangements"/>
                    <CodeTableEditor list={props.barrierNeedsReassignmentReasons} type="BarrierNeedsReassignmentReason" title="Barrier Reassignment Reasons"/>
                    <TransactionErrorDialog
                      errors={this.props.errors}
                      onRequestClose={this.props.actions.clearErrorPg} />
                  </div>
                }
              />
            )
          }
          return <LoadingIndicator/>
        }}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
