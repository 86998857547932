import { commitMutation } from 'react-relay'
import {handleErrorArray, handleRelayOnError, hasErrors} from '../errorHandler'
import {fixVariableIds} from '../temp'

export const handleMutation = (
  environment,
  mutation,
  variables,
  callback,
  renderCallback,
  errCallback,
  updater,
  optimisticResponse
) => {
  variables = fixVariableIds(variables)
  commitMutation(environment, {
    mutation: mutation,
    variables: variables,
    updater: updater,
    optimisticResponse: optimisticResponse,
    onCompleted: (response, errors) => {
      if (hasErrors(errors)) {
        handleErrorArray(errCallback, errors)
      } else {
        if (callback) {
          callback(response)
        }
        if (renderCallback) {
          renderCallback()
        }
      }
    },
    onError: error => {
      handleRelayOnError(errCallback, error)
    },
  })
}
