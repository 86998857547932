import { graphql } from 'react-relay'

export const createFavoriteBarrierFromTemplate = graphql`
  mutation user_createFavoriteBarrierFromTemplate_Mutation($templateId: UUID!) {
    userSettingMutation {
      createFavoriteBarrierFromTemplate(templateId: $templateId) {
        id
        name
        description
        category {
          code
          value
        }
        templateId
        auditMeta {
          updated
          updatedBy {
            userName
            firstName
            lastName
          }
        }
      }
    }
  }
`
