import React, { useState } from 'react'
import Dialog from 'material-ui/Dialog'
import CloseIcon from 'mdi-react/CloseIcon'
import { IconButton } from 'material-ui'
import { StatefulTextField } from '../../../common'
import PropTypes from 'prop-types'

import './SendBarrierInfoDialog.scss'
import BarrierRecipient from '../BarrierRecipient/BarrierRecipient'
import SendBarrierButton from '../SendBarrierButton/SendBarrierButton'
import BarrierCommHistorySection from '../BarrierCommHistorySection/BarrierCommHistorySection'

const SendBarrierInfoDialog = ({ open, onRequestClose, barrierCommunicationHistory, onSearchKnownPersonnel, onSendBarrierInfo }) => {

  const [barrierRecipient, setBarrierRecipient] = useState('')
  const [barrierRecipientId, setBarrierRecipientId] = useState(null)
  const [message, setMessage] = useState(null)

  const handleSelectRecipient = (value, knownUser) => {
    setBarrierRecipient(value)
    setBarrierRecipientId(knownUser?.id)
  }

  return (
    <Dialog
      open={open}
      modal
      title={'Send Barrier Information'}
      titleStyle={{ color: '#4A4A4A', fontSize: '16px', paddingBottom: '12px', fontWeight: 'bold' }}
      bodyStyle={{ borderTop: 'none' }}
      contentStyle={{ maxWidth: '550px' }}
      onRequestClose={onRequestClose}
      className="SendBarrierInfoDialog"
      autoScrollBodyContent>
      <div className="CloseIcon">
        <IconButton onClick={onRequestClose}>
          <CloseIcon/>
        </IconButton>
      </div>
      <div className={'Contents'}>
        <BarrierRecipient
          onSearchKnownPersonnel={onSearchKnownPersonnel}
          onSelectBarrierRecipient={(value, knownUser) => { handleSelectRecipient(value, knownUser) }}
          barrierRecipient={barrierRecipient} />
        <StatefulTextField
          textFieldProps={{
            style: { width: '75%', fontSize: '14px' },
            fullWidth: true,
            hintText: '(Optional) Enter a message here',
            multiLine: true
          }}
          text={message}
          onChange={(event, newVal) => setMessage(newVal)}
          clearOnTextEntered={false}
          showCharacterCount
          maxNumberOfChars={255}
        />
        <SendBarrierButton
          disabled={!barrierRecipient || barrierRecipient === ''}
          onSendBarrier={(event) => { onSendBarrierInfo(event, barrierRecipientId, message) }} />
      </div>
      <BarrierCommHistorySection history={barrierCommunicationHistory}/>

    </Dialog>
  )
}

SendBarrierInfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSearchKnownPersonnel: PropTypes.func.isRequired,
  onSendBarrierInfo: PropTypes.func.isRequired,
  barrierCommunicationHistory: PropTypes.array.isRequired
}

export default SendBarrierInfoDialog
