/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose} from 'recompose'
import '../views.scss'
import {
  setSelectedBarrierSection,
  setFilter,
} from '../../ducks/escalation-filters'
import { updateNavigationWorkspaces } from '../../ducks/views'
import { showDrawer } from '../../ducks/views'

import { updateNavigationWorkspacesQuery } from '../../ducks/views'
import {
  getNumToLoad,
  getPaginationsFromIndex,
  getScrollToIndex
} from '../../util/utils'
import { LoadingIndicator } from '../../components/loader'



import {QueryRenderer, graphql, fetchQuery} from 'react-relay'
import { default as EscalationListPaginationContainer } from './EscalationListPaginationContainer'
import { environment } from '../../graphql/relay/env'
import {deepDiffShouldComponentUpdate} from "../../util/should-component-update"
import {TransactionErrorDialog} from '../../components/error'
import {clearErrorPg} from '../../ducks/errorspg'

require('es6-promise').polyfill()
require('isomorphic-fetch')

class EscalationList extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      updateNavigationWorkspacesQuery: PropTypes.func,
    }),
    escalationFilterQuery: PropTypes.string,
    escalationNameBedQuery: PropTypes.string,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
    errors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        code: PropTypes.number.isRequired,
        message: PropTypes.string.isRequired,
      })
    ),
  }


  constructor(props) {

    super(props)
    this.state = {
      type: 'ESCALATIONS',
      subtype: `patients_total`
    }
  }

  componentDidMount() {
    this.paginationLoads = 1
    this.updateNavQuery()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return deepDiffShouldComponentUpdate(
      this.props,
      nextProps,
      this.state,
      nextState,
    )
  }

  componentDidUpdate() {
    this.updateNavQuery()
  }

  // CA-2876 - this was never called from componentDidUpdate after component mounted for Router
  // so now do it on componentDidMount also.
  updateNavQuery = () => {
    const type = this.props.escalationNameBedQuery ? 'VISIT' : 'ESCALATIONS'
    this.props.actions.updateNavigationWorkspacesQuery({
      type: type,
      query: this.props.escalationNameBedQuery || this.props.escalationFilterQuery,
    })
  }

  incrementPaginationLoads = () => {
    this.paginationLoads++
  }
  clearPaginationLoads = () => this.paginationLoads = 1

  // CA-2330 same approach as PlanList and ComplianceList
  refreshEscalation = () => {
    const index = getScrollToIndex(this.props.history)
    const numToLoad = getNumToLoad(index, this.paginationLoads)
    this.paginationLoads = getPaginationsFromIndex(index, this.paginationLoads)

    fetchQuery(environment, query, {
      first: numToLoad,
      after: null,
      getSettingsRoles: true,
      skipOwnerKnownUser: true,
      type: this.props.escalationNameBedQuery ? 'VISIT' : 'ESCALATIONS',
      query: this.props.escalationNameBedQuery || this.props.escalationFilterQuery
    })
  }

  render() {
    const type = this.props.escalationNameBedQuery ? 'VISIT' : 'ESCALATIONS'
    const index = getScrollToIndex(this.props.history)
    const first = getNumToLoad(index, this.paginationLoads)
    this.paginationLoads = getPaginationsFromIndex(index, this.paginationLoads)

    return (
      <React.Fragment>
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{
            first: first,
            after: null,
            getSettingsRoles: true,
            skipOwnerKnownUser: false,
            type: type,
            query: this.props.escalationNameBedQuery || this.props.escalationFilterQuery
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>
            }
            if (!props) {
              return (
                <LoadingIndicator/>
              )
            }
            return (
              <EscalationListPaginationContainer
                incrementPaginationLoads={this.incrementPaginationLoads}
                clearPaginationLoads={this.clearPaginationLoads}
                refreshEscalation={this.refreshEscalation}
                search={props}
                handleFilterChange={this.handleFilterChange}
                query={this.props.escalationNameBedQuery || this.props.escalationFilterQuery}
                {...this.props}
              />
            )
          }}
        />
        <TransactionErrorDialog
          errors={this.props.errors}
          onRequestClose={this.props.actions.clearErrorPg}
        />
      </React.Fragment>
    )
  }
}


function mapStateToProps(state) {
  return {
    selectedBarrierSection: state.escalationView.selectedBarrierSection,
    filter: state.escalationView.filter,
    escalationFilterQuery: state.escalationView.escalationFilterQuery,
    escalationNameBedQuery: state.escalationView.escalationNameBedQuery,
    errors: state.errorStatePg.errorspg,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setSelectedBarrierSection,
        setFilter,
        showDrawer,
        updateNavigationWorkspaces,
        updateNavigationWorkspacesQuery,
        clearErrorPg,
      },
      dispatch
    ),
  }
}

const query = graphql`
  query EscalationListQuery(
    $first: Int
    $after: String
    $getSettingsRoles: Boolean!
    $skipOwnerKnownUser: Boolean!
    $type: SearchType
    $query: String
  ) {
    ...EscalationListPaginationContainer_search
      @arguments(first: $first, after: $after, getSettingsRoles: $getSettingsRoles, skipOwnerKnownUser: $skipOwnerKnownUser, type: $type, query: $query)
  }
`

export const EscalationListView = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(EscalationList)
