/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CodeType = "BarrierCategory" | "BarrierEscalationRole" | "BarrierNeedsReassignmentReason" | "BarrierSourceDetail" | "BundledPaymentType" | "CareTeamMemberRole" | "Condition" | "DeliveryMethod" | "PlanCategory" | "WorkingDischargeDisposition" | "%future added value";
export type CodeTableInput = {|
  code?: ?string,
  description?: ?string,
  sortOrder?: ?number,
  active?: ?boolean,
|};
export type codeTable_modifyCodeTableEntry_MutationVariables = {|
  codeTableType: CodeType,
  code: string,
  codeTableInput: CodeTableInput,
|};
export type codeTable_modifyCodeTableEntry_MutationResponse = {|
  +codeTableMutation: ?{|
    +modifyCodeTableEntry: ?{|
      +id: string,
      +code: string,
      +value: string,
      +ordinal: number,
      +active: boolean,
    |}
  |}
|};
export type codeTable_modifyCodeTableEntry_Mutation = {|
  variables: codeTable_modifyCodeTableEntry_MutationVariables,
  response: codeTable_modifyCodeTableEntry_MutationResponse,
|};
*/


/*
mutation codeTable_modifyCodeTableEntry_Mutation(
  $codeTableType: CodeType!
  $code: String!
  $codeTableInput: CodeTableInput!
) {
  codeTableMutation {
    modifyCodeTableEntry(codeTableType: $codeTableType, code: $code, codeTableInput: $codeTableInput) {
      id
      code
      value
      ordinal
      active
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "code"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "codeTableInput"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "codeTableType"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CodeTableMutation",
    "kind": "LinkedField",
    "name": "codeTableMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "code",
            "variableName": "code"
          },
          {
            "kind": "Variable",
            "name": "codeTableInput",
            "variableName": "codeTableInput"
          },
          {
            "kind": "Variable",
            "name": "codeTableType",
            "variableName": "codeTableType"
          }
        ],
        "concreteType": "CodeTable",
        "kind": "LinkedField",
        "name": "modifyCodeTableEntry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ordinal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "codeTable_modifyCodeTableEntry_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "codeTable_modifyCodeTableEntry_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d7b0c14c81b545d68b887cab081f26de",
    "id": null,
    "metadata": {},
    "name": "codeTable_modifyCodeTableEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation codeTable_modifyCodeTableEntry_Mutation(\n  $codeTableType: CodeType!\n  $code: String!\n  $codeTableInput: CodeTableInput!\n) {\n  codeTableMutation {\n    modifyCodeTableEntry(codeTableType: $codeTableType, code: $code, codeTableInput: $codeTableInput) {\n      id\n      code\n      value\n      ordinal\n      active\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '74e4821160212b979e10d0cc5d942781';

module.exports = node;
