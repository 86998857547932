import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import NoteIcon from 'mdi-react/NoteIcon'
import BarrierIcon from 'mdi-react/GateIcon'
import { debounce } from 'lodash'

import { Pin } from '../plan'
import { EscalationStatus, BarrierStatus } from '.'
import './barrier.scss'

import classNames from 'classnames'
import {auditUpdatedCompareDescending} from "../../util/sort"
import BarrierMessageStatusIcon from '../barrier/BarrierMessageStatusIcon/BarrierMessageStatusIcon'
import {APP_DELIVERY, EMAIL_DELIVERY} from './BarrierConstants'
import { DischargeDelayImage } from '../image/CodeImage'
export const CollapsedBarrier = (
  {
    visit,
    barrier,
    planId,
    showBarrierIcon,
    onBarrierClick,
    onEscalateBarrier,
    onDeescalateBarrier,
    onUpdatePinned,
    onUpdateBarrierStatus,
    rerender
  }
) => {
  const [escalationValue, setEscalationValue] = useState(barrier.escalation ? 'Escalated' : 'Deescalated')
  const  DEBOUNCE_INTERVAL = 500

  // [debounce the click function]
  // Note that escalationStatus is copied value of escalationValue (from state), b/c of it is a callback function that
  //  it may run into racing issue if the value inside of callback is trying to compare with the outside of value
  // Also note that after examining the codes, it appears that the escalate mutation call is not using a callback to update
  //  the barrier status but rather depends on triggering the escalation mutation call and immediately retrieving the barriers
  //  to update the barriers. And I think this is the main reason for CA-4408, server error can occurs when mutation calls
  //  collided. The current solution is relayed the each call (using debounce) may work but it is not the most efficient ways to handle.
  //  The better way to handle is using callback on the mutation call to trigger UI update render but this requires more changes in the core relay functions.

  const debounceEscalationStatusClick = useCallback(debounce((newValue, escalationStatus) => {
    if (escalationStatus !== newValue) {
      newValue === 'Escalated'
        ? onEscalateBarrier(barrier.id, rerender)
        : onDeescalateBarrier(barrier.id, rerender)

      setEscalationValue(newValue)
    }
  }, DEBOUNCE_INTERVAL, {
    leading: true,
    trailing: false,
    maxWait: DEBOUNCE_INTERVAL
  }), []);

  const ownerMessageDeliveryMethod = (barrier => {
    if(barrier.ownerKnownUser) {
      if(barrier.ownerKnownUser.instantMessageReceiver) {
        return APP_DELIVERY
      }
      if(barrier.ownerKnownUser.emailReceiver) {
        return EMAIL_DELIVERY
      }
    }
    return null
  })(barrier)

  const renderIcons = () => (
    <div className={classNames('col-xs-3', 'col-md-2', 'col-lg-2', 'icons')}>
      {showBarrierIcon && <BarrierIcon className="barrier-icon" />}
      <Pin item={barrier} onClick={onUpdatePinned} rerender={rerender} showButtonBorder/>
      <BarrierStatus
        value={barrier.status}
        onChange={newValue =>
                    onUpdateBarrierStatus(barrier.id, newValue, visit.bedRequestStatus)
        }
      />
      <EscalationStatus
        value={escalationValue}
        onChange={(newVal) => { debounceEscalationStatusClick(newVal, escalationValue) }}
        itemStatus={barrier.status}
      />
      <span style={{
        marginRight: '4px',
        visibility: barrier.status === 'Open' && ownerMessageDeliveryMethod === APP_DELIVERY ? 'visible' : 'hidden'
      }}>
        <BarrierMessageStatusIcon
          messageStatus={barrier.ownerMessageStatus}
        />
      </span>
      {barrier.dischargeDelay &&  <DischargeDelayImage type="Discharge" code="Delay"/>}
      {barrier.notes.length > 0 &&
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px'
          }}>
          <NoteIcon className="note-icon" />
          <span style={{verticalAlign: 'top'}}>{barrier.notes.length>1?barrier.notes.length:''}</span>
        </span>
      }
    </div>
  )

  const renderName = () => (
    <div
      className={classNames(
        'col-xs-2',
        'col-md-2',
        'col-lg-2',
        'barrier-name'
      )}>
      {barrier.name}
    </div>
  )

  const renderNotes = () => {
    const sortedNotes = barrier.notes
      .slice(0)
      .sort(auditUpdatedCompareDescending)
    let notes = ''
    sortedNotes.map(note => notes += (notes ? ' | ' : '') + note.text )

    return (
      <div className={classNames('col-xs-7', 'col-md-8', 'col-lg-8', 'barrier-name')} >
        {notes || "No Notes"}
      </div>

    )
  }

  return (
    <div
      className={classNames('row', 'collapsed-barrier-container')}
      onClick={e => onBarrierClick(e, barrier, planId)}>
      {renderIcons()}
      {renderName()}
      {renderNotes()}
    </div>
  )
}

CollapsedBarrier.propTypes = {
  rerender: PropTypes.func,
  barrier: PropTypes.object,
  visit: PropTypes.object,
  planId: PropTypes.string,
  onBarrierClick: PropTypes.func.isRequired,
  onUpdatePinned: PropTypes.func.isRequired,
  onUpdateBarrierStatus: PropTypes.func.isRequired,
  onEscalateBarrier: PropTypes.func.isRequired,
  onDeescalateBarrier: PropTypes.func.isRequired,
  showBarrierIcon: PropTypes.bool
}
