export const baseURL =
  (typeof window !== 'undefined' && window.baseURL) ||
  (typeof global !== 'undefined' && global.baseURL) ||
  '/api'

export const baseAuthURL =
  (typeof window !== 'undefined' && window.baseAuthURL) ||
  (typeof global !== 'undefined' && global.baseAuthURL) ||
  baseURL

export const baseGraphQLURL =
  (typeof window !== 'undefined' && window.baseGraphQLURL) ||
  (typeof global !== 'undefined' && global.baseGraphQLURL) ||
  baseURL
