import React from 'react'
import PersonName, { RECIPIENT, SENDER } from '../../../sendBarrierInfo/BarrierCommHistorySection/PersonName/PersonName'
import { MESSAGE_SENT_MSG_SUB_TYPE, BARRIER_OWNER_ASSIGNED_SUB_TYPE } from '../../../BarrierConstants'
import PropTypes from 'prop-types'

import './NameDisplay.scss'

const NameDisplay = ({ historyData }) => {
  let name
  if (historyData.messageSubType === MESSAGE_SENT_MSG_SUB_TYPE || historyData.messageSubType === BARRIER_OWNER_ASSIGNED_SUB_TYPE) {
    name = (
      <React.Fragment>
        <PersonName nameType={RECIPIENT} displayName={`${historyData.recipientLastName}, ${historyData.recipientFirstName}`} />
        <PersonName nameType={SENDER} displayName={`${historyData.senderLastName}, ${historyData.senderFirstName}`} />
      </React.Fragment>
    )
  } else {
    name = (
      <span>{`${historyData.senderLastName}, ${historyData.senderFirstName}`}</span>
    )
  }

  return (
    <div className={'NameDisplay'}>
      {name}
    </div>
  )
}

NameDisplay.propTypes = {
  historyData: PropTypes.shape({
    recipientLastName: PropTypes.string.isRequired,
    recipientFirstName: PropTypes.string.isRequired,
    senderLastName: PropTypes.string.isRequired,
    senderFirstName: PropTypes.string.isRequired,
    messageSubType: PropTypes.string.isRequired
  }).isRequired
}


export default NameDisplay
