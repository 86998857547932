/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { humanizeStatus } from './VisitStatus'
import { grey900 as HintColor } from 'material-ui/styles/colors'

const styleSheet = createStyleSheet('AdmissionStatus', theme => {
  const { typography, palette, transitions, shadows, c3Classes } = theme

  return {
    text: {
      whiteSpace: 'nowrap',
      width: '160px',
    },
    pending: {
      borderBottom: '4px solid #C2EECB !important',
    },
    anticipated: {
      borderBottom: '4px solid #92A8CF !important',
    },
    ready: {
      borderBottom: '4px solid #83CF83 !important',
    },
    intent: {
      borderBottom: '4px solid #0693CD !important',
    },
    attr: c3Classes.attr,
  }
})

export class AdmissionStatus extends Component {
  static propTypes = {
    relayEnv: PropTypes.object,
    bed: PropTypes.object.isRequired,
    visit: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    onToBeAdmittedUpdate: PropTypes.func.isRequired,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
  }

  handleAdmissionStatusChange = (planId, value) => {
    this.props.onToBeAdmittedUpdate(planId, value)
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const { visit, plan} = this.props

    const status =
      (visit.bedRequests &&
        visit.bedRequests.length > 0 &&
        visit.bedRequests[0].bedRequestStatus) || ''
    const isEditable = !status || status === 'Cancelled' // bedRequestStatus will be null or Cancelled if not set for AdmissionSource bed

    const toBeAdmitted = plan.toBeAdmitted

    const bedRequestStatusBackgroundClass = status
      ? status.startsWith('Pending')
        ? classes.pending
        : status.startsWith('Anticipated')
        ? classes.anticipated
        : status.startsWith('Ready')
        ? classes.ready
        : status.startsWith('Intent')
        ? classes.intent
        : '' : ''

    const items = [
      <MenuItem key={false} value={false} primaryText="No Admission" />,
      <MenuItem key={true} value={true} value primaryText="To Be Admitted" />,
    ]
    let result
    if (!isEditable) {
      result = (
        <div className={classNames(classes.attr, classes.text)}>
          <div className={classNames(bedRequestStatusBackgroundClass)}>
            {humanizeStatus(status)}
          </div>
        </div>
      )
    } else {
      result = (
        <SelectField
          value={toBeAdmitted}
          onChange={(event, index, value) =>
            value !== toBeAdmitted &&
            this.handleAdmissionStatusChange(plan.id, value)
          }
          hintText="Not Set"
          hintStyle={{ color: HintColor }}
          style={{
            width: 170,
            height: 48,
            top: -10,
            padding: '0 !important',
          }}>
          {items}
        </SelectField>
      )
    }

    return result
  }
}
