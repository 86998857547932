/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type note_FullBarrier$ref = any;
export type note_updateBarrierNote_MutationVariables = {|
  noteId: any,
  text: string,
|};
export type note_updateBarrierNote_MutationResponse = {|
  +noteMutation: ?{|
    +updateNote: ?{|
      +id: string,
      +barriers: $ReadOnlyArray<?{|
        +$fragmentRefs: note_FullBarrier$ref
      |}>,
    |}
  |}
|};
export type note_updateBarrierNote_Mutation = {|
  variables: note_updateBarrierNote_MutationVariables,
  response: note_updateBarrierNote_MutationResponse,
|};
*/


/*
mutation note_updateBarrierNote_Mutation(
  $noteId: UUID!
  $text: String!
) {
  noteMutation {
    updateNote(noteId: $noteId, text: $text) {
      id
      barriers {
        ...note_FullBarrier
        id
      }
    }
  }
}

fragment note_FullBarrier on Barrier {
  id
  name
  description
  status
  pinned
  auditMeta {
    created
    createdBy {
      userName
      firstName
      lastName
      id
    }
    updated
    updatedBy {
      userName
      firstName
      lastName
      id
    }
  }
  category {
    code
    value
    id
  }
  escalation {
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
        id
      }
    }
    id
  }
  owner
  ownerRole {
    code
    value
    id
  }
  notes {
    id
    text
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
        id
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
        id
      }
    }
    pinned
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "noteId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "noteId",
    "variableName": "noteId"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditUser",
      "kind": "LinkedField",
      "name": "updatedBy",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "note_updateBarrierNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutation",
        "kind": "LinkedField",
        "name": "noteMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateNote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Barrier",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "note_FullBarrier"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "note_updateBarrierNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NoteMutation",
        "kind": "LinkedField",
        "name": "noteMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateNote",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Barrier",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BarrierCategory",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Escalation",
                    "kind": "LinkedField",
                    "name": "escalation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "owner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CodeTablePair",
                    "kind": "LinkedField",
                    "name": "ownerRole",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BarrierNote",
                    "kind": "LinkedField",
                    "name": "notes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdc966aa74a990440d210db5338ab6c4",
    "id": null,
    "metadata": {},
    "name": "note_updateBarrierNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation note_updateBarrierNote_Mutation(\n  $noteId: UUID!\n  $text: String!\n) {\n  noteMutation {\n    updateNote(noteId: $noteId, text: $text) {\n      id\n      barriers {\n        ...note_FullBarrier\n        id\n      }\n    }\n  }\n}\n\nfragment note_FullBarrier on Barrier {\n  id\n  name\n  description\n  status\n  pinned\n  auditMeta {\n    created\n    createdBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n    updated\n    updatedBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n  }\n  category {\n    code\n    value\n    id\n  }\n  escalation {\n    auditMeta {\n      created\n      createdBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n    }\n    id\n  }\n  owner\n  ownerRole {\n    code\n    value\n    id\n  }\n  notes {\n    id\n    text\n    auditMeta {\n      created\n      createdBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      updated\n      updatedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n    }\n    pinned\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab244bdb25782bf500f68c9b3e26e270';

module.exports = node;
