import { deepDiff } from './diff'

import { LogManager, Logger } from '../log'
const logger: Logger = LogManager.getLogger('ca.util.should-component-update')

const DISALLOW_DEBUG = true

export function deepDiffShouldComponentUpdate(
  currentProps,
  nextProps,
  currentState,
  nextState,
  opts: {
    key: string,
    debug: boolean,
    ignoreProp: (key: string, oldValue: any, newValue: any) => boolean,
  }
) {
  const pDiff = deepDiff(currentProps, nextProps, opts && opts.ignoreProp)
  const sDiff = deepDiff(currentState, nextState, opts && opts.ignoreProp)
  if (!DISALLOW_DEBUG && opts && opts.debug) {
    if (pDiff) {
      logger.debug(opts.key, 'Component Props Changes', pDiff)
    }
    if (sDiff) {
      logger.debug(opts.key, 'Component State Changes', sDiff)
    }
  }
  return !!pDiff || !!sDiff
}
