import React from 'react'
import PropTypes from 'prop-types'

import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconButton from 'material-ui/IconButton'
import Stairs from 'mdi-react/StairsIcon'

import {
  red300 as EscalatedColor,
  grey500 as DeescalatedColor,
} from 'material-ui/styles/colors'

export const EscalationStatus = ({
  value,
  onChange = () => {},
  itemStatus,
}) => {
  const handleClick = e => {
    e.stopPropagation()
  }

  const color = value === 'Escalated' ? EscalatedColor : DeescalatedColor
  const changeToValue = value === 'Escalated' ? 'Deescalated' : 'Escalated'

  return (
    <span
      onClick={e => handleClick(e)}
      style={{
        display: 'flex',
        alignItems: 'center'
      }}>
      <IconButton
        color={color}
        style={{
          fill: color,
          padding: 8,
          width: 40,
          height: 40,
          visibility: itemStatus === 'Resolved' ? 'hidden' : 'visible',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        iconStyle={{
          border: '1px solid #b3b3b3',
          borderRadius: '5px',
          padding: '2px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => onChange(changeToValue)}>
        <Stairs height={28} width={28} />
      </IconButton>
    </span>
  )
}

EscalationStatus.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  itemStatus: PropTypes.string,
}
