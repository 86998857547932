import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { deepDiffShouldComponentUpdate } from '../../util/should-component-update'

const appPropType = PropTypes.shape({
  enabled: PropTypes.bool.isRequired,
  access: PropTypes.bool.isRequired,
})
export const applicationsPropType = PropTypes.shape({
  // Add Applications here as they're known.
  patientLogistics: appPropType,
  orderLogistics: appPropType,
  demandLogistics: appPropType,
  careAdvance: appPropType,
})

// Add Applications here as they're known.
const appNameMapping = {
  PATIENT_LOGISTICS: 'patientLogistics',
  ORDER_LOGISTICS: 'orderLogistics',
  WORKLOAD_LOGISTICS: 'demandLogistics',
  C3_FULL: 'careAdvance',
}

const mapStateToProps = state => ({
  assignedApplications: state.authState.identity.assignedApplications,
  enabledApplications: state.authState.identity.enabledApplications,
})

export function withApplications(component) {
  if (!component) {
    return withApplications
  }

  @connect(mapStateToProps)
  class WithApplications extends Component {
    static propTypes = {
      enabledApplications: PropTypes.arrayOf(PropTypes.string),
      assignedApplications: PropTypes.arrayOf(PropTypes.string),
    }

    static defaultProps = {
      assignedApplications: [],
      enabledApplications: [],
    }

    componentWillMount() {
      this.consolidateProps(this.props)
    }

    componentWillReceiveProps(nextProps) {
      this.consolidateProps(nextProps)
    }

    shouldComponentUpdate(nextProps, nextState) {
      return true
      // return deepDiffShouldComponentUpdate(
      //   this.props,
      //   nextProps,
      //   this.state,
      //   nextState,
      //   {
      //     debug: true,
      //     key: 'withApplications',
      //     ignoreProp: (key, oldValue, newValue) =>
      //       typeof newValue === 'function',
      //   }
      // )
    }

    consolidateProps = props => {
      this.apps = Object.values(appNameMapping).reduce((apps, app) => {
        apps[app] = { enabled: false, access: false }
        return apps
      }, {})

      props.enabledApplications.forEach(
        app =>
          this.apps[appNameMapping[app]] &&
          (this.apps[appNameMapping[app]].enabled = true)
      )
      props.assignedApplications.forEach(
        app =>
          this.apps[appNameMapping[app]] &&
          (this.apps[appNameMapping[app]].access = true)
      )
    }

    render() {
      const {
        enabledApplications,
        assignedApplications,
        ...otherProps
      } = this.props
      return React.createElement(component, {
        ...otherProps,
        applications: this.apps,
      })
    }
  }

  // Add Applications here as they're known.
  // Only map for known applications
  // Filter out the props that aren't relevant to the child
  return WithApplications
}
