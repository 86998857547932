import React from 'react'
import PropTypes from 'prop-types'
import Subheader from 'material-ui/Subheader'
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'

const DueDateFilter = ({ onChange, selected }) => {

  return (
    <div className="filter-container">
      <Subheader className="filter-subheader">Due Date</Subheader>
      <RadioButtonGroup
        key="dueDateFilter"
        name="dueDateFilter"
        valueSelected={selected}
        className="filter-items"
        defaultSelected="Current">
        <RadioButton
          value="All"
          label="All"
          onClick={onChange}
        />
        <RadioButton
          value="Current"
          label="Current + Overdue"
          onClick={onChange}
        />
        <RadioButton
          value="Future"
          label="Future"
          onClick={onChange}
        />
      </RadioButtonGroup>
    </div>
  )
}

DueDateFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired
}

export default DueDateFilter
