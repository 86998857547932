import React from 'react'
import PropTypes from 'prop-types'

import NAIcon from 'mdi-react/CheckboxBlankCircleIcon'
import OpenIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon'
import CompletedIcon from 'mdi-react/CheckboxMarkedCircleIcon'
import {
  green500 as OpenColor,
  green500 as CompletedColor,
  grey600 as NAColor,
  red500 as OverdueColor,
} from 'material-ui/styles/colors'

const colors = { OpenColor, CompletedColor, NAColor }
const icons = {
  Open: OpenIcon,
  Completed: CompletedIcon,
  NA: NAIcon,
}

export const MilestoneStatusIcon = ({
  value = 'NA',
  overdue = false,
  style = {},
  ...props
}) => {
  const Icon = icons[value]
  return (
    <div>
      <Icon
        style={{
          ...style,
          fill: overdue ? OverdueColor : colors[`${value}Color`],
        }}
        {...props}
      />
    </div>
  )
}

MilestoneStatusIcon.propTypes = {
  value: PropTypes.string,
  overdue: PropTypes.bool,
  style: PropTypes.object,
}
