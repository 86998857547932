/*eslint-disable react/no-set-state */
/*eslint-disable react/no-did-mount-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { createPaginationContainer, graphql } from 'react-relay'
import { ComplianceSection } from '../../components/compliance'

import { drawers } from '../../containers/drawer-component-mapping'
import '../views.scss'
import { collectWorkspaces } from '../../util/workspace'
import {
  deptNameCompareAscending,
  bedNameCompareAscending,
  currentDayOfStayCompareAscending,
} from '../../util/sort'
import { computeThresholds, findComplianceIssues } from '../../util/compliance'
import { upgradeProgressionIndicator } from '../../graphql/relay/queries/plan'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { updateAnticipatedDischargeTime } from '../../graphql/relay/queries/visit'
import { environment } from '../../graphql/relay/env'

const SectionLabel = 'Section'
const AreaLabel = 'My Totals'
const UnitsLabel = 'Units'
const PlanLabel = 'Plan'
const DRGLabel = 'DRG'
const WDDLabel = 'WDD'
const ProgressionLabel = 'Progression'
const SortLabel = 'Sort'
const LOSLabel = 'Current LOS'
const FilterTextLabel = 'Filter Text'

const AreaSection = AreaLabel + SectionLabel
const UnitsSection = UnitsLabel + SectionLabel
const PlanSection = PlanLabel + SectionLabel
const DRGSection = DRGLabel + SectionLabel
const WDDSection = WDDLabel + SectionLabel
const ProgressionSection = ProgressionLabel + SectionLabel
const SortSection = SortLabel + SectionLabel
const FilterTextSection = FilterTextLabel + SectionLabel
const SortOrder = 'SortOrder'
const Ascending = 'Ascending'
const Descending = 'Descending'


class ComplianceListPaginationContainer extends React.Component {
  static propTypes = {
    refreshCompliance: PropTypes.func.isRequired,
    search: PropTypes.shape({
      viewer: PropTypes.shape({
        beds: PropTypes.shape({
          edges: PropTypes.array,
          pageInfo: PropTypes.shape({
            hasNextPage: PropTypes.boolean,
          }),
        }),
      }),
    }),
    filters: PropTypes.array.isRequired,
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
      showErrorsPg: PropTypes.func,
      updateComplianceFilters: PropTypes.func,
      setDetailTabIndex: PropTypes.func,
      updateNavigationWorkspaces: PropTypes.func,
    }),
    complianceFilters: PropTypes.array,
    updateProgressionIndicator: PropTypes.func,
    updateAnticipatedDischargeTime: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        facility: PropTypes.object,
        department: PropTypes.object,
        bed: PropTypes.object,
        visit: PropTypes.object,
        plan: PropTypes.object,
      })
    ),
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidUpdate() {
    this.props.actions.updateNavigationWorkspaces(this.filteredItems)
  }

  handleDataItemClick = data => {
    this.handleFilterClick({
      section: AreaSection,
      id: 'My Totals',
      update: true,
      value: data.value,
      rowNumber: data.rowNumber,
      columnId: data.columnId,
    })
  }

  handleFilterClick = addRemoveFilter => {
    // CA-1099 - make a copy of complianceFilters so we don't have to
    // end this method with a call to forceUpdate
    let currentFilters = this.props.complianceFilters.slice()

    let nextFilters
    if (addRemoveFilter) {
      const areaFilter = addRemoveFilter.id === 'My Totals'
      const index = currentFilters.findIndex(f => f.id === addRemoveFilter.id)
      if (index === -1) {
        if (addRemoveFilter.update && addRemoveFilter.value.length === 0) {
          // Not in the current filter, but value is empty so ignore
          nextFilters = currentFilters
        } else {
          // Not in the current filter, so add
          nextFilters = currentFilters.concat([addRemoveFilter])
          if (addRemoveFilter.deselectChildrenIds) {
            addRemoveFilter.deselectChildrenIds.forEach(id => {
              const index = nextFilters.findIndex(f => f.id === id)
              index !== -1 && nextFilters.splice(index, 1)
            })
          }
        }
      } else {
        if (addRemoveFilter.update && addRemoveFilter.value.length > 0) {
          // In the current filter and can be updated
          currentFilters[index].value = addRemoveFilter.value
          if (areaFilter) {
            currentFilters[index].rowNumber = addRemoveFilter.rowNumber
            currentFilters[index].columnId = addRemoveFilter.columnId
          }
          nextFilters = currentFilters
        } else {
          // In the current filter, so remove
          nextFilters = currentFilters.slice(0)
          nextFilters.splice(index, 1)
        }
      }
    } else {
      nextFilters = []
    }

    this.props.actions.updateComplianceFilters(nextFilters)
  }

  handleSortOrderToggle = toggled => {
    const newValue = toggled ? Ascending : Descending
    let currentFilters = this.props.complianceFilters
    let nextFilters
    const index = currentFilters.findIndex(f => f.id === SortOrder)
    if (index === -1) {
      nextFilters = currentFilters.concat([
        {
          id: SortOrder,
          section: SortLabel,
          value: newValue,
          toggled: toggled,
        },
      ])
    } else {
      // In the current filter and can be updated
      currentFilters[index].value = newValue
      currentFilters[index].toggled = toggled
      nextFilters = currentFilters
    }
    this.props.actions.updateComplianceFilters(nextFilters)
    this.forceUpdate()
  }

  handleViewPlanClick = (bedId, planId, visitId) => {
    this.props.actions.setDetailTabIndex(0)
    this.props.history.push(`/visit/${visitId}`, {
      visitId,
      planId,
      bedId,
    })
  }

  handleProgressionIndicatorChange = (planId, progressionIndicator) => {
    const variables = {
      planId: planId,
      progressionIndicator: progressionIndicator }
    handleMutation(environment, upgradeProgressionIndicator, variables,
      this.props.refreshCompliance
    )
  }

  handleSetPlannedDischarge = (dischargeDateTime, visitId, autoPopulated = false) => {
    const variables = {
      visitId: visitId,
      anticipatedDischargeTime: dischargeDateTime,
      anticipatedDischargeTimeAutoPopulated: autoPopulated
    }
    handleMutation(environment, updateAnticipatedDischargeTime,
      variables,
      this.props.refreshCompliance
    )
  }

  handleSetWorkingDischargeDisposition = data => {
    this.props.actions.showDrawer(drawers.EDIT_WORKING_DC, {
      visit: data.visit,
      refetch: this.props.refreshCompliance
    })
  }

  handleSetDRG = ({ visit }) => {
    this.props.actions.showDrawer(drawers.DRG_VIEW, {
      visit: visit,
      refresh: this.props.refreshCompliance
    })
  }

  handleSetPlan = data => {
    const {
      actions: { showDrawer },
    } = this.props

    const {
      patient,
      visit: {
        id,
        decisionTime,
        admissionTime,
        targetDischargeTime,
        anticipatedDischargeTime,
      },
    } = data
    const title = `Apply Plan for ${patient.lastName}, ${patient.firstName}`

    showDrawer(drawers.CHOOSE_PLAN, {
      title,
      id,
      plan: data,
      patient,
      decisionTime,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
      refetch: this.props.refreshCompliance
    })
  }

  makeFilter(allItems, complianceFilters) {
    // First decide which items is the set to begin the filtering
    // Do this by building a set with all the items in the selected units
    // If no units are selected, then the set is all items
    let ids1, ids2, ids3
    let filterText,
      sort,
      sortOrder = Ascending

    complianceFilters.forEach(filter => {
      if (filter.section) {
        if (filter.section === AreaSection) {
          ids1 = ids1 ? ids1 : []
          filter.value.forEach(item => ids1.push(item.id))
        }
        if (filter.section === UnitsSection) {
          ids1 = ids1 ? ids1 : []
          filter.value.forEach(item => ids1.push(item.id))
        }
        if (
          filter.section === PlanSection ||
          filter.section === DRGSection ||
          filter.section === WDDSection ||
          filter.section === ProgressionSection
        ) {
          ids2 = ids2 ? ids2 : []
          filter.value.forEach(item => ids2.push(item.id))
        }
        if (filter.section === FilterTextSection) {
          filterText = filter.value
        }
        if (filter.section === SortSection) {
          if (filter.id === SortOrder) {
            sortOrder = filter.value
          } else {
            sort = filter
          }
        }
      }
    })

    // So if no unit is selected then use all the items
    if (!ids1) {
      ids1 = []
      allItems.forEach(item => ids1.push(item.id))
    }

    if (ids2) {
      ids1 = ids1.filter(x => this.has(ids2, x))
    }

    if (ids3) {
      ids1 = ids1.filter(x => this.has(ids3, x))
    }
    return {
      ids: ids1,
      filterText: filterText,
      sort: sort,
      sortOrder: sortOrder,
    }
  }

  has(array, item) {
    return array.indexOf(item) !== -1
  }

  isItemInFilter(filter, { id, bed, visit, patient }) {
    let isInFilter = this.has(filter.ids, id)

    if (isInFilter) {
      if (filter.filterText) {
        isInFilter = false
        if (bed.name.toLowerCase().startsWith(filter.filterText)) {
          isInFilter = true
        } else if (visit) {
          const name = `${visit.visitNumber} ${patient.mrn} ${
            patient.lastName
          },${patient.firstName}`
          if (name.toLowerCase().includes(filter.filterText)) {
            isInFilter = true
          }
        }
      }
    }

    return isInFilter
  }

  makeItems(workspaces) {
    let allItems = []
    let facilityItems = {}
    let deptItems = {}
    let myAssignmentItems = []

    if (workspaces) {
      const planIds = new Set()
      const thresholds = computeThresholds()

      workspaces.forEach(({ facility, department, bed, visit, plan }) => {
        if (
          plan &&
          !planIds.has(plan.id) &&
          visit.bedRequestStatus !== 'Discharged'
        ) {
          planIds.add(plan.id)
          const compliance = findComplianceIssues(
            bed,
            visit,
            plan,
            thresholds
          )

          if (
            compliance.noPlanIssue ||
            compliance.noDRGIssue ||
            compliance.noWDDIssue ||
            compliance.noPSIssue ||
            compliance.planDischargeIssue
          ) {
            // Only display patients that have at least one compliance issue
            const item = {
              ...plan,
              // Have no idea why the plan was spread and why patient copy
              // is at top level. Put careTeamMembers under plan to fix
              // CA-1527
              plan: {
                careTeamMembers: plan.careTeamMembers,
              },
              dept: department,
              bed,
              visit,
              patient: visit.patient,
              compliance,
            }
            allItems.push(item)

            bed.assigned && myAssignmentItems.push(item)
            let di =
              deptItems[department.name] || (deptItems[department.name] = [])
            di.push(item)
          }
        }
      })
    }
    return {
      allItems,
      facilityItems,
      deptItems,
      myAssignmentItems,
    }
  }

  makeFilteredItems(allItems, complianceFilters) {
    let filteredItems = []
    const filter = this.makeFilter(allItems, complianceFilters)

    allItems.forEach(item => {
      this.isItemInFilter(filter, item) && filteredItems.push(item)
    })

    if (filter.sort) {
      if (filter.sort.id === LOSLabel) {
        filteredItems = filteredItems.sort(currentDayOfStayCompareAscending) //LOS visit.currentDayOfStay
      } else {
        //LocationLabel
        filteredItems = filteredItems.sort(bedNameCompareAscending) //Location: Bed Name
        filteredItems = filteredItems.sort(deptNameCompareAscending) //Location: Dept Name
      }

      if (filter.sortOrder === Descending) {
        filteredItems = filteredItems.reverse()
      }
    }

    return filteredItems
  }

  render() {
    let beds = []
    if (this.props.search.viewer.beds) {
      beds = this.props.search.viewer.beds.edges || []
      this.hasNextPage = this.props.search.viewer.beds.pageInfo.hasNextPage
    }
    this.workspaces = beds.map(node => {
      let workspace = {}
      workspace.bed = node.bed
      workspace.department = workspace.bed.department
      workspace.visit = workspace.bed.visit
      workspace.plan = workspace.visit.plan ? workspace.visit.plan : null
      return workspace
    })
    this.workspaceLength = this.workspaces.length

    const { history } = this.props

    const items = this.makeItems(this.workspaces)
    const allItems = items.allItems
    return (
      <div className="compliance-view-container">
        {
          <ComplianceSection
            workspaces={allItems}
            onProgressionIndicatorChange={this.handleProgressionIndicatorChange}
            onSetPlan={this.handleSetPlan}
            onSetDRG={this.handleSetDRG}
            onSetWDD={this.handleSetWorkingDischargeDisposition}
            onSetPDC={this.handleSetPlannedDischarge}
            onViewPlanClick={this.handleViewPlanClick}
            history={history}
          />
        }
      </div>
    )
  }
}

const newProps = props => {
  const { facilityData, workspaceData } = props
  const workspaces = collectWorkspaces(
    facilityData.facilities,
    workspaceData.workspaces
  )
  return { ...props, workspaces }
}

export default createPaginationContainer(
  ComplianceListPaginationContainer,
  {
    search: graphql`
      fragment ComplianceListPaginationContainer_search on Query
        @argumentDefinitions(
          first: { type: "Int" }
          after: { type: "String", defaultValue: null }
          type: { type: "SearchType", defaultValue: null }
          query: { type: "String", defaultValue: null }
        ) {
        viewer {
          beds(first: $first, after: $after, type: $type, query: $query)
            @connection(key: "ComplianceListPaginationContainer_beds") {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
              totalCount
            }
            edges {
              bed: node {
                id
                name
                department {
                  id
                  exchangeName
                  name
                  type
                  includedInLosCalculation
                }
                exchangeName
                status
                visit {
                  id
                  visitNumber
                  patientProblem
                  homeBedExchangeName
                  admissionType
                  anticipatedDischargeTime
                  anticipatedDischargeUpdatedBy {
                    userName
                    firstName
                    lastName
                    id
                  }
                  targetDischargeTime
                  bedRequestStatus
                  decisionTime
                  tlos
                  currentDayOfStay
                  admittingComplaint
                  meta {
                    timeElapsedSinceAdmission
                  }
                  drgAttributes {
                    code
                    title
                    level
                    targetLos
                    modifyTargetLos
                  }
                  patient {
                    id
                    mrn
                    age
                    gender
                    lastName
                    middleInitial
                    firstName
                  }
                  workingDischargeDisposition {
                    id
                    code
                    value
                  }
                  dischargeVendor
                  plan {
                    id
                    progressionIndicator
                    toBeAdmitted
                    milestones {
                      id
                    }
                    appliedTemplates {
                      id
                      name
                      removed
                    }
                    careTeamMembers {
                      id
                      name
                      primary
                      role {
                        id
                        code
                        value
                      }
                    }
                  }
                  anticipatedTransferTime
                  anticipatedDischargeTimeAutoPopulated
                  anticipatedTransferTimeAutoPopulated
                }
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      }
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        first: 40,
        after: props.search.viewer.beds.pageInfo.endCursor,
        type: props.type,
        query: props.query,
      }
    },
    query: graphql`
      query ComplianceListPaginationContainerQuery(
        $first: Int
        $after: String
      ) {
        ...ComplianceListPaginationContainer_search
          @arguments(first: $first, after: $after)
      }
    `,
  }
)
