import React, {useState, useRef, useEffect} from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import IconButton from "material-ui/IconButton";
import 'react-datepicker/dist/react-datepicker.min.css'
import Clear from "../icons/Clear";
import PropTypes from "prop-types";

const CLDatePicker = (props) => {
  const { timeAutoPopulated, dateTime, customInput, id, popperProps, onSetPDC, dateOnlySelectionType, timeAutoPopulatedByMinutes } = props
  const [calendarDateTime, setCalendarDateTime] = useState(() => {
    return dateTime ? new Date(dateTime) : null
  });
  const storedCalendarDateTime = useRef(dateTime ? new Date(dateTime) : null)
  const timeCleared = useRef(false)
  const datePickerRef = useRef()

  useEffect(() => {
  }, [calendarDateTime])

  const clearTime = () => {
    storedCalendarDateTime.current = calendarDateTime
    setCalendarDateTime(null)
    timeCleared.current = true
  }

  const saveDateTime = () => {
    let dateToSave = storedCalendarDateTime.current
    if(timeCleared.current) {
      if(dateOnlySelectionType==='midnight') {
        if(!dateToSave) {
          //case when trying to save same day
          dateToSave = new Date()
        }
        dateToSave.setHours(23, 59, 59, 999)
      } else if(dateOnlySelectionType==='currentTime') {
        if(!dateToSave) {
          //case when trying to save same day
          dateToSave = new Date()
        }
        let currentDateWithAddition = moment(new Date()).add(timeAutoPopulatedByMinutes, 'minutes').toDate()
        dateToSave.setHours(currentDateWithAddition.getHours(), currentDateWithAddition.getMinutes(), 0, 0)
      }
    }
    onSetPDC(moment(dateToSave), id, timeCleared.current)
    datePickerRef.current.setOpen(false)
    onClickOutsideDatePicker(null, dateToSave)
  }

  const onCalendarOpen = (date) => {
    storedCalendarDateTime.current = date
    //either we are in dateOnly case or no time set case
    if(timeAutoPopulated) {
      setCalendarDateTime(null)
      timeCleared.current = true
    } else if(!date) {
      storedCalendarDateTime.current = null
      setCalendarDateTime(null)
      timeCleared.current = true
    }

  }
  const onClickOutsideDatePicker = (evt, dateToSave) => {
    if(dateToSave) {
      storedCalendarDateTime.current = new Date(dateToSave)
      setCalendarDateTime(new Date(dateToSave))
    } else {
      //when we click outside the date picker and click back on it, put back original date
      storedCalendarDateTime.current = dateTime ? new Date(dateTime) : new Date()
      setCalendarDateTime(dateTime ? new Date(dateTime) : new Date())
    }
    // setCalendarDateTime(storedCalendarDateTime ? storedCalendarDateTime.current : null)
    timeCleared.current = false
  }
  const onChange = (date, calendarDateSelectionEvent) => {
    //event sent if the date is changed and no event is sent if the time is changed
    if(calendarDateSelectionEvent) {
      if(timeCleared.current) {
        storedCalendarDateTime.current = date
        setCalendarDateTime(null)
      } else {
        storedCalendarDateTime.current = date
        setCalendarDateTime(date)
      }
    } else {
      //time changed case
      if(timeCleared.current && storedCalendarDateTime.current) {
        //since the calendar date has already been set to null before (i.e. today's calendar date), we cannot take the date from there
        storedCalendarDateTime.current.setHours(date?.getHours())
        storedCalendarDateTime.current.setMinutes(date?.getMinutes())
        //use corrected value
        setCalendarDateTime(storedCalendarDateTime.current)
      } else {
        setCalendarDateTime(date)
        storedCalendarDateTime.current = date
      }
      timeCleared.current = false
    }
  }

  return (
    <div>
      <DatePicker
        ref={datePickerRef}
        selected={calendarDateTime}
        onCalendarOpen={() => { onCalendarOpen(dateTime? new Date(dateTime): null) }}
        popperProps={popperProps}
        customInput={customInput}
        onChange={(date, e) => onChange(date, e)}
        timeInputLabel="Time"
        dateFormat="LLL"
        timeFormat="HH:mm"
        timeIntervals={15}
        minDate={new Date()}
        shouldCloseOnSelect={false}
        onClickOutside={onClickOutsideDatePicker}
        showTimeSelect
      >
        <div style={{position: 'absolute', right: '39px', bottom: '5px'}}>
          <button className="btn btn-dark btn-sm" style={{margin: '1px 120px 4px 17px'}} onClick={saveDateTime}>Save</button>
          <IconButton
            className="iconButtonStyle"
            onClick={clearTime}>
            <Clear />
          </IconButton>
        </div>
      </DatePicker>

    </div>
  );
};

CLDatePicker.propTypes = {
  onSetPDC: PropTypes.func,
  disabled: PropTypes.bool,
  popperProps: PropTypes.object,
  dateTime: PropTypes.string,
  customInput: PropTypes.object.isRequired,
  timeAutoPopulated: PropTypes.bool,
  id: PropTypes.string.isRequired,
  timeAutoPopulatedByMinutes: PropTypes.number,
  dateOnlySelectionType: PropTypes.string
}

CLDatePicker.defaultProps = {
  timeAutoPopulated: true,
  timeAutoPopulatedByMinutes: 0
}

export default CLDatePicker
