import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { graphql } from 'react-apollo'
import { CodeTableGQL } from '../../../../graphql/queries'

@graphql(CodeTableGQL.queries.barrierCategories, {
    name: 'barrierCategories'
})

class BarrierCategoryPicker extends Component {
  constructor(props) {
    super(props)
  }

  // getter of barrier categories filter
  get barrierCategoriesData() {
    const { barrierCategories } = this.props
    return (barrierCategories && !barrierCategories.loading)
      ? barrierCategories.codeTables.filter (item => item.active)
      : []
  }

  render() {
    const { className, onSelect, hasError } = this.props

    return (
      <Autocomplete
        size="small"
        required
        autoComplete
        // autoSelect   // disabling a Tab selection
        autoHighlight
        clearOnEscape
        openOnFocus
        className={className}
        options={this.barrierCategoriesData}
        getOptionLabel={(option) => option.value}
        onChange={(event, value) => {
          onSelect(value)
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            label="Category"
            error={hasError}
            variant="outlined"
            // helperText="*Required"
          />}
      />
    )
  }
}

BarrierCategoryPicker.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  barrierCategories: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    codeTables: PropTypes.array,
  }),
  onSelect: PropTypes.func
}

export default BarrierCategoryPicker
