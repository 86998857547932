/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NoteIcon from 'mdi-react/NoteIcon'
import { Paper } from 'material-ui'
import { yellow600 as NoteColor } from 'material-ui/styles/colors'
import { QuickAddMenu } from '../plan'
import { auditUpdatedCompareDescending } from '../../util/sort'
import { Note } from './Note'
import { StatefulTextField } from '../common'

export class NoteSection extends Component {
  static propTypes = {
    notes: PropTypes.array.isRequired,
    userNotes: PropTypes.array,
    selectedItem: PropTypes.object,
    onCreatePlanNote: PropTypes.func.isRequired,
    onUpdatePlanNote: PropTypes.func.isRequired,
    onRemovePlanNote: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    onUpdatePinned: PropTypes.func.isRequired,
  }

  render() {
    const {
      notes,
      selectedItem,
      onItemClick,
      onUpdatePinned,
      onCreatePlanNote,
      onUpdatePlanNote,
      onRemovePlanNote,
      userNotes,
    } = this.props

    const itemsToShow = notes.slice(0).sort(auditUpdatedCompareDescending)

    const normalizedUserNotes = userNotes.map(i => i.text).slice(0, 10)

    const quickAddElements = [
      {
        items: normalizedUserNotes,
        handleQuickAddItem: onCreatePlanNote,
      },
    ]

    return (
      <div className="note-section">
        <Header quickAddElements={quickAddElements} />
        {itemsToShow.map(item => (
          <div key={item.id} className="paper">
            <Note
              item={item}
              expanded={selectedItem.id === item.id}
              onClick={onItemClick}
              onUpdatePlanNote={onUpdatePlanNote}
              onRemovePlanNote={onRemovePlanNote}
              onUpdatePinned={onUpdatePinned}
            />
          </div>
        ))}
        <div
          style={{ paddingLeft: 15, paddingRight: 15, marginTop: '1px' }}
          className="paper">
          <StatefulTextField
            maxNumberOfChars={255}
            textFieldProps={{
              fullWidth: true,
              hintText: 'Add Note',
            }}
            onEnter={onCreatePlanNote}
            showCharacterCount
          />
        </div>
      </div>
    )
  }
}

const Header = ({ quickAddElements }) => (
  <div>
    <QuickAddMenu elements={quickAddElements} showMenuType={false} />
    <NoteIcon style={{ fill: NoteColor }} />
    <span style={{ verticalAlign: 'super' }}> Notes </span>
  </div>
)

Header.propTypes = {
  quickAddElements: PropTypes.array.isRequired,
}
