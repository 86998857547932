import gql from '../util/gql'

export const AuditGQL = {
  fragments: {
    FullAuditUser: gql`
      fragment FullAuditUser on AuditUser {
        userName
        firstName
        lastName
      }
    `,
    CreateUpdate: gql`
      fragment Created on Auditable {
        auditMeta {
          created
          createdBy {
            ...FullAuditUser
          }
        }
      }
      fragment Updated on Auditable {
        auditMeta {
          updated
          updatedBy {
            ...FullAuditUser
          }
        }
      }
      fragment CreatedUpdated on Auditable {
        ...Created
        ...Updated
      }
    `,
  },
  queries: {},
  mutations: {},
}
