import { graphql } from 'react-relay'

export const newEntryCodeTableUpdater = (proxyStore, variables) => {
  const store = proxyStore.getRootField("codeTableMutation")
  const payload = store.getLinkedRecord("createCodeTableEntry", variables)
  updateStore(proxyStore, variables, payload)
}

export const updateEntryCodeTableUpdater = (proxyStore, variables) => {
  updateStore(proxyStore, variables)
}

const updateStore = (proxyStore, variables, payload) => {
  const rootStore = proxyStore.get("client:root")
  const codeTableList = rootStore.getLinkedRecords('codeTables',{codeTableType: variables.codeTableType})
  const newArray = payload ? [payload, ...codeTableList] : codeTableList
  const sortedArray = newArray.sort((a,b) => a.getValue('ordinal') - b.getValue('ordinal'))
  rootStore.setLinkedRecords(sortedArray,'codeTables',{codeTableType: variables.codeTableType})
}

export const createCodeTableEntry = graphql`
  mutation codeTable_createCodeTableEntry_Mutation($codeTableType: CodeType!, $codeTableInput: CodeTableInput!) {
    codeTableMutation {
      createCodeTableEntry(codeTableType: $codeTableType, codeTableInput: $codeTableInput) {
        ...CodeTableRow_rowData
      }
    }
  }
`

export const modifyCodeTableEntry = graphql`
  mutation codeTable_modifyCodeTableEntry_Mutation($codeTableType: CodeType!, $code: String!, $codeTableInput: CodeTableInput!) {
    codeTableMutation {
      modifyCodeTableEntry(codeTableType: $codeTableType, code: $code, codeTableInput: $codeTableInput) {
        id
        code
        value
        ordinal
        active
      }
    }
  }
`

