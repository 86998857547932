/*eslint-disable react/no-set-state */

import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { PersonnelNavigation } from './PersonnelNavigation'
import { updateSnapFilter } from '../ducks/views'
import { graphql, fetchQuery } from 'react-relay'
import { environment } from '../graphql/relay/env'

class NavigatorComp extends React.Component {
  state = {
    workspaces: false,
  }
  componentDidMount() {
    this.fetchData()
  }

  populateWorkspace = (workspaces, bed, visit) => {
    let workspace = {}
    workspace.bed = bed
    workspace.department = workspace.bed.department

    workspace.visit = visit
    workspace.plan = workspace.visit ? workspace.visit.plan : null
    if (workspace.visit) {
      workspaces.push(workspace)
    }
  }

  fetchData = () => {
    let queryData = this.props.navigationWorkspacesQuery
    // CA-1776 - support Browser refresh on PatientPlan
    if(!queryData) {
      const lastQuery = sessionStorage.getItem("queryDataQuery")
      queryData = {
        type: sessionStorage.getItem("queryDataType"),
        query: lastQuery === 'undefined' || lastQuery === 'null' ? "" : lastQuery
      }
    }
    if (
      queryData &&
      queryData.type &&
      (queryData.type !== 'KPI' && queryData.type !== 'conflicted')
    ) {
      const assignedBeds = fetchQuery(environment, NavigatorWorkspaces, {
        type: queryData.type,
        query: queryData.query,
      }).then(data => {
        let workspaces = []
        data.viewer.beds.edges.forEach(node => {
          if (queryData.type === 'DISCHARGE' && queryData.query === 'Recent') {
            const dischargedVisits = node.bed.dischargedVisits
            for (let dvIndex = 0; dvIndex < dischargedVisits.length; dvIndex++) {
              this.populateWorkspace(workspaces, node.bed, dischargedVisits[dvIndex])
            }
          } else {
            this.populateWorkspace(workspaces, node.bed, node.bed.visit)
          }
        })
        // save queryData in session storage for access on Browser Refresh
        sessionStorage.setItem("queryDataType", queryData.type)
        sessionStorage.setItem("queryDataQuery", queryData.query)
        this.setState({ workspaces: workspaces })
      })
    } else {
      this.setState({ workspaces: this.props.navigationWorkspaces })
    }
  }
  render() {

    if (!this.state.workspaces) {
      return null
    }

    const {
      currentRoute,
      history,
      actions,

      snapFilter,
    } = this.props

    // CA-2144 When showing Plan Details for Epic Link we don't
    // have history.location.state and don't need a Navigator
    if(!history.location.state) {
      return ''
    }

    const { viewType} = currentRoute

    const conflictedView = viewType === 'ConflictedPlan'
    const planView = viewType === 'Plan'

    if (!(conflictedView || planView)) return null

    const handleViewPlanNavigation = (bedId, planId, visitId) => {
      // CA-2824 - now only query by visit when viewing Discharges
      const query = sessionStorage.getItem("queryDataQuery")
      const type = sessionStorage.getItem("queryDataType")
      const route =
        (type === 'DISCHARGE' && query === 'Recent') || viewType === 'ConflictedPlan' ?
          `/visit/${visitId}` : `/bed/${encodeURIComponent('Bed:' + bedId.replace(/%/g, '%25'))}`  // Had to manually encode % to get it to show correctly in URL

      history.push(route, {
        visitId,
        planId,
        bedId,
      })
    }

    const handleConflictedViewNavigation = (bedId, planId, visitId) => {
      history.push(`/conflicted-visit/${visitId}`, {
        visitId,
        bedId,
        planId,
      })
    }

    const viewNavigation = conflictedView ?
      handleConflictedViewNavigation : handleViewPlanNavigation

    const filteredWorkspaces = this.state.workspaces

    const { bedId, visitId } = history.location.state

    return (
      <span>
        <span style={{ verticalAlign: '5px' }}>
          <PersonnelNavigation
            workspaces={filteredWorkspaces}
            onSnapNavigation={viewNavigation}
            snapFilter={snapFilter}
            updateSnapFilter={actions.updateSnapFilter}
            visitId={visitId}
            bedId={bedId}
          />
        </span>
      </span>
    )
  }
}

NavigatorComp.propTypes = {
  currentRoute: PropTypes.object.isRequired,
  navigationWorkspaces: PropTypes.array,
  navigationWorkspacesQuery: PropTypes.object,
  snapFilter: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    updateSnapFilter: PropTypes.func,
  }),
  facilityData: PropTypes.object,
  workspaceData: PropTypes.object,
  systemConfigData: PropTypes.shape({
    configs: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        bedId: PropTypes.string,
        visitId: PropTypes.string,
      }),
    })
  }).isRequired,
}

const mapStateToProps = state => ({
  snapFilter: state.viewsAppState.snapFilter,
  navigationWorkspaces: state.viewsAppState.navigationWorkspaces,
  navigationWorkspacesQuery: state.viewsAppState.navigationWorkspacesQuery,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      updateSnapFilter,
    },
    dispatch
  ),
})

export const Navigator = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(NavigatorComp)

const NavigatorWorkspaces = graphql`
  query NavigatorWorkspacesQuery($type: SearchType, $query: String) {
    viewer {
      beds(first: 100000, type: $type, query: $query) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          totalCount
        }
        edges {
          bed: node {
            id
            name
            exchangeName
            status
#            providers {
#              id
#              firstName
#              lastName
#              primary
#              role {
#                id
#                code
#                value
#              }
#            }
            conflictingVisits {
              id
            }
            dischargedVisits {
              id
              patient {
                id
                age
                firstName
                gender
                lastName
                middleInitial
                mrn
              }
              plan {
                id
                careTeamMembers {
                  id
                  primary
                }
              }
              attendingPhysician {
                firstName
                id
                lastName
              }
              provider1 {
                id
                firstName
                lastName
                role {
                  code
                  id
                  value
                }
              }
            }
            department {
              id
              exchangeName
              name
              type
              includedInLosCalculation
            }
            visit {
              id
#              admissionTime
#              admissionType
#              admittingComplaint
#              anticipatedDischargeTime
              attendingPhysician {
                firstName
                id
                lastName
                primary
                role {
                  code
                  id
                  value
                }
              }
#              bedRequestStatus
#              bundledPayment
#              currentDayOfStay
#              decisionTime
#              decisionTimeEditable
#              decisionTimeUseMidnight
#              dischargeVendor
#              drgAttributes {
#                id
#                arithmeticLos
#                bundledPayment
#                code
#                commonPrincipalDX
#                commonProcedures
#                expectedLos
#                geometricLos
#                level
#                modifyTargetLos
#                majorDiagnosisCategory
#                readmissionRisk
#                targetLos
#                title
#                type
#                weight
#              }
#              drgUpdatedBy {
#                id
#                firstName
#                lastName
#                userName
#              }
#              homeBedExchangeName
#              insurancePlans {
#                id
#                planName
#                priority
#              }
#              meta {
#                id
#                decisionDue
#                dischargeToday
#                plannedDischargeExceeded
#                timeElapsedSinceAdmission
#                tlosExceeded
#              }
              patient {
                id
                age
                firstName
                gender
                lastName
                middleInitial
                mrn
              }
#              patientAlerts {
#                id
#                alertType
#                value
#              }
#              patientProblem
              provider1 {
                id
                firstName
                lastName
                role {
                  code
                  id
                  value
                }
              }
              provider2 {
                id
                firstName
                lastName
                role {
                  code
                  id
                  value
                }
              }
#              readmission
#              readmissionRisk
#              targetDischargeTime
#              tlos
#              visitNumber
#              visitStatus
#              workingDischargeDisposition {
#                code
#                id
#                value
#              }
              plan {
                id
#                actions {
#                  id
#                  text
#                  pinned
#                  status
#                  auditMeta {
#                    createdBy {
#                      id
#                      userName
#                      firstName
#                      lastName
#                    }
#                    updatedBy {
#                      id
#                      userName
#                      firstName
#                      lastName
#                    }
#                    created
#                    updated
#                  }
#                }
#                barriers {
#                  id
#                  name
#                  description
#                  pinned
#                  status
#                  category {
#                    id
#                    code
#                    value
#                  }
#                  escalation {
#                    id
#                  }
#                  auditMeta {
#                    createdBy {
#                      id
#                      userName
#                      firstName
#                      lastName
#                    }
#                    updatedBy {
#                      id
#                      userName
#                      firstName
#                      lastName
#                    }
#                    created
#                    updated
#                  }
#                  owner
#                  ownerRole {
#                    id
#                    code
#                    value
#                  }
#                  notes {
#                    id
#                    text
#                    pinned
#                    auditMeta {
#                      createdBy {
#                        id
#                        userName
#                        firstName
#                        lastName
#                      }
#                      updatedBy {
#                        id
#                        userName
#                        firstName
#                        lastName
#                      }
#                      created
#                      updated
#                    }
#                  }
#                }
                careTeamMembers {
                  id
                  name
                  role {
                    id
                    code
                    value
                  }
                  primary
                }
#                meta {
#                  milestoneMeta {
#                    overdue
#                    onTime
#                    completed
#                  }
#                  lengthOfStayMeta {
#                    forecast
#                    cappedForecast
#                  }
#                  actualOE
#                }
#                milestones {
#                  id
#                  actions {
#                    id
#                    pinned
#                    text
#                  }
#                }
#                notes {
#                  id
#                  text
#                  pinned
#                  auditMeta {
#                    createdBy {
#                      id
#                      userName
#                      firstName
#                      lastName
#                    }
#                    updatedBy {
#                      id
#                      userName
#                      firstName
#                      lastName
#                    }
#                    created
#                    updated
#                  }
#                }
#                progressionIndicator
#                toBeAdmitted
              }
            }
          }
        }
      }
    }
  }
`
