/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateEstimatedTransferDate_MutationVariables = {|
  visitId: any,
  anticipatedTransferTime?: ?any,
  anticipatedTransferTimeAutoPopulated: boolean,
|};
export type visit_updateEstimatedTransferDate_MutationResponse = {|
  +visitMutation: ?{|
    +updateEstimatedTransferDate: ?{|
      +id: string,
      +anticipatedTransferTime: ?any,
      +anticipatedTransferTimeAutoPopulated: ?boolean,
    |}
  |}
|};
export type visit_updateEstimatedTransferDate_Mutation = {|
  variables: visit_updateEstimatedTransferDate_MutationVariables,
  response: visit_updateEstimatedTransferDate_MutationResponse,
|};
*/


/*
mutation visit_updateEstimatedTransferDate_Mutation(
  $visitId: Long!
  $anticipatedTransferTime: ZonedDateTime
  $anticipatedTransferTimeAutoPopulated: Boolean!
) {
  visitMutation {
    updateEstimatedTransferDate(visitId: $visitId, anticipatedTransferTime: $anticipatedTransferTime, anticipatedTransferTimeAutoPopulated: $anticipatedTransferTimeAutoPopulated) {
      id
      anticipatedTransferTime
      anticipatedTransferTimeAutoPopulated
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anticipatedTransferTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "anticipatedTransferTimeAutoPopulated"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VisitMutation",
    "kind": "LinkedField",
    "name": "visitMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "anticipatedTransferTime",
            "variableName": "anticipatedTransferTime"
          },
          {
            "kind": "Variable",
            "name": "anticipatedTransferTimeAutoPopulated",
            "variableName": "anticipatedTransferTimeAutoPopulated"
          },
          {
            "kind": "Variable",
            "name": "visitId",
            "variableName": "visitId"
          }
        ],
        "concreteType": "Visit",
        "kind": "LinkedField",
        "name": "updateEstimatedTransferDate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anticipatedTransferTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "anticipatedTransferTimeAutoPopulated",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateEstimatedTransferDate_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateEstimatedTransferDate_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4ec16e62293a0581a0b9e548c6989af3",
    "id": null,
    "metadata": {},
    "name": "visit_updateEstimatedTransferDate_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateEstimatedTransferDate_Mutation(\n  $visitId: Long!\n  $anticipatedTransferTime: ZonedDateTime\n  $anticipatedTransferTimeAutoPopulated: Boolean!\n) {\n  visitMutation {\n    updateEstimatedTransferDate(visitId: $visitId, anticipatedTransferTime: $anticipatedTransferTime, anticipatedTransferTimeAutoPopulated: $anticipatedTransferTimeAutoPopulated) {\n      id\n      anticipatedTransferTime\n      anticipatedTransferTimeAutoPopulated\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '045faa441a2d289f216828f0a76ce266';

module.exports = node;
