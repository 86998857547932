/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'

import IconButton from 'material-ui/IconButton'
import OpenInNew from 'material-ui/svg-icons/action/open-in-new'

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'

import FlatButton from 'material-ui/FlatButton'
import { default as Pdf } from 'react-pdf-js-infinite'

export class PlanDocumentLink extends React.Component {
  state = { showPdfModal: false }
  isTablet = navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i
  )

  showPlanDocument = (link, templateInfo) => {
    if (this.isTablet) {
      this.setState({ showPdfModal: true })
    } else {
      let newWindow = window.open(link, link)
      // put a meaningful title on the Window
      // In Dev environment, it fails with this cors error because the link we
      // just opened is at localhost with the default port, but this code was
      // served up from localhost:3000
      setTimeout(
        () =>
          newWindow.document
            .getElementsByTagName('html')[0]
            .appendChild(document.createElement('head'))
            .appendChild(document.createElement('title'))
            .appendChild(
              document.createTextNode(`${templateInfo.name} Document`)
            ),
        500
      )
    }
  }

  hidePlanDocument = () => {
    this.setState({ showPdfModal: false })
  }

  templateName = this.props.templateInfo
    ? this.props.templateInfo.name
    : 'No Plan Applied'
  link = this.props.templateInfo ? this.props.templateInfo.documentURL : ''

  render() {
    return (
      <span>
        {this.isTablet && (
          <StyledDialog
            open={this.state.showPdfModal}
            onClose={() => {
              this.hidePlanDocument()
            }}
            fullWidth>
            <DialogTitle id="alert-dialog-slide-title">
              {this.templateName}
            </DialogTitle>
            <DialogContent>
              <Pdf
                file={this.link}
                scale={1.0}
                style={{ minHeight: '500px' }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.hidePlanDocument} color="primary">
                Close
              </Button>
            </DialogActions>
          </StyledDialog>
        )}
        <span>
          {this.link && (
            <IconButton
              style={{ padding: 8, width: 40, height: 40 }}
              onClick={() => {
                this.showPlanDocument(this.link, this.templateInfo)
              }}>
              <OpenInNew />
            </IconButton>
          )}
          <span
            style={{
              paddingRight: '.5em',
            }}>
            {this.templateName}
          </span>
        </span>
      </span>
    )
  }
}

PlanDocumentLink.propTypes = {
  templateInfo: PropTypes.object,
}

const StyledDialog = styled(Dialog)`
  z-index: 9900 !important;
`
