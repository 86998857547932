import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { NoteSection } from './NoteSection'
import { selectItem } from '../../ducks/app-selections'
import {
  createPlanNote,
  updatePlanNote,
  removePlanNote,
} from '../../graphql/relay/queries/note'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import {
  pinPlanNote,
  unpinPlanNote,
} from '../../graphql/relay/queries/pin'
import { environment } from '../../graphql/relay/env'

export const NoteSectionGQL = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  mapProps(props => newProps(props))
)(NoteSection)

const newProps = props => {
  const {
    workspace: {
      plan: { id, notes },
    },
    actions: { selectItem },
    selected,
    userNotes,
  } = props

  const selectedItem = selected.currentItem

  const onItemClick = (e, item) => {
    e.stopPropagation()
    selectItem(item)
  }

  const onCreatePlanNote = text => {

    const variables = { planId: id, note: { text: text, pinned: false } }
    handleMutation(
      environment,
      createPlanNote,
      variables,
      undefined,
      undefined
    )
  }

  const onUpdatePlanNote = (noteId, text) => {
    if (text && text.length > 0) {

      const variables = { noteId: noteId, text: text }
      handleMutation(
        environment,
        updatePlanNote,
        variables,
        undefined,
        undefined
      )
    } else {

      const variables = { noteId: noteId }
      handleMutation(
        environment,
        removePlanNote,
        variables,
        undefined,
        undefined
      )
    }
  }

  const onRemovePlanNote = noteId => {

    const variables = { noteId: noteId }
    handleMutation(
      environment,
      removePlanNote,
      variables,
      undefined,
      undefined
    )
  }

  const onUpdatePinned = note => {
    const fn = note.pinned ? unpinPlanNote : pinPlanNote

    const variables = { itemId: note.id }
    handleMutation(environment, fn, variables, undefined, undefined)
  }

  return {
    ...props,
    notes,
    userNotes,
    selectedItem,
    onItemClick,
    onCreatePlanNote,
    onUpdatePlanNote,
    onRemovePlanNote,
    onUpdatePinned,
  }
}

function mapStateToProps(state) {
  return {
    selected: state.selectAppState,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectItem,
      },
      dispatch
    ),
  }
}
