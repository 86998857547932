import gql, {injectForGqlFile} from '../util/gql'

export const DrgGQL = {
  fragments: {
    FullDrg: gql`
      fragment FullDrg on Drg {
        arithmeticLos
        code
        commonPrincipalDX
        commonProcedures
        geometricLos
        level
        majorDiagnosisCategory
        readmissionRisk
        title
        type
        weights
        snfExpectedLos
        snfTargetLos
        rehabExpectedLos
        rehabTargetLos
        hospiceMedExpectedLos
        hospiceMedTargetLos
        hospiceHomeExpectedLos
        hospiceHomeTargetLos
        homeHealthExpectedLos
        homeHealthTargetLos
        homeExpectedLos
        homeTargetLos
        acuteCareHospitalExpectedLos
        acuteCareHospitalTargetLos
        otherExpectedLos
        otherTargetLos
      }
    `,
    FullSeverity: gql`
      fragment FullSeverity on Severity {
        level
        diagnosisCategory
        secondaryDiagnosis
      }
    `,
  },
  queries: {
    drgs: gql`
      query drgs($facilityId: Long) {
        drgs(facilityId: $facilityId) {
          ...FullDrg
        }
      }
    `,
    severities: gql`
      query severities {
        severities {
          ...FullSeverity
        }
      }
    `,
    severityPoster: gql`
      query severityPoster {
        severityPoster
      }
    `,
  },
  mutations: {},
}

injectForGqlFile(DrgGQL)
