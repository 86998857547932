export const fixVariableIds = variables => {
  if (variables.planId) {
    variables.planId = variables.planId.substring(
      variables.planId.indexOf(':') + 1
    )
  }

  if (variables.visitId) {
    variables.visitId = variables.visitId.substring(
      variables.visitId.indexOf(':') + 1
    )
  }

  if (variables.planTemplateId) {
    variables.planTemplateId = variables.planTemplateId.substring(
      variables.planTemplateId.indexOf(':') + 1
    )
  }

  if (variables.templateId) {
    variables.templateId = variables.templateId.substring(
      variables.templateId.indexOf(':') + 1
    )
  }

  if (variables.barrierId) {
    variables.barrierId = variables.barrierId.substring(
      variables.barrierId.indexOf(':') + 1
    )
  }

  if (variables.noteId) {
    variables.noteId = variables.noteId.substring(
      variables.noteId.indexOf(':') + 1
    )
  }

  if (variables.itemId) {
    variables.itemId = variables.itemId.substring(
      variables.itemId.indexOf(':') + 1
    )
  }

  if (variables.actionId) {
    variables.actionId = variables.actionId.substring(
      variables.actionId.indexOf(':') + 1
    )
  }

  if (variables.itemId) {
    variables.itemId = variables.itemId.substring(
      variables.itemId.indexOf(':') + 1
    )
  }

  if (variables.memberId) {
    variables.memberId = variables.memberId.substring(
      variables.memberId.indexOf(':') + 1
    )
  }

  if (variables.boardTemplateId) {
    variables.boardTemplateId = variables.boardTemplateId.substring(
      variables.boardTemplateId.indexOf(':') + 1
    )
  }

  if (variables.visibilityBoardId) {
    variables.visibilityBoardId = variables.visibilityBoardId.substring(
      variables.visibilityBoardId.indexOf(':') + 1
    )
  }

  if (variables.visibilityBoardInput && variables.visibilityBoardInput.templateId) {
    variables.visibilityBoardInput.templateId = variables.visibilityBoardInput.templateId.substring(
      variables.visibilityBoardInput.templateId.indexOf(':') + 1
    )
  }

  if (variables.visibilityBoardAddressId) {
    variables.visibilityBoardAddressId = variables.visibilityBoardAddressId.substring(
      variables.visibilityBoardAddressId.indexOf(':') + 1
    )
  }

  if (variables.visibilityBoardAddressInput && variables.visibilityBoardAddressInput.visibilityBoardId) {
    variables.visibilityBoardAddressInput.visibilityBoardId = variables.visibilityBoardAddressInput.visibilityBoardId.substring(
      variables.visibilityBoardAddressInput.visibilityBoardId.indexOf(':') + 1
    )
  }
  if (variables.ownerId) {
    variables.ownerId = variables.ownerId.substring(
      variables.ownerId.indexOf(':') + 1
    )
  }

  if (variables.recipientId) {
    variables.recipientId = variables.recipientId.substring(
      variables.recipientId.indexOf(':') + 1
    )
  }

  return variables
}
