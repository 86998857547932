/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type action_fetchPlan_QueryVariables = {|
  planId: string
|};
export type action_fetchPlan_QueryResponse = {|
  +node: ?{|
    +id?: string,
    +actions?: $ReadOnlyArray<?{|
      +id: string,
      +text: string,
      +auditMeta: ?{|
        +created: any,
        +createdBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
        +updated: any,
        +updatedBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
      |},
    |}>,
    +milestones?: $ReadOnlyArray<?{|
      +id: string,
      +actions: $ReadOnlyArray<?{|
        +id: string,
        +text: string,
        +auditMeta: ?{|
          +updated: any,
          +updatedBy: {|
            +userName: string,
            +firstName: string,
            +lastName: string,
          |},
        |},
      |}>,
    |}>,
  |}
|};
export type action_fetchPlan_Query = {|
  variables: action_fetchPlan_QueryVariables,
  response: action_fetchPlan_QueryResponse,
|};
*/


/*
query action_fetchPlan_Query(
  $planId: ID!
) {
  node(id: $planId) {
    __typename
    ... on Plan {
      id
      actions {
        id
        text
        auditMeta {
          created
          createdBy {
            userName
            firstName
            lastName
            id
          }
          updated
          updatedBy {
            userName
            firstName
            lastName
            id
          }
        }
      }
      milestones {
        id
        actions {
          id
          text
          auditMeta {
            updated
            updatedBy {
              userName
              firstName
              lastName
              id
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "planId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "planId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "updatedBy",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v11 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v2/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "updatedBy",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "action_fetchPlan_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Plan",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "action_fetchPlan_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditUser",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Plan",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff09ad3edd0f95456ba0210308115889",
    "id": null,
    "metadata": {},
    "name": "action_fetchPlan_Query",
    "operationKind": "query",
    "text": "query action_fetchPlan_Query(\n  $planId: ID!\n) {\n  node(id: $planId) {\n    __typename\n    ... on Plan {\n      id\n      actions {\n        id\n        text\n        auditMeta {\n          created\n          createdBy {\n            userName\n            firstName\n            lastName\n            id\n          }\n          updated\n          updatedBy {\n            userName\n            firstName\n            lastName\n            id\n          }\n        }\n      }\n      milestones {\n        id\n        actions {\n          id\n          text\n          auditMeta {\n            updated\n            updatedBy {\n              userName\n              firstName\n              lastName\n              id\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42d1d45360e9b21e8df42a2147766606';

module.exports = node;
