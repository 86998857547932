import React, { useEffect, useReducer, useRef } from 'react'
import TextField from '../TextField'
import { LoadingIndicator } from '../../loader'
import useDebounce from '../../../hooks/useDebounce'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Popper from '@material-ui/core/Popper'
import classNames from 'classnames'

const initialState = {
  searchText: '',
  subText: '',
  isDirty: false,
  isResultsDropdownOpen: false,
  cursor: 0
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INITIAL_SELECTED_VALUE':
      return {
        ...state,
        searchText: action.payload.text,
        subText: action.payload.subText
      }
    case 'CLOSE_RESULTS_DROPDOWN':
      return {
        ...state,
        isResultsDropdownOpen: false
      }
    case 'CLEAR_SEARCH_TEXT':
      return {
        ...state,
        searchText: '',
        subText: '',
        isDirty: false,
        cursor: 0
      }
    case 'SEARCH_TEXT_CHANGED':
      return {
        ...state,
        isDirty: true,
        searchText: action.payload,
        subText: '',
        isResultsDropdownOpen: action.payload.length > 0,
        cursor: 0
      }
    case 'RESULT_CLICKED':
      return {
        searchText: '',
        subText: '',
        isDirty: false,
        isResultsDropdownOpen: false,
        cursor: 0
      }
    case 'SAME_RESULT_CLICKED':
      return {
        searchText: action.payload.text,
        subText: action.payload.subText,
        isDirty: false,
        isResultsDropdownOpen: false,
        cursor: 0
      }
    case 'INCREMENT_CURSOR':
      return {
        ...state,
        cursor: state.cursor + 1
      }
    case 'DECREMENT_CURSOR':
      return {
        ...state,
        cursor: state.cursor - 1
      }
    case 'CURSOR_MOVED':
      return {
        ...state,
        cursor: action.payload
      }
    default:
      return {...state}
  }
}

const TypeAheadInput = ({ label, onTextChanged, onResultSelected, searchResults, selectedValue, selectedSubText, debounceInMS = 200, isLoading,
                        displayField = 'displayValue', searchResultIconDisplay, minLength = 2, searchFooterText, disabled, showSubtext,
                          clearTextOnSelect, focusOnSearchField, ...textFieldProps}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const resultsRef = useRef(null)
  const searchFieldRef = useRef(null)

  // When search text is changed, debounce it before storing the results
  const debouncedSearchText = useDebounce(state.searchText, debounceInMS)

  // Handles an initial value being selected when component is loaded. Will set the search text to that value
  useEffect(() => {
    let payload
    if (selectedValue) {
      payload = {
        text: selectedValue,
        subText: selectedSubText
      }
    } else {
      payload = {
        text: '',
        subText: ''
      }
    }
    dispatch({ type: 'SET_INITIAL_SELECTED_VALUE', payload: payload })
  }, [selectedValue, selectedSubText])

  // When the debounced search text value is received, only then use the callback to call server to search for results
  useEffect(() => {
    if (debouncedSearchText && debouncedSearchText === state.searchText && debouncedSearchText !== selectedValue && debouncedSearchText.length >= minLength && state.isResultsDropdownOpen) {
      onTextChanged(debouncedSearchText)
    }
  }, [debouncedSearchText, minLength, selectedValue, state.searchText, state.isResultsDropdownOpen])

  // Handle when search text is being removed. Do we clear it or set back to the initial value?
  useEffect(() => {
    let payload
    if (selectedValue) {
      payload = {
        text: selectedValue,
        subText: selectedSubText
      }
    } else {
      payload = {
        text: '',
        subText: ''
      }
    }
    if (state.isDirty && !state.isResultsDropdownOpen && state.searchText === '') {
      dispatch({ type: 'CLEAR_SEARCH_TEXT' })
    } else if (state.isDirty && !state.isResultsDropdownOpen && state.searchText !== '') {
      dispatch({ type: 'SET_INITIAL_SELECTED_VALUE', payload: payload })
    }
  }, [state.isResultsDropdownOpen, state.isDirty, state.searchText, selectedValue, selectedSubText])

  useEffect(() => {
    if (focusOnSearchField && searchFieldRef?.current) {
      searchFieldRef.current.focus()
    }
  }, [focusOnSearchField])

  const handleSearchTextChanged = event => {
    const value = event.target.value

    dispatch({ type: 'SEARCH_TEXT_CHANGED', payload: value})
  }

  const handleOnMouseDown = (event) => {
    event.preventDefault()
  }

  const handleResultClicked = result => {
    if (result && result[displayField]) {
      var eventType = result[displayField] === selectedValue ? 'SAME_RESULT_CLICKED' : 'RESULT_CLICKED'
      dispatch({type: eventType, payload: {text: result[displayField], subText: result.subText}})
      onResultSelected(result[displayField], result.id)
    }
    if (clearTextOnSelect && eventType === 'RESULT_CLICKED') {
      dispatch({ type: 'CLEAR_SEARCH_TEXT' })
    }
  }

  const handleKeyDown = event => {
    if (event.keyCode === 38 && state.cursor > 0) { // Up arrow
      dispatch({ type: 'DECREMENT_CURSOR' })
    } else if (event.keyCode === 40 && state.cursor < searchResults.length - 1) { // Down arrow
      dispatch({ type: 'INCREMENT_CURSOR' })
    } else if ((event.keyCode === 9 || event.keyCode === 13) && state.cursor >= 0 && state.isResultsDropdownOpen ) {
      handleResultClicked(searchResults[state.cursor])
    }
  }

  const boldMatchingText= (text, splitSearchOnComma = true) => {
    let boldedText = null
    if (text) {
      const wordSplit = text.split(' ')
      const searchSplit = splitSearchOnComma ? state.searchText.trim().split(',') : state.searchText.trim().split(' ')

      boldedText = wordSplit.map((word, index) => {
        let searchText
        if (searchSplit.length > 1) {
          for (let i=0; i < searchSplit.length; i++) {
            if (word.trim().toLowerCase().startsWith(searchSplit[i].trim().toLowerCase())) {
              searchText = searchSplit[i].trim()
              break
            }
          }
        } else {
          searchText = state.searchText.trim()
        }

        let matchingParts
        if (searchText) {
          const parts = word.split(new RegExp(`^(${escapeRegExp(searchText)})`, 'gi'))
          matchingParts = parts.map((part, i) => (
            <span key={i} style={part.trim().toLowerCase() === searchText.trim().toLowerCase() ? { fontWeight: 'bold' } : {}}>
              {part}
            </span>
          ))
        } else {
          matchingParts = word
        }
        return <span key={index}>{matchingParts} </span>
      })
    }

    return boldedText
  }

  //https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const getBoldMatchingDisplay = result => {
    const itemBoldDisplayResultText = boldMatchingText(result[displayField])
    const itemBoldDisplayResultSubText = boldMatchingText(result.subText, false)
    return (
      <div>
        <div className="result-text">{itemBoldDisplayResultText}</div>
        <StyledResultSubtext className="result-subtext">{itemBoldDisplayResultSubText}</StyledResultSubtext>
      </div>
    )
  }

  const getResultsPositioning = () => {
    let resultsPositioning = { maxHeight: 175, placement : 'top-start'}
    if (resultsRef.current) {
      // Ref for typeahead field
      const { top, bottom } = resultsRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const resultsPadding = 25

      // (Height of window) - (bottom position of typeahead) - 25px
      // Result dropdown should be from bottom of typeahead to 25px above bottom of screen
      const resultsHeightBeneath = windowHeight - bottom - resultsPadding
      // Alternatively, go from 25px from screen top to top of typeahead field
      const resultsHeightAbove = top - resultsPadding

      // If results dropdown is less than 175px, then put it above
      let minHeight = 175
      if (resultsHeightBeneath > minHeight) {
        resultsPositioning.maxHeight = resultsHeightBeneath
        resultsPositioning.placement = 'bottom-start'
      } else if (resultsHeightAbove > minHeight) {
        resultsPositioning.maxHeight = resultsHeightAbove
        resultsPositioning.placement = 'top-start'
      }
    }

    return resultsPositioning
  }

  const searchResultsDropdown = () => {
    let resultsDropdown = null
    const { maxHeight, placement } = getResultsPositioning()
    if (state.isResultsDropdownOpen && isLoading) {
      resultsDropdown = (
        <StyledResultsWrapperDiv>
          <LoadingIndicator style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '15px'
          }} />
        </StyledResultsWrapperDiv>
      )
    } else if (state.isResultsDropdownOpen && state.searchText.length < minLength) {
      resultsDropdown = (
        <StyledResultsWrapperDiv><StyledResultsFeedback className="NotEnoughChars">Enter at least { minLength } characters to begin searching.</StyledResultsFeedback></StyledResultsWrapperDiv>
      )
    } else if (state.isResultsDropdownOpen && searchResults && searchResults.length > 0) {
      resultsDropdown = (
        <StyledResultsWrapperDiv $maxHeight={`${maxHeight}px`}>
          {searchResults.map((result, index) => (
            <StyledResult
              key={result.id}
              className={classNames('Result', state.cursor === index ? 'active' : null, result.instantMessageReceiver ? 'IrisResult': null)}
              onClick={() => handleResultClicked(result)}
              onMouseDown={event => handleOnMouseDown(event)}
              onMouseOver={() => dispatch({ type: 'CURSOR_MOVED', payload: index})}
              $active={state.cursor === index}
              $instantMessageReceiver={result.instantMessageReceiver}>
              {getBoldMatchingDisplay(result)}
              {searchResultIconDisplay && searchResultIconDisplay(result, state.cursor === index ? 'active' : null)}
            </StyledResult>
          ))}
          {searchFooterText ?
            (<StyledSearchFooter className={'SearchFooter'}>
              {searchFooterText}
            </StyledSearchFooter>)
            : null
          }
        </StyledResultsWrapperDiv>
      )
    } else if (state.isResultsDropdownOpen && searchResults && searchResults.length === 0) {
      resultsDropdown = (
        <StyledResultsWrapperDiv><StyledResultsFeedback className="NoResults">No Results Found</StyledResultsFeedback></StyledResultsWrapperDiv>
      )
    }

    if (resultsDropdown) {
      resultsDropdown = (
        <StyledResultsPopper
          open
          anchorEl={resultsRef.current}
          placement={placement}
          $width={resultsRef.current?.clientWidth}>
          {resultsDropdown}
        </StyledResultsPopper>
      )
    }

    return resultsDropdown
  }

  const handleBlur = () => {
    // Field had a value at first, but user deleted it.
    // We need to set that blank value as selected once user leaves field
    if (selectedValue && state.searchText === '') {
      onResultSelected('')
    }
    dispatch({ type: 'CLOSE_RESULTS_DROPDOWN' })
  }

  return (
    <StyledTypeAheadInputWrapper ref={resultsRef}>
      <StyledTextField
        {...textFieldProps}
        onClick={() => {searchFieldRef?.current?.focus()}}
        inputRef={searchFieldRef}
        label={label}
        value={state.searchText}
        onChange={event => handleSearchTextChanged(event)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        trackDirty
        disabled={disabled}
        fullWidth
        $subText={showSubtext && state.subText}
      />
      { showSubtext && state.subText ? (
        <StyledSubtext className={'SubText'}>
          {state.subText}
        </StyledSubtext>
      ) : null}
      {searchResultsDropdown()}
    </StyledTypeAheadInputWrapper>
  )
}

const StyledTextField = styled(TextField)`
  && label {
    font-size: 14px;
  }

  && label.Mui-focused {
    color: #4dcedf;
  }

  && ::after {
    border-bottom-color: #4dcedf;
  }

  ${props => props.$subText && css`
    & div {
      padding-bottom: 10px;
      z-index: 50;
    }
  `}
`

const StyledTypeAheadInputWrapper = styled.div`
  width: 100%;
  position: relative;
  color: #4A4A4A;
`

const StyledResultsPopper = styled(Popper)`
  z-index: 20000;
  width: ${props => props.$width}px;
`

const StyledResultsWrapperDiv = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #979797;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,0.5);
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.5);
  padding: 10px 0;
  border-radius: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: ${props => props.$maxHeight};
`

const StyledResult = styled.div`
  padding: 5px 2px 5px 15px;
  word-break: break-word;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props => props.$active && css`
    background-color: #4DD0E1;
    color: white;
  `}

  ${props => props.$instantMessageReceiver && css`
    padding-right: 2px;
  `}

  :hover {
    background-color: #4DD0E1;
    color: white;
    cursor: pointer;
  }
`

const StyledResultSubtext = styled.div`
  color: rgba(0, 0, 0, 0.5)
`

const StyledResultsFeedback = styled.div`
  padding: 10px 15px;
  text-align: center;
  font-style: italic;
`

const StyledSearchFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #A5A5A5;
  font-size: 12px;
  border-top: 1px solid #979797;
  padding-top: 4px;
  padding-right: 15px;
`

const StyledSubtext = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 1px;
  font-size: 12px;
  z-index: 10;
`

TypeAheadInput.propTypes = {
  label: PropTypes.string,
  onTextChanged: PropTypes.func.isRequired,
  onResultSelected: PropTypes.func.isRequired,
  searchResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayValue: PropTypes.string
    })
  ),
  selectedValue: PropTypes.string,
  selectedSubText: PropTypes.string,
  debounceInMS: PropTypes.number,
  isLoading: PropTypes.bool,
  displayField: PropTypes.string,
  searchResultIconDisplay: PropTypes.func,
  minLength: PropTypes.number,
  searchFooterText: PropTypes.string,
  disabled: PropTypes.bool,
  showSubtext: PropTypes.bool,
  clearTextOnSelect: PropTypes.bool,
  focusOnSearchField: PropTypes.bool
}

export default TypeAheadInput
