/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './compliance.scss'

import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table'

import SortAscendingIcon from 'mdi-react/ArrowUpThickIcon'
import SortDescendingIcon from 'mdi-react/ArrowDownThickIcon'

import { toSortableString } from '../../util/sort'
import { getPlannedLabel } from '../../util/configs'
import { is_Mac, is_Safari, is_Windows, is_Chrome } from '../../util/utils'
import { ComplianceTableCell } from './ComplianceTableCell'

import classNames from 'classnames'
export class ComplianceTable extends Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    items: PropTypes.object,
    complianceFilters: PropTypes.array,
    onDataItemClick: PropTypes.func,
    department: PropTypes.string,
    filter: PropTypes.string,
    handleQueryUpdate: PropTypes.func,
  }

  state = {
    sortOrder: 'All',
    ascending: false,
    rowName: '',
  }

  handleHeaderClick = (rowNumber, columnId) => {
    const sortOrders = [
      'My Totals',
      'All',
      'Plan',
      'DRG',
      'D/C Arrangements',
      'Progression',
      getPlannedLabel() + ' D/C',
    ]
    const newSortOrder = sortOrders[columnId]

    if (this.state.sortOrder !== newSortOrder) {
      this.setState({ sortOrder: newSortOrder, ascending: columnId === 0 })
    } else {
      this.setState({ ascending: !this.state.ascending })
    }
    // this.props.onDataItemClick({ value: [], rowNumber: -1, columnId: -1 })
  }

  makeHeader(label) {
    const { sortOrder, ascending } = this.state

    const cn = classNames('icon', ascending ? 'ascending' : 'descending')
    const sortAscendingIcon = <SortAscendingIcon className={cn} />
    const sortDescendingIcon = <SortDescendingIcon className={cn} />
    let sortIcon =
      sortOrder === label
        ? ascending
          ? sortAscendingIcon
          : sortDescendingIcon
        : null

    return (
      <TableRowColumn className="cell">
        {sortIcon}
        {label}
      </TableRowColumn>
    )
  }

  sortFilters = filters => {
    if (this.state.sortOrder === '') {
      return filters
    } else {
      let colName = ''
      if (this.state.sortOrder === 'Plan') {
        colName = 'plans'
      } else if (this.state.sortOrder === 'All') {
        colName = 'totalIssues'
      } else if (this.state.sortOrder === 'D/C Arrangements') {
        colName = 'dischargeArrangements'
      } else if (this.state.sortOrder === 'DRG') {
        colName = 'drgs'
      } else if (this.state.sortOrder === `${getPlannedLabel()} D/C`) {
        colName = 'plannedDischarges'
      } else if (this.state.sortOrder === 'Progression') {
        colName = 'progression'
      } else if (this.state.sortOrder === 'My Totals') {
        colName = 'department.name'
      }

      return filters
        .slice()
        .sort(this.compareFunc(colName, this.state.ascending ? 'asc' : 'des'))
    }
  }

  compareFunc = (col, order) => (a, b) => {
    if (col === 'department.name') {
      if (order === 'asc') {
        return a.department.name > b.department.name ? 1 : -1
      } else {
        return a.department.name < b.department.name ? 1 : -1
      }
    }
    if (order === 'asc') {
      return a[col] > b[col] ? 1 : -1
    } else {
      return a[col] < b[col] ? 1 : -1
    }
  }

  computeFacilitySystemTotals = (filters) => {
    const newItem = exchangeName => {
      return {
        department: {
          exchangeName: exchangeName,
          name: exchangeName
        },
        facility: {
          exchangeName: exchangeName
        },
        dischargeArrangements: 0,
          drgs: 0,
        plannedDischarges: 0,
        plans: 0,
        progression: 0,
        totalIssues: 0
      }
    }

    const incrementTotals = (found, filter) => {
      found.dischargeArrangements += filter.dischargeArrangements
      found.drgs += filter.drgs
      found.plannedDischarges += filter.plannedDischarges
      found.plans += filter.plans
      found.progression += filter.progression
      found.totalIssues += filter.totalIssues
    }

    let result = [newItem('System')]
    // for handling edge case of only one unit in facility and multiple facilities
    let facMap = {}

    const findAndIncrement = (filter, exchangeName) => {
      let found = result.find(item => item.facility.exchangeName === exchangeName)
      if(!found) {
        found = newItem(exchangeName)
        facMap[exchangeName] = 0
        result.push(found)
      }
      // increment facility totals
      incrementTotals(found, filter)
      facMap[exchangeName] += 1
      // now add to system totals
      found = result.find(item => item.facility.exchangeName === 'System')
      incrementTotals(found, filter)
    }

    filters.forEach(filter => {
      findAndIncrement(filter, filter.facility.exchangeName)
    })


    // if there is only one facility in result in addition to the System item
    // then we only need the Facility one at index 1
    if(result.length === 2) {
      result = result.slice(1)
    } else {
      // remove facilities that had only one unit
      result = result.filter(item =>
        item.facility.exchangeName === 'System' || facMap[item.facility.exchangeName] > 1)
    }

    return filters.concat(result)
  }
  
  render() {
    const { filters, complianceFilters } = this.props

    const newFilters =
      filters.length > 1 ? this.computeFacilitySystemTotals(filters) : filters

    const thirdHeight = Math.floor((window.innerHeight - 50 - 38) / 4) //account for title bar and filter view
    const heightNeeded = newFilters.length * 38 + 1
   

    // todo: test on mobile, but I would guess that this is not needed on mobile since scroll bar rides on top of view
    const scrollbarNeeded = thirdHeight < heightNeeded
    let cnTable = null
    if (scrollbarNeeded) {
      cnTable = 'default-adjustment'
      if (is_Windows) {
        cnTable = 'windows-adjustment'
      } else if (is_Mac && is_Safari) {
        cnTable = 'mac-safari-adjustment'
      } else if (is_Mac && is_Chrome) {
        cnTable = 'mac-chrome-adjustment'
      }
    }

    const plannedLabel = getPlannedLabel() + ' D/C'

    let row = -1

    return (
      <div className="compliance-table">
        <Table
          selectable={false}
          className={cnTable}
          onCellClick={this.handleHeaderClick}>
          <TableBody displayRowCheckbox={false}>
            <TableRow className="cell">
              {this.makeHeader('My Totals')}
              {this.makeHeader('All')}
              {this.makeHeader('Plan')}
              {this.makeHeader('DRG')}
              {this.makeHeader('D/C Arrangements')}
              {this.makeHeader('Progression')}
              {this.makeHeader(plannedLabel)}
            </TableRow>
          </TableBody>
        </Table>
        <Table
          // height={`${tableHeight}px`}
          wrapperStyle={{ maxHeight: 185 }}
          selectable={false}>
          <TableBody displayRowCheckbox={false}>
            {this.sortFilters(newFilters).map(filter => {
              return (
                <TableRow key={filter.department.exchangeName} className="cell">
                  <TableRowColumn className={'cell-nopadding'}>
                    {filter.department.name}
                  </TableRowColumn>
                  <TableRowColumn className={'cell-nopadding'}>
                    <ComplianceTableCell
                      department={filter.department.exchangeName}
                      facility={filter.facility.exchangeName}
                      filter="total_issues"
                      selected={
                        filter.department.exchangeName ===
                          this.props.department &&
                        'total_issues' === this.props.filter
                       }
                      handleQueryUpdate={this.props.handleQueryUpdate}>
                      {filter.totalIssues}
                    </ComplianceTableCell>
                  </TableRowColumn>
                  <TableRowColumn className={'cell-nopadding'}>
                    <ComplianceTableCell
                      department={filter.department.exchangeName}
                      facility={filter.facility.exchangeName}
                      filter="plan"
                      selected={
                        filter.department.exchangeName ===
                          this.props.department && 'plan' === this.props.filter
                      }
                      handleQueryUpdate={this.props.handleQueryUpdate}>
                      {filter.plans}
                    </ComplianceTableCell>
                  </TableRowColumn>
                  <TableRowColumn className={'cell-nopadding'}>
                    <ComplianceTableCell
                      department={filter.department.exchangeName}
                      facility={filter.facility.exchangeName}
                      filter="drg"
                      selected={
                        filter.department.exchangeName ===
                          this.props.department && 'drg' === this.props.filter
                      }
                      handleQueryUpdate={this.props.handleQueryUpdate}>
                      {filter.drgs}
                    </ComplianceTableCell>
                  </TableRowColumn>
                  <TableRowColumn className={'cell-nopadding'}>
                    <ComplianceTableCell
                      department={filter.department.exchangeName}
                      facility={filter.facility.exchangeName}
                      filter="discharge_arrangements"
                      selected={
                        filter.department.exchangeName ===
                          this.props.department &&
                        'discharge_arrangements' === this.props.filter
                      }
                      handleQueryUpdate={this.props.handleQueryUpdate}>
                      {filter.dischargeArrangements}
                    </ComplianceTableCell>
                  </TableRowColumn>
                  <TableRowColumn className={'cell-nopadding'}>
                    <ComplianceTableCell
                      department={filter.department.exchangeName}
                      facility={filter.facility.exchangeName}
                      filter="progression"
                      selected={
                        filter.department.exchangeName ===
                          this.props.department &&
                        'progression' === this.props.filter
                      }
                      handleQueryUpdate={this.props.handleQueryUpdate}>
                      {filter.progression}
                    </ComplianceTableCell>
                  </TableRowColumn>
                  <TableRowColumn className={'cell-nopadding'}>
                    <ComplianceTableCell
                      department={filter.department.exchangeName}
                      facility={filter.facility.exchangeName}
                      filter="planned_discharge"
                      selected={
                        filter.department.exchangeName ===
                          this.props.department &&
                        'planned_discharge' === this.props.filter
                      }
                      handleQueryUpdate={this.props.handleQueryUpdate}>
                      {filter.plannedDischarges}
                    </ComplianceTableCell>
                  </TableRowColumn>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}
