import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './escalation.scss'

import Subheader from 'material-ui/Subheader'
import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton'
import { filterConstants } from '../../util/filters'

const AdmissionFilter = ({ onChange, valueSelected, defaultSelected }) => {
  return (
    <div className="filter-container">
      <Subheader className="filter-subheader">Admission Type</Subheader>
         <RadioButtonGroup
          key="filterAdmissionType"
          name="filterAdmissionType"
          valueSelected={valueSelected}
          className="filter-items"
          defaultSelected={defaultSelected}>
          <RadioButton
            value={filterConstants.admissionType.allValue}
            label="All"
            onClick={onChange}
          />
          <RadioButton
            value={filterConstants.admissionType.inpatientValue}
            label="Inpatient"
            onClick={onChange}
          />
          <RadioButton
            value={filterConstants.admissionType.outObsValue}
            label="Obs/Out"
            onClick={onChange}
          />
        </RadioButtonGroup>
    </div>
  )
}

AdmissionFilter.propTypes = {
  onChange: PropTypes.func,
  valueSelected: PropTypes.string,
  defaultSelected: PropTypes.string,
}

export default AdmissionFilter
