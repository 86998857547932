// this used to be used for both Filters and Sorting
// But then the filters were replaced by the ComplianceTable component
// So this just has the sorting left

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Avatar } from 'material-ui'
import Toggle from 'material-ui/Toggle'

import {
  cyan500 as FilterSelectedColor,
  grey400 as DefaultColor,
} from 'material-ui/styles/colors'

import { ClChip } from '../common'

import './compliance.scss'

const SectionLabel = 'Section'
const SortLabel = 'Sort'
const LocationLabel = 'Location'
const LOSLabel = 'Current LOS'
const SortSection = SortLabel + SectionLabel

export class ComplianceFilter extends Component {
  static propTypes = {
    complianceFilters: PropTypes.array,
    items: PropTypes.array,
    onDataItemClick: PropTypes.func,
    onSortOrderToggle: PropTypes.func,
    orderBy: PropTypes.string.isRequired,
    sort: PropTypes.string,
    onFilterSectionClick: PropTypes.func
  }

  handleFilterChange = item => {
    if (item.data) {
      this.props.onDataItemClick(item.data)
    }
  }

  handleSortOrderToggle = (event, toggled) => {
    this.props.onSortOrderToggle(toggled)
  }

  makeFilterChip(config) {
    let selected = false

    selected = this.props.orderBy === config.data.id
    const bc = selected ? FilterSelectedColor : config.backgroundColor
    return (
      <span className="filter-item" key={config.label}>
        <ClChip
          backgroundColor={config.color}
          onClick={
            () => {
              this.props.onFilterSectionClick(config.data.id)
            } 
          }>
          <Avatar color="black" backgroundColor={bc}>
            {Array.isArray(config.data.value)
              ? config.data.value.length
              : config.data.value}
          </Avatar>
          {config.label}
        </ClChip>
      </span>
    )
  }

  makeSortSection() {
    let chipConfigs = [
      {
        label: LocationLabel,
        backgroundColor: DefaultColor,
        data: {
          id: LocationLabel,
          section: SortSection,
          deselectChildrenIds: [LOSLabel],
          value: ' ',
        },
        onClick: this.handleFilterChange,
      },
      {
        label: LOSLabel,
        backgroundColor: DefaultColor,
        data: {
          id: LOSLabel,
          section: SortSection,
          deselectChildrenIds: [LocationLabel],
          value: ' ',
        },
        onClick: this.handleFilterChange,
      },
    ]
    const sortLabel = this.props.sort === 'asc' ? "Ascending" : "Descending"

    return (
      <div className="filter-section">
        <div className="row">
          <div className="chip-column col-xs-12">
            <span style={{ paddingLeft: '5px', marginTop: '10px' }}>
              Sort By:
            </span>
            {chipConfigs.map(config => this.makeFilterChip(config))}
            <span style={{ paddingLeft: '15px', marginTop: '9px' }}>
              <Toggle
                labelPosition="right"
                label={sortLabel}
                onToggle={this.handleSortOrderToggle}
              />
            </span>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <div className="filter-container">{this.makeSortSection()}</div>
  }
}
