import React from 'react'
import PropTypes from 'prop-types'

import { IconMenu, IconButton, MenuItem } from 'material-ui'

import { BarrierStatusIcon } from './BarrierStatusIcon'

export const BarrierStatus = ({ value, onChange }) => {
  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <span
      onClick={e => handleClick(e)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <IconMenu
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        iconButtonElement={
          <IconButton style={{
            width: 28,
            height: 28,
            border: '1px solid #b3b3b3',
            borderRadius: '5px',
            padding: '1px' }}>
            <BarrierStatusIcon height={24} width={24} value={value} />
          </IconButton>
        }
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onItemClick={(event, child) => onChange(child.props.value)}>
        <MenuItem
          value="Open"
          primaryText="Open"
          leftIcon={<BarrierStatusIcon value="Open" />}
        />
        <MenuItem
          value="Resolved"
          primaryText="Resolve"
          leftIcon={<BarrierStatusIcon value="Resolved" />}
        />
      </IconMenu>
    </span>
  )
}

BarrierStatus.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
