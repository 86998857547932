import React from 'react'
import PropTypes from 'prop-types'

const TrackColumn = ({ track }) => {
  const getTrackClassName = (track) => {
    let cssClass
    switch (track) {
      case 'Progression':
        cssClass = 'progression'
        break
      case 'Discharge':
        cssClass = 'discharge'
        break
      case 'T':
        cssClass = 'task'
        break
    }
    return cssClass
  }

  return (
    <td className="trackColumnCell">
      <span className={getTrackClassName(track)}>{track.charAt(0)}</span>
    </td>
  )
}

TrackColumn.propTypes = {
  track: PropTypes.string.isRequired
}

export default TrackColumn
