import React from 'react'
import PropTypes from 'prop-types'
import Chip from 'material-ui/Chip'

import './AppliedFilters.scss'

const AppliedFilters = (
  {
    deleteDueDateFilter,
    deleteTrackFilter,
    deleteStatusFilter,
    filter
  }) => {

  const getStatusText = (status) => {
    let statusText
    switch (status) {
      case 'All':
        statusText = 'All Statuses'
        break
      case 'NA':
        statusText = 'N/A Status'
        break
      default:
        statusText = status + ' Status'
    }
    return statusText
  }

  return (
    <span className="appliedFilters">
      <span>Filters:</span>
      <span className="pillWrapper">
        <Chip
          className="filter-chip"
          onRequestDelete={filter.dueDate !== 'Current' ? deleteDueDateFilter : null}>
          {
            filter.dueDate === 'Current' ?
              'Current + Overdue Due Dates' :
              filter.dueDate + ' Due Dates'
          }
        </Chip>
      </span>
      <span className="pillWrapper">
        <Chip
          className="filter-chip"
          onRequestDelete={filter.track !== 'All' ? deleteTrackFilter : null}>
          {
            filter.track === 'All' ?
              'All Tracks' :
              filter.track + ' Track'
          }
        </Chip>
      </span>
      <span className="pillWrapper">
        <Chip
          className="filter-chip"
          onRequestDelete={filter.status !== 'All' ? deleteStatusFilter : null}>
          { getStatusText(filter.status) }
        </Chip>
      </span>
    </span>
  )
}

AppliedFilters.propTypes = {
  deleteDueDateFilter: PropTypes.func,
  deleteTrackFilter: PropTypes.func,
  deleteStatusFilter: PropTypes.func,
  filter: PropTypes.object
}

export default AppliedFilters
