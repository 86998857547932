import React, {Component} from 'react'
import VisibilityBoardRowList from "../../components/visibilityBoard/VisibilityBoardRowList"
import {showDrawer} from '../../ducks/views'
import {drawers} from '../../containers/drawer-component-mapping'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import IconButton from 'material-ui/IconButton'
import OpenDrawer from 'material-ui/svg-icons/content/add-circle-outline'
import PropTypes from 'prop-types'
import { showErrorsPg } from '../../ducks/errorspg'
import {environment} from "../../graphql/relay/env"
import { deleteVisibilityBoardEntry } from "../../graphql/relay/queries/visibilityBoard"
import { handleMutation } from '../../graphql/relay/queries/mutation'
import MenuItem from 'material-ui/MenuItem'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
        showErrorsPg
      },
      dispatch
    ),
  }
}

const rowStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingTop: "15px",
  paddingBottom: "15px"
}

const columnStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "5px",
  paddingBottom: "5px"
}


class VisibilityBoardEditor extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    listTemplates: PropTypes.arrayOf(PropTypes.object),
    listFacilities: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
      showErrorsPg: PropTypes.func,
    }),
  }
  constructor(props) {
    super(props)

    this.departmentMap = {}
    this.departmentItems = []
    this.bedMap = {}

    props.listFacilities.forEach((facility, index) => {
      facility.departments.nodes.forEach((department, index) => {
        this.departmentItems.push(
          <MenuItem key={department.exchangeName} value={department.exchangeName} primaryText={department.name} />
        )
        this.departmentMap[department.exchangeName] = department.name

        department.beds.nodes.forEach((bed, index) => {
          if (this.bedMap[department.exchangeName]) {
            this.bedMap[department.exchangeName].push({
              name: bed.name,
              exchangeName: bed.exchangeName
            })
          } else {
            this.bedMap[department.exchangeName] = [{
              name: bed.name,
              exchangeName: bed.exchangeName
            }]
          }
        })
      })
    })
  }

  onEditHandler = (rowData) => {
    this.props.actions.showDrawer(drawers.EDIT_VISIBILITY_BOARD_ENTRY, {
      title: "Edit " + this.props.title,
      data: {
        ...rowData,
        templates: this.props.listTemplates,
        departmentItems: this.departmentItems,
        bedMap: this.bedMap
      }
    })
  }

  onDeleteHandler = (rowData) => {
    const variables = {
      visibilityBoardId: rowData.id
    }

    const updateAfterDeletion = (proxyStore) => {
      const store = proxyStore.getRootField("visibilityBoardMutation")
      const payload = store.getLinkedRecords("deleteVisibilityBoardEntry", variables)
      const rootStore = proxyStore.get("client:root")
      const visibilityBoardsList = rootStore.getLinkedRecords('visibilityBoards')
      rootStore.setLinkedRecords(payload,'visibilityBoards')
    }

    handleMutation(
      environment,
      deleteVisibilityBoardEntry,
      variables,
      undefined,
      undefined,
      undefined,
      updateAfterDeletion
    )
  }

  onAddHandler = () => {
    this.props.actions.showDrawer(drawers.ADD_VISIBILITY_BOARD_ENTRY, {
      title: "Add " + this.props.type,
      data: {
        templates: this.props.listTemplates,
        departmentItems: this.departmentItems,
        bedMap: this.bedMap
      }
    })
  }


  render() {
    return (
      <div style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
        <h1 style={{display: "inline"}}>{this.props.title}</h1>
        <IconButton
          style={{padding: 8, width: 40, height: 40 }}
          onClick={this.onAddHandler}>
          <OpenDrawer />
        </IconButton>
        <table style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}>
          <colgroup>
            <col width="3%"/>
            <col width="7%"/>
            <col width="15%"/>
            <col width="20%"/>
            <col width="20%"/>
            <col width="3%"/>
            <col width="6%"/>
            <col width="6%"/>
            <col width="6%"/>
            <col width="11%"/>
            <col width="3%"/>
          </colgroup>
          <thead style={{fontSize: "16px"}}>
            <tr style={rowStyle}>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
              <th style={columnStyle} bgcolor="#d3d3d3">Name</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Description</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Template</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Footer</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Scroll Duration(s)</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Time Header Format</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Location Statuses Display</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Department</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Number of Beds</th>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
            </tr>
          </thead>
          <tbody>
            <VisibilityBoardRowList list={this.props.list} departmentMap={this.departmentMap} onEdit={this.onEditHandler} onDelete={this.onDeleteHandler}/>
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(VisibilityBoardEditor)
