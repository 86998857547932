/**
 A collection of sort functions for sorting on common attributes shared amongst
 different entities.
 */

export const toSortableString = number => {
  if (typeof number === 'number') {
    let pad = ''
    if (number < 10) {
      pad = '000'
    } else if (number < 100) {
      pad = '00'
    } else if (number < 1000) {
      pad = '0'
    }
    return pad + number
  }
  return number
}

/**
 * Sorts the dates in Descending order (newest first).
 *
 * @param a First item containing a 'auditMeta.created' property
 * @param b Second item containing a 'auditMeta.created' property
 */
export const auditCreatedCompareDescending = (a, b) =>
  b.auditMeta.created.localeCompare(a.auditMeta.created)

/**
 * Sorts the dates in Descending order (newest first).
 *
 * @param a First item containing a 'auditMeta.updated' property
 * @param b Second item containing a 'auditMeta.updated' property
 */
export const auditUpdatedCompareDescending = (a, b) =>
  b.auditMeta.updated.localeCompare(a.auditMeta.updated)

/**
 * Sorts the dates in Descending order (newest first).
 *
 * @param a First item containing a 'auditMeta.updated' or 'auditMeta.created' property
 * @param b Second item containing a 'auditMeta.updated' or 'auditMeta.created' property
 */
export const auditUpdatedOrCreatedCompareDescending = (a, b) => {
  const aTime = a.auditMeta.updated ? a.auditMeta.updated : a.auditMeta.created
  const bTime = b.auditMeta.updated ? b.auditMeta.updated : b.auditMeta.created
  return bTime.localeCompare(aTime)
}

/**
 * Sorts the dates in Descending order (newest first).
 *
 * @param a First item containing a 'dueDate' property
 * @param b Second item containing a 'dueDate' property
 */
export const dueDateCompareDescending = (a, b) =>
  b.dueDate.localeCompare(a.dueDate)

/**
 * Sorts the dates in Ascending order (oldest first).
 *
 * @param a First item containing a 'auditMeta.created' property
 * @param b Second item containing a 'auditMeta.created' property
 */
export const auditCreatedCompareAscending = (a, b) =>
  a.auditMeta.created.localeCompare(b.auditMeta.created)

/**
 * Sorts the dates in Ascending order (oldest first).
 *
 * @param a First item containing a 'auditMeta.updated' property
 * @param b Second item containing a 'auditMeta.updated' property
 */
export const auditUpdatedCompareAscending = (a, b) =>
  a.auditMeta.updated.localeCompare(b.auditMeta.updated)

/**
 * Sorts the dates in Ascending order (oldest first).
 *
 * @param a First item containing a 'auditMeta.updated' or 'auditMeta.created' property
 * @param b Second item containing a 'auditMeta.updated' or 'auditMeta.created' property
 */
export const auditUpdatedOrCreatedCompareAscending = (a, b) => {
  const aTime = a.auditMeta.updated ? a.auditMeta.updated : a.auditMeta.created
  const bTime = b.auditMeta.updated ? b.auditMeta.updated : b.auditMeta.created
  return aTime.localeCompare(bTime)
}

/**
 * Sorts the dates in Ascending order (oldest first).
 *
 * @param a First item containing a 'dueDate' property
 * @param b Second item containing a 'dueDate' property
 */
export const dueDateCompareAscending = (a, b) =>
  a.dueDate.localeCompare(b.dueDate)

/**
 * Sorts the status in the following order (Open, Completed, NA).
 *
 * @param a First item containing a 'status' property
 * @param b Second item containing a 'status' property
 */
export const statusOpenCompletedNACompare = (a, b) => {
  let result = 1 // 1 a after b
  if (a.status === b.status) {
    result = 0 // equal
  } else if (
    a.status === 'Open' ||
    (a.status === 'Completed' && b.status === 'NA')
  ) {
    result = -1 // a before b
  }
  return result
}

/**
 * Sorts the status in the following order (Open, Resolved).
 *
 * @param a First item containing a 'status' property
 * @param b Second item containing a 'status' property
 */
export const statusOpenResolvedCompare = (a, b) => {
  let result = 1 // 1 a after b
  if (a.status === b.status) {
    result = 0 // equal
  } else if (a.status === 'Open') {
    result = -1 // a before b
  }
  return result
}

/**
 * Sorts the status in the following order (escalated, not escalated).
 *
 * @param a First item containing a 'escalation' property
 * @param b Second item containing a 'escalation' property
 */
export const escalationCompare = (a, b) => {
  const aEscalated = Boolean(a.escalation)
  const bEscalated = Boolean(b.escalation)
  let result = 1 // 1 a after b
  if (aEscalated === bEscalated) {
    result = 0 // equal
  } else if (aEscalated) {
    result = -1 // a before b
  }
  return result
}

/**
 * Sorts the type in the following order (Barrier, action, note).
 *
 * @param a First item containing a 'type' property
 * @param b Second item containing a 'type' property
 */
export const typeBarrierActionNoteCompare = (a, b) => {
  let result = 1 // 1 a after b
  if (a.type === b.type) {
    result = 0 // equal
  } else if (
    a.type === 'barrier' ||
    (a.type === 'action' && b.type === 'note')
  ) {
    result = -1 // a before b
  }
  return result
}

/**
 * Groups the status in the following order (Open, Completed, NA).
 *
 * @param items List of items containing a 'status' property
 */
export const groupOpenCompletedNA = items => {
  let openItems = [],
    completedItems = [],
    naItems = []
  items.forEach(item => {
    item.status === 'Open' && openItems.push(item)
    item.status === 'Completed' && completedItems.push(item)
    item.status === 'NA' && naItems.push(item)
  })
  return openItems.concat(completedItems.concat(naItems))
}

/**
 * Groups the status in the following order (Open, Resolved).
 *
 * @param items List of items containing a 'status' property
 */
export const groupOpenResolved = items => {
  let openItems = [],
    resolvedItems = []

  items.forEach(item => {
    item.status === 'Open' && openItems.push(item)
    item.status === 'Resolved' && resolvedItems.push(item)
  })

  return openItems.concat(resolvedItems)
}

/**
 * Sorts the dept names in Ascending order.
 *
 * @param a First item containing a 'dept' property
 * @param b Second item containing a 'dept' property
 */
export const deptNameCompareAscending = (a, b) =>
  a.dept.name.localeCompare(b.dept.name)

/**
 * Sorts the dept names in Descending order.
 *
 * @param a First item containing a 'dept' property
 * @param b Second item containing a 'dept' property
 */
export const deptNameCompareDescending = (a, b) =>
  b.dept.name.localeCompare(a.dept.name)

/**
 * Sorts the bed names in Ascending order.
 *
 * @param a First item containing a 'bed' property
 * @param b Second item containing a 'bed' property
 */
export const bedNameCompareAscending = (a, b) =>
  a.bed.name.localeCompare(b.bed.name)

/**
 * Sorts the bed names in Descending order.
 *
 * @param a First item containing a 'bed' property
 * @param b Second item containing a 'bed' property
 */
export const bedNameCompareDescending = (a, b) =>
  b.bed.name.localeCompare(a.bed.name)

/**
 * Sorts by the visit tlos in Ascending order.
 *
 * @param a First item containing a 'visit' property
 * @param b Second item containing a 'visit' property
 */

export const tlosCompareAscending = (a, b) => {
  if (!a.visit.tlos) {
    return 1 // a is null so return 1 since a after b
  }
  if (!b.visit.tlos) {
    return -1 // b is null so return -1 since b after a
  }

  let result = 1 // 1 a after b
  if (a.visit.tlos === b.visit.tlos) {
    result = 0 // equal
  } else if (a.visit.tlos < b.visit.tlos) {
    result = -1 // a before b
  }
  return result
}

/**
 * Sorts by the visit currentDayOfStay in Ascending order.
 *
 * @param a First item containing a 'visit' property
 * @param b Second item containing a 'visit' property
 */

export const currentDayOfStayCompareAscending = (a, b) => {
  const aInpatient = a.visit.admissionType === 'InPatient'
  const bInpatient = b.visit.admissionType === 'InPatient'

  if (aInpatient && bInpatient) {
    if (!a.visit.currentDayOfStay) {
      return 1 // a is null so return 1 since a after b
    }
    if (!b.visit.currentDayOfStay) {
      return -1 // b is null so return -1 since b after a
    }

    let result = 1 // 1 a after b
    if (a.visit.currentDayOfStay === b.visit.currentDayOfStay) {
      result = 0 // equal
    } else if (a.visit.currentDayOfStay < b.visit.currentDayOfStay) {
      result = -1 // a before b
    }
    return result
  }

  if (aInpatient) {
    return 1
  }

  if (bInpatient) {
    return -1
  }

  if (!a.visit.decisionTime) {
    return -1
  }

  if (!b.visit.decisionTime) {
    return 1
  }

  return b.visit.decisionTime.localeCompare(a.visit.decisionTime)
}

/**
 * Sorts by the visit currentDayOfStay in Descending order.
 *
 * @param a First item containing a calculated 'forecastExcessDays' property
 * @param b Second item containing a calculated 'forecastExcessDays' property
 */

export const forecastExcessDaysCompareDescending = (a, b) => {
  return b.forecastExcessDays - a.forecastExcessDays
}

/**
 * Sorts by the visit currentDayOfStay in Descending order.
 *
 * @param a First item containing a 'visit' property
 * @param b Second item containing a 'visit' property
 */

export const currentDayOfStayCompareDescending = (a, b) => {
  return b.visit.currentDayOfStay - a.visit.currentDayOfStay
}

/**
 * Sorts by the visit decisionTime in Descending order.
 *
 * @param a First item containing a 'visit' property
 * @param b Second item containing a 'visit' property
 */

export const visitDecisionTypeCompareAscending = (a, b) => {
  if (a.visit.decisionTime && b.visit.decisionTime) {
    return a.visit.decisionTime.localeCompare(b.visit.decisionTime)
  } else if (!a.visit.decisionTime) {
    return 1
  } else {
    return -1
  }
}

/**
 * Sorts by the plan.meta.actualOEin Descending order.
 *
 * @param a First item containing a 'plan.meta' property
 * @param b Second item containing a 'plan.meta' property
 */

export const actualOECompareDescending = (a, b) => {
  return b.plan.meta.actualOE - a.plan.meta.actualOE
}

/**
 * Sorts by the visit currentDayOfStay in Descending order.
 *
 * @param a First item containing a calculated 'forecastWorkingOE' property
 * @param b Second item containing a calculated 'forecastWorkingOE' property
 */

export const forecastWorkingOECompareDescending = (a, b) => {
  if (b.forecastWorkingOE !== '?' && a.forecastWorkingOE !== '?') {
    return b.forecastWorkingOE - a.forecastWorkingOE
  } else if (a.forecastWorkingOE === '?') {
    return 1
  } else {
    return -1
  }
}

/**
 * Sort by Department name and Bed name
 * Next 2 sorts changed to handle AdmissionSource Beds for CA-1443
 */

export const departmentNameBedNameCompareAscending = (a, b) => {
  return (
    a.department.type.localeCompare(b.department.type) ||
    a.department.name.localeCompare(b.department.name) ||
    a.bed.name.localeCompare(b.bed.name)
  )
}

// We are finally sorting the Units/AdmissionSources in the Escalation List Filter
export const departmentListCompareAscending = (a, b) => {
  return a.type.localeCompare(b.type) || a.name.localeCompare(b.name)
}

export const watcherSort = (a, b) => {
  if (a.isOwner) return -1
  if (b.isOwner) return 1
  if (a.knownUser.lastName < b.knownUser.lastName) return -1
  if (a.knownUser.lastName > b.knownUser.lastName) return 1
  if (a.knownUser.firstName < b.knownUser.firstName) return -1
  if (a.knownUser.firstName > b.knownUser.firstName) return 1
  return 0
}

/**
 * Group array by its property
 *
 * @param a Object property to group the array by
 */
Array.prototype.groupBy = function(prop) {
  return this.reduce(function(groups, item) {
    const val = item[prop]
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}
