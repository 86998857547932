//TODO the following 2 functions can actually be one. Create create a higher order function
//that takes in the name of the node to return whatever(nodeName = 'visit', depth = 2) => (mutation) => node

// This is quite the hack. We don't want to create a normalize function
// for every mutation as each result is named differently. but, what we do
// now is that for the Plan mutations they are all in the shape of
// {mutationNode}.{mutationName}.plan
// These are very fragile. We can't have aliases in there at the moment.
export const visitHandler = mutation => {
  //we could write a recursive function to get n-depth result[2].
  //but do that later.
  const mutationItself = mutation[Object.keys(mutation)[0]]
  const visit = mutationItself[Object.keys(mutationItself)[0]]

  return {
    workspaceData: {
      workspace: {
        visit,
      },
    },
  }
}
// This is quite the hack. We don't want to create a normalize function
// for every mutation as each result is named differently. but, what we do
// now is that for the Plan mutations they are all in the shape of
// {mutationNode}.{mutationName}.plan
// These are very fragile. We can't have aliases in there at the moment.
export const planHandler = mutation => {
  //we could write a recursive function to get n-depth result[2].
  //but do that later.
  const mutationItself = mutation[Object.keys(mutation)[0]]
  const plan = mutationItself[Object.keys(mutationItself)[0]]

  return {
    workspaceData: {
      workspace: {
        plan,
      },
    },
  }
}

export const planHandler_relay = plan => {
  return {
    workspaceData: {
      workspace: {
        plan,
      },
    },
  }
}

export const workspaceHandler = mutation => {
  //we could write a recursive function to get n-depth result[2].
  //but do that later.
  const mutationItself = mutation[Object.keys(mutation)[0]]
  const workspaces = mutationItself[Object.keys(mutationItself)[0]]

  return {
    workspaceData: {
      workspace: { ...workspaces },
    },
  }
}
