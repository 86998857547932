import React from 'react'
import { PatientPlan } from './PatientPlan'
import { QueryRenderer, graphql } from 'react-relay'
import { environment } from '../../graphql/relay/env'
import PropTypes from 'prop-types'
import { LoadingIndicator } from '../../components/loader'

export class PatientPlanGQL extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={PatientPlanQuery}
        variables={{ id: this.props.match.params.id }}
        render={({ error, props, retry }) => {
          if (error) {
            return <div>Error!</div>
          }
          if (!props) {
            return (<LoadingIndicator/>)
          }
          return (<PatientPlan
            visit={props.node}
            userSetting={props.userSetting}
            barrierEscalationRoles={props.barrierEscalationRoles}
            deliveryMethods={props.deliveryMethods}
            refetch={retry}
            {...this.props}/>)
        }}
      />
    )
  }
}
PatientPlanGQL.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

// also used by PatientPlanVNGQL
export const PatientPlanQuery = graphql`
  query PatientPlanGQLQuery($id: ID!) {
    userSetting {
      barriers {
        id
        name
        description
        active
        category {
          code
          value
        }
        templateId
      }
      actions {
        id
        text
      }
      notes {
        id
        text
      }
    }
    barrierEscalationRoles: codeTables (codeTableType:BarrierEscalationRole) {
      code
      value
      ordinal
      active
    }
    deliveryMethods: codeTables (codeTableType: DeliveryMethod) {
      code
      active
    }
    node(id: $id) {
      ... on Visit {
        id
        vector {
          id
          patientId
          encounterId
          predictionDate
          conditions {
            name
            description
            riskBracket
            riskFactors {
              id
              type
              description
            }
            recommendations {
              id
              rank
              description
              code
              accepted
            }
          }
        }
        bed {
          id
          status
          name
          department {
            id
            exchangeName
            name
            type
            includedInLosCalculation
          }
          exchangeName
#          providers {
#            id
#            firstName
#            lastName
#            primary
#            role {
#              id
#              code
#              value
#            }
#          }
        }
        admissionTime
        admissionType
        admittingComplaint
        admittingPhysician {
          firstName
          id
          lastName
          primary
          role {
            code
            id
            value
          }
        }
        anticipatedDischargeTime
        anticipatedDischargeUpdatedWhen
        anticipatedDischargeUpdatedBy {
          userName
          firstName
          lastName
        }
        attendingPhysician {
          firstName
          id
          lastName
          primary
          role {
            code
            id
            value
          }
        }
        bedRequestStatus
        bedRequests {
          id
          bedRequestType
          bedRequestStatus
          updatedWhen
          bedExchangeName
        }

        consultingPhysicians {
          id
          firstName
          lastName
          role {
            id
            code
            value
          }
          primary
        }
        currentDayOfStay
        decisionTime
        decisionTimeEditable
        decisionTimeUseMidnight
        dischargeVendor
        drgAttributes {
          id
          arithmeticLos
          code
          commonPrincipalDX
          commonProcedures
          expectedLos
          geometricLos
          level
          modifyTargetLos
          majorDiagnosisCategory
          readmissionRisk
          targetLos
          title
          type
          weight
        }
        drgUpdatedBy {
          id
          firstName
          lastName
          userName
        }
        drgUpdatedWhen
        homeBedExchangeName
        insurancePlans {
          id
          planName
          priority
          cms
        }
        meta {
          id
          decisionDue
          dischargeToday
          plannedDischargeExceeded
          timeElapsedSinceAdmission
          tlosExceeded
        }
        patient {
          id
          age
          firstName
          gender
          lastName
          middleInitial
          mrn
          language {
              code
              defaultValue
          }
        }
        patientAlerts {
          id
          alertType
          value
        }
        patientProblem
        provider1 {
          id
          firstName
          lastName
          role {
            code
            id
            value
          }
        }
        provider2 {
          id
          firstName
          lastName
          role {
            code
            id
            value
          }
        }

        readmission
        readmissionUpdatedWhen
        readmissionVerifiedBy {
          userName
          firstName
          lastName
        }

        readmissionRisk
        readmissionRiskUpdatedWhen
        readmissionRiskVerifiedBy {
          userName
          firstName
          lastName
        }

        targetDischargeTime
        tlos
        visitNumber
        visitStatus
        workingDischargeDisposition {
          code
          id
          value
        }
        plan {
          id
          actions {
            id
            __typename
            text
            pinned
            status
            auditMeta {
              createdBy {
                id
                userName
                firstName
                lastName
              }
              updatedBy {
                id
                userName
                firstName
                lastName
              }
              created
              updated
            }
          }
          appliedTemplates {
            id
            name
            description
            documentURL
            removed
          }
          barriers {
            id
            __typename
            name
            description
            dischargeDelay
            pinned
            status
            category {
              id
              code
              value
              sourceTypes {
                id
                code
                value
                active
                activeDirectorySourceName
                hasConfiguredList
                sourceDetails {
                  id
                  code
                  value
                  active
                }
              }
            }
            escalation {
              id
              auditMeta {
                createdBy {
                  id
                  userName
                  firstName
                  lastName
                }
                updatedBy {
                  id
                  userName
                  firstName
                  lastName
                }
                created
                updated
              }
            }
            auditMeta {
              createdBy {
                id
                userName
                firstName
                lastName
              }
              updatedBy {
                id
                userName
                firstName
                lastName
              }
              created
              updated
            }
            ownerMessageStatus
            ownerMessageStatusDate
            ownerKnownUser {
              id
              emailReceiver
              instantMessageReceiver
            }
            ownerMessageDeliveryDate
            owner
            ownerRole {
              id
              code
              value
            }
            sourceTypeCode
            sourceName
            notes {
              id
              __typename
              text
              pinned
              auditMeta {
                createdBy {
                  id
                  userName
                  firstName
                  lastName
                }
                updatedBy {
                  id
                  userName
                  firstName
                  lastName
                }
                created
                updated
              }
            }
          }
          careTeamMembers {
            id
            name
            role {
              id
              code
              value
            }
            primary
          }
          meta {
            milestoneMeta {
              overdue
              onTime
              completed
            }
            lengthOfStayMeta {
              forecast
              cappedForecast
            }
            actualOE
          }
          milestones {
            id
            name
            description
            dueDate
            dueDateCutoff
            track
            duplicate
            status
            actions {
              id
              __typename
              pinned
              text
              status
              milestoneTemplateName
              auditMeta {
                createdBy {
                  id
                  userName
                  firstName
                  lastName
                }
                updatedBy {
                  id
                  userName
                  firstName
                  lastName
                }
                created
                updated
              }
            }
            offset {
              basis
              days
              hours
              minutes
            }
            planTemplateName
          }
          notes {
            id
            __typename
            text
            pinned
            auditMeta {
              createdBy {
                id
                userName
                firstName
                lastName
              }
              updatedBy {
                id
                userName
                firstName
                lastName
              }
              created
              updated
            }
          }
          progressionIndicator
          toBeAdmitted
        }
        anticipatedTransferTime
        anticipatedDischargeTimeAutoPopulated
        anticipatedTransferTimeAutoPopulated
      }
    }
  }
`

// export const PatientPlanGQL = compose(
//   graphql(WorkAreaGQL.queries.FacilityBeds, {
//     name: 'facilityData',
//     options: {
//       variables: { assignedBedsOnly: true },
//       fetchPolicy: 'cache-and-network',
//     },
//   }),
//   shouldUpdate((props, nextProps) => {
//     const { facilityData: currentSet } = props
//     const { facilityData: nextSet } = nextProps
//     if (!isEqual(props.match, nextProps.match)) {
//       return true
//     }
//     if (currentSet.loading !== nextSet.loading) {
//       return true
//     }
//     if (!!currentSet.facilities && !!nextSet.facilities) {
//       return !isEqual(currentSet.facilities, nextSet.facilities)
//     }
//     return false
//   }),
//   graphql(WorkspaceGQL.queries.FullWorkspacePlanVisitByVisitIds, {
//     name: 'workspaceData',
//     options: props => {
//       return {
//         variables: {
//           visitIds: [props.match.params.id],
//         },
//         fetchPolicy: 'cache-and-network',
//       }
//     },
//   }),
//   mapProps(props => newProps(props))
// )(PatientPlan)

// const newProps = props => {
//   const { facilityData, workspaceData } = props

//   const jvionEnabled = isJvionEnabled()

//   const loading =
//     !facilityData ||
//     facilityData.loading ||
//     (!workspaceData || workspaceData.loading)

//   return {
//     ...props,
//     loading,
//     jvionEnabled,
//   }
// }
