import gql, {injectForGqlFile} from '../util/gql'

export const CodeTableGQL = {
  fragments: {
    FullCodeTable: gql`
      fragment FullCodeTable on CodeTable {
        id: code
        code
        value
        defaultValue
        active
        modifiable
        ordinal
      }
    `,
    CodeValueActive: gql`
      fragment CodeValueActive on CodeTable {
        id: code
        code
        value
        active
      }
    `,
    CodeValue: gql`
      fragment CodeValue on CodeTablePair {
        id: code
        code
        value
      }
    `,
  },
  queries: {
    codeTables: gql`
      query codeTables($codeTableType: CodeType!) {
        codeTables(codeTableType: $codeTableType) {
          ...CodeValueActive
        }
      }
    `,
    barrierCategories: gql`
      query barrierCategories {
        codeTables(codeTableType: BarrierCategory) {
          ...CodeValueActive
          ordinal
        }
      }
    `,
    barrierEscalationRoles: gql`
      query barrierEscalationRoles {
        codeTables(codeTableType: BarrierEscalationRole) {
          ...CodeValueActive
          ordinal
        }
      }
    `,

    planCategories: gql`
      query planCategories {
        codeTables(codeTableType: PlanCategory) {
          ...CodeValueActive
        }
      }
    `,
  },
  mutations: {},
}

injectForGqlFile(CodeTableGQL)
