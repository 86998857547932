import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AcknowledgedIcon from '../../image/img/acknowledged-icon.svg'
import NeedsReassignmentIcon from '../../image/img/needs-reassignment-icon.svg'
import UnacknowledgedIcon from '../../image/img/unacknowledged-icon.svg'
import Tooltip from '@material-ui/core/Tooltip'

import './BarrierMessageStatusIcon.scss'
import {ACKNOWLEDGED_MSG_STATUS, NEEDS_REASSIGNMENT_MSG_STATUS, UNACKNOWLEDGED_MSG_STATUS} from '../BarrierConstants'

const BarrierMessageStatusIcon = ({ messageStatus, messageStatusDate, messageStatusCount, showTooltip = false }) => {
  let commStatusImage
  let commStatusIconCss = 'CommMsgStatusIcon'
  let commStatusText
  let commStatusDate = messageStatusDate ? moment(messageStatusDate).format('MMMM Do, YYYY H:mm') : null
  switch (messageStatus) {
    case ACKNOWLEDGED_MSG_STATUS:
      commStatusImage = AcknowledgedIcon
      commStatusText = 'Acknowledged on ' + commStatusDate
      break
    case NEEDS_REASSIGNMENT_MSG_STATUS:
      commStatusImage = NeedsReassignmentIcon
      commStatusText = 'Needs reassignment on ' + commStatusDate
      commStatusIconCss = 'CommMsgStatusNeedsReassign'
      break
    case UNACKNOWLEDGED_MSG_STATUS:
      commStatusImage = UnacknowledgedIcon
      commStatusText = 'Unacknowledged as of ' + commStatusDate
      break
    default:
      commStatusImage = UnacknowledgedIcon
      commStatusText = null
  }

  const commStatusIcon = (
    <div className={'CommMsgStatusIconWrapper'}>
      <img className={commStatusIconCss} src={commStatusImage} />
      { messageStatusCount ? messageStatusCount : null }
    </div>
  )

  const commStatusIconWithTooltip = (
    <Tooltip title={commStatusText} placement={'bottom-end'}>
      {commStatusIcon}
    </Tooltip>)

  if (commStatusIcon && showTooltip) {
    return commStatusIconWithTooltip
  } else {
    return commStatusIcon
  }
}

BarrierMessageStatusIcon.propTypes = {
  messageStatus: PropTypes.string,
  messageStatusDate: PropTypes.string,
  messageStatusCount: PropTypes.number,
  showTooltip: PropTypes.bool
}

export default BarrierMessageStatusIcon
