/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CodeTableRow_rowData$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CodeTableRowList_list$ref: FragmentReference;
declare export opaque type CodeTableRowList_list$fragmentType: CodeTableRowList_list$ref;
export type CodeTableRowList_list = $ReadOnlyArray<{|
  +$fragmentRefs: CodeTableRow_rowData$ref,
  +$refType: CodeTableRowList_list$ref,
|}>;
export type CodeTableRowList_list$data = CodeTableRowList_list;
export type CodeTableRowList_list$key = $ReadOnlyArray<{
  +$data?: CodeTableRowList_list$data,
  +$fragmentRefs: CodeTableRowList_list$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CodeTableRowList_list",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodeTableRow_rowData"
    }
  ],
  "type": "CodeTable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9ed4d66aacc0d7049d06ea3483ce0a08';

module.exports = node;
