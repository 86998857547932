/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { compose} from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import './views.scss'
import { FixedView } from './FixedView'
import {
  HeaderSection,
  FilterSection,
  ExcessDaysSection,
  SortSection,
  WorkspaceSection,
} from '../components/kpi'
import {
  pushUniqueToArray,
  pushUniqueWithPropertyToArray,
  pushToArray,
  pushToArrays,
} from '../util/utils'
import { calcForecastExcessDays } from '../util/workspace'
import { selectItem } from '../ducks/app-selections'
import {
  updateKpiCapped,
  updateKpiFilters,
  updateKpiDimension,
  updateKpiChartSelection,
  updateKpiSort,
  updateKpiFacility,
  updateKpiFLOSFilter,
  updateKpiExcessDaysFilter,
  updateNavigationWorkspaces,
  updateNavigationWorkspacesQuery,
} from '../ducks/views'
import { fetchQuery, graphql } from 'relay-runtime'
import { environment } from '../graphql/relay/env'
import classNames from 'classnames'
import get from 'lodash.get'
import {getConfig,  getConfigBoolean} from "../util/configs"
import { LoadingIndicator } from '../components/loader'

const SectionLabel = 'Section'
const ProgressionLabel = 'Progression'
const PlansLabel = 'Plans'
const CategoriesLabel = 'Barriers'
const NotSetLabel = 'Not Set'
const OnTimeLabel = 'On Time'
const AtRiskLabel = 'At Risk'
const HighRiskLabel = 'High Risk'
const ProgressionNotSet = ProgressionLabel + NotSetLabel
const ProgressionOnTime = ProgressionLabel + OnTimeLabel
const ProgressionAtRisk = ProgressionLabel + AtRiskLabel
const ProgressionHighRisk = ProgressionLabel + HighRiskLabel
const CategoriesSection = CategoriesLabel + SectionLabel
const PlansSection = PlansLabel + SectionLabel
const ProgressionSection = ProgressionLabel + SectionLabel

function mapStateToProps(state) {
  return {
    selected: state.selectAppState,
    kpiFacility: state.viewsAppState.kpiFacility,
    kpiCapped: state.viewsAppState.kpiCapped,
    kpiFilters: state.viewsAppState.kpiFilters,
    kpiDimension: state.viewsAppState.kpiDimension,
    kpiChartSelection: state.viewsAppState.kpiChartSelection,
    kpiSort: state.viewsAppState.kpiSort,
    kpiFLOSFilter: state.viewsAppState.kpiFLOSFilter,
    kpiExcessDaysFilter: state.viewsAppState.kpiExcessDaysFilter,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectItem,
        updateKpiFacility,
        updateKpiCapped,
        updateKpiFilters,
        updateKpiDimension,
        updateKpiChartSelection,
        updateKpiSort,
        updateKpiFLOSFilter,
        updateKpiExcessDaysFilter,
        updateNavigationWorkspaces,
        updateNavigationWorkspacesQuery,
      },
      dispatch
    ),
  }
}

export class KpiList extends React.Component {
  static propTypes = {
    actions: PropTypes.shape({
      selectItem: PropTypes.func.isRequired,
      updateKpiFacility: PropTypes.func,
      updateKpiCapped: PropTypes.func,
      updateKpiFilters: PropTypes.func,
      updateKpiDimension: PropTypes.func,
      updateKpiChartSelection: PropTypes.func,
      updateKpiSort: PropTypes.func,
      updateKpiFLOSFilter: PropTypes.func,
      updateKpiExcessDaysFilter: PropTypes.func,
      updateNavigationWorkspaces: PropTypes.func,
      updateNavigationWorkspacesQuery: PropTypes.func,
    }),

    selected: PropTypes.object.isRequired,

    kpiFacility: PropTypes.object,
    kpiCapped: PropTypes.bool,
    kpiFilters: PropTypes.object,
    kpiDimension: PropTypes.string,
    kpiChartSelection: PropTypes.object,
    kpiSort: PropTypes.object,
    kpiFLOSFilter: PropTypes.object,
    kpiExcessDaysFilter: PropTypes.object,

    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        facility: PropTypes.object,
        department: PropTypes.object,
        bed: PropTypes.object,
        visit: PropTypes.object,
        plan: PropTypes.object,
      })
    ),
    kpi: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        exchangeName: PropTypes.string,
        capped: PropTypes.object,
        uncapped: PropTypes.object,
      })
    ),
  }

  // ************************************************************************
  // zzz Life Cycle
  // ************************************************************************
  constructor() {
    super()
    this.state = {
      data: undefined,
    }
  }

  componentWillMount() {
    // this.clearFilters()
    //Todo: Below scrolls the view back to the top - Is this the right thing to do
    const inner = document.querySelector('.inner-container')
    inner && (inner.scrollTop = 0)
  }

  componentDidMount() {

    // flat is used in render and this is a polyfill for IE11
    if (!Array.prototype.flat) {
      Array.prototype.flat = function(depth) {
        let flattend = [];
        (function flat(array, depth) {
          for (let el of array) {
            if (Array.isArray(el) && depth > 0) {
              flat(el, depth - 1)
            } else {
              flattend.push(el)
            }
          }
        })(this, Math.floor(depth) || 1)
        return flattend
      }
    }

    if (!this.state.data && !this.loading) {
      this.loading = true
      const variables = {
        first: 10000,
      }
      fetchQuery(environment, KpiViewQuery, variables).then(data => {
        //console.log('KpiView Data: ', data)
        this.loading = false
        this.setState({ data: data })
      })
    }
  }


  componentDidUpdate() {
    this.props.actions.updateNavigationWorkspaces(this.selectedItems)
    this.props.actions.updateNavigationWorkspacesQuery({
      type: 'KPI',
      query: null,
    })
  }

  loading = false

  // ************************************************************************
  // zzz Handle Top Section
  // ************************************************************************
  handleFacilityChange = (e, key, facilityName) => {

    const facility = this.state.data.kpi.find(
      kpiFacility => kpiFacility.name === facilityName
    )
    this.resetPartial()
    this.props.actions.updateKpiFacility(facility)
  }

  handleCappedToggle = (e, capped) => {
    this.resetPartial()
    this.props.actions.updateKpiCapped(capped)
  }

  // ************************************************************************
  // zzz Handle Filter Section
  // ************************************************************************
  handleFilterClick = newFilter => {
    this.updateKpiChartSelection()

    this.resetFLOSFilter()
    this.resetExcessDaysFilter()

    this.props.actions.updateKpiFilters(newFilter ? newFilter : null)
  }

  handleFLOSFilterChange = newFilter => {
    if (newFilter.apply) {
      this.updateKpiChartSelection()

      this.resetExcessDaysFilter()

      this.props.actions.updateKpiFilters(null)
    }

    this.props.actions.updateKpiFLOSFilter(newFilter)
  }

  handleExcessDaysFilterChange = newFilter => {
    if (newFilter.apply) {
      this.updateKpiChartSelection()

      this.resetFLOSFilter()

      this.props.actions.updateKpiFilters(null)
    }

    this.props.actions.updateKpiExcessDaysFilter(newFilter)
  }

  resetFLOSFilter() {
    const filter = this.getFlosFilter()
    if (filter.apply) {
      this.props.actions.updateKpiFLOSFilter({
        ...filter,
        apply: false,
      })
    }
  }

  resetExcessDaysFilter() {
    const filter = this.getExcessDaysFilter()
    if (filter.apply) {
      this.props.actions.updateKpiExcessDaysFilter({
        ...filter,
        apply: false,
      })
    }
  }

  // ************************************************************************
  // zzz Handle Chart Section
  // ************************************************************************
  handleDimensionChange = dimension => {
    this.props.actions.updateKpiDimension(dimension)
    this.updateKpiChartSelection()
  }

  handleBarChartBarClick = (e, item) => {
    this.updateKpiChartSelection(item)
  }

  handleBarChartLabelClick = item => {
    this.updateKpiChartSelection(item)
  }

  handleClearSelectionClick = () => {
    this.updateKpiChartSelection()
  }

  updateKpiChartSelection = item => {
    this.props.actions.updateKpiChartSelection(item ? item : null)
  }

  // ************************************************************************
  // zzz Handle Sort Section
  // ************************************************************************
  handleSortClick = newSort => {
    this.props.actions.updateKpiSort(newSort ? newSort : null)
  }

  handleItemClick = (e, item) => {
    e.stopPropagation()
    this.props.actions.selectItem(item)
  }

  // ************************************************************************
  // zzz Handle Reset and Clean Up
  // ************************************************************************
  resetPartial() {
    this.handleFilterClick() //Reset Filter
    this.updateKpiChartSelection() //Clear Selection
    this.handleSortClick() //Reset Sort
  }

  // ************************************************************************
  // zzz Make Filter Data
  // ************************************************************************
  makeFilteredItems(
    occupiedInPatientWorkspaces,
    flosFilter,
    excessDaysFilter,
    stats
  ) {
    // todo: CA-722 refactor/redesign below and related since this code was modified late in the
    // todo: development cycle and is more of a workaround than clean design
    const { kpiCapped, kpiFilters } = this.props

    let result = occupiedInPatientWorkspaces
    if (kpiFilters) {
      // If a filter chip is selected then return its items contained in value
      if (kpiFilters.section === 'PlansSection') {
        result = stats.planTemplatesSection.planTemplates[kpiFilters.id]
      } else if (kpiFilters.section === 'BarriersSection') {
        result = stats.categoriesSection.categories[kpiFilters.id]
      } else {
        result = stats[kpiFilters.key].value
      }
    } else if (flosFilter.apply) {
      result = occupiedInPatientWorkspaces.reduce((acc, workspace) => {
        const forecastLOS = kpiCapped
          ? workspace.plan.meta.lengthOfStayMeta.cappedForecast
          : workspace.plan.meta.lengthOfStayMeta.forecast
        if (flosFilter.above) {
          forecastLOS > flosFilter.value && acc.push(workspace)
        } else {
          forecastLOS < flosFilter.value && acc.push(workspace)
        }
        return acc
      }, [])
    } else if (excessDaysFilter.apply) {
      result = occupiedInPatientWorkspaces.reduce((acc, workspace) => {
        const forecastExcessDays = calcForecastExcessDays(kpiCapped, workspace)
        if (excessDaysFilter.above) {
          forecastExcessDays > excessDaysFilter.value && acc.push(workspace)
        } else {
          forecastExcessDays < excessDaysFilter.value && acc.push(workspace)
        }
        return acc
      }, [])
    }
    return result
  }

  makeSelectedItems(filteredItems, filtered, kpiChartSelection) {
    // todo: CA-722 refactor/redesign below and related since this code was modified late in the
    // todo: development cycle and is more of a workaround than clean design
    const dimension = this.props.kpiDimension

    let result = []
    if (filtered) {
      let found = filteredItems || [] // CA-765 can come in here with filteredItems undefined
      if (kpiChartSelection) {
        let key = kpiChartSelection.obj.key
        if (dimension === 'Unit') {
          found = filteredItems.reduce((acc, workspace) => {
            workspace.department.name === key && acc.push(workspace)
            return acc
          }, [])
        } else if (dimension === 'DRG') {
          found = filteredItems.reduce((acc, workspace) => {
            const drgCode = workspace.visit.drgAttributes
              ? workspace.visit.drgAttributes.code
              : 'NotSet'
            drgCode === key && acc.push(workspace)
            return acc
          }, [])
        } else {
          found = filteredItems.reduce((acc, workspace) => {
            workspace.visit.workingDischargeDisposition.code === key &&
              acc.push(workspace)
            return acc
          }, [])
        }
      }

      result = found
    }

    return result
  }

  makeFilterData(workspaces) {
    let planTemplates = {}
    let allPlanTemplateIds = []

    let categories = {}
    let allCategoriesIds = []

    let progression = []
    let progressionNotSet = []
    let progressionOnTime = []
    let progressionAtRisk = []
    let progressionHighRisk = []
    let hasNotIncluded = false

    workspaces.forEach(workspace => {
      const { bed, visit, plan, department } = workspace
      if (visit) {
        hasNotIncluded = department.includedInLosCalculation
          ? hasNotIncluded
          : true
        // todo This check shouldn't really be necessary
        plan.appliedTemplates.forEach(template => {
          const templateName = template.name
          const planTemplate = planTemplates[templateName]
          pushUniqueToArray(templateName, allPlanTemplateIds)
          if (planTemplate) {
            pushUniqueWithPropertyToArray(workspace, 'plan.id', planTemplate)
          } else {
            planTemplates[templateName] = [workspace]
          }
        })

        plan.barriers.forEach(barrier => {
          if (barrier.status === 'Open') {
            const categoryValue = barrier.category.value
            const category = categories[categoryValue]
            pushUniqueToArray(categoryValue, allCategoriesIds)
            if (category) {
              pushUniqueWithPropertyToArray(workspace, 'plan.id', category)
            } else {
              categories[categoryValue] = [workspace]
            }
          }
        })

        const pi = plan.progressionIndicator
        'NotSet' === pi &&
          pushToArrays(workspace, progressionNotSet, progression)
        'OnTime' === pi && pushToArray(workspace, progressionOnTime)
        'AtRisk' === pi &&
          pushToArrays(workspace, progressionAtRisk, progression)
        'HighRisk' === pi &&
          pushToArrays(workspace, progressionHighRisk, progression)
      }
    })

    const allProgressionIds = [
      ProgressionNotSet,
      ProgressionOnTime,
      ProgressionAtRisk,
      ProgressionHighRisk,
    ]

    return {
      hasNotIncluded: hasNotIncluded,
      allPlanTemplateIds: allPlanTemplateIds,
      planTemplatesSection: {
        id: PlansSection,
        key: 'planTemplatesSection',
        label: PlansLabel,
        value: '',
        planTemplates: planTemplates,
      },
      allCategoriesIds: allCategoriesIds,
      categoriesSection: {
        id: CategoriesSection,
        key: 'categoriesSection',
        label: CategoriesLabel,
        value: '',
        categories: categories,
      },
      allProgressionIds: allProgressionIds,
      progressionSection: {
        id: ProgressionSection,
        key: 'progressionSection',
        label: ProgressionLabel,
        value: progression,
      },
      progressionNotSet: {
        id: ProgressionNotSet,
        key: 'progressionNotSet',
        label: NotSetLabel,
        value: progressionNotSet,
      },
      progressionOnTime: {
        id: ProgressionOnTime,
        key: 'progressionOnTime',
        label: OnTimeLabel,
        value: progressionOnTime,
      },
      progressionAtRisk: {
        id: ProgressionAtRisk,
        key: 'progressionAtRisk',
        label: AtRiskLabel,
        value: progressionAtRisk,
      },
      progressionHighRisk: {
        id: ProgressionHighRisk,
        key: 'progressionHighRisk',
        label: HighRiskLabel,
        value: progressionHighRisk,
      },
    }
  }

  // ************************************************************************
  // zzz Make Sections
  // ************************************************************************
  makeHeaderSection(
    facilityStats,
    currentFacility,
    oeThresholdCaution,
    oeThresholdDanger
  ) {
    return (
      <div className={classNames('paper', 'row', 'header-section')}>
        <HeaderSection
          capped={this.props.kpiCapped}
          facilityStats={facilityStats}
          currentFacility={currentFacility}
          oeThresholdCaution={oeThresholdCaution}
          oeThresholdDanger={oeThresholdDanger}
          onFacilityChange={this.handleFacilityChange}
          onCappedToggle={this.handleCappedToggle}
        />
      </div>
    )
  }

  makeFilterSection(stats, flosFilter, excessDaysFilter) {
    const { kpiFilters } = this.props

    return (
      <div className={classNames('paper', 'row', 'section', 'filter-section')}>
        <FilterSection
          stats={stats}
          kpiFilters={kpiFilters}
          flosFilter={flosFilter}
          excessDaysFilter={excessDaysFilter}
          onFilterClick={this.handleFilterClick}
          onFLOSFilterChange={this.handleFLOSFilterChange}
          onExcessDaysFilterChange={this.handleExcessDaysFilterChange}
        />
      </div>
    )
  }

  makeExcessDaysSection(workspaces, kpiChartSelection) {
    return (
      <div
        style={{ marginLeft: 0 }}
        className={classNames('paper', 'row', 'section')}>
        <ExcessDaysSection
          workspaces={workspaces}
          selected={kpiChartSelection}
          capped={this.props.kpiCapped}
          dimension={this.props.kpiDimension}
          onDimensionChange={this.handleDimensionChange}
          onBarChartBarClick={this.handleBarChartBarClick}
          onBarChartLabelClick={this.handleBarChartLabelClick}
          onClearSelectionClick={this.handleClearSelectionClick}
        />
      </div>
    )
  }

  makeSortSection(workspaces, oeThresholdCaution, oeThresholdDanger) {
    const { kpiCapped, kpiSort } = this.props

    return (
      workspaces.length > 0 && (
        <div
          style={{ marginLeft: 0 }}
          className={classNames('paper', 'row', 'section')}>
          <SortSection
            workspaces={workspaces}
            capped={kpiCapped}
            sort={kpiSort}
            oeThresholdCaution={oeThresholdCaution}
            oeThresholdDanger={oeThresholdDanger}
            onSortClick={this.handleSortClick}
          />
        </div>
      )
    )
  }

  makeWorkspaceSection(
    hasPatients,
    filtered,
    workspaces,
    currentItem,
    oeThresholdCaution,
    oeThresholdDanger,
    hasNotIncluded
  ) {
    const { kpiCapped, kpiSort, history } = this.props

    let section
    if (workspaces.length > 0) {
      section = (
        <div className="list">
          <WorkspaceSection
            workspaces={workspaces}
            routeTo={history.push}
            selectedItem={currentItem}
            capped={kpiCapped}
            sort={kpiSort}
            oeThresholdCaution={oeThresholdCaution}
            oeThresholdDanger={oeThresholdDanger}
            onItemClick={this.handleItemClick}
            history={history}
          />
        </div>
      )
    } else {
      const notIncludedText = hasNotIncluded
        ? '(* Unit Not Included In O/E Rollup)'
        : ''
      const noneMessage = hasPatients
        ? filtered
          ? 'No Patients Matching Filter'
          : `No Filter Chosen ${notIncludedText}`
        : 'No Assigned Patients'

      section = (
        <div className="paper none-list">
          <div className="none-item">{noneMessage}</div>
        </div>
      )
    }

    return section
  }

  getFlosFilter() {
    const { kpiFLOSFilter} = this.props
    if (kpiFLOSFilter) {
      return kpiFLOSFilter
    } else {
      const flosAbove =
        getConfigBoolean('ca.flos.days.filter.above.boolean')

      const flosThreshold = Number(
        getConfig('ca.flos.days.threshold.time').replace('d', ''))

      return {
        sortId: 'FLOS',
        value: flosThreshold,
        above: flosAbove,
        apply: false,
      }
    }
  }

  getExcessDaysFilter() {
    const { kpiExcessDaysFilter} = this.props
    if (kpiExcessDaysFilter) {
      return kpiExcessDaysFilter
    } else {
      const excessDaysAbove =
        getConfigBoolean( 'ca.excess.days.filter.above.boolean')

      const excessDaysThreshold = parseInt(
        getConfig('ca.excess.days.threshold.time').replace('d', '')
      )
      return {
        sortId: 'ExcessDays',
        value: excessDaysThreshold,
        above: excessDaysAbove,
        apply: false,
      }
    }
  }

  // ************************************************************************
  // zzz Render
  // ************************************************************************
  render() {
    if (!this.state.data) {
      return <LoadingIndicator />
    }
    const facilities = this.state.data.viewer.facilities || []
    const workspacesTwoDim = facilities.map(facility => {
      const departments = get(facility, 'departments.nodes')
      return departments.map(dept => {
        const beds = get(dept, 'beds.edges')
        return beds.map(bed => {
          let workspace = {}
          workspace.bed = bed.bed
          workspace.department = dept
          workspace.facility = facility
          workspace.visit = workspace.bed.visit
          workspace.plan = workspace.visit.plan ? workspace.visit.plan : null
          return workspace
        })
      })
    })
    this.workspaces = workspacesTwoDim.flat(2)

    const {
      kpiFacility,
      kpiFilters,
      kpiChartSelection,
    } = this.props

    const kpiFacilities = this.state.data.kpi

    // note: kpiFacilities will always have a minimal of 2 items, the Hospital rollup and at least one facility
    const facilityStats =
      kpiFacilities.length > 2 ? kpiFacilities : [kpiFacilities[0]]

    const currentFacility = kpiFacility ? kpiFacility : facilityStats[0]

    const flosFilter = this.getFlosFilter()
    const excessDaysFilter = this.getExcessDaysFilter()

    const filtered =
      kpiFilters ||
      kpiChartSelection ||
      flosFilter.apply ||
      excessDaysFilter.apply

    // todo If order (A-Z, or number of beds, or ordinal) then gather facilities and sort first
    let occupiedInPatientWorkspaces = this.workspaces.reduce(
      (acc, workspace) => {
        const occupied =
          workspace.bed.status === 'Occupied' ||
          workspace.bed.status === 'Swapped'
        let inpatient = false
        let discharged = false
        if (workspace.visit) {
          inpatient = workspace.visit.admissionType === 'InPatient'
          discharged = workspace.visit.visitStatus === 'Discharged'
        }
        if (occupied && inpatient && !discharged) {
          // If a facility is chosen other than the whole hospital, then make sure the patient is in the facility
          currentFacility.exchangeName
            ? currentFacility.exchangeName ===
                workspace.facility.exchangeName && acc.push(workspace)
            : acc.push(workspace)
        }
        return acc
      },
      []
    )

    const {
      selected: { currentItem },
    } = this.props
    const stats = this.makeFilterData(occupiedInPatientWorkspaces)

    let filteredItems = this.makeFilteredItems(
      occupiedInPatientWorkspaces,
      flosFilter,
      excessDaysFilter,
      stats
    )
    let selectedItems = this.makeSelectedItems(
      filteredItems,
      filtered,
      kpiChartSelection
    )
    this.selectedItems = selectedItems

    const hasNotIncluded = stats.hasNotIncluded

    const oeThresholdCaution = Number(getConfig(
      'ca.oe.threshold.caution.double'))

    const oeThresholdDanger = Number(getConfig(
      'ca.oe.threshold.danger.double'))

    const hasPatients = occupiedInPatientWorkspaces.length > 0

    return (
      <FixedView
        header={this.makeHeaderSection(
          facilityStats,
          currentFacility,
          oeThresholdCaution,
          oeThresholdDanger
        )}
        container={
          <div className="kpi-view-container">
            {hasPatients &&
              this.makeFilterSection(stats, flosFilter, excessDaysFilter)}
            {hasPatients &&
              this.makeExcessDaysSection(filteredItems, kpiChartSelection)}
            {this.makeSortSection(
              selectedItems,
              oeThresholdCaution,
              oeThresholdDanger
            )}
            {this.makeWorkspaceSection(
              hasPatients,
              filtered,
              selectedItems,
              currentItem,
              oeThresholdCaution,
              oeThresholdDanger,
              hasNotIncluded
            )}
          </div>
        }
      />
    )
  }
}



export const KpiView = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(KpiList)

const KpiViewQuery = graphql`
  query KpiViewQuery {
    kpi {
      name
      exchangeName
      capped {
        workingOE
        mtdOE
        monthEndOE
      }
      uncapped {
        workingOE
        mtdOE
        monthEndOE
      }
    }
    viewer {
      facilities {
        name
        exchangeName
        id
        departments(first: 10000) {
          nodes {
            id
            exchangeName
            name
            type
            includedInLosCalculation
            beds(first: 10000) {
              totalCount
              edges {
                bed: node {
                  id
                  name
                  exchangeName
                  status
                  visit {
                    id
                    visitNumber
                    homeBedExchangeName
                    admissionType
                    admissionTime
                    patientProblem
                    admittingComplaint
                    bedRequestStatus
                    bedRequests {
                      id
                      bedRequestType
                      bedRequestStatus
                      updatedWhen
                      bedExchangeName
                    }
                    visitStatus
                    anticipatedDischargeTime
                    anticipatedDischargeUpdatedBy {
                      id
                      userName
                      firstName
                      lastName
                    }
                    targetDischargeTime
                    decisionTime
                    tlos
                    currentDayOfStay
                    insurancePlans {
                      planName
                      priority
                    }
                    meta {
                      dischargeToday
                      tlosExceeded
                      plannedDischargeExceeded
                      decisionDue
                      timeElapsedSinceAdmission
                    }
                    drgAttributes {
                      code
                      title
                      level
                      targetLos
                      modifyTargetLos
                    }
                    drgUpdatedBy {
                      userName
                      firstName
                      lastName
                    }
                    patient {
                      id
                      mrn
                      age
                      gender
                      lastName
                      middleInitial
                      firstName
                    }
                    workingDischargeDisposition {
                      id
                      code
                      value
                    }
                    dischargeVendor
                    attendingPhysician {
                      id
                      firstName
                      lastName
                      primary
                      role {
                        id
                        code
                        value
                      }
                    }
                    plan {
                      id
                      progressionIndicator
                      appliedTemplates {
                        name
                      }
                      meta {
                        milestoneMeta {
                          onTime
                          overdue
                          completed
                        }
                        lengthOfStayMeta {
                          forecast
                          cappedForecast
                        }
                        actualOE
                      }
                      milestones {
                        id
                      }
                      barriers {
                        id
                        name
                        description
                        status
                        pinned
                        auditMeta {
                          created
                          createdBy {
                            userName
                            firstName
                            lastName
                          }
                          updated
                          updatedBy {
                            userName
                            firstName
                            lastName
                          }
                        }
                        category {
                          code
                          value
                        }
                        escalation {
                          auditMeta {
                            created
                            createdBy {
                              userName
                              firstName
                              lastName
                            }
                          }
                        }
                        ownerMessageStatus
                        ownerKnownUser {
                          id
                          emailReceiver
                          instantMessageReceiver
                        }
                        owner
                        ownerRole {
                          code
                          value
                        }
                        notes {
                          id
                          text
                          auditMeta {
                            created
                            createdBy {
                              userName
                              firstName
                              lastName
                            }
                            updated
                            updatedBy {
                              userName
                              firstName
                              lastName
                            }
                          }
                          pinned
                        }
                        escalation {
                          auditMeta {
                            created
                            createdBy {
                              userName
                              firstName
                              lastName
                            }
                          }
                        }
                      }
                      careTeamMembers {
                        id
                        name
                        primary
                        role {
                          id
                          code
                          value
                        }
                      }
                    }
                    anticipatedTransferTime
                    anticipatedDischargeTimeAutoPopulated
                    anticipatedTransferTimeAutoPopulated
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
