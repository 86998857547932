import React from 'react'
import PropTypes from 'prop-types'

import './PersonName.scss'

export const RECIPIENT = 'RECIPIENT'
export const SENDER = 'SENDER'

const PersonName = ({ nameType = RECIPIENT, displayName }) => {
  const nameDisplayType = nameType === RECIPIENT || nameType === SENDER ? nameType : RECIPIENT
  const iconClassName = nameDisplayType === RECIPIENT ? 'RecipientIcon' : 'SenderIcon'

  return (
    <div className={'Person'}>
      <span className={'PersonIconWrapper'}>
        <span className={iconClassName} />
      </span>
      <span className={'NameText'}>{displayName}</span>
    </div>
  )
}

PersonName.propTypes = {
  nameType: PropTypes.string,
  displayName: PropTypes.string.isRequired
}

export default PersonName
