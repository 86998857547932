import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash.sortby'

import { CarePointPin } from './'

import { typeBarrierActionNoteCompare } from '../../util/sort'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('CarePointList', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    list: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    item: {
      marginTop: '.2em', // Needed when wrapped
      marginRight: '.2em',
    },
  }
})

export const CarePointList = ({ plan, onClick, onRequestDelete }, context) => {
  const classes = context.styleManager.render(styleSheet)

  let pinnableItems = []
  pinnableItems = pinnableItems.concat(sortBy(plan.barriers, item => item.auditMeta.updated))
  pinnableItems = pinnableItems.concat(sortBy(plan.actions, item => item.auditMeta.updated))
  pinnableItems = pinnableItems.concat(sortBy(plan.notes, item => item.auditMeta.updated))

  let milestoneItems = []
  plan.milestones.forEach(item => {
    milestoneItems = milestoneItems.concat(item.actions)
  })
  pinnableItems = pinnableItems.concat(sortBy(milestoneItems, item => item.auditMeta.updated))

  let pinnedItems = []
  pinnedItems = pinnableItems.reduce((pinnedItems, item) => {
    if (item.pinned) {
      pinnedItems.push(item)
    }
    return pinnedItems
  }, pinnedItems)

  const rollUpNotes = false

  return (
    <div className={classNames(classes.list)}>
      {buildItems(pinnedItems, rollUpNotes).map(item => (
        <span className={classNames(classes.item)} key={item.id}>
          {rollUpNotes && item.__typename === 'PlanNote' ? (
            <CarePointPin item={item} />
          ) : (
            <CarePointPin
              item={item}
              onClick={onClick}
              onRequestDelete={onRequestDelete}
            />
          )}
        </span>
      ))}
    </div>
  )
}

function buildItems(items, rollUpNotes) {
  // let itemsCopy = items.slice(0);
  // itemsCopy.push({id: '3', '__typename': 'PlanAction', text: '#Has is an action with #hashtags and more text'});
  // itemsCopy.push({id: '1', '__typename': 'Barrier', name: 'BarrierName', description: 'Barrier Description'});
  // itemsCopy.push({id: '2', '__typename': 'PlanAction', text: 'Max Number Test'});
  // itemsCopy.push({id: '4', '__typename': 'PlanAction', text: 'Truncated action text'});
  // itemsCopy.push({id: '5', '__typename': 'PlanNote', text: 'Note 5'});
  // itemsCopy.push({id: '6', '__typename': 'PlanNote', text: 'Note 6'});

  let result
  if (rollUpNotes) {
    result = items
      .filter(item => item.__typename !== 'PlanNote')
      .sort(typeBarrierActionNoteCompare)
    if (items.length > result.length) {
      result.push({ id: '-1', __typename: 'PlanNote', text: 'Notes' })
    }
  } else {
    result = items
  }
  return result
}

CarePointList.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

CarePointList.propTypes = {
  plan: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onRequestDelete: PropTypes.func,
}
