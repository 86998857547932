/* eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import BarrierIcon from 'mdi-react/GateIcon'
import Stairs from 'mdi-react/StairsIcon'
import ActionIcon from 'mdi-react/CalendarCheckIcon'
import { CodeImage, PatientInfoImage } from '../image/CodeImage'
import { red400 as Red } from 'material-ui/styles/colors'
import { NameFormat } from '../common'
import { computeThresholds, findComplianceIssues } from '../../util/compliance'
import CLDatePicker from './CLDatePicker'
import { BedUnitLink } from '../common/BedUnitLink'
import { MilestoneSummaryStatusIcon } from '../milestone/MilestoneSummaryStatusIcon'
import { ProgressionIndicator } from '../'
import { Alerts } from '../../components/Alerts'
import ClockIcon from 'mdi-react/ClockIcon'
import {
  VisitStatus,
  AdmissionStatus,
  CarePointList,
  DecisionTimePicker,
} from './'
import { StatefulTextField } from '../common/StatefulTextField'
import { getPlannedLabel, hasEstimatedTransferDateIssue, isJvionEnabled, getTransferLabelAcronym} from '../../util/configs'
import './plan.scss'
import VectorSummary from '../../plugins/jvion/VectorSummary'
import { setDetailTabHeight } from '../../ducks/views'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ModeEdit from '../icons/ModeEdit'
import { Popover, OverlayTrigger, Button } from 'react-bootstrap'
import sortBy from 'lodash.sortby'
import BarrierMessageStatusIcon from '../barrier/BarrierMessageStatusIcon/BarrierMessageStatusIcon'
import {
  APP_DELIVERY,
  EMAIL_DELIVERY,
  NEEDS_REASSIGNMENT_MSG_STATUS,
  UNACKNOWLEDGED_MSG_STATUS
} from '../barrier/BarrierConstants'
import DatePickerButton from './DatePickerButton'
import moment from "moment/moment";

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setDetailTabHeight,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class PlanHeader extends React.Component {
  static propTypes = {
    relayEnv: PropTypes.object,
    languageResources: PropTypes.arrayOf(
      PropTypes.shape({
        resourceKey: PropTypes.string,
        resourceValue: PropTypes.string,
      })
    )
  }
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      newTlos: null,
      compliance: findComplianceIssues(
        props.workspace.bed,
        props.workspace.visit,
        props.workspace.plan,
        computeThresholds()
      ),
    }
    //this.toggleCalendar = this.toggleCalendar.bind(this)
    this.submitDate = this.submitDate.bind(this)
  }

  componentWillMount() {
    window.removeEventListener('resize', this.handleOrientationChange)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleOrientationChange)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      compliance: findComplianceIssues(
        nextProps.workspace.bed,
        nextProps.workspace.visit,
        nextProps.workspace.plan,
        computeThresholds()
      ),
    })
  }
  componentDidUpdate() {
    this.fixHeight()
  }
  handleOrientationChange = e => {
    this.forceUpdate()
  }

  handleTlosKeyUp = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
    // CA-1840 - humor QA as best we can
    this.setState({ newTlos: e.target.value === "0" ? "1" : e.target.value})
  }

  submitDate(dt, visitId, autoPopulated) {
    this.props.onPlannedDischargeTimeUpdate(dt, visitId, autoPopulated)
  }

  onClick = (bedId, planId, visitId) => {
    this.props.handleViewPlanClick(bedId, planId, visitId, this.props.index)
  }

  handleEditTLOS = visitId => {
    if (visitId && this.state.newTlos)
      this.props.onTargetLosUpdate(visitId, this.state.newTlos)
    this.setState({ newTlos: null })
    this.refs.overlay.hide()
  }

  fixHeight = () => {
    if (this.props.rvcache && this.props.rvlist) {
      this.props.rvcache.clear(this.props.index)
      this.props.rvlist.recomputeRowHeights(this.props.index)
    }
  }

  renderRow1 = () => {
    const { visit, bed, plan, department } = this.props.workspace
    const { compliance } = this.state
    const isDischarged = visit.visitStatus === 'Discharged'
    const isSwapped = bed.status === 'Swapped' && !isDischarged
    const patientProblem =
      visit.patientProblem ||
      (visit.drgAttributes && visit.drgAttributes.title) ||
      visit.admittingComplaint ||
      ''

    const getOwnerMessageDeliveryMethod = (barrier) => {
      if(barrier.ownerKnownUser) {
        if(barrier.ownerKnownUser.instantMessageReceiver) {
          return APP_DELIVERY
        }
        if(barrier.ownerKnownUser.emailReceiver) {
          return EMAIL_DELIVERY
        }
      }
      return null
    }

    const needsReassignmentBarrierCount = plan.barriers.filter(b => b.status === 'Open' && b.ownerMessageStatus === NEEDS_REASSIGNMENT_MSG_STATUS && getOwnerMessageDeliveryMethod(b) === APP_DELIVERY)
      .length
    const unacknowledgedBarrierCount = plan.barriers.filter(b => b.status === 'Open' && b.ownerMessageStatus === UNACKNOWLEDGED_MSG_STATUS && getOwnerMessageDeliveryMethod(b) === APP_DELIVERY)
      .length
    const openBarriersCount = plan.barriers.filter(b => b.status === 'Open')
      .length
    const openPlanActionsCount = plan.actions.filter(a => a.status === 'Open')
      .length
    const escalatedBarriersCount = plan.barriers.filter(
      b => b.escalation !== null
    ).length
    const isAdmission = department.type === 'AdmissionSource'

    return (
      <div className="row row1">
        <BedUnitLink
          bed={bed}
          unit={department.name}
          planId={plan.id}
          visitId={visit.id}
          onClick={this.props.viewType === 'PlanList' ? this.onClick : null}
          extraClasses="col-xs-3"
          discharged={isDischarged}
        />
        <div className="col-xs-3" style={{ display: 'flex' }}>
          <div className="row1col2col1">
            <ProgressionIndicator
              value={plan.progressionIndicator}
              compliance={compliance}
              iconButtonStyle={{ top: '-10px' }}
              onChange={newValue => {
                this.props.onProgressionIndicatorUpdate(plan.id, newValue)
              }}
            />
          </div>
          <div className="row1col2col2">
            {isAdmission ? (
              <AdmissionStatus
                bed={bed}
                visit={visit}
                plan={plan}
                relayEnv={this.props.relayEnv}
                onToBeAdmittedUpdate={this.props.onToBeAdmittedUpdate}
              />
            ) : (
              <VisitStatus
                visit={visit}
                bed={bed}
                isSwapped={isSwapped}
                canEdit
                onVisitStatusUpdate={this.props.onVisitStatusUpdate}
              />
            )}
          </div>
        </div>
        <div className="col-xs-3 row1col3">
          <StatefulTextField
            textFieldProps={{ fullWidth: true }}
            text={patientProblem}
            onEnter={newValue =>
              this.props.onPatientProblemUpdate(visit.id, newValue)
            }
            fixHeight={
              this.props.rvcache && this.props.rvlist
                ? this.fixHeight
                : undefined
            }
            clearOnTextEntered={false}
            blurOnEnter
            showCharacterCount
            maxNumberOfChars={255}
            propsOverState
          />
        </div>
        <div className="col-xs-3 col4 iconSection">
          { unacknowledgedBarrierCount > 0 ? (
            <BarrierMessageStatusIcon
              messageStatus={UNACKNOWLEDGED_MSG_STATUS}
              messageStatusCount={unacknowledgedBarrierCount}
            />
          ) : (
            ''
          )}
          { needsReassignmentBarrierCount > 0 ? (
            <BarrierMessageStatusIcon
              messageStatus={NEEDS_REASSIGNMENT_MSG_STATUS}
              messageStatusCount={needsReassignmentBarrierCount}
            />
          ) : (
            ''
          )}
          {escalatedBarriersCount > 0 ? (
            <span className="iconWrapper">
              <Stairs className="escalationIcon" style={{ fill: Red }} />
              {escalatedBarriersCount}
            </span>
          ) : (
            ''
          )}
          <BarrierIcon className="barrierIcon" />
          {openBarriersCount}
          <ActionIcon className="actionIcon" />
          {openPlanActionsCount}
          {isJvionEnabled() && (
            <VectorSummary
              bedId={bed.exchangeName}
              planId={plan.id}
              visitId={visit.id}
              onClick={this.props.viewType === 'PlanList' ? this.onClick : null}
              patientVector={visit.vector ? visit.vector : null}
            />
          )}
        </div>
      </div>
    )
  }

  changeTlos = () => {
    setTimeout(() => {
      this.refs.inputTLOS.select()
    }, 1)
  }

  renderRow2 = () => {
    const { visit, bed, department } = this.props.workspace
    const { compliance } = this.state
    const inpatient = visit.admissionType === 'InPatient'
    const disableEstimatedTransferDate =
    bed.status === 'Reserved' || bed.status === 'Swapped' || department.type !== 'Unit'

    const submitDate = (dt, visitId, autoPopulated) => {
      this.props.onEstimatedTransferDateUpdate(dt, visitId, autoPopulated)
    }

    const handleConfirmRemove = () => {
      submitDate(null, visit.id, false)
    }

    const handleClearClick = () => {
      // Server won't allow removal if pending or ready for unit transfer, and get the server Validation
      if(visit.bedRequestStatus === 'PendingUnitTransfer' || visit.bedRequestStatus === 'ReadyForUnitTransfer') {
        submitDate(null, visit.id, false)
        return true
      }
      return false
    }

    const popoverRight = (
      <Popover id="popover-positioned-right" title="Change TLOS">
        <input
          defaultValue={visit.tlos}
          onKeyUp={this.handleTlosKeyUp}
          ref="inputTLOS"
          maxLength="3"
        />
        <br />
        <button onClick={() => this.handleEditTLOS(visit.id)}>Submit</button>
        <button onClick={() => this.handleEditTLOS(null)}>Close</button>
      </Popover>
    )
    const currentDayOfStay = visit.currentDayOfStay ? (
      <span>
        <span
          className={classNames({
            overtime: visit.currentDayOfStay >= visit.tlos,
          })}>
          {`Day ${visit.currentDayOfStay} of ${visit.tlos || '?'}`}
          {visit.drgAttributes && visit.drgAttributes.modifyTargetLos ? (
            <OverlayTrigger
              ref="overlay"
              trigger="click"
              placement="right"
              overlay={popoverRight}>
              <Button
                style={{ border: 0, padding: '0px 2px', height: '24px' }}
                onClick={() => this.changeTlos()}>
                <ModeEdit />
              </Button>
            </OverlayTrigger>
          ) : (
            ''
          )}
        </span>
      </span>
    ) : (
      'Not Admitted'
    )

    const WDD = visit.workingDischargeDisposition.code !== 'NotSet'
    const physician = visit.attendingPhysician
    const physicianName =
      physician && `${physician.lastName}, ${physician.firstName}`

    return (
      <div className="row row2">
        <div className="col-xs-3 text">
          <PatientInfoImage
            type="Gender"
            code={visit.patient.gender || 'nil'}
          />
          {`${visit.patient.age || ''} `}
          <NameFormat person={visit.patient} format="lfm" />
        </div>
        <div className="col-xs-3 row2col2">
          <div style={{marginRight: '10px'}} className="reactDatepicker">
            {inpatient ? currentDayOfStay :
              <DecisionTimePicker
                visit={visit}
                onEdit={this.props.onEditDecisionTime} />}
          </div>
          <div
          style={(visit.anticipatedTransferTime && !disableEstimatedTransferDate ? {position: 'inherit', bottom: '1.7px'} : {})}
          className={classNames('reactDatepicker')}>
            <CLDatePicker
              disabled={disableEstimatedTransferDate}
              //forces the component to rerender when the date is removed
              key={"PlanHeader:ETD:"+visit.id+":"+visit.anticipatedDischargeTime}
              onSetPDC={submitDate}
              popperProps={{
                positionFixed: (this.props.viewType === 'Plan')
              }}
              timeAutoPopulated={visit.anticipatedTransferTimeAutoPopulated}
              timeAutoPopulatedByMinutes={0}
              dateTime={visit.anticipatedTransferTime}
              id={visit.id}
              dateOnlySelectionType='currentTime'
              customInput={
                <DatePickerButton
                  noChange={disableEstimatedTransferDate}
                  timeAutoPopulated={visit.anticipatedTransferTimeAutoPopulated}
                  dateTime={visit.anticipatedTransferTime}
                  hasDateTimeIssue={hasEstimatedTransferDateIssue(visit)}
                  handleConfirmRemove={handleConfirmRemove}
                  handleClearClick={handleClearClick}
                  label={getTransferLabelAcronym()}
                />
              }
            />
          </div>
        </div>
        <div className="col-xs-3">
          <span
            style={{ cursor: 'pointer' }}
            className={classNames(
              { complianceIssue: compliance.noWDDIssue },
              { clickText: !compliance.noWDDIssue }
            )}
            onClick={() =>
              this.props.onWorkingDischargeDispositionUpdate(visit)
            }>
            {WDD
              ? visit.workingDischargeDisposition.value
              : 'No D/C Arrangements'}
          </span>
        </div>
        <div className="col-xs-3 col4">
          {physicianName || 'No Attending Physician'}
        </div>
      </div>
    )
  }

  renderRow3 = () => {
    const { visit, plan, bed } = this.props.workspace
    const { compliance } = this.state
    const inpatient = visit.admissionType === 'InPatient'
    // CA-2489 - sortBy, so we get primary/secondary right
    const insurances = sortBy(visit.insurancePlans, ['priority'])
    const noInsurances = insurances.length === 0
    const primaryInsurance = insurances[0]
    const secondaryInsurance = insurances[1]

    const dischargeDateInPast = visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).isBefore( visit.anticipatedDischargeTimeAutoPopulated ? moment().startOf('day') : moment() )
    const dischargeDateWithinTwoDays = visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).subtract(2, 'd').startOf('day').isBefore(moment())

    const drgCode =
      visit.drgAttributes &&
      visit.drgAttributes.code &&
      `${visit.drgAttributes.code} ${visit.drgAttributes.level}`

    const systemSetDRG = inpatient && drgCode && !visit.drgUpdatedBy

    const discharged = visit.visitStatus === 'Discharged'
    const disablePlannedDischarge =
      bed.status === 'Reserved' || bed.status === 'Swapped'

    const systemSetDischargeTime = visit.anticipatedDischargeTime && !visit.anticipatedDischargeUpdatedBy
    const drgInfo = inpatient ? (
      <span
        style={{ cursor: discharged ? '' : 'pointer' }}
        className={classNames(
          { complianceIssue: compliance.noDRGIssue && !discharged },
          { clickText: !compliance.noDRGIssue && !discharged }
        )}
        onClick={discharged ? null : () => this.props.onSearchDRG(visit)}>
        {drgCode || 'NO DRG'}
      </span>
    ) : (
      <span style={{ verticalAlign: '-5px' }}>
        <CodeImage type="AdmissionType" code={visit.admissionType} />
      </span>
    )

    const noPlan = plan.appliedTemplates && plan.appliedTemplates.filter(template => !template.removed).length === 0

    const planInfo = noPlan ? (
      <span
        style={{ cursor: 'pointer' }}
        className={classNames(
          { complianceIssue: compliance.noPlanIssue },
          { clickText: !compliance.noPlanIssue }
        )}
        onClick={() => this.props.onApplyPlan(visit, plan)}>
        {`  No Plan`}
      </span>
    ) : (
      <span
        style={{
          display: 'inline-block',
          verticalAlign: '-5px',
          paddingLeft: 3,
        }}>
        <MilestoneSummaryStatusIcon
          milestoneStatuses={{
            onTime: plan.meta.milestoneMeta.onTime,
            overdue: plan.meta.milestoneMeta.overdue,
            completed: plan.meta.milestoneMeta.completed,
          }}
          className="milestoneSummaryIcon"
        />
      </span>
    )

    const handleConfirmRemove = () => {
      this.submitDate(null, visit.id, false)
    }

    return (
      <div className="row row3">
        <div className="col-xs-3">
          {drgInfo}
          {systemSetDRG ? ' (External System) ' : ''}
          {planInfo}
        </div>
        <div className="col-xs-3 row3col2">
          {!dischargeDateInPast && dischargeDateWithinTwoDays && <ClockIcon className="clockIcon"/>}
          <div
            className={classNames('reactDatepicker', {
              complianceIssue:
                !disablePlannedDischarge && compliance.planDischargeIssue,
            })}>
            <CLDatePicker
              disabled={discharged || disablePlannedDischarge}
              //forces the component to rerender when the date is removed
              key={"PlanHeader:EDD:"+visit.id+":"+visit.anticipatedDischargeTime}
              onSetPDC={this.submitDate}
              popperProps={{
                positionFixed: (this.props.viewType === 'Plan')
              }}
              timeAutoPopulated={visit.anticipatedDischargeTimeAutoPopulated}
              dateTime={visit.anticipatedDischargeTime}
              id={visit.id}
              dateOnlySelectionType='midnight'
              customInput={
                <DatePickerButton
                  noChange={discharged || disablePlannedDischarge}
                  timeAutoPopulated={visit.anticipatedDischargeTimeAutoPopulated}
                  dateTime={visit.anticipatedDischargeTime}
                  handleConfirmRemove={handleConfirmRemove}
                  label={getPlannedLabel() + ' D/C'}
                  dateUpdatedBy={systemSetDischargeTime ? ' (External System)' : ''}
                />
              }
            />
          </div>
        </div>
        <div className="col-xs-3 clickText text">
          <a
            onClick={() =>
              this.props.onWorkingDischargeDispositionUpdate(visit)
            }>
            {visit.dischargeVendor || 'No Note'}
          </a>
        </div>
        <div className="col-xs-3 text col4">
          {noInsurances && 'No Insurance Plan(s) '}
          {primaryInsurance && primaryInsurance.planName}
          {secondaryInsurance && ` | ${secondaryInsurance.planName}`}
        </div>
      </div>
    )
  }

  renderRow4 = () => {
    const { visit, plan, department } = this.props.workspace
    return (
      <div className="row">
        <div className="col-xs-8 ">
          <CarePointList
            plan={plan}
            onClick={() => {}}
            onRequestDelete={this.props.onUpdateCarePin}
          />
        </div>
        <div className="col-xs-4 col4">
          <Alerts
            unitName={department.name}
            visit={visit}
          />
        </div>
      </div>
    )
  }

  computeStoreDetailHeight = headerRef => {
    if (headerRef && headerRef.parentElement) {
      const height =
        headerRef.parentElement.clientHeight - headerRef.clientHeight
      this.props.actions.setDetailTabHeight(height)
    }
  }

  render() {
    const snapView = this.props.viewType === 'Snap'

    // CA-1320 - set the height of the height available to Detail area in Redux so we
    // can size details properly (could have several rows of pinned items)
    return (
      <div
        ref={this.computeStoreDetailHeight}
        key={window.innerWidth}
        className="plan-header"
        id={this.props.index}
        style={{ display: 'flex', position: 'relative' }}>
        <div className="paper" style={{ padding: '5px', flex: 'auto' }}>
          {this.renderRow1()}
          {this.renderRow2()}
          {this.renderRow3()}
          {!snapView && this.renderRow4()}
        </div>
      </div>
    )
  }
}

PlanHeader.propTypes = {
  actions: PropTypes.shape({ setDetailTabHeight: PropTypes.func }),
  viewType: PropTypes.oneOf(['ConflictedPlan', 'PlanList', 'Snap', 'Plan'])
    .isRequired,
  workspace: PropTypes.shape({
    bed: PropTypes.object,
    visit: PropTypes.object,
    plan: PropTypes.object,
    department: PropTypes.object,
  }),
  handleViewPlanClick: PropTypes.func,
  onPatientProblemUpdate: PropTypes.func.isRequired,
  onTargetLosUpdate: PropTypes.func.isRequired,
  onProgressionIndicatorUpdate: PropTypes.func.isRequired,
  onWorkingDischargeDispositionUpdate: PropTypes.func.isRequired,
  onVisitStatusUpdate: PropTypes.func.isRequired,
  onToBeAdmittedUpdate: PropTypes.func.isRequired,
  onPlannedDischargeTimeUpdate: PropTypes.func.isRequired,
  onEstimatedTransferDateUpdate: PropTypes.func.isRequired,
  onUpdateCarePin: PropTypes.func.isRequired,
  onSearchDRG: PropTypes.func.isRequired,
  onApplyPlan: PropTypes.func.isRequired,
  onEditDecisionTime: PropTypes.func.isRequired,
  index: PropTypes.number,
  patientVector: PropTypes.object,
  rvcache: PropTypes.object,
  rvlist: PropTypes.object,
}
