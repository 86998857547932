/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateTargetLos_MutationVariables = {|
  visitId: any,
  targetLos: number,
|};
export type visit_updateTargetLos_MutationResponse = {|
  +visitMutation: ?{|
    +updateTargetLos: ?{|
      +id: string,
      +tlos: ?number,
      +plan: ?{|
        +id: string,
        +milestones: $ReadOnlyArray<?{|
          +id: string,
          +dueDate: ?any,
        |}>,
      |},
    |}
  |}
|};
export type visit_updateTargetLos_Mutation = {|
  variables: visit_updateTargetLos_MutationVariables,
  response: visit_updateTargetLos_MutationResponse,
|};
*/


/*
mutation visit_updateTargetLos_Mutation(
  $visitId: Long!
  $targetLos: Int!
) {
  visitMutation {
    updateTargetLos(visitId: $visitId, targetLos: $targetLos) {
      id
      tlos
      plan {
        id
        milestones {
          id
          dueDate
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetLos"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VisitMutation",
    "kind": "LinkedField",
    "name": "visitMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "targetLos",
            "variableName": "targetLos"
          },
          {
            "kind": "Variable",
            "name": "visitId",
            "variableName": "visitId"
          }
        ],
        "concreteType": "Visit",
        "kind": "LinkedField",
        "name": "updateTargetLos",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tlos",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "plan",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dueDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateTargetLos_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateTargetLos_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6fd353034955ee29f4efd572150ccf97",
    "id": null,
    "metadata": {},
    "name": "visit_updateTargetLos_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateTargetLos_Mutation(\n  $visitId: Long!\n  $targetLos: Int!\n) {\n  visitMutation {\n    updateTargetLos(visitId: $visitId, targetLos: $targetLos) {\n      id\n      tlos\n      plan {\n        id\n        milestones {\n          id\n          dueDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0563e3b8f5829fd16f852aeb7c10d87c';

module.exports = node;
