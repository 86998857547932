import React from 'react'
import PropTypes from 'prop-types'

import OpenIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon'
import ResolvedIcon from 'mdi-react/CheckboxMarkedCircleIcon'
import {
  green500 as OpenColor,
  green500 as ResolvedColor,
  red500 as OverdueColor,
} from 'material-ui/styles/colors'

const colors = { OpenColor, ResolvedColor }
const icons = {
  Open: OpenIcon,
  Resolved: ResolvedIcon,
}

export class BarrierStatusIcon extends React.Component {
  render() {
    const { value = 'Open', overdue = false, style = {} } = this.props
    const Icon = icons[value]
    const color = overdue ? OverdueColor : colors[`${value}Color`]

    return (
      <Icon
        style={{
          ...style,
          top: 3,
        }}
        fill={color}
      />
    )
  }
}

BarrierStatusIcon.propTypes = {
  value: PropTypes.string,
  overdue: PropTypes.bool,
  style: PropTypes.object,
}
