/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type plan_updateWorkingDischargeDisposition_MutationVariables = {|
  visitId: any,
  dispositionCode: string,
  dischargeVendor: string,
|};
export type plan_updateWorkingDischargeDisposition_MutationResponse = {|
  +visitMutation: ?{|
    +updateWorkingDischargeDisposition: ?{|
      +id: string
    |}
  |}
|};
export type plan_updateWorkingDischargeDisposition_Mutation = {|
  variables: plan_updateWorkingDischargeDisposition_MutationVariables,
  response: plan_updateWorkingDischargeDisposition_MutationResponse,
|};
*/


/*
mutation plan_updateWorkingDischargeDisposition_Mutation(
  $visitId: Long!
  $dispositionCode: String!
  $dischargeVendor: String!
) {
  visitMutation {
    updateWorkingDischargeDisposition(visitId: $visitId, dispositionCode: $dispositionCode, dischargeVendor: $dischargeVendor) {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeVendor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dispositionCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VisitMutation",
    "kind": "LinkedField",
    "name": "visitMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "dischargeVendor",
            "variableName": "dischargeVendor"
          },
          {
            "kind": "Variable",
            "name": "dispositionCode",
            "variableName": "dispositionCode"
          },
          {
            "kind": "Variable",
            "name": "visitId",
            "variableName": "visitId"
          }
        ],
        "concreteType": "Visit",
        "kind": "LinkedField",
        "name": "updateWorkingDischargeDisposition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "plan_updateWorkingDischargeDisposition_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "plan_updateWorkingDischargeDisposition_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "288366dc4c6551d91583e079a1dc7ab2",
    "id": null,
    "metadata": {},
    "name": "plan_updateWorkingDischargeDisposition_Mutation",
    "operationKind": "mutation",
    "text": "mutation plan_updateWorkingDischargeDisposition_Mutation(\n  $visitId: Long!\n  $dispositionCode: String!\n  $dischargeVendor: String!\n) {\n  visitMutation {\n    updateWorkingDischargeDisposition(visitId: $visitId, dispositionCode: $dispositionCode, dischargeVendor: $dischargeVendor) {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '98bed85e4156a0838ab7f7a28491f563';

module.exports = node;
