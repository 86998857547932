/*eslint-disable react/no-set-state*/
import { hot } from 'react-hot-loader/root'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import './app.scss'
import 'react-virtualized/styles.css'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import {
  grey500 as BorderColorV0,
  grey200 as ContentFrameColorV0,
  red200 as AssignedBedRemovedColorV0,
  lightGreen100 as AssignedBedColorV0,
  lightGreen400 as AssignedBedAddedColorV0,
  blue900 as selectedItemColorV0,
} from 'material-ui/styles/colors'
import grey from '@material-ui/core/colors/grey'
import red from '@material-ui/core/colors/red'
import lightGreen from '@material-ui/core/colors/lightGreen'
import blue from '@material-ui/core/colors/blue'


import { AppHeader } from './AppHeader'

import { generateRoutes } from './route'

import { selectItem, selectSubItem } from './ducks/app-selections'
import { setSelectedBarrierSection } from './ducks/escalation-filters'
import { deepDiffShouldComponentUpdate } from './util/should-component-update'

import { NavBar } from './components'
import { ConfirmLogout } from './containers/auth/ConfirmLogout'
import {
  IdleTimerContainer,
  NetworkStatus,
  StandardDrawerContainer,
  TokenRefresher,
} from './containers'

import { createStyleManager } from 'jss-theme-reactor'
import { create as createJss } from 'jss'
import preset from 'jss-preset-default'

import { environment as relayEnv } from './graphql/relay/env'
import { setEnviroment } from 'recompose-relay-modern'
import {queryAndSetConfigs, getConfig, getConfigBoolean} from "./util/configs"
import { initializeDatadogRum } from './util/utils'

const BorderColor = grey[500]
const ContentFrameColor = grey[200]
const AssignedBedRemovedColor = red[200]
const AssignedBedColor = lightGreen[100]
const AssignedBedAddedColor = lightGreen[400]
const selectedItemColor = blue[900]


setEnviroment(relayEnv)
const muiThemeV0 = getMuiTheme({
  appBar: {
    height: 50,
  },
  // CA-605 - someone didn't like the default
  menuItem: {
    selectedTextColor: selectedItemColorV0,
  },
  palette: {
    background: {
      contentFrame: ContentFrameColorV0,
    },
  },
  c3Classes: {
    attr: {
      marginRight: '.5em',
    },
    attrLeft: {
      marginLeft: '.5em',
    },
    attrRight: {
      marginRight: '.5em',
    },
    spacer: {
      marginRight: '2px',
    },
    spacerLeft: {
      marginLeft: '2px',
    },
    spacerRight: {
      marginRight: '2px',
    },
    buttonBar: {
      padding: '.5em 1em .5em 1em',
      height: '50px',
      background: 'transparent',
    },
    buttonBarButton: {
      float: 'right',
      marginRight: '.5em !important',
    },
    background: {
      assignedBed: AssignedBedColorV0,
      assignedBedAdded: AssignedBedAddedColorV0,
      assignedBedRemoved: AssignedBedRemovedColorV0,
      toolbar: 'transparent',
    },
    color: {
      border: BorderColorV0,
    },
  },
})

const theme = createMuiTheme({
  appBar: {
    height: 50,
  },
  // CA-605 - someone didn't like the default
  menuItem: {
    selectedTextColor: selectedItemColor,
  },
  palette: {
    primary: {
      main: '#4dcedf'
    },
    background: {
      contentFrame: ContentFrameColor,
    },
  },
  c3Classes: {
    attr: {
      marginRight: '.5em',
    },
    attrLeft: {
      marginLeft: '.5em',
    },
    attrRight: {
      marginRight: '.5em',
    },
    spacer: {
      marginRight: '2px',
    },
    spacerLeft: {
      marginLeft: '2px',
    },
    spacerRight: {
      marginRight: '2px',
    },
    buttonBar: {
      padding: '.5em 1em .5em 1em',
      height: '50px',
      background: 'transparent',
    },
    buttonBarButton: {
      float: 'right',
      marginRight: '.5em !important',
    },
    background: {
      assignedBed: AssignedBedColor,
      assignedBedAdded: AssignedBedAddedColor,
      assignedBedRemoved: AssignedBedRemovedColor,
      toolbar: 'transparent',
    },
    color: {
      border: BorderColor,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '14px',
        fontWeight: 'normal',
        '&$focused': {
          color: '#4dcedf'
        }
      },
    },
    MuiInput: {
      root: {
        fontSize: '14px'
      },
      underline: {
        '&::before': {
          borderBottomColor: BorderColor
        },
        '&::after': {
          borderBottomColor: '#4dcedf'
        },
        '&:hover:not($disabled):not($error):not($focused)::before': {
          borderBottomColor: BorderColor
        }
      }
    },
    MuiSelect: {
      select: {
        fontSize: '14px',
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px'
      }
    },
    MuiPopover: {
      root: {
        zIndex: '1700 !important'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '14px'
      }
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#FFF'
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        fontSize: '14px'
      }
    },
    MuiAutocomplete: {
      root: {
        fontSize: '14px'
      },
      listbox: {
        fontSize: '14px'
      },
      popper: {
        zIndex: 2000
      }
    },
    MuiChip: {
      root: {
        fontSize: '14px'
      }
    }
  },
})

const mapStateToProps = state => ({
  authState: state.authState,
  selectedBarrierSection: state.escalationView.selectedBarrierSection,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      selectItem,
      selectSubItem,
      setSelectedBarrierSection,
    },
    dispatch
  ),
})

// Thid method will be called when the CA App configuration loaded successfully
const onConfigsLoaded = () => {
  // Initialize datadog RUM when we're done fetching configs
  initializeDatadogRum({
    enabled: getConfigBoolean('ca.admin.datadog.enabled.boolean'),
    envName: getConfig('ca.admin.datadog.env.text'),
    sampleRate: parseInt(getConfig('ca.admin.datadog.samplerate.integer'))
  })
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
class App extends Component {
  static propTypes = {
    routes: PropTypes.array,
    authState: PropTypes.shape({
      token: PropTypes.string,
      refreshToken: PropTypes.string,
      identity: PropTypes.object,
    }),
    selectedBarrierSection: PropTypes.object.isRequired,
    actions: PropTypes.shape({
      selectItem: PropTypes.func,
      selectSubItem: PropTypes.func,
      setSelectedBarrierSection: PropTypes.func.isRequired,
    }),
  }
  static childContextTypes = {
    styleManager: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      caAppConfigsLoading: true
    }
  }

  getChildContext() {
    return {
      styleManager: createStyleManager({
        jss: createJss(preset()),
        theme: muiThemeV0,
      }),
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  // CA-2874 - ensure we get fresh configs after a logout
  componentWillReceiveProps(nextProps) {
    if(!nextProps.authState.token) {
      this.setState({caAppConfigsLoading: true})
    }
  }


  shouldComponentUpdate(nextProps, nextState) {
    let changedProps = []

    Object.entries(this.props).forEach(([key, val]) => {
      if (nextProps[key] !== val) {
        changedProps.push(key)
      }
    })

    if (
      changedProps.length == 1 &&
      changedProps[0] === 'selectedBarrierSection'
    ) {
      return false
    }

    return deepDiffShouldComponentUpdate(
      this.props,
      nextProps,
      this.state,
      nextState,
      {
        debug: true,
        key: 'App',
      }
    )
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _isMounted = false    // required for config.js queryAndSetConfigs

  handleAppContainerClick = () => {
    this.resetView(true)
  }

  resetView = selected => {
    selected && this.resetSelected()
  }

  resetSelected = () => {
    const {
      actions: { selectItem, selectSubItem, setSelectedBarrierSection },
    } = this.props

    selectItem({ name: '-1', __typename: 'PlanProgressionMilestone' })
    selectItem({ name: '-1', __typename: 'PlanDischargeMilestone' })
    selectItem({ name: '-1', __typename: 'PlanBarrier' })
    selectItem({ name: '-1', __typename: 'PlanAction' })
    selectItem({ name: '-1', __typename: 'PlanNote' })
    selectItem({ name: '-1', __typename: 'FavoriteBarrier' })
    selectSubItem({ name: '-1', __typename: 'PlanAction' })
    setSelectedBarrierSection({
      selectedBarrierId: '-1',
      selectedPlanId: '-1',
      showAllBarriers: false,
    })
  }

  render() {

    const {
      routes,
      authState,
    } = this.props

    // wait till configs loaded for all to use
    if (this.state.caAppConfigsLoading && authState.token) {
      // can't retrieve until we've got token
      queryAndSetConfigs(this, onConfigsLoaded)
      return null
    }

    return (
      <MuiThemeProvider theme={theme}>
        <V0MuiThemeProvider muiTheme={muiThemeV0}>
          <BrowserRouter>
            <div className="app-container" onClick={this.handleAppContainerClick}>
              {authState.token && !this.state.caAppConfigsLoading && (
                <div>
                  <IdleTimerContainer />
                  <NetworkStatus />
                  <TokenRefresher authState={authState} />
                  <AppHeader
                    routes={routes}
                    authState={authState}
                  />
                  <div className="nav-container">
                    <NavBar
                      routes={routes}
                      authState={authState}
                    />
                  </div>
                </div>
              )}

              {/*when no authState.token this will supply Login*/}
              <div className="app-route-container">{generateRoutes()}</div>

              <StandardDrawerContainer />
              <ConfirmLogout />
             </div>
          </BrowserRouter>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    )
  }
}

export default hot(App)
