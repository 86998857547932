import React from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import CodeTableRow from "./CodeTableRow"
import PropTypes from 'prop-types'

const CodeTableRowList = ({list, onEdit}) => {
  return (
    <React.Fragment>
      {list.map(item => <CodeTableRow key={item.__id} rowData={item} onEdit={onEdit}/>)}
    </React.Fragment>
  )
}

CodeTableRowList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired
}

export default createFragmentContainer(CodeTableRowList, {
  list: graphql`
    fragment CodeTableRowList_list on CodeTable @relay(plural: true) {
      ...CodeTableRow_rowData
    }
  `,
})
