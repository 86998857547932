/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type BarrierStatus = "Open" | "Resolved" | "%future added value";
export type MilestoneStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type ProgressionIndicator = "AtRisk" | "HighRisk" | "NotSet" | "OnTime" | "%future added value";
export type Track = "Discharge" | "Progression" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type plan_FullPlan$ref: FragmentReference;
declare export opaque type plan_FullPlan$fragmentType: plan_FullPlan$ref;
export type plan_FullPlan = {|
  +id: string,
  +appliedTemplates: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +description: string,
    +documentURL: ?string,
  |}>,
  +careTeamMembers: $ReadOnlyArray<?{|
    +id: string,
    +name: ?string,
    +primary: ?boolean,
    +role: {|
      +id: string,
      +code: ?string,
      +value: ?string,
    |},
  |}>,
  +progressionIndicator: ProgressionIndicator,
  +toBeAdmitted: ?boolean,
  +meta: ?{|
    +milestoneMeta: {|
      +onTime: any,
      +overdue: any,
      +completed: any,
    |},
    +lengthOfStayMeta: {|
      +forecast: ?any,
      +cappedForecast: ?any,
    |},
    +actualOE: ?number,
  |},
  +milestones: $ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +description: ?string,
    +track: ?Track,
    +dueDate: ?any,
    +completedDate: ?any,
    +status: MilestoneStatus,
    +planTemplateName: string,
    +actions: $ReadOnlyArray<?{|
      +id: string,
      +text: string,
      +status: ActionStatus,
      +pinned: boolean,
      +auditMeta: ?{|
        +created: any,
        +createdBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
        +updated: any,
        +updatedBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
      |},
      +milestoneTemplateName: ?string,
    |}>,
  |}>,
  +barriers: $ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +description: string,
    +status: BarrierStatus,
    +pinned: boolean,
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +updated: any,
      +updatedBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |},
    +category: {|
      +code: string,
      +value: string,
    |},
    +escalation: ?{|
      +auditMeta: ?{|
        +created: any,
        +createdBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
      |}
    |},
    +owner: ?string,
    +ownerRole: ?{|
      +code: ?string,
      +value: ?string,
    |},
    +notes: ?$ReadOnlyArray<?{|
      +id: string,
      +text: string,
      +auditMeta: ?{|
        +created: any,
        +createdBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
        +updated: any,
        +updatedBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
      |},
      +pinned: boolean,
    |}>,
  |}>,
  +actions: $ReadOnlyArray<?{|
    +id: string,
    +text: string,
    +status: ActionStatus,
    +pinned: boolean,
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +updated: any,
      +updatedBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |},
  |}>,
  +notes: $ReadOnlyArray<?{|
    +id: string,
    +text: string,
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +updated: any,
      +updatedBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |},
    +pinned: boolean,
  |}>,
  +$refType: plan_FullPlan$ref,
|};
export type plan_FullPlan$data = plan_FullPlan;
export type plan_FullPlan$key = {
  +$data?: plan_FullPlan$data,
  +$fragmentRefs: plan_FullPlan$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditUser",
      "kind": "LinkedField",
      "name": "updatedBy",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v13 = [
  (v0/*: any*/),
  (v6/*: any*/),
  (v11/*: any*/),
  (v7/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "plan_FullPlan",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TemplateInfo",
      "kind": "LinkedField",
      "name": "appliedTemplates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "documentURL",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CareTeamMember",
      "kind": "LinkedField",
      "name": "careTeamMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CodeTablePair",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "progressionIndicator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toBeAdmitted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlanMeta",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MilestoneMeta",
          "kind": "LinkedField",
          "name": "milestoneMeta",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "overdue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completed",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LengthOfStayMeta",
          "kind": "LinkedField",
          "name": "lengthOfStayMeta",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecast",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cappedForecast",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualOE",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestones",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "track",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedDate",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planTemplateName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MilestoneAction",
          "kind": "LinkedField",
          "name": "actions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v6/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/),
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "milestoneTemplateName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Barrier",
      "kind": "LinkedField",
      "name": "barriers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        (v7/*: any*/),
        (v11/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BarrierCategory",
          "kind": "LinkedField",
          "name": "category",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Escalation",
          "kind": "LinkedField",
          "name": "escalation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AuditMeta",
              "kind": "LinkedField",
              "name": "auditMeta",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                (v10/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CodeTablePair",
          "kind": "LinkedField",
          "name": "ownerRole",
          "plural": false,
          "selections": (v12/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BarrierNote",
          "kind": "LinkedField",
          "name": "notes",
          "plural": true,
          "selections": (v13/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlanAction",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v6/*: any*/),
        (v5/*: any*/),
        (v7/*: any*/),
        (v11/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PlanNote",
      "kind": "LinkedField",
      "name": "notes",
      "plural": true,
      "selections": (v13/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Plan",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '5474bdb845bc88d8fa15a98dc5cbcea3';

module.exports = node;
