import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withApplications, applicationsPropType } from './withApplications'

@withApplications
export class AppRequired extends Component {
  static propTypes = {
    applications: applicationsPropType,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    userHasAccess: PropTypes.bool,

    // Add Applications here as they're known.
    // We could do this more dynamically, but this is more readable
    // when actually using the component
    patientLogistics: PropTypes.bool,
    orderLogistics: PropTypes.bool,
    demandLogistics: PropTypes.bool,
    careAdvance: PropTypes.bool,
  }

  static defaultProps = {
    userHasAccess: false,

    // Add Applications here as they're known.
    patientLogistics: false,
    orderLogistics: false,
    demandLogistics: false,
    careAdvance: false,
  }

  shouldComponentUpdate(nextProps) {
    // The only time the applications list will really be updated is when the user logs in or out
    // and when the refresh token happens
    if (this.props.applications !== nextProps.applications) {
      return true
    } else if (
      this.props.userHasAccess !== nextProps.userHasAccess ||
      // Add Applications here as they're known.
      this.props.patientLogistics !== nextProps.patientLogistics ||
      this.props.orderLogistics !== nextProps.orderLogistics ||
      this.props.demandLogistics !== nextProps.demandLogistics ||
      this.props.careAdvance !== nextProps.careAdvance
    ) {
      // Chances that any of these will change is pretty low
      return true
    }
    return false
  }

  render() {
    const {
      applications,
      children,
      userHasAccess,
      // Add Applications here as they're known.
      patientLogistics,
      orderLogistics,
      demandLogistics,
      careAdvance,
    } = this.props

    // Add Applications here as they're known.
    const appsToCheck = {
      patientLogistics,
      orderLogistics,
      demandLogistics,
      careAdvance,
    }

    let allowed = !!applications
    if (applications) {
      for (let [app, required] of Object.entries(appsToCheck)) {
        if (
          required &&
          // If it's required, it must be enabled
          // If access is required, user must have the app assigned
          (!applications[app] ||
            !applications[app].enabled ||
            (userHasAccess && !applications[app].access))
        ) {
          allowed = false
          break
        }
      }
    }

    return allowed ? children : null
  }
}
