/* eslint-disable react/no-set-state */

import React from 'react'
import PropTypes from 'prop-types'

import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'

import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'

export class CreateBarrier extends React.Component {
  static propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    createBarrierFromTemplate: PropTypes.func, // Needs a graphql mutation on this class
    patient: PropTypes.object,
    plan: PropTypes.object,
  }

  // Stubbed out example of a possible way to save and close the drawer upon success
  handleCreateBarrierFromTemplate(planId, templateId) {
    this.props
      .createBarrierFromTemplate(planId, templateId)
      .then(this.props.onRequestClose)
  }

  render() {
    const {
      plan, // Not sure what needs to be passed here, but it should be passed as part of showDrawer()
      onRequestClose,
    } = this.props

    return (
      <div>
        <DrawerHeaderBar
          title="Create Barrier"
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
        />
        <h1 />
      </div>
    )
  }
}
