import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { PlanHeader } from '../../components/plan'
import { showDrawer } from '../../ducks/views'
import { showErrorsPg } from '../../ducks/errorspg'
import { drawers } from '../drawer-component-mapping'
import {
  upgradeProgressionIndicator,
  updateToBeAdmitted
} from '../../graphql/relay/queries/plan'
import {
  updatePatientProblem,
  updateBedRequestStatus,
  updateAnticipatedDischargeTime,
  updateTargetLos,
  updateEstimatedTransferDate
} from '../../graphql/relay/queries/visit'
import {
  pinBarrier,
  unpinBarrier,
  pinMilestoneAction,
  unpinMilestoneAction,
  pinPlanAction,
  unpinPlanAction,
  pinPlanNote,
  unpinPlanNote,
} from '../../graphql/relay/queries/pin'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'

export const PlanHeaderGQL = compose(
   connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  mapProps(props => newProps(props))
)(PlanHeader)

const newProps = props => {
  const {
    workspace,
    currentRoute: { viewType },
    actions: { showDrawer, showErrorsPg },
    refetch
  } = props

  const { plan, visit } = workspace

  const onPatientProblemUpdate = (visitId, patientProblem) => {

    const variables = { visitId: visitId, patientProblem: patientProblem }
    handleMutation(environment, updatePatientProblem, variables)
  }

  const onTargetLosUpdate = (visitId, targetLos) => {
    const variables = { visitId: visitId, targetLos: targetLos }
    handleMutation(environment, updateTargetLos, variables, forceUpdateGrid)
  }

  const forceUpdateGrid = () => {
    props.refresh ? props.refresh() : props.rvlist? props.rvlist.forceUpdateGrid() : null
  }

  const showError = (errKey, errCode, errMessage) => {
    showErrorsPg([
      {
        key: errKey,
        code: errCode,
        message: errMessage,
      },
    ])
  }

  const onProgressionIndicatorUpdate = (
    planId,
    progressionIndicator
  ) => {
    handleMutation(
      environment,
      upgradeProgressionIndicator,
      {
        planId,
        progressionIndicator,
      },
      forceUpdateGrid)
  }

  const onToBeAdmittedUpdate = (planId, toBeAdmitted) => {
    handleMutation(
      environment,
      updateToBeAdmitted,
      {
        planId,
        toBeAdmitted,
      },
      forceUpdateGrid
    )
  }

  const onEstimatedTransferDateUpdate = (anticipatedTransferTime, visitId, autoPopulated = false) => {
    handleMutation(
      environment,
      updateEstimatedTransferDate,
      {
        visitId: visitId,
        anticipatedTransferTime: anticipatedTransferTime,
        anticipatedTransferTimeAutoPopulated: autoPopulated,
      },
      forceUpdateGrid
    )
  }

  const onPlannedDischargeTimeUpdate = (dischargeDateTime, visitId, autoPopulated = false) => {
    handleMutation(
      environment,
      updateAnticipatedDischargeTime,
      {
        visitId: visitId,
        anticipatedDischargeTime: dischargeDateTime,
        anticipatedDischargeTimeAutoPopulated: autoPopulated,
      },
      forceUpdateGrid
    )
  }

  const onVisitStatusUpdate = (visitId, status, anticipatedDischargeTime, anticipatedTransferTime) => {
    handleMutation(
      environment,
      updateBedRequestStatus,
      {
        visitId,
        status,
        anticipatedDischargeTime,
        anticipatedTransferTime
      },
      forceUpdateGrid
    )
  }

  const onWorkingDischargeDispositionUpdate = visit => {
    showDrawer(drawers.EDIT_WORKING_DC, {
      visit,
      grid: props.rvlist,
      refresh: props.refresh,
      refetch: props.refetch
    })
  }

  const onSearchDRG = visit => {
    showDrawer(drawers.DRG_VIEW, {
      visit,
      grid: props.rvlist,
      refresh: props.refresh
    })
  }

  const onApplyPlan = () => {
    const {
      id,
      patient,
      decisionTime,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
    } = visit

    const title = `Apply Plan for ${patient.lastName}, ${patient.firstName}`

    showDrawer(drawers.CHOOSE_PLAN, {
      title,
      id,
      plan,
      patient,
      decisionTime,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
      grid: props.rvlist,
      refresh: props.refresh
    })
  }

  const onEditDecisionTime = () => {
    const {
      patient,
      id,
      admissionType,
      decisionTime,
      decisionTimeUseMidnight,
    } = visit
    const createOrEDIT = decisionTime ? 'Edit' : 'Create'
    const title = `${createOrEDIT} Decision Time for ${patient.lastName},
      ${patient.firstName}`

    showDrawer(drawers.DECISION_TIME_PICKER, {
      title,
      visitId: id,
      admissionType,
      decisionTime,
      decisionTimeUseMidnight,
      grid: props.rvlist,
      refresh: props.refresh
    })
  }

  const onUpdateCarePin = item => {
    let fn
    switch (item.__typename) {
      case 'Barrier':
        fn = item.pinned ? unpinBarrier : pinBarrier
        break
      case 'PlanAction':
        fn = item.pinned ? unpinPlanAction : pinPlanAction
        break
      case 'MilestoneAction':
        fn = item.pinned ? unpinMilestoneAction : pinMilestoneAction
        break
      case 'PlanNote':
        fn = item.pinned ? unpinPlanNote : pinPlanNote
        break
    }


    const variables = {
      itemId: item.id,
    }
    handleMutation(environment, fn, variables, forceUpdateGrid)
  }

  return {
    ...props,
    viewType,
    workspace,
    onPatientProblemUpdate,
    onTargetLosUpdate,
    onProgressionIndicatorUpdate,
    onToBeAdmittedUpdate,
    onEstimatedTransferDateUpdate,
    onWorkingDischargeDispositionUpdate,
    onVisitStatusUpdate,
    onPlannedDischargeTimeUpdate,
    onUpdateCarePin,
    onSearchDRG,
    onApplyPlan,
    onEditDecisionTime,
  }
}

function mapStateToProps(state) {
  return {
    currentRoute: state.viewsAppState.currentRoute,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer: showDrawer,
        showErrorsPg: showErrorsPg,
      },
      dispatch
    ),
  }
}
