/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DepartmentType = "AdmissionSource" | "Unit" | "%future added value";
export type AssignBeds_AllAssignedBeds_QueryVariables = {||};
export type AssignBeds_AllAssignedBeds_QueryResponse = {|
  +viewer: {|
    +beds: {|
      +nodes: $ReadOnlyArray<{|
        +department: {|
          +type: DepartmentType,
          +exchangeName: string,
          +name: string,
        |},
        +exchangeName: string,
        +name: string,
        +status: string,
      |}>
    |}
  |}
|};
export type AssignBeds_AllAssignedBeds_Query = {|
  variables: AssignBeds_AllAssignedBeds_QueryVariables,
  response: AssignBeds_AllAssignedBeds_QueryResponse,
|};
*/


/*
query AssignBeds_AllAssignedBeds_Query {
  viewer {
    beds(first: 10000, query: "allBeds", type: PATIENT) {
      nodes {
        department {
          type
          exchangeName
          name
          id
        }
        exchangeName
        name
        status
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "query",
    "value": "allBeds"
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "PATIENT"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignBeds_AllAssignedBeds_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "BedConnection",
            "kind": "LinkedField",
            "name": "beds",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bed",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "department",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "beds(first:10000,query:\"allBeds\",type:\"PATIENT\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AssignBeds_AllAssignedBeds_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "BedConnection",
            "kind": "LinkedField",
            "name": "beds",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bed",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "department",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "beds(first:10000,query:\"allBeds\",type:\"PATIENT\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c75acfe41f7e5a60c4bc33f768b619ea",
    "id": null,
    "metadata": {},
    "name": "AssignBeds_AllAssignedBeds_Query",
    "operationKind": "query",
    "text": "query AssignBeds_AllAssignedBeds_Query {\n  viewer {\n    beds(first: 10000, query: \"allBeds\", type: PATIENT) {\n      nodes {\n        department {\n          type\n          exchangeName\n          name\n          id\n        }\n        exchangeName\n        name\n        status\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2ff5f77b1e6934c71d6280d28e3b546';

module.exports = node;
