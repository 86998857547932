import React from 'react'
import { PatientPlan } from './PatientPlan/PatientPlan'
import { QueryRenderer, graphql } from 'react-relay'
import { environment } from '../graphql/relay/env'
import PropTypes from 'prop-types'
import { PatientPlanQuery} from "./PatientPlan/PatientPlanGQL"
import { LoadingIndicator } from '../components/loader'

export class ConflictedPatientPlanGQL extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      caAppConfigsLoading: true,
    }
  }


  render() {
    return (
      <QueryRenderer
        environment={environment}
        query={PatientPlanQuery}
        variables={{ id: this.props.match.params.id }}
        render={({ error, props, retry }) => {
          if (error) {
            return <div>Error!</div>
          }
          if (!props) {
            return <LoadingIndicator />
          }
          return (<PatientPlan
            visit={props.node}
            userSetting={props.userSetting}
            barrierEscalationRoles={props.barrierEscalationRoles}
            deliveryMethods={props.deliveryMethods}
            refetch={retry}
            {...this.props} />)
        }}
      />
    )
  }
}
ConflictedPatientPlanGQL.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

