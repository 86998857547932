import React from 'react'
import PropTypes from 'prop-types'

import './milestone.scss'
import moment from 'moment'
import classNames from 'classnames'

import MilestoneTimelineIcon from 'mdi-react/NatureIcon'
import ZeroIcon from 'mdi-react/Numeric0BoxIcon'

// todo These colors should be externalized for consistency
import {
  green500 as OpenColor,
  black as CompletedColor,
  black as WarnBGColor,
  grey600 as NAColor,
  yellow500 as WarnColor,
  red500 as OverdueColor,
} from 'material-ui/styles/colors'

// CA-2479 - deactivated late in CA 2.0
// but don't delete this file as it will be
// resurrected in 2.1 with new backend logic see CA-2313
// For now we just have this component render nothing
export const MilestoneSummaryStatusIcon = ({
  milestones = [],
  milestoneStatuses,
  className,
  ...props
}) => {
  // const statuses = milestoneStatuses
  //   ? milestoneStatuses
  //   : determineMilestoneStatuses(milestones)

  return null
  // what we rendered before CA-2479 - for future use
  // return (
  //   <div
  //     className={classNames('milestone-summary-status-icon', className)}
  //     title={`Current Milestones - On Time: ${statuses.onTime} Past Due: ${
  //       statuses.overdue
  //     } Completed: ${statuses.completed}`}
  //     {...props}>
  //     <MilestoneTimelineIcon
  //       className="svg"
  //       style={{
  //         fill:
  //           !!statuses.overdue && !statuses.onTime
  //             ? OverdueColor
  //             : statuses.onTime
  //             ? OpenColor
  //             : CompletedColor,
  //       }}
  //     />
  //     {!!statuses.overdue && (
  //       <div className="secondary">
  //         <MilestoneTimelineIcon
  //           style={{ fill: OverdueColor }}
  //           className="svg"
  //         />
  //       </div>
  //     )}
  //     {statuses.overdue + statuses.onTime + statuses.completed === 0 && (
  //       <div className="warning-zero">
  //         <ZeroIcon
  //           width={12}
  //           height={12}
  //           style={{ fill: WarnColor }}
  //           className="svg"
  //         />
  //       </div>
  //     )}
  //   </div>
  // )
}

MilestoneSummaryStatusIcon.propTypes = {
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(['Open', 'Completed', 'NA']),
      dueDate: PropTypes.string,
    })
  ),
  milestoneStatuses: PropTypes.object,
  className: PropTypes.string,
}

/**
 * Helper function to assess the status of the milestones
 * @param milestones
 */
// function determineMilestoneStatuses(
//   milestones: [{ status: string, dueDate: string }]
// ) {
//   // todo Need to filter out non-current milestones when the server adds that
//   return milestones.reduce(
//     (statuses, milestone) => {
//       if (milestone.status === 'Completed') {
//         statuses.completed++
//       } else if (milestone.status === 'Open') {
//         if (milestone.dueDate && moment().isAfter(milestone.dueDate)) {
//           statuses.overdue++
//         } else {
//           statuses.onTime++
//         }
//       }
//       return statuses
//     },
//     { onTime: 0, overdue: 0, completed: 0 }
//   )
// }
