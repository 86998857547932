import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import sortBy from 'lodash.sortby'

import { SelectField, MenuItem } from 'material-ui'

const codeTableQuery = gql`
  query codeTables($codeTableType: CodeType!) {
    codeTables(codeTableType: $codeTableType) {
      active
      code
      defaultValue
      ordinal
      value
    }
  }
`

@graphql(codeTableQuery, {
  options: props => ({ variables: { codeTableType: props.codeTableType } }),
})
class CodeTableSelect extends Component {
  static propTypes = {
    data: PropTypes.shape({
      codeTables: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          value: PropTypes.string,
          active: PropTypes.bool,
          ordinal: PropTypes.number,
          defaultValue: PropTypes.bool,
        })
      ),
    }),
    value: PropTypes.string,
    componentProps: PropTypes.object,
    codeTableType: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    noNullValue: PropTypes.bool,
  }

  static defaultProps = {
    data: {
      codeTables: [],
    },
    componentProps: {},
    onChange: () => {},
  }

  render() {
    const {
      data: { codeTables = [] },
      value,
      componentProps,
      onChange,
      noNullValue,
    } = this.props

    const codeTableEntries = sortBy(codeTables.filter(entry => entry.active), [
      'ordinal',
    ])
    // CA-1498 - a previously set value that has since been deactivated will
    // be shown in hintText, and in that case valueOrDefault must be empty to
    // get hintText displayed. Ext Combos are so much better at handling this
    // via forceSelection config
    const hintText = codeTableEntries.find(entry => entry.code === value)
      ? ''
      : value
    const valueOrDefault = hintText
      ? ''
      : value || codeTables.find(entry => entry.defaultValue)

    return (
      <SelectField
        {...componentProps}
        value={valueOrDefault}
        hintText={hintText || componentProps.hintText}
        onChange={onChange}>
        {!noNullValue && <MenuItem value={null} primaryText="" />}
        {codeTableEntries.map(
          entry =>
            entry.active && (
              <MenuItem
                key={entry.code}
                value={entry.code}
                primaryText={entry.value}
              />
            )
        )}
      </SelectField>
    )
  }
}

export default CodeTableSelect
