import gql, {injectForGqlFile} from '../util/gql'

export const UserGQL = {
  fragments: {},
  queries: {
    userSetting: gql`
      query userSetting {
        userSetting {
          barriers {
            id
            name
            description
            active
            category {
              code
              value
            }
            templateId
            ...Updated
          }
          actions {
            id
            text
            ...Updated
          }
          notes {
            id
            text
          }
        }
      }
    `,
  },
  mutations: {
    createFavoriteBarrier: gql`
      mutation createFavoriteBarrier(
        $name: String!
        $description: String!
        $categoryCode: String!
      ) {
        userSettingMutation {
          createFavoriteBarrier(
            name: $name
            description: $description
            categoryCode: $categoryCode
          ) {
            id
            name
            description
            category {
              code
              value
            }
            templateId
            ...Updated
          }
        }
      }
    `,
    createFavoriteBarrierFromTemplate: gql`
      mutation createFavoriteBarrierFromTemplate($templateId: UUID!) {
        userSettingMutation {
          createFavoriteBarrierFromTemplate(templateId: $templateId) {
            id
            name
            description
            category {
              code
              value
            }
            templateId
            ...Updated
          }
        }
      }
    `,
    removeFavoriteBarrier: gql`
      mutation removeFavoriteBarrier($id: UUID!) {
        userSettingMutation {
          removeFavoriteBarrier(id: $id)
        }
      }
    `,
    updateFavoriteActions: gql`
      mutation updateFavoriteActions($actions: [String]!) {
        userSettingMutation {
          updateFavoriteActions(actions: $actions) {
            id
            text
            ...Updated
          }
        }
      }
    `,
    updateFavoriteNotes: gql`
      mutation updateFavoriteNotes($notes: [String]!) {
        userSettingMutation {
          updateFavoriteNotes(notes: $notes) {
            id
            text
            ...Updated
          }
        }
      }
    `,
  },
}

injectForGqlFile(UserGQL)
