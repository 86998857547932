/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type AdmissionType = "InPatient" | "Observation" | "OutPatient" | "%future added value";
export type BarrierStatus = "Open" | "Resolved" | "%future added value";
export type BedRequestStatus = "Anticipated" | "AnticipatedDischarge" | "AnticipatedUnitTransfer" | "Cancelled" | "CheckedIn" | "DischargeOrderWithDelay" | "Discharged" | "IntentToAdmit" | "Pending" | "PendingDischarge" | "PendingUnitTransfer" | "Ready" | "ReadyForDischarge" | "ReadyForUnitTransfer" | "Transferred" | "%future added value";
export type BedRequestType = "Admission" | "UnitTransfer" | "%future added value";
export type DepartmentType = "AdmissionSource" | "Unit" | "%future added value";
export type OwnerMessageStatus = "ACKNOWLEDGED" | "NEEDS_REASSIGNMENT" | "UNACKNOWLEDGED" | "%future added value";
export type ProgressionIndicator = "AtRisk" | "HighRisk" | "NotSet" | "OnTime" | "%future added value";
export type VisitStatus = "Admitted" | "Discharged" | "PreAdmission" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PlanListPaginationContainer_search$ref: FragmentReference;
declare export opaque type PlanListPaginationContainer_search$fragmentType: PlanListPaginationContainer_search$ref;
export type PlanListPaginationContainer_search = {|
  +viewer: {|
    +beds: {|
      +totalCount: number,
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
        +startCursor: ?string,
        +totalCount: string,
      |},
      +edges: $ReadOnlyArray<{|
        +bed: {|
          +id: string,
          +name: string,
          +exchangeName: string,
          +status: string,
          +department: {|
            +id: string,
            +exchangeName: string,
            +name: string,
            +type: DepartmentType,
          |},
          +visit: ?{|
            +id: string,
            +vector: ?{|
              +id: string,
              +patientId: string,
              +encounterId: string,
              +predictionDate: any,
              +conditions: ?$ReadOnlyArray<?{|
                +name: string,
                +riskBracket: ?string,
                +riskFactors: $ReadOnlyArray<?{|
                  +id: string,
                  +type: string,
                  +description: string,
                |}>,
                +recommendations: $ReadOnlyArray<?{|
                  +id: string,
                  +rank: string,
                  +description: string,
                  +code: string,
                  +accepted: ?boolean,
                |}>,
              |}>,
            |},
            +admissionTime: ?any,
            +admissionType: ?AdmissionType,
            +admittingComplaint: ?string,
            +anticipatedDischargeTime: ?any,
            +anticipatedDischargeUpdatedBy: ?{|
              +id: string,
              +userName: string,
              +firstName: string,
              +lastName: string,
            |},
            +attendingPhysician: ?{|
              +firstName: ?string,
              +id: string,
              +lastName: ?string,
            |},
            +bedRequestStatus: ?BedRequestStatus,
            +bedRequests: $ReadOnlyArray<{|
              +id: string,
              +bedRequestType: BedRequestType,
              +bedRequestStatus: BedRequestStatus,
              +updatedWhen: any,
              +bedExchangeName: ?string,
            |}>,
            +currentDayOfStay: ?number,
            +decisionTime: ?any,
            +decisionTimeEditable: boolean,
            +decisionTimeUseMidnight: boolean,
            +dischargeVendor: ?string,
            +drgAttributes: ?{|
              +id: string,
              +code: string,
              +level: string,
              +modifyTargetLos: boolean,
              +title: string,
            |},
            +drgUpdatedBy: ?{|
              +id: string,
              +firstName: string,
              +lastName: string,
              +userName: string,
            |},
            +homeBedExchangeName: ?string,
            +insurancePlans: $ReadOnlyArray<{|
              +id: string,
              +planName: string,
              +priority: any,
            |}>,
            +meta: {|
              +id: string,
              +decisionDue: boolean,
              +dischargeToday: boolean,
              +plannedDischargeExceeded: boolean,
              +timeElapsedSinceAdmission: any,
              +tlosExceeded: boolean,
            |},
            +patient: {|
              +id: string,
              +age: ?string,
              +firstName: ?string,
              +gender: ?string,
              +lastName: ?string,
              +middleInitial: ?string,
              +mrn: ?string,
              +language: ?{|
                +code: ?string,
                +defaultValue: ?string,
              |},
            |},
            +patientAlerts: $ReadOnlyArray<?{|
              +id: string,
              +alertType: string,
              +value: string,
            |}>,
            +patientProblem: ?string,
            +readmission: ?boolean,
            +readmissionRisk: ?boolean,
            +targetDischargeTime: ?any,
            +tlos: ?number,
            +visitNumber: ?string,
            +visitFacilityId: ?any,
            +visitStatus: VisitStatus,
            +workingDischargeDisposition: ?{|
              +code: ?string,
              +id: string,
              +value: ?string,
              +workingDischargeDispositionGroup: ?string,
            |},
            +plan: ?{|
              +id: string,
              +actions: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +text: string,
                +pinned: boolean,
                +status: ActionStatus,
                +auditMeta: ?{|
                  +updated: any
                |},
              |}>,
              +appliedTemplates: ?$ReadOnlyArray<?{|
                +id: string,
                +name: string,
                +description: string,
                +documentURL: ?string,
                +removed: boolean,
              |}>,
              +barriers: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +name: string,
                +description: string,
                +pinned: boolean,
                +status: BarrierStatus,
                +escalation: ?{|
                  +id: string
                |},
                +auditMeta: ?{|
                  +updated: any
                |},
                +ownerMessageStatus: ?OwnerMessageStatus,
                +ownerKnownUser: ?{|
                  +id: string,
                  +emailReceiver: boolean,
                  +instantMessageReceiver: boolean,
                |},
              |}>,
              +careTeamMembers: $ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
                +role: {|
                  +id: string,
                  +code: ?string,
                  +value: ?string,
                |},
                +primary: ?boolean,
              |}>,
              +meta: ?{|
                +milestoneMeta: {|
                  +overdue: any,
                  +onTime: any,
                  +completed: any,
                |}
              |},
              +milestones: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +actions: $ReadOnlyArray<?{|
                  +__typename: string,
                  +id: string,
                  +pinned: boolean,
                  +text: string,
                  +auditMeta: ?{|
                    +updated: any
                  |},
                |}>,
              |}>,
              +notes: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +text: string,
                +pinned: boolean,
                +auditMeta: ?{|
                  +updated: any
                |},
              |}>,
              +progressionIndicator: ProgressionIndicator,
              +toBeAdmitted: ?boolean,
            |},
            +anticipatedTransferTime: ?any,
            +anticipatedDischargeTimeAutoPopulated: ?boolean,
            +anticipatedTransferTimeAutoPopulated: ?boolean,
          |},
          +dischargedVisits: ?$ReadOnlyArray<?{|
            +id: string,
            +admissionTime: ?any,
            +admissionType: ?AdmissionType,
            +admittingComplaint: ?string,
            +anticipatedDischargeTime: ?any,
            +anticipatedDischargeUpdatedBy: ?{|
              +id: string,
              +userName: string,
              +firstName: string,
              +lastName: string,
            |},
            +attendingPhysician: ?{|
              +firstName: ?string,
              +id: string,
              +lastName: ?string,
            |},
            +bedRequestStatus: ?BedRequestStatus,
            +bedRequests: $ReadOnlyArray<{|
              +id: string,
              +bedRequestType: BedRequestType,
              +bedRequestStatus: BedRequestStatus,
              +updatedWhen: any,
              +bedExchangeName: ?string,
            |}>,
            +currentDayOfStay: ?number,
            +decisionTime: ?any,
            +decisionTimeEditable: boolean,
            +decisionTimeUseMidnight: boolean,
            +dischargeVendor: ?string,
            +drgAttributes: ?{|
              +id: string,
              +code: string,
              +level: string,
              +title: string,
            |},
            +drgUpdatedBy: ?{|
              +id: string,
              +firstName: string,
              +lastName: string,
              +userName: string,
            |},
            +homeBedExchangeName: ?string,
            +insurancePlans: $ReadOnlyArray<{|
              +id: string,
              +planName: string,
              +priority: any,
            |}>,
            +meta: {|
              +id: string,
              +decisionDue: boolean,
              +dischargeToday: boolean,
              +plannedDischargeExceeded: boolean,
              +timeElapsedSinceAdmission: any,
              +tlosExceeded: boolean,
            |},
            +patient: {|
              +id: string,
              +age: ?string,
              +firstName: ?string,
              +gender: ?string,
              +lastName: ?string,
              +middleInitial: ?string,
              +mrn: ?string,
              +language: ?{|
                +code: ?string,
                +defaultValue: ?string,
              |},
            |},
            +patientAlerts: $ReadOnlyArray<?{|
              +id: string,
              +alertType: string,
              +value: string,
            |}>,
            +patientProblem: ?string,
            +readmission: ?boolean,
            +readmissionRisk: ?boolean,
            +targetDischargeTime: ?any,
            +tlos: ?number,
            +visitNumber: ?string,
            +visitFacilityId: ?any,
            +visitStatus: VisitStatus,
            +workingDischargeDisposition: ?{|
              +code: ?string,
              +id: string,
              +value: ?string,
            |},
            +plan: ?{|
              +id: string,
              +actions: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +text: string,
                +pinned: boolean,
                +status: ActionStatus,
                +auditMeta: ?{|
                  +updated: any
                |},
              |}>,
              +appliedTemplates: ?$ReadOnlyArray<?{|
                +id: string,
                +name: string,
                +description: string,
                +documentURL: ?string,
                +removed: boolean,
              |}>,
              +barriers: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +name: string,
                +description: string,
                +pinned: boolean,
                +status: BarrierStatus,
                +escalation: ?{|
                  +id: string
                |},
                +auditMeta: ?{|
                  +updated: any
                |},
              |}>,
              +careTeamMembers: $ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
                +role: {|
                  +id: string,
                  +code: ?string,
                  +value: ?string,
                |},
                +primary: ?boolean,
              |}>,
              +meta: ?{|
                +milestoneMeta: {|
                  +overdue: any,
                  +onTime: any,
                  +completed: any,
                |}
              |},
              +milestones: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +actions: $ReadOnlyArray<?{|
                  +id: string,
                  +pinned: boolean,
                  +text: string,
                  +auditMeta: ?{|
                    +updated: any
                  |},
                |}>,
              |}>,
              +notes: $ReadOnlyArray<?{|
                +__typename: string,
                +id: string,
                +text: string,
                +pinned: boolean,
                +auditMeta: ?{|
                  +updated: any
                |},
              |}>,
              +progressionIndicator: ProgressionIndicator,
              +toBeAdmitted: ?boolean,
            |},
          |}>,
        |}
      |}>,
    |}
  |},
  +$refType: PlanListPaginationContainer_search$ref,
|};
export type PlanListPaginationContainer_search$data = PlanListPaginationContainer_search;
export type PlanListPaginationContainer_search$key = {
  +$data?: PlanListPaginationContainer_search$data,
  +$fragmentRefs: PlanListPaginationContainer_search$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admissionTime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admissionType",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admittingComplaint",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "anticipatedDischargeTime",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "anticipatedDischargeUpdatedBy",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonnelRole",
  "kind": "LinkedField",
  "name": "attendingPhysician",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v1/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bedRequestStatus",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "BedRequest",
  "kind": "LinkedField",
  "name": "bedRequests",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bedRequestType",
      "storageKey": null
    },
    (v17/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedWhen",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bedExchangeName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentDayOfStay",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionTime",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionTimeEditable",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionTimeUseMidnight",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeVendor",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "drgUpdatedBy",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homeBedExchangeName",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "concreteType": "InsurancePlan",
  "kind": "LinkedField",
  "name": "insurancePlans",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "concreteType": "VisitMeta",
  "kind": "LinkedField",
  "name": "meta",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decisionDue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dischargeToday",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedDischargeExceeded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeElapsedSinceAdmission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tlosExceeded",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "Patient",
  "kind": "LinkedField",
  "name": "patient",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "age",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    (v14/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "middleInitial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mrn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Language",
      "kind": "LinkedField",
      "name": "language",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "concreteType": "PatientAlert",
  "kind": "LinkedField",
  "name": "patientAlerts",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alertType",
      "storageKey": null
    },
    (v31/*: any*/)
  ],
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientProblem",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmission",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmissionRisk",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetDischargeTime",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlos",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitNumber",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitFacilityId",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitStatus",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "concreteType": "PlanAction",
  "kind": "LinkedField",
  "name": "actions",
  "plural": true,
  "selections": [
    (v41/*: any*/),
    (v1/*: any*/),
    (v42/*: any*/),
    (v43/*: any*/),
    (v4/*: any*/),
    (v44/*: any*/)
  ],
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "concreteType": "TemplateInfo",
  "kind": "LinkedField",
  "name": "appliedTemplates",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentURL",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "removed",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "concreteType": "Escalation",
  "kind": "LinkedField",
  "name": "escalation",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "concreteType": "CareTeamMember",
  "kind": "LinkedField",
  "name": "careTeamMembers",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CodeTablePair",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v7/*: any*/),
        (v31/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primary",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "PlanMeta",
  "kind": "LinkedField",
  "name": "meta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MilestoneMeta",
      "kind": "LinkedField",
      "name": "milestoneMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overdue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "concreteType": "PlanNote",
  "kind": "LinkedField",
  "name": "notes",
  "plural": true,
  "selections": [
    (v41/*: any*/),
    (v1/*: any*/),
    (v42/*: any*/),
    (v43/*: any*/),
    (v44/*: any*/)
  ],
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progressionIndicator",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toBeAdmitted",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "viewer",
          "beds"
        ]
      }
    ]
  },
  "name": "PlanListPaginationContainer_search",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": "beds",
          "args": [
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "type",
              "variableName": "type"
            }
          ],
          "concreteType": "BedConnection",
          "kind": "LinkedField",
          "name": "__PlanListPaginationContainer_beds_connection",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BedEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": "bed",
                  "args": null,
                  "concreteType": "Bed",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Department",
                      "kind": "LinkedField",
                      "name": "department",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/),
                        (v2/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Visit",
                      "kind": "LinkedField",
                      "name": "visit",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PatientVector",
                          "kind": "LinkedField",
                          "name": "vector",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "patientId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "encounterId",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "predictionDate",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PatientCondition",
                              "kind": "LinkedField",
                              "name": "conditions",
                              "plural": true,
                              "selections": [
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "riskBracket",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PatientRiskFactor",
                                  "kind": "LinkedField",
                                  "name": "riskFactors",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v5/*: any*/),
                                    (v6/*: any*/)
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "PatientRecommendation",
                                  "kind": "LinkedField",
                                  "name": "recommendations",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "rank",
                                      "storageKey": null
                                    },
                                    (v6/*: any*/),
                                    (v7/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "accepted",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/),
                        (v22/*: any*/),
                        (v23/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DrgAttributes",
                          "kind": "LinkedField",
                          "name": "drgAttributes",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v7/*: any*/),
                            (v24/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "modifyTargetLos",
                              "storageKey": null
                            },
                            (v25/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v26/*: any*/),
                        (v27/*: any*/),
                        (v28/*: any*/),
                        (v29/*: any*/),
                        (v30/*: any*/),
                        (v32/*: any*/),
                        (v33/*: any*/),
                        (v34/*: any*/),
                        (v35/*: any*/),
                        (v36/*: any*/),
                        (v37/*: any*/),
                        (v38/*: any*/),
                        (v39/*: any*/),
                        (v40/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WorkingDischargeDispositionDTO",
                          "kind": "LinkedField",
                          "name": "workingDischargeDisposition",
                          "plural": false,
                          "selections": [
                            (v7/*: any*/),
                            (v1/*: any*/),
                            (v31/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "workingDischargeDispositionGroup",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Plan",
                          "kind": "LinkedField",
                          "name": "plan",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v45/*: any*/),
                            (v46/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Barrier",
                              "kind": "LinkedField",
                              "name": "barriers",
                              "plural": true,
                              "selections": [
                                (v41/*: any*/),
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v6/*: any*/),
                                (v43/*: any*/),
                                (v4/*: any*/),
                                (v47/*: any*/),
                                (v44/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "ownerMessageStatus",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "KnownUser",
                                  "kind": "LinkedField",
                                  "name": "ownerKnownUser",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "emailReceiver",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "instantMessageReceiver",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v48/*: any*/),
                            (v49/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Milestone",
                              "kind": "LinkedField",
                              "name": "milestones",
                              "plural": true,
                              "selections": [
                                (v41/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MilestoneAction",
                                  "kind": "LinkedField",
                                  "name": "actions",
                                  "plural": true,
                                  "selections": [
                                    (v41/*: any*/),
                                    (v1/*: any*/),
                                    (v43/*: any*/),
                                    (v42/*: any*/),
                                    (v44/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v50/*: any*/),
                            (v51/*: any*/),
                            (v52/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedTransferTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedDischargeTimeAutoPopulated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedTransferTimeAutoPopulated",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Visit",
                      "kind": "LinkedField",
                      "name": "dischargedVisits",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/),
                        (v22/*: any*/),
                        (v23/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DrgAttributes",
                          "kind": "LinkedField",
                          "name": "drgAttributes",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v7/*: any*/),
                            (v24/*: any*/),
                            (v25/*: any*/)
                          ],
                          "storageKey": null
                        },
                        (v26/*: any*/),
                        (v27/*: any*/),
                        (v28/*: any*/),
                        (v29/*: any*/),
                        (v30/*: any*/),
                        (v32/*: any*/),
                        (v33/*: any*/),
                        (v34/*: any*/),
                        (v35/*: any*/),
                        (v36/*: any*/),
                        (v37/*: any*/),
                        (v38/*: any*/),
                        (v39/*: any*/),
                        (v40/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WorkingDischargeDispositionDTO",
                          "kind": "LinkedField",
                          "name": "workingDischargeDisposition",
                          "plural": false,
                          "selections": [
                            (v7/*: any*/),
                            (v1/*: any*/),
                            (v31/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Plan",
                          "kind": "LinkedField",
                          "name": "plan",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v45/*: any*/),
                            (v46/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Barrier",
                              "kind": "LinkedField",
                              "name": "barriers",
                              "plural": true,
                              "selections": [
                                (v41/*: any*/),
                                (v1/*: any*/),
                                (v2/*: any*/),
                                (v6/*: any*/),
                                (v43/*: any*/),
                                (v4/*: any*/),
                                (v47/*: any*/),
                                (v44/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v48/*: any*/),
                            (v49/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Milestone",
                              "kind": "LinkedField",
                              "name": "milestones",
                              "plural": true,
                              "selections": [
                                (v41/*: any*/),
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "MilestoneAction",
                                  "kind": "LinkedField",
                                  "name": "actions",
                                  "plural": true,
                                  "selections": [
                                    (v1/*: any*/),
                                    (v43/*: any*/),
                                    (v42/*: any*/),
                                    (v44/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v50/*: any*/),
                            (v51/*: any*/),
                            (v52/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Bed",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v41/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '23af77319985e189f15f554cf6ee29b4';

module.exports = node;
