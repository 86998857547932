/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  red300 as DecisionTimeNotSetColor,
  grey500 as NonCMSDecisionTimeNotSetColor,
} from 'material-ui/styles/colors'

import { CountdownTimer } from '../date'

import { showDrawer } from '../../ducks/views'
import { drawers } from '../../containers/drawer-component-mapping'
import { deepDiffShouldComponentUpdate } from '../../util/should-component-update'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
/**
 * Show and allow change of Decision Time for Out/Observation patients
 */
export class DecisionTimePicker extends Component {
  static propTypes = {
    visit: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
  }


  render() {
    let { decisionTime, visitStatus, admissionTime } = this.props.visit
    let dayDecision // holder for control that always displays whatever decision time is
    const isAdmission = visitStatus !== 'Admitted'

    // Create the appropriate readonly controls and store in dayDecision
    if (admissionTime && decisionTime) {
      dayDecision = (
        <span style={{ cursor: 'pointer' }} onClick={this.props.onEdit}>
          Decision Due: <CountdownTimer date={decisionTime} />
        </span>
      )
    } else {
      // can't set/change decision time until we have an Admission Time
      if (!admissionTime ) {
        dayDecision = (
          <span style={{ color: NonCMSDecisionTimeNotSetColor }}>
            Decision Time Not Set
          </span>
        )
      } else {
        dayDecision = (
          <span
            onClick={this.props.onEdit}
            style={{
              cursor: 'pointer',
              color: isAdmission ? '' : DecisionTimeNotSetColor,
            }}>
            Decision Time Not Set
          </span>
        )
      }
    }

    return <span style={{ position: 'relative' }}>{dayDecision}</span>
  }
}
