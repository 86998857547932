export const AUTHENTICATED = 'care-advance/user/AUTHENTICATED'
export const REFRESHED = 'care-advance/user/REFRESHED'
export const AUTHENTICATION_FAILED = 'care-advance/user/AUTHENTICATION_FAILED'
export const LOGOUT = 'care-advance/user/LOGOUT'

import {
  AuthState,
  authenticateCredentials,
  destroyCredentials,
  refreshAuthenticationToken,
} from '../auth'

const initialState = {
  identity: null,
  token: null,
  refreshToken: null,
  expiration: 0,
  errors: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...initialState,
        ...action.payload,
      }
    case REFRESHED:
      return {
        ...state,
        ...action.payload,
      }
    case AUTHENTICATION_FAILED:
      return {
        ...initialState,
        ...action.payload,
      }
    case LOGOUT:
      // Note: Logout is also handled in the root reducer (reducers/index.js)
      // Because logout should wipe all the app data (except static configs)
      return {
        ...initialState,
        ...action.payload,
      }
    default:
      return state
  }
}

export function authenticate(username: string, password: string) {
  return dispatch =>
    authenticateCredentials(username, password)
      .then(loggedIn)
      .then(dispatch)
}

export function loggedIn(authState: AuthState) {
  return {
    type: AUTHENTICATED,
    payload: authState,
  }
}

export function refreshAuthToken() {
  return dispatch =>
    refreshAuthenticationToken()
      .then(payload => ({
        type: REFRESHED,
        payload,
      }))
      .catch(logout)
      .then(dispatch)
}

export function logout(payload = {}) {
  return dispatch =>
    destroyCredentials()
      .then(() => ({
        type: LOGOUT,
        payload,
      }))
      .then(dispatch)
}
