import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import TextEdit from 'material-ui/TextField'

import { CodeTableSelect } from '../../containers'
import { StatefulTextField } from '../common/StatefulTextField'

export class CareTeamList extends Component {
  static propTypes = {
    planId: PropTypes.string.isRequired,
    careTeamMembers: PropTypes.array.isRequired,
    className: PropTypes.string,
    editable: PropTypes.bool,
    rowClassName: PropTypes.string,
    onUpdateRole: PropTypes.func,
    onUpdateName: PropTypes.func,
    onRemove: PropTypes.func,
  }

  static defaultProps = {
    careTeamMembers: [],
    editable: false,
    onUpdateRole: () => {},
    onUpdateName: () => {},
    onRemove: () => {},
  }

  inputs = {}

  render() {
    const {
      planId,
      careTeamMembers,
      className,
      editable,
      rowClassName,
      onUpdateRole,
      onUpdateName,
      onRemove,
    } = this.props
    return editable ? (
      // Editable Version
      <div className={className}>
        {careTeamMembers.map(
          ({ id, name, role: { code: roleCode, value: roleValue } }) => (
            <div key={id} className="clearfix">
              <div className={rowClassName}>
                <CodeTableSelect
                  label="Role"
                  value={roleCode}
                  componentProps={{
                    hintText: 'Role',
                    floatingLabelStyle: { display: 'none' },
                    style: { maxHeight: 50 },
                  }}
                  codeTableType="CareTeamMemberRole"
                  noNullValue
                  onChange={(event, index, newValue) =>
                    onUpdateRole(planId, id, newValue)
                  }
                />
              </div>
              <div className={rowClassName}>
                <StatefulTextField
                  id={`CareTeamMemberName-${id}`}
                  textFieldProps={{
                    hintText: `${roleValue} Name`,
                  }}
                  ref={t => (this.inputs[id] = t)}
                  text={name || ''}
                  onEnter={newValue => onUpdateName(planId, id, newValue)}
                  maxNumberOfChars={50}
                  clearOnTextEntered={false}
                  showCharacterCount
                />
              </div>
              <IconButton
                onClick={() => onRemove(planId, id)}
                style={{ marginLeft: 20 }}>
                <NavigationClose />
              </IconButton>
            </div>
          )
        )}
      </div>
    ) : (
      // Read Only Version
      <div className={className}>
        {careTeamMembers.map(({ id, name, role: { code, value } }) => (
          <div key={`${id}-${code}`} className={rowClassName}>
            {`${value} : ${name}`}
          </div>
        ))}
      </div>
    )
  }
}
