/*eslint-disable react/no-did-mount-set-state */
/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppBar from 'material-ui/AppBar'
import Avatar from 'material-ui/Avatar'
import { Navigator } from './components/Navigator'
import { QuickFilter} from './components/escalation/QuickFilter'
import { QuickSearch } from './components/escalation/QuickSearch'
import { NavMenu } from './components/NavMenu'
import { connect } from 'react-redux'
import { QueryRenderer, graphql } from 'react-relay'
import { environment } from './graphql/relay/env'

const mapStateToProps = state => ({
  currentRoute: state.viewsAppState.currentRoute
})

const query = graphql`
  query AppHeaderQuery {
    appInfo {
      tag
    }
  }
`

@connect(mapStateToProps)
export class AppHeader extends Component {
  static propTypes = {
    currentRoute: PropTypes.object,
    routes: PropTypes.array,
    authState: PropTypes.object,
  }

  static defaultProps = {
    currentRoute: { title: 'Care Advance' },
  }

  render() {
    const { currentRoute, routes, authState } = this.props
    //we cannot use currentRoute directly because it does not contain all properties needed
    const route = routes.find(item => item.viewType === currentRoute.viewType)
    const viewType = route.viewType
    const showNavigator = viewType === 'Plan' || viewType === 'ConflictedPlan'
    const showEscalation = viewType === 'EscalationList'

    const titleColor = route && route.headerTextColor


    return (
      <QueryRenderer
        environment={environment}
        query={query}
        render={({props}) => {

          let title = route.title
          if (props && props.appInfo && props.appInfo.tag) {
            title = `${route.title} - ${props.appInfo.tag}`
            document.title = `Care Advance - ${props.appInfo.tag}`
          }

          return (
            <AppBar
              style={{ backgroundColor: route && route.headerBackgroundColor }}
              iconElementLeft={
                route && (
                  <Avatar
                    style={{ marginTop: 5 }}
                    backgroundColor={route.headerBackgroundColor}>
                    {route.svgIcon && (
                      <route.svgIcon style={{ fill: route.headerIconColor }} />
                    )}
                  </Avatar>
                )
              }
              titleStyle={{ color: titleColor }}
              title={title}>
              <div>
                {showNavigator && <Navigator currentRoute={route} />}
                {showEscalation && <QuickSearch/>}
                {showEscalation && <QuickFilter/>}
                <NavMenu currentRoute={route} routes={routes} authState={authState} />
              </div>
            </AppBar>
          )
        }
        }
      />

    )
  }
}
