/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type AdmissionType = "InPatient" | "Observation" | "OutPatient" | "%future added value";
export type BedRequestStatus = "Anticipated" | "AnticipatedDischarge" | "AnticipatedUnitTransfer" | "Cancelled" | "CheckedIn" | "DischargeOrderWithDelay" | "Discharged" | "IntentToAdmit" | "Pending" | "PendingDischarge" | "PendingUnitTransfer" | "Ready" | "ReadyForDischarge" | "ReadyForUnitTransfer" | "Transferred" | "%future added value";
export type DepartmentType = "AdmissionSource" | "Unit" | "%future added value";
export type ProgressionIndicator = "AtRisk" | "HighRisk" | "NotSet" | "OnTime" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ComplianceListPaginationContainer_search$ref: FragmentReference;
declare export opaque type ComplianceListPaginationContainer_search$fragmentType: ComplianceListPaginationContainer_search$ref;
export type ComplianceListPaginationContainer_search = {|
  +viewer: {|
    +beds: {|
      +totalCount: number,
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
        +startCursor: ?string,
        +totalCount: string,
      |},
      +edges: $ReadOnlyArray<{|
        +bed: {|
          +id: string,
          +name: string,
          +department: {|
            +id: string,
            +exchangeName: string,
            +name: string,
            +type: DepartmentType,
            +includedInLosCalculation: boolean,
          |},
          +exchangeName: string,
          +status: string,
          +visit: ?{|
            +id: string,
            +visitNumber: ?string,
            +patientProblem: ?string,
            +homeBedExchangeName: ?string,
            +admissionType: ?AdmissionType,
            +anticipatedDischargeTime: ?any,
            +anticipatedDischargeUpdatedBy: ?{|
              +userName: string,
              +firstName: string,
              +lastName: string,
              +id: string,
            |},
            +targetDischargeTime: ?any,
            +bedRequestStatus: ?BedRequestStatus,
            +decisionTime: ?any,
            +tlos: ?number,
            +currentDayOfStay: ?number,
            +admittingComplaint: ?string,
            +meta: {|
              +timeElapsedSinceAdmission: any
            |},
            +drgAttributes: ?{|
              +code: string,
              +title: string,
              +level: string,
              +targetLos: number,
              +modifyTargetLos: boolean,
            |},
            +patient: {|
              +id: string,
              +mrn: ?string,
              +age: ?string,
              +gender: ?string,
              +lastName: ?string,
              +middleInitial: ?string,
              +firstName: ?string,
            |},
            +workingDischargeDisposition: ?{|
              +id: string,
              +code: ?string,
              +value: ?string,
            |},
            +dischargeVendor: ?string,
            +plan: ?{|
              +id: string,
              +progressionIndicator: ProgressionIndicator,
              +toBeAdmitted: ?boolean,
              +milestones: $ReadOnlyArray<?{|
                +id: string
              |}>,
              +appliedTemplates: ?$ReadOnlyArray<?{|
                +id: string,
                +name: string,
                +removed: boolean,
              |}>,
              +careTeamMembers: $ReadOnlyArray<?{|
                +id: string,
                +name: ?string,
                +primary: ?boolean,
                +role: {|
                  +id: string,
                  +code: ?string,
                  +value: ?string,
                |},
              |}>,
            |},
            +anticipatedTransferTime: ?any,
            +anticipatedDischargeTimeAutoPopulated: ?boolean,
            +anticipatedTransferTimeAutoPopulated: ?boolean,
          |},
        |}
      |}>,
    |}
  |},
  +$refType: ComplianceListPaginationContainer_search$ref,
|};
export type ComplianceListPaginationContainer_search$data = ComplianceListPaginationContainer_search;
export type ComplianceListPaginationContainer_search$key = {
  +$data?: ComplianceListPaginationContainer_search$data,
  +$fragmentRefs: ComplianceListPaginationContainer_search$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v7 = [
  (v1/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "viewer",
          "beds"
        ]
      }
    ]
  },
  "name": "ComplianceListPaginationContainer_search",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": "beds",
          "args": [
            {
              "kind": "Variable",
              "name": "query",
              "variableName": "query"
            },
            {
              "kind": "Variable",
              "name": "type",
              "variableName": "type"
            }
          ],
          "concreteType": "BedConnection",
          "kind": "LinkedField",
          "name": "__ComplianceListPaginationContainer_beds_connection",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPreviousPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startCursor",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BedEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": "bed",
                  "args": null,
                  "concreteType": "Bed",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Department",
                      "kind": "LinkedField",
                      "name": "department",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v3/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "includedInLosCalculation",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Visit",
                      "kind": "LinkedField",
                      "name": "visit",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "visitNumber",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "patientProblem",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "homeBedExchangeName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "admissionType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedDischargeTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AuditUser",
                          "kind": "LinkedField",
                          "name": "anticipatedDischargeUpdatedBy",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "userName",
                              "storageKey": null
                            },
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "targetDischargeTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "bedRequestStatus",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "decisionTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tlos",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "currentDayOfStay",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "admittingComplaint",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "VisitMeta",
                          "kind": "LinkedField",
                          "name": "meta",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "timeElapsedSinceAdmission",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DrgAttributes",
                          "kind": "LinkedField",
                          "name": "drgAttributes",
                          "plural": false,
                          "selections": [
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "title",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "level",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "targetLos",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "modifyTargetLos",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Patient",
                          "kind": "LinkedField",
                          "name": "patient",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "mrn",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "age",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "gender",
                              "storageKey": null
                            },
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "middleInitial",
                              "storageKey": null
                            },
                            (v4/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "WorkingDischargeDispositionDTO",
                          "kind": "LinkedField",
                          "name": "workingDischargeDisposition",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "dischargeVendor",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Plan",
                          "kind": "LinkedField",
                          "name": "plan",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "progressionIndicator",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "toBeAdmitted",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Milestone",
                              "kind": "LinkedField",
                              "name": "milestones",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TemplateInfo",
                              "kind": "LinkedField",
                              "name": "appliedTemplates",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "removed",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CareTeamMember",
                              "kind": "LinkedField",
                              "name": "careTeamMembers",
                              "plural": true,
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "primary",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "CodeTablePair",
                                  "kind": "LinkedField",
                                  "name": "role",
                                  "plural": false,
                                  "selections": (v7/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedTransferTime",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedDischargeTimeAutoPopulated",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "anticipatedTransferTimeAutoPopulated",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Bed",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '69cc7fd17c6e40a96e905b543b48dcc9';

module.exports = node;
