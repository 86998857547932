import React from 'react'
import PropTypes from 'prop-types'
import Subheader from 'material-ui/Subheader'
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'

const TrackFilter = ({ onChange, selected }) => {

  return (
    <div className="filter-container">
      <Subheader className="filter-subheader">Track</Subheader>
      <RadioButtonGroup
        key="trackFilter"
        name="trackFilter"
        valueSelected={selected}
        className="filter-items"
        defaultSelected="All">
        <RadioButton
          value="All"
          label="All"
          onClick={onChange}
        />
        <RadioButton
          value="Discharge"
          label="Discharge"
          onClick={onChange}
        />
        <RadioButton
          value="Progression"
          label="Progression"
          onClick={onChange}
        />
        <RadioButton
          value="User"
          label="To-Do"
          onClick={onChange}
        />
      </RadioButtonGroup>
    </div>
  )
}

TrackFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired
}

export default TrackFilter
