/*eslint-disable react/no-set-state */

import React from 'react'
import PropTypes from 'prop-types'

import { Chip } from 'material-ui'
import {
  red300 as BarrierColor,
  blue200 as PlanActionColor,
  yellow200 as NoteColor,
  purple200 as MilestoneActionColor,
} from 'material-ui/styles/colors'

import { getPinnableText } from '../../util/utils'

export class CarePointPin extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      pinSelected: false
    }
  }

  render() {
    const { item, onRequestDelete } = this.props
    let backgroundColor

    switch (item.__typename) {
      case 'Barrier':
        backgroundColor = BarrierColor
        break

      case 'PlanAction':
        backgroundColor = PlanActionColor
        break

      case 'MilestoneAction':
        backgroundColor = MilestoneActionColor
        break

      default:
        backgroundColor = NoteColor
        break
    }

    const fullText = item.name || item.text || item.description

    return (
      <Chip
        onClick={() => this.setState(prevState => ({pinSelected: !prevState.pinSelected}))}
        onRequestDelete={onRequestDelete && (e => onRequestDelete(item))}
        backgroundColor={backgroundColor}>
        {this.state.pinSelected ? fullText : getPinnableText(15, item.name, item.text || item.description)}
      </Chip>
    )
  }

}

CarePointPin.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    __typename: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
  }),
  onRequestDelete: PropTypes.func,
}
