import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Link } from 'react-router-dom'
import IconButton from 'material-ui/IconButton'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import MenuDotsIcon from 'mdi-react/DotsVerticalIcon'
import PrinterIcon from 'mdi-react/PrinterIcon'

import { createStyleSheet } from 'jss-theme-reactor'

import Avatar from 'material-ui/Avatar'
import {
  grey900 as TextColor,
  cyan500 as RouteSelectedColor,
  transparent as RouteUnselectedColor,
} from 'material-ui/styles/colors'

import { showConfirmLogout, showDrawer, hideDrawer } from '../ducks/views'
import { evaluateRouteAuth } from '../route/routing-utils'
import { is_PhoneorTablet } from '../util/utils'
import { drawers } from '../containers/drawer-component-mapping'

const styleSheet = createStyleSheet('NavMenu', theme => ({
  menuItem: {
    padding: '10px 15px 0',
  },
  linkText: {
    textDecoration: 'none',
    color: TextColor,
    paddingLeft: '10px',
    display: 'inline-block',
    position: 'relative',
    top: '-5px',
  },
}))

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      showConfirmLogout,
      showDrawer,
      hideDrawer
    },
    dispatch
  ),
})

@connect(
  null,
  mapDispatchToProps
)
export class NavMenu extends Component {
  static propTypes = {
    routes: PropTypes.array,
    authState: PropTypes.object,
    currentRoute: PropTypes.object,
    actions: PropTypes.shape({
      showConfirmLogout: PropTypes.func,
      showDrawer: PropTypes.func,
      hideDrawer: PropTypes.func,
    }),
  }
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  }


  render() {
    const showReportDrawer = (name) => {
      this.props.actions.showDrawer(drawers.GENERATE_REPORT, {
        reportType: name,
        closeDrawer: this.props.actions.hideDrawer
      })
    }

    const { routes, authState, currentRoute } = this.props

    const classes = this.context.styleManager.render(styleSheet)
    const logoutRoute = routes.find(route => route.path === '/logout')
    const showReports = !is_PhoneorTablet
    return (
      <IconMenu
        iconButtonElement={
          <IconButton
            style={{ fill: currentRoute && currentRoute.headerTextColor }}>
            <MenuDotsIcon />
          </IconButton>
        }
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}>
        {showReports && (
          <MenuItem innerDivStyle={{ padding: 0 }} onClick={() => showReportDrawer('Census')}>
            <div className={classes.menuItem}>
              <Avatar backgroundColor={RouteUnselectedColor}>
                <PrinterIcon />
              </Avatar>
              <span className={classes.linkText}>Census</span>
            </div>
          </MenuItem>
        )}


        {showReports && (
          <MenuItem innerDivStyle={{ padding: 0 }} onClick={() => showReportDrawer('Snap')}>
            <div className={classes.menuItem}>
              <Avatar backgroundColor={RouteUnselectedColor}>
                <PrinterIcon />
              </Avatar>
              <span className={classes.linkText}>SNAP</span>
            </div>
          </MenuItem>
        )}

        {routes &&
          routes
            .filter(
              route => route !== logoutRoute && routeIsVisible(route, authState)
            )
            .map(route => (
              <MenuItem key={route.path} innerDivStyle={{ padding: 0 }}>
                <Link key={route.path} to={route.path}>
                  <div className={classes.menuItem} key={route.path}>
                    <Avatar
                      backgroundColor={
                        route === currentRoute
                          ? RouteSelectedColor
                          : RouteUnselectedColor
                      }>
                      {route.svgIcon && (
                        <route.svgIcon
                          style={{ fill: route.navbarIconColor }}
                        />
                      )}
                    </Avatar>
                    <span className={classes.linkText}>{route.title}</span>
                  </div>
                </Link>
              </MenuItem>
            ))}

        {logoutRoute && logoutRoute.menu && (
          <MenuItem
            innerDivStyle={{ padding: 0 }}
            onClick={this.props.actions.showConfirmLogout}>
            <div className={classes.menuItem}>
              <Avatar backgroundColor={RouteUnselectedColor}>
                {logoutRoute.svgIcon && (
                  <logoutRoute.svgIcon
                    style={{ fill: logoutRoute.navbarIconColor }}
                  />
                )}
              </Avatar>
              <span className={classes.linkText}>{logoutRoute.title}</span>
            </div>
          </MenuItem>
        )}
      </IconMenu>
    )
  }
}

function routeIsVisible(route, authState) {
  return route.menu && evaluateRouteAuth(route.auth, authState).length === 0
}
