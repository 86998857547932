/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import _sortBy from 'lodash.sortby'

import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import { accumulateProviders, normalizeProviders } from '../../util/utils'

import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import { CareTeamList } from '../../components/visit/CareTeamList'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { CareTeamMemberRow } from './CareTeamMemberRow'
import {
  careTeamMembers,
  assignCareTeamMember,
  createCareTeamMember,
  removeCareTeamMember,
  updateCareTeamMemberName,
  updateCareTeamMemberRole } from '../../graphql/relay/queries/careTeam'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'
import { fetchQuery } from 'react-relay'
import { PatientPlanQuery} from "../../views/PatientPlan/PatientPlanGQL"

const styleSheet = createStyleSheet('ManageCareTeamMembers', theme => ({
  height100: {
    height: '100%',
  },
  heightInner: {
    height: 'calc(100% - 40px)',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  providerRow: {
    color: '#908787 !important',
    padding: '2px 2px 2px 0 !important',
    marginTop: '10px',
    marginLeft: '10px',
  },
  inputRow: {
    marginTop: '10px',
  },
  inputFloat: {
    float: 'left',
    marginLeft: '10px',
  },
  addButton: {
    marginTop: '7px',
  },
}))

class ManageCareTeamMembers extends Component {
  static propTypes = {
    title: PropTypes.string,
    planId: PropTypes.string.isRequired,
    visitId: PropTypes.number.isRequired,
    workspace: PropTypes.isRequired,
    onRequestClose: PropTypes.func.isRequired,

    // Mutations
    createCareTeamMember: PropTypes.func,
    assignCareTeamMember: PropTypes.func,
    updateCareTeamMemberName: PropTypes.func,
    updateCareTeamMemberRole: PropTypes.func,
    removeCareTeamMember: PropTypes.func,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      careTeamMembers: [],
      careTeamMembersList: []
    }
  }

  componentDidMount() {
    this.fetchTeam()
  }

  // done for CA-2284
  fetchTeam = () => {
    const careTeamMembersTop = fetchQuery(environment, careTeamMembers, null)
    const careTeamMembersList = fetchQuery(environment, PatientPlanQuery, {id: this.props.visitId})
    return Promise.all([careTeamMembersTop, careTeamMembersList]).then(values => {
      this.setState({...this.state,
        careTeamMembers: values[0].careTeamMembers,
        careTeamMembersList: values[1].node.plan.careTeamMembers
      })
    })
  }

  handleCreateCareTeamMember = (planId, roleCode, name) => {
    handleMutation(
      environment,
      createCareTeamMember,
      {
        planId: planId,
        member: {name: name, roleCode: roleCode}
      },
      this.fetchTeam
    )
  }

  handleUpdateCareTeamMemberRole = (planId, memberId, roleCode) => {
    handleMutation(
      environment,
      updateCareTeamMemberRole,
      {planId, memberId, roleCode},
      this.fetchTeam
    )
  }

  handleUpdateCareTeamMemberName = (planId, memberId, name) => {
    handleMutation(
      environment,
      updateCareTeamMemberName,
      {planId, memberId, name},
      this.fetchTeam
    )
  }

  handleRemoveCareTeamMember = (planId, memberId) => {
    handleMutation(
      environment,
      removeCareTeamMember,
      {planId, memberId},
      this.fetchTeam
    )
  }

  handleAssignCareTeamMember = (planId, memberId) => {
    handleMutation(
      environment,
      assignCareTeamMember,
      {planId, memberId},
      this.fetchTeam
    )
  }


  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const {
      title,
      planId,
      workspace,
      onRequestClose,
    } = this.props

    const visit = workspace.visit
    const bed = workspace.bed
    const careTeamMembersList = _sortBy(this.state.careTeamMembersList, ['role.code', 'name'])

    const providers = normalizeProviders(
      _sortBy(accumulateProviders(visit, bed), ['role.code', 'name'])
    )

    return (
      <div className={classes.height100}>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={[]}
        />
        <div
          className={classNames(classes.heightInner)}
          style={{ overflow: 'auto' }}>
          <CareTeamList
            planId={planId}
            careTeamMembers={providers}
            rowClassName={classes.providerRow}
          />
          <CareTeamMemberRow
            planId={planId}
            careTeamMembers={this.state.careTeamMembers}
            handleCreateCareTeamMember={this.handleCreateCareTeamMember}
            handleAssignCareTeamMember={this.handleAssignCareTeamMember}
            handleUpdateCareTeamMemberName={this.handleUpdateCareTeamMemberName}
            handleUpdateCareTeamMemberRole={this.handleUpdateCareTeamMemberRole}
            handleRemoveCareTeamMember={this.handleRemoveCareTeamMember}
          />
          <CareTeamList
            planId={planId}
            careTeamMembers={careTeamMembersList}
            className={classes.inputRow}
            rowClassName={classes.inputFloat}
            editable
            onUpdateRole={this.handleUpdateCareTeamMemberRole}
            onUpdateName={this.handleUpdateCareTeamMemberName}
            onRemove={this.handleRemoveCareTeamMember}
          />
        </div>
      </div>
    )
  }
}

export default ManageCareTeamMembers
