import { showErrorsDirect } from '../../ducks/errorspg'

export const hasErrors = (errors) => {
  return errors && errors.length > 0
}

export const handleErrorArray = (errCallback, errors) => {
  if (hasErrors(errors)) {
    const error = errors[0]
    console.error('Error while mutating', error.message)
    if (errCallback) {
      errCallback(error.message, 200, error.message)
    }
    showErrorsDirect(error)
  }
}

export const hasRelayError = (error) => {
  return error && error.message
}

export const handleRelayOnError = (errCallback, error) => {
  if (hasRelayError(error)) {
    console.error('Error while mutating', error.message)
    if (errCallback) {
      errCallback(error.message, 200, error.message)
    }
    showErrorsDirect(error)
  }
}
