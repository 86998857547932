import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * A wrapper component conditionally shows the children based on whether or not their attribute data-config-name is included
 * in the array of config strings
 *
 * @param config A string 'all' or an array of strings to match with each child's data-config-name attribute
 * @param children An array
 * @returns null if no child matches the config,
 *          a single child if only one child matches the config,
 *          multiple children if more than one child matches the config
 */
export const Configurable = ({ config = [], children }) => {
  const arr = Array.isArray(children) ? children : [children]
  const elements = arr.filter(
    child =>
      !child ||
      !child.props['data-config-name'] ||
      config === 'all' ||
      config.includes(child.props['data-config-name'])
  )
  return elements.length === 0 ? null : arr.length === 1 ? (
    elements[0]
  ) : (
    <div>{elements}</div>
  )
}

Configurable.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}
