import React from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

export const ConfirmDialog = ({
  open,
  title = 'Confirm',
  message,
  confirmText = 'Yes',
  cancelText = 'No',
  onCancelClick,
  onConfirmClick,
}) => {
  const actions = [
    <FlatButton
      key="cancel"
      onClick={() => onCancelClick()}
      label={cancelText}
    />,
    <FlatButton
      key="confirm"
      onClick={() => onConfirmClick()}
      label={confirmText}
    />,
  ]

  return (
    <Dialog open={open} modal title={title} actions={actions}>
      {message}
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
}
