import React, { Component } from 'react'
import PropTypes from 'prop-types'

import IconButton from 'material-ui/IconButton'
import ActionSearch from 'material-ui/svg-icons/action/search'

export class DRGNumber extends Component {
  static propTypes = {
    visit: PropTypes.object.isRequired,
    onSearchDRG: PropTypes.func.isRequired,
  }

  handleSearchDRG = () => {
    this.props.onSearchDRG(this.props.visit)
  }

  render() {
    const drgCode =
      (this.props.visit.drgAttributes && this.props.visit.drgAttributes.code) ||
      'Not Set'

    return (
      <span>
        <span>{drgCode}</span>
        <IconButton
          disabled={this.props.visit.visitStatus === 'Discharged'}
          style={{
            padding: '8px',
            width: 40,
            height: 40,
            verticalAlign: '-6px',
          }}
          tooltip="Search for DRG"
          onClick={this.handleSearchDRG}>
          <ActionSearch />
        </IconButton>
      </span>
    )
  }
}
