import React from 'react'
import PropTypes from 'prop-types'

import './kpi.scss'

import { Avatar } from 'material-ui'
import Toggle from 'material-ui/Toggle'
import FlatButton from 'material-ui/FlatButton'
import {
  cyan100 as TabSelectedColor,
  cyan500 as FilterSelectedColor,
  grey400 as DefaultColor,
  grey200 as UnknownColor,
} from 'material-ui/styles/colors'

import {
  findColor,
  calcForecastExcessDays,
  calcForecastWorkingOE,
} from '../../util/workspace'
import { ClChip } from '../common'

const ForecastExcessDaysLabel = 'Excess Days'
const ActualLOSLabel = 'Actual LOS'
const ForecastWorkingOELabel = 'Working O/E'

const ForecastExcessDaysId = 'ForecastExcessDays'
const ActualLOSId = 'ActualLOS'
const ForecastWorkingOEId = 'ForecastWorkingO/E'

const Ascending = 'Ascending'
const Descending = 'Descending'

export class SortSection extends React.Component {
  static propTypes = {
    workspaces: PropTypes.array,
    capped: PropTypes.bool,
    sort: PropTypes.object,
    oeThresholdCaution: PropTypes.number,
    oeThresholdDanger: PropTypes.number,
    onSortClick: PropTypes.func,
  }

  handleSortClick = item => {
    this.doSort({ sortId: item.sortId, sortOrder: this.props.sort.sortOrder })
  }

  handleSortOrderToggle = () => {
    const { sort } = this.props
    this.doSort({
      sortId: sort.sortId,
      sortOrder: sort.sortOrder === Ascending ? Descending : Ascending,
    })
  }

  handleResetClick = () => {
    this.doSort()
  }

  doSort(newSort) {
    this.props.onSortClick(newSort)
  }

  makeSortSection(sort) {
    const {
      oeThresholdCaution,
      oeThresholdDanger,
      capped,
      workspaces,
    } = this.props

    let configs = [
      {
        label: ForecastExcessDaysLabel,
        sortId: ForecastExcessDaysId,
        selected: ForecastExcessDaysId === sort.sortId,
        color:
          ForecastExcessDaysId === sort.sortId ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleSortClick,
      },
      {
        label: ActualLOSLabel,
        sortId: ActualLOSId,
        selected: ActualLOSId === sort.sortId,
        color: ActualLOSId === sort.sortId ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleSortClick,
      },
      {
        label: ForecastWorkingOELabel,
        sortId: ForecastWorkingOEId,
        selected: ForecastWorkingOEId === sort.sortId,
        color:
          ForecastWorkingOEId === sort.sortId ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleSortClick,
      },
    ]

    // Count Workspaces shown
    // Average Excess Days (in whole days)
    // Average Forecast Working O/E (to two decimals)

    let averageOE = '?'
    let oeBackground = UnknownColor
    let excessDaysText = '(?)'
    if (workspaces.length > 0) {
      averageOE = calcForecastWorkingOE(capped, workspaces)
      oeBackground = findColor(averageOE, oeThresholdCaution, oeThresholdDanger)

      const excessDays = calcForecastExcessDays(capped, workspaces)
      const excessDaysSign = excessDays === 0 ? '' : excessDays < 0 ? '-' : '+'
      excessDaysText = `(${excessDaysSign}${excessDays})`
    }

    const showReset = false

    return (
      <div className="sort-container">
        <div className="sort-container-list">
          <span>Sort:</span>
          {configs.map(config => this.makeChip(config))}
          <span style={{ paddingLeft: '10px', marginTop: '7px' }}>
            <Toggle
              labelPosition="right"
              label={sort.sortOrder}
              onToggle={this.handleSortOrderToggle}
            />
          </span>
          {showReset && (
            <FlatButton
              label="Reset"
              style={{
                border: '1px solid lightgrey',
                lineHeight: '30px',
                maxHeight: '34px',
                marginLeft: '10px',
                marginTop: '3px',
                marginBottom: '5px',
                minWidth: '44px !important',
              }}
              labelStyle={{ textTransform: 'none' }}
              onClick={this.handleResetClick}
            />
          )}
        </div>
        <div className="sort-container-info">
          <span>Patients: {workspaces.length}</span>
          <span style={{ border: '1px solid lightgray' }}>
            {excessDaysText}
          </span>
          <span
            style={{ background: oeBackground, border: '1px solid lightgray' }}>
            O/E {averageOE}
          </span>
        </div>
      </div>
    )
  }

  makeChip(config) {
    const bc = config.selected ? FilterSelectedColor : config.backgroundColor
    return (
      <ClChip
        key={config.label}
        backgroundColor={config.color}
        onClick={() => config.onClick(config)}>
        <Avatar color="black" backgroundColor={bc} />
        {config.label}
      </ClChip>
    )
  }

  render() {
    return this.makeSortSection(this.props.sort)
  }
}
