/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type pin_FullPlanAction$ref: FragmentReference;
declare export opaque type pin_FullPlanAction$fragmentType: pin_FullPlanAction$ref;
export type pin_FullPlanAction = {|
  +id: string,
  +text: string,
  +status: ActionStatus,
  +pinned: boolean,
  +auditMeta: ?{|
    +created: any,
    +createdBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
    +updated: any,
    +updatedBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
  |},
  +$refType: pin_FullPlanAction$ref,
|};
export type pin_FullPlanAction$data = pin_FullPlanAction;
export type pin_FullPlanAction$key = {
  +$data?: pin_FullPlanAction$data,
  +$fragmentRefs: pin_FullPlanAction$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pin_FullPlanAction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditMeta",
      "kind": "LinkedField",
      "name": "auditMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditUser",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditUser",
          "kind": "LinkedField",
          "name": "updatedBy",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PlanAction",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc1406ae8f7a20e034e34b470f24baec';

module.exports = node;
