import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash.sortby'

import { Paper } from 'material-ui'

import { AssignedLocation } from './'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('UnitRow', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    container: {
      padding: '.3em',
      marginBottom: '.6em',
      overflow: 'auto',
      width: 'calc(100% - 3px)',
      backgroundColor: '#fff',
      boxShadow:
        'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    },
    unitCol: {
      width: '160px',
      float: 'left',
    },
    rowCol: {
      width: 'calc(100% - 160px)',
      float: 'left',
    },
  }
})

export const UnitRow = (
  {
    unit,
    myLocations,
    myNextLocations,
    disabled = false,
    onUnitClick = () => {},
    onBedClick = () => {},
  },
  context
) => {

  const classes = context.styleManager.render(styleSheet)
  const myLocationsSet = new Set(myLocations)
  const myNextLocationsSet = new Set(myNextLocations)

  const isLocationSelected = locationRef => myNextLocationsSet.has(locationRef)
  const isLocationAdded = locationRef => {
    const originallySelected = myLocationsSet.has(locationRef)
    const selected = myNextLocationsSet.has(locationRef)
    return !originallySelected && selected
  }
  const isLocationRemoved = locationRef => {
    const originallySelected = myLocationsSet.has(locationRef)
    const selected = myNextLocationsSet.has(locationRef)
    return originallySelected && !selected
  }

  const selectedUnit = unit.beds.every(bed =>
    isLocationSelected(bed.exchangeName)
  )

  return unit.beds.length == 0 ? null : (
    <div className={classNames(classes.container)}>
      <div className={classNames(classes.unitCol)}>
        <AssignedLocation
          name={unit.name}
          type={unit.type}
          selected={selectedUnit}
          disabled={disabled}
          onClick={() => onUnitClick(unit.exchangeName, !selectedUnit)}
        />
      </div>

      <div className={classNames(classes.rowCol)}>
        {sortBy(unit.beds, [bed => bed.name]).map(bed => (
          <AssignedLocation
            key={bed.exchangeName}
            name={bed.name}
            type="bed"
            closed={bed.status === 'Closed'}
            selected={isLocationSelected(bed.exchangeName)}
            added={isLocationAdded(bed.exchangeName)}
            removed={isLocationRemoved(bed.exchangeName)}
            disabled={disabled}
            onClick={() =>
              onBedClick(
                unit.exchangeName,
                bed.exchangeName,
                !isLocationSelected(bed.exchangeName)
              )
            }
          />
        ))}
      </div>
    </div>
  )
}

UnitRow.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

UnitRow.propTypes = {
  unit: PropTypes.object.isRequired,
  myLocations: PropTypes.array.isRequired,
  myNextLocations: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  onUnitClick: PropTypes.func,
  onBedClick: PropTypes.func,
}
