import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { logout } from '../ducks/user'

import { LogManager, Logger } from '../log'
const logger: Logger = LogManager.getLogger('ca.views.Logout')

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logout,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class Logout extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      logout: PropTypes.func.isRequired,
    }),
  }

  componentDidMount() {
    this.props.actions.logout()
  }

  render() {
    const centeredStyle = {
      width: '300px',
      height: '420px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: '-210px 0 0 -150px',
    }

    return <div style={centeredStyle}>You are being logged out...</div>
  }
}
