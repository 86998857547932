/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type config_appConfigs_QueryVariables = {||};
export type config_appConfigs_QueryResponse = {|
  +configs: ?$ReadOnlyArray<?{|
    +id: string,
    +name: string,
    +value: ?string,
  |}>,
  +alertIconConfig: $ReadOnlyArray<{|
    +id: string,
    +key: string,
    +value: $ReadOnlyArray<?string>,
  |}>,
  +languageResources: ?$ReadOnlyArray<?{|
    +resourceKey: ?string,
    +resourceValue: ?string,
  |}>,
|};
export type config_appConfigs_Query = {|
  variables: config_appConfigs_QueryVariables,
  response: config_appConfigs_QueryResponse,
|};
*/


/*
query config_appConfigs_Query {
  configs(applicationType: CA) {
    id
    name
    value
  }
  alertIconConfig {
    id
    key
    value
  }
  languageResources(resourceKey: "BedRequestStatus") {
    resourceKey
    resourceValue
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "applicationType",
        "value": "CA"
      }
    ],
    "concreteType": "Config",
    "kind": "LinkedField",
    "name": "configs",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": "configs(applicationType:\"CA\")"
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AlertIconConfig",
    "kind": "LinkedField",
    "name": "alertIconConfig",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "resourceKey",
        "value": "BedRequestStatus"
      }
    ],
    "concreteType": "LanguageResource",
    "kind": "LinkedField",
    "name": "languageResources",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resourceKey",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resourceValue",
        "storageKey": null
      }
    ],
    "storageKey": "languageResources(resourceKey:\"BedRequestStatus\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "config_appConfigs_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "config_appConfigs_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e7f561d90caef3145b31ef46105fea49",
    "id": null,
    "metadata": {},
    "name": "config_appConfigs_Query",
    "operationKind": "query",
    "text": "query config_appConfigs_Query {\n  configs(applicationType: CA) {\n    id\n    name\n    value\n  }\n  alertIconConfig {\n    id\n    key\n    value\n  }\n  languageResources(resourceKey: \"BedRequestStatus\") {\n    resourceKey\n    resourceValue\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1fb927e80763ecb616124e857ae07b6e';

module.exports = node;
