import gql, {injectForGqlFile} from '../util/gql'

export const TemplateGQL = {
  fragments: {
    FullPlanTemplate: gql`
      fragment FullPlanTemplate on PlanTemplate {
        id
        name
        description
        active
        progressionDocument {
          url
        }
        category {
          ...CodeValue
        }
        milestoneTemplates {
          ...FullMilestoneTemplate
        }
      }
    `,
    FullMilestoneTemplate: gql`
      fragment FullMilestoneTemplate on MilestoneTemplate {
        id
        ordinal
        name
        description
        active
        track
        actions
        offset {
          days
          hours
          minutes
          basis
        }
      }
    `,
    FullBarrierTemplate: gql`
      fragment FullBarrierTemplate on BarrierTemplate {
        id
        name
        description
        active
        dischargeDelay
        category {
          id: code
          code
          value
        }
      }
    `,
  },
  queries: {
    allPlanTemplates: gql`
      query allPlanTemplates {
        planTemplates {
          ...FullPlanTemplate
        }
      }
    `,
    allBarrierTemplates: gql`
      query allBarrierTemplates {
        barrierTemplates {
          ...FullBarrierTemplate
        }
      }
    `,
  },
  mutations: {},
}

injectForGqlFile(TemplateGQL)
