import React from 'react'
import PropTypes from 'prop-types'
import IrisIcon from '../../common/CustomIcons/IrisIcon'
import EmailIcon from 'mdi-react/EmailOutlineIcon'
import BarrierMessageStatusIcon from '../BarrierMessageStatusIcon/BarrierMessageStatusIcon'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'

import './BarrierCommStatusIcons.scss'
import { APP_DELIVERY, EMAIL_DELIVERY } from '../BarrierConstants'
import classNames from 'classnames'

const BarrierCommStatusIcons = ({ ownerMessageDeliveryMethod, ownerMessageDeliveryDate, ownerMessageStatus, ownerMessageStatusDate }) => {
  const displayCommStatus = () => {
    if (ownerMessageDeliveryMethod && ownerMessageDeliveryMethod === APP_DELIVERY) {
      return (
        <BarrierMessageStatusIcon
          messageStatus={ownerMessageStatus}
          messageStatusDate={ownerMessageStatusDate}
          showTooltip
        />
      )
    } else {
      return null
    }
  }

  const displayCommMethod = () => {
    let commMethodIcon
    let commMethodText
    let commStatusDate = ownerMessageDeliveryDate ? moment(ownerMessageDeliveryDate).format('MMMM Do, YYYY H:mm') : null
    let extraPaddingClass = ''
    switch (ownerMessageDeliveryMethod) {
      case APP_DELIVERY:
        commMethodIcon = <IrisIcon color={'BLUE'} width={32} height={32} />
        commMethodText = 'Message'
        break
      case EMAIL_DELIVERY:
        commMethodIcon = <EmailIcon className={'EmailIcon'} style={{ width: 22, height: 22 }} />
        commMethodText = 'Message'
        extraPaddingClass = 'ExtraPadding'
        break
      default:
        commMethodIcon = null
    }

    return commMethodIcon ? (
      <Tooltip title={`${commMethodText} was sent on ${commStatusDate}`} placement={'bottom-end'}>
        <div className={classNames('CommMethod', extraPaddingClass)}>
          {commMethodIcon}
        </div>
      </Tooltip>
      ) : null
  }

  return (
    <div className={'BarrierCommStatusIcons'}>
      {displayCommStatus()}
      {displayCommMethod()}
    </div>
  )
}

BarrierCommStatusIcons.propTypes = {
  ownerMessageDeliveryMethod: PropTypes.string,
  ownerMessageDeliveryDate: PropTypes.string,
  ownerMessageStatus: PropTypes.string,
  ownerMessageStatusDate: PropTypes.string
}

export default BarrierCommStatusIcons
