import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import {createFragmentContainer, graphql} from 'react-relay'

const BarrierSourceTypeTable = ({ barrierSourceTypeConfigs, onEdit }) => {

  const getSelectionType = (entry) => {
    let selectionType
    if (entry.activeDirectorySourceName) {
      selectionType = "AD Name Lookup"
    } else if (entry.hasConfiguredList) {
      let displayArray = []
      entry.sourceDetails.forEach((element, index, array) => {
        let isLastEntry = index === array.length - 1
        let text = element.value

        if (!element.active) {
          text += ' - Deactivated'
        }

        if (!isLastEntry) {
          text += ', '
        }

        displayArray.push(<StyledSourceDetail key={element.code} $isActive={element.active}>{text}</StyledSourceDetail>)
      })

      selectionType = (
        <StyledFlexDiv>
          <span style={{ textDecoration: 'underline' }}>Configured List</span>
          <span>{displayArray}</span>
        </StyledFlexDiv>
      )
    } else {
      selectionType = "Free Text"
    }

    return selectionType
  }

  return (
    <StyledTable>
      <colgroup>
        <col width="5%"/>
        <col width="25%"/>
        <col width="25%"/>
        <col width="14%"/>
        <col width="14%"/>
        <col width="17%"/>
      </colgroup>
      <thead>
      <tr>
        <th/>
        <th>Code</th>
        <th>Description</th>
        <th>Order</th>
        <th>Active</th>
        <th>Selection Type</th>
      </tr>
      </thead>
      <tbody>
        {barrierSourceTypeConfigs.map((entry, index) => (
          <tr key={index}>
            <td>
              <IconButton onClick={() => onEdit(entry)}>
                <Edit />
              </IconButton>
            </td>
            <td>{entry.code}</td>
            <td>{entry.value}</td>
            <td>{entry.ordinal}</td>
            <td>{entry.active?"Y":"N"}</td>
            <td>{getSelectionType(entry)}</td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  thead {
    font-size: 16px;
    background-color: #d3d3d3;
  }

  tbody {
    background-color: white;
  }

  tr {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  th {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  td {
    padding-left: 15px;
    padding-right: 15px;
  }


  &, th, td {
    border: 1px solid rgb(224, 224, 224);
  }
`

const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSourceDetail = styled.span`
  ${(props) => {
    switch (props.$isActive) {
      case false:
        return css`
          color: red;
        `
      default:
        return
    }
  }}
`

BarrierSourceTypeTable.propTypes = {
  barrierSourceTypeConfigs: PropTypes.array,
  onEdit: PropTypes.func
}

export default createFragmentContainer(BarrierSourceTypeTable, {
  barrierSourceTypeConfigs: graphql`
    fragment BarrierSourceTypeTable_barrierSourceTypeConfigs on BarrierSourceType @relay(plural: true) {
      id
      code
      value
      ordinal
      active
      activeDirectorySourceName
      hasConfiguredList
      sourceDetails {
        code
        value
        active
        ordinal
      }
    }
  `,
})

