/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type query_jvion_QueryVariables = {|
  id: string
|};
export type query_jvion_QueryResponse = {|
  +node: ?{|
    +id?: string,
    +vector?: ?{|
      +id: string,
      +patientId: string,
      +encounterId: string,
      +predictionDate: any,
      +conditions: ?$ReadOnlyArray<?{|
        +name: string,
        +riskBracket: ?string,
        +riskFactors: $ReadOnlyArray<?{|
          +id: string,
          +type: string,
          +description: string,
        |}>,
        +recommendations: $ReadOnlyArray<?{|
          +id: string,
          +rank: string,
          +description: string,
          +code: string,
          +accepted: ?boolean,
        |}>,
      |}>,
    |},
  |}
|};
export type query_jvion_Query = {|
  variables: query_jvion_QueryVariables,
  response: query_jvion_QueryResponse,
|};
*/


/*
query query_jvion_Query(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Visit {
      id
      vector {
        id
        patientId
        encounterId
        predictionDate
        conditions {
          name
          riskBracket
          riskFactors {
            id
            type
            description
          }
          recommendations {
            id
            rank
            description
            code
            accepted
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PatientVector",
  "kind": "LinkedField",
  "name": "vector",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "patientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "encounterId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "predictionDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PatientCondition",
      "kind": "LinkedField",
      "name": "conditions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "riskBracket",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PatientRiskFactor",
          "kind": "LinkedField",
          "name": "riskFactors",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PatientRecommendation",
          "kind": "LinkedField",
          "name": "recommendations",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rank",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accepted",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "query_jvion_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "type": "Visit",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "query_jvion_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/)
            ],
            "type": "Visit",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a14028233038e3e02cb27f73d313999",
    "id": null,
    "metadata": {},
    "name": "query_jvion_Query",
    "operationKind": "query",
    "text": "query query_jvion_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Visit {\n      id\n      vector {\n        id\n        patientId\n        encounterId\n        predictionDate\n        conditions {\n          name\n          riskBracket\n          riskFactors {\n            id\n            type\n            description\n          }\n          recommendations {\n            id\n            rank\n            description\n            code\n            accepted\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dac79899ad3ecfbd4cc4f8b651877eb5';

module.exports = node;
