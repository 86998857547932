import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'
import PropTypes from 'prop-types'
import IrisIconWhite from '../../image/img/iris-mobile-icon-white.svg'
import IrisIconBlue from '../../image/img/iris-mobile-icon-blue.svg'

const IrisIcon = ({ color = 'WHITE', width = 22, height = 22 }) => {

  let iconPath
  switch (color) {
    case 'WHITE':
      iconPath = IrisIconWhite
      break
    case 'BLUE':
      iconPath = IrisIconBlue
      break
    default:
      iconPath = IrisIconWhite
      break
  }

  return (
    <img src={iconPath}
         style={{ width: width, height: height }} />
  )
  /*return (
    <SvgIcon color={color} style={{ width: width, height: height }} viewBox="0 0 43 46">
      <path className="cls-1"
            d="M16,24a6.69,6.69,0,0,1-1.88-5,6.73,6.73,0,0,1,2.76-4.44,20.72,20.72,0,0,1,6.76-3.18A11.89,11.89,0,0,0,20.74,11,11.62,11.62,0,0,0,9,22.5,11.62,11.62,0,0,0,20.74,34,11.81,11.81,0,0,0,28,31.53C21.24,29,17.75,26.07,16,24"/>
      <path className="cls-1"
            d="M16,19.49A4.74,4.74,0,0,0,17.43,23a4,4,0,0,1,.13-1c.57-2.3,3.13-4.33,7.59-6A40.12,40.12,0,0,1,29,14.7,11.51,11.51,0,0,0,26.67,13c-1.05.2-5.76,1.2-8.59,3.38A4.83,4.83,0,0,0,16,19.49"/>
      <path className="cls-1"
            d="M19.06,22.34c-.29,1.23.5,3.26,2.85,4.66a4.06,4.06,0,0,1-.37-2.81c.44-1.81,2.16-3.35,5.13-4.58A24,24,0,0,1,31,18.31,11.47,11.47,0,0,0,29.74,16c-3.64,1-9.95,3.23-10.68,6.34"/>
      <path className="cls-1"
            d="M31.7,20c-3,.5-8.07,2.17-8.65,4.4C22.69,25.78,24.4,28.28,29,30a10.85,10.85,0,0,0,3-7.48A10.54,10.54,0,0,0,31.7,20"/>
      <path className="cls-1"
            d="M29.33,2.77l-1.57-.91L24.55,0V1.86H10.66A10.69,10.69,0,0,0,0,12.58V26a7.21,7.21,0,0,1,2.12-1.37l.33-.13V13.56a9.22,9.22,0,0,1,9.19-9.24H24.55v2l3.45-2,1.18-.68L30,3.16Z"/>
      <path className="cls-1"
            d="M41,19l-.13,0h-.11l-.14,0-.1,0H38.62V31.56a9,9,0,0,1-8.92,9.1H18.29V38.77l-3.21,1.89-1,.57-1.1.66.88.51,1.16.69L18.29,45V43.09H30.66A10.45,10.45,0,0,0,41,32.53V19h0"/>
      <path className="cls-1"
            d="M37,3a3,3,0,0,0-2,5.27l.05,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0h0l.06,0h0l.05,0h0l0,0h0l0,0h0l0,0H36l0,0h2l0,0h.09l0,0h0l0,0h0l0,0h0l.05,0h0l.06,0h0l0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,.05,0A3,3,0,0,0,37,3"/>
      <path className="cls-1"
            d="M39.16,9a4,4,0,0,1-5.3,0A8.67,8.67,0,0,0,32,13.58,1.25,1.25,0,0,0,33.29,15h6.64l.16,0h0l.07,0h0a1.26,1.26,0,0,0,.8-1.32A8.51,8.51,0,0,0,39.16,9"/>
      <path className="cls-1"
            d="M4,29a3,3,0,0,0-2,5.27l0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0h0l.06,0h0l.05,0h0l0,0h0l0,0h0l0,0H3l0,0H5l0,0h.09l0,0h0l0,0h0l0,0h0l0,0h0l.06,0h0l0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0,0,0,0,0,0,0,0,0h0l0,0,0,0,0,0A3,3,0,0,0,4,29"/>
      <path className="cls-1"
            d="M7.16,35a4.48,4.48,0,0,1-2.66.84A4.48,4.48,0,0,1,1.84,35,6.68,6.68,0,0,0,0,38.83,1.13,1.13,0,0,0,1.26,40H7.93l.17,0h0l.08,0h0A1,1,0,0,0,9,38.83,6.68,6.68,0,0,0,7.16,35"/>
    </SvgIcon>
  )*/
}

IrisIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
}

export default IrisIcon
