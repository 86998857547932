import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import _sortBy from 'lodash.sortby'

import IconButton from 'material-ui/IconButton'
import ModeEdit from '../icons/ModeEdit'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'

import { normalizeProviders } from '../../util/utils'

const styleSheet = createStyleSheet('CareTeamMembers', theme => ({
  parentContainer: {
    position: 'relative',
  },
  providerRow: {
    color: '#908787 !important',
    paddingLeft: '15px',
  },
  careTeamMemberRow: {
    paddingLeft: '15px',
  },
  editButton: {
    position: 'absolute',
    right: 0,
    top: '-30px',
  },
}))

import { showDrawer } from '../../ducks/views'
import { drawers } from '../../containers/drawer-component-mapping'
import { CareTeamList } from './CareTeamList'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class CareTeamMembers extends Component {
  static propTypes = {
    planId: PropTypes.string.isRequired,
    visitId: PropTypes.string.isRequired,
    bedExchangeName: PropTypes.string.isRequired,
    workspace: PropTypes.object,
    careTeamMembers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        role: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    providers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        role: PropTypes.shape({
          value: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  static defaultProps = {
    careTeamMembers: [],
    providers: [],
  }

  handleEdit = () => {
    this.props.actions.showDrawer(drawers.MANAGE_CARE_TEAM_MEMBERS, {
      planId: this.props.planId,
      visitId: this.props.visitId,
      workspace: this.props.workspace,
      bedExchangeName: this.props.bedExchangeName,
    })
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const providers = normalizeProviders(
      _sortBy(this.props.providers, ['role.code', 'lastName'])
    )

    const careTeamMembers = _sortBy(this.props.careTeamMembers, [
      'role.code',
      'name',
    ])

    return (
      <div className={classNames(classes.parentContainer)}>
        <div className={classNames(classes.editButton)}>
          <IconButton tooltip="Edit Care Providers" onClick={this.handleEdit}>
            <ModeEdit />
          </IconButton>
        </div>
        {providers.length > 0 && (
          <CareTeamList
            planId={this.props.planId}
            careTeamMembers={providers}
            rowClassName={classes.providerRow}
          />
        )}
        {careTeamMembers.length > 0 && (
          <CareTeamList
            planId={this.props.planId}
            careTeamMembers={careTeamMembers}
            rowClassName={classes.careTeamMemberRow}
          />
        )}
      </div>
    )
  }
}
