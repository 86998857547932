import { graphql, commitMutation, fetchQuery } from 'react-relay'

export const handleJvionMutation = (
  environment,
  mutation,
  variables,
  callback,
  errCallback
) => {
  commitMutation(environment, {
    mutation: mutation,
    variables: variables,
    onCompleted: (response, errors) => {
      if (callback) {
        //callback()
        fetchQuery(environment, fetchVectors, { id: variables.visitId })
        //variables.refetch()
      }
      if (errors && errors.length > 0) {
        errCallback(errors[0].errorType, 200, errors[0].message)
      }
    },
    onError: err => {
      console.error('Error while mutating', err)
    },
  })
}

export const acceptRecommendation = graphql`
  mutation query_accept_Mutation(
    $acceptedRecommendationId: AcceptedRecommendationId!
    $status: Boolean
    $loadId: Int!
  ) {
    acceptedRecommendationMutation {
      accept(
        acceptedRecommendationId: $acceptedRecommendationId
        status: $status
        loadId: $loadId
      ) {
        acceptedRecommendationId {
          patientId
          encounterId
          conditionName
          code
        }
        description
        predictionDate
        status
        loadId
        riskBracket
        auditMeta {
          createdBy {
            firstName
            lastName
          }
          created
          updatedBy {
            firstName
            lastName
          }
          updated
        }
      }
    }
  }
`

export const fetchVectors = graphql`
  query query_jvion_Query($id: ID!) {
    node(id: $id) {
      ... on Visit {
        id
        vector {
          id
          patientId
          encounterId
          predictionDate
          conditions {
            name
            riskBracket
            riskFactors {
              id
              type
              description
            }
            recommendations {
              id
              rank
              description
              code
              accepted
            }
          }
        }
      }
    }
  }
`
