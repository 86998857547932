import React from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderColumn,
} from 'material-ui/Table'

import { DrgRow } from './DrgRow'
import { EmptyMessageRow } from './EmptyMessageRow'
import { countDRGFilters } from '../../containers/drg/drgFilter'

import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('DrgFamilyTable', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    height100: {
      height: '100%',
      overflow: 'auto',
    },
  }
})

const HEADER_HEIGHT = 57

export const DrgFamilyTable = (
  { drgs, searchFilters, onSelectDrg, onShowSeverities, workingDischargeDispositionGroup },
  context
) => {
  const classes = context.styleManager.render(styleSheet)
  const rows = drgs.map((drg, index) => (
    <DrgRow
      key={drg.id}
      rowNumber={index}
      searchTerm={searchFilters.searchTerm}
      drg={drg}
      onSelectDrg={onSelectDrg}
      onShowSeverities={onShowSeverities}
      workingDischargeDispositionGroup={workingDischargeDispositionGroup}
    />
  ))

  const tableHeader = drgs.length && (
    <TableHeader
      adjustForCheckbox={false}
      displaySelectAll={false}
      enableSelectAll={false}>
      <TableRow>
        <TableHeaderColumn style={{ width: 50, paddingLeft: 15 }}>
          DRG
        </TableHeaderColumn>
        <TableHeaderColumn style={{ width: 60, paddingLeft: 14 }}>
          Level
        </TableHeaderColumn>
        <TableHeaderColumn style={{ width: 42, paddingLeft: 7 }}>
          ELOS
        </TableHeaderColumn>
        <TableHeaderColumn style={{ width: 34, paddingLeft: 5 }}>
          TLOS
        </TableHeaderColumn>
        <TableHeaderColumn style={{ width: 50, paddingLeft: 9 }}>
          Type
        </TableHeaderColumn>
        <TableHeaderColumn style={{ width: 100, paddingLeft: 4 }}>
          MDC
        </TableHeaderColumn>
        <TableHeaderColumn>Title</TableHeaderColumn>
        <TableHeaderColumn>Principal DXs</TableHeaderColumn>
        <TableHeaderColumn>Procedures</TableHeaderColumn>
      </TableRow>
    </TableHeader>
  )

  const emptyRowText =
    countDRGFilters(searchFilters) > 1
      ? 'No DRG families matching these filters'
      : 'Please use the search and filter options above to find the appropriate criteria'

  return (
    <Table
      onCellClick={onShowSeverities}
      height={`calc(100% - ${HEADER_HEIGHT}px)`}>
      {rows.length && tableHeader}
      <TableBody displayRowCheckbox={false}>
        {rows.length ? rows : <EmptyMessageRow text={emptyRowText} />}
      </TableBody>
    </Table>
  )
}

DrgFamilyTable.propTypes = {
  drgs: PropTypes.array.isRequired,
  searchFilters: PropTypes.object.isRequired,
  onSelectDrg: PropTypes.func.isRequired,
  onShowSeverities: PropTypes.func.isRequired,
  workingDischargeDispositionGroup: PropTypes.string
}

DrgFamilyTable.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}
