/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BoardTemplateRow_rowData$ref = any;
export type BoardTemplateInput = {|
  name: string,
  boardType: string,
  description: string,
  template: string,
|};
export type boardTemplate_addBoardTemplateEntry_MutationVariables = {|
  boardTemplateInput: BoardTemplateInput
|};
export type boardTemplate_addBoardTemplateEntry_MutationResponse = {|
  +boardTemplateMutation: ?{|
    +addBoardTemplateEntry: ?{|
      +$fragmentRefs: BoardTemplateRow_rowData$ref
    |}
  |}
|};
export type boardTemplate_addBoardTemplateEntry_Mutation = {|
  variables: boardTemplate_addBoardTemplateEntry_MutationVariables,
  response: boardTemplate_addBoardTemplateEntry_MutationResponse,
|};
*/


/*
mutation boardTemplate_addBoardTemplateEntry_Mutation(
  $boardTemplateInput: BoardTemplateInput!
) {
  boardTemplateMutation {
    addBoardTemplateEntry(boardTemplateInput: $boardTemplateInput) {
      ...BoardTemplateRow_rowData
      id
    }
  }
}

fragment BoardTemplateRow_rowData on BoardTemplate {
  id
  name
  boardType
  description
  template
  columns {
    field
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardTemplateInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardTemplateInput",
    "variableName": "boardTemplateInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "boardTemplate_addBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTemplateMutation",
        "kind": "LinkedField",
        "name": "boardTemplateMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BoardTemplate",
            "kind": "LinkedField",
            "name": "addBoardTemplateEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BoardTemplateRow_rowData"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardTemplate_addBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTemplateMutation",
        "kind": "LinkedField",
        "name": "boardTemplateMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BoardTemplate",
            "kind": "LinkedField",
            "name": "addBoardTemplateEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "boardType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "template",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardColumnConfig",
                "kind": "LinkedField",
                "name": "columns",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "field",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7b1ea49722496229e7ecc8700107e2c",
    "id": null,
    "metadata": {},
    "name": "boardTemplate_addBoardTemplateEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation boardTemplate_addBoardTemplateEntry_Mutation(\n  $boardTemplateInput: BoardTemplateInput!\n) {\n  boardTemplateMutation {\n    addBoardTemplateEntry(boardTemplateInput: $boardTemplateInput) {\n      ...BoardTemplateRow_rowData\n      id\n    }\n  }\n}\n\nfragment BoardTemplateRow_rowData on BoardTemplate {\n  id\n  name\n  boardType\n  description\n  template\n  columns {\n    field\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6178724827a574dd018a713b38a9e297';

module.exports = node;
