/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import moment from 'moment'
import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { ConfirmDialog } from '../common'
import { getBedRequestStatusName } from '../../util/utils'

const checkedIn = '#E0E0E0'
const checkedOut = '#E0E0E0'
const pending = '#C2EECB'
const dischargeOrderWithDelay = '#C3B1E1'
const anticipated = '#92A8CF'
const ready = '#83CF83'
const discharged = '#E9967A'

const styleSheet = createStyleSheet('VisitStatus', theme => {
  const { typography, palette, transitions, shadows, c3Classes } = theme

  return {
    text: {
      whiteSpace: 'nowrap',
      width: '160px',
    },
    textLarge: {
      fontSize: '16px',
    },
    textSmall: {
      fontSize: '14px',
    },
    swapped: {
      borderBottom: '4px solid #ffeb3b !important',
    },
    checkedIn: {
      borderBottom: '4px solid #E0E0E0 !important',
    },
    checkedOut: {
      // borderBottom: '4px solid #E0E0E0 !important',
    },
    pending: {
      borderBottom: '4px solid #C2EECB !important',
    },
    dischargeOrderWithDelay: {
      borderBottom: '4px solid #C3B1E1 !important',
    },
    anticipated: {
      borderBottom: '4px solid #92A8CF !important',
    },
    ready: {
      borderBottom: '4px solid #83CF83 !important',
    },
    discharged: {
      borderBottom: '4px solid #E9967A !important',
    },
    intent: {
      borderBottom: '4px solid #0693CD !important',
    },
    attr: c3Classes.attr,
  }
})

/**
 * These are the only statuses which are allowed to be edited
 * @type {Set<string>}
 */
const editableStatuses = new Set([
  'CheckedIn',
  'AnticipatedDischarge',
  'DischargeOrderWithDelay',
  'PendingDischarge',
  'ReadyForDischarge',
  'AnticipatedUnitTransfer',
  'PendingUnitTransfer',
  'ReadyForUnitTransfer',
])

const dischargeCode = {
  "AnticipatedDischarge": "Potential DC",
  "PendingDischarge": "DC Ordered",
  "ReadyForDischarge": "Ready For DC",
  "DischargeOrderWithDelay": "DC Ordered With Delay"
}

/**
 * These are the only statuses which are admission types
 * @type {Set<string>}
 */
const admissionStatuses = new Set(['Anticipated', 'Pending', 'Ready'])

export class VisitStatus extends Component {
  static propTypes = {
    visit: PropTypes.object.isRequired,
    bed: PropTypes.object,
    style: PropTypes.object,
    onVisitStatusUpdate: PropTypes.func,
    canEdit: PropTypes.bool,
    isSwapped: PropTypes.bool,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      confirmDialogOpen: false
    }
    this.unitTransferStatuses = new Set(['AnticipatedUnitTransfer', 'PendingUnitTransfer', 'ReadyForUnitTransfer'])
    this.changedBedRequestStatus = ''
  }

  handleVisitStatusChange = (event, index, value) => {
    const { visit, bed } = this.props
    const bedRequests = visit.bedRequests;
    this.changedBedRequestStatus = value

    let bedRequestStatus;
    if (bedRequests && bedRequests.length > 1) {
      let bedRequestsFiltered = bedRequests.filter(br => br.bedExchangeName === bed.exchangeName)
      if (bedRequestsFiltered.length > 0) bedRequestStatus = bedRequestsFiltered[0].bedRequestStatus
    } else {
      bedRequestStatus = visit.bedRequestStatus
    }

    if (value && isEditable(bedRequestStatus)) {
      if(!this.hasUnitTransferStatus(value) && this.hasUnitTransferStatus(bedRequestStatus)){
        //When U/T status changed to D/C or 'Checked In' status, then cancel unit transfer request and clear ETD as well.
        this.setState({confirmDialogOpen: true})
        return
      }
      this.props.onVisitStatusUpdate(visit.id, value, this.getAnticipatedDischargeTime(), this.getAnticipatedTransferTime())
    }
  }

  getAnticipatedDischargeTime = () => {
    const { visit } = this.props
    let anticipatedDischargeTime = null,
        currentTime = this.getCurrentTime(10) // Extend time by 10 minutes as we are doing on HOS

    if(visit.anticipatedDischargeTimeAutoPopulated && visit.anticipatedDischargeTime && moment(visit.anticipatedDischargeTime).format('L') === currentTime.format('L') ){
      anticipatedDischargeTime = currentTime
    }

    return anticipatedDischargeTime
  }

  getAnticipatedTransferTime = () => {
    const { visit } = this.props
    let anticipatedTransferTime = null,
        currentTime = this.getCurrentTime()

    if(visit.anticipatedTransferTimeAutoPopulated && visit.anticipatedTransferTime && moment(visit.anticipatedTransferTime).format('L') === currentTime.format('L') ){
      anticipatedTransferTime = currentTime
    }
    return anticipatedTransferTime
  }

  getCurrentTime = (extendTimeByMinutes) => {
    if(!extendTimeByMinutes){
      extendTimeByMinutes = 0
    }
    let currentTime = moment(new Date()).add(extendTimeByMinutes, 'minutes')
    return currentTime.set({second: 0})
  }

  getBedRequestStatus = (bed, visit) => {
    const match = visit.bedRequests.find(br => br.bedExchangeName === bed.exchangeName)
    return match ? match.bedRequestStatus : 'UNKNOWN'
  }

  hasUnitTransferStatus = (status) => {
    return this.unitTransferStatuses.has(status)
  }

  handleConfirmRemove = () => {
    this.setState({confirmDialogOpen: false})
    this.props.onVisitStatusUpdate(this.props.visit.id, this.changedBedRequestStatus, this.getAnticipatedDischargeTime(), this.getAnticipatedTransferTime())
  }

  handleCancelConfirmRemove = () => {
    this.setState({confirmDialogOpen: false})
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const {bed, visit, isSwapped } = this.props

    const bedRequestStatus = this.getBedRequestStatus(bed, visit)

    const status = bedRequestStatus || visit.visitStatus
    let isDischargeTimeNotSet = false

    const isEditableValue = isEditable(status)

    // insert a space before all caps AND uppercase the first character
    const statusValue = isSwapped ? 'Swapped' : humanizeStatus(status)
    const isDischargeOrderWithDelay = status === 'DischargeOrderWithDelay'

    const bedRequestStatusBackgroundClass = isSwapped
      ? classes.swapped
      : status
      ? status.startsWith('Discharged')
        ? classes.discharged
        : isDischargeOrderWithDelay
        ? classes.dischargeOrderWithDelay
        : status.startsWith('Pending')
        ? classes.pending
        : status.startsWith('Anticipated')
        ? classes.anticipated
        : status.startsWith('Ready')
        ? classes.ready
        : status.startsWith('Intent')
        ? classes.intent
        : classes.checkedIn
      : classes.checkedOut

    const bedRequestStatusColor = status
      ? status.startsWith('Discharged')
        ? discharged
        : isDischargeOrderWithDelay
        ? dischargeOrderWithDelay
        : status.startsWith('Pending')
        ? pending
        : status.startsWith('Anticipated')
        ? anticipated
        : status.startsWith('Ready')
        ? ready
        : checkedIn
      : checkedOut

    let result
    if (isSwapped || !this.props.canEdit || !isEditableValue) {
      const textSize =
        statusValue.length > 10 ? classes.textSmall : classes.textLarge
      result = (
        <div className={classNames(classes.attr, classes.text, textSize)}>
          <div className={classNames(bedRequestStatusBackgroundClass)}>
            {statusValue}
          </div>
        </div>
      )
    } else if (this.props.canEdit && isEditableValue) {
      const thisValue = isEditableValue ? status : null
      result = (
        <>
        <SelectField
          style={{
            width: 220,
            height: 48,
            top: -10,
            padding: '0 !important',
          }}
          value={thisValue}
          onChange={this.handleVisitStatusChange}
          underlineStyle={{
            borderBottom: '4px solid',
            color: bedRequestStatusColor,
          }}>
          <MenuItem
            value="CheckedIn"
            className={classes.checkedIn}
            primaryText="Checked In"
          />
          <MenuItem
            value="AnticipatedDischarge"
            className={classes.anticipated}
            primaryText={getBedRequestStatusName("AnticipatedDischarge")}
          />
          <MenuItem
            value="DischargeOrderWithDelay"
            className={classes.dischargeOrderWithDelay}
            primaryText={getBedRequestStatusName("DischargeOrderWithDelay")}
          />
          <MenuItem
            value="PendingDischarge"
            className={classes.pending}
            primaryText={getBedRequestStatusName("PendingDischarge")}
            disabled={isDischargeTimeNotSet}
          />
          <MenuItem
            value="ReadyForDischarge"
            className={classes.ready}
            primaryText={getBedRequestStatusName("ReadyForDischarge")}
            disabled={isDischargeTimeNotSet}
          />
          <MenuItem
            value="AnticipatedUnitTransfer"
            className={classes.anticipated}
            primaryText={getBedRequestStatusName("AnticipatedUnitTransfer")}
          />
          <MenuItem
            value="PendingUnitTransfer"
            className={classes.pending}
            primaryText={getBedRequestStatusName("PendingUnitTransfer")}
            disabled={isDischargeTimeNotSet}
          />
          <MenuItem
            value="ReadyForUnitTransfer"
            className={classes.ready}
            primaryText={getBedRequestStatusName("ReadyForUnitTransfer")}
            disabled={isDischargeTimeNotSet}
          />
        </SelectField>

        {this.state.confirmDialogOpen && (
          <ConfirmDialog
            open={this.state.confirmDialogOpen}
            message={`Unit transfer request will be canceled. Are you sure you want to change the status of the bed request to ${humanizeStatus(this.changedBedRequestStatus)}?`}
            onCancelClick={this.handleCancelConfirmRemove}
            onConfirmClick={this.handleConfirmRemove}
          />
        )}
        </>
      )
    }

    return result
  }
}

/**
 * Returns true if we're allowed to edit the given status
 * @param {string} status
 * @returns {boolean}
 */
function isEditable(status: string): boolean {
  return editableStatuses.has(status)
}

/**
 * Returns true if given an admission status
 * @param {string} status
 * @returns {boolean}
 */
function isAdmission(status: string): boolean {
  return admissionStatuses.has(status)
}

/**
 * Insert a space before all caps AND uppercase the first character of the status.
 * Add Admission to the end of admission statuses
 * @param {string} status
 * @returns {string} human readable status
 */
export function humanizeStatus(status = '') {
  //One can add an acronym for a particular status and its respective value, the text which needs to be replaced,
  //in this following object i.e. statusWithAcronyms.
  //ES6 map was not used on purpose for its limited support for IE edge

  let statusWithAcronyms = { UT: 'Unit Transfer' }
  let foundKeys = Object.keys(statusWithAcronyms).filter(function(key) {
    return statusWithAcronyms[key].toUpperCase() === status.toUpperCase()
  })

  if (foundKeys.length > 0) {
    status = foundKeys[0]
  }

  //Below mentioned regex is putting a space between the CamelCase one word status to make it human readable.
  return (getBedRequestStatusName(status))
    .replace(/([a-z]+)([A-Z])/g, '$1 $2')
    .replace(/^./, str => str.toUpperCase())
}
