// import {createNetworkInterface} from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import 'unfetch/polyfill'

import {
  sessionStorageJWTToken,
  noEmptyStringOperationName,
} from './cl-apollo-middleware'
import { handleNetworkErrors, handleGraphQlErrors } from './cl-apollo-afterware'

import { baseGraphQLURL } from '../util/base-url'

const standardMiddlewares = [sessionStorageJWTToken, noEmptyStringOperationName]

const standardAfterwares = [handleNetworkErrors, handleGraphQlErrors]

const standardApolloNetworkInterface = createHttpLink({
  uri: `${baseGraphQLURL}/graphql`,
})

// https://www.apollographql.com/docs/link/composition.html#additive
export function applyStandardInterceptors() {
  return ApolloLink.from(
    standardMiddlewares.concat(
      standardAfterwares,
      standardApolloNetworkInterface
    )
  )
}
