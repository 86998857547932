import minilog from 'minilog'
import { Logger } from './types'

export const LogLevel = {
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
}

// TODO: This should be externalized
const loggerConfig = {
  defaultLevel: LogLevel.WARN,
  rules: [],
}

setupLoggingFilters()
minilog.enable()

export const LogManager = {
  getLogger(name: string): Logger {
    return minilog(name)
  },

  setLogLevel(
    levelPatternOrName: string | RegExp | LogLevel,
    level?: LogLevel
  ) {
    if (level) {
      let rule = loggerConfig.rules.find(r => r.name === levelPatternOrName)
      if (rule) {
        rule.level = level
      } else {
        loggerConfig.rules.push({ name: levelPatternOrName, level })
      }
    } else {
      loggerConfig.defaultLevel = levelPatternOrName
    }

    setupLoggingFilters()
  },
}

function setupLoggingFilters() {
  // Clear existing filtering. We need to reset it all.
  let levelFilter = minilog.suggest
  levelFilter.clear()

  // Apply all values from the logger config to the filter
  levelFilter.defaultResult = false
  loggerConfig.rules.forEach(({ name, level }) => {
    levelFilter.allow(name, level)
    levelFilter.deny(name, level)
  })

  // This blanket rule allows anything not previously matched to come through at the given default level
  levelFilter.allow(/.*/, loggerConfig.defaultLevel)
}
