/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import MenuItem from 'material-ui/MenuItem'
import DropDownMenu from 'material-ui/DropDownMenu'
import ArrowDown from 'mdi-react/MenuDownIcon'

class BedsSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = { opened: false }
  }

  onClose = () => {
    this.setState({ open: false })
  }

  handleSelect = bed_visit => {
    this.onClose()
    this.props.handleSelect(bed_visit)
  }

  handleClick = () => {
    this.setState({ open: true })
  }

  renderDropDown(selectedValue, textColor, workspaces) {
    const items = workspaces.map(ws => (
      // CA-2850 - use bed_visit as value
      <MenuItem
        key={`${ws.bed.exchangeName}_${ws.visit.id}`}
        value={`${ws.bed.exchangeName}_${ws.visit.id}`}
        label={`${ws.bed.name}`}
        primaryText={`${ws.bed.name} ${ws.visit.patient.lastName}`}
      />
    ))

    return (
      <DropDownMenu
        value={selectedValue && selectedValue.bed.exchangeName}
        style={{
          whiteSpace: 'nowrap',
          lineHeight: '36px',
          height: '36px',
          verticalAlign: 'middle',
        }}
        iconStyle={{ right: 10, top: -8, fill: textColor }}
        labelStyle={{
          fontSize: 14,
          fontWeight: 500,
          paddingRight: '36px',
          height: '20px',
          lineHeight: '20px',
          top: 6,
          color: textColor,
          width: 100,
        }}
        underlineStyle={{ display: 'none' }}
        disabled={workspaces.length === 0}
        onChange={(event, key, value) => this.handleSelect(value)}
        onClose={this.onClose}
        openImmediately>
        {items}
      </DropDownMenu>
    )
  }

  renderButton(selectedValue, textColor) {
    return (
      <FlatButton
        style={{ color: 'white', width: 130, top: -2 }}
        icon={<ArrowDown style={{ marginLeft: 0, fill: 'white' }} />}
        labelPosition="before"
        disabled={false}
        onClick={() => this.handleClick()}
        label={selectedValue && selectedValue.bed.name}
      />
    )
  }

  render() {
    const { workspaces, selectedValue, textColor } = this.props
    const { open } = this.state

    if (open) {
      return this.renderDropDown(selectedValue, textColor, workspaces)
    }

    return this.renderButton(selectedValue, textColor)
  }
}

BedsSelect.propTypes = {
  selectedValue: PropTypes.object,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      bed: PropTypes.object,
      visit: PropTypes.object,
      plan: PropTypes.object,
    })
  ),
  textColor: PropTypes.string,
  handleSelect: PropTypes.func,
}

export default BedsSelect
