/*eslint-disable react/no-set-state */

import React from 'react'
import PropTypes from 'prop-types'
import { VisitSectionGQL } from '../../containers/plan/VisitSectionGQL'
import { BarrierSectionGQL } from '../../components/barrier/BarrierSectionGQL'
import { NoteSectionGQL } from '../../components/note/NoteSectionGQL'
import {CombinedMilestoneActionSectionGQL} from '../../components/combined-milestone-action/CombinedMilestoneActionSectionGQL'
import SwipeableViews from 'react-swipeable-views'
import { VectorSectionGQL } from '../../plugins/jvion/VectorSectionGQL'
import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setDetailTabIndex } from '../../ducks/views'

const mapStateToProps = state => ({
  detailTabIndex: state.viewsAppState.detailTabIndex,
  detailTabHeight: state.viewsAppState.detailTabHeight,
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setDetailTabIndex,
      },
      dispatch
    ),
  }
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
export default class DetailTabs extends React.Component {
  constructor(props) {
    super(props)
  }

  state = {
    detailTabIndex: 0,
  }

  render() {
    const {
      workspace,
      jvionEnabled,
      detailTabIndex,
      detailTabHeight,
      actions: { setDetailTabIndex },
      userSetting,
      barrierEscalationRoles,
      deliveryMethods
    } = this.props

    const views = [
      <div key={'plan'} style={{ paddingRight: 10, paddingLeft: 10 }}>
        <BarrierSectionGQL
          barrierEscalationRoles={barrierEscalationRoles}
          deliveryMethods={deliveryMethods}
          userBarriers={userSetting.barriers}
          refetch={this.props.refetch}
          workspace={workspace} />
        <CombinedMilestoneActionSectionGQL
          userActions={userSetting.actions}
          plan={workspace.plan} visit={workspace.visit} />
       <NoteSectionGQL userNotes={userSetting.notes}  workspace={workspace} />
      </div>,
      <VisitSectionGQL key={'visit'} workspace={workspace} />,
    ]

    if (jvionEnabled) {
      views.push(
        <VectorSectionGQL
          style={{ paddingRight: 10, paddingLeft: 10 }}
          key={'vector'}
          workspace={workspace}
          refetch={this.props.refetch}
        />
      )
    }

    return (
      <div className="detail-tabs" style={{ height: `${detailTabHeight}px` }}>
        <StyledTabs
          onChange={(e, v) => {
            setDetailTabIndex(v)
          }}
          value={detailTabIndex}
          variant="fullWidth">
          <Tab label="PLAN" value={0} />
          <Tab label="VISIT" value={1} />
          {jvionEnabled && <Tab label="JVION" value={2} />}
        </StyledTabs>
        <div className="tab-container">
          <SwipeableViews
            index={detailTabIndex}
            onChangeIndex={setDetailTabIndex}>
            {views}
          </SwipeableViews>
        </div>
      </div>
    )
  }
}

DetailTabs.propTypes = {
  refetch: PropTypes.func,
  workspace: PropTypes.object.isRequired,
  jvionEnabled: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    setDetailTabIndex: PropTypes.func.isRequired,
  }),
  detailTabIndex: PropTypes.number,
  detailTabHeight: PropTypes.number,
  userSetting: PropTypes.shape({
    actions: PropTypes.array,
    barriers: PropTypes.array,
    notes: PropTypes.array,
  }),
  barrierEscalationRoles: PropTypes.array,
  deliveryMethods: PropTypes.array
}
const StyledTabs = styled(Tabs)`
  background-color: #4dd0e1;
  color: #ffffff;
  font-weight: bold;
`
