import React from 'react'
import PropTypes from 'prop-types'

import { TableRow, TableRowColumn } from 'material-ui/Table'

export const EmptyMessageRow = ({ text }) => {
  return (
    <TableRow>
      <TableRowColumn>
        <div style={{ textAlign: 'center' }}>{text}</div>
      </TableRowColumn>
    </TableRow>
  )
}

EmptyMessageRow.propTypes = {
  text: PropTypes.string.isRequired,
}
