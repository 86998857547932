//This class should not be emulated due to the 'dangerouslySetInnerHTML'.
//There is probably a better way to decorate text HTML, but
//I am not sure of how to do this yet. --briggs

/*eslint-disable  react/no-danger*/
import React from 'react'
import PropTypes from 'prop-types'
import { extractSearchTokens } from '../../containers/drg/drgFilter'

/**
 * A component which takes in a string 'value' and a string 'searchTerm' and will highlight the given
 * searchTerm.
 *
 * @param props
 * @constructor
 */
export const Highlighter = props => (
  <span>
    <span
      dangerouslySetInnerHTML={{
        __html: highlight(props.value, props.searchTerm),
      }}
    />
  </span>
)

Highlighter.propTypes = {
  value: PropTypes.string,
  searchTerm: PropTypes.string,
}

function highlight(value = '', searchText = '') {
  if (value) {
    const texts = extractSearchTokens(searchText)
    const text = texts.join('|')
    if (text.length) {
      const regex = new RegExp(text, 'gi')
      //this returns RAW html and is not JSX syntax.
      return value.replace(
        regex,
        `<span style="background-color: #ffd226 !important">$&</span>`
      )
    }
  }

  return value
}
