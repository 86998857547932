import React from 'react'
import PropTypes from 'prop-types'

import '../escalation.scss'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'

import styled from 'styled-components'

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    horizontal: 'left',
    vertical: 'bottom'
  }
}

const CodeTypeDropdownFilter = ({ onChange, onClose, valueSelected, allSelectedText, filterLabel, onOpen }) => {

  const selectionRenderer = values => {
    if (values.find(value => value.code === 'ALL')) {
      return allSelectedText
    }

    return values.filter(val => val.checked && val.code !== 'ALL')
      .map(val => val.value)
      .join(', ')
  }

  return (
    <div className="filter-sub-container">
      <div className="filter-header">
        <span className="filter-label">{filterLabel}</span>
      </div>
      <div className="filter-items">
        <Select
          fullWidth
          multiple
          onChange={onChange}
          onClose={onClose}
          onOpen={onOpen}
          value={valueSelected.filter(
            value => value.checked
          )}
          renderValue={selectionRenderer}
          MenuProps={MenuProps}>
          {valueSelected.map(codeType => (
            <StyledMenuItem key={codeType.code} value={codeType}>
              <Checkbox checked={codeType.checked} color={'primary'}/>
              <ListItemText primary={codeType.value} />
            </StyledMenuItem>
          ))}
        </Select>
      </div>
    </div>
  )
}

const StyledMenuItem = styled(MenuItem)`
  && {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`

CodeTypeDropdownFilter.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  valueSelected: PropTypes.array,
  allSelectedText: PropTypes.string,
  filterLabel: PropTypes.string
}

export default CodeTypeDropdownFilter
