import React from 'react'
import PropTypes from 'prop-types'

import { Dialog, FlatButton } from 'material-ui'


/**
 * This is a Dialog to display Mutation errors detected by Server
 * @param errors - The strange data structure from server errors and validations
 * @param onRequestClose - Callback when the snackbar goes to close (usually removes the data that causes it in the first place)
 * @param autoHideDuration - How long to show the message before disappearing. Default 10s
 * @param props - Any additional props to provide to directly to the underlying MUI Snackbar
 */
export const TransactionErrorDialog = ({
                                         errors = [],
                                         onRequestClose,
                                       }) => {
  const open = errors.length > 0
  const id = errors[0] ? errors[0].id : 0
  const actions = [
    <FlatButton
      key="close"
      label="Close"
      primary
      onClick={() => onRequestClose(id)}
    />,
  ]

  const getTitle = (errors) => {
    return (errors?.length > 0 && errors[0].message.includes("Internal server error")) ? "Error" : "Validation Error"
  }

  const getMessage = (message) => {
    return message.includes(':') ? message.split(':')[1] : message
  }

  return (
    <Dialog
      actions={actions}
      modal
      title={getTitle(errors)}
      open={open}
      onRequestClose={() => onRequestClose(id)}>
      {errors.map(({ id, message }) => (
        <div key={id}>{getMessage(message)}</div>
      ))}
    </Dialog>
  )
}

TransactionErrorDialog.propTypes = {
  errors: PropTypes.array,
  onRequestClose: PropTypes.func,
}
