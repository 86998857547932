import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {sortBy, some, filter } from 'lodash'

import { UnitRow, UnitFacilitiesRow } from './'
import './UnitList.scss'

export const UnitList = (
  {
    facilities = [],
    myLocations = [],
    myNextLocations = [],
    disabled = false,
    onFacilityClick = () => {},
    onUnitClick = () => {},
    onBedClick = () => {},
  },
  context
) => {

  const [facilityVisibility, setFacilityVisibility] = useState(Array(facilities.length).fill(true))

  const getFacilitySelections = () => {
    const facilitySelections = []
    // this will loop though the unit beds selections and update facility selection
    facilities.forEach((fac, idx) => {
      // for each facility, let's loop each bed in a facility department and check if it is in "myNextLocations"
      let hasItem = false
      // using array.every is better way to short circuit the array loop since forEach does not have break
      fac.departments.every(unit => {
        unit.beds.every(bed => {
          hasItem = some(myNextLocations, (loc) => loc === bed.exchangeName)
          return !hasItem
        })
        return !hasItem
      })
      if (hasItem) {
        facilitySelections.push(fac.exchangeName)
      }
    })
    return facilitySelections
  }

  const cleanFacilities = (facArr) => filter(facArr, fac => {
    return Array.isArray(fac.departments) && fac.departments.length > 0;
  }) || [];

  const renderFacilityOptions = () => (
    <div className="unit-row facilities-row sticky" key="facilities-row">
      <UnitFacilitiesRow
        facilities={cleanFacilities(facilities)}
        facilitySelections={getFacilitySelections()}
        disabled={disabled}
        onFacilityClick={onFacilityClick}
      />
    </div>
  )

  const toggleFacilityVisibility = (index) => {
    facilityVisibility[index] = !facilityVisibility[index]
    setFacilityVisibility([...facilityVisibility])
  }

  return (
    <div className="unit-list">
      { renderFacilityOptions() }

      {sortBy(cleanFacilities(facilities), [facility => facility.ordinal]).map((fac, idx) => {
          return (
            <div key={'item-' + fac.exchangeName}>
            <div
              className={`facility-name ${facilityVisibility[idx] ? 'expanded' : 'collapse'}`}
              key={'title-' + fac.exchangeName}
              onClick={() => {toggleFacilityVisibility(idx)}}
            >
              <div className="title-caret" />
              <label>{fac.name}</label>
              {!facilityVisibility[idx] &&
                <small>(Click to expand)</small>
              }
            </div>
              {facilityVisibility[idx] &&
              <div className="unit-row" key={'list-' + fac.exchangeName}>
                {sortBy(fac.departments, ['type', 'name']).map(unit => {
                  return (
                    <div className="unit-row" key={unit.exchangeName}>
                      <UnitRow
                        unit={unit}
                        disabled={disabled}
                        myLocations={myLocations}
                        myNextLocations={myNextLocations}
                        onUnitClick={onUnitClick}
                        onBedClick={onBedClick}
                      />
                    </div>
                  )
                })
                }
              </div>
              }
            </div>
          )
          }
      )}
    </div>
  )
}


UnitList.propTypes = {
  facilities: PropTypes.array,
  myLocations: PropTypes.array,
  myNextLocations: PropTypes.array,
  disabled: PropTypes.bool,
  onFacilityClick: PropTypes.func,
  onUnitClick: PropTypes.func,
  onBedClick: PropTypes.func,
}
