import React from 'react'
import PropTypes from 'prop-types'

import { VictoryLabel } from 'victory'

export class ClickableVictoryLabel extends VictoryLabel {
  static propTypes = {
    onClick: PropTypes.func,
  }

  handleClick = () => {
    const { data, datum, onClick } = this.myProps
    onClick(data[datum - 1])
  }

  render() {
    this.myProps = {
      ...this.props,
      events: {
        onClick: this.handleClick,
      },
    }

    return <VictoryLabel {...this.myProps} />
  }
}
