import React from 'react'
import PropTypes from 'prop-types'

import { shadeBlend } from './utils'

import {
  VictoryContainer,
  VictoryLabel,
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
} from 'victory'

import { ClickableVictoryLabel } from './'

export class BarChart extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    labels: PropTypes.array,
    selected: PropTypes.object,
    style: PropTypes.object,
    theme: PropTypes.object,
    onBarClick: PropTypes.func,
    onLabelClick: PropTypes.func,
  }

  handleTouchStart(e, obj) {
    return this.handleStart(e, obj)
  }

  handleMouseDown(e, obj) {
    return this.handleStart(e, obj)
  }

  handleStart(e, obj) {
    e.stopPropagation()
    return [
      /*{
        mutation: props => {
          return {
            style: Object.assign({}, props.style, {
              fill: shadeBlend(0.3, props.datum.color),
            }),
          }
        },
      },*/
    ]
  }

  handleTouchEnd(e, obj) {
    return this.handleEnd(e, obj, true)
  }

  handleMouseUp(e, obj) {
    return this.handleEnd(e, obj, true)
  }

  handleClick(e, obj) {
    return this.handleEnd(e, obj, true)
  }

  handleEnd(e, obj, doCallback) {
    e.stopPropagation()
    return [
      {
        target: 'labels',
        mutation: props => {
          if (obj.index === props.index) {
            doCallback && this.props.onBarClick(e, props.datum)
            return null
          } else {
            return null
          }
        },
      },
      /*{
        mutation: props => {
          return {
            style: Object.assign({}, props.style, {fill: props.datum.color}),
          }
        },
      },*/
    ]
  }

  render() {
    const { data, labels, selected, style } = this.props

    const selectedX = selected ? selected.x : -1

    // Now data is padded to 40 items so best width is 1500
    let width = 1500
    let height = 260

    VictoryTheme.material.axis.style.grid = {
      fill: 'transparent',
      stroke: 'transparent',
      pointerEvents: 'none',
    }

    let hasExcessDays = data.reduce((acc, item) => {
      acc = item.obj.excessDays > 0 ? true : acc
      return acc
    }, false)

    // The reason to perform a slice on the label below is limit the text to 10 chars but still allow all the ticks to be drawn
    return (
      <VictoryChart
        ref="victoryChart"
        standalone={false}
        containerComponent={
          <VictoryContainer responsive={false} width={width} height={height} />
        }
        theme={VictoryTheme.material}
        width={width}
        height={height}
        style={{
          parent: {},
        }}
        domainPadding={{ x: 20, y: 10 }}
        padding={{ top: 5, right: 20, bottom: 80, left: 40 }}
        defaultAxes={{
          dependent: (
            <VictoryAxis
              dependentAxis
              tickLabelComponent={
                <VictoryLabel
                  dx={5}
                  style={{
                    tickLabels: {
                      fontSize: 12,
                    },
                  }}
                />
              }
            />
          ),
          independent: (
            <VictoryAxis
              tickValues={labels}
              tickLabelComponent={
                <ClickableVictoryLabel
                  data={data}
                  dx={-2}
                  dy={-5}
                  text={i => labels[i - 1].slice(0, 10)}
                  onClick={this.props.onLabelClick}
                />
              }
              style={{
                axis: { xstroke: 'gray' },
                ticks: { xstroke: 'gray' },
                tickLabels: {
                  cursor: 'pointer',
                  fontSize: 12,
                  padding: 0,
                  fill: 'black',
                  textAnchor: 'end',
                  verticalAnchor: 'end',
                  angle: 290,
                },
              }}
            />
          ),
        }}>
        <VictoryBar
          name="bar"
          data={hasExcessDays ? data : []}
          labels={datum => (selectedX === datum.x ? datum.y : '')}
          style={{
            ...style,
            data: {
              width: 30,
              fill: datum => datum.color,
            },
            labels: {
              fontSize: 12,
              textAnchor: 'middle',
              verticalAnchor: 'middle',
            },
          }}
          events={[
            {
              target: 'data',
              eventHandlers: {
                onMouseDown: (e, obj) => this.handleMouseDown(e, obj),
                onMouseUp: (e, obj) => this.handleMouseUp(e, obj),
              },
            },
          ]}
        />
      </VictoryChart>
    )
  }
}
// onTouchStart: (e, obj) => this.handleTouchStart(e, obj),
// onTouchEnd: (e, obj) => this.handleTouchEnd(e, obj),
// onClick: (e, obj) => this.handleClick(e, obj),
