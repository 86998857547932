import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'

export const CurrentLocation = ({
  department,
  bed,
  visit,
  onMoveToHoldingArea,
}) => {
  return (
    <div>
      {visit.bedConflict && (
        <span style={{ paddingLeft: '15px', color: 'red' }}>
          Patient in Holding Area
        </span>
      )}
      <span style={{ paddingLeft: '15px' }}>
        Home Bed: {bed.name} / {department.name}
      </span>
      {onMoveToHoldingArea && department.type !== 'AdmissionSource' && (
        <span style={{ float: 'right', position: 'relative', top: '-12px' }}>
          <FlatButton
            label="Move to Holding Area"
            backgroundColor="transparent"
            onClick={() => onMoveToHoldingArea(bed, visit)}
          />
        </span>
      )}
    </div>
  )
}

CurrentLocation.propTypes = {
  department: PropTypes.object.isRequired,
  bed: PropTypes.object.isRequired,
  visit: PropTypes.object.isRequired,
  onMoveToHoldingArea: PropTypes.func,
}
