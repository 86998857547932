/* eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import FlatButton from 'material-ui/FlatButton'

import { showDrawer } from '../../ducks/views'
import { drawers } from '../../containers/drawer-component-mapping'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class AddBarrier extends React.Component {
  constructor(props) {
    super(props)
  }

  handleOpen = () => {
    const {
      actions: { showDrawer },
      visit: { plan, patient },
      refetch,
    } = this.props

    showDrawer(drawers.CHOOSE_BARRIER, {
      title: `Add Barrier for ${patient.lastName}, ${patient.firstName}`,
      planId: plan.id,
      refetch,
      visit: this.props.visit
    })
  }

  render() {
    return <FlatButton label="Add Barrier" onClick={this.handleOpen} />
  }
}

AddBarrier.propTypes = {
  actions: PropTypes.object,
  visit: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
}
