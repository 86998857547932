import React from 'react'
import PropTypes from 'prop-types'
import { StatefulTextField } from '../../../common'

const TextColumn = ({ todo, expanded, onUpdateActionText, onRemoveAction, onTodoTextClick }) => {
  const { id, text, track, status } = todo

  const strikethroughTextClass = status === 'NA' ? 'strikethroughText' : ''

  const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0

  const jsx = expanded ?
    (<span className="todoTextWrapper">
      <div className="todoEditableText">
        <StatefulTextField
          text={text}
          textFieldProps={{
            fullWidth: true,
            hintText: track === 'T' ? 'To-Do will be deleted' : 'Save not possible with text field blank.',
            multiLine: isSafari
          }}
          onEnter={value => {
            if (value && value.length > 0) {
              onUpdateActionText(id, value)
            } else if (track === 'T') {
              onRemoveAction(id)
            }
          }}
          maxNumberOfChars={255}
          allowEmptyText
          clearOnTextEntered={false}
          showCharacterCount
          blurOnEnter
        />
      </div>
    </span>)
    : (<span className={strikethroughTextClass}>{text}</span>)
  return <td className="todoColumnCell" onClick={onTodoTextClick}>{jsx}</td>
}

TextColumn.propTypes = {
  todo: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  onUpdateActionText: PropTypes.func,
  onRemoveAction: PropTypes.func,
  onTodoTextClick: PropTypes.func
}

export default TextColumn
