import React from 'react'
import PropTypes from 'prop-types'

import IconButton from 'material-ui/IconButton'
import PinIcon from 'mdi-react/PinIcon'

import {
  blue800 as PinColor,
  grey500 as UnpinColor,
} from 'material-ui/styles/colors'

export const Pin = ({ item, onClick, rerender, showButtonBorder = false }) => {
  const visible = !item.status || item.status === 'Open'
  const buttonBorderStyle = showButtonBorder ? {
    border: '1px solid #b3b3b3',
    borderRadius: '5px',
    padding: '2px'
  } : {}
  const iconHeight = showButtonBorder ? 28 : 24
  const iconWidth = showButtonBorder ? 28 : 24

  return (
    <span
      onClick={e => e.stopPropagation()}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <IconButton
        style={{
          visibility: visible ? 'visible' : 'hidden',
          fill: item.pinned ? PinColor : UnpinColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 8,
          width: 40,
          height: 40,
        }}
        iconStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...buttonBorderStyle
        }}
        onClick={() => onClick(item, rerender)}>
        <PinIcon height={iconHeight} width={iconWidth} />
      </IconButton>
    </span>
  )
}

Pin.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  rerender: PropTypes.func,
  showButtonBorder: PropTypes.bool
}
