/*eslint-disable react/no-set-state */

import React from 'react'
import {connect} from "react-redux"
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import {setFilter, resetFilter, setQuery,
  getQueryString, getInitialState} from '../../ducks/escalation-filters'
import MenuItem from "material-ui/MenuItem"
import {white as textColor} from "material-ui/styles/colors"
import DropDownMenu from "material-ui/DropDownMenu"
import FlatButton from "material-ui/FlatButton"
import ArrowDown from "mdi-react/MenuDownIcon"
import {getConfig, parseTimeValue} from "../../util/configs"
import {filterConstants} from "../../util/filters"

class QuickFilterComp extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filter: { ...props.filter },
      opened: false,
      selectedValue: this.props.filter.quickFilters.find(item => item.checked)
    }

  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.filter.invokedAsQuickFilter) {
      this.setState({selectedValue: ''})
    }
  }

  onClose = () => {
    this.setState({ open: false })
  }

  handleSelect = (event, index, value) => {
    this.onClose()

    const quickFilters = this.state.filter.quickFilters
    const selected = quickFilters[index]

    if(selected.value !== 'Reset') {
      this.setState({
        filter: {
          ...this.state.filter,
          quickFilters: quickFilters,
        },
        selectedValue: selected
      })

      this.props.resetFilter()

      let newFilter
      const initialState = getInitialState().filter
      switch (selected.code) {
        case 'escalatedBarriers':
          newFilter = {
            ...initialState,
            barriers: { selected: 'escalated' }
          }
          break

        case 'pastLOSThreshold':

          //fetch threshold time for the medically clear status
          var thresholdTime = getConfig("ca.obs/out.patient.los.threshold.time")
          //convert time to seconds
          thresholdTime = parseTimeValue( thresholdTime ) 
          //convert time to hours 
          thresholdTime = parseInt(thresholdTime / 3600000 )
          //validate time
          thresholdTime = (0 >=thresholdTime<=48) ? thresholdTime : 0
            
          newFilter = {
            ...initialState,
            admissionType: 'obsout',
            losPreview: {
              decisionTimeDue: thresholdTime
            }
          }
          break

        case 'excessDays':
          newFilter = {
            ...initialState,
            admissionType: 'inpatient',
            losPreview: {
              value: 'forecastExcessDayGreater',
              days: parseInt(getConfig('ca.quickfilter.excess.days.above.integer')),
              oeForecastTarget: 1,
              oeCurrentTarget: 1,
            },
            barriers: {
              selected: 'none',
              open: false,
              escalated: false
            }
          }
          break

        case 'openNoOwner':
          newFilter = {
            ...initialState,
            barrierOwnerRadio: {
              value: filterConstants.barrierOwnerRadio.noOwners.value
            },
            barriers: {
              selected: filterConstants.barriers.withOpenBarriers
            }
          }
          break
      }

      if(newFilter) {
        this.props.setFilter({...newFilter, invokedAsQuickFilter: true})
        this.props.setQuery(getQueryString(newFilter))
      } else {
        // it's just a reset for "No Quick Filter"
        this.props.setQuery('')
      }

    } else  { // Reset chosen
      this.props.resetFilter()
      this.props.setQuery('')
    }

  }

  handleClick = () => {
    this.setState({ open: true })
  }

  renderDropDown(selectedValue) {

    const getMenuValue = (filter) => {
      switch(filter.code) {
        case "excessDays":  
            return `Excess > ${parseInt(getConfig('ca.quickfilter.excess.days.above.integer'))}, No Open Barriers`
        case "pastLOSThreshold":   
            return `Obs/Out, LOS > ${parseInt(getConfig("ca.obs/out.patient.los.threshold.time"))}h`
        default:      
            return filter.value
      }
    }

    const items = this.state.filter.quickFilters.map(filter => (
        <MenuItem
          key={filter.code}
          value={filter}
          primaryText={
            <div className="menu-item">{ getMenuValue(filter) }
            </div>}
          // checked={filter.checked}   // for now don't check selected item
        />
      )
    )

    return (
      <span>
        <DropDownMenu
          value={this.state.selectedValue}
          style={{
            whiteSpace: 'nowrap',
            lineHeight: '36px',
            height: '36px',
            verticalAlign: 'middle',
            width: 260,
          }}
          iconStyle={{ right: 10, top: -8, fill: textColor }}
          labelStyle={{
            fontSize: 14,
            fontWeight: 500,
            paddingRight: '36px',
            height: '20px',
            lineHeight: '20px',
            top: 6,
            color: textColor,
            width: 100,
          }}
          underlineStyle={{ display: 'none' }}
          onChange={this.handleSelect}
          onClose={this.onClose}
          openImmediately>
          {items}
        </DropDownMenu>
      </span>
    )
  }

  renderButton(selectedValue) {
    return (
      <FlatButton
        style={{ color: 'white', width: 260, top: -2 }}
        icon={<ArrowDown style={{ marginLeft: 0, fill: 'white' }} />}
        labelPosition="before"
        disabled={false}
        onClick={() => this.handleClick()}
        // label={selectedValue && selectedValue.value}   // this would show what was selected on AppHeader
        label="Quick Filters"
      />
    )
  }

  render() {
    const { open, selectedValue } = this.state

    if (open) {
      return this.renderDropDown(selectedValue)
    }

    return this.renderButton(selectedValue)
  }

}

QuickFilterComp.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  setQuery: PropTypes.func
}

function mapStateToProps(state) {
  return {
    filter: state.escalationView.filter,
  }
}

export const QuickFilter = compose(
  connect(
    mapStateToProps,
    {setFilter, resetFilter, setQuery}
  )
)(QuickFilterComp)
