/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BarrierSourceTypeInput = {|
  code: string,
  value: string,
  ordinal: number,
  active: boolean,
  activeDirectorySourceName: boolean,
  sourceDetailCodes?: ?$ReadOnlyArray<?string>,
|};
export type barrierSourceType_addBarrierSourceTypeEntry_MutationVariables = {|
  barrierSourceTypeInput: BarrierSourceTypeInput
|};
export type barrierSourceType_addBarrierSourceTypeEntry_MutationResponse = {|
  +barrierSourceMutation: ?{|
    +addBarrierSourceTypeEntry: ?{|
      +id: string,
      +code: string,
      +value: string,
      +ordinal: number,
      +active: boolean,
      +activeDirectorySourceName: boolean,
      +hasConfiguredList: boolean,
      +sourceDetails: ?$ReadOnlyArray<?{|
        +code: string,
        +value: string,
        +active: boolean,
        +ordinal: number,
      |}>,
    |}
  |}
|};
export type barrierSourceType_addBarrierSourceTypeEntry_Mutation = {|
  variables: barrierSourceType_addBarrierSourceTypeEntry_MutationVariables,
  response: barrierSourceType_addBarrierSourceTypeEntry_MutationResponse,
|};
*/


/*
mutation barrierSourceType_addBarrierSourceTypeEntry_Mutation(
  $barrierSourceTypeInput: BarrierSourceTypeInput!
) {
  barrierSourceMutation {
    addBarrierSourceTypeEntry(barrierSourceTypeInput: $barrierSourceTypeInput) {
      id
      code
      value
      ordinal
      active
      activeDirectorySourceName
      hasConfiguredList
      sourceDetails {
        code
        value
        active
        ordinal
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierSourceTypeInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "barrierSourceTypeInput",
    "variableName": "barrierSourceTypeInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordinal",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeDirectorySourceName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasConfiguredList",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "barrierSourceType_addBarrierSourceTypeEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BarrierSourceMutation",
        "kind": "LinkedField",
        "name": "barrierSourceMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BarrierSourceType",
            "kind": "LinkedField",
            "name": "addBarrierSourceTypeEntry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierSourceDetail",
                "kind": "LinkedField",
                "name": "sourceDetails",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "barrierSourceType_addBarrierSourceTypeEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BarrierSourceMutation",
        "kind": "LinkedField",
        "name": "barrierSourceMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BarrierSourceType",
            "kind": "LinkedField",
            "name": "addBarrierSourceTypeEntry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierSourceDetail",
                "kind": "LinkedField",
                "name": "sourceDetails",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "338c569d4219b857712ebfa4e7870ef7",
    "id": null,
    "metadata": {},
    "name": "barrierSourceType_addBarrierSourceTypeEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation barrierSourceType_addBarrierSourceTypeEntry_Mutation(\n  $barrierSourceTypeInput: BarrierSourceTypeInput!\n) {\n  barrierSourceMutation {\n    addBarrierSourceTypeEntry(barrierSourceTypeInput: $barrierSourceTypeInput) {\n      id\n      code\n      value\n      ordinal\n      active\n      activeDirectorySourceName\n      hasConfiguredList\n      sourceDetails {\n        code\n        value\n        active\n        ordinal\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f82e445c091097eb68bfbc0d0913613';

module.exports = node;
