/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Paper } from 'material-ui'
import {
  updateNavigationWorkspaces,
  updateNavigationWorkspacesQuery,
} from '../ducks/views'

import { ConflictedPatient } from '../components/conflicted-patient'

import { graphql, fetchQuery } from 'react-relay'

import { checkInToBed } from '../graphql/relay/queries/visit'
import { handleMutation } from '../graphql/relay/queries/mutation'
import { environment } from '../graphql/relay/env'
import styled from 'styled-components'
import { LoadingIndicator } from '../components/loader'

export class ConflictedPatientListComp extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      updateNavigationWorkspaces: PropTypes.func.isRequired,
      updateNavigationWorkspacesQuery: PropTypes.func.isRequired
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  state = {
    data: undefined,
    availableLocations: undefined,
    conflictedWorkspaces: undefined,
  }

  componentDidMount() {
    this.handleFetchData()
  }


  handleFetchData = () => {
      fetchQuery(environment, query, {}).then(data => {
        const beds = data.viewer.beds.edges || []
        const workspaces = beds.map(node => {
          let workspace = {}
          workspace.bed = node.bed
          workspace.department = workspace.bed.department
          workspace.visit = workspace.bed.visit
          //workspace.plan = workspace.visit.plan ? workspace.visit.plan : null
          return workspace
        })

        //available
        const availableLocations = workspaces.filter(
          workspace =>
            workspace.bed.status === 'Available' &&
            workspace.department.type !== 'AdmissionSource'
        )

        this.setState({
          availableLocations: availableLocations,
          workspaces: workspaces,
        })
      })
  }

  handleViewPlanClick = (bedId, planId, visitId) => {
    this.props.history.push(`/conflicted-visit/${visitId}`, {
      visitId,
      planId,
      bedId,
    })
  }

  handleMoveToBed = (bed, visit) => {
    const variables = {
      visitId: visit.id,
      bedExchangeName: bed.exchangeName,
    }
    handleMutation(
      environment,
      checkInToBed,
      variables,
      this.handleFetchData
    )
  }

  render() {
    if (!this.state.workspaces) {
      return <LoadingIndicator/>
    }

    const {
      actions: { updateNavigationWorkspaces, updateNavigationWorkspacesQuery },
    } = this.props

    // we could either refetch entire workspaces or only render workspace with bedConflict
    const conflictedWorkspaces = this.state.workspaces.filter(ws => {
      return ws.bed.conflicts.length > 0
    })

    let cwExpanded = []
    if (conflictedWorkspaces.length > 0) {
        // normalize to match expected format in BedNavigator
         conflictedWorkspaces.forEach((cws) => {
          cws.bed.conflicts.forEach((conflict) => {
            let ws = {
              bed: cws.bed,
              unitName: cws.department.name,
              visit: conflict.visit,
              plan: conflict.visit.plan,
              created: conflict.created,
              createdBy: conflict.createdBy

            }
            cwExpanded.push(ws)
          })
        })

      updateNavigationWorkspaces(cwExpanded)
      updateNavigationWorkspacesQuery({ type: 'conflicted', query: null })
    }

    return conflictedWorkspaces.length === 0 ? (
      <Paper>
        <div style={{ marginTop: '10px', padding: '.5em' }}>
          {'No Conflicted Patients'}
        </div>
      </Paper>
    ) : (
      <div
        style={{
          marginTop: '10px',
          height: 'calc(100% - 15px)',
          overflowY: 'auto',
        }}>
        {cwExpanded.map((item) => (
          <div key={item.visit.id}>
            <ConflictedPatient
              created={item.created}
              createdBy={item.createdBy}
              bed={item.bed}
              visit={item.visit}
              plan={item.plan}
              unitName={item.unitName}
              availableBeds={this.state.availableLocations}
              onMoveToBed={this.handleMoveToBed}
              onViewPlanClick={this.handleViewPlanClick}
            />
          </div>
        ))}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { updateNavigationWorkspaces, updateNavigationWorkspacesQuery },
      dispatch
    ),
  }
}

export const ConflictedPatientList = compose(
  connect(
    null,
    mapDispatchToProps
  )
)(ConflictedPatientListComp)

const query = graphql`
  query ConflictedPatientListQuery {
    viewer {
      beds(first: 10000, type: PATIENT, query: "allbeds")
        @connection(key: "ConflictedPatientList_beds") {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
          totalCount
        }
        edges {
          bed: node {
            name
            exchangeName
            status
            conflicts {
              id
              created
              createdBy {
                userName
                firstName
                lastName
              }
              visit {
                id
                attendingPhysician {
                  id
                  firstName
                  lastName
                }
                visitNumber
                admissionType
                plan {
                  id
                  careTeamMembers {
                    id
                    name
                    role {
                      id
                      code
                      value
                    }
                    primary
                  }
                }
                patient {
                  gender
                  age
                  mrn
                  firstName
                  lastName
                }
              }
            }
            department {
              name
              type
            }
          }
        }
      }
    }
  }
`
