import { setContext } from 'apollo-link-context'

import { retrieveAuthToken } from '../auth'

// Tell Apollo to use our JWT token on every request in the Authorization header
export const sessionStorageJWTToken = setContext(() => {
  // always get the token from sessionStorage, do not cache it here
  return retrieveAuthToken().then(authToken => {
    return {
      headers: {
        authorization: `Bearer ${authToken}`,
      },
    }
  })
})

// Tell Apollo to not use empty string operation names
export function noEmptyStringOperationName(operation, forward) {
  if (operation.operationName === '') {
    operation.operationName = null
  }

  return forward(operation)
}
