/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createPaginationContainer, graphql } from 'react-relay'
import {getScrollToIndex, highlightSelected, saveIndexPosition} from '../../util/utils'
import { VariableSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { PlanListSection } from '../../components/plan'
import { PlanHeaderGQL } from '../../containers/plan/PlanHeaderGQL'
import { getPageSize } from '../../util/configs'
import { LoadingIndicator } from '../../components/loader'

class PlanListPaginationContainer extends Component {
  constructor(props) {
    super(props)

    this.workspaces = []
    this.hasNextPage = false
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    const index = getScrollToIndex(this.props.history)
    if(index >= 0) {
      this.listRef.current.scrollToItem(index, "center")
    }
  }


  /***
   * Load row items
   */
  handleLoadMore = () => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true })
      this.props.relay.loadMore(getPageSize(), () => {
        this.setState({ loading: false })
        resolve()
        this.props.incrementPaginationLoads()
      })
    })
  }

  /***
   * Item client event handler
   */
  handleViewPlanClick = (bedId, planId, visitId, index) => {
    const {query, type} = this.props
    // CA-2284 - 2.1.0 hack for poor handling of Unit Transfers in the graph
    // we have to find recent discharges by visit and all others by bed
    const route = (type === 'DISCHARGE' && query === 'Recent') ? `/visit/${visitId}` :
      `/bed/${encodeURIComponent('Bed:' + bedId.replace(/%/g, '%25'))}` // Had to manually encode % to get it to show correctly in URL
    saveIndexPosition(index, this.props.history)
    // CA-1325 - go to plan tab.
    this.props.actions.setDetailTabIndex(0)
    this.props.history.push(route, {
      visitId,
      planId,
      bedId,
    })
  }

  /***
   * Row item WITHOUT data
   */
  rowRenderer = ({ index, style }) => {
    const item = this.workspaces[index]

    const lastRow = this.hasNextPage && index === this.workspaces.length - 1
    const preDepName = index > 0 ? this.workspaces[index - 1].department.name : null

    const hasDeptHeader = (
      !lastRow &&
      item &&
      (!preDepName || preDepName !== item.department.name)
    )
    const {history} = this.props

    if (lastRow && this.state.loading) {
      return (
        <div style={{ textAlign: 'center', padding: '10px', ...style }}>
          <LoadingIndicator/>
        </div>
      )
    } else {
      // CA-2506 - highlight if it's the element we invoked Details from
      if(index === getScrollToIndex(history)) {
        const element = document.getElementById(index)
        element && highlightSelected(element, history)
      }

      return (
        <div style={style} >
          {hasDeptHeader && <PlanListSection department={item.department} />}
          <PlanHeaderGQL
            workspace={item}
            handleViewPlanClick={this.handleViewPlanClick}
            viewType="PlanList"
            rvlist={this.list}
            index={index}
            refresh={this.props.refreshPlanList}
          />
        </div>
      )
    }
  }

  /***
   * Row item WITH data
   */
  availableRowRenderer = ({ index, style }) => {
    const item = this.workspaces[index]
    const lastRow = this.hasNextPage && index === this.workspaces.length - 1

    if (lastRow && this.state.loading) {
      return (
        <div style={{ textAlign: 'center', padding: '10px', ...style }}>
          <LoadingIndicator/>
        </div>
      )
    } else {
      return (
        <div style={{ paddingBottom: '0px', ...style, height: '35px' }}>
          <div
            key={window.innerWidth}
            className="plan-header"
            id={this.props.index}
            style={{ display: 'flex', position: 'relative' }}>
            <div className="paper" style={{ padding: '5px', flex: 'auto' }}>
              {item ? item.bed.name + "/" + item.department.name : ''}
            </div>
          </div>
        </div>
      )
    }
  }

  noRowsRenderer = () => {
    return (
      <div className="paper" style={{padding: 5}}>
        No results. Change Filters or Bed Assignments
      </div>
    )
  }
  listRef = React.createRef()

  /***
   * Component Render
   */
  render() {
    const beds = this.props.search.viewer.beds ? this.props.search.viewer.beds.edges : []
    const {type, query} = this.props
    const isAvailableBeds = (type === 'PATIENT' && query === 'available')
    const useDV = type === 'DISCHARGE' && query === 'Recent'
    this.hasNextPage = this.props.search.viewer.beds ?
      this.props.search.viewer.beds.pageInfo.hasNextPage :  false

    this.workspaces = []
    beds.forEach(node => {
      let workspace = {}
      workspace.department = node.bed.department

      if(useDV) {
        if (node.bed.dischargedVisits && node.bed.dischargedVisits.length > 0) {
          for (let i = 0; i < node.bed.dischargedVisits.length; i++) {
            workspace = {}
            workspace.department = node.bed.department

            let dv = node.bed.dischargedVisits[i]

            workspace.visit = dv
            workspace.plan =  dv ? dv.plan : null
            // If bed is currently got a Reserved status we don't want to see that
            // for a Recent D/C that we are handling here
            workspace.bed = {...node.bed, status: ''}

            this.workspaces.push(workspace)
          }
        }
      } else {
        if (node.bed.visit) {
          workspace.visit = node.bed.visit
          workspace.plan = workspace.visit.plan ? workspace.visit.plan : null
        }
        workspace.bed = node.bed

        // Now need this since as of 1.3 dischargedVisits are shamelessly
        // in the main PlanList Query whether you want them or not
        if(isAvailableBeds || workspace.visit) {
          this.workspaces.push(workspace)
        }
      }
    })

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = this.hasNextPage ?  this.workspaces.length + 1 :  this.workspaces.length

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = async () => {
      if (this.hasNextPage && !this.state.loading) {
        await this.handleLoadMore()
      }
    }

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => !this.hasNextPage || index < this.workspaces.length

    const getItemSize = index => {
      const item = this.workspaces[index]
      const lastRow = this.hasNextPage && index == this.workspaces.length

      const preDepName = index > 0 ? this.workspaces[index - 1].department.name : null
      const currDepName = item.department.name

      if (lastRow) {
        // loading image only
        return 50
      } else if (item && preDepName !== currDepName) {
        // department header and item
        return 210
      }
      // item only
      return 177
    }

    // https://react-window.now.sh/#/examples/list/variable-size
    if(itemCount === 0) {
      return (this.noRowsRenderer())
    }

    return (
      <React.Fragment>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered }) => (
            <List
              style={{overflowX:'hidden'}}
              height={window.innerHeight - 73 - this.props.filterHeight}
              width={window.innerWidth - 57}
              itemCount={this.workspaces.length}
              itemSize={isAvailableBeds ? () => 35 : getItemSize}
              onItemsRendered={onItemsRendered}
              ref={this.listRef}
            >
              {isAvailableBeds ? this.availableRowRenderer : this.rowRenderer}
            </List>
          )}
        </InfiniteLoader>
      </React.Fragment>
    )

  }
}


PlanListPaginationContainer.propTypes = {
  incrementPaginationLoads: PropTypes.func,
  refreshPlanList: PropTypes.func,
  handleFilterHeightChange: PropTypes.func,
  filterHeight: PropTypes.number,
  relay: PropTypes.shape({ loadMore: PropTypes.func }),
  history: PropTypes.shape({ push: PropTypes.func }),
  search: PropTypes.shape({
    viewer: PropTypes.shape({
      beds: PropTypes.shape({
        edges: PropTypes.array,
        pageInfo: PropTypes.shape({
          hasNextPage: PropTypes.boolean,
        }),
      }),
    }),
  }),
  index: PropTypes.string,
  handleFilterChange: PropTypes.func,
  type: PropTypes.string,
  query: PropTypes.string,
  currentRoute: PropTypes.shape({
    viewType: PropTypes.oneOf(['Plan', 'Snap', 'PlanList']).isRequired,
  }),
  actions: PropTypes.shape({
    showDrawer: PropTypes.func,
    updatePlanFilters: PropTypes.func,
    updateNavigationWorkspaces: PropTypes.func,
    setDetailTabIndex: PropTypes.func.isRequired,
  }),
  planFilters: PropTypes.object,

  updateProgressionIndicator: PropTypes.func,
  updateToBeAdmitted: PropTypes.func,
  updatePatientProblem: PropTypes.func,
  updateTargetLos: PropTypes.func,
  updateWorkingDischargeDisposition: PropTypes.func,
  updateBedRequestStatus: PropTypes.func,
  updateAnticipatedDischargeTime: PropTypes.func,

  pinBarrier: PropTypes.func,
  unpinBarrier: PropTypes.func,
  pinPlanAction: PropTypes.func,
  unpinPlanAction: PropTypes.func,
  pinMilestoneAction: PropTypes.func,
  unpinMilestoneAction: PropTypes.func,
  pinPlanNote: PropTypes.func,
  unpinPlanNote: PropTypes.func,

  location: PropTypes.object,
}

export default createPaginationContainer(
  PlanListPaginationContainer,
  {
    search: graphql`
        fragment PlanListPaginationContainer_search on Query
        @argumentDefinitions(
            first: { type: "Int" }
            after: { type: "String", defaultValue: null }
            type: { type: "SearchType", defaultValue: null }
            query: { type: "String", defaultValue: null }
        ) {
        viewer {
          beds(first: $first, after: $after, type: $type, query: $query)
            @connection(key: "PlanListPaginationContainer_beds") {
            totalCount
            pageInfo {
              endCursor
              hasNextPage
              hasPreviousPage
              startCursor
              totalCount
            }
            edges {
              bed: node {
                id
                name
                exchangeName
                status
                # providers {
                #   id
                #   firstName
                #   lastName
                #   primary
                #   role {
                #     id
                #     code
                #     value
                #   }
                # }
                # conflictingVisits {
                #   id
                # }
                # dischargedVisit {
                #   id
                # }
                department {
                  id
                  exchangeName
                  name
                  type
                  # includedInLosCalculation
                }
                visit {
                  id
                  vector {
                    id
                    patientId
                    encounterId
                     predictionDate
                     conditions {
                       name
                       riskBracket
                       riskFactors {
                         id
                         type
                         description
                       }
                       recommendations {
                         id
                         rank
                         description
                         code
                         accepted
                       }
                     }
                  }
                  admissionTime
                  admissionType
                  admittingComplaint
                  anticipatedDischargeTime
                  anticipatedDischargeUpdatedBy {
                    id
                    userName
                    firstName
                    lastName
                  }
                  attendingPhysician {
                    firstName
                    id
                    lastName
                    # primary
                    # role {
                    #   code
                    #   id
                    #   value
                    # }
                  }
                  bedRequestStatus
                  bedRequests {
                    id
                    bedRequestType
                    bedRequestStatus
                    updatedWhen
                    bedExchangeName
                  }
                  currentDayOfStay
                  decisionTime
                  decisionTimeEditable
                  decisionTimeUseMidnight
                  dischargeVendor
                  drgAttributes {
                    id
                    # arithmeticLos
                    code
                    # commonPrincipalDX
                    # commonProcedures
                    # expectedLos
                    # geometricLos
                    level
                    modifyTargetLos
                    # majorDiagnosisCategory
                    # readmissionRisk
                    # targetLos
                     title
                    # type
                    # weight
                  }
                   drgUpdatedBy {
                     id
                     firstName
                     lastName
                     userName
                   }
                  homeBedExchangeName
                  insurancePlans {
                    id
                    planName
                    priority
                  }
                  meta {
                    id
                    decisionDue
                    dischargeToday
                    plannedDischargeExceeded
                    timeElapsedSinceAdmission
                    tlosExceeded
                  }
                  patient {
                    id
                    age
                    firstName
                    gender
                    lastName
                    middleInitial
                    mrn
                    language {
                        code
                        defaultValue
                    }
                  }
                  patientAlerts {
                    id
                    alertType
                    value
                  }
                  patientProblem
                  readmission
                  readmissionRisk
                  targetDischargeTime
                  tlos
                  visitNumber
                  visitFacilityId
                  visitStatus
                  workingDischargeDisposition {
                    code
                    id
                    value
                    workingDischargeDispositionGroup
                  }
                  plan {
                    id
                    actions {
                      __typename
                      id
                      text
                      pinned
                      status
                      auditMeta {
                      #   createdBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   updatedBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   created
                        updated
                      }
                    }
                    appliedTemplates {
                      id
                      name
                      description
                      documentURL
                      removed
                    }
                    barriers {
                      __typename
                      id
                      name
                      description
                      pinned
                      status
                      # category {
                      #   id
                      #   code
                      #   value
                      # }
                      escalation {
                        id
                      }
                      auditMeta {
                      #   createdBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   updatedBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   created
                        updated
                      }
                      ownerMessageStatus
                      ownerKnownUser {
                        id
                        emailReceiver
                        instantMessageReceiver
                      }
                      # owner
                      # ownerRole {
                      #   id
                      #   code
                      #   value
                      # }
                      # notes {
                      #   id
                      #   text
                      #   pinned
                      # auditMeta {
                      #   createdBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   updatedBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   created
                      #   updated
                      # }
                      # }
                    }
                    careTeamMembers {
                      id
                      name
                      role {
                        id
                        code
                        value
                      }
                      primary
                    }
                    meta {
                      milestoneMeta {
                        overdue
                        onTime
                        completed
                      }
                      #   lengthOfStayMeta {
                      #     forecast
                      #     cappedForecast
                      #   }
                      #   actualOE
                    }
                    milestones {
                      __typename
                      id
                      actions {
                        __typename
                        id
                        pinned
                        text
                        auditMeta {
                          updated
                        }
                      }
                    }
                    notes {
                      __typename
                      id
                      text
                      pinned
                      auditMeta {
                      #   createdBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   updatedBy {
                      #     id
                      #     userName
                      #     firstName
                      #     lastName
                      #   }
                      #   created
                        updated
                      }
                    }
                    progressionIndicator
                    toBeAdmitted
                  }
                  anticipatedTransferTime
                  anticipatedDischargeTimeAutoPopulated
                  anticipatedTransferTimeAutoPopulated
                }

                            dischargedVisits {
                                id
                                #vector {
                                #  id
                                #  patientId
                                #  encounterId
                                #   predictionDate
                                #   conditions {
                                #     name
                                #     riskBracket
                                #     riskFactors {
                                #       id
                                #       type
                                #       description
                                #     }
                                #     recommendations {
                                #       id
                                #       rank
                                #       description
                                #       code
                                #       accepted
                                #     }
                                #   }
                                #}
                                admissionTime
                                admissionType
                                admittingComplaint
                                anticipatedDischargeTime
                                anticipatedDischargeUpdatedBy {
                                  id
                                  userName
                                  firstName
                                  lastName
                                }
                                attendingPhysician {
                                    firstName
                                    id
                                    lastName
                                    # primary
                                    # role {
                                    #   code
                                    #   id
                                    #   value
                                    # }
                                }
                                bedRequestStatus
                                bedRequests {
                                  id
                                  bedRequestType
                                  bedRequestStatus
                                  updatedWhen
                                  bedExchangeName
                                }

                                currentDayOfStay
                                decisionTime
                                decisionTimeEditable
                                decisionTimeUseMidnight
                                dischargeVendor
                                drgAttributes {
                                    id
                                    # arithmeticLos
                                    code
                                    # commonPrincipalDX
                                    # commonProcedures
                                    # expectedLos
                                    # geometricLos
                                    level
                                    # modifyTargetLos
                                    # majorDiagnosisCategory
                                    # readmissionRisk
                                    # targetLos
                                    title
                                    # type
                                    # weight
                                }
                                drgUpdatedBy {
                                    id
                                    firstName
                                    lastName
                                    userName
                                }
                                homeBedExchangeName
                                insurancePlans {
                                    id
                                    planName
                                    priority
                                }
                                meta {
                                    id
                                    decisionDue
                                    dischargeToday
                                    plannedDischargeExceeded
                                    timeElapsedSinceAdmission
                                    tlosExceeded
                                }
                                patient {
                                    id
                                    age
                                    firstName
                                    gender
                                    lastName
                                    middleInitial
                                    mrn
                                    language {
                                        code
                                        defaultValue
                                    }
                                }
                                patientAlerts {
                                    id
                                    alertType
                                    value
                                }
                                patientProblem
                                readmission
                                readmissionRisk
                                targetDischargeTime
                                tlos
                                visitNumber
                                visitFacilityId
                                visitStatus
                                workingDischargeDisposition {
                                    code
                                    id
                                    value
                                }
                                plan {
                                    id
                                    actions {
                                        __typename
                                        id
                                        text
                                        pinned
                                        status
                                        auditMeta {
                                        #   createdBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   updatedBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   created
                                          updated
                                        }
                                    }
                                    appliedTemplates {
                                        id
                                        name
                                        description
                                        documentURL
                                        removed
                                    }
                                    barriers {
                                        __typename
                                        id
                                        name
                                        description
                                        pinned
                                        status
                                        # category {
                                        #   id
                                        #   code
                                        #   value
                                        # }
                                        escalation {
                                            id
                                        }
                                        auditMeta {
                                        #   createdBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   updatedBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   created
                                          updated
                                        }
                                        # owner
                                        # ownerRole {
                                        #   id
                                        #   code
                                        #   value
                                        # }
                                        # notes {
                                        #   id
                                        #   text
                                        #   pinned
                                        # auditMeta {
                                        #   createdBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   updatedBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   created
                                        #   updated
                                        # }
                                        # }
                                    }
                                    careTeamMembers {
                                        id
                                        name
                                        role {
                                            id
                                            code
                                            value
                                        }
                                        primary
                                    }
                                    meta {
                                        milestoneMeta {
                                            overdue
                                            onTime
                                            completed
                                        }
                                        #   lengthOfStayMeta {
                                        #     forecast
                                        #     cappedForecast
                                        #   }
                                        #   actualOE
                                    }
                                    milestones {
                                        __typename
                                        id
                                        actions {
                                            id
                                            pinned
                                            text
                                            auditMeta {
                                              updated
                                            }
                                        }
                                    }
                                    notes {
                                        __typename
                                        id
                                        text
                                        pinned
                                        auditMeta {
                                        #   createdBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   updatedBy {
                                        #     id
                                        #     userName
                                        #     firstName
                                        #     lastName
                                        #   }
                                        #   created
                                          updated
                                        }
                                    }
                                    progressionIndicator
                                    toBeAdmitted
                                }
                            }


                        }
                    }
                }
            }
        }
    `,
  },
  {
    direction: 'forward',
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      }
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        // need a bigger pageSize for the available beds to ensure it will scroll
        first: props.type === 'PATIENT' && props.query === 'available' ? 50 : getPageSize(),
        after: props.search.viewer.beds.pageInfo.endCursor,
        type: props.type,
        query: props.query,
      }
    },
    query: graphql`
        query PlanListPaginationContainerQuery(
            $first: Int
            $after: String
            $query: String
            $type: SearchType
        ) {
            ...PlanListPaginationContainer_search
            @arguments(first: $first, after: $after, query: $query, type: $type)
        }
    `,
  }
)
