import React from 'react'
import PropTypes from 'prop-types'

import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconButton from 'material-ui/IconButton'
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'

import { getPinnableText } from '../../util/utils'

export const QuickAddMenu = ({ elements, showMenuType, visit }) => {
  const items = elements.reduce((acc, element) => acc.concat(element.items), [])

  const handleClick = e => {
    e.stopPropagation()
  }

  const itemText = item => {
    return typeof item === 'string' ? item : item.text
  }

  const itemKey = (item, index) => {
    return itemText(item) + index
  }

  const renderItem = (item, index, handleQuickAddItem) => {
    return (
      <MenuItem
        key={itemKey(item, index)}
        value={item}
        primaryText={getPinnableText(30, itemText(item))}
        onClick={event => {
          if(visit && visit.bedRequestStatus){
            item.bedRequestStatus = visit.bedRequestStatus
          }
          handleQuickAddItem(item)
        }}
      />
    )
  }

  return (
    <span onClick={e => handleClick(e)}>
      <IconMenu
        iconButtonElement={
          <IconButton
            disabled={items.length === 0}
            style={{ padding: 8, width: 40, height: 40 }}>
            <MoreVertIcon />
          </IconButton>
        }
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}>
        {showMenuType
          ? elements.map((element, index) => (
              <MenuItem
                key={itemKey(element.primaryText, index)}
                rightIcon={<ArrowDropRight />}
                leftIcon={element.icon}
                primaryText={element.primaryText}
                menuItems={element.items.map((item, index) =>
                  renderItem(item, index, element.handleQuickAddItem)
                )}
              />
            ))
          : items.map((item, index) =>
              item ? renderItem(item, index, elements[0].handleQuickAddItem) : ''
            )}
      </IconMenu>
    </span>
  )
}

QuickAddMenu.propTypes = {
  elements: PropTypes.array.isRequired,
  showMenuType: PropTypes.bool.isRequired,
  visit: PropTypes.object,
}
