/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import sortBy from 'lodash.sortby'
import {fetchQuery} from 'react-relay'

import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import { ListItem } from 'material-ui/List'
import FlatButton from 'material-ui/FlatButton'
import Snackbar from 'material-ui/Snackbar'
import { planHandler } from '../../graphql/component/result-handlers'

import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import { ConfirmDialog } from '../../components/common'
import SelectableList from '../../components/SelectableList'
import BarrierSource from '../../components/barrier/BarrierSource/BarrierSource'

import {
  TemplateGQL,
  BarrierGQL,
  CodeTableGQL,
  UserGQL,
} from '../../graphql/queries'
import { withMutations } from '../../graphql/decorators'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { StatefulTextField } from '../../components/common/StatefulTextField'

import {
  createBarrier,
  createBarrierFromTemplate,
  barrierSourceTypes,
} from '../../graphql/relay/queries/barrier'
import { searchKnownUsers } from '../../graphql/relay/queries/knownUser'
import { createFavoriteBarrierFromTemplate } from '../../graphql/relay/queries/user'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'

const styleSheet = createStyleSheet('ChooseBarrier', theme => ({
  dlHorizontal: {
    '& dt': {
      float: 'left',
      width: '100px',
      overflow: 'hidden',
      clear: 'left',
      textAlign: 'right',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: '#707478',
      fontWeight: 700,
    },
    '& dd': {
      marginLeft: '120px',
      '&.after': {
        content: 'a',
        whiteSpace: 'pre',
      },
    },
  },
  dl: {
    '&.before': {
      boxSizing: 'border-box',
    },
    '&.after': {
      boxSizing: 'border-box',
    },
  },
  height100: {
    height: '100%',
  },
  categoryColumn: {
    paddingRight: '0 !important',
  },
  categoryList: {
    overflowY: 'scroll',
    height: 'calc(430px - 40px)',
  },
  padLeft: {},
  editColumn: {
    paddingRight: '30px !important',
  },
  absolutePos: {},
}))

@graphql(CodeTableGQL.queries.barrierCategories, {
  name: 'barrierCategories',
})
@graphql(TemplateGQL.queries.allBarrierTemplates, {
  name: 'barrierTemplatesAndCategories',
})
@withMutations([
  [
    BarrierGQL.mutations.createBarrier,
    {
      handler: planHandler,
    },
  ],
  [
    BarrierGQL.mutations.createBarrierFromTemplate,
    {
      handler: planHandler,
    },
  ],
  [
    UserGQL.mutations.createFavoriteBarrier,
    {
      handler: planHandler,
    },
  ],
  [
    UserGQL.mutations.createFavoriteBarrierFromTemplate,
    {
      handler: planHandler,
    },
  ],
])
class ChooseBarrier extends Component {
  static propTypes = {
    rerender: PropTypes.func,
    planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    barrierTemplatesAndCategories: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      barrierTemplates: PropTypes.array,
    }),
    barrierCategories: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      codeTables: PropTypes.array,
    }),
    refetch: PropTypes.func,
    createBarrier: PropTypes.func,
    createBarrierFromTemplate: PropTypes.func,
    createFavoriteBarrier: PropTypes.func,
    createFavoriteBarrierFromTemplate: PropTypes.func,
    visit: PropTypes.object.isRequired,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    confirmOpen: false,
    confirmCloseOpen: false,
    prevCategoryCode: '',
    selectedCategoryCode: '',
    selectedTemplateId: '',
    selectedTemplateName: '',
    statusMessage: '',
    snackBarOpen: false,
    isSubmitting: false,
    isCategorySelected: false,
    isAdhocBarrierTemplate: false,
    adhocBarrierName: '',
    optionalBarrierNote: '',
    sourceTypeCode: null,
    sourceName: null,
    barrierSourceTypes: null,
  }

  shouldClose() {
    return true
  }

  getBarrierNote() {
    let note = this.state.optionalBarrierNote
    note = note.trim()
    return note ? note : undefined
  }

  handleApplyTouchTap = () => {
    if (this.state.isAdhocBarrierTemplate) {
      if (this.props.planId === '-1') {
        //FIXME swtich from apollo
        this.handleAddAdhocFavoriteBarrier()
      } else {
        this.handleAddAdhocBarrier()
      }
    } else {
      if (this.props.planId === '-1') {
        this.handleApplyFavoriteBarrierTemplateTouchTap()
      } else {
        this.handleApplyBarrierTemplateTouchTap()
      }
    }
  }

  handleApplyBarrierTemplateTouchTap = () => {
    this.setState({
      isSubmitting: true,
    })
    //call the method that will update the plan with this barrier
    const note = this.getBarrierNote()
    const variables = {
      planId: this.props.planId,
      templateId: this.state.selectedTemplateId,
      note: note,
      barrierSourceTypeCode: this.state.sourceTypeCode,
      barrierSourceName: this.state.sourceName,
      bedRequestStatus: this.props.visit.bedRequestStatus,
    }

    handleMutation(
      environment,
      createBarrierFromTemplate,
      variables,
      () => {
        this.success()
      },
      null,
      () => this.setState({
        isSubmitting: false,
      })
    )
  }

  handleApplyFavoriteBarrierTemplateTouchTap = () => {
    this.setState({
      isSubmitting: true,
    })
    //call the method that will update the plan with this barrier
    const variables = {
      templateId: this.state.selectedTemplateId,
    }

    handleMutation(
      environment,
      createFavoriteBarrierFromTemplate,
      variables,
      () => {
        this.success()
      },
      () => {
        this.props.rerender()
      },
      () => this.setState({
        isSubmitting: false,
      })
    )
  }

  handleCategorySelect = async categoryName => {
    this.setState({
      selectedCategoryCode: categoryName,
      isCategorySelected: true,
      adhocBarrierName: '',
      optionalBarrierNote: '',
    })
  }

  handleTemplateSelect = async (id, name, clickedCategoryCode) => {
    await this.loadBarrierSourceTypes(clickedCategoryCode)

    this.setState({
      selectedTemplateId: id,
      selectedTemplateName: name,
      isAdhocBarrierTemplate: false,
      adhocBarrierName: '',
      optionalBarrierNote: ''
    })
  }

  handleSearchKnownPersonnel = async knownUserInput => {
    const response = await fetchQuery(environment, searchKnownUsers, { knownUserInput })
    return response.knownUsers
  }

  loadBarrierSourceTypes = async (clickedCategoryCode) => {
    if (this.state.prevCategoryCode !== clickedCategoryCode) {
      const response = await fetchQuery(environment, barrierSourceTypes, { barrierCategoryCode: clickedCategoryCode })

      this.setState({
        barrierSourceTypes: response.barrierSourceTypes.filter(type => type.active),
        prevCategoryCode: clickedCategoryCode
      })
    }
  }

  handleSnackBarClose = () => {
    this.setState({
      snackBarOpen: false,
    })
    // todo: decide how and what to do to close the drawer, everytime? use a callback to decide? etc.?
    this.props.onRequestClose && this.props.onRequestClose()
  }

  handleAddAdhocBarrier = () => {
    this.setState({
      isSubmitting: true,
    })
    //call the method that will update the plan with this barrier
    const note = this.getBarrierNote()

    const variables = {
      planId: this.props.planId,
      barrier: {
        name: this.state.adhocBarrierName,
        description: '',
        categoryCode: this.state.selectedCategoryCode,
      },
      note: note,
      barrierSourceTypeCode: this.state.sourceTypeCode,
      barrierSourceName: this.state.sourceName
    }
    handleMutation(
      environment,
      createBarrier,
      variables,
      res => {
        this.success()
      },
      null,
      () => {
        this.setState({
          isSubmitting: false,
        })
      }
    )
  }

  handleAddAdhocFavoriteBarrier = () => {
    this.setState({
      isSubmitting: true,
    })
    //call the method that will update the plan with this barrier
    this.props
      .createFavoriteBarrier({
        variables: {
          name: this.state.adhocBarrierName,
          description: '',
          categoryCode: this.state.selectedCategoryCode,
        },
      })
      .then(
        res => {
          this.success()
        },
        err => {
          this.setState({
            isSubmitting: false,
          })
          //handled globally
        }
      )
  }

  handleCreateBarrierTouchTap = async (clickedCategoryCode) => {
    await this.loadBarrierSourceTypes(clickedCategoryCode)

    this.setState({
      isAdhocBarrierTemplate: true,
    })
  }

  handleAdhocBarrierNameUpdate = e => {

    this.setState({
      adhocBarrierName: e.target.value,
    })
  }

  handleOptionalBarrierNoteUpdate = e => {

    this.setState({
      optionalBarrierNote: e.target.value,
    })
  }

  handleSourceTypeChange = (value) => {
    if (this.state.barrierSourceTypes && this.state.sourceTypeCode) {
      const prevSourceType = this.state.barrierSourceTypes.find(type => type.code === this.state.sourceTypeCode)
      const newSourceType = this.state.barrierSourceTypes.find(type => type.code === value)

      if (this.state.sourceName !== null &&
        (this.isSourceTypeSelectionTypeChanged(value, prevSourceType, newSourceType) ||
        this.isSourceDetailMissingFromList(newSourceType))) {
        this.setState({
          sourceName: null,
        })
      }
    }

    this.setState({
      sourceTypeCode: value,
    })
  }

  isSourceTypeSelectionTypeChanged = (newValue, prevSourceType, newSourceType) => {
    return (!newValue ||
      prevSourceType.activeDirectorySourceName !== newSourceType.activeDirectorySourceName ||
      prevSourceType.hasConfiguredList !== newSourceType.hasConfiguredList)
  }

  isSourceDetailMissingFromList = (newSourceType) => {
    let isMissing = false
    if (newSourceType?.hasConfiguredList) {
      isMissing = !newSourceType.sourceDetails.map(detail => detail.code).includes(this.state.sourceName)
    }

    return isMissing
  }

  handleSourceNameChange = (value) => {
    this.setState({
      sourceName: value,
    })
  }

  returnNestedChildren = (category, templates, planId) => {
    let children = []

    sortBy(
      templates.filter(x => x.active && x.category.code === category.code),
      ['name']
    ).map(template => {
      if (template.active) {
        children.push(
          <ListItem
            key={template.id}
            primaryText={template.name}
            value={template.id}
            id={template.id}
            onClick={() => {
              this.handleCategorySelect(category.code)
              this.handleTemplateSelect(template.id, template.name, category.code)
            }}
          />
        )
      }
    })

    // CA-1717 - custom barriers - didn't want them anymore
    // CA-2799 - and now they are back, but at the end of the list,
    // but don't add them when this is used by QuickAdd ie. planId === -1
    if(planId !== "-1") {
      children.push(
        <ListItem
          key={`${category.code}_adhoc`}
          primaryText="Custom"
          id={`${category.code}_create_id`}
          value={`${category.code}_value`}
          onClick={() => {
            this.handleCategorySelect(category.code)
            this.handleCreateBarrierTouchTap(category.code)
          }}
        />
      )
    }

    return children
  }

  success() {
    this.setState({
      isSubmitting: false,
      adhocBarrierName: '',
      optionalBarrierNote: '',
    })
    this.props.onRequestClose && this.props.onRequestClose()
    this.props.refetch && this.props.refetch()
  }

  render() {
    const { title, onRequestClose } = this.props

    const {
      confirmCloseOpen,
      isAdhocBarrierTemplate,
      selectedTemplateName,
      adhocBarrierName,
    } = this.state

    const classes = this.context.styleManager.render(styleSheet)

    const barrierTemplates =
      (this.props.barrierTemplatesAndCategories &&
        this.props.barrierTemplatesAndCategories.barrierTemplates) ||
      []

    const applyDisabled =
      (!isAdhocBarrierTemplate && !selectedTemplateName) ||
      (isAdhocBarrierTemplate && !adhocBarrierName)

    const applyButtonStyle = !applyDisabled && {
      labelStyle: {
        color: 'white',
      },
    }

    let barrierCategories =
      (this.props.barrierCategories &&
        this.props.barrierCategories.codeTables &&
        sortBy(this.props.barrierCategories.codeTables.filter(x => x.active), [
          'ordinal',
          'value',
        ])) ||
      []

    const actions = [
      <FlatButton
        key="apply"
        label="Apply"
        disabled={applyDisabled}
        {...applyButtonStyle}
        onClick={this.handleApplyTouchTap}
      />,
    ]

    let editColumn = ''
    const barrierNoteTextEdit =
      this.props.planId === '-1' ? null : (
        <StatefulTextField
          text={this.state.optionalBarrierNote}
          className={classNames(classes.padLeft)}
          textFieldProps={{
            fullWidth: true,
            hintText: 'Barrier Note (Optional)',
            disabled: this.state.isSubmitting,
            style: { fontSize: '14px' }
          }}
          onChange={this.handleOptionalBarrierNoteUpdate}
          maxNumberOfChars={255}
          clearOnTextEntered={false}
          showCharacterCount
          allowEmptyText
        />
      )

    const barrierSourceDetails = this.state.barrierSourceTypes?.find(type => type.code === this.state.sourceTypeCode)
      ?.sourceDetails?.filter(type => type.active || type.code === this.state.sourceName)

    const barrierSource = (<BarrierSource
      sourceType={this.state.sourceTypeCode}
      sourceName={this.state.sourceName}
      onSourceTypeChange={this.handleSourceTypeChange}
      onSourceNameChange={this.handleSourceNameChange}
      barrierSourceTypes={this.state.barrierSourceTypes}
      barrierSourceDetails={barrierSourceDetails}
      onSearchKnownPersonnel={this.handleSearchKnownPersonnel} />)

    if (this.state.isCategorySelected && !this.state.isAdhocBarrierTemplate) {
      editColumn = (
        <div className={classNames(classes.editColumn, 'col-xs-8', 'col-lg-9')}>
          <h3 className={classNames(classes.padLeft)}>
            {this.state.selectedTemplateName}
          </h3>
          <div className={classNames(classes.absolutePos)}>
            {barrierTemplates
              .filter(x => x.id === this.state.selectedTemplateId)
              .map(template => (
                <dl
                  key={template.id}
                  className={classNames(classes.dlHorizontal)}>
                  <dt> Category </dt> <dd> {template.category.value} </dd>
                  <dt> Description </dt> <dd> {template.description} </dd>
                </dl>
              ))}
            {this.state.selectedTemplateId && barrierNoteTextEdit}
          </div>
          {this.state.selectedTemplateId && barrierSource}
        </div>
      )
    } else if (
      this.state.isCategorySelected &&
      this.state.isAdhocBarrierTemplate
    ) {
      editColumn = (
        <div className={classNames(classes.editColumn, 'col-xs-8', 'col-lg-9')}>
          <div>
            <div>
              <h3 className={classNames(classes.padLeft)}>
                Create a New Barrier
              </h3>
              <StatefulTextField
                text={this.state.adhocBarrierName}
                className={classNames(classes.padLeft)}
                textFieldProps={{
                  fullWidth: true,
                  hintText: 'Barrier Name (Required)',
                  disabled: this.state.isSubmitting,
                }}
                onChange={this.handleAdhocBarrierNameUpdate}
                maxNumberOfChars={30}
                clearOnTextEntered={false}
                showCharacterCount
              />
              {barrierNoteTextEdit}
            </div>
            {barrierSource}
          </div>
        </div>
      )
    } else {
      editColumn = ''
    }
    return (
      <div className={classNames(classes.height100)}>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />
        <div className={classNames(classes.height100, 'row')}>
          <div
            className={classNames(
              classes.categoryColumn,
              'col-xs-4',
              'col-lg-3'
            )}>
            <div className={classNames(classes.categoryList)}>
              <SelectableList defaultValue={0}>
                {barrierCategories.map(
                  c =>
                    c.active && (
                      <ListItem
                        key={c.code}
                        value={c.code}
                        primaryText={c.value}
                        primaryTogglesNestedList
                        nestedItems={this.returnNestedChildren(
                          c,
                          barrierTemplates,
                          this.props.planId
                        )}
                        onClick={() => {
                          this.handleCategorySelect(c.code)
                        }}
                      />
                    )
                )}
              </SelectableList>
            </div>
          </div>
          {editColumn}
        </div>
        <ConfirmDialog
          open={confirmCloseOpen}
          message="You have unsaved data. Do you want to proceed without applying?"
          onCancelClick={this.handleCancelConfirmClose}
          onConfirmClick={this.handleAcceptConfirmClose}
        />
        <Snackbar
          open={this.state.snackBarOpen}
          message={this.state.statusMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleSnackBarClose}
        />
      </div>
    )
  }
}

export default ChooseBarrier
