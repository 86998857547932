import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'material-ui/FlatButton'
import ActionIcon from 'mdi-react/CalendarCheckIcon'
import { blue600 as ActionColor } from 'material-ui/styles/colors'

import { Date } from '../date'
import { AuditUser } from '../audit'

import { ActionStatus, Pin } from '../plan'
import { StatefulTextField } from '../common'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('Action', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    actionIcon: {
      fill: ActionColor,
      marginLeft: '13px',
    },
    actionStatusIcon: {
      position: 'relative',
      top: '3px',
    },
    item: {
      padding: '0 .5em 0 .1em',
      margin: '-1px',
      border: '1px solid #d3d3d3',
    },
    primaryLine: {
      width: 'calc(100% - 100px)',
      position: 'relative',
      top: '-2px',
      marginLeft: '20px',
    },
    primaryLineExpanded: {
      display: 'inline-block',
      width: 'calc(100% - 100px)',
      position: 'relative',
      marginLeft: '20px',
    },
    secondaryLine: {
      minHeight: '1.5em',
    },
    secondaryLinePullRight: {
      marginTop: '10px',
      marginRight: '5px',
      float: 'right',
    },
    createdBy: {
      marginRight: '1em',
    },
    button: {
      border: '1px solid lightgrey !important',
    },
    expanded: {
      padding: '0 .5em .5em .5em',
      marginTop: '0.5em',
      marginBottom: '0.5em',
    },
    topHalf: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
  }
})

export const Action = (
  {
    item,
    expanded,
    onClick,
    onUpdateActionText,
    onUpdateActionStatus,
    onRemoveAction,
    onUpdatePinned,
    showActionIcon,
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)

  return (
    <div
      className={classNames(classes.item, expanded ? classes.expanded : null)}
      onClick={onClick ? e => onClick(e, item) : () => {}}>
      <div className={classes.topHalf}>
        {showActionIcon && (
          <ActionIcon className={classNames(classes.actionIcon)} />
        )}
        <Pin item={item} onClick={onUpdatePinned} />
        <ActionStatus
          value={item.status}
          onChange={newValue => onUpdateActionStatus(item.id, newValue)}
          className={classes}
        />
        {!expanded && (
          <span className={classNames(classes.primaryLine)}>{item.text}</span>
        )}
        {expanded && (
          <span className={classNames(classes.primaryLineExpanded)}>
            <StatefulTextField
              text={item.text}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Action will be deleted',
              }}
              onEnter={value => {
                if (value && value.length > 0) {
                  onUpdateActionText(item.id, value)
                } else {
                  onRemoveAction(item.id)
                }
              }}
              maxNumberOfChars={255}
              allowEmptyText
              clearOnTextEntered={false}
              showCharacterCount
            />
          </span>
        )}
      </div>
      {expanded && (
        <div className={classNames(classes.secondaryLine)}>
          <FlatButton
            className={classNames(classes.button)}
            label={'Delete'}
            onClick={() => onRemoveAction(item.id)}
          />
          <div className={classNames(classes.secondaryLinePullRight)}>
            Updated: <Date dateString={item.auditMeta.updated} /> By:{' '}
            <AuditUser user={item.auditMeta.updatedBy} />
          </div>
          <div
            className={classNames(
              classes.secondaryLinePullRight,
              classes.createdBy
            )}>
            Created: <Date dateString={item.auditMeta.created} /> By:{' '}
            <AuditUser user={item.auditMeta.createdBy} />
          </div>
        </div>
      )}
    </div>
  )
}

Action.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

Action.propTypes = {
  item: PropTypes.object,
  expanded: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onUpdateActionText: PropTypes.func.isRequired,
  onUpdateActionStatus: PropTypes.func.isRequired,
  onRemoveAction: PropTypes.func.isRequired,
  onUpdatePinned: PropTypes.func.isRequired,
  showActionIcon: PropTypes.bool,
}
