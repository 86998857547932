import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Drawer from '../components/drawer/Drawer'

import { drawerConfigs } from './drawer-component-mapping'

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger(
  'c3.containers.StandardDrawerContainer'
)

function mapStateToProps(state) {
  return {
    openDrawer: state.viewsAppState.openDrawer,
    drawerOptions: state.viewsAppState.drawerOptions,
  }
}

@connect(mapStateToProps /* No Dispatch. Don't fire actions in here. */)
export class StandardDrawerContainer extends Component {
  static propTypes = {
    openDrawer: PropTypes.string,
    drawerOptions: PropTypes.shape({
      title: PropTypes.string,
    }),
  }

  static defaultProps = {
    drawerOptions: {},
  }

  render() {
    const drawer = drawerConfigs[this.props.openDrawer] || {}
    return (
      <Drawer
        open={!!drawer.component}
        {...drawer}
        {...this.props.drawerOptions}
      />
    )
  }
}
