/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'
import {
  grey800 as HelperTextColor,
  red500 as MaxCharacterColor,
  orange500 as DirtyColor,
} from 'material-ui/styles/colors'

import classNames from 'classnames'
import './common.scss'

export class StatefulTextField extends Component {
  static propTypes = {
    trackDirty: PropTypes.bool,
    blurOnEnter: PropTypes.bool,
    text: PropTypes.string,
    textFieldProps: PropTypes.object,
    inputStyleOverride: PropTypes.object,
    onEnter: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    maxNumberOfChars: PropTypes.number,
    allowEmptyText: PropTypes.bool,
    clearOnTextEntered: PropTypes.bool,
    showCharacterCount: PropTypes.bool,
    fixHeight: PropTypes.func,
    propsOverState: PropTypes.bool,
  }
  static contextTypes = {
    muiTheme: PropTypes.object.isRequired,
  }

  static defaultProps = {
    onEnter: () => {},
    onChange: () => {},
    className: '',
    maxNumberOfChars: 10,
    blurOnEnter: false,
    trackDirty: true,
    allowEmptyText: false,
    clearOnTextEntered: true,
    showCharacterCount: false,
    propsOverState: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      charsLeft: props.text
        ? props.maxNumberOfChars - props.text.length
        : props.maxNumberOfChars,
      dirty: false,
      text: props.text ? props.text : '',
      hasFocus: false,
      id: `${Math.random()}`,
    }
  }

  componentWillReceiveProps(nextProps) {
    // CA-1789 - only use props if we don't have text in state
    // CA-2880 - handle DRG change into this
    if ((nextProps.text || nextProps.text === '') &&
      (!this.state.text || this.props.propsOverState) ) {
      this.setState({
        text: nextProps.text,
      })
    }
  }

  handleChange = (event, text) => {
    const newState = {
      charsLeft: this.props.maxNumberOfChars - text.length,
      dirty: true,
    }

    if (text.length <= this.props.maxNumberOfChars) {
      newState.text = text
      this.props.onChange(event, text)
    }

    this.setState(newState)
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleSave()
    }
  }

  handleSave = () => {
    this.setState({ dirty: false })
    if (this.props.blurOnEnter) {
      setTimeout(() => {
        this.theField.input.blur()
      }, 500)
    }

    const text = this.state.text.trim()
    if (this.props.allowEmptyText || text) {
      this.props.onEnter(text)
      if (this.props.clearOnTextEntered) {
        this.setState({ text: '' })
      }
    }
  }

  handleFocus = () => {
    if (this.props.fixHeight) {
      this.setState({ hasFocus: true }, this.props.fixHeight)
    } else {
      this.setState({ hasFocus: true })
    }
  }

  handleBlur = () => {
    // don't call mutations if user is just tabbing about
    if (this.props.fixHeight) {
      this.setState({ hasFocus: false }, this.props.fixHeight)
    } else {
      this.setState({ hasFocus: false })
    }

    if (this.props.text !== this.state.text) {
      this.handleSave()
    }
  }

  render() {
    const { id, text, dirty, hasFocus } = this.state
    const {
      maxNumberOfChars,
      trackDirty,
      textFieldProps,
      inputStyleOverride,
      showCharacterCount,
      className,
    } = this.props

    const underlineFocusStyle = {
      borderColor: this.context.muiTheme.textField.focusColor,
    }
    const atMaxChars = text.length === maxNumberOfChars
    if (atMaxChars) {
      underlineFocusStyle.borderColor = MaxCharacterColor
    } else if (trackDirty && dirty) {
      underlineFocusStyle.borderColor = DirtyColor
    }

    return (
      <TextField
        ref={el => (this.theField = el)}
        {...textFieldProps}
        id={id}
        value={text}
        underlineFocusStyle={underlineFocusStyle}
        errorStyle={{ color: underlineFocusStyle.borderColor }}
        inputStyle={
          {
            ...{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'},
            ...(inputStyleOverride || {})
          }
        }
        onChange={this.handleChange}
        onKeyPress={
          textFieldProps.multiLine ? null : this.handleKeyPress
        }
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        className={classNames(className)}
        errorText={
          hasFocus &&
          showCharacterCount && (
            <div
              className={classNames('error-text', {
                'at-max-characters': atMaxChars,
              })}>
              {text.length}/{maxNumberOfChars}
            </div>
          )
        }
      />
    )
  }
}
