import React from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import VisibilityBoardAddressRow from "./VisibilityBoardAddressRow"
import PropTypes from 'prop-types'

const VisibilityBoardAddressRowList = ({list, onEdit, onDelete}) => {
  return (
    <React.Fragment>
      {list.map(item => <VisibilityBoardAddressRow key={item.__id} rowData={item} onEdit={onEdit} onDelete={onDelete}/>)}
    </React.Fragment>
  )
}

VisibilityBoardAddressRowList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default createFragmentContainer(VisibilityBoardAddressRowList, {
  list: graphql`
    fragment VisibilityBoardAddressRowList_list on VisibilityBoardAddress @relay(plural: true) {
      ...VisibilityBoardAddressRow_rowData
    }
  `,
})
