import React, { useReducer } from 'react'

import Dialog from '../../common/Dialog'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import CurrentWatchers from './CurrentWatchers/CurrentWatchers'
import { QueryRenderer, graphql } from 'react-relay'
import { environment } from '../../../graphql/relay/env'
import KnownPersonnelTypeAhead from '../knownPersonnelTypeAhead/KnownPersonnelTypeAhead'
import {
  addWatchers,
  removeWatchers,
  addOrRemoveWatcherUpdater
} from '../../../graphql/relay/queries/barrier'
import { buildKnownUserInput, IRIS_USER_ROLE } from '../../../graphql/relay/queries/knownUser'
import { handleMutation } from '../../../graphql/relay/queries/mutation'
import styled from 'styled-components'
import { LoadingIndicator } from '../../loader'
import WatcherSnackbar from './WatcherSnackbar/WatcherSnackbar'

const actions = {
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR'
}

const initialState = {
  isSnackbarOpen: false,
  snackbarMessage: '',
  removedUserId: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.OPEN_SNACKBAR:
      return {
        ...state,
        isSnackbarOpen: true,
        snackbarMessage: action.payload.message,
        removedUserId: action.payload.userId
      }
    case actions.CLOSE_SNACKBAR:
      return {...initialState}
    default:
      return {...state}
  }
}

const ManageWatchersDialog = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    open,
    onClose,
    onSearch,
    barrierId
  } = props

  const handleAddWatcher = (name, knownUser) => {
    manageWatchers(addWatchers, "addWatchers", knownUser?.id)
  }

  const handleRemoveWatcher = (id, firstName, lastName) => {
    manageWatchers(removeWatchers, "removeWatchers", id, () => showSnackbar(id, firstName, lastName))
  }

  const showSnackbar = (id, firstName, lastName) => {
    dispatch({ type: actions.OPEN_SNACKBAR, payload: { message: `${firstName} ${lastName} removed`, userId: id}})
  }

  const manageWatchers = (mutation, mutationName, userId, callback) => {
    const variables = {
      barrierId: barrierId,
      userIds: [userId]
    }

    handleMutation(
      environment,
      mutation,
      variables,
      callback,
      null,
      null,
      addOrRemoveWatcherUpdater(mutationName, variables)
    )
  }

  const handleUndoRemoveWatcher = () => {
    manageWatchers(addWatchers, "addWatchers", state.removedUserId)
    handleSnackbarClose()
  }

  const handleSnackbarClose = () => {
    dispatch({ type: actions.CLOSE_SNACKBAR })
  }

  const handleSearchKnownUsers = async(searchText, watchers) => {
    const watcherIds = watchers.map(watcher => watcher.knownUser.id)

    const searchResults = await onSearch(buildKnownUserInput({ searchText: searchText, instantMessageRegistered: true, roles: [IRIS_USER_ROLE] }))

    return searchResults
      .filter(user => !watcherIds.includes(user.id))
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        title="Manage Watchers">
        <QueryRenderer
          environment={environment}
          query={
            graphql`
              query ManageWatchersDialogQuery($barrierId: ID!) {
                node(id: $barrierId) {
                  ... on Barrier {
                    watchers {
                      ...CurrentWatchers_watchers
                      knownUser {
                        id
                      }
                    }
                  }
                }
              }
            `
          }
          variables={{
            barrierId: barrierId
          }}
          render={({ error, props: relayProps }) => {
            if (error) {
              console.error(error)
              return <div>Something went wrong. Please try again in a minute.</div>
            } else if (!relayProps) {
              return <LoadingIndicator />
            } else {
              return (
                <div>
                  <div>
                    <KnownPersonnelTypeAhead
                      onSearchKnownPersonnel={(value) => handleSearchKnownUsers(value, relayProps.node.watchers)}
                      onResultSelected={handleAddWatcher}
                      placeholder="Search to add a watcher"
                      isShowIrisIcon={false}
                      showSubtext
                      clearTextOnSelect
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <StyledSearchIcon/>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div>
                    <CurrentWatchers
                      watchers={relayProps.node.watchers}
                      onRemoveWatcher={handleRemoveWatcher}
                    />
                  </div>
                </div>
              )
            }
          }}
        />
      </Dialog>
      <WatcherSnackbar
        open={state.isSnackbarOpen}
        onUndo={handleUndoRemoveWatcher}
        onClose={handleSnackbarClose}
        message={state.snackbarMessage}
      />
    </React.Fragment>
  )
}

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 24px;
`

ManageWatchersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  barrierId: PropTypes.string.isRequired,
}

export default ManageWatchersDialog
