import React from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import IconButton from 'material-ui/IconButton'
import ModeEdit from '../icons/ModeEdit'
import PropTypes from 'prop-types'

const CodeTableRow = ({rowData, onEdit}) => {
  const {
    code,
    value,
    ordinal,
    active
  } = rowData

  const rowStyle = {
    border: "1px rgb(224, 224, 224)",
  }

  const columnStyle = {
    border: "1px solid rgb(224, 224, 224)",
    paddingLeft: "15px",
    paddingRight: "15px"
  }

  return (
    <tr style={rowStyle}>
      <td style={columnStyle} bgcolor="white">
        <IconButton onClick={() => onEdit(rowData)}>
          <ModeEdit />
        </IconButton>
      </td>
      <td style={columnStyle} bgcolor="white">{code}</td>
      <td style={columnStyle} bgcolor="white">{value}</td>
      <td style={columnStyle} bgcolor="white">{ordinal}</td>
      <td style={columnStyle} bgcolor="white">{active?"Y":"N"}</td>
    </tr>
  )
}

CodeTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired
}

export default createFragmentContainer(CodeTableRow, {
 rowData: graphql`
    fragment CodeTableRow_rowData on CodeTable {
      id
      code
      value
      ordinal
      active
    }
  `,
})
