import React, { useState } from 'react'
import TypeAheadInput from '../../common/TypeAheadInput/TypeAheadInput'
import PropTypes from 'prop-types'
import EmailIcon from 'mdi-react/EmailOutlineIcon'
import IrisIcon from '../../common/CustomIcons/IrisIcon'

import styled, { css } from 'styled-components'

const KnownPersonnelTypeAhead = ({ onSearchKnownPersonnel,
                                   onResultSelected,
                                   selectedValue,
                                   title,
                                   label,
                                   isShowIrisIcon = true,
                                   searchFooterText,
                                   disabled,
                                   showSubtext,
                                   clearTextOnSelect,
                                   focusOnSearchField,
                                   ...textFieldProps}) => {

  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleSearchTextChanged = async value => {
    setIsLoading(true)

    const filteredData = await onSearchKnownPersonnel(value)
    const displayData = filteredData ? filteredData.map(item => ({ ...item, displayValue: `${ item.lastName }, ${ item.firstName }`, subText: item.title })) : []

    setSearchResults(displayData)
    setIsLoading(false)
  }

  const handleResultSelected = (name, id) => {
    const knownUserArr = searchResults.filter(user => user.id === id)
    let knownUser
    if (knownUserArr && knownUserArr.length > 0) {
      knownUser = knownUserArr[0]
    }

    onResultSelected(name, knownUser)
  }

  const displayCommMethodIcon = (result, active) => {
    let icon = null
    if(isShowIrisIcon) {
      if (result.instantMessageReceiver) {
        const fillColor = active ? 'WHITE' : 'BLUE'
        icon = (<StyledIrisIcon color={fillColor} width={32} height={32} />)
      } else {
        if(result.emailReceiver) {
          icon = (<StyledEmailIcon $active={active} />)
        }
      }
    }

    return (
      <StyledCommMethodIconWrapper>
        {icon}
      </StyledCommMethodIconWrapper>
    )
  }

  return (
    <StyledKnownPersonnelTypeAheadWrapper>
     <TypeAheadInput
          {...textFieldProps}
          label={label}
          searchResults={searchResults}
          isLoading={isLoading}
          onTextChanged={value => handleSearchTextChanged(value)}
          onResultSelected={(name, id) => handleResultSelected(name, id)}
          selectedValue={selectedValue}
          selectedSubText={title}
          searchResultIconDisplay={(result, active) => displayCommMethodIcon(result, active)}
          searchFooterText={searchFooterText}
          disabled={disabled}
          showSubtext={showSubtext}
          clearTextOnSelect={clearTextOnSelect}
          focusOnSearchField={focusOnSearchField}
      />
    </StyledKnownPersonnelTypeAheadWrapper>
  )
}

const StyledKnownPersonnelTypeAheadWrapper = styled.div`
  width: 100%;
`

const StyledCommMethodIconWrapper = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledIrisIcon = styled(IrisIcon)`
  width: 22px;
  height: 22px;
`

const StyledEmailIcon = styled(EmailIcon)`
  fill: #1589EE;
  width: 22px;
  height: 22px;

  ${props => props.$active && css`
    fill: #FFFFFF;
  `}
`

KnownPersonnelTypeAhead.propTypes = {
  onResultSelected: PropTypes.func.isRequired,
  onSearchKnownPersonnel: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  isShowIrisIcon: PropTypes.bool,
  searchFooterText: PropTypes.string,
  disabled: PropTypes.bool,
  showSubtext: PropTypes.bool,
  clearTextOnSelect: PropTypes.bool,
  focusOnSearchField: PropTypes.bool
}

export default KnownPersonnelTypeAhead
