import React from 'react'
import Dialog from 'material-ui/Dialog'
import CloseIcon from 'mdi-react/CloseIcon'
import { IconButton } from 'material-ui'
import PropTypes from 'prop-types'

import BarrierHistoryRow from '../BarrierHistoryRow/BarrierHistoryRow'

import './BarrierCommunicationHistoryDialog.scss'



const BarrierCommunicationHistoryDialog = ({ open, barrierCommunicationHistory, barrier, onRequestClose }) => {
  return (
    <Dialog
      open={open}
      modal
      title={'Message History'}
      titleStyle={{ color: '#4A4A4A', fontSize: '16px', paddingBottom: '8px', fontWeight: 'bold' }}
      bodyStyle={{ borderTop: 'none' }}
      contentStyle={{ maxWidth: '650px' }}
      onRequestClose={onRequestClose}
      className="BarrierCommunicationHistoryDialog"
      autoScrollBodyContent>
      <div className="CloseIcon">
        <IconButton onClick={onRequestClose}>
          <CloseIcon/>
        </IconButton>
      </div>

      {barrierCommunicationHistory && barrierCommunicationHistory.length > 0 ?
        barrierCommunicationHistory.map(data => <BarrierHistoryRow key={data.id} historyData={data} />) :
        (
          <div className={'NoHistory'}>
            No history to display.
          </div>
        )
      }
    </Dialog>
  )
}

BarrierCommunicationHistoryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  barrierCommunicationHistory: PropTypes.array.isRequired,
  barrier: PropTypes.shape({
    ownerMessageDeliveryMethod: PropTypes.string
  }),
  onRequestClose: PropTypes.func.isRequired
}

export default BarrierCommunicationHistoryDialog
