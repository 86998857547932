/*eslint-disable react/no-set-state*/
import React from 'react'
import PropTypes from 'prop-types'
import './kpi.scss'

import { Avatar } from 'material-ui'
import Toggle from 'material-ui/Toggle'
import FlatButton from 'material-ui/FlatButton'
import {
  cyan500 as FilteredApplyColor,
  cyan100 as TabSelectedColor,
  cyan500 as FilterSelectedColor,
  grey400 as DefaultColor,
  grey400 as BarriersColor,
  grey400 as ReviewColor,
  grey400 as ProgressionColor,
  grey400 as DischargesColor,
  grey400 as CensusColor,
  grey500 as ProgressionIndicatorNotSetColor,
  green500 as ProgressionIndicatorOnTimeColor,
  yellow500 as ProgressionIndicatorAtRiskColor,
  red500 as ProgressionIndicatorHighRiskColor,
} from 'material-ui/styles/colors'

import { ClChip, NumberInput } from '../common'

const SectionLabel = 'Section'
const FLOSLabel = 'FLOS'
const ExcessDaysLabel = 'Excess Days'
const ProgressionLabel = 'Progression'
const PlansLabel = 'Plans'
const CategoriesLabel = 'Barriers'

const FLOSSection = FLOSLabel + SectionLabel
const ExcessDaysSection = ExcessDaysLabel + SectionLabel
const CategoriesSection = CategoriesLabel + SectionLabel
const PlansSection = PlansLabel + SectionLabel
const ProgressionSection = ProgressionLabel + SectionLabel

const SortOrder = 'SortOrder'
const Ascending = 'Ascending'
const Descending = 'Descending'

import { withApplications, applicationsPropType } from '../../containers/app'
import { pushUnique } from '../../util/utils'

@withApplications
export class FilterSection extends React.Component {
  static propTypes = {
    applications: applicationsPropType,
    style: PropTypes.object,
    stats: PropTypes.object,
    kpiFilters: PropTypes.object,
    flosFilter: PropTypes.object,
    excessDaysFilter: PropTypes.object,
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        bed: PropTypes.object,
        visit: PropTypes.object,
        plan: PropTypes.object,
      })
    ),
    onFilterClick: PropTypes.func,
    onFLOSFilterChange: PropTypes.func,
    onExcessDaysFilterChange: PropTypes.func,
  }

  state = {
    sectionToShow: '',
  }

  // ************************************************************************
  // zzz FLOS Days Filter
  // ************************************************************************
  handleFLOSFilterSectionClick = item => {
    this.handleFilterSectionClick(item)
  }

  handleFLOSSortOrderToggle = (na, value) => {
    this.props.onFLOSFilterChange({
      ...this.props.flosFilter,
      above: value,
      apply: false,
    })
  }

  handleFLOSValueChange = (na, value) => {
    this.props.onFLOSFilterChange({
      ...this.props.flosFilter,
      value: value,
      apply: false,
    })
  }

  handleFLOSApplyClick = () => {
    this.props.onFLOSFilterChange({ ...this.props.flosFilter, apply: true })
  }

  // ************************************************************************
  // zzz Excess Days Filter
  // ************************************************************************
  handleExcessDaysFilterSectionClick = item => {
    this.handleFilterSectionClick(item)
  }

  handleExcessDaysValueChange = (na, value) => {
    this.props.onExcessDaysFilterChange({
      ...this.props.excessDaysFilter,
      value: value,
      apply: false,
    })
  }

  handleExcessDaysSortOrderToggle = (na, value) => {
    this.props.onExcessDaysFilterChange({
      ...this.props.excessDaysFilter,
      above: value,
      apply: false,
    })
  }

  handleExcessDaysApplyClick = () => {
    this.props.onExcessDaysFilterChange({
      ...this.props.excessDaysFilter,
      apply: true,
    })
  }

  // ************************************************************************
  // zzz General
  // ************************************************************************
  handleFilterSectionClick = item => {
    const sectionToShow =
      this.state.sectionToShow === item.section ? '' : item.section
    this.setState({ sectionToShow: sectionToShow })
  }

  handleFilterChange = item => {
    if (item.data) {
      this.props.onFilterClick(item.data)
    }
  }

  handleResetClick = () => {
    this.setState({ sectionToShow: '' })
    this.props.onFilterClick()
  }

  // ************************************************************************
  // zzz Make Filters
  // ************************************************************************
  makeFilterChip(config) {
    const { kpiFilters, flosFilter, excessDaysFilter } = this.props

    let selected = false
    if (
      (config.section === FLOSSection && flosFilter.apply) ||
      (config.section === ExcessDaysSection && excessDaysFilter.apply)
    ) {
      selected = true
    } else if (config.childrenIds) {
      selected = config.childrenIds.find(
        id => kpiFilters && kpiFilters.id === id
      )
    } else {
      // selected = kpiFilters.find(filter => filter.id === config.data.id)
      selected = kpiFilters && kpiFilters.id === config.data.id
    }

    const bc = selected ? FilterSelectedColor : config.backgroundColor
    const avatarLabel = Array.isArray(config.data.value)
      ? config.data.value.length
      : config.data.value
    return (
      <span className="filter-chip" key={config.label}>
        <ClChip
          backgroundColor={config.color}
          onClick={() => config.onClick(config)}>
          <Avatar color="black" backgroundColor={bc}>
            {avatarLabel}
          </Avatar>
          {config.label}
        </ClChip>
      </span>
    )
  }

  makeFilterSection(stats, sectionToShow) {
    const configs = [
      {
        label: FLOSLabel,
        section: FLOSSection,
        childrenIds: [],
        data: [],
        color: FLOSSection === sectionToShow ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleFLOSFilterSectionClick,
      },
      {
        label: ExcessDaysLabel,
        section: ExcessDaysSection,
        childrenIds: [],
        data: [],
        color:
          ExcessDaysSection === sectionToShow ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleExcessDaysFilterSectionClick,
      },
      {
        label: ProgressionLabel,
        section: ProgressionSection,
        childrenIds: stats.allProgressionIds,
        data: [], //stats.progressionSection,
        color:
          ProgressionSection === sectionToShow ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleFilterSectionClick,
      },
      // Commented out per CA-737
      // {
      //   label: PlansLabel,
      //   section: PlansSection,
      //   childrenIds: stats.allPlanTemplateIds,
      //   data: [], //stats.planTemplatesSection,
      //   color: PlansSection === sectionToShow ? TabSelectedColor : undefined,
      //   backgroundColor: DefaultColor,
      //   onClick: this.handleFilterSectionClick,
      // },
      {
        label: CategoriesLabel,
        section: CategoriesSection,
        childrenIds: stats.allCategoriesIds,
        data: [], //stats.categoriesSection,
        color:
          CategoriesSection === sectionToShow ? TabSelectedColor : undefined,
        backgroundColor: DefaultColor,
        onClick: this.handleFilterSectionClick,
      },
    ]

    return (
      <div className="list">
        <span className="filter-label">Filter</span>
        {configs.map(config => this.makeFilterChip(config))}
        <FlatButton
          label="Clear"
          style={{
            paddingLeft: '8px',
            paddingRight: '8px',
            border: '1px solid lightgrey',
            lineHeight: '30px',
            maxHeight: 34,
            marginLeft: '4px',
            marginTop: '4px',
            minWidth: '44px !important',
          }}
          labelStyle={{
            textTransform: 'none',
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          }}
          onClick={this.handleResetClick}
        />
      </div>
    )
  }

  makeFLOSSection(flosFilter) {
    // Patients above or below a Forecast LOS of X days where <= 7 is the default
    // and the user can change the number of days or the above or below.
    // It is always inclusive of the number set in the parameter

    return (
      <div className="section">
        <div className="row">
          <div className="col-xs-12 number-input-column">
            <span
              className="number-input-toggle"
              onClick={() =>
                this.handleFLOSSortOrderToggle(null, !flosFilter.above)
              }>
              <Toggle
                toggled={flosFilter.above}
                labelPosition="right"
                label={flosFilter.above ? 'Above' : 'Below'}
              />
            </span>
            <NumberInput
              style={{ marginBottom: 5, width: 130 }}
              inputStyle={{ textAlign: 'right' }}
              name="FLOSNumberInput"
              hintText={'Days'}
              // floatingLabelText={'Forecast LOS'}
              min={0}
              max={100}
              value={flosFilter.value}
              onChange={this.handleFLOSValueChange}
            />
            <span className="number-input-label">Forecast LOS Days</span>
            <FlatButton
              label="Apply"
              style={{
                backgroundColor: flosFilter.apply ? FilteredApplyColor : '',
                border: '1px solid lightgrey',
                lineHeight: '30px',
                maxHeight: 34,
                marginLeft: '8px',
                marginTop: '1px',
              }}
              labelStyle={{ textTransform: 'none' }}
              onClick={this.handleFLOSApplyClick}
            />
          </div>
        </div>
      </div>
    )
  }

  makeExcessDaysSection(excessDaysFilter) {
    // Patients above or below a Forecast excess of X days where >= 2 is the default
    // and the user can change the number of days or the above or below.
    // It is always inclusive of the number set in the parameter

    return (
      <div className="section">
        <div className="row">
          <div className="col-xs-12 number-input-column">
            <span
              className="number-input-toggle"
              onClick={() =>
                this.handleExcessDaysSortOrderToggle(
                  null,
                  !excessDaysFilter.above
                )
              }>
              <Toggle
                toggled={excessDaysFilter.above}
                labelPosition="right"
                label={excessDaysFilter.above ? 'Above' : 'Below'}
              />
            </span>
            <NumberInput
              style={{ marginBottom: 5, width: 130 }}
              inputStyle={{ textAlign: 'right' }}
              name="ExcessDaysNumberInput"
              hintText={'Days'}
              // floatingLabelText={'Excess Days'}
              min={0}
              max={100}
              value={excessDaysFilter.value}
              onChange={this.handleExcessDaysValueChange}
            />
            <span className="number-input-label">Excess Days</span>
            <FlatButton
              label="Apply"
              style={{
                backgroundColor: excessDaysFilter.apply
                  ? FilteredApplyColor
                  : '',
                border: '1px solid lightgrey',
                lineHeight: '30px',
                maxHeight: 34,
                marginLeft: '8px',
                marginTop: '1px',
              }}
              labelStyle={{ textTransform: 'none' }}
              onClick={this.handleExcessDaysApplyClick}
            />
          </div>
        </div>
      </div>
    )
  }

  makeCategoriesSection(stats) {
    let chipConfigs = []
    const categories = stats.categoriesSection.categories
    Object.keys(categories).forEach(key => {
      chipConfigs.push({
        label: key,
        backgroundColor: DefaultColor,
        data: { id: key, section: CategoriesSection, value: categories[key] },
        onClick: this.handleFilterChange,
      })
    })

    return (
      <div className="section">
        <div className="row">
          <div className="col-xs-12 chip-column">
            {chipConfigs.map(config => this.makeFilterChip(config))}
          </div>
        </div>
      </div>
    )
  }

  makePlansSection(stats) {
    let chipConfigs = []
    const planTemplates = stats.planTemplatesSection.planTemplates
    Object.keys(planTemplates).forEach(key => {
      chipConfigs.push({
        label: key,
        backgroundColor: DefaultColor,
        data: { id: key, section: PlansSection, value: planTemplates[key] },
        onClick: this.handleFilterChange,
      })
    })

    return (
      <div className="section">
        <div className="row">
          <div className="col-xs-12 chip-column">
            {chipConfigs.map(config => this.makeFilterChip(config))}
          </div>
        </div>
      </div>
    )
  }

  makeProgressionSection(stats) {
    const chipConfigs = [
      {
        label: stats.progressionNotSet.label,
        backgroundColor: ProgressionColor,
        color: ProgressionIndicatorNotSetColor,
        data: stats.progressionNotSet,
        onClick: this.handleFilterChange,
      },
      {
        label: stats.progressionOnTime.label,
        backgroundColor: ProgressionColor,
        color: ProgressionIndicatorOnTimeColor,
        data: stats.progressionOnTime,
        onClick: this.handleFilterChange,
      },
      {
        label: stats.progressionAtRisk.label,
        backgroundColor: ProgressionColor,
        color: ProgressionIndicatorAtRiskColor,
        data: stats.progressionAtRisk,
        onClick: this.handleFilterChange,
      },
      {
        label: stats.progressionHighRisk.label,
        backgroundColor: ProgressionColor,
        color: ProgressionIndicatorHighRiskColor,
        data: stats.progressionHighRisk,
        onClick: this.handleFilterChange,
      },
    ]

    return (
      <div className="section">
        <div className="row">
          <div className="col-xs-2 label-column">
            <span>Progression Indicator</span>
          </div>
          <div className="col-xs-10 chip-column">
            {chipConfigs.map(config => this.makeFilterChip(config))}
          </div>
        </div>
      </div>
    )
  }

  // ************************************************************************
  // zzz Render
  // ************************************************************************
  render() {
    const { stats, flosFilter, excessDaysFilter } = this.props

    const { sectionToShow } = this.state

    return (
      <div className="filter-section-container">
        {this.makeFilterSection(stats, sectionToShow)}
        {FLOSSection === sectionToShow && this.makeFLOSSection(flosFilter)}
        {ExcessDaysSection === sectionToShow &&
          this.makeExcessDaysSection(excessDaysFilter)}
        {ProgressionSection === sectionToShow &&
          this.makeProgressionSection(stats)}
        {PlansSection === sectionToShow && this.makePlansSection(stats)}
        {CategoriesSection === sectionToShow &&
          this.makeCategoriesSection(stats)}
      </div>
    )
  }
}
