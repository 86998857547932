/*eslint-disable react/no-set-state */

import React from 'react'
import { RaisedButton, FlatButton } from 'material-ui'
import Dialog from 'material-ui/Dialog'
import TextField from 'material-ui/TextField'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import moment from 'moment'
import { hipaaAuditReport } from '../../util/reports'

import './HipaaAuditReport.scss'

class HipaaAuditReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHipaaDialogOpen: false,
      startDate: moment(),
      endDate: moment(),
      visitId: '',
      mrn: '',
      userName: '',
      errors: ''
    }
  }

  handleOpen = () => {
    this.setState({ isHipaaDialogOpen: true })
  }

  handleClose = () => {
    this.setState({
      isHipaaDialogOpen: false,
      errors: ''
    })
  }

  handleSubmit = () => {
    if (this.state.startDate.isAfter(this.state.endDate, 'day')) {
      this.setState({ errors: 'Start date cannot be after the end date.'})
    } else {
      this.handleClose()
      hipaaAuditReport(
        moment(this.state.startDate).format('MMDDYYYY'),
        moment(this.state.endDate).format('MMDDYYYY'),
        this.state.userName,
        this.state.mrn,
        this.state.visitId
      )
    }
  }

  render() {
    const actions = [
      <FlatButton
        key="cancel"
        label="Cancel"
        primary
        onClick={this.handleClose}
      />,
      <FlatButton
        key={"download"}
        label="Download"
        primary
        onClick={this.handleSubmit}
      />,
    ]

    return (
      <div className="hipaaAuditReport">
        <div className="hipaaButtonRow">
          <RaisedButton
            label="HIPAA Audit Report"
            onClick={this.handleOpen} />
        </div>

        <Dialog
          open={this.state.isHipaaDialogOpen}
          onRequestClose={this.handleClose}
          title={'HIPAA Audit Report'}
          actions={actions}
          modal={false}
        >
          <div className="hipaaReportDialog">
            <div className="reportRow">
              <label htmlFor="startDate">Start Date:</label>
              <span>
                <DatePicker
                  id="startDate"
                  selected={this.state.startDate?.toDate()}
                  onChange={dt => this.setState({ startDate: moment(dt) })}
                />
              </span>
            </div>
            <div className="reportRow">
              <label htmlFor="endDate">End Date:</label>
              <span>
                <DatePicker
                  id="endDate"
                  selected={this.state.endDate?.toDate()}
                  onChange={dt => this.setState({ endDate: moment(dt) })}
                />
              </span>
            </div>
            <div className="reportRow">
              <label htmlFor="visitId">Visit ID:</label>
              <TextField
                id="visitId"
                hintText="Visit ID"
                onChange={(e) => this.setState({ visitId: e.target.value })}
                value={this.state.visitId}
              />
            </div>
            <div className="reportRow">
              <label htmlFor="mrn">MRN:</label>
              <TextField
                id="mrn"
                hintText="MRN"
                onChange={(e) => this.setState({ mrn: e.target.value })}
                value={this.state.mrn}
              />
            </div>
            <div className="reportRow">
              <label htmlFor="username">Username:</label>
              <TextField
                id="username"
                hintText="Username"
                onChange={(e) => this.setState({ userName: e.target.value })}
                value={this.state.userName}
              />
            </div>
            <div className="errorSection">
              {this.state.errors}
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export default HipaaAuditReport
