import React from 'react'
import PropTypes from 'prop-types'

export function nameFormat(person, format = 'lf') {
  let formattedName = ''
  let middleIn = ''
  switch (format) {
    case 'fl':
      formattedName = `${person.firstName} ${person.lastName}`
      break
    case 'lfm':
      middleIn = person.middleInitial ? person.middleInitial : ' '
      formattedName = `${person.lastName}, ${person.firstName} ${middleIn}`
      break
    default:
      formattedName = `${person.lastName}, ${person.firstName}`
      break
  }
  return formattedName
}

export const NameFormat = ({ person, format = 'lf' }) => {
  return <span>{nameFormat(person, format)}</span>
}

NameFormat.propTypes = {
  person: PropTypes.shape({
    firstName: PropTypes.string,
    middleInitial: PropTypes.string,
    lastName: PropTypes.string,
  }),
  format: PropTypes.string,
}
