/*eslint-disable react/no-set-state */

/**
 * Based on DrgView that was done in Jarvis in 2016
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import sortBy from 'lodash.sortby'

import { DrgGQL } from '../../graphql/queries'

import IconButton from 'material-ui/IconButton'
import FlatButton from 'material-ui/FlatButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'

import { showDrawer } from '../../ducks/views'
import { drawers } from '../drawer-component-mapping'
import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'

import { drgSearch, mdcSearch, mdcSearchFast } from './drgFilter'
import { DrgSearchInput, DrgFamilyTable } from '../../components/drg'
import { withGraphql } from '../../graphql/component/with-graphql-hoc'

import styled from 'styled-components'

import { updateDrg } from '../../graphql/relay/queries/visit'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'

const DRAWER_HEADER_HEIGHT = 40
const DRG_SEARCH_HEIGHT = 58

function mapDispatchToProps(dispatch) {
  const actions = {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }


  return actions
}

@withGraphql
@connect(
  null,
  mapDispatchToProps
)
@graphql(DrgGQL.queries.drgs, {options: ({visit}) => ({
    variables: { 'facilityId': visit.visitFacilityId }
  }),
})
@graphql(DrgGQL.queries.severityPoster, { name: 'severityPoster' })

class DrgView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
    }),
    grid: {
      forceUpdateGrid: PropTypes.func,
    },
    updateDrg: PropTypes.func.isRequired,
    data: PropTypes.shape({
      drgs: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          title: PropTypes.string,
        })
      ),
    }),
    severityPoster: PropTypes.shape({
      severityPoster: PropTypes.string,
    }),
    visit: PropTypes.object.isRequired,
    drgSearchFilters: PropTypes.object,
    onRequestClose: PropTypes.func.isRequired,
    refresh: PropTypes.func
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    familyDrgs: [],
    searchFilters: {
      drgCode: '',
      searchTerm: '',
      med: true,
      surg: true,
      majorDiagnosisCategory: '',
    },
  }

  componentWillMount() {
    // coming back from Severities
    if (this.props.drgSearchFilters) {
      this.setState({ searchFilters: this.props.drgSearchFilters })
    } else if (this.props.visit.drgAttributes) {
      this.handleDrgChanged({
        target: { value: this.props.visit.drgAttributes.code },
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.familyDrgs.length === 0 && nextProps.data.drgs) {
      const familyDrgs = this.createDrgFamilies(nextProps.data.drgs)

      this.setState({
        familyDrgs: familyDrgs,
      })
    }
  }

  handleShowSeverities = drg => {
    const { visit } = this.props
    const drgSearchFilters = this.state.searchFilters
    const onSelectDrg = this.handleSelectDrg

    this.props.onRequestClose().then(() =>
      this.props.actions.showDrawer(drawers.SEVERITY_VIEW, {
        drg,
        visit,
        drgSearchFilters,
        onSelectDrg,
      })
    )
  }

  handleReset = () => {
    this.setState({
      searchFilters: {
        drgCode: '',
        searchTerm: '',
        med: true,
        surg: true,
        majorDiagnosisCategory: '',
      },
    })
  }

  debounceId = null

  handleDrgChanged = e => {
    // CA-983
    const regex = '^[a-zA-Z0-9]*$'
    const valids = e.target.value.match(regex)
    const value = valids ? valids[0].toUpperCase() : ''
    if (value.length <= 3) {
      this.setState({
        searchFilters: {
          drgCode: value,
          searchTerm: '',
          med: true,
          surg: true,
          majorDiagnosisCategory: '',
        },
      })
    }
  }

  handleSearchTermChanged = e => {
    const value = e.target.value
    const textSearch = () => {
      this.setState({
        searchFilters: {
          ...this.state.searchFilters,
          searchTerm: value,
          drgCode: '',
        },
      })
    }

    if (this.debounceId) {
      clearTimeout(this.debounceId)
    }

    this.debounceId = setTimeout(textSearch, 400)
  }

  handleMdcChanged = (event, index, value) => {
    this.setState({
      searchFilters: {
        ...this.state.searchFilters,
        majorDiagnosisCategory: value === 'ALL' ? '' : value,
        drgCode: '',
      },
    })
  }

  handleMedChanged = () => {
    this.setState({
      searchFilters: {
        ...this.state.searchFilters,
        med: !this.state.searchFilters.med,
        drgCode: '',
      },
    })
  }

  handleSurgChanged = () => {
    this.setState({
      searchFilters: {
        ...this.state.searchFilters,
        surg: !this.state.searchFilters.surg,
        drgCode: '',
      },
    })
  }

  handleSelectDrg = (drg) => {


    const variables = { visitId: this.props.visit.id, drgCode: drg.code }
    handleMutation(
      environment,
      updateDrg,
      variables,
      ()=>{
        this.props.onRequestClose()
        this.props.refresh ? this.props.refresh() :
          this.props.grid && this.props.grid.forceUpdateGrid()
      }
    )
  }

  createDrgFamilies = drgs => {
    const result = []

    let currentTitle = '',
      currentFamily = [],
      index = 0

    if (drgs) {
      const sortedDrgs = sortBy(drgs, ['code'])
      // now group into families
      sortedDrgs.forEach(item => {
        if (item.title !== currentTitle) {
          currentTitle = item.title
          currentFamily = []
          result.push({
            id: index++,
            family: currentFamily,
            type: item.type,
            majorDiagnosisCategory: item.majorDiagnosisCategory,
            title: item.title,
            commonPrincipalDX: item.commonPrincipalDX,
            commonProcedures: item.commonProcedures,
          })
        }
        currentFamily.push(item)
      })
    }

    return result
  }

  render() {
    const { onRequestClose } = this.props
    const filteredDrgs = drgSearch(
      this.state.familyDrgs,
      this.state.searchFilters
    )
    const posterLink = this.props.severityPoster.severityPoster
    const filteredMdcs =
      filteredDrgs.length === 0
        ? mdcSearchFast(this.state.familyDrgs)
        : mdcSearch(this.state.familyDrgs, this.state.searchFilters)

    const actions = [
      <FlatButton
        label="CC/MCC Poster"
        key="ccmcc-poster"
        labelStyle={{ color: 'white' }}
        href={posterLink}
        target="_blank"
      />,
    ]

    return (
      <StyledDiv DRAWER_HEADER_HEIGHT={DRAWER_HEADER_HEIGHT} DRG_SEARCH_HEIGHT={DRG_SEARCH_HEIGHT}>
        <DrawerHeaderBar
          title="DRG Search"
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={posterLink ? actions : []}
        />

        <DrgSearchInput
          mdcs={filteredMdcs}
          onDrgChanged={this.handleDrgChanged}
          onMedChanged={this.handleMedChanged}
          onSurgChanged={this.handleSurgChanged}
          onMdcChanged={this.handleMdcChanged}
          onReset={this.handleReset}
          onSearchTermChanged={this.handleSearchTermChanged}
          searchFilters={this.state.searchFilters}
        />

        <DrgFamilyTable
          drgs={filteredDrgs}
          searchFilters={this.state.searchFilters}
          onSelectDrg={this.handleSelectDrg}
          onShowSeverities={this.handleShowSeverities}
          workingDischargeDispositionGroup={this.props.visit.workingDischargeDisposition.workingDischargeDispositionGroup}
        />
      </StyledDiv>
    )
  }
}

const StyledDiv = styled.div`
  height: 100%;

  > :last-child {
    height: calc(100% - ${props => props.DRAWER_HEADER_HEIGHT + props.DRG_SEARCH_HEIGHT}px) !important;
  }
`

export default DrgView
