/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CodeTableRow_rowData$ref = any;
export type CodeType = "BarrierCategory" | "BarrierEscalationRole" | "BarrierNeedsReassignmentReason" | "BarrierSourceDetail" | "BundledPaymentType" | "CareTeamMemberRole" | "Condition" | "DeliveryMethod" | "PlanCategory" | "WorkingDischargeDisposition" | "%future added value";
export type CodeTableInput = {|
  code?: ?string,
  description?: ?string,
  sortOrder?: ?number,
  active?: ?boolean,
|};
export type codeTable_createCodeTableEntry_MutationVariables = {|
  codeTableType: CodeType,
  codeTableInput: CodeTableInput,
|};
export type codeTable_createCodeTableEntry_MutationResponse = {|
  +codeTableMutation: ?{|
    +createCodeTableEntry: ?{|
      +$fragmentRefs: CodeTableRow_rowData$ref
    |}
  |}
|};
export type codeTable_createCodeTableEntry_Mutation = {|
  variables: codeTable_createCodeTableEntry_MutationVariables,
  response: codeTable_createCodeTableEntry_MutationResponse,
|};
*/


/*
mutation codeTable_createCodeTableEntry_Mutation(
  $codeTableType: CodeType!
  $codeTableInput: CodeTableInput!
) {
  codeTableMutation {
    createCodeTableEntry(codeTableType: $codeTableType, codeTableInput: $codeTableInput) {
      ...CodeTableRow_rowData
      id
    }
  }
}

fragment CodeTableRow_rowData on CodeTable {
  id
  code
  value
  ordinal
  active
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "codeTableInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "codeTableType"
},
v2 = [
  {
    "kind": "Variable",
    "name": "codeTableInput",
    "variableName": "codeTableInput"
  },
  {
    "kind": "Variable",
    "name": "codeTableType",
    "variableName": "codeTableType"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "codeTable_createCodeTableEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CodeTableMutation",
        "kind": "LinkedField",
        "name": "codeTableMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CodeTable",
            "kind": "LinkedField",
            "name": "createCodeTableEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CodeTableRow_rowData"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "codeTable_createCodeTableEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CodeTableMutation",
        "kind": "LinkedField",
        "name": "codeTableMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CodeTable",
            "kind": "LinkedField",
            "name": "createCodeTableEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ordinal",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee1486e46343a72c0ecaf6edef4baf42",
    "id": null,
    "metadata": {},
    "name": "codeTable_createCodeTableEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation codeTable_createCodeTableEntry_Mutation(\n  $codeTableType: CodeType!\n  $codeTableInput: CodeTableInput!\n) {\n  codeTableMutation {\n    createCodeTableEntry(codeTableType: $codeTableType, codeTableInput: $codeTableInput) {\n      ...CodeTableRow_rowData\n      id\n    }\n  }\n}\n\nfragment CodeTableRow_rowData on CodeTable {\n  id\n  code\n  value\n  ordinal\n  active\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '142d5586934017960370ff815bff1d62';

module.exports = node;
