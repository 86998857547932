/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BarrierCategoryEditor_barrierSourceTypeConfigs$ref: FragmentReference;
declare export opaque type BarrierCategoryEditor_barrierSourceTypeConfigs$fragmentType: BarrierCategoryEditor_barrierSourceTypeConfigs$ref;
export type BarrierCategoryEditor_barrierSourceTypeConfigs = $ReadOnlyArray<{|
  +id: string,
  +code: string,
  +value: string,
  +ordinal: number,
  +active: boolean,
  +activeDirectorySourceName: boolean,
  +$refType: BarrierCategoryEditor_barrierSourceTypeConfigs$ref,
|}>;
export type BarrierCategoryEditor_barrierSourceTypeConfigs$data = BarrierCategoryEditor_barrierSourceTypeConfigs;
export type BarrierCategoryEditor_barrierSourceTypeConfigs$key = $ReadOnlyArray<{
  +$data?: BarrierCategoryEditor_barrierSourceTypeConfigs$data,
  +$fragmentRefs: BarrierCategoryEditor_barrierSourceTypeConfigs$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BarrierCategoryEditor_barrierSourceTypeConfigs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeDirectorySourceName",
      "storageKey": null
    }
  ],
  "type": "BarrierSourceType",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '53e464df287567bedd6e728f4265236a';

module.exports = node;
