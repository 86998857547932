import gql, {injectForGqlFile} from '../util/gql'

export const SourceTypeGQL = {
  fragments: {
    FullCodeTable: gql`
      fragment SourceTypes on BarrierSourceType {
        id
        code
        value
        active
        activeDirectorySourceName
        hasConfiguredList
        sourceDetails {
          ...SourceDetails
        }
      }
    `,
    CodeValueActive: gql`
      fragment SourceDetails on BarrierSourceDetail {
        code
        value
        active
      }
    `,
  },
  queries: {
    barrierSourceTypes: gql`
      query barrierSourceTypes($barrierCategoryCode: String!) {
        barrierSourceTypes(barrierCategoryCode: $barrierCategoryCode) {
          ...SourceTypes
        }
      }
    `,
  }
   ,
  mutations: {},
}

injectForGqlFile(SourceTypeGQL)
