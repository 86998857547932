/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CodeTableRow_rowData$ref: FragmentReference;
declare export opaque type CodeTableRow_rowData$fragmentType: CodeTableRow_rowData$ref;
export type CodeTableRow_rowData = {|
  +id: string,
  +code: string,
  +value: string,
  +ordinal: number,
  +active: boolean,
  +$refType: CodeTableRow_rowData$ref,
|};
export type CodeTableRow_rowData$data = CodeTableRow_rowData;
export type CodeTableRow_rowData$key = {
  +$data?: CodeTableRow_rowData$data,
  +$fragmentRefs: CodeTableRow_rowData$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodeTableRow_rowData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "type": "CodeTable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f454d38b9fb9cf52f3f50332b7cf6342';

module.exports = node;
