import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@material-ui/core/TextField'
import styled, { css } from 'styled-components'

import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
const DirtyColor = orange[500]
const MaxCharacterColor = red[500]

const TextField = ({ value,
                     onChange=() => {},
                     onEnter=() => {},
                     maxChars,
                     showCharacterCount,
                     trackDirty,
                     ...otherProps}) => {
  const [text, setText] = useState(value || '')
  const [isDirty, setDirty] = useState(false)
  const [hasFocus, setHasFocus] = useState(false)

  useEffect(() => {
    setText(value || '')
  }, [value])

  const handleChange = (event) => {
    const newText = event.target.value
    if (!maxChars || newText.length <= maxChars) {
      setText(newText)
      setDirty(true)
      onChange(event)
    }
  }

  const handleBlur = (event) => {
    saveText()
    if (otherProps.onBlur) {
      otherProps.onBlur(event)
    }
  }

  const handleFocus = () => {
    setHasFocus(true)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      saveText()
    }
  }

  const saveText = () => {
    setHasFocus(false)
    setDirty(false)
    if (value !== text) {
      onEnter(text.trim())
    }
  }

  const displayMaxChars = () => {
    return showCharacterCount && hasFocus && text.length === maxChars
  }

  const displayDirty = () => {
    return trackDirty && isDirty
  }

  const displayCharacterCount = () => {
    return showCharacterCount && hasFocus
  }

  return (
    <React.Fragment>
      <StyledTextField
        {...otherProps}
        value={text}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        autoComplete="off"
        color={'primary'}
        $atMaxChars={displayMaxChars()}
        $dirty={displayDirty()}
      />
      { displayCharacterCount() &&
        <CharacterCount
          className={"textFieldCharacterCount"}
          $dirty={displayDirty()}
          $atMaxChars={displayMaxChars()}>
          {text.length}/{maxChars}
        </CharacterCount>
      }
    </React.Fragment>
  )
}

const StyledTextField = styled(MuiTextField)`
  && {
    ${props => props.$dirty && css`
      div::before, div::after {
        border-bottom-color: ${ DirtyColor };
      }
      label {
        color: ${ DirtyColor };
      }
    `}

    ${props => props.$atMaxChars && css`
      div::before, div::after {
        border-bottom-color: ${ MaxCharacterColor };
      }
      label {
        color: ${ MaxCharacterColor };
      }
    `}
  }
`

const CharacterCount = styled.div`
  color: ${ props => props.$atMaxChars ? MaxCharacterColor : props.$dirty ? DirtyColor : '#4dcedf' };
  font-size: 12px;
  margin-top: 2px;

`

TextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  maxChars: PropTypes.number,
  showCharacterCount: PropTypes.bool,
  trackDirty: PropTypes.bool,
}

export default TextField
