/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { EscalationFilter } from '../../components/escalation'
import EscalationWorkspace from '../../components/escalation/EscalationWorkspace'
import '../views.scss'
import { drawers } from '../../containers/drawer-component-mapping'
import { filterWorkspaces } from '../../util/filters'
import {getScrollToIndex, saveIndexPosition} from '../../util/utils'
import { VariableSizeList as List } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import {createPaginationContainer, fetchQuery, graphql} from 'react-relay'
import {
  createBarrier,
  createBarrierFromTemplate,
  updateBarrierStatus,
  updateBarrierOwner,
  escalateBarrier,
  deescalateBarrier,
  sendBarrierInfoMessage,
  barrierHistory,
  updateBarrierSourceType,
  updateBarrierSourceName,
  barrierOwnerOptimisticResponse,
  barrierSourceNameOptimisticResponse
} from '../../graphql/relay/queries/barrier'
import {
  searchKnownUsers,
  buildKnownUserInput } from '../../graphql/relay/queries/knownUser'
import {
  createBarrierNote,
  updateBarrierNote,
  removeBarrierNote,
} from '../../graphql/relay/queries/note'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'
import {
  pinBarrier,
  unpinBarrier,
} from '../../graphql/relay/queries/pin'
import { getPageSize } from '../../util/configs'
import { highlightSelected} from "../../util/utils"
import { LoadingIndicator } from '../../components/loader'

class EscalationListPaginationContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }

  }

  componentDidMount() {
    const index = getScrollToIndex(this.props.history)
    if(index) {
      this.listRef.current.scrollToItem(index, "center")
    }
  }

  // FIXME this needs to go as this is deprecated
  componentWillReceiveProps(nextProps) {
    // this.handleFilterApply(nextProps.filter, this.workspaces)

    // CA-2330 - in addition to refreshEscalation, getItemSize never called
    // for VariableSizeList since it caches offsets and measurements for each item.
    // So we have to call resetAfterIndex as documented in:
    // https://react-window.now.sh/#/api/VariableSizeList
    this.listRef.current.resetAfterIndex(0, true)

  }

  componentDidUpdate() {
    this.props.actions.updateNavigationWorkspaces(this.filteredWorkspaces)
  }

  listRef = React.createRef()

  handleQuickAddBarrier = planId => barrierInfo => {
    if (barrierInfo.item.templateId === null) {
      const variables = {
        planId: planId,
        barrier: {
          name: barrierInfo.item.name,
          description: barrierInfo.item.description,
          categoryCode: barrierInfo.item.category.code,
        },
      }
      handleMutation(
        environment,
        createBarrier,
        variables,
        this.props.refreshEscalation
      )
    } else {
      const variables = {
        planId: planId,
        templateId: barrierInfo.item.templateId,
        bedRequestStatus: barrierInfo.bedRequestStatus,
      }
      this.handleCreateBarrierFromTemplate(variables)
    }
  }

  handleCreateBarrierFromTemplate = (variables, callback) => {
    handleMutation(
      environment,
      createBarrierFromTemplate,
      variables,
      (response) => {
        this.props.refreshEscalation()
        if(callback){
          callback(response)
        }
      }
    )
  }

  handleAddBarrier = (planId, visit, rerender) => () => {
    const {
      actions: { showDrawer },
    } = this.props

    showDrawer(drawers.CHOOSE_BARRIER, {
      title: `Add Barrier for ${visit.patient.lastName},
        ${visit.patient.firstName}`,
      planId: planId,
      rerender: rerender,
      refetch: this.props.refreshEscalation,
      visit: visit
    })
  }

  handleUpdateBarrierPin = (barrier, rerender) => {
    barrier.pinned
      ? handleMutation(
          environment,
          unpinBarrier,
          { itemId: barrier.id },
          null,
          rerender,
          null
        )
      : handleMutation(
          environment,
          pinBarrier,
          { itemId: barrier.id },
          null,
          rerender,
          null
        )
  }
  handleUpdateBarrierStatus = (barrierId, status, bedRequestStatus) => {
    handleMutation(
      environment,
      updateBarrierStatus,
      { barrierId: barrierId, status: status, bedRequestStatus},
      this.props.refreshEscalation
    )
  }

  handleUpdateBarrierOwner = (barrier, owner, knownUser) => {
    const variables = { barrierId: barrier.id, owner: owner, ownerId: knownUser?.id }
    const optimisticResponse = barrierOwnerOptimisticResponse(barrier, owner, knownUser)
    handleMutation(environment, updateBarrierOwner, variables, this.props.refreshEscalation, undefined, undefined, undefined, optimisticResponse)
  }

  handleUpdateSourceType = (barrierId, sourceTypeCode) => {
    const variables = { barrierId: barrierId, sourceTypeCode: sourceTypeCode }
    handleMutation(environment, updateBarrierSourceType, variables)
  }

  handleUpdateSourceName = (barrier, sourceName) => {
    const variables = { barrierId: barrier.id, sourceName: sourceName }
    const optimisticResponse = barrierSourceNameOptimisticResponse(barrier, sourceName)
    handleMutation(environment, updateBarrierSourceName, variables, undefined, undefined, undefined, undefined, optimisticResponse)
  }

  handleSearchKnownPersonnel = async (knownUserInput) => {
    const response = await fetchQuery(environment, searchKnownUsers, { knownUserInput })
    return response.knownUsers
  }


  handleEscalateBarrier = (barrierId, planId) => {
    handleMutation(
      environment,
      escalateBarrier,
      { barrierId: barrierId, planId: planId },
      this.props.refreshEscalation
    )
  }

  handleDeescalateBarrier = (barrierId, planId) => {
    handleMutation(
      environment,
      deescalateBarrier,
      { barrierId: barrierId, planId: planId },
      this.props.refreshEscalation
    )
  }

  handleCreateBarrierNote = (barrierId, text, rerender) => {
    handleMutation(
      environment,
      createBarrierNote,
      { barrierId: barrierId, text: text },
      this.props.refreshEscalation,
      rerender,
      null
    )
  }

  handleUpdateBarrierNote = (noteId, text, rerender) => {
    //this.props.updateBarrierNote({ variables: { noteId: noteId, text: text } })
    handleMutation(
      environment,
      updateBarrierNote,
      { noteId: noteId, text: text },
      this.props.refreshEscalation,
      rerender,
      null
    )
  }

  handleRemoveBarrierNote = (noteId, rerender) => {
    //this.props.removeBarrierNote({ variables: { noteId: noteId } })
    handleMutation(
      environment,
      removeBarrierNote,
      { noteId: noteId },
      this.props.refreshEscalation,
      rerender,
      null
    )
  }

  handleBarrierClick = (e, barrier, planId) => {
    e.stopPropagation()

    const {
      actions: { setSelectedBarrierSection },
      selectedBarrierSection,
    } = this.props

    setSelectedBarrierSection({
      ...selectedBarrierSection,
      selectedBarrierId: barrier.id,
      selectedPlanId: planId || selectedBarrierSection.selectedPlanId,
    })
  }

  handleShowAllBarriers = (e, planId) => {
    e.stopPropagation()

    const {
      actions: { setSelectedBarrierSection },
      selectedBarrierSection,
    } = this.props

    // For a different plan clicking "Show All" should expand the new plan, which will close any currently open
    // Otherwise, "Show All/Current" should toggle for the same plan
    const showAllBarriers = selectedBarrierSection.selectedPlanId !== planId ? true : !selectedBarrierSection.showAllBarriers

    setSelectedBarrierSection({
      ...selectedBarrierSection,
      selectedPlanId: planId,
      showAllBarriers: showAllBarriers,
    })
  }

  handleSendBarrierInfo = (e, barrierId, recipientId, message, closeModal) => {
    e.stopPropagation()
    const variables = { barrierId: barrierId, recipientId: recipientId, messageNote: message }
    handleMutation(environment, sendBarrierInfoMessage, variables, closeModal)
  }

  handleLoadBarrierMessageHistory = async (barrierId, onlySentBarrierInfo) => {
    const variables = { barrierId: barrierId, onlySentBarrierInfo: onlySentBarrierInfo }

    /*if (variables.barrierId) {
      variables.barrierId = variables.barrierId.substring(
        variables.barrierId.indexOf(':') + 1
      )
    }*/

    const response = await fetchQuery(environment, barrierHistory, variables)
    return response.node.barrierHistory
  }

  onNavigate = index => {
    saveIndexPosition(index, this.props.history)
  }

  rowRenderer = ({ index, style }) => {
    const item = this.workspaces[index]
    const content = this.formatItem(index, item, this.list)
    let lastRow = this.hasNextPage && index === this.workspaces.length - 1
    const {history} = this.props

    if (lastRow && this.state.loading) {
      return (
        <div style={{ textAlign: 'center', padding: '10px', ...style }}>
          <LoadingIndicator/>
        </div>
      )
    } else {
      // CA-2506 - highlight if it's the element we invoked Details from
      if(index === getScrollToIndex(history)) {
        const element = document.getElementById(index)
        element && highlightSelected(element, history)
      }
      return (
        <div id={index} style={style}>
          {content}
        </div>
      )
    }
  }

  formatItem(index, item, list) {
    const {
      history,
      selectedBarrierSection,
      search: {userSetting, barrierEscalationRoles, deliveryMethods},
    } = this.props

    let selBarSection = { ...selectedBarrierSection }
    selBarSection.userBarriers = userSetting.barriers
    return (
      <EscalationWorkspace
        showBarriersExpanded
        rvlist={list}
        workspace={item}
        onBarrierClick={this.handleBarrierClick}
        onShowAllBarriers={this.handleShowAllBarriers}
        selectedBarrierSection={selBarSection}
        onQuickAddBarrier={this.handleQuickAddBarrier}
        onAddBarrier={this.handleAddBarrier}
        onUpdatePinned={this.handleUpdateBarrierPin}
        onEscalateBarrier={this.handleEscalateBarrier}
        onDeescalateBarrier={this.handleDeescalateBarrier}
        onUpdateBarrierStatus={this.handleUpdateBarrierStatus}
        onUpdateBarrierOwner={this.handleUpdateBarrierOwner}
        onSearchKnownPersonnel={this.handleSearchKnownPersonnel}
        routeTo={history.push}
        onNavigate={this.onNavigate}
        onCreateBarrierNote={this.handleCreateBarrierNote}
        onUpdateBarrierNote={this.handleUpdateBarrierNote}
        onRemoveBarrierNote={this.handleRemoveBarrierNote}
        onSendBarrierInfo={this.handleSendBarrierInfo}
        onLoadBarrierMessageHistory={this.handleLoadBarrierMessageHistory}
        index={index}
        barrierEscalationRoles={barrierEscalationRoles}
        deliveryMethods={deliveryMethods}
        onUpdateSourceType={this.handleUpdateSourceType}
        onUpdateSourceName={this.handleUpdateSourceName}
        onCreateBarrierFromTemplate={this.handleCreateBarrierFromTemplate}
      />
    )
  }

  handleFilterApply = (filter, workspaces) => {
    this.filteredWorkspaces = filterWorkspaces(filter, workspaces)
    this.props.clearPaginationLoads()
  }

  handleLoadMore = () => {
    this.setState({ loading: true })
    this.props.relay.loadMore(getPageSize(), () => {
      this.setState({ loading: false })
      this.props.incrementPaginationLoads()
    })
  }

  loadMoreItems = async () => {
    if (this.hasNextPage && !this.state.loading) {
      await this.handleLoadMore()
    }
  }

  getItemSize = index => {
    const item = this.workspaces[index]
    // hard coded heights - would be nice to get these programmatically
    // (really crazy getting heights using DevTools Elements)
    // even better if the free open source code we use could just size each row
    const headerH = 170, noteH = 50, barrierH = 159, barrierSourceTypes = 50, barrierBottomPadding = 10

    if (!item || item.plan.barriers.length === 0) {
      return headerH
    }

    let totalNotes = 0, totalOpenBarriers = 0, totalBarrierSrcTypes = 0
    const { showAllBarriers, selectedPlanId } = this.props.selectedBarrierSection
    item.plan.barriers.forEach(function(b, i) {
      // Always show Open barriers. Show Resolved barriers for the selected plan if "Show All" was clicked
      if((showAllBarriers && selectedPlanId === item.plan.id) || b.status === 'Open') {
        totalOpenBarriers += 1
        if (b.notes.length > 0) totalNotes += b.notes.length
        if (b.category.sourceTypes.length > 0) totalBarrierSrcTypes++
      }
    })

    const barrierSourceTypeHeight = totalBarrierSrcTypes * barrierSourceTypes
    const notesHeight = totalNotes * noteH
    const barriersHeight = totalOpenBarriers * barrierH + barrierBottomPadding
    return headerH + notesHeight + barriersHeight + barrierSourceTypeHeight
  }

  render() {
    const beds = this.props.search.viewer.beds.edges || []
    const totalCount = this.props.search.viewer.beds.pageInfo.totalCount
    this.hasNextPage = this.props.search.viewer.beds.pageInfo.hasNextPage
    this.workspaces = []

    beds.forEach(node => {
      // CA-2624 shouldn't get a null visit from query results,
      // but be proactive as in PlanListPaginationContainer
      if(node.bed.visit) {
        let workspace = {}
        workspace.bed = node.bed
        workspace.department = node.bed.department
        workspace.visit = node.bed.visit
        workspace.plan = workspace.visit.plan
        this.workspaces.push(workspace)
      }
    })

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = this.hasNextPage ?  this.workspaces.length + 1 :  this.workspaces.length

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = index => !this.hasNextPage || index < this.workspaces.length

    return (
      <div className="escalation-container">
        <EscalationFilter
          resultsLength={totalCount}
        />
        <div className="escalation-list">

          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={this.loadMoreItems}
          >
            {({ onItemsRendered }) => (
              <List
                style={{overflowX:'hidden'}}
                height={window.innerHeight - 105}
                width={window.innerWidth - 57}
                itemCount={this.workspaces.length}
                itemSize={this.getItemSize}    // row height
                onItemsRendered={onItemsRendered}
                ref={this.listRef}
              >
                {this.rowRenderer}
              </List>
            )}
          </InfiniteLoader>

        </div>
      </div>
    )

  }
}

EscalationListPaginationContainer.propTypes = {
  refreshEscalation: PropTypes.func.isRequired,
  incrementPaginationLoads: PropTypes.func,
  clearPaginationLoads: PropTypes.func,
  relay: PropTypes.shape({ loadMore: PropTypes.func }),
  search: PropTypes.shape({
    userSetting: PropTypes.shape({
      barriers: PropTypes.array
    }),
    viewer: PropTypes.shape({
      beds: PropTypes.shape({
        edges: PropTypes.array,
        pageInfo: PropTypes.shape({
          hasNextPage: PropTypes.boolean,
          totalCount: PropTypes.string,
        }),
      }),
    }),
    barrierEscalationRoles: PropTypes.array,
    deliveryMethods: PropTypes.array
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  patientVectorMap: PropTypes.object,
  selectedBarrierSection: PropTypes.shape({
    selectedPlanId: PropTypes.string.isRequired,
    selectedBarrierId: PropTypes.string,
    showAllBarriers: PropTypes.bool.isRequired,
  }),
  filter: PropTypes.object,
  escalationFilterQuery: PropTypes.string,
  workspaces: PropTypes.arrayOf(
    PropTypes.shape({
      facility: PropTypes.object,
      department: PropTypes.object,
      bed: PropTypes.object,
      visit: PropTypes.object,
      plan: PropTypes.object,
    })
  ),
  patientVectorData: PropTypes.object,
  departments: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.shape({
    updateNavigationWorkspaces: PropTypes.func.isRequired,
    showDrawer: PropTypes.func,
    setSelectedBarrierSection: PropTypes.func
  }),
  location: PropTypes.object,
  scrollToIndex: PropTypes.number
}

export default createPaginationContainer(
  EscalationListPaginationContainer,
  {
    search: graphql`
        fragment EscalationListPaginationContainer_search on Query
        @argumentDefinitions(
            first: { type: "Int" }
            after: { type: "String", defaultValue: null }
            getSettingsRoles: {type: "Boolean!"}
            skipOwnerKnownUser: {type: "Boolean!", defaultValue: false}
            type: { type: "SearchType", defaultValue: null }
            query: { type: "String", defaultValue: null }
        ) {

          userSetting @include(if: $getSettingsRoles) {
            barriers {
              id
              name
              description
              active
              dischargeDelay
              category {
                code
                value
              }
              templateId
            }
          }
          barrierEscalationRoles: codeTables (codeTableType:BarrierEscalationRole) @include(if: $getSettingsRoles) {
            code
            value
            ordinal
            active
          }
          deliveryMethods: codeTables (codeTableType: DeliveryMethod) {
            code
            active
          }
          viewer {
                beds(first: $first, after: $after, type: $type, query: $query)
                @connection(key: "EscalationListPaginationContainer_beds") {
                    totalCount
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        totalCount
                    }
                    edges {
                        bed: node {
                            id
                            name
                            exchangeName
                            status
                            # providers {
                            #   id
                            #   firstName
                            #   lastName
                            #   primary
                            #   role {
                            #     id
                            #     code
                            #     value
                            #   }
                            # }
                            # conflictingVisits {
                            #   id
                            # }
                            # dischargedVisit {
                            #   id
                            # }
                            department {
                                id
                                exchangeName
                                name
                                type
                                # includedInLosCalculation
                            }
                            visit {
                                id
                                vector {
                                    id
                                    patientId
                                    encounterId
                                    predictionDate
                                    conditions {
                                        name
                                        riskBracket
                                        riskFactors {
                                            id
                                            type
                                            description
                                        }
                                        recommendations {
                                            id
                                            rank
                                            description
                                            code
                                            accepted
                                        }
                                    }
                                }
                                admissionTime
                                admissionType
                                admittingComplaint
                                anticipatedDischargeTime
                                anticipatedDischargeUpdatedBy {
                                  id
                                  userName
                                  firstName
                                  lastName
                                }
                                attendingPhysician {
                                    firstName
                                    id
                                    lastName
                                    # primary
                                    # role {
                                    #   code
                                    #   id
                                    #   value
                                    # }
                                }
                                bedRequestStatus
                                bedRequests {
                                  id
                                  bedRequestType
                                  bedRequestStatus
                                  updatedWhen
                                  bedExchangeName
                                }

                              currentDayOfStay
                                decisionTime
                                dischargeVendor
                                drgAttributes {
                                    id
                                    # arithmeticLos
                                    code
                                    # commonPrincipalDX
                                    # commonProcedures
                                    # expectedLos
                                    # geometricLos
                                    level
                                    # modifyTargetLos
                                    # majorDiagnosisCategory
                                    # readmissionRisk
                                    # targetLos
                                    title
                                    # type
                                    # weight
                                }
                                drgUpdatedBy {
                                    id
                                    firstName
                                    lastName
                                    userName
                                }
                                homeBedExchangeName
                                insurancePlans {
                                    id
                                    planName
                                    priority
                                }
                                meta {
                                    id
                                    decisionDue
                                    dischargeToday
                                    plannedDischargeExceeded
                                    timeElapsedSinceAdmission
                                    tlosExceeded
                                }
                                patient {
                                    id
                                    age
                                    firstName
                                    gender
                                    lastName
                                    middleInitial
                                    mrn
                                }
                                patientProblem
                                targetDischargeTime
                                tlos
                                visitNumber
                                visitStatus
                                workingDischargeDisposition {
                                    code
                                    id
                                    value
                                }
                                plan {
                                    id
                                    barriers {
                                        id
                                        name
                                        description
                                        dischargeDelay
                                        pinned
                                        status
                                        category {
                                            id
                                            code
                                            value
                                            sourceTypes {
                                              id
                                              code
                                              value
                                              active
                                              activeDirectorySourceName
                                              hasConfiguredList
                                              sourceDetails {
                                                id
                                                code
                                                value
                                                active
                                              }
                                            }
                                        }
                                        escalation {
                                            id
                                            auditMeta {
                                                createdBy {
                                                    id
                                                    userName
                                                    firstName
                                                    lastName
                                                }
                                                updatedBy {
                                                    id
                                                    userName
                                                    firstName
                                                    lastName
                                                }
                                                created
                                                updated
                                            }
                                        }
                                        auditMeta {
                                             createdBy {
                                               id
                                               userName
                                               firstName
                                               lastName
                                             }
                                             updatedBy {
                                               id
                                               userName
                                               firstName
                                               lastName
                                             }
                                            created
                                            updated
                                        }
                                        ownerMessageStatus
                                        ownerMessageStatusDate
                                        ownerMessageDeliveryDate
                                        ownerKnownUser @skip(if: $skipOwnerKnownUser) {
                                          id
                                          emailReceiver
                                          instantMessageReceiver
                                        }
                                        owner
                                        ownerRole {
                                            id
                                            code
                                            value
                                        }
                                        sourceTypeCode
                                        sourceName
                                        notes {
                                            id
                                            text
                                            pinned
                                            auditMeta {
                                                createdBy {
                                                    id
                                                    userName
                                                    firstName
                                                    lastName
                                                }
                                                updatedBy {
                                                    id
                                                    userName
                                                    firstName
                                                    lastName
                                                }
                                                created
                                                updated
                                            }
                                        }
                                    }
                                    careTeamMembers {
                                        id
                                        name
                                        role {
                                            id
                                            code
                                            value
                                        }
                                        primary
                                    }
                                    meta {
                                        milestoneMeta {
                                            overdue
                                            onTime
                                            completed
                                        }
                                        lengthOfStayMeta {
                                            forecast
                                            cappedForecast
                                        }
                                        actualOE
                                    }
                                    milestones {
                                        id
                                        name
                                        description
                                        dueDate
                                        dueDateCutoff
                                        completedDate
                                        track
                                        status
                                        duplicate
                                    }
                                    progressionIndicator
                                    toBeAdmitted
                                }
                                anticipatedTransferTime
                                anticipatedDischargeTimeAutoPopulated
                                anticipatedTransferTimeAutoPopulated
                            }
                        }
                    }
                }
            }
        }
    `,
  },
  {
    direction: 'forward',
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      }
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      return {
        count,
        cursor,
        first: getPageSize(),
        after: props.search.viewer.beds.pageInfo.endCursor,
        getSettingsRoles: props.search.viewer.beds.pageInfo.endCursor === null,
        type: 'ESCALATIONS', //props.type,
        query: props.escalationFilterQuery,
      }
    },
    query: graphql`
        query EscalationListPaginationContainerQuery(
            $first: Int
            $after: String
            $getSettingsRoles: Boolean!
            $type: SearchType
            $query: String
        ) {
            ...EscalationListPaginationContainer_search
            @arguments(first: $first, after: $after, getSettingsRoles: $getSettingsRoles, type: $type, query: $query)
        }
    `,
  }
)
