import React, {useState} from 'react'
import {handleMutation} from '../../graphql/relay/queries/mutation'
import {environment} from '../../graphql/relay/env'
import {DrawerHeaderBar} from '../../components/drawer'
import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import TextField from '../../components/common/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import styled, {css} from 'styled-components'
import Button from '@material-ui/core/Button'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {updaterForBarrierDetailInSourceTypes} from "../../graphql/relay/queries/barrierSourceType"
import {newEntryCodeTableUpdater, updateEntryCodeTableUpdater} from "../../graphql/relay/queries/codeTable"

const CodeTableDrawer = ({
       title,
       isEdit,
       data,
       mutation,
       onRequestClose,
       onError}) => {

  const [code, setCode] = useState(data.code)
  const [value, setValue] = useState(data.value)
  const [ordinal, setOrdinal] = useState(data.ordinal.toString())
  const [active, setActive] = useState(data.active)

  const handleCodeBlur = (event) => {
    const value = event.target.value
    const trimmedValue = value.trim()

    setCode(trimmedValue)
  }

  const onClickApply = () => {
    const variables = {
      code: code,
      codeTableType: data.codeTableType,
      codeTableInput: {
        code,
        description: value,
        sortOrder: ordinal,
        active
      }
    }

    let updater
    if (!isEdit) {
      delete variables.code
      updater = (proxyStore) => {
        newEntryCodeTableUpdater(proxyStore, variables)
      }
    } else {
      updater = (proxyStore) => {
        if (variables.codeTableType === 'BarrierSourceDetail') {
          updaterForBarrierDetailInSourceTypes(proxyStore, { code, value, ordinal, active})
        }
        updateEntryCodeTableUpdater(proxyStore, variables)
      }
    }

    handleMutation(
      environment,
      mutation,
      variables,
      () => {
        onRequestClose && onRequestClose()
      },
      null,
      onError,
      updater
    )
  }

  const noValueChanged = (code === data.code)
    && (value === data.value)
    && (ordinal === data.ordinal.toString())
    && (active === data.active)

  const anyValueUnPopulated = !code || !value || !ordinal

  const applyDisabled = isEdit ? (noValueChanged || anyValueUnPopulated) : anyValueUnPopulated

  const drawerActions = [
    <StyledApplyButton
      key="apply"
      disabled={applyDisabled}
      onClick={onClickApply}
    >Apply</StyledApplyButton>,
  ]

  return (
    <div>
      <DrawerHeaderBar
        title={title}
        iconElementLeft={
          <IconButton onClick={onRequestClose}>
            <NavigationClose />
          </IconButton>
        }
        actions={drawerActions}
      />
      <FieldSection>
        <StyledTextFieldWrapper>
          <TextField
            value={code}
            label={'Code (Required)'}
            maxChars={30}
            disabled={isEdit}
            onChange={event => setCode(event.target.value)}
            onBlur={handleCodeBlur}
            fullWidth
            showCharacterCount
          />
        </StyledTextFieldWrapper>
        <StyledTextFieldWrapper>
          <TextField
            value={value}
            label={'Description (Required)'}
            maxChars={255}
            onChange={event => setValue(event.target.value)}
            fullWidth
            showCharacterCount
          />
        </StyledTextFieldWrapper>
        <StyledTextFieldWrapper>
          <TextField
            value={ordinal}
            label={'Sort Order (Required)'}
            maxChars={3}
            onChange={event => setOrdinal(event.target.value)}
            fullWidth
            type={'number'}
          />
        </StyledTextFieldWrapper>
        <FormControlLabel
          control={<Switch color={'primary'} checked={active} onChange={event => setActive(event.target.checked)} />}
          label="Active"
        />
      </FieldSection>
    </div>
  )
}

const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: 60%;
`

const StyledTextFieldWrapper = styled.div`
  margin-bottom: 20px;
`

const StyledApplyButton = styled(Button)`
  && {
    font-weight: 500;
    text-transform: uppercase;

    ${props => !props.disabled && css`
      color: white;
    `}
  }
`

CodeTableDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    code: PropTypes.string,
    value: PropTypes.string,
    ordinal: PropTypes.number,
    active: PropTypes.bool,
  }),
  mutation: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onError: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {},
      dispatch
    ),
  }
}

export default connect(null, mapDispatchToProps)(CodeTableDrawer)
