/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BarrierStatus = "Open" | "Resolved" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type note_FullBarrier$ref: FragmentReference;
declare export opaque type note_FullBarrier$fragmentType: note_FullBarrier$ref;
export type note_FullBarrier = {|
  +id: string,
  +name: string,
  +description: string,
  +status: BarrierStatus,
  +pinned: boolean,
  +auditMeta: ?{|
    +created: any,
    +createdBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
    +updated: any,
    +updatedBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
  |},
  +category: {|
    +code: string,
    +value: string,
  |},
  +escalation: ?{|
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |}
  |},
  +owner: ?string,
  +ownerRole: ?{|
    +code: ?string,
    +value: ?string,
  |},
  +notes: ?$ReadOnlyArray<?{|
    +id: string,
    +text: string,
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +updated: any,
      +updatedBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |},
    +pinned: boolean,
  |}>,
  +$refType: note_FullBarrier$ref,
|};
export type note_FullBarrier$data = note_FullBarrier;
export type note_FullBarrier$key = {
  +$data?: note_FullBarrier$data,
  +$fragmentRefs: note_FullBarrier$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditUser",
      "kind": "LinkedField",
      "name": "updatedBy",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "note_FullBarrier",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v1/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BarrierCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Escalation",
      "kind": "LinkedField",
      "name": "escalation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditMeta",
          "kind": "LinkedField",
          "name": "auditMeta",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CodeTablePair",
      "kind": "LinkedField",
      "name": "ownerRole",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BarrierNote",
      "kind": "LinkedField",
      "name": "notes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        (v5/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Barrier",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a7e851fbd08b8544da53cdbab38b2bc6';

module.exports = node;
