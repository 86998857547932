import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { red400 } from 'material-ui/styles/colors'

// todo: later we can add a check to see if a Date instance was passed or a string.
export const Date = ({
  dateString,
  format = 'MMM D HH:mm',
  defaultValue = '',
  highlightIfPast = false,
}) => {
  const past = highlightIfPast ? moment(dateString).isBefore(moment()) : false

  return (
    <span style={{ color: past ? red400 : '' }}>
      {dateString ? moment(dateString).format(format) : defaultValue}
    </span>
  )
}

Date.propTypes = {
  dateString: PropTypes.string,
  highlightIfPast: PropTypes.bool,
  defaultValue: PropTypes.string,
  format: PropTypes.string,
}
