import moment from 'moment'
import {getConfig, computeThresholdMinutes} from "./configs"

export const computeThresholds = () => {
  return {
    inPlanThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.inpatient.withoutPlan.threshold.time')),
    outPlanThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.out/obs.withoutPlan.threshold.time')),
    inDrgThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.inpatient.withoutDRG.threshold.time')),
    inPSThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.inpatient.withoutProgressionStatus.threshold.time')),
    outPSThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.out/obs.withoutProgressionStatus.threshold.time')),
    inWDDThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.inpatient.withoutWorkingDischargeDisposition.threshold.time')),
    outWDDThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.out/obs.withoutWorkingDischargeDisposition.threshold.time')),
    inPlanDischargeTimeThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.inpatient.withoutPlanDischargeTime.threshold.time')),
    outPlanDischargeTimeThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.out/obs.withoutPlanDischargeTime.threshold.time')),
    planDischargeTimeSurpassedThreshold: computeThresholdMinutes(getConfig(
      'ca.compliance.planDischargeTimeSurpassed.threshold.time')),
  }
}

const findPlanDischargeIssue = (
  inPatient,
  bed,
  visit,
  thresholds,
  compliance
) => {
  const targetDischargeTime = visit.targetDischargeTime
  const plannedDischargeTime = visit.anticipatedDischargeTime
  const anticipatedTransferTime = visit.anticipatedTransferTime
  const decisionTime = visit.decisionTime
  const status = visit.bedRequestStatus
  const pendingOrReadyForDischarge =
    status === 'DischargeOrderWithDelay' || status === 'PendingDischarge' || status === 'ReadyForDischarge'
  const plannedDischargeNotNeeded =
    bed.status === 'Reserved' || bed.status === 'Swapped'
  const allUnitTransfer = ['AnticipatedUnitTransfer', 'PendingUnitTransfer', 'ReadyForUnitTransfer']
  const hasUnitTransferIssue = !(anticipatedTransferTime || allUnitTransfer.includes(status))

  const now = moment()

  if (inPatient && !plannedDischargeNotNeeded && targetDischargeTime && hasUnitTransferIssue) {
    // Check for Issue 1 inpatient
    // targetDischargeTime within X(24 hours) AND NO plannedDischargeTime
    // Inpatients that have a target discharge time within 24 hours and do not have a planned discharge time entered
    //    Configure a time for all all Inpatients that have a target discharge time
    //    within XX hours and do not have a planned discharge time entered. Default is 24
    const withinThreshold =
      moment(targetDischargeTime).diff(now, 'minute') <
      thresholds.inPlanDischargeTimeThreshold
    if (withinThreshold && !plannedDischargeTime) {
      // console.log('Issue 1', visit.patient.lastName)
      compliance.noPlanDischarge = true
      compliance.planDischargeIssue = '1'
      return
    }
  }

  if (!inPatient && decisionTime && hasUnitTransferIssue) {
    // Check for Issue 2 - out/obs
    // timeToDecision < Y(8 hours) AND NO plannedDischargeTime
    // Observation and Outpatients that have a time to decision of less than 8 hours and do not have a planned discharge time entered
    //     Configure a time for all Observation and Outpatients that have a time to decision
    //     of less than XX hours and do not have a planned discharge time entered. Default is 8
    const withinThreshold =
      moment(decisionTime).diff(now, 'minute') <
      thresholds.outPlanDischargeTimeThreshold
    if (withinThreshold && !plannedDischargeTime) {
      // console.log('Issue 2', visit.patient.lastName)
      compliance.noPlanDischarge = true
      compliance.planDischargeIssue = '2'
      return
    }
  }

  if (plannedDischargeTime) {
    // Check for Issue 3 - all
    //     Configure all patients that have a planned discharge time greater than XX hours in the past. Default is 2
    const diff = moment(plannedDischargeTime).diff(now, 'minute')
    if (diff < 0) {
      const withinThreshold =
        Math.abs(diff) > thresholds.planDischargeTimeSurpassedThreshold
      if (withinThreshold) {
        compliance.noPlanDischarge = false
        compliance.planDischargeInPast = true
        if(hasUnitTransferIssue){
          compliance.planDischargeIssue = '3'
          return
        }
      }
    }
  }

  if (pendingOrReadyForDischarge && !plannedDischargeTime && hasUnitTransferIssue) {
    // Check for Issue 4 - all
    // (PendingDischarge OR ReadyDischarge) AND NO plannedDischargeTime
    // Patients with a Pending Discharge or Ready Discharge status and no planned discharge time entered

    // console.log('Issue 4', visit.patient.lastName)
    compliance.noPlanDischarge = true
    compliance.planDischargeIssue = '4'
    return
  }

  compliance.noPlanDischarge = plannedDischargeTime === null
  compliance.planDischargeIssue = false
}

export const findComplianceIssues = (bed, visit, plan,  thresholds) => {
  let compliance = {
    noPlan: true /* in>8 outObs>4 */,
    noPlanIssue: true /* in>8 outObs>4 */,
    noDRG: true /* in>8 */,
    noDRGIssue: true /* in>8 */,
    noWDD: true /* in>24 outObs>4 */,
    noWDDIssue: true /* in>24 outObs>4 */,
    noPS: true /* in>24 outObs>4 */,
    noPSIssue: true /* in>24 outObs>4 */,
  }

  if (!thresholds) {
    thresholds = computeThresholds()
  }

  // CA-2458 - there can be milestones left in plan after all removed
  let noPlan = plan.appliedTemplates && plan.appliedTemplates.filter(template => !template.removed).length === 0

  let noDRG = visit.drgAttributes === null
  let noWDD = visit.workingDischargeDisposition.code === 'NotSet'
  let noPS = plan.progressionIndicator === 'NotSet'

  let inPatient = visit.admissionType === 'InPatient'

  const timeElapsedSinceAdmission = visit.meta.timeElapsedSinceAdmission //minutes

  let inPlanIssue = timeElapsedSinceAdmission > thresholds.inPlanThreshold
  let outPlanIssue = timeElapsedSinceAdmission > thresholds.outPlanThreshold
  let inDrgIssue = timeElapsedSinceAdmission > thresholds.inDrgThreshold
  let inPSIssue = timeElapsedSinceAdmission > thresholds.inPSThreshold
  let outPSIssue = timeElapsedSinceAdmission > thresholds.outPSThreshold
  let inWDDIssue = timeElapsedSinceAdmission > thresholds.inWDDThreshold
  let outWDDIssue = timeElapsedSinceAdmission > thresholds.outWDDThreshold

  if (inPatient) {
    compliance.noPlan = noPlan
    compliance.noPlanIssue = noPlan && inPlanIssue
    compliance.noDRG = noDRG
    compliance.noDRGIssue = noDRG && inDrgIssue
    compliance.noWDD = noWDD
    compliance.noWDDIssue = noWDD && inWDDIssue
    compliance.noPS = noPS
    compliance.noPSIssue = noPS && inPSIssue
  } else {
    compliance.noPlan = noPlan
    compliance.noPlanIssue = noPlan && outPlanIssue
    compliance.noDRG = false // Outpatient/Observation DRG is not a compliance issue
    compliance.noDRGIssue = false // Outpatient/Observation DRG is not a compliance issue
    compliance.noWDD = noWDD
    compliance.noWDDIssue = noWDD && outWDDIssue
    compliance.noPS = noPS
    compliance.noPSIssue = noPS && outPSIssue
  }

  findPlanDischargeIssue(inPatient, bed, visit, thresholds, compliance)

  compliance.hasIssue =
    compliance.noPlanIssue ||
    compliance.noDRGIssue ||
    compliance.noWDDIssue ||
    compliance.noPSIssue ||
    compliance.planDischargeIssue

  return compliance
}
