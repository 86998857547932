import React from 'react'
import PropTypes from 'prop-types'

const {
  string,
  array,
  object,
  number,
  bool,
  instanceOf,
  oneOf,
  oneOfType,
  arrayOf,
  objectOf,
  shape,
} = PropTypes

export const workspace = shape({
  bed: shape(bed),
  plan: shape(plan),
  visit: shape(visit),
  facility: shape(facility),
  department: shape(department),
})

export const workspaces = arrayOf(workspace)

export const bed = shape({
  assigned: bool,
  conflictingVisitIds: arrayOf(string),
  exchangeName: string,
  visitId: number,
  dischargedVisitId: string,
  name: string,
  providers: array,
})

export const plan = shape({
  appliedTemplates: array,
  careTeamMembers: array,
  progressionIndicator: string,
  milestones: array,
  barriers: array,
  actions: array,
  notes: array,
})

export const facility = shape({
  exchangeName: string,
  ordinal: number,
  departments: arrayOf(shape(department)),
})

export const visit = shape({
  id: number.isRequired,
  visitNumber: string,
  homeBedExchangeName: string,
  bedConflict: object,
  admissionType: string,
  admissionTime: string,
  patientProblem: object,
  admittingComplaint: string,
  bedRequestStatus: string,
  visitStatus: string,
  anticipatedDischargeTime: string,
  anticipatedDischargeUpdatedWhen: string,
  anticipatedDischargeUpdatedBy: string,
  targetDischargeTime: string,
  decisionTime: string,
  decisionTimeEditable: bool,
  decisionTimeUseMidnight: bool,
  tlos: number,
  currentDayOfStay: number,
  readmissionRisk: bool,
  readmissionRiskVerifiedBy: string,
  readmissionRiskUpdatedWhen: string,
  readmission: string,
  readmissionUpdatedWhen: string,
  readmissionVerifiedBy: string,
  patientAlerts: arrayOf(shape(alert)),
  insurancePlan: arrayOf(shape(alert)),
  drgAttributes: string,
  drgUpdatedBy: string,
  drgUpdatedWhen: string,
  patient: shape(patient),
  workingDischargeDisposition: shape(codeValueObject),
  dischargeVendor: string,
  admittingPhysician: shape(personnelRole),
  attendingPhysician: shape(personnelRole),
  consultingPhysicians: arrayOf(shape(personnelRole)),
  transferDate: string,
  anticipatedDischargeTimeAutoPopulated: bool,
  anticipatedTransferTimeAutoPopulated: bool
})

export const personnelRole = shape({
  id: number,
  firstName: string,
  lastName: string,
  primary: bool,
  role: shape(codeValueObject),
})

export const alert = shape({
  alertType: string,
  value: string,
})

export const codeValueObject = shape({
  code: string,
  value: string,
})

export const insurancePlan = shape({
  planName: string,
  cms: bool,
})

export const patient = shape({
  age: string,
  firstName: string,
  lastName: string,
  middleInitials: string,
  mrn: string,
})

export const department = shape({
  type: string,
  exchangeName: string,
  name: string,
  beds: arrayOf(shape(bed)),
  includedInLosCalculation: bool,
})
