/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AdmissionType = "InPatient" | "Observation" | "OutPatient" | "%future added value";
export type DepartmentType = "AdmissionSource" | "Unit" | "%future added value";
export type ConflictedPatientListQueryVariables = {||};
export type ConflictedPatientListQueryResponse = {|
  +viewer: {|
    +beds: {|
      +totalCount: number,
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
        +hasPreviousPage: boolean,
        +startCursor: ?string,
        +totalCount: string,
      |},
      +edges: $ReadOnlyArray<{|
        +bed: {|
          +name: string,
          +exchangeName: string,
          +status: string,
          +conflicts: $ReadOnlyArray<{|
            +id: string,
            +created: any,
            +createdBy: ?{|
              +userName: string,
              +firstName: string,
              +lastName: string,
            |},
            +visit: {|
              +id: string,
              +attendingPhysician: ?{|
                +id: string,
                +firstName: ?string,
                +lastName: ?string,
              |},
              +visitNumber: ?string,
              +admissionType: ?AdmissionType,
              +plan: ?{|
                +id: string,
                +careTeamMembers: $ReadOnlyArray<?{|
                  +id: string,
                  +name: ?string,
                  +role: {|
                    +id: string,
                    +code: ?string,
                    +value: ?string,
                  |},
                  +primary: ?boolean,
                |}>,
              |},
              +patient: {|
                +gender: ?string,
                +age: ?string,
                +mrn: ?string,
                +firstName: ?string,
                +lastName: ?string,
              |},
            |},
          |}>,
          +department: {|
            +name: string,
            +type: DepartmentType,
          |},
        |}
      |}>,
    |}
  |}
|};
export type ConflictedPatientListQuery = {|
  variables: ConflictedPatientListQueryVariables,
  response: ConflictedPatientListQueryResponse,
|};
*/


/*
query ConflictedPatientListQuery {
  viewer {
    beds(first: 10000, type: PATIENT, query: "allbeds") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        totalCount
      }
      edges {
        bed: node {
          name
          exchangeName
          status
          conflicts {
            id
            created
            createdBy {
              userName
              firstName
              lastName
              id
            }
            visit {
              id
              attendingPhysician {
                id
                firstName
                lastName
              }
              visitNumber
              admissionType
              plan {
                id
                careTeamMembers {
                  id
                  name
                  role {
                    id
                    code
                    value
                  }
                  primary
                }
              }
              patient {
                gender
                age
                mrn
                firstName
                lastName
                id
              }
            }
          }
          department {
            name
            type
            id
          }
          id
        }
        cursor
        node {
          __typename
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "query",
  "value": "allbeds"
},
v1 = {
  "kind": "Literal",
  "name": "type",
  "value": "PATIENT"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PersonnelRole",
  "kind": "LinkedField",
  "name": "attendingPhysician",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitNumber",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "admissionType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CareTeamMember",
      "kind": "LinkedField",
      "name": "careTeamMembers",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CodeTablePair",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primary",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "age",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mrn",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v22 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConflictedPatientListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "beds",
            "args": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "concreteType": "BedConnection",
            "kind": "LinkedField",
            "name": "__ConflictedPatientList_beds_connection",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BedEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": "bed",
                    "args": null,
                    "concreteType": "Bed",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BedConflict",
                        "kind": "LinkedField",
                        "name": "conflicts",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuditUser",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Visit",
                            "kind": "LinkedField",
                            "name": "visit",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Patient",
                                "kind": "LinkedField",
                                "name": "patient",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Department",
                        "kind": "LinkedField",
                        "name": "department",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v19/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bed",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "__ConflictedPatientList_beds_connection(query:\"allbeds\",type:\"PATIENT\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConflictedPatientListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v22/*: any*/),
            "concreteType": "BedConnection",
            "kind": "LinkedField",
            "name": "beds",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BedEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": "bed",
                    "args": null,
                    "concreteType": "Bed",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BedConflict",
                        "kind": "LinkedField",
                        "name": "conflicts",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuditUser",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Visit",
                            "kind": "LinkedField",
                            "name": "visit",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Patient",
                                "kind": "LinkedField",
                                "name": "patient",
                                "plural": false,
                                "selections": [
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Department",
                        "kind": "LinkedField",
                        "name": "department",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v19/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Bed",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v21/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "beds(first:10000,query:\"allbeds\",type:\"PATIENT\")"
          },
          {
            "alias": null,
            "args": (v22/*: any*/),
            "filters": [
              "type",
              "query"
            ],
            "handle": "connection",
            "key": "ConflictedPatientList_beds",
            "kind": "LinkedHandle",
            "name": "beds"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5798fa3ac42b5f2c82d62f0001218edf",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "viewer",
            "beds"
          ]
        }
      ]
    },
    "name": "ConflictedPatientListQuery",
    "operationKind": "query",
    "text": "query ConflictedPatientListQuery {\n  viewer {\n    beds(first: 10000, type: PATIENT, query: \"allbeds\") {\n      totalCount\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n        totalCount\n      }\n      edges {\n        bed: node {\n          name\n          exchangeName\n          status\n          conflicts {\n            id\n            created\n            createdBy {\n              userName\n              firstName\n              lastName\n              id\n            }\n            visit {\n              id\n              attendingPhysician {\n                id\n                firstName\n                lastName\n              }\n              visitNumber\n              admissionType\n              plan {\n                id\n                careTeamMembers {\n                  id\n                  name\n                  role {\n                    id\n                    code\n                    value\n                  }\n                  primary\n                }\n              }\n              patient {\n                gender\n                age\n                mrn\n                firstName\n                lastName\n                id\n              }\n            }\n          }\n          department {\n            name\n            type\n            id\n          }\n          id\n        }\n        cursor\n        node {\n          __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cd64364c662f2a6ab4b730b742452691';

module.exports = node;
