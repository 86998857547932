import React from 'react'

import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'


const CustomRoundIcon = (props) => {
  return (
    <Tooltip title={props.tooltip} placement={'bottom-end'}>
      <OuterCircle onClick={props.onClick}>
        <InnerCircle>
          <IconImage
            src={props.icon}
            $marginRight={props.customIconCss?.marginRight}
            $size={props.customIconCss?.size}
          />
        </InnerCircle>
      </OuterCircle>
    </Tooltip>
  )
}

const OuterCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-left: 6px;
  background-color: #F0F0F0;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
`

const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0095CB;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  z-index: 20;
`

const IconImage = styled.img`
  height: ${props => props.$size ? props.$size : '18px'};
  width: ${props => props.$size ? props.$size : '18px'};
  margin-right: ${props => props.$marginRight}
`

CustomRoundIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  customIconCss: PropTypes.object
}

export default CustomRoundIcon
