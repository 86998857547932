const SET_FILTER = 'care-advance/plan-todo-filter/SET_FILTER'
const SET_DRAFT_FILTER = 'care-advance/plan-todo-filter/SET_DRAFT_FILTER'
const RESET_FILTER = 'care-advance/plan-todo-filter/RESET_FILTER'

const initialState = {
  filter: {
    dueDate: 'Current',
    track: 'All',
    status: 'All'
  },
  draftFilter: {
    dueDate: 'Current',
    track: 'All',
    status: 'All'
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILTER:
      return {
        ...state,
        filter: { ...action.filter },
        draftFilter: { ...action.filter}
      }
    case SET_DRAFT_FILTER:
      return {
        ...state,
        draftFilter: { ...action.draftFilter }
      }
    case RESET_FILTER:
      return {
        ...state,
        filter: initialState.filter,
        draftFilter: initialState.draftFilter
      }
    default:
      return state
  }
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    filter: filter,
  }
}

export function setDraftFilter(draftFilter) {
  return {
    type: SET_DRAFT_FILTER,
    draftFilter: draftFilter
  }
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  }
}

export default reducer
