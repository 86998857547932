/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BoardTemplateRowList_list$ref = any;
export type boardTemplate_deleteBoardTemplateEntry_MutationVariables = {|
  boardTemplateId: any
|};
export type boardTemplate_deleteBoardTemplateEntry_MutationResponse = {|
  +boardTemplateMutation: ?{|
    +deleteBoardTemplateEntry: ?$ReadOnlyArray<?{|
      +$fragmentRefs: BoardTemplateRowList_list$ref
    |}>
  |}
|};
export type boardTemplate_deleteBoardTemplateEntry_Mutation = {|
  variables: boardTemplate_deleteBoardTemplateEntry_MutationVariables,
  response: boardTemplate_deleteBoardTemplateEntry_MutationResponse,
|};
*/


/*
mutation boardTemplate_deleteBoardTemplateEntry_Mutation(
  $boardTemplateId: UUID!
) {
  boardTemplateMutation {
    deleteBoardTemplateEntry(boardTemplateId: $boardTemplateId) {
      ...BoardTemplateRowList_list
      id
    }
  }
}

fragment BoardTemplateRowList_list on BoardTemplate {
  ...BoardTemplateRow_rowData
}

fragment BoardTemplateRow_rowData on BoardTemplate {
  id
  name
  boardType
  description
  template
  columns {
    field
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardTemplateId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardTemplateId",
    "variableName": "boardTemplateId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "boardTemplate_deleteBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTemplateMutation",
        "kind": "LinkedField",
        "name": "boardTemplateMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BoardTemplate",
            "kind": "LinkedField",
            "name": "deleteBoardTemplateEntry",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BoardTemplateRowList_list"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardTemplate_deleteBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTemplateMutation",
        "kind": "LinkedField",
        "name": "boardTemplateMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BoardTemplate",
            "kind": "LinkedField",
            "name": "deleteBoardTemplateEntry",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "boardType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "template",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardColumnConfig",
                "kind": "LinkedField",
                "name": "columns",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "field",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b33e6cc7534ab97640de4f11e3616e3d",
    "id": null,
    "metadata": {},
    "name": "boardTemplate_deleteBoardTemplateEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation boardTemplate_deleteBoardTemplateEntry_Mutation(\n  $boardTemplateId: UUID!\n) {\n  boardTemplateMutation {\n    deleteBoardTemplateEntry(boardTemplateId: $boardTemplateId) {\n      ...BoardTemplateRowList_list\n      id\n    }\n  }\n}\n\nfragment BoardTemplateRowList_list on BoardTemplate {\n  ...BoardTemplateRow_rowData\n}\n\nfragment BoardTemplateRow_rowData on BoardTemplate {\n  id\n  name\n  boardType\n  description\n  template\n  columns {\n    field\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3ad2fd47e6da88481292e2cf1e72e6eb';

module.exports = node;
