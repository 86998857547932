export const calcNewFilter = (filterType, selectedPersonnelId, workspaces) => {
  let personnelRoles, personnelId

  switch (filterType) {
    case 'all':
      personnelRoles = []
      break

    case 'attending':
      personnelRoles = findAllAttendingPhysicians(workspaces)
      personnelId = findPersonnelId(selectedPersonnelId, personnelRoles)
      break

    case 'nurse':
      personnelRoles = findAllPrimaryNurses(workspaces)
      personnelId = findPersonnelId(selectedPersonnelId, personnelRoles)
      break
  }

  return {
    filterType: filterType,
    selectedPersonnelId: personnelId,
  }
}

const findPersonnelId = (selectedPersonnelId, personnelRoles) => {
  const personnelIds = personnelRoles.map(personnel => personnel.id)

  if (personnelIds.includes(selectedPersonnelId)) {
    return selectedPersonnelId
  } else if (personnelRoles.length > 0) {
    return personnelRoles[0].id
  }
}

export const findPersonnels = (filterType, workspaces) => {
  switch (filterType) {
    case 'all':
      return []
    case 'attending':
      return findAllAttendingPhysicians(workspaces)
    case 'nurse':
      return findAllPrimaryNurses(workspaces)
  }
}

export const findPersonnelWorkspaces = (
  filterType,
  personnelId,
  workspaces
) => {
  switch (filterType) {
    case 'all':
      return workspaces

    case 'attending':
      return findWorkspacesForAttendingPhysician(personnelId, workspaces)

    case 'nurse':
      return findWorkspacesForProvider(personnelId, workspaces)
  }
}

const findAllAttendingPhysicians = workspaces => {
  const physicians = []
  const physicianIds = []
  workspaces.forEach(workspace => {
    const physician = workspace.visit.attendingPhysician
    if (physician && !physicianIds.includes(physician.id)) {
      physicians.push(workspace.visit.attendingPhysician)
      physicianIds.push(physician.id)
    }
  })
  return physicians
}

const findAllPrimaryNurses = workspaces => {
  const nurses = []
  const nurseIds = []
  workspaces.forEach(workspace => {
    const provider1 = workspace.visit.provider1

    if (provider1 && !nurseIds.includes(provider1.id)) {
      nurses.push(provider1)
      nurseIds.push(provider1.id)
    }
  })

  workspaces.forEach(workspace => {
    workspace.plan.careTeamMembers && workspace.plan.careTeamMembers.forEach(careTeamMember => {
      if (careTeamMember.primary && !nurseIds.includes(careTeamMember.id)) {
        nurses.push(careTeamMember)
        nurseIds.push(careTeamMember.id)
      }
    })
  })
  return nurses
}

const findWorkspacesForProvider = (providerId, workspaces) => {
  return workspaces.filter(
    workspace =>
      workspace.visit.provider1 && workspace.visit.provider1.id === providerId ||
      workspace.plan.careTeamMembers && workspace.plan.careTeamMembers.some(
        member => member.primary && member.id === providerId
      )
  )
}

const findWorkspacesForAttendingPhysician = (
  attendingPhysicianId,
  workspaces
) => {
  return workspaces.filter(
    workspace =>
      workspace.visit.attendingPhysician &&
      workspace.visit.attendingPhysician.id === attendingPhysicianId
  )
}
