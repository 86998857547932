import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import KnownPersonnelTypeAhead from '../../knownPersonnelTypeAhead/KnownPersonnelTypeAhead'
import { BARRIER_OWNER_ROLE, buildKnownUserInput, searchKnownUsers } from '../../../../graphql/relay/queries/knownUser'
import { fetchQuery } from 'react-relay'
import { environment } from '../../../../graphql/relay/env'

class BarrierOwnerPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedBarrierOwnerName: null
    }
  }

  onSearchAssignedBarriersKnownPersonnel = async (value) => {
    const knownUserInput = buildKnownUserInput({ searchText: value, roles: [BARRIER_OWNER_ROLE] })
    const response = await fetchQuery(environment, searchKnownUsers, { knownUserInput })
    return response.knownUsers
  }

  onBarrierOwnerNameChange = (name, knownUser) => {
    const { onSelect } = this.props
    this.setState({
      selectedBarrierOwnerName: name
    })
    if (typeof onSelect === 'function') {
      onSelect(knownUser)
    }
  }

  render() {
    const { className } = this.props
    const { selectedBarrierOwnerName } = this.state

    return (
      <div className={className}>
      <KnownPersonnelTypeAhead
        label="Owner Name (Optional)"
        variant="outlined"
        size="small"
        showSubtext={false}
        isShowIrisIcon
        clearTextOnSelect
        onSearchKnownPersonnel={this.onSearchAssignedBarriersKnownPersonnel}
        onResultSelected={this.onBarrierOwnerNameChange}
        selectedValue={selectedBarrierOwnerName}
        InputLabelProps={{
          style: {
            // marginLeft: '32px'
          }
        }}
        />
      </div>
    )
  }
}

BarrierOwnerPicker.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func
}

export default BarrierOwnerPicker
