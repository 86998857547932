import React from 'react'
import PropTypes from 'prop-types'
import { StatefulTextField } from '../../common'

const AddToDo = ({ onCreatePlanAction }) => {
  return (
    <div
      style={{ paddingLeft: 15, paddingRight: 15, marginTop: '1px' }}
      className="paper">
      <StatefulTextField
        maxNumberOfChars={255}
        textFieldProps={{
          fullWidth: true,
          hintText: 'Add To-Do',
        }}
        onEnter={onCreatePlanAction}
        showCharacterCount
      />
    </div>
  )
}

AddToDo.propTypes = {
  onCreatePlanAction: PropTypes.func
}

export default AddToDo
