/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updatePatientProblem_MutationVariables = {|
  visitId: any,
  patientProblem: string,
|};
export type visit_updatePatientProblem_MutationResponse = {|
  +visitMutation: ?{|
    +updatePatientProblem: ?{|
      +id: string,
      +patientProblem: ?string,
    |}
  |}
|};
export type visit_updatePatientProblem_Mutation = {|
  variables: visit_updatePatientProblem_MutationVariables,
  response: visit_updatePatientProblem_MutationResponse,
|};
*/


/*
mutation visit_updatePatientProblem_Mutation(
  $visitId: Long!
  $patientProblem: String!
) {
  visitMutation {
    updatePatientProblem(visitId: $visitId, patientProblem: $patientProblem) {
      id
      patientProblem
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "patientProblem"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VisitMutation",
    "kind": "LinkedField",
    "name": "visitMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "patientProblem",
            "variableName": "patientProblem"
          },
          {
            "kind": "Variable",
            "name": "visitId",
            "variableName": "visitId"
          }
        ],
        "concreteType": "Visit",
        "kind": "LinkedField",
        "name": "updatePatientProblem",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "patientProblem",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updatePatientProblem_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updatePatientProblem_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5175818e17f82b0478149ad8a4200feb",
    "id": null,
    "metadata": {},
    "name": "visit_updatePatientProblem_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updatePatientProblem_Mutation(\n  $visitId: Long!\n  $patientProblem: String!\n) {\n  visitMutation {\n    updatePatientProblem(visitId: $visitId, patientProblem: $patientProblem) {\n      id\n      patientProblem\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4d552dc9524f534e90b546d637812fb';

module.exports = node;
