/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateWorkingDischargeDisposition_MutationVariables = {|
  visitId: any,
  dispositionCode: string,
  dischargeVendor: string,
|};
export type visit_updateWorkingDischargeDisposition_MutationResponse = {|
  +visitMutation: ?{|
    +updateWorkingDischargeDisposition: ?{|
      +id: string,
      +workingDischargeDispositionUpdatedWhen: ?any,
      +workingDischargeDispositionUpdatedBy: ?{|
        +id: string,
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +workingDischargeDisposition: ?{|
        +id: string,
        +code: ?string,
        +value: ?string,
      |},
      +dischargeVendor: ?string,
    |}
  |}
|};
export type visit_updateWorkingDischargeDisposition_Mutation = {|
  variables: visit_updateWorkingDischargeDisposition_MutationVariables,
  response: visit_updateWorkingDischargeDisposition_MutationResponse,
|};
*/


/*
mutation visit_updateWorkingDischargeDisposition_Mutation(
  $visitId: Long!
  $dispositionCode: String!
  $dischargeVendor: String!
) {
  visitMutation {
    updateWorkingDischargeDisposition(visitId: $visitId, dispositionCode: $dispositionCode, dischargeVendor: $dischargeVendor) {
      id
      workingDischargeDispositionUpdatedWhen
      workingDischargeDispositionUpdatedBy {
        id
        userName
        firstName
        lastName
      }
      workingDischargeDisposition {
        id
        code
        value
      }
      dischargeVendor
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeVendor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dispositionCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "VisitMutation",
    "kind": "LinkedField",
    "name": "visitMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "dischargeVendor",
            "variableName": "dischargeVendor"
          },
          {
            "kind": "Variable",
            "name": "dispositionCode",
            "variableName": "dispositionCode"
          },
          {
            "kind": "Variable",
            "name": "visitId",
            "variableName": "visitId"
          }
        ],
        "concreteType": "Visit",
        "kind": "LinkedField",
        "name": "updateWorkingDischargeDisposition",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workingDischargeDispositionUpdatedWhen",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuditUser",
            "kind": "LinkedField",
            "name": "workingDischargeDispositionUpdatedBy",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkingDischargeDispositionDTO",
            "kind": "LinkedField",
            "name": "workingDischargeDisposition",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dischargeVendor",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateWorkingDischargeDisposition_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateWorkingDischargeDisposition_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "0eb078a744006f38e234242bdb99591d",
    "id": null,
    "metadata": {},
    "name": "visit_updateWorkingDischargeDisposition_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateWorkingDischargeDisposition_Mutation(\n  $visitId: Long!\n  $dispositionCode: String!\n  $dischargeVendor: String!\n) {\n  visitMutation {\n    updateWorkingDischargeDisposition(visitId: $visitId, dispositionCode: $dispositionCode, dischargeVendor: $dischargeVendor) {\n      id\n      workingDischargeDispositionUpdatedWhen\n      workingDischargeDispositionUpdatedBy {\n        id\n        userName\n        firstName\n        lastName\n      }\n      workingDischargeDisposition {\n        id\n        code\n        value\n      }\n      dischargeVendor\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c9e710a9d47de7d9f285382d5fe069e';

module.exports = node;
