import React from 'react'

import ComplianceIcon from 'mdi-react/FormatListChecksIcon'
import Hotel from 'mdi-react/HotelIcon'
import PlanIcon from 'mdi-react/AvTimerIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import AccountSettingsIcon from 'mdi-react/AccountSettingsIcon'
import Assignment from 'mdi-react/ClipboardTextIcon'
import Info from 'mdi-react/InformationOutlineIcon'
import LogoutIcon from 'mdi-react/LogoutIcon'
import KpiIcon from 'mdi-react/ChartBarIcon'
import KpiOverviewIcon from 'mdi-react/TrendingUpIcon'
import ConflictedPatientIcon from 'mdi-react/FormatHorizontalAlignCenterIcon'
import UnknownRouteIcon from 'mdi-react/AlertDecagramIcon'
import Stairs from 'mdi-react/StairsIcon'
import {
  cyan500 as StandardHeaderBackgroundColor,
  cyan900 as StandardNavbarIconColor,
  white as StandardHeaderIconColor,
  white as StandardHeaderTextColor,
} from 'material-ui/styles/colors'

import { Route } from './types'

import {
  AppInfo,
  AssignBeds,
  ComplianceView,
  ConflictedPatientList,
  Error404,
  KpiOverview,
  KpiView,
  LoginBox,
  Logout,
  UserDashboard,
} from '../views'
import { EscalationListView } from '../views/EscalationList'
import { PlanListView } from '../views/PlanList'
import { PatientPlanGQL } from '../views/PatientPlan/PatientPlanGQL'
import { PatientPlanVNGQL} from "../views/PatientPlan/PatientPlanVNGQL"
import { PatientPlanBedGQL} from "../views/PatientPlan/PatientPlanBedGQL"
import { ConflictedPatientPlanGQL } from '../views/ConflictedPatientPlanGQL'
import Admin from "../views/Admin"

const indexRoute = {
      path: '/',
      redirect: 'my-patient-list',
      nav: false,
      menu: false,
      auth: true,
    }

export const routeConfig: Route[] = [
  // Inject the index or / route. Redirects to list view in production.
  indexRoute,

  // Care Advance regular user routes
  {
    path: '/my-patient-list',
    title: 'My Patient List',
    viewType: 'PlanList',
    component: PlanListView,
    svgIcon: Assignment,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: true,
  },
  {
    path: '/bed/:id',
    title: 'Patient Plan',
    viewType: 'Plan',
    component: PatientPlanBedGQL,
    svgIcon: PlanIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: false,
    auth: true,
  },
  {
    path: '/visit/:id',
    title: 'Patient Plan',
    viewType: 'Plan',
    component: PatientPlanGQL,
    svgIcon: PlanIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: false,
    auth: true,
  },
  {
    path: '/visitNumber/:id',
    title: 'Patient Plan',
    viewType: 'Plan',
    component: PatientPlanVNGQL,
    svgIcon: PlanIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: false,
    auth: true
  },

  {
    path: '/conflicted-visit/:id',
    title: 'Patient Plan',
    viewType: 'ConflictedPlan',
    component: ConflictedPatientPlanGQL,
    svgIcon: PlanIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: false,
    auth: true,
  },
  {
    path: '/escalation-list',
    title: 'Escalation Management',
    viewType: 'EscalationList',
    component: EscalationListView,
    svgIcon: Stairs,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: true,
  },
  {
    path: '/variance',
    title: 'Variance',
    viewType: 'Compliance',
    component: ComplianceView,
    svgIcon: ComplianceIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: true,
  },
  {
    path: '/los-forecast',
    title: 'Inpatient LOS Performance',
    viewType: 'KPI',
    component: KpiView,
    svgIcon: KpiIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: true,
  },
  {
    path: '/kpm-overview',
    title: 'KPM Overview',
    viewType: 'KPIOverview',
    component: KpiOverview,
    svgIcon: KpiOverviewIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: true,
  },
  {
    path: '/location-manager',
    title: 'Location Manager',
    viewType: 'ConflictedPatientList',
    component: ConflictedPatientList,
    svgIcon: ConflictedPatientIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: {
      disabledApplications: ['PATIENT_LOGISTICS'],
    },
  },
  {
    path: '/my-assignments',
    title: 'My Assignments',
    viewType: 'WorkArea',
    component: AssignBeds,
    svgIcon: Hotel,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: true,
  },
  {
    path: '/my-settings',
    title: 'My Settings',
    viewType: 'UserSetting',
    component: UserDashboard,
    svgIcon: AccountSettingsIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: true,
    auth: true,
  },
  {
    path: '/admin',
    title: 'Admin',
    viewType: 'Admin',
    component: Admin,
    svgIcon: SettingsIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: true,
    menu: false,
    auth: {
      assignedApplications: ['C3_ADMIN']
    }
  },
  {
    path: '/app-info',
    title: 'About Care Advance',
    viewType: 'AppInfo',
    component: AppInfo,
    svgIcon: Info,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: true,
    auth: true,
  },

  // Security routes
  {
    path: '/login',
    title: 'Login',
    viewType: 'Login',
    component: LoginBox,
    nav: false,
    menu: false,
    auth: false,
  },
  {
    path: '/logout',
    title: 'Logout',
    viewType: 'Logout',
    component: Logout,
    svgIcon: LogoutIcon,
    headerIconColor: StandardHeaderIconColor,
    navbarIconColor: StandardNavbarIconColor,
    headerTextColor: StandardHeaderTextColor,
    headerBackgroundColor: StandardHeaderBackgroundColor,
    nav: false,
    menu: true,
    auth: true,
  },

  // Catch all missing routes (Give a 404 page)
  {
    title: 'Unknown Destination',
    component: Error404,
    svgIcon: UnknownRouteIcon,
    nav: false,
    menu: false,
    auth: false,
  },
]
