/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type barrier_barrierSourceTypes_QueryVariables = {|
  barrierCategoryCode: string
|};
export type barrier_barrierSourceTypes_QueryResponse = {|
  +barrierSourceTypes: ?$ReadOnlyArray<?{|
    +id: string,
    +code: string,
    +value: string,
    +active: boolean,
    +activeDirectorySourceName: boolean,
    +hasConfiguredList: boolean,
    +sourceDetails: ?$ReadOnlyArray<?{|
      +code: string,
      +value: string,
      +active: boolean,
    |}>,
  |}>
|};
export type barrier_barrierSourceTypes_Query = {|
  variables: barrier_barrierSourceTypes_QueryVariables,
  response: barrier_barrierSourceTypes_QueryResponse,
|};
*/


/*
query barrier_barrierSourceTypes_Query(
  $barrierCategoryCode: String!
) {
  barrierSourceTypes(barrierCategoryCode: $barrierCategoryCode) {
    id
    code
    value
    active
    activeDirectorySourceName
    hasConfiguredList
    sourceDetails {
      code
      value
      active
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierCategoryCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "barrierCategoryCode",
    "variableName": "barrierCategoryCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeDirectorySourceName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasConfiguredList",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "barrier_barrierSourceTypes_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BarrierSourceType",
        "kind": "LinkedField",
        "name": "barrierSourceTypes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BarrierSourceDetail",
            "kind": "LinkedField",
            "name": "sourceDetails",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "barrier_barrierSourceTypes_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BarrierSourceType",
        "kind": "LinkedField",
        "name": "barrierSourceTypes",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BarrierSourceDetail",
            "kind": "LinkedField",
            "name": "sourceDetails",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d89d25eb0d543b4ebe5c28c53c4cdb74",
    "id": null,
    "metadata": {},
    "name": "barrier_barrierSourceTypes_Query",
    "operationKind": "query",
    "text": "query barrier_barrierSourceTypes_Query(\n  $barrierCategoryCode: String!\n) {\n  barrierSourceTypes(barrierCategoryCode: $barrierCategoryCode) {\n    id\n    code\n    value\n    active\n    activeDirectorySourceName\n    hasConfiguredList\n    sourceDetails {\n      code\n      value\n      active\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f54dee397b64a7e7f216c356aebaf10';

module.exports = node;
