import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('Error404', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    errorPageInfo: {
      margin: '0 auto',
      width: '50%',
      position: 'relative',
      transform: 'translateY(50%)',
    },
  }
})

export class Error404 extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)
    return (
      <div className={classNames(classes.errorPageInfo, 'error-404')}>
        <h4>Unable to access requested view.</h4>
        <h6 className="text-muted">
          View: <a href="#">{this.props.location.pathname}</a>
        </h6>
        <div className="well">
          <h5>Possible Reasons:</h5>
          <ul>
            <li>Requested page does not or no longer exists.</li>
            <li>The provided link was invalid or broken.</li>
            <li>Typographical error when entering the URL.</li>
          </ul>
        </div>
      </div>
    )
  }
}
