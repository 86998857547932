import React from 'react'
import PropTypes from 'prop-types'
import { lightBlue900 as TitleColor } from 'material-ui/styles/colors'

import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('AppInfoRow', theme => {
  return {
    title: {
      color: TitleColor,
      fontSize: '90%',
    },
    child: {
      padding: '10px',
      paddingTop: '5px',
    },
    container: {
      padding: '5px 20px',
      border: '1px solid #efefef',
    },
  }
})

export const AppInfoRow = ({ label, children }, context) => {
  const classes = context.styleManager.render(styleSheet)

  return (
    <div className={classes.container}>
      <div className={classes.title}>{label}</div>
      <div className={classes.child}>{children}</div>
    </div>
  )
}

AppInfoRow.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

AppInfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}
