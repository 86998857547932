/*eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { Highlighter } from './Highlighter'
import { TableRow, TableRowColumn } from 'material-ui/Table'

// Example of using a simple function as a component.
export const SeverityRow = ({ severity, searchTerm }) => {
  return (
    <TableRow>
      <TableRowColumn style={{ width: '10%' }}>
        <div>{severity.level}</div>
      </TableRowColumn>

      <TableRowColumn style={{ width: '40%' }}>
        <div>{severity.diagnosisCategory}</div>
      </TableRowColumn>

      <TableRowColumn style={{ width: '50%' }}>
        <div>
          <Highlighter
            value={severity.secondaryDiagnosis}
            searchTerm={searchTerm}
          />
        </div>
      </TableRowColumn>
    </TableRow>
  )
}

//Setting propTypes when using a function for a component.
SeverityRow.propTypes = {
  severity: PropTypes.object.isRequired,
  searchTerm: PropTypes.string.isRequired,
}
