import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import { logout } from '../ducks/user'
import { queryAndSetConfigs, getConfig } from '../util/configs'
import { parseTimeValue } from '../util/configs'
import moment from 'moment'
import { LogManager } from '../log'
const logger = LogManager.getLogger('IdleTimerContainer')

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        logout
      },
      dispatch
    ),
  }
}

let visibilityChange = null
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

@connect(
  null,
  mapDispatchToProps
)
class IdleTimerContainer extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      logout: PropTypes.func.isRequired,
    }),
  }


  constructor(props) {
    super(props)

    this.logEnabled = false
    this.idleTimer = null
    this.onAction  = this._onAction.bind(this)
    this.onActive  = this._onActive.bind(this)

  }

  componentDidMount() {
    this.log('componentDidMount')
  }

  componentWillUnmount() {
    document.removeEventListener(visibilityChange, this.handleVisibilityChangeEvent)
    this.log('componentWillUnmount')
  }

  handleVisibilityChangeEvent(event) {
    if (this.idleTimer) {
      const remainingTime = this.idleTimer.getRemainingTime()
      const elapsedTime = this.idleTimer.getElapsedTime()

      const lastActive = this.idleTimer.getLastActiveTime()
      const ts = moment(lastActive).format('hh:mm:ss:SSS')
      this.log('visibilitychange' + ' ' + ts + ' ' + remainingTime + ' ' + (remainingTime <= 0))

      if (remainingTime <= 0) {
          this.logout()
      } else {
        this.idleTimer.resume()
      }
    }
  }

  logout() {
    const {actions: { logout }} = this.props
    this.log('[[LOGOUT]]')
    logout()
  }

  _onAction(e) {
    // logger.debug('user is active', e)
    // this.log('(event)' + ' ' + e.type + ' ' + JSON.stringify(e))
    // const ts = moment(this.idleTimer.getLastActiveTime()).format('hh:mm:ss:SSS')
    // this.log('(onAction)' + ' ' + ts + ' ' + this.idleTimer.getRemainingTime())
  }

  _onActive(e) {
    // logger.debug('user is active', e)
    // logger.debug('time remaining', this.idleTimer.getRemainingTime())
    // this.log('user is active ' + ' ' + this.idleTimer.getRemainingTime())
  }

  log(message) {
    if (!this.logEnabled) { return }

    let output = ''
    if (typeof message == 'object') {
      output += (JSON && JSON.stringify ? JSON.stringify(message) : message)
    } else {
      output += message
    }
    logger.debug(output)

    const m = moment(t).format('hh:mm:ss:SSS')
    const d = document.createElement('div')
    const t = document.createTextNode('[' + m + '] ' + output)
    d.appendChild(t)

    const l = document.getElementById('logger')
    if (l) {
      l.appendChild(d)
    } else {
      const elem = document.createElement('div')
      elem.id = 'logger-container'
      elem.style.cssText = 'position:fixed;min-width:300px;fixed;top:50px;left:50px;z-index:100;background:#000;color:#fff;padding:5px;'
      const anchor = document.createElement('a')
      anchor.href='javascript:document.getElementById("logger").innerHTML="";void(0);'
      const anchorText = document.createTextNode('CLEAR')
      anchor.appendChild(anchorText)
      elem.appendChild(anchor)
      const el = document.createElement('div')
      el.id = 'logger'
      el.appendChild(d)    // add the first log message
      elem.appendChild(el)
      document.body.appendChild(elem)
    }
  }

  _onIdle(e) {
    logger.debug('user is idle', e)
    logger.debug('last active', this.idleTimer.getLastActiveTime())

    const lastActive = this.idleTimer.getLastActiveTime()
    this.log(`user is idle - last active time: ${moment(lastActive).format('hh:mm:ss:SSS')}`)
  }

  render() {
    const sessionTimeoutConfig = getConfig('ca.inactivity.timeout.time')
    // We decided to make 60 seconds the lowest possible timeout
    const sessionTimeout = Math.max(
      parseTimeValue(
        sessionTimeoutConfig
      ),
      60 * 1000
    )

    this.log(`sessionTimeoutConfig: ${sessionTimeoutConfig}, sessionTimeout: ${sessionTimeout}`)

    setTimeout(() => {
      document.addEventListener(visibilityChange, this.handleVisibilityChangeEvent.bind(this))
      this.idleTimer.reset()    // in order to avoid immediate logout by onIdle action, manual start (reset) is required.
    }, 500)
    // We always use ca timeout even if started from HOS launcher
    return (
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        events={[
          'mousemove',
          'keydown',
          'wheel',
          'DOMMouseScroll',
          'mouseWheel',
          'mousedown',
          'touchstart',
          'touchmove',
          'MSPointerDown',
          'MSPointerMove',
          // 'visibilitychange'     // CA-2720 a manual visibilitychange event handling is required
        ]}
        element={document}
        onActive={this.onActive}
        onIdle={(e) => {
          this._onIdle(e)
          this.logout()
        }}
        startOnMount={false}      // keep as `false` to avoid immediate onIdle trigger after mount
        onAction={this.onAction}
        debounce={250}
        timeout={sessionTimeout}/>
    )
  }

}

export default IdleTimerContainer
