import React from 'react'
import { Date } from '../../../date'
import { AuditUser } from '../../../audit'
import PropTypes from 'prop-types'

const ToDoInformationRow = ({ expanded, toDoAuditMeta, colspanLength }) => {

  return expanded ?
    (<tr>
      <td colSpan={colspanLength}>
        <div className="todoInformationRow">
          <span className="updatedInfo">
            Updated: <Date dateString={toDoAuditMeta.updated} /> By:{' '}
            <AuditUser user={toDoAuditMeta.updatedBy} />
          </span>
          <span className="createdInfo">
            Created: <Date dateString={toDoAuditMeta.created} /> By:{' '}
            <AuditUser user={toDoAuditMeta.createdBy} />
          </span>
        </div>
      </td>
    </tr>)
    : null
}

ToDoInformationRow.propTypes = {
  expanded: PropTypes.bool,
  toDoAuditMeta: PropTypes.object,
  colspanLength: PropTypes.number
}

export default ToDoInformationRow
