/* eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import FlatButton from 'material-ui/FlatButton'

import { showDrawer } from '../../ducks/views'
import { drawers } from '../../containers/drawer-component-mapping'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
export class ApplyPlan extends React.Component {
  handleOpen = () => {
    const {
      refetch,
      actions: { showDrawer },
      visit: {
        id,
        plan,
        patient,
        decisionTime,
        admissionTime,
        targetDischargeTime,
        anticipatedDischargeTime,
      },
    } = this.props

    const title = `Apply Plan for ${patient.lastName}, ${patient.firstName}`

    showDrawer(drawers.CHOOSE_PLAN, {
      title,
      id,
      plan,
      patient,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
      decisionTime,
      refetch,
    })
  }

  render() {
    return <FlatButton label="Apply Plan" onClick={this.handleOpen} />
  }
}

ApplyPlan.propTypes = {
  actions: PropTypes.object,
  visit: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
}
