import React from 'react'
import PropTypes from 'prop-types'

import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconButton from 'material-ui/IconButton'
import MoveToBedIcon from 'mdi-react/HotelIcon'

export const MoveToBed = ({ items, onChange }) => {
  const handleClick = e => {
    e.stopPropagation()
  }

  const itemText = item => {
    return `${item.bed.name} / ${item.department.name}`
  }

  const itemKey = (item, index) => {
    return itemText(item) + index
  }

  return (
    <span onClick={e => handleClick(e)}>
      <IconMenu
        iconButtonElement={
          <IconButton
            disabled={items.length == 0}
            style={{ fill: 'gray', padding: 8, width: 40, height: 40 }}>
            <MoveToBedIcon />
          </IconButton>
        }
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onItemClick={(event, child) => onChange && onChange(child.props.value)}>
        {items.map((item, index) => (
          <MenuItem
            key={itemKey(item, index)}
            value={item}
            primaryText={itemText(item)}
          />
        ))}
      </IconMenu>
    </span>
  )
}

MoveToBed.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func,
}
