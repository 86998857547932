import { graphql } from 'react-relay'

export const caAppConfigs = graphql`
  query config_appConfigs_Query {

    configs(applicationType: CA) {
      id
      name
      value
    }

    alertIconConfig {
      id
      key
      value
    }

    languageResources(resourceKey: "BedRequestStatus") {
      resourceKey
      resourceValue
    }

  }
`
