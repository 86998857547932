import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../escalation.scss'

import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton'
import StairsBox from 'mdi-react/StairsIcon'
import { grey400 as DevToolIconColor } from 'material-ui/styles/colors'
import { filterConstants } from '../../../util/filters'

const BarrierTypeFilter = ({
  onChange,
  onFilterBarrierOpenChange,
  onFilterBarrierEscalatedChange,
  valueSelected,
  defaultSelected,
}) => {
  return (
    <div className="filter-sub-container">
      <div className="filter-header">
        <span className="filter-label">Barriers</span>
      </div>
      <div>
        <RadioButtonGroup
          name="filterAdmissionType"
          valueSelected={valueSelected.selected}
          className="filter-items"
          defaultSelected={defaultSelected}>
          <RadioButton
            value={filterConstants.barriers.allValue}
            label="All"
            onClick={onChange}
          />
          <RadioButton
            value={filterConstants.barriers.withoutBarriers}
            label="None Open"
            onClick={onChange}
          />
          <RadioButton
            value={filterConstants.barriers.withOpenBarriers}
            label="Open"
            onClick={onChange}
          />
          <RadioButton
            value={filterConstants.barriers.withOpenEscalatedBarriers}
            label="Escalated"
            onClick={onChange}
          />
        </RadioButtonGroup>
      </div>
    </div>
  )
}

BarrierTypeFilter.propTypes = {
  onChange: PropTypes.func,
  onFilterBarrierOpenChange: PropTypes.func,
  onFilterBarrierEscalatedChange: PropTypes.func,
  valueSelected: PropTypes.object,
  defaultSelected: PropTypes.string,
}

export default BarrierTypeFilter
