const safeIndexOf = (field, term) => field && field.toUpperCase().includes(term)

// any time we filter on any field (other than majorDiagnosisCategory)
// reduce the set of items in the combo to match what available via the other filters
// we don't want to give the user the chance to pick an mdc that will result in nothing being shown
export function mdcSearch(drgs: Array, searchFilters: Object) {
  const mdcSet = new Set()
  const st = searchFilters.searchTerm.toUpperCase()

  drgs.forEach(item => {
    if (
      ((searchFilters.med && item.type === 'MED') ||
        (searchFilters.surg && item.type === 'SURG')) &&
      (st.length <= 2 ||
        (item.title.toUpperCase().indexOf(st) !== -1 ||
          (item.commonPrincipalDX &&
            item.commonPrincipalDX.toUpperCase().indexOf(st) !== -1) ||
          (item.commonProcedures &&
            item.commonProcedures.toUpperCase().indexOf(st) !== -1)))
    ) {
      mdcSet.add(item.majorDiagnosisCategory)
    }
  })

  return Array.from(mdcSet).sort()
}

export function mdcSearchFast(drgs: Array) {
  const mdcSet = new Set()

  drgs.forEach(item => {
    mdcSet.add(item.majorDiagnosisCategory)
  })

  return Array.from(mdcSet).sort()
}

export function drgSearch(drgs: Array, searchFilters) {
  // Looking for a specific code overrides all other searches
  if (searchFilters.drgCode) {
    let padded = searchFilters.drgCode
    while (padded.length < 3) {
      padded = '0' + padded
    }

    return drgs.filter(drg => drg.family.some(d => d.code === padded))
  } else {
    // don't search unless we have 2 of 3
    if (countDRGFilters(searchFilters) >= 2) {
      const { med, surg, searchTerm = '' } = searchFilters
      const majorDiagnosisCategory =
        searchFilters.majorDiagnosisCategory &&
        searchFilters.majorDiagnosisCategory.trim()
      const searchTokens = extractSearchTokens(searchTerm)

      return drgs.filter(
        drg =>
          (med || (!med && drg.type !== 'MED')) &&
          (surg || (!surg && drg.type !== 'SURG')) &&
          (!searchTokens.length ||
            searchTokens.some(
              token =>
                safeIndexOf(drg.title, token) ||
                safeIndexOf(drg.commonPrincipalDX, token) ||
                safeIndexOf(drg.commonProcedures, token)
            )) &&
          (!majorDiagnosisCategory ||
            drg.majorDiagnosisCategory.trim() === majorDiagnosisCategory)
      )
    }

    return []
  }
}

export function extractSearchTokens(searchTerm) {
  // every other item in firstPass starting with index 1 will be what's
  // between double quotes
  let firstPass = searchTerm.split('"'),
    tokens = [],
    terms = [],
    isToken = true
  const re = /"/g

  firstPass.forEach(item => {
    isToken
      ? tokens.push(' ' + item.toUpperCase() + ' ')
      : terms.push(item.replace(re, '').toUpperCase())
    isToken = !isToken
  })
  return (tokens.join() || '')
    .split(' ')
    .filter(term => term.length > 2)
    .concat(terms.filter(term => term.length > 2))
}

export function countDRGFilters(searchFilters) {
  return (
    ((searchFilters.med || searchFilters.surg) && 1) +
    (!!searchFilters.majorDiagnosisCategory && 1) +
    (extractSearchTokens(searchFilters.searchTerm).length && 1)
  )
}

export function severitiesSearch(severities: Array, searchFilters: Object) {
  const terms = extractSearchTokens(searchFilters.searchTerm)

  let result = severities || []

  if (!searchFilters.cc) {
    result = result.filter(severity => severity.level !== 'CC')
  }

  if (!searchFilters.mcc) {
    result = result.filter(severity => severity.level !== 'MCC')
  }

  if (searchFilters.searchTerm.length > 2) {
    result = result.filter(severity =>
      terms.some(term => safeIndexOf(severity.secondaryDiagnosis, term))
    )
  }

  if (searchFilters.dc) {
    const sdc = searchFilters.dc.trim()
    result = result.filter(
      severity => severity.diagnosisCategory.trim() === sdc
    )
  }

  return result
}

export function countSeverityFilters(searchFilters) {
  return (
    ((searchFilters.mcc || searchFilters.cc) && 1) +
    (!!searchFilters.dc && 1) +
    (extractSearchTokens(searchFilters.searchTerm).length && 1)
  )
}
// any time we filter on any field (other than dc)
// reduce the set of items in the combo to match what available via the other filters
// we don't want to give the user the chance to pick an dc that will result in nothing being shown
export function dcSearch(severities: Array, searchFilters: Object) {
  const dcSet = new Set()
  const st = searchFilters.searchTerm.toUpperCase()

  if (severities) {
    severities.forEach(item => {
      if (
        ((searchFilters.cc && item.level === 'CC') ||
          (searchFilters.mcc && item.level === 'MCC')) &&
        (st.length <= 2 ||
          item.secondaryDiagnosis.toUpperCase().indexOf(st) !== -1)
      ) {
        dcSet.add(item.diagnosisCategory)
      }
    })
  }

  return Array.from(dcSet).sort()
}
