import React, {Component} from 'react'
import BoardTemplateRowList from "../../components/boardTemplate/BoardTemplateRowList"
import {showDrawer} from '../../ducks/views'
import {drawers} from '../../containers/drawer-component-mapping'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import IconButton from 'material-ui/IconButton'
import OpenDrawer from 'material-ui/svg-icons/content/add-circle-outline'
import PropTypes from 'prop-types'
import { showErrorsPg } from '../../ducks/errorspg'
import { deleteBoardTemplateEntry } from '../../graphql/relay/queries/boardTemplate'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import {environment} from "../../graphql/relay/env"

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
        showErrorsPg
      },
      dispatch
    ),
  }
}

const rowStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingTop: "15px",
  paddingBottom: "15px"
}

const columnStyle = {
  border: "1px solid rgb(224, 224, 224)",
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingTop: "5px",
  paddingBottom: "5px"
}


class BoardTemplateEditor extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.object),
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
      showErrorsPg: PropTypes.func,
    }),
  }
  constructor(props) {
    super(props)
  }

  onEditHandler = (rowData) => {
    this.props.actions.showDrawer(drawers.EDIT_BOARD_TEMPLATE_ENTRY, {
      title: "Edit " + this.props.title,
      data: {
        ...rowData
      }
    })
  }

  onDeleteHandler = (rowData) => {
    const variables = {
      boardTemplateId: rowData.id
    }

    const updateAfterDeletion = (proxyStore) => {
      const store = proxyStore.getRootField("boardTemplateMutation")
      const payload = store.getLinkedRecords("deleteBoardTemplateEntry", variables)
      const rootStore = proxyStore.get("client:root")
      const boardTemplateList = rootStore.getLinkedRecords('boardTemplates')
      rootStore.setLinkedRecords(payload,'boardTemplates')
    }

    handleMutation(
      environment,
      deleteBoardTemplateEntry,
      variables,
      undefined,
      undefined,
      undefined,
      updateAfterDeletion
    )
  }

  onAddHandler = () => {
    this.props.actions.showDrawer(drawers.ADD_BOARD_TEMPLATE_ENTRY, {
      title: "Add " + this.props.type,
    })
  }


  render() {
    return (
      <div style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
        <h1 style={{display: "inline"}}>{this.props.title}</h1>
        <IconButton
          style={{padding: 8, width: 40, height: 40 }}
          onClick={this.onAddHandler}>
          <OpenDrawer />
        </IconButton>
        <table style={{width: "100%", marginTop: "10px", marginBottom: "10px"}}>
          <colgroup>
            <col width="3%"/>
            <col width="29%"/>
            <col width="30%"/>
            <col width="10%"/>
            <col width="25%"/>
            <col width="3%"/>
          </colgroup>
          <thead style={{fontSize: "16px"}}>
            <tr style={rowStyle}>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
              <th style={columnStyle} bgcolor="#d3d3d3">Name</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Description</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Type</th>
              <th style={columnStyle} bgcolor="#d3d3d3">Number of columns</th>
              <th style={columnStyle} bgcolor="#d3d3d3"/>
            </tr>
          </thead>
          <tbody>
            <BoardTemplateRowList list={this.props.list} onEdit={this.onEditHandler} onDelete={this.onDeleteHandler}/>
          </tbody>
        </table>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(BoardTemplateEditor)
