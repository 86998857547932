/* Creates an instance of the Apollo Client with our standard configuration */
import ApolloClient from 'apollo-client'
import { toIdValue } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { applyStandardInterceptors } from './cl-apollo-network-interface'

import { clFragmentMatcher } from './cl-apollo-fragment-matcher'

const TYPE_KEY_SEPARATOR = '___'

const cache = new InMemoryCache({
  dataIdFromObject: dataIdFromObject,
  addTypename: true,
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  cacheRedirects: {
    Query: {
      workspaces: (_, args) =>
        // If we expand the workspace query beyond visit ids, we need additional logic in here.
        args.visitIds.map(visitId =>
          toIdValue(
            dataIdFromObject({
              __typename: 'Workspace',
              visit: { id: visitId },
            })
          )
        ),
    },
  },
  fragmentMatcher: clFragmentMatcher,
})

export const clApolloClient = new ApolloClient({
  link: applyStandardInterceptors(),
  cache: cache.restore(window.__APOLLO_STATE__ || {}),
  queryDeduplication: true,
})

function dataIdFromObject(result) {
  const key = _getKeyFrom(result)
  if (result.__typename && typeof key !== 'undefined') {
    return assembleDataId(result.__typename, key)
  }

  // Make sure to return null if this object doesn't have an ID
  return null
}

/**
 * Extract a key from an object
 * @param {Object} obj The graphql object
 * @return {*|undefined} key The key of the object
 * @private
 */
function _getKeyFrom(obj: Object) {
  // As this expands, consider extracting a better interface for getting ids by typename
  return (
    obj &&
    ((obj.__typename === 'PersonnelRole' && obj.id + '##' + obj.role.code) ||
      obj.id ||
      obj.exchangeName ||
      ((obj.__typename === 'Workspace' && (obj.visit && obj.visit.id)) ||
        (obj.bed && obj.bed.length && obj.bed[0].exchangeName)) ||
      (obj.__typename === 'Config' && obj.name) ||
      undefined)
  )
}

/**
 * Combine a typename and a key to produce a Data Id for Apollo
 * @param typename The typename of the entity
 * @param key The string or number key of the entity
 * @returns {string} DataId for the Apollo cache
 */
export function assembleDataId(typename, key) {
  return typename + TYPE_KEY_SEPARATOR + key
}
