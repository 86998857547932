/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ActionStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type MilestoneStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type Track = "Discharge" | "Progression" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type pin_FullMilestone$ref: FragmentReference;
declare export opaque type pin_FullMilestone$fragmentType: pin_FullMilestone$ref;
export type pin_FullMilestone = {|
  +id: string,
  +name: string,
  +description: ?string,
  +track: ?Track,
  +dueDate: ?any,
  +completedDate: ?any,
  +status: MilestoneStatus,
  +planTemplateName: string,
  +actions: $ReadOnlyArray<?{|
    +id: string,
    +text: string,
    +status: ActionStatus,
    +pinned: boolean,
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +updated: any,
      +updatedBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |},
    +milestoneTemplateName: ?string,
  |}>,
  +$refType: pin_FullMilestone$ref,
|};
export type pin_FullMilestone$data = pin_FullMilestone;
export type pin_FullMilestone$key = {
  +$data?: pin_FullMilestone$data,
  +$fragmentRefs: pin_FullMilestone$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pin_FullMilestone",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "track",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedDate",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planTemplateName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MilestoneAction",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinned",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditMeta",
          "kind": "LinkedField",
          "name": "auditMeta",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AuditUser",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "updated",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AuditUser",
              "kind": "LinkedField",
              "name": "updatedBy",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "milestoneTemplateName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Milestone",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c8052327931610634428883cbd37d5cd';

module.exports = node;
