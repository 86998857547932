/*eslint-disable react/no-did-mount-set-state*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NoteIcon from 'mdi-react/NoteIcon'
import BarrierIcon from 'mdi-react/GateIcon'
import { Pin } from '.././plan'
import { BarrierStatus, EscalationStatus, BarrierNote } from '.'
import { StatefulTextField } from '../common'
import KnownPersonnelTypeAhead from './knownPersonnelTypeAhead/KnownPersonnelTypeAhead'
import BarrierCommStatusIcons from './barrierCommStatusIcons/BarrierCommStatusIcons'
import BarrierSource from './BarrierSource/BarrierSource'

import {
  red400 as BarrierColor,
  yellow600 as NoteColor,
} from 'material-ui/styles/colors'
import { Date } from '../date'
import { AuditUser } from '../audit'
import { buildElapsedTime } from '../../util/utils'
import { auditUpdatedCompareDescending } from '../../util/sort'
import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { Popover, OverlayTrigger  } from 'react-bootstrap'
import BarrierMessageStatusIcon from '../barrier/BarrierMessageStatusIcon/BarrierMessageStatusIcon'
import {APP_DELIVERY, EMAIL_DELIVERY} from './BarrierConstants'
import BarrierHistoryButton from './BarrierHistoryButton/BarrierHistoryButton'
import CustomRoundIcon from '../common/CustomIcons/CustomRoundIcon'
import WatcherIcon from '../image/img/watcher.svg'
import SendIcon from '../image/img/send.svg'
import { BARRIER_OWNER_ROLE, buildKnownUserInput } from '../../graphql/relay/queries/knownUser'
import { DischargeDelayImage } from '../image/CodeImage'


const styleSheet = createStyleSheet('ExpandedBarrier', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme
  return {
    elapsedTimeText: {
      padding: '10px 0 5px',
      cursor: 'pointer'
    },
    popOverInfo: {
      maxWidth: 'fit-content',
      padding: '10px 15px'
    },
    item: {
      padding: '.5em',
      margin: '-1px',
      border: '1px solid #d3d3d3',
    },
    text: {
      textAlign: 'left'
    },
    barrierName: {
      lineHeight: '40px',
      wordWrap: 'break-word',
    },
    timeElapsed: {
      lineHeight: '40px',
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    icons: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '0 !important',
    },
    noteIcon: {
      fill: NoteColor,
      marginRight: '8px'
    },
    barrierIcon: {
      fill: BarrierColor,
      marginLeft: '13px',
      position: 'relative',
    },
    ownerRole: {
      height: '43px',
    },
    ownerName: {
      height: '43px',
      display: 'flex'
    },
    description: {
      padding: '12px',
    },
    createdMeta: {
      padding: '12px',
    },
    updatedMeta: {
      paddingTop: '12px',
    },
    escalatedMeta: {
      paddingTop: '12px',
    },
    ownerRow: {
      height: '65px'
    }
  }
})

export class ExpandedBarrier extends Component {
  static propTypes = {
    rerender: PropTypes.func,
    barrier: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
    visit: PropTypes.object.isRequired,
    showBarrierIcon: PropTypes.bool,
    onBarrierClick: PropTypes.func.isRequired,
    onUpdatePinned: PropTypes.func.isRequired,
    onUpdateBarrierStatus: PropTypes.func.isRequired,
    onEscalateBarrier: PropTypes.func.isRequired,
    onDeescalateBarrier: PropTypes.func.isRequired,
    onUpdateBarrierOwner: PropTypes.func.isRequired,
    onSearchKnownPersonnel: PropTypes.func.isRequired,
    onCreateBarrierNote: PropTypes.func.isRequired,
    onUpdateBarrierNote: PropTypes.func.isRequired,
    onRemoveBarrierNote: PropTypes.func.isRequired,
    barrierEscalationRoles: PropTypes.array,
    deliveryMethods: PropTypes.array,
    onViewBarrierInfoHistory: PropTypes.func.isRequired,
    onViewSendBarrierDialog: PropTypes.func.isRequired,
    onViewManageWatchersDialog: PropTypes.func.isRequired,
    onUpdateSourceType: PropTypes.func.isRequired,
    onUpdateSourceName: PropTypes.func.isRequired
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  handleStopPropagation = e => {
    e.stopPropagation()
  }

  renderRow1 = classes => {
    const {
      barrier,
      plan,
      visit,
      deliveryMethods,
      showBarrierIcon,
      onUpdateBarrierStatus,
      onUpdatePinned,
      onEscalateBarrier,
      onDeescalateBarrier,
      onViewBarrierInfoHistory,
      onViewSendBarrierDialog,
      onViewManageWatchersDialog,
      rerender
    } = this.props
    const escalationValue = barrier.escalation ? 'Escalated' : 'Deescalated'
    const popoverLeft = (
      <Popover
        id="popover-positioned-left"
        className={classNames(classes.popOverInfo)}
      >
        <div className={classNames('row')}>
          <div>
            <div className={classNames(classes.text)}>
              Created: <Date dateString={barrier.auditMeta.created} />
            </div>
            <div className={classNames(classes.text)}>
              By: <AuditUser user={barrier.auditMeta.createdBy} />
            </div>
          </div>
          <br />
          <div>
            <div className={classNames(classes.text)}>
              Updated: <Date dateString={barrier.auditMeta.updated} />
            </div>
            <div className={classNames(classes.text)}>
              By: <AuditUser user={barrier.auditMeta.updatedBy} />
            </div>
          </div>
          {barrier.escalation && (
            <div>
              <br />
              <div className={classNames(classes.text)}>
                Escalated:{' '}
                <Date dateString={barrier.escalation.auditMeta.created} />
              </div>
              <div className={classNames(classes.text)}>
                By: <AuditUser user={barrier.escalation.auditMeta.createdBy} />
              </div>
            </div>
          )}
        </div>
      </Popover>
    )

    const showSendMessageAndHistoryButton = (() => {
      if(deliveryMethods) {
        let canSendInstantMsg = false
        let canSendEmailMsg = false
        deliveryMethods.forEach(elem => {
          if(elem.code === "APP" && elem.active) {
            canSendInstantMsg = true
          } else if(elem.code === "EMAIL" && elem.active) {
            canSendEmailMsg = true
          }
        })

        return (canSendInstantMsg || canSendEmailMsg)
      }
    })

    return (
      <div className={classNames('row')}>
        <div className={classNames('col-xs-4', classes.icons)}>
          {showBarrierIcon && (
            <BarrierIcon className={classNames(classes.barrierIcon)} />
          )}
          <Pin item={barrier} onClick={onUpdatePinned} rerender={rerender} showButtonBorder/>
          <BarrierStatus
            value={barrier.status}
            onChange={newValue =>
              onUpdateBarrierStatus(barrier.id, newValue, visit.bedRequestStatus)
            }
          />
          <EscalationStatus
            value={escalationValue}
            onChange={newValue => {
              if (escalationValue !== newValue) {
                newValue === 'Escalated'
                  ? onEscalateBarrier(barrier.id, plan.id, rerender)
                  : onDeescalateBarrier(barrier.id, plan.id, rerender)
              }
            }}
            itemStatus={barrier.status}
          />
          <span style={{
            marginRight: '4px',
            visibility: barrier.status === 'Open' && barrier.ownerMessageDeliveryMethod? 'visible': 'hidden'
          }}>
            <BarrierMessageStatusIcon
              messageStatus={barrier.ownerMessageStatus}
            />
          </span>
          {barrier.dischargeDelay &&  <DischargeDelayImage type="Discharge" code="Delay"/>}
          {barrier.notes.length > 0 && (
            <NoteIcon className={classNames(classes.noteIcon)} />
          )}
        </div>
        <div className={classNames('col-xs-4', classes.barrierName)}>
          {barrier.name}
        </div>
        <div className={classNames('col-xs-4', classes.timeElapsed)}>
          <OverlayTrigger
            ref="barrierInfo"
            trigger="click"
            placement="left"
            rootClose
            overlay={popoverLeft}>
            <div className={classNames('clickText text', classes.elapsedTimeText)}>
              <a onClick={() => {}}>
                {buildElapsedTime(barrier.auditMeta.created, 'hos', 'hour')}{' '}
                {barrier.escalation && `| `}
                {barrier.escalation &&
                buildElapsedTime(
                  barrier.escalation.auditMeta.created,
                  'hos',
                  'hour'
                )}
              </a>
            </div>
          </OverlayTrigger>
          {showSendMessageAndHistoryButton && (
            <>
              <CustomRoundIcon
                tooltip={'Manage Watchers'}
                icon={WatcherIcon}
                onClick={onViewManageWatchersDialog}
                customIconCss={{
                  size: '16px'
                }}
              />
              <CustomRoundIcon
                tooltip={'Send Barrier Information'}
                icon={SendIcon}
                onClick={onViewSendBarrierDialog}
                customIconCss={{
                  marginRight: '2px'
                }}
              />
              <BarrierHistoryButton
                onViewBarrierInfoHistory={onViewBarrierInfoHistory}
              />
            </>
          )}
        </div>
      </div>
    )
  }

  renderRow2 = classes => {
    const {
      barrier,
      onUpdateBarrierOwner,
      onSearchKnownPersonnel,
    } = this.props

    const ownerMessageDeliveryMethod = (barrier => {
      if(barrier.ownerKnownUser) {
        if(barrier.ownerKnownUser.instantMessageReceiver) {
          return APP_DELIVERY
        }
        if(barrier.ownerKnownUser.emailReceiver) {
          return EMAIL_DELIVERY
        }
      }
      return null
    })(barrier)

    const getKnownUserInput = (searchText) => {
      return buildKnownUserInput({ searchText: searchText, roles: [BARRIER_OWNER_ROLE] })
    }

    return (
      <div className={classNames('row', classes.ownerRow)}>
        <div
          className={classNames('col-xs-6', classes.description, classes.text)}>
          {barrier.category ? barrier.category.value : 'No Category'}
        </div>
        <div
          className={classNames('col-xs-6', classes.ownerName)}
          onClick={this.handleStopPropagation}>
          <KnownPersonnelTypeAhead
            onSearchKnownPersonnel={searchText => onSearchKnownPersonnel(getKnownUserInput(searchText))}
            onResultSelected={(value, knownUser) => {
              onUpdateBarrierOwner(barrier, value, knownUser)
            }}
            selectedValue={barrier.owner}
            title={barrier.ownerRole && barrier.ownerRole.value}
            label="Owner Name"
            disabled={barrier.status === 'Resolved'}
            showSubtext
          />
          <BarrierCommStatusIcons
            ownerMessageDeliveryMethod={ownerMessageDeliveryMethod}
            ownerMessageDeliveryDate={barrier.ownerMessageDeliveryDate}
            ownerMessageStatus={barrier.ownerMessageStatus}
            ownerMessageStatusDate={barrier.ownerMessageStatusDate}
          />
        </div>
      </div>
    )
  }

  renderBarrierSource = () => {
    const {
      barrier,
      onUpdateSourceType,
      onUpdateSourceName,
      onSearchKnownPersonnel
    } = this.props

    const barrierSourceDetails = barrier.category.sourceTypes.find(type => type.code === barrier.sourceTypeCode)
      ?.sourceDetails?.filter(type => type.active || type.code === barrier.sourceName)

    return (<BarrierSource
      sourceType={barrier.sourceTypeCode}
      sourceName={barrier.sourceName}
      onSourceTypeChange={(value) => onUpdateSourceType(barrier.id, value)}
      onSourceNameChange={(value) => onUpdateSourceName(barrier, value)}
      barrierSourceTypes={barrier.category.sourceTypes.filter(type => type.active || type.code === barrier.sourceTypeCode)}
      barrierSourceDetails={barrierSourceDetails}
      onSearchKnownPersonnel={onSearchKnownPersonnel}/>)

  }

  renderNoteList = classes => {
    const {
      barrier,
      onCreateBarrierNote,
      onUpdateBarrierNote,
      onRemoveBarrierNote,
      rerender,
    } = this.props

    const sortedNotes = barrier.notes
      .slice(0)
      .sort(auditUpdatedCompareDescending)

    return (
      <div>
        <div
          onClick={e => this.handleStopPropagation(e)}>
          <StatefulTextField
            maxNumberOfChars={255}
            textFieldProps={{ fullWidth: true, hintText: 'Add Barrier Note', style: { fontSize: '14px' } }}
            onEnter={value => onCreateBarrierNote(barrier.id, value)}
            showCharacterCount
            blurOnEnter
            onBlur
            clearOnTextEntered
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {sortedNotes.map(item => (
            <BarrierNote
              key={item.id}
              note={item}
              onUpdateBarrierNote={onUpdateBarrierNote}
              onRemoveBarrierNote={onRemoveBarrierNote}
            />
          ))}
        </div>
      </div>
    )
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)
    const { barrier, onBarrierClick } = this.props

    return (
      <div
        style={{
          paddingLeft: '15px',
          paddingRight: '12px',
          marginBottom: '5px',
        }}
        onClick={e => onBarrierClick(e, barrier)}>
        {this.renderRow1(classes)}
        {this.renderRow2(classes)}
        {this.renderBarrierSource()}
        {this.renderNoteList(classes)}
      </div>
    )
  }
}
