import gql from '../../graphql/util/gql'

export const VectorGQL = {
  fragments: {},
  queries: {
    DataSet: gql`
      query JvionDataSet {
        jvionDataSet {
          id
          expired
        }
      }
    `,
    PatientVectorsList: gql`
      query PatientVectorsList($ids: [String]!) {
        patientVectors(ids: $ids, idType: ENCOUNTER) {
          encounterId
          conditions {
            riskBracket
          }
        }
      }
    `,
    PatientVectorsFull: gql`
      query PatientVectorsFull($ids: [String]!) {
        patientVectors(ids: $ids, idType: ENCOUNTER) {
          patientId
          encounterId
          predictionDate
          conditions {
            name
            riskBracket
            recommendations {
              id
              rank
              description
              code
              accepted
            }
            riskFactors {
              type
              description
            }
          }
        }
      }
    `,
    AcceptedRecommendations: gql`
      query AcceptedRecommendations(
        $patientId: String!
        $encounterId: String!
      ) {
        acceptedRecommendations(
          acceptedRecommendationQueryId: {
            encounterId: $encounterId
            patientId: $patientId
          }
          status: true
        ) {
          acceptedRecommendationId {
            patientId
            encounterId
            conditionName
            code
          }
          description
          predictionDate
          status
          riskBracket
          auditMeta {
            createdBy {
              firstName
              lastName
            }
            created
            updatedBy {
              firstName
              lastName
            }
            updated
          }
        }
      }
    `,
  },
  mutations: {
    acceptRecommendation: gql`
      mutation accept(
        $acceptedRecommendationId: AcceptedRecommendationId!
        $status: Boolean
        $loadId: Int!
      ) {
        acceptedRecommendationMutation {
          accept(
            acceptedRecommendationId: $acceptedRecommendationId
            status: $status
            loadId: $loadId
          ) {
            acceptedRecommendationId {
              patientId
              encounterId
              conditionName
              code
            }
            description
            predictionDate
            status
            loadId
            riskBracket
            auditMeta {
              createdBy {
                firstName
                lastName
              }
              created
              updatedBy {
                firstName
                lastName
              }
              updated
            }
          }
        }
      }
    `,
  },
}
