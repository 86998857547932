/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type action_updateActionText_MutationVariables = {|
  actionId: any,
  text: string,
|};
export type action_updateActionText_MutationResponse = {|
  +actionMutation: ?{|
    +updateActionText: ?{|
      +id: string,
      +actions: $ReadOnlyArray<?{|
        +id: string,
        +text: string,
        +auditMeta: ?{|
          +updated: any,
          +updatedBy: {|
            +userName: string,
            +firstName: string,
            +lastName: string,
          |},
        |},
      |}>,
      +milestones: $ReadOnlyArray<?{|
        +id: string,
        +actions: $ReadOnlyArray<?{|
          +id: string,
          +text: string,
          +auditMeta: ?{|
            +updated: any,
            +updatedBy: {|
              +userName: string,
              +firstName: string,
              +lastName: string,
            |},
          |},
        |}>,
      |}>,
    |}
  |}
|};
export type action_updateActionText_Mutation = {|
  variables: action_updateActionText_MutationVariables,
  response: action_updateActionText_MutationResponse,
|};
*/


/*
mutation action_updateActionText_Mutation(
  $actionId: UUID!
  $text: String!
) {
  actionMutation {
    updateActionText(actionId: $actionId, text: $text) {
      id
      actions {
        id
        text
        auditMeta {
          updated
          updatedBy {
            userName
            firstName
            lastName
            id
          }
        }
      }
      milestones {
        id
        actions {
          id
          text
          auditMeta {
            updated
            updatedBy {
              userName
              firstName
              lastName
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "actionId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "actionId",
    "variableName": "actionId"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AuditMeta",
    "kind": "LinkedField",
    "name": "auditMeta",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "AuditUser",
        "kind": "LinkedField",
        "name": "updatedBy",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AuditMeta",
    "kind": "LinkedField",
    "name": "auditMeta",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "AuditUser",
        "kind": "LinkedField",
        "name": "updatedBy",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "action_updateActionText_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateActionText",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "action_updateActionText_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "updateActionText",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PlanAction",
                "kind": "LinkedField",
                "name": "actions",
                "plural": true,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ea9139fb06cf335a5370b0ddc494433",
    "id": null,
    "metadata": {},
    "name": "action_updateActionText_Mutation",
    "operationKind": "mutation",
    "text": "mutation action_updateActionText_Mutation(\n  $actionId: UUID!\n  $text: String!\n) {\n  actionMutation {\n    updateActionText(actionId: $actionId, text: $text) {\n      id\n      actions {\n        id\n        text\n        auditMeta {\n          updated\n          updatedBy {\n            userName\n            firstName\n            lastName\n            id\n          }\n        }\n      }\n      milestones {\n        id\n        actions {\n          id\n          text\n          auditMeta {\n            updated\n            updatedBy {\n              userName\n              firstName\n              lastName\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1df4246be601b15b571d1d932a09077';

module.exports = node;
