/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VisibilityBoardRowList_list$ref = any;
export type visibilityBoard_deleteBoardTemplateEntry_MutationVariables = {|
  visibilityBoardId: any
|};
export type visibilityBoard_deleteBoardTemplateEntry_MutationResponse = {|
  +visibilityBoardMutation: ?{|
    +deleteVisibilityBoardEntry: ?$ReadOnlyArray<?{|
      +$fragmentRefs: VisibilityBoardRowList_list$ref
    |}>
  |}
|};
export type visibilityBoard_deleteBoardTemplateEntry_Mutation = {|
  variables: visibilityBoard_deleteBoardTemplateEntry_MutationVariables,
  response: visibilityBoard_deleteBoardTemplateEntry_MutationResponse,
|};
*/


/*
mutation visibilityBoard_deleteBoardTemplateEntry_Mutation(
  $visibilityBoardId: UUID!
) {
  visibilityBoardMutation {
    deleteVisibilityBoardEntry(visibilityBoardId: $visibilityBoardId) {
      ...VisibilityBoardRowList_list
      id
    }
  }
}

fragment VisibilityBoardRowList_list on VisibilityBoard {
  ...VisibilityBoardRow_rowData
}

fragment VisibilityBoardRow_rowData on VisibilityBoard {
  id
  name
  description
  boardTemplate {
    id
    name
  }
  departmentExchangeName
  bedExchangeNames
  footer
  scrollDuration
  timeHeaderFormat
  locationStatusesDisplay
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "visibilityBoardId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "visibilityBoardId",
    "variableName": "visibilityBoardId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "visibilityBoard_deleteBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityBoardMutation",
        "kind": "LinkedField",
        "name": "visibilityBoardMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "VisibilityBoard",
            "kind": "LinkedField",
            "name": "deleteVisibilityBoardEntry",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VisibilityBoardRowList_list"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "visibilityBoard_deleteBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityBoardMutation",
        "kind": "LinkedField",
        "name": "visibilityBoardMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "VisibilityBoard",
            "kind": "LinkedField",
            "name": "deleteVisibilityBoardEntry",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardTemplate",
                "kind": "LinkedField",
                "name": "boardTemplate",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "departmentExchangeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bedExchangeNames",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "footer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scrollDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeHeaderFormat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locationStatusesDisplay",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd219ae1c8c6ebc331f2e43c5a2eba48",
    "id": null,
    "metadata": {},
    "name": "visibilityBoard_deleteBoardTemplateEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation visibilityBoard_deleteBoardTemplateEntry_Mutation(\n  $visibilityBoardId: UUID!\n) {\n  visibilityBoardMutation {\n    deleteVisibilityBoardEntry(visibilityBoardId: $visibilityBoardId) {\n      ...VisibilityBoardRowList_list\n      id\n    }\n  }\n}\n\nfragment VisibilityBoardRowList_list on VisibilityBoard {\n  ...VisibilityBoardRow_rowData\n}\n\nfragment VisibilityBoardRow_rowData on VisibilityBoard {\n  id\n  name\n  description\n  boardTemplate {\n    id\n    name\n  }\n  departmentExchangeName\n  bedExchangeNames\n  footer\n  scrollDuration\n  timeHeaderFormat\n  locationStatusesDisplay\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7a2fa8196c9af51b63b8a7153eae63df';

module.exports = node;
