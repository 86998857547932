/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type BarrierSourceTypeEditor_barrierSourceDetailConfigs$ref: FragmentReference;
declare export opaque type BarrierSourceTypeEditor_barrierSourceDetailConfigs$fragmentType: BarrierSourceTypeEditor_barrierSourceDetailConfigs$ref;
export type BarrierSourceTypeEditor_barrierSourceDetailConfigs = $ReadOnlyArray<{|
  +id: string,
  +code: string,
  +value: string,
  +ordinal: number,
  +active: boolean,
  +$refType: BarrierSourceTypeEditor_barrierSourceDetailConfigs$ref,
|}>;
export type BarrierSourceTypeEditor_barrierSourceDetailConfigs$data = BarrierSourceTypeEditor_barrierSourceDetailConfigs;
export type BarrierSourceTypeEditor_barrierSourceDetailConfigs$key = $ReadOnlyArray<{
  +$data?: BarrierSourceTypeEditor_barrierSourceDetailConfigs$data,
  +$fragmentRefs: BarrierSourceTypeEditor_barrierSourceDetailConfigs$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BarrierSourceTypeEditor_barrierSourceDetailConfigs",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordinal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "type": "CodeTable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '39dee8776481910b9be6b23816245546';

module.exports = node;
