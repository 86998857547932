import { graphql } from 'react-relay'

export const createMilestoneAction = graphql`
  mutation action_createMilestoneAction_Mutation(
    $milestoneId: UUID!
    $text: String!
  ) {
    actionMutation {
      createMilestoneAction(milestoneId: $milestoneId, text: $text) {
        id
        milestones {
          id
          actions {
            id
            status
            pinned
            auditMeta {
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`

export const createPlanAction = graphql`
  mutation action_createPlanAction_Mutation(
    $planId: UUID!
    $action: ActionInput!
  ) {
    actionMutation {
      createPlanAction(planId: $planId, action: $action) {
        id
        actions {
          ...action_FullPlanAction
        }
      }
    }
  }
`
export const updateActionText = graphql`
  mutation action_updateActionText_Mutation($actionId: UUID!, $text: String!) {
    actionMutation {
      updateActionText(actionId: $actionId, text: $text) {
        id
        actions {
          id
          text
          auditMeta {
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
        }
        milestones {
          id
          actions {
            id
            text
            auditMeta {
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`

export const updateActionStatus = graphql`
  mutation action_updateActionStatus_Mutation(
    $actionId: UUID!
    $status: ActionStatus!
  ) {
    actionMutation {
      updateActionStatus(actionId: $actionId, status: $status) {
        id
        actions {
          id
          status
          pinned
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
        }
        milestones {
          id
          dueDate
          actions {
            id
            status
            pinned
            auditMeta {
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`

export const removeAction = graphql`
  mutation action_removeAction_Mutation($actionId: UUID!) {
    actionMutation {
      removeAction(actionId: $actionId) {
        id
        actions {
          id
        }
        milestones {
          id
          actions {
            id
          }
        }
      }
    }
  }
`

const FullPlanAction = graphql`
  fragment action_FullPlanAction on PlanAction {
    id
    text
    status
    pinned
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
  }
`

const refetchPlan = graphql`
  query action_fetchPlan_Query($planId: ID!) {
    node(id: $planId) {
      ... on Plan {
        id
        actions {
          id
          text
          auditMeta {
            created
            createdBy {
              userName
              firstName
              lastName
            }
            updated
            updatedBy {
              userName
              firstName
              lastName
            }
          }
        }
        milestones {
          id
          actions {
            id
            text
            auditMeta {
              updated
              updatedBy {
                userName
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`
