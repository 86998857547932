/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import TodoFilterDrawer from './TodoFilterDrawer'

import './ToDoFilter.scss'

import FilterIcon from 'mdi-react/FilterOutlineIcon'

class TodoFilter extends Component {

  state = {
    isFilterOpen: false
  }

  handlerDrawerToggle = () => {
    this.setState((prevState, props) => {
      return {
        isFilterOpen: !prevState.isFilterOpen
      }
    })
  }

  render() {
    const {
      filter,
      draftFilter,
      applyDraftFilter,
      applyFilter,
      resetFilter
    } = this.props

    return (
      <div className="todoFilter">
        <FlatButton
          className="filter-button"
          label="FILTER"
          primary
          onClick={this.handlerDrawerToggle}>
          <FilterIcon className="filter-icon" />
        </FlatButton>
        <TodoFilterDrawer
          open={this.state.isFilterOpen}
          handlerDrawerToggle={this.handlerDrawerToggle}
          filter={filter}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          draftFilter={draftFilter}
          applyDraftFilter={applyDraftFilter}
        />
      </div>
    )
  }
}

TodoFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  draftFilter: PropTypes.object.isRequired,
  applyDraftFilter: PropTypes.func.isRequired,
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired
}

export default TodoFilter
