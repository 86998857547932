import React from 'react'
import PropTypes from 'prop-types'

import './escalation.scss'

import Checkbox from 'material-ui/Checkbox'
import Subheader from 'material-ui/Subheader'
import FacilityFilter from './FacilityFilter'

const UnitsFilter = ({ onChange, currentUnits, facilities}) => {

  const checkboxes = values => {
    return values.map((unit, index) => (
      <Checkbox
        key={unit.exchangeName + '_' + index}
        onClick={() => onCheckChange(unit.name, !unit.checked)}
        style={{width: '150px'}}
        inputStyle={{marginRight: '0px'}}
        labelStyle={{marginLeft: '0px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
        label={unit.name}
        checked={unit.checked}
      />
    ))
  }

  const onCheckChange = (name, isChecked) => {

    let newUnits = currentUnits.map(unit => {
      if (name === 'ALL') {
        return {...unit, checked: isChecked}
      }

      if (unit.name === name) {
        return { ...unit, checked: isChecked }
      }

      return {...unit}
    })

    // now ensure All item 0 reflects all other items
    newUnits[0].checked = newUnits.slice(1).every(item => item.checked)

    onChange(newUnits)
  }

  const height = window.innerHeight - 220

  return (
    <div >
      <FacilityFilter onChange={onChange} currentUnits={currentUnits} facilities={facilities} />
      <Subheader className="filter-subheader">Units</Subheader>
        <div style={{height: height}} className="filter-items-units">
              {checkboxes(currentUnits)}
        </div>
    </div>
  )
}

UnitsFilter.propTypes = {
  onChange: PropTypes.func,
  currentUnits: PropTypes.array,
  facilities: PropTypes.array,
}

export default UnitsFilter
