/*eslint-disable react/no-set-state */
// We are disabling the set state because we are not going to store
// state of the username/password in the store. This is for security.

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { TextField, RaisedButton } from 'material-ui'
import {
  cyan500 as PrimaryColor,
  cyan900 as SecondaryColor,
} from 'material-ui/styles/colors'

import { authenticate } from '../ducks/user'
import { showErrorsPg } from '../ducks/errorspg'
import { LoadingIndicator } from '../components/loader'
import { initializeDatadogRum } from '../util/utils'

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('c3.views.LoginBox')

type LoginTypes = {
  username: string,
  password: string,
  loginHeader: string,
  messageHeader: string,
  message: string,
  loginButtonText: string,
}

function mapStateToProps(state) {
  return {
    authState: state.authState,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        authenticate,
        showErrorsPg,
      },
      dispatch
    ),
  }
}

@connect(
  mapStateToProps,
  mapDispatchToProps
)
export class LoginBox extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      authenticate: PropTypes.func.isRequired,
      showErrorsPg: PropTypes.func.isRequired,
    }),
    location: PropTypes.object,
    authState: PropTypes.shape({
      error: PropTypes.string,
      errors: PropTypes.array,
      token: PropTypes.string,
    }),
  }

  static defaultProps = {
    loginHeader: 'Care Advance',
    messageHeader: '',
    message: '',
    loginButtonText: 'Log in',
  }

  state = {
    appInfo: {
      hospitalName: '',
      tag: ''
    },
    username: '',
    password: '',
    redirectToReferrer: !!this.props.authState.token,
    errors: [],
    isLoading: false
  }

  async componentWillMount() {
    if (this.props.authState.token) {
      !this.isCancelled && this.setState({ redirectToReferrer: true })
    }

    try {
      const response = await fetch('/api/application_info')
      const respJson = await response.json()
      initializeDatadogRum(respJson.datadogConfig)
      this.setState({
        appInfo: {
          hospitalName: respJson.hospitalName,
          tag: respJson.tag
        }
      })
      if (respJson.tag && respJson.tag.trim() !== '') {
        document.title = 'Care Advance - ' + respJson.tag
      }
    } catch (ex) {
      document.title = 'Care Advance'
    }
  }

  componentDidMount() {
    this.userInput && this.userInput.focus()
  }
  componentWillUnmount() {
    this.isCancelled = true
  }

  props: LoginTypes

  handleUserNameChanged = e => {
    this.setState({
      username: e.target.value,
    })
  }

  handlePasswordChanged = e => {
    this.setState({
      password: e.target.value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { authenticate } = this.props.actions
    this.setState({ errors: [], isLoading: true })

    // Not using redux for username and password at the moment
    // The password should not be in the store, ever.
    authenticate(this.state.username, this.state.password)
      .then(
        () =>
          !this.isCancelled &&
          this.setState({
            redirectToReferrer: true,
            username: '',
            password: '',
            isLoading: false
          }),
            error => !this.isCancelled && this.setState({...error, password: '', isLoading: false})
      )
      .catch(error => {
        this.setState({ isLoading: false })
        logger.error(
          'An error occurred while trying to process the login',
          error
        )
        const message =
          error && (error.text || error.message || JSON.stringify(error))
        this.props.actions.showErrorsPg([
          { key: 'Post Login Error', code: -1, message },
        ])
      })
  }

  renderLoginHeader = () => {
    const { loginHeader } = this.props
    const envTag = this.state.appInfo.tag ? ` - ${this.state.appInfo.tag}` : ''

    return (
      <h3 style={{ width: '100%', textAlign: 'center' }}>
        {loginHeader}{envTag}
      </h3>
    )
  }

  renderHospitalName = () => {
    let hospName = null
    if (this.state.appInfo.hospitalName) {
      hospName = (
        <h4 style={{ width: '100%', textAlign: 'center' }}>
          {this.state.appInfo.hospitalName}
        </h4>
      )
    }
    return hospName
  }

  render() {
    const {
      messageHeader,
      loginButtonText,
      message,
      location: { state: { from, errors: routingErrors = [] } = {} } = {},
      authState: { errors: authErrors = [] },
    } = this.props

    const {
      username,
      password,
      redirectToReferrer,
      errors: credentialErrors,
    } = this.state

    const errors = credentialErrors.length
      ? credentialErrors
      : authErrors.length
      ? authErrors
      : routingErrors

    if (redirectToReferrer) {
      return <Redirect to={from || '/'} />
    }

    return (
      <div style={{ padding: '1em' }}>
        <form
          style={{ width: '50%', margin: 'auto' }}
          onSubmit={this.handleSubmit}>
          {this.renderLoginHeader()}
          {this.renderHospitalName()}
          <div>
            <TextField
              ref={input => (this.userInput = input)}
              tabIndex={1}
              className="no-webkit-autofill"
              floatingLabelFocusStyle={{ color: SecondaryColor }}
              underlineFocusStyle={{ borderColor: PrimaryColor }}
              style={{ width: '100%' }}
              floatingLabelText="Username"
              type="text"
              onChange={this.handleUserNameChanged}
              value={username}
              autoCapitalize="none"
              autoCorrect="off"
            />
          </div>

          <div>
            <TextField
              ref="password"
              tabIndex={2}
              className="no-webkit-autofill"
              floatingLabelFocusStyle={{ color: SecondaryColor }}
              underlineFocusStyle={{ borderColor: PrimaryColor }}
              style={{ width: '100%' }}
              floatingLabelText="Password"
              type="password"
              onChange={this.handlePasswordChanged}
              value={password}
            />
          </div>

          <div>
            <RaisedButton
              tabIndex={3}
              type="submit"
              backgroundColor={PrimaryColor}
              style={{ width: '100%', color: 'white', marginTop: 10 }}>
              {loginButtonText}
            </RaisedButton>
          </div>

          { this.state.isLoading ? (
            <LoadingIndicator style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '15px'
            }}/>
          ) : null}

          <div
            style={{
              visibility: errors && errors.length ? 'visible' : 'hidden',
              marginTop: 10,
            }}>
            <div>
              {/* TODO: Display errors properly, and with styles*/}
              {errors.map((error, i) => (
                <div key={i}>{error.message}</div>
              ))}
            </div>
          </div>

          <div
            style={{
              visibility: message ? 'visible' : 'hidden',
              marginTop: 10,
            }}>
            <div>
              <h4 style={{ visibility: messageHeader ? 'visible' : 'hidden' }}>
                {messageHeader}
              </h4>
              {message}
            </div>
          </div>
        </form>
      </div>
    )
  }
}
