import React from 'react'
import { IconButton } from 'material-ui'
import ActionStatusIcon from './ActionStatusIcon'
import PropTypes from 'prop-types'

const ActionButtonsColumn = ({ id, status, onUpdateActionStatus }) => {
  const completedButtonNewStatus = status === 'Completed' ? 'Open' : 'Completed'
  const naButtonNewStatus = status === 'NA' ? 'Open' : 'NA'

  return (
    <td className="statusColumnCell">
      <div className="statusColumnButtonWrapper">
        <IconButton className="statusIconButton"
                    onClick={() => onUpdateActionStatus(id, completedButtonNewStatus)}>
          <ActionStatusIcon actionStatus={status} iconType="Completed" />
        </IconButton>
        <IconButton className="statusIconButton"
                    onClick={() => onUpdateActionStatus(id, naButtonNewStatus)}>
          <ActionStatusIcon actionStatus={status} iconType="NA" />
        </IconButton>
      </div>
    </td>
  )
}

ActionButtonsColumn.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string,
  onUpdateActionStatus: PropTypes.func.isRequired
}

export default ActionButtonsColumn
