import React from 'react'
import PropTypes from 'prop-types'
import Subheader from 'material-ui/Subheader'
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton'

const StatusFilter = ({ onChange, selected }) => {

  return (
    <div className="filter-container">
      <Subheader className="filter-subheader">Status</Subheader>
      <RadioButtonGroup
        key="statusFilter"
        name="statusFilter"
        valueSelected={selected}
        className="filter-items"
        defaultSelected="All">
        <RadioButton
          value="All"
          label="All"
          onClick={onChange}
        />
        <RadioButton
          value="Open"
          label="Open"
          onClick={onChange}
        />
        <RadioButton
          value="NA"
          label="N/A"
          onClick={onChange}
        />
        <RadioButton
          value="Completed"
          label="Completed"
          onClick={onChange}
        />
      </RadioButtonGroup>
    </div>
  )
}

StatusFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired
}

export default StatusFilter
