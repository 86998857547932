/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type VisibilityBoardLocationStatusesDisplay = "HIDDEN" | "VISIBLE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type VisibilityBoardRow_rowData$ref: FragmentReference;
declare export opaque type VisibilityBoardRow_rowData$fragmentType: VisibilityBoardRow_rowData$ref;
export type VisibilityBoardRow_rowData = {|
  +id: string,
  +name: string,
  +description: string,
  +boardTemplate: {|
    +id: string,
    +name: string,
  |},
  +departmentExchangeName: string,
  +bedExchangeNames: $ReadOnlyArray<string>,
  +footer: ?string,
  +scrollDuration: ?number,
  +timeHeaderFormat: ?string,
  +locationStatusesDisplay: ?VisibilityBoardLocationStatusesDisplay,
  +$refType: VisibilityBoardRow_rowData$ref,
|};
export type VisibilityBoardRow_rowData$data = VisibilityBoardRow_rowData;
export type VisibilityBoardRow_rowData$key = {
  +$data?: VisibilityBoardRow_rowData$data,
  +$fragmentRefs: VisibilityBoardRow_rowData$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VisibilityBoardRow_rowData",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardTemplate",
      "kind": "LinkedField",
      "name": "boardTemplate",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "departmentExchangeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bedExchangeNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "footer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scrollDuration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeHeaderFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locationStatusesDisplay",
      "storageKey": null
    }
  ],
  "type": "VisibilityBoard",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '5a7e94feceef5745af770253117d84c0';

module.exports = node;
