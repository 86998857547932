/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DepartmentType = "AdmissionSource" | "Unit" | "%future added value";
export type EscalationFilterUnitNameQueryVariables = {||};
export type EscalationFilterUnitNameQueryResponse = {|
  +viewer: {|
    +facilities: $ReadOnlyArray<{|
      +name: string,
      +departments: {|
        +nodes: ?$ReadOnlyArray<{|
          +name: string,
          +exchangeName: string,
          +type: DepartmentType,
        |}>
      |},
    |}>
  |}
|};
export type EscalationFilterUnitNameQuery = {|
  variables: EscalationFilterUnitNameQueryVariables,
  response: EscalationFilterUnitNameQueryResponse,
|};
*/


/*
query EscalationFilterUnitNameQuery {
  viewer {
    facilities {
      name
      departments(first: 1000) {
        nodes {
          name
          exchangeName
          type
          id
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EscalationFilterUnitNameQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Facility",
            "kind": "LinkedField",
            "name": "facilities",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "DepartmentConnection",
                "kind": "LinkedField",
                "name": "departments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "departments(first:1000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EscalationFilterUnitNameQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Facility",
            "kind": "LinkedField",
            "name": "facilities",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "DepartmentConnection",
                "kind": "LinkedField",
                "name": "departments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Department",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "departments(first:1000)"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cdbee0aa448fb634f4955cd767ee3fad",
    "id": null,
    "metadata": {},
    "name": "EscalationFilterUnitNameQuery",
    "operationKind": "query",
    "text": "query EscalationFilterUnitNameQuery {\n  viewer {\n    facilities {\n      name\n      departments(first: 1000) {\n        nodes {\n          name\n          exchangeName\n          type\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cbfcdc2ef49c08b37a66105f48ae4ede';

module.exports = node;
