/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import PropTypes from 'prop-types'
import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import IconButton from 'material-ui/IconButton'
import { StatefulTextField } from '../../components/common/StatefulTextField'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import { addVisibilityBoardEntry } from '../../graphql/relay/queries/visibilityBoard'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import {environment} from "../../graphql/relay/env"
import { bindActionCreators } from 'redux'
import { showErrorsPg } from '../../ducks/errorspg'
import {connect} from 'react-redux'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Json2Toml from 'json2toml'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showErrorsPg
      },
      dispatch
    ),
  }
}

class AddVisibilityBoardEntry extends Component {
  static propTypes = {
    data: PropTypes.exact({
      templates: PropTypes.array.isRequired,
      departmentItems: PropTypes.array.isRequired,
      bedMap: PropTypes.object.isRequired
    }),
    onRequestClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      showErrorsPg: PropTypes.func
    }),
  }
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      name: null,
      description: null,
      footer: null,
      scrollDuration: null,
      timeHeaderFormat: 'ddd MMM DD, YYYY HH:mm',
      locationStatusesDisplay: "VISIBLE",
      templateId: null,
      departmentExchangeName: null,
      bedExchangeNames: []
    }
  }

  handleError = (errKey, errCode, errMessage) => {
    this.setState({
      isSubmitting: false
    })
  }

  handleNameUpdate = e => {
    this.setState({
      name: e.target.value,
    })
  }

  handleDescriptionUpdate = e => {
    this.setState({
      description: e.target.value,
    })
  }


  handleFooterUpdate = e => {
    this.setState({
      footer: e.target.value,
    })
  }


  handleScrollDurationUpdate = (event, index, value) => {
    this.setState({
      scrollDuration: value
    })
  }

  handleTimeHeaderFormatUpdate = e => {
    this.setState({
      timeHeaderFormat: e.target.value,
    })
  }

  handleLocationStatusesDisplayUpdate = (event, index, value) => {
    this.setState({
      locationStatusesDisplay: value
    })
  }


  handleDepartmentExchangeNameUpdate = (event, index, value) => {
    this.setState({
      departmentExchangeName: value,
      bedExchangeNames: []
    })
  }

  handleBedExchangeNamesUpdate = (event, index, value) => {
    const bedExchangeNameSelected = value[0]
    const found = this.state.bedExchangeNames.find((bedExchangeName) => bedExchangeName === bedExchangeNameSelected)
    //we are sorting the final result so that comparison are made easier later on
    if(typeof found === "undefined") {
      this.setState({
        bedExchangeNames: [...this.state.bedExchangeNames, bedExchangeNameSelected].sort()
      })
    } else {
      this.setState({
        bedExchangeNames: this.state.bedExchangeNames.filter((bedExchangeName) => bedExchangeName !== bedExchangeNameSelected).sort()
      })
    }
  }

  handleTemplateIdUpdate = (event, index, value)=> {
    this.setState({
      templateId: value
    })
  }

  onApplyHandler = () => {
    this.setState({
      isSubmitting: true
    })
    const variables = {
      visibilityBoardInput: {
        name: this.state.name,
        description: this.state.description,
        footer: this.state.footer,
        scrollDuration: ((duration) => {
          if(this.state.scrollDuration && this.state.scrollDuration !== '') {
            return duration
          } else {
            return null
          }
        })(this.state.scrollDuration),
        timeHeaderFormat: this.state.timeHeaderFormat,
        locationStatusesDisplay: this.state.locationStatusesDisplay,
        templateId: this.state.templateId,
        departmentBedConfig: Json2Toml({
          department: this.state.departmentExchangeName,
          beds: this.state.bedExchangeNames
        })
      }
    }

    const updater = (proxyStore) => {
      const store = proxyStore.getRootField("visibilityBoardMutation")
      const payload = store.getLinkedRecord("addVisibilityBoardEntry", variables)
      const rootStore = proxyStore.get("client:root")
      const visibilityBoardsList = rootStore.getLinkedRecords('visibilityBoards')
      rootStore.setLinkedRecords([payload, ...visibilityBoardsList],'visibilityBoards')
    }

    handleMutation(
      environment,
      addVisibilityBoardEntry,
      variables,
      () => {
        this.props.onRequestClose && this.props.onRequestClose()
      },
      undefined,
      this.handleError,
      updater
    )
  }

  render() {
    const { title, onRequestClose, data } = this.props

    const {
      name,
      description,
      footer,
      scrollDuration,
      timeHeaderFormat,
      locationStatusesDisplay,
      templateId,
      departmentExchangeName,
      bedExchangeNames
    } = this.state

    const templateItems = data.templates.map(template =>
      <MenuItem key={template.id} value={template.id} primaryText={template.name} />,
    )

    const locationStatusesDisplayItems = [
      <MenuItem key={"Visible"} value={"VISIBLE"} primaryText={"Visible"} />,
      <MenuItem key={"Hidden"} value={"HIDDEN"} primaryText={"Hidden"} />,
    ]


    const scrollDurationDisplayItems = [
      <MenuItem key={"5s"} value={"5"} primaryText={"5s"} />,
      <MenuItem key={"10s"} value={"10"} primaryText={"10s"} />,
      <MenuItem key={"15s"} value={"15"} primaryText={"15s"} />,
      <MenuItem key={"20s"} value={"20"} primaryText={"20s"} />,
      <MenuItem key={"25s"} value={"25"} primaryText={"25s"} />,
      <MenuItem key={"30s"} value={"30"} primaryText={"30s"} />,
      <MenuItem key={"35s"} value={"35"} primaryText={"35s"} />,
      <MenuItem key={"40s"} value={"40"} primaryText={"40s"} />,
      <MenuItem key={"45s"} value={"45"} primaryText={"45s"} />,
      <MenuItem key={"50s"} value={"50"} primaryText={"50s"} />,
      <MenuItem key={"55s"} value={"55"} primaryText={"55s"} />,
      <MenuItem key={"60s"} value={"60"} primaryText={"60s"} />,
      <MenuItem key={"65s"} value={"65"} primaryText={"65s"} />,
      <MenuItem key={"70s"} value={"70"} primaryText={"70s"} />,
      <MenuItem key={"75s"} value={"75"} primaryText={"75s"} />,
      <MenuItem key={"80s"} value={"80"} primaryText={"80s"} />,
      <MenuItem key={"85s"} value={"85"} primaryText={"85s"} />,
      <MenuItem key={"90s"} value={"90"} primaryText={"90s"} />,
    ]

    const departmentItems = data.departmentItems
    const bedMap = data.bedMap

    const anyRequiredValueUnPopulated = !name || !description || !templateId || !departmentExchangeName || bedExchangeNames.length === 0

    const applyDisabled = anyRequiredValueUnPopulated

    const applyButtonStyle = !applyDisabled && {
      labelStyle: {
        color: 'white',
      },
    }


    const actions = [
      <FlatButton
        key="apply"
        label="Apply"
        disabled={applyDisabled}
        {...applyButtonStyle}
        onClick={this.onApplyHandler}
      />,
    ]

    return (
      <div>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div className="row">
          <div className="col-xs-1" />
          <div className="col-xs-5">
            <StatefulTextField
              text={name}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Name *',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Name *'
              }}
              onChange={this.handleNameUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <StatefulTextField
              text={description}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Description *',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Description *'
              }}
              onChange={this.handleDescriptionUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <SelectField
              fullWidth
              floatingLabelShrinkStyle={{whiteSpace: 'nowrap'}}
              floatingLabelText="Template *"
              value={templateId}
              onChange={this.handleTemplateIdUpdate}
            >
              {templateItems}
            </SelectField>
            <StatefulTextField
              text={footer}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Footer',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Footer'
              }}
              onChange={this.handleFooterUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <SelectField
              fullWidth
              floatingLabelShrinkStyle={{whiteSpace: 'nowrap'}}
              floatingLabelText="Scroll Duration"
              value={scrollDuration}
              onChange={this.handleScrollDurationUpdate}
            >
              {scrollDurationDisplayItems}
            </SelectField>
          </div>
          <div className="col-xs-5">
            <StatefulTextField
              text={timeHeaderFormat}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Time Header Format',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Time Header Format'
              }}
              onChange={this.handleTimeHeaderFormatUpdate}
              maxNumberOfChars={60}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <SelectField
              fullWidth
              floatingLabelShrinkStyle={{whiteSpace: 'nowrap'}}
              floatingLabelText="Location Statuses Display"
              value={locationStatusesDisplay}
              onChange={this.handleLocationStatusesDisplayUpdate}
            >
              {locationStatusesDisplayItems}
            </SelectField>
            <SelectField
              fullWidth
              floatingLabelShrinkStyle={{whiteSpace: 'nowrap'}}
              floatingLabelText="Department *"
              value={departmentExchangeName}
              onChange={this.handleDepartmentExchangeNameUpdate}
            >
              {departmentItems}
            </SelectField>
            <SelectField
              disabled={!bedMap[departmentExchangeName]}
              floatingLabelText="Beds *"
              fullWidth
              onChange={this.handleBedExchangeNamesUpdate}
              multiple>
              {bedMap[departmentExchangeName] && bedMap[departmentExchangeName].map(bed => (
                <MenuItem
                  key={bed.exchangeName}
                  value={bed.exchangeName}
                  primaryText={<div className="menu-item">{bed.name}</div>}
                  checked={typeof bedExchangeNames.find((bedExchangeName) => bedExchangeName === bed.exchangeName) !== "undefined"}
                  insetChildren
                />
              ))}
            </SelectField>
            <span>{bedExchangeNames.length} bed(s) selected</span>
          </div>
          <div className="col-xs-1" />
        </div>
      </div>
    )
  }

}
export default connect(null, mapDispatchToProps)(AddVisibilityBoardEntry)
