/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_removeTimeToDecision_MutationVariables = {|
  visitId: any
|};
export type visit_removeTimeToDecision_MutationResponse = {|
  +visitMutation: ?{|
    +removeTimeToDecision: ?{|
      +id: string,
      +decisionTime: ?any,
      +meta: {|
        +dischargeToday: boolean,
        +tlosExceeded: boolean,
        +plannedDischargeExceeded: boolean,
        +decisionDue: boolean,
        +timeElapsedSinceAdmission: any,
      |},
      +plan: ?{|
        +id: string,
        +milestones: $ReadOnlyArray<?{|
          +id: string,
          +dueDate: ?any,
        |}>,
      |},
    |}
  |}
|};
export type visit_removeTimeToDecision_Mutation = {|
  variables: visit_removeTimeToDecision_MutationVariables,
  response: visit_removeTimeToDecision_MutationResponse,
|};
*/


/*
mutation visit_removeTimeToDecision_Mutation(
  $visitId: Long!
) {
  visitMutation {
    removeTimeToDecision(visitId: $visitId) {
      id
      decisionTime
      meta {
        dischargeToday
        tlosExceeded
        plannedDischargeExceeded
        decisionDue
        timeElapsedSinceAdmission
        id
      }
      plan {
        id
        milestones {
          id
          dueDate
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "visitId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionTime",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeToday",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlosExceeded",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedDischargeExceeded",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionDue",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeElapsedSinceAdmission",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestones",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_removeTimeToDecision_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "removeTimeToDecision",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "visit_removeTimeToDecision_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "removeTimeToDecision",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a8f2f0ab20e07c06a240c411fba827f",
    "id": null,
    "metadata": {},
    "name": "visit_removeTimeToDecision_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_removeTimeToDecision_Mutation(\n  $visitId: Long!\n) {\n  visitMutation {\n    removeTimeToDecision(visitId: $visitId) {\n      id\n      decisionTime\n      meta {\n        dischargeToday\n        tlosExceeded\n        plannedDischargeExceeded\n        decisionDue\n        timeElapsedSinceAdmission\n        id\n      }\n      plan {\n        id\n        milestones {\n          id\n          dueDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0ffb6d3c43b4ac9f3699bc3d9473e25';

module.exports = node;
