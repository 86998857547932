export const ACKNOWLEDGED_MSG_STATUS = 'ACKNOWLEDGED'
export const NEEDS_REASSIGNMENT_MSG_STATUS = 'NEEDS_REASSIGNMENT'
export const UNACKNOWLEDGED_MSG_STATUS = 'UNACKNOWLEDGED'

export const EMAIL_DELIVERY = 'EMAIL'
export const APP_DELIVERY = 'RESOLVE'

export const MESSAGE_SENT_MSG_SUB_TYPE = 'BARRIER_INFO_SENT_RECIPIENT_NOTIFIED'
export const BARRIER_OWNER_ASSIGNED_SUB_TYPE = 'BARRIER_OWNER_ASSIGNED_OWNER_NOTIFIED'
export const ACKNOWLEDGED_MSG_SUBTYPE = 'BARRIER_ACKNOWLEDGED'
export const RESOLVED_MSG_SUBTYPE = 'BARRIER_RESOLVED'
export const NEW_NOTE_MSG_SUBTYPE = 'BARRIER_NOTE_ADDED'
export const NEEDS_REASSIGNMENT_MSG_SUBTYPE = 'BARRIER_REASSIGNMENT_NEEDED'
export const BARRIER_REOPENED_MSG_SUBTYPE = 'BARRIER_REOPENED'
