/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'

import { SelectField, MenuItem } from 'material-ui'
import { grey900 as HintColor } from 'material-ui/styles/colors'
import { Date2 } from '../../components/date'
import { AuditUser } from '../audit'

import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('ReadmissionVisit', theme => {
  return {
    updatedBy: {
      textAlign: 'right',
    },
  }
})

export const ReadmissionVisit = (
  {
    visit,
    onReadmissionChange,
    readmissionVisit,
    readmissionVerifiedBy,
    readmissionUpdatedWhen,
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)

  const items = [
    <MenuItem key={false} value={false} primaryText="No" />,
    <MenuItem key={true} value={true} value primaryText="Yes" />,
  ]
  const readmissionEdited = !(readmissionVisit === null)
  const marginTop = readmissionVisit === null ? '-14px' : '-14px'

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xs-6" style={{ marginTop: marginTop }}>
          <SelectField
            value={readmissionVisit}
            onChange={(event, index, value) => {
              value !== visit.readmission &&
                onReadmissionChange(visit.id, value)
            }}
            hintText="Not Set"
            hintStyle={{ color: HintColor }}>
            {items}
          </SelectField>
        </div>
        {readmissionEdited && (
          <div className="col-xs-6">
            <div className={classes.updatedBy}>
              Updated: <Date2 dateString={readmissionUpdatedWhen} /> By:{' '}
              <AuditUser user={readmissionVerifiedBy} emptyText="System" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ReadmissionVisit.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

ReadmissionVisit.propTypes = {
  visit: PropTypes.object.isRequired,
  onReadmissionChange: PropTypes.func.isRequired,
  readmissionVisit: PropTypes.bool,
  readmissionVerifiedBy: PropTypes.shape({
    userName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  readmissionUpdatedWhen: PropTypes.string,
}
