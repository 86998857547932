/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import './escalation.scss'

import FlatButton from 'material-ui/FlatButton'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import FilterDrawer from './FilterDrawer'
import { workspaces } from './../../types'
import {setFilter, resetFilter, setQuery, getQueryString} from '../../ducks/escalation-filters'
import FilterHeader from './FilterHeader'
import FilterSortDropdown from './FilterSortDropdown'

import { graphql, fetchQuery } from 'react-relay'
import { environment } from '../../graphql/relay/env'
import get from 'lodash.get'

class EscalationFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDrawerOpen: false,
      departments: [false],
      barrierOwners: [false],
      facilities: [false],
    }
  }
  componentDidMount() {
    this.fetchData()
  }
  componentWillUnmount() {
    this.isCancelled = true
  }
  fetchData = () => {
    const UnitNamePromise = fetchQuery(environment, UnitNameQuery, {}).then(
      data => {
        let departmentNames = []
        let allDepartmentsNode = {
          checked: true,
          exchangeName: 'All',
          name: 'ALL',
          type: 'Unit',
        }
        departmentNames.push(allDepartmentsNode)

        const facilities = get(data, 'viewer.facilities')
        facilities.forEach(fac => {
          const departments = get(fac, 'departments.nodes')
          for (let department in departments) {
            if (departments[department].name) {
              let departmentClone = { ...departments[department], checked: true }
              departmentNames.push(departmentClone)
            }
          }

        })
        return {facilities, departments: departmentNames}
      }
    )

    Promise.all([UnitNamePromise]).then(values => {
      !this.isCancelled &&
        this.setState({
          departments: Object.values(values[0]["departments"]),
          facilities: Object.values(values[0]["facilities"]),
        })
    })
  }

  handleDrawerToggle = () => {
    /*eslint-disable */
    !this.isCancelled &&
      this.setState((prevState, props) => {
        return { isDrawerOpen: !prevState.isDrawerOpen }
      })

    /*eslint-enable */
  }

  formatFilter = () => {}

  applyFilter = filter => {
    this.props.setFilter(filter)
    this.handleDrawerToggle()
  }

  applyQuery = query => {
    this.props.setQuery(query)
  }

  resetFilter = () => {
    this.props.resetFilter(this.props.workspaces)
    this.handleDrawerToggle()
  }

  handleSortChange = (event, index, value) => {
    const newFilter = {
      ...this.props.filter,
      sortField: value
    }
    this.props.setFilter(newFilter)
    this.applyQuery(getQueryString(newFilter))
  }

  render() {
    const { isDrawerOpen } = this.state
    const { resultsLength, filter } = this.props

    return (
      <div className="escalation-filter">
        <FilterHeader
          resultsLength={resultsLength}
          filter={this.props.filter}
          nameBedQuery={this.props.nameBedQuery}
        />
        <FilterSortDropdown
          sortOptions={filter.sortOptions}
          selectedValue={filter.sortField}
          admissionType={filter.admissionType}
          onChange={this.handleSortChange}
        />
        <div>
          <FlatButton
            className="filter-button"
            label="FILTER"
            primary
            onClick={this.handleDrawerToggle}
          />
        </div>
        <FilterDrawer
          open={isDrawerOpen}
          handleDrawerToggle={this.handleDrawerToggle}
          applyFilter={this.applyFilter}
          resetFilter={this.resetFilter}
          applyQuery={this.applyQuery}
          filter={filter}
          departments={this.state.departments}
          facilities={this.state.facilities}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  filter: state.escalationView.filter,
  nameBedQuery: state.escalationView.escalationNameBedQuery
})

EscalationFilter.propTypes = {
  workspaces: workspaces,
  departments: PropTypes.arrayOf(PropTypes.object),
  facilities: PropTypes.arrayOf(PropTypes.object),
  resultsLength: PropTypes.string,
  filter: PropTypes.object,
  escalationNameBedQuery: PropTypes.string,
  setFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  setQuery: PropTypes.func,
  nameBedQuery: PropTypes.string,
}

export default connect(
  mapStateToProps,
  { setFilter, resetFilter, setQuery }
)(EscalationFilter)
const EscalationFilterQuery = graphql`
  query EscalationFilterQuery {
    viewer {
      beds(first: 10000, query: null, type: ESCALATIONS) {
        nodes {
          department {
            name
          }
          visit {
            plan {
              barriers {
                owner
                # ownerRole {
                #   id
                #   code
                #   value
                # }
              }
            }
          }
        }
      }
    }
  }
`

const UnitNameQuery = graphql`
  query EscalationFilterUnitNameQuery {
    viewer {
      facilities {
        name
        departments(first: 1000) {
          nodes {
            name
            exchangeName
            type
          }
        }
      }
    }
  }
`

const BarrierOwnerQuery = graphql`
  query EscalationFilterBarrierOwnerQuery {
    viewer {
      beds(first: 10000, query: null, type: BARRIER) {
        nodes {
          visit {
            plan {
              barriers {
                owner
              }
            }
          }
        }
      }
    }
  }
`
