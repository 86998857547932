import React from 'react'
import PropTypes from 'prop-types'

import NAIcon from 'mdi-react/CloseCircleIcon'
import NAUnselectedIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon'
import OpenIcon from 'mdi-react/CheckboxBlankCircleOutlineIcon'
import CompletedIcon from 'mdi-react/CheckboxMarkedCircleIcon'
import {
  green500 as OpenColor,
  green500 as CompletedColor,
  grey600 as NAColor,
} from 'material-ui/styles/colors'

const colors = { OpenColor, CompletedColor, NAColor }

const icons2 = {
  Open: {
    Completed: OpenIcon,
    NA: NAUnselectedIcon
  },
  Completed: {
    Completed: CompletedIcon,
    NA: NAUnselectedIcon
  },
  NA: {
    Completed: OpenIcon,
    NA: NAIcon
  }
}

const ActionStatusIcon = ({
  actionStatus = 'NA',
  iconType = 'Completed',
  overdue = false,
  style = {},
  ...props
}) => {
  const Icon = icons2[actionStatus][iconType]
  const fillColor = colors[`${iconType}Color`]
  return (
    <Icon
      style={{
        ...style,
        fill: fillColor,
      }}
      {...props}
      height="32px"
      width="32px"
    />
  )
}

ActionStatusIcon.propTypes = {
  actionStatus: PropTypes.string,
  iconType: PropTypes.string,
  overdue: PropTypes.bool,
  style: PropTypes.object,
}

export default ActionStatusIcon
