import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import {createFragmentContainer, graphql} from 'react-relay'
import {bindActionCreators} from 'redux'
import {showDrawer} from '../../../ducks/views'
import {connect} from 'react-redux'
import {drawers} from '../../drawer-component-mapping'
import { addBarrierCategory, modifyBarrierCategoryEntry } from '../../../graphql/relay/queries/barrierCategory'
import BarrierCategoryTable from './BarrierCategoryTable'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer
      },
      dispatch
    ),
  }
}

const BarrierCategoryEditor = ({ list, barrierSourceTypeConfigs, actions }) => {

  const handleAdd = () => {
    const drawerTitle = "Create a Barrier Category"
    const data = {
      code: '',
      value: '',
      ordinal: getMaxSortOrder(),
      active: true,
      sourceTypes: setupSourceTypes(barrierSourceTypeConfigs, [])
    }
    openDrawer(drawerTitle, false, data, addBarrierCategory)
  }

  const handleEdit = (entry) => {
    const drawerTitle = "Edit a Barrier Category"
    const data = {
      ...entry,
      sourceTypes: setupSourceTypes(barrierSourceTypeConfigs, entry.sourceTypes)
    }
    openDrawer(drawerTitle, true, data, modifyBarrierCategoryEntry)
  }

  const openDrawer = (drawerTitle, isEdit, data, mutation) => {
    actions.showDrawer(drawers.BARRIER_CATEGORY_DRAWER, {
      title: drawerTitle,
      isEdit,
      data,
      mutation
    })
  }

  const setupSourceTypes = (barrierSourceTypeConfigs, selectedSourceTypes) => {
    return barrierSourceTypeConfigs.map(type => {
      const isSelected = selectedSourceTypes.find(selectedSource => selectedSource.code === type.code)
      return {...type, checked: !!isSelected}
    })
  }

  const getMaxSortOrder = () => {
    const sortArray = list.map(item => item.ordinal)
    const max = Math.max(...sortArray)
    return max ? max + 1 : 1
  }

  return (
    <StyledWrapperDiv>
      <StyledTitleSection>
        <h1>Barrier Categories</h1>
        <StyledAddButton onClick={handleAdd}>
          <AddCircleOutlineIcon/>
        </StyledAddButton>
      </StyledTitleSection>
      <BarrierCategoryTable list={list} onEdit={handleEdit} />
    </StyledWrapperDiv>
  )
}

const StyledWrapperDiv = styled.div`
  padding: 20px 20px 0 20px;

  svg {
    font-size: 24px;
  }
`

const StyledTitleSection = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin: 0;
  }
`

const StyledAddButton = styled(IconButton)`
  && {
    padding: 8px;
    height: 40px;
    width: 40px;
  }
`

BarrierCategoryEditor.propTypes = {
  list: PropTypes.array,
  barrierSourceTypeConfigs: PropTypes.array,
  actions: PropTypes.shape({
    showDrawer: PropTypes.func
  }),
}

const BarrierCategoryEditorRedux = connect(null, mapDispatchToProps)(BarrierCategoryEditor)
export default createFragmentContainer(BarrierCategoryEditorRedux, {
  barrierSourceTypeConfigs: graphql`
    fragment BarrierCategoryEditor_barrierSourceTypeConfigs on BarrierSourceType @relay(plural: true) {
      id
      code
      value
      ordinal
      active
      activeDirectorySourceName
    }
  `,
  list: graphql`
    fragment BarrierCategoryEditor_list on BarrierCategory @relay(plural: true) {
      ...BarrierCategoryTable_list
      ordinal
    }
  `
})

