function highlightQuery(query, errors) {
  const locations = errors
    .map(function(e) {
      return e.locations
    })
    .reduce(function(a, b) {
      return a.concat(b)
    }, [])

  let queryHighlight = ''

  query.split('\n').forEach(function(row, index) {
    const line = index + 1
    const lineErrors = locations.filter(function(loc) {
      return loc && loc.line === line
    })

    queryHighlight += row + '\n'

    if (lineErrors.length) {
      const errorHighlight = []

      lineErrors.forEach(function(line) {
        for (let i = 0; i < 8; i++) {
          errorHighlight[line.column + i] = '~'
        }
      })

      for (let i = 0; i < errorHighlight.length; i++) {
        queryHighlight += errorHighlight[i] || ' '
      }
      queryHighlight += '\n'
    }
  })

  return queryHighlight
}

module.exports = function(params) {
  require('isomorphic-fetch')
  if (!params.url) throw new Error('Missing url parameter')

  const headers = new Headers(params.headers)
  headers.append('Content-Type', 'application/json')

  return {
    query: function(query, variables, onResponse) {
      // Jammed in the header, there is no fancy middleware here.

      const authToken = sessionStorage.getItem('authToken')

      if (authToken != null) {
        headers.set('Authorization', authToken)
      }

      const req = new Request(params.url, {
        method: 'POST',
        body: JSON.stringify({
          query: query,
          variables: variables,
        }),
        headers: headers,
        credentials: params.credentials,
      })

      return fetch(req)
        .then(function(res) {
          onResponse && onResponse(req, res)

          return res.json()
        })
        .then(function(body) {
          if (body.errors && body.errors.length) {
            body.highlightQuery = highlightQuery(query, body.errors)
          }

          return body
        })
    },
  }
}
