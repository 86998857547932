import React from 'react'
import PropTypes from 'prop-types'
import './jvion.scss'
import { Chip, Subheader, Divider, Checkbox } from 'material-ui'
import { List, ListItem } from 'material-ui/List'
import classNames from 'classnames'
import { isJvionSetRecommendations } from '../../util/configs'

const RiskFactors = ({ riskFactors }) => {
  const groupedRiskFactors = Object.entries(riskFactors.groupBy('type'))

  return (
    <div>
      <Subheader style={{ fontSize: '16px', color: '#6a6a6a' }}>
        {' '}
        Risk Factors{' '}
      </Subheader>
      {groupedRiskFactors.map(rf => (
        <div key={rf[0]} className="risk-factor">
          <Subheader>{rf[0]}</Subheader>
          <div className="risk-factor-desc">
            {rf[1].map(rf => (
              <Chip style={{ margin: 3 }} key={rf.description}>
                {rf.description}
              </Chip>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const Recommendations = ({
  recommendations,
  patientId,
  encounterId,
  conditionName,
  onAcceptRecommendation,
  loadId,
  expired,
}) => {
  return (
    <div>
      <List>
        <Subheader style={{ fontSize: '16px', color: '#6a6a6a' }}>
          Recommendations
        </Subheader>
        {recommendations.map(rec => (
          <div key={rec.id} className="recommendation">
            <Recommendation
              recommendation={rec}
              patientId={patientId}
              encounterId={encounterId}
              conditionName={conditionName}
              loadId={loadId}
              expired={expired}
              onAcceptRecommendation={onAcceptRecommendation}
            />
          </div>
        ))}
      </List>
    </div>
  )
}

const Recommendation = ({
  recommendation,
  patientId,
  encounterId,
  conditionName,
  onAcceptRecommendation,
  loadId,
  expired,
}) => {
  return (
    <ListItem
      primaryText={recommendation.description}
      style={{ display: 'inline-block' }}
      leftCheckbox={
        isJvionSetRecommendations() ? (
          <Checkbox
            checked={recommendation.accepted}
            disabled={expired}
            onClick={() => {
              onAcceptRecommendation(
                {
                  patientId,
                  encounterId,
                  conditionName,
                  code: recommendation.code,
                },
                !recommendation.accepted,
                loadId,
              )
            }}
          />
        ) : null
      }
    />
  )
}

const Vector = ({
  vector,
  patientId,
  encounterId,
  onAcceptRecommendation,
  loadId,
  expired,
}) => {
  const { name, description, riskBracket, riskFactors, recommendations } = vector

  const highRisk = riskBracket === 'High Risk'
  const midRisk = riskBracket === 'Medium Risk'
  const lowRisk = riskBracket === 'Low Risk'

  return (
    <div className="vector">
      <div className="header2">
        <span className="jvlabel">{description}</span>
        <span
          style={{ paddingLeft: '10px' }}
          className={classNames(
            'jvlabel',
            { 'high-risk': highRisk },
            { 'mid-risk': midRisk },
            { 'low-risk': lowRisk }
          )}>
          {riskBracket}
        </span>
      </div>
      <Recommendations
        recommendations={recommendations}
        patientId={patientId}
        encounterId={encounterId}
        conditionName={name}
        loadId={loadId}
        expired={expired}
        onAcceptRecommendation={onAcceptRecommendation}
      />
      <RiskFactors riskFactors={riskFactors} />
      <Divider />
    </div>
  )
}

Vector.propTypes = {
  vector: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    recommendations: PropTypes.array.isRequired,
    riskFactors: PropTypes.array.isRequired,
  }).isRequired,
  patientId: PropTypes.string,
  encounterId: PropTypes.string,
  onAcceptRecommendation: PropTypes.func.isRequired,
  loadId: PropTypes.number,
  expired: PropTypes.bool,
}

RiskFactors.propTypes = {
  riskFactors: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Recommendations.propTypes = {
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      rank: PropTypes.string.isRequired,
    })
  ).isRequired,
  patientId: PropTypes.string,
  encounterId: PropTypes.string,
  conditionName: PropTypes.string,
  onAcceptRecommendation: PropTypes.func.isRequired,
  loadId: PropTypes.number,
  expired: PropTypes.bool,
}

Recommendation.propTypes = {
  recommendation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    rank: PropTypes.string.isRequired,
  }),
  patientId: PropTypes.string,
  encounterId: PropTypes.string,
  conditionName: PropTypes.string,
  onAcceptRecommendation: PropTypes.func.isRequired,
  loadId: PropTypes.number,
  expired: PropTypes.bool,
}

export default Vector
