import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { RouteWhenAllowed } from './RouteWhenAllowed'

import { routeConfig } from './route-config'
import { RouteError, RouteAuthConfig } from './types'
import { AuthState } from '../auth'

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('ca.route.routing-utils')

/**
 * Convert the route configs into React Router components
 * @param routes
 */
export const routesAsComponents = routes => (
  <Switch>
    {routes.map(route => {
      return route.path ? (
        <RouteWhenAllowed
          key={route.path}
          {...route}
          exact={route.exact !== false}
        />
      ) : (
        <RouteWhenAllowed key="*" {...route} exact={route.exact !== false} />
      )
    })}
  </Switch>
)

export const generateRoutes = routesAsComponents.bind(null, routeConfig)

/**
 * Given a route config and a user's authState, returns an array of errors. If the return array is empty, then the user
 * is allowed to access this route
 * @param {RouteAuthConfig} auth The route config to check
 * @param {AuthState} authState The user auth to check against
 * @param {*} context Contextual information about the route being checked. Passed back with any errors
 * @returns {Array<RouteError>} If empty, then the user can access the route. If there are errors, then the user should
 * not be allowed to access the route
 */
export function evaluateRouteAuth(
  auth: RouteAuthConfig,
  authState: AuthState,
  context: any
): RouteError[] {
  const errors = []
  // If the route config has no auth object or is false, then return the empty errors array
  if (!auth) {
    return errors
  } else if (!authState || !authState.token || !authState.identity) {
    // Auth of any form is required, but the user doesn't appear to be logged in
    errors.push({
      key: 'NotLoggedIn',
      code: 401,
      message: 'Your session has expired. To continue, please log in.',
      context,
    })
  } else if (typeof auth === 'object') {
    // If the auth is a complex object, evaluate each part
    const user = authState.identity
    // If the user is required to be in a certain directory
    if (auth.directory && auth.directory !== user.directory) {
      errors.push({
        key: 'IncorrectDirectory',
        code: 403,
        message: 'User must be from directory ' + auth.directory,
        context,
      })
    }
    // If the user is required to have one of the given permissions
    if (
      Array.isArray(auth.permissions) &&
      (!Array.isArray(user.permissions) ||
        !user.permissions.some(perm => auth.permissions.includes(perm)))
    ) {
      errors.push({
        key: 'MissingPermission',
        code: 403,
        message:
          'User must have one of the following permissions: ' +
          auth.permissions.join(', '),
        context,
      })
    }
    // If the user is required to have one of the given roles (aka personnelTypes)
    if (
      Array.isArray(auth.personnelTypes) &&
      (!Array.isArray(user.personnelTypes) ||
        !user.personnelTypes.some(type => auth.personnelTypes.includes(type)))
    ) {
      errors.push({
        key: 'MissingRole',
        code: 403,
        message:
          'User must have one of the following roles: ' +
          auth.personnelTypes.join(', '),
        context,
      })
    }
    // If the user is required to have one of the given applications
    if (
      Array.isArray(auth.assignedApplications) &&
      (!Array.isArray(user.assignedApplications) ||
        !user.assignedApplications.some(type =>
          auth.assignedApplications.includes(type)
        ))
    ) {
      errors.push({
        key: 'MissingApplicationPermission',
        code: 403,
        message:
          'User must have one of the following applications: ' +
          auth.assignedApplications.join(', '),
        context,
      })
    }
    // If certain applications are required to be enabled
    if (
      Array.isArray(auth.enabledApplications) &&
      (!Array.isArray(user.enabledApplications) ||
        !user.enabledApplications.some(type =>
          auth.enabledApplications.includes(type)
        ))
    ) {
      errors.push({
        key: 'ApplicationMustBeEnabled',
        code: 401,
        message:
          'One of the following applications must be enabled: ' +
          auth.enabledApplications.join(', '),
        context,
      })
    }
    // If certain applications are required to be disabled
    if (
      Array.isArray(auth.disabledApplications) &&
      (!Array.isArray(user.enabledApplications) ||
        user.enabledApplications.some(type =>
          auth.disabledApplications.includes(type)
        ))
    ) {
      errors.push({
        key: 'ApplicationMustNotBeEnabled',
        code: 401,
        message:
          'All of the following applications must be disabled: ' +
          auth.disabledApplications.join(', '),
        context,
      })
    }
  }

  return errors
}
