/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ActionIcon from 'mdi-react/CalendarCheckIcon'
import {blue600 as ActionColor} from 'material-ui/styles/colors'
import OpenDrawer from 'material-ui/svg-icons/content/add-circle-outline'
import IconButton from 'material-ui/IconButton'

import TodoFilter from '../filter/TodoFilter'
import AppliedFilters from '../filter/AppliedFilters'

import './MilestoneActionsHeader.scss'
import Chip from 'material-ui/Chip'
import {ConfirmDialog} from '../../common'
import {QuickAddMenu} from '../../plan'
import { getPlanDotColor } from './PlanColorDotUtil'

import classNames from 'classnames'

class MilestoneActionsHeader extends Component {
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  static defaultProps = {
    windowSize: 5,
  }

  state = {
    templateToDelete: null
  }

  handleDeleteDueDateFilter = () => {
    this.props.applyFilter({
      ...this.props.filter,
      dueDate: 'Current'
    })
  }

  handleDeleteTrackFilter = () => {
    this.props.applyFilter({
      ...this.props.filter,
      track: 'All'
    })
  }

  handleDeleteStatusFilter = () => {
    this.props.applyFilter({
      ...this.props.filter,
      status: 'All'
    })
  }

  handleConfirmRemovePlan = () => {
    this.props.onRemovePlan(this.state.templateToDelete.id)
    this.setState({ templateToDelete: null })
  }

  handleCancelRemovePlan = () => {
    this.setState({ templateToDelete: null })
  }

  render() {
    const {
      onApplyPlan,
      appliedTemplates,
      filter,
      applyFilter,
      resetFilter,
      draftFilter,
      applyDraftFilter,
      userActions,
      onCreatePlanAction
    } = this.props

    const showPlanColorDots = appliedTemplates.length > 1

    const normalizedUserActions = userActions.map(i => i.text).slice(0, 10)

    const quickAddElements = [
      {
        items: normalizedUserActions,
        handleQuickAddItem: onCreatePlanAction,
      },
    ]

    return (
      <div className="MilestoneActionsHeader">
        <div className="titleWrapper">
          <QuickAddMenu elements={quickAddElements} showMenuType={false} />
          <span className="iconMenuTitle">
            <IconButton
              style={{ padding: 0, width: 30, height: 30 }}
              className="applyPlanIcon"
              onClick={onApplyPlan}>
              <OpenDrawer />
            </IconButton>
          </span>

          <ActionIcon style={{ fill: ActionColor, marginLeft: '6px' }} />

          <span className="title">
            To-Do's
          </span>
          <span className="planNames">
            <span>Plans:</span>
            {
              appliedTemplates.length > 0 ?
                appliedTemplates
                  .map(template => (
                    <span
                      key={template.id}
                      className={classNames({
                        "pillWrapper": true,
                        "deletedPlan": template.removed
                      })}>
                      <Chip
                        className="filterChip"
                        onRequestDelete={template.removed ?
                          null :
                          () => this.setState({ templateToDelete: { id: template.id, name: template.name }})
                        }>
                        {showPlanColorDots ? <span className="planColorDot" style={{backgroundColor: getPlanDotColor(appliedTemplates, template.name)}} /> : null}
                        {template.name}
                      </Chip>
                    </span>
                  ))
                :
                  <span className="pillWrapper">
                    <Chip className="filterChip">
                      No Plan
                    </Chip>
                  </span>
            }
          </span>
          <AppliedFilters
            deleteDueDateFilter={this.handleDeleteDueDateFilter}
            deleteTrackFilter={this.handleDeleteTrackFilter}
            deleteStatusFilter={this.handleDeleteStatusFilter}
            filter={filter}
          />
          <TodoFilter
            filter={filter}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            draftFilter={draftFilter}
            applyDraftFilter={applyDraftFilter}
          />
        </div>
        { this.state.templateToDelete !== null &&
          <ConfirmDialog
            open={this.state.templateToDelete !== null}
            title={`Remove Plan: ${ this.state.templateToDelete.name }`}
            confirmText="Remove"
            cancelText="Cancel"
            message="Any To-Do's that have been marked Complete or N/A shall remain; all others will be removed."
            onCancelClick={this.handleCancelRemovePlan}
            onConfirmClick={() => this.handleConfirmRemovePlan()}
          />
        }
      </div>
    )
  }
}

MilestoneActionsHeader.propTypes = {
  onApplyPlan: PropTypes.func,
  appliedTemplates: PropTypes.array,
  filter: PropTypes.object,
  applyFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  draftFilter: PropTypes.object,
  applyDraftFilter: PropTypes.func,
  onRemovePlan: PropTypes.func,
  userActions: PropTypes.array,
  onCreatePlanAction: PropTypes.func
}

export default MilestoneActionsHeader
