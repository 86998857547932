import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Paper } from 'material-ui'
import {
  calcForecastExcessDays,
  calcForecastWorkingOE,
} from '../../util/workspace'
import { PlanHeader } from '../escalation/PlanHeader'
import { onScrollNavigate, scrollPageNumber } from '../../util/utils'

const ForecastExcessDaysId = 'ForecastExcessDays'
const ActualLOSId = 'ActualLOS'
const ForecastWorkingOEId = 'ForecastWorkingO/E'

const Ascending = 'Ascending'
const Descending = 'Descending'

export class WorkspaceSection extends Component {
  static propTypes = {
    workspaces: PropTypes.array.isRequired,
    routeTo: PropTypes.func.isRequired,
    capped: PropTypes.bool,
    sort: PropTypes.object,
    selectedItem: PropTypes.object,
    oeThresholdCaution: PropTypes.number,
    oeThresholdDanger: PropTypes.number,
    onItemClick: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }).isRequired,
  }

  componentDidMount = () => {
    scrollPageNumber(this.props.history)
  }

  compareByBedName(value, a, b) {
    let result = value
    if (value === 0) {
      result = a.bed.name.localeCompare(b.bed.name)
    }
    return result
  }

  sortByForecastExcessDays(workspaces, capped, sort) {
    let comparable
    if (sort.sortOrder === Ascending) {
      comparable = (a, b) => {
        let result =
          calcForecastExcessDays(capped, a) - calcForecastExcessDays(capped, b)
        return this.compareByBedName(result, a, b)
      }
    } else {
      comparable = (a, b) => {
        let result =
          calcForecastExcessDays(capped, b) - calcForecastExcessDays(capped, a)
        return this.compareByBedName(result, a, b)
      }
    }
    return workspaces.sort(comparable)
  }

  sortByForecastWorkingOE(workspaces, capped, sort) {
    let comparable
    if (sort.sortOrder === Ascending) {
      comparable = (a, b) => {
        // Make sure the O/E ? is always at the bottom
        if (calcForecastWorkingOE(capped, a) === '?') {
          return calcForecastWorkingOE(capped, b) === '?'
            ? this.compareByBedName(0, a, b)
            : 1
        }
        if (calcForecastWorkingOE(capped, b) === '?') {
          return calcForecastWorkingOE(capped, a) === '?'
            ? this.compareByBedName(0, a, b)
            : -1
        }
        let result =
          calcForecastWorkingOE(capped, a) - calcForecastWorkingOE(capped, b)
        return this.compareByBedName(result, a, b)
      }
    } else {
      comparable = (b, a) => {
        // Make sure the O/E ? is always at the bottom
        if (calcForecastWorkingOE(capped, b) === '?') {
          return calcForecastWorkingOE(capped, a) === '?'
            ? this.compareByBedName(0, a, b)
            : 1
        }
        if (calcForecastWorkingOE(capped, a) === '?') {
          return calcForecastWorkingOE(capped, b) === '?'
            ? this.compareByBedName(0, a, b)
            : -1
        }
        let result =
          calcForecastWorkingOE(capped, a) - calcForecastWorkingOE(capped, b)
        return this.compareByBedName(result, a, b)
      }
    }
    return workspaces.sort(comparable)
  }

  sortByActualLOS(workspaces, capped, sort) {
    let comparable
    if (sort.sortOrder === Ascending) {
      comparable = (a, b) => {
        let result = a.visit.currentDayOfStay - b.visit.currentDayOfStay
        return this.compareByBedName(result, a, b)
      }
    } else {
      comparable = (a, b) => {
        let result = b.visit.currentDayOfStay - a.visit.currentDayOfStay
        return this.compareByBedName(result, a, b)
      }
    }
    return workspaces.sort(comparable)
  }

  sort(workspaces, capped, sort) {
    let result
    if (sort.sortId === ForecastExcessDaysId) {
      result = this.sortByForecastExcessDays(workspaces, capped, sort)
    } else if (sort.sortId === ForecastWorkingOEId) {
      result = this.sortByForecastWorkingOE(workspaces, capped, sort)
    } else {
      //ActualLOSId
      result = this.sortByActualLOS(workspaces, capped, sort)
    }
    return result
  }

  onNavigate = id => {
    onScrollNavigate(id, this.props.history)
  }

  render() {
    const { workspaces, routeTo, capped, sort } = this.props
    const sortedWorkspaces = this.sort(workspaces, capped, sort)

    return (
      <div className="workspace-section">
        {sortedWorkspaces.map(ws => (
          <div
            key={ws.bed.exchangeName}
            id={`${ws.bed.name}-${ws.department.name}`}
            className="row section-row">
            <div className="section">
              <PlanHeader
                capped={capped}
                viewType="KPI"
                workspace={ws}
                routeTo={routeTo}
                onNavigate={this.onNavigate}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
}
