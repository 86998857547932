import React, { useState } from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import Portal from '@material-ui/core/Portal'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WatcherSnackbar = (props) => {
  const [isMouseOverUndo, setMouseOverUndo] = useState(false)

  const {
    open,
    onUndo,
    onClose,
    message
  } = props

  const handleUndo = () => {
    setMouseOverUndo(false)
    onUndo()
    onClose()
  }

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={onClose}
        message={message}
        onClick={(e) => e.stopPropagation()}
        action={
          <UndoButton
            onMouseOver={() => setMouseOverUndo(true)}
            onMouseOut={() => setMouseOverUndo(false)}
            variant={isMouseOverUndo ? "contained" : "text"}
            onClick={handleUndo}>
            UNDO
          </UndoButton>
        }
      />
    </Portal>
  )
}

const UndoButton = styled(Button)`
  && {
    color: #9e9e9e;
    font-size: 14px;

    :hover {
      color: #3f3f3f;
    }
  }
`

WatcherSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onUndo: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired
}

export default WatcherSnackbar
