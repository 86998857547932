import React from 'react'
import PropTypes from 'prop-types'

import { Pin } from '../../../plan'
import { grey500 as GreyColor } from 'material-ui/styles/colors'
import DeleteIcon from 'mdi-react/DeleteIcon'
import { IconButton } from 'material-ui'

const IconSection = ({ todo, onClickPin, onClickRemove, showDeleteIcon }) => {

  return (
    <td>
      <span className="iconSection">
      {showDeleteIcon && todo.track === 'T' ?
        (
          <IconButton
            onClick={() => onClickRemove(todo.id)}
            style={{
              fill: GreyColor,
              padding: 8,
              width: 40,
              height: 40,
            }}>
            <DeleteIcon/>
          </IconButton>
        )
        : null
      }
      <Pin
        item={todo}
        onClick={onClickPin}
      />
      </span>
    </td>
  )
}

IconSection.propTypes = {
  todo: PropTypes.object,
  onClickPin: PropTypes.func,
  onClickRemove: PropTypes.func,
  showDeleteIcon: PropTypes.bool,
}

export default IconSection
