import {reduxStore} from "../index"

export const SHOWPG = 'care-advance/errors/SHOWPG'
export const CLEARPG = 'care-advance/errors/CLEARPG'

const initialState = {
  errorspg: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOWPG:
      return {
        errorspg: [...state.errorspg, ...action.payload],
      }
    case CLEARPG: {
      const removalIndex = state.errorspg.findIndex(
        error => error.id === action.payload
      )
      return {
        errorspg: [
          ...state.errorspg.slice(0, removalIndex),
          ...state.errorspg.slice(removalIndex + 1),
        ],
      }
    }
    default:
      return state
  }
}

export type CLError = {
  id: string,
  key: string,
  code: number,
  message: string,
}

export function showErrorsPg(errors: [CLError]) {
  // Errors are not expected to come with ids already provided
  const errorsWithIds = errors.map(error => ({
    ...error,
    id: generateRandomId(),
  }))
  return {
    type: SHOWPG,
    payload: errorsWithIds,
  }
}

export function clearErrorPg(id: string) {
  return {
    type: CLEARPG,
    payload: id,
  }
}

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
function generateRandomId() {
  return (
    s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  )
}

// CA-2260 in case caller of new relay mutation doesn't supply errCallback param
export function showErrorsDirect(error) {
  reduxStore.dispatch(showErrorsPg([{key: error.message, code: 200, message: error.message}]))
}
