/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type user_createFavoriteBarrierFromTemplate_MutationVariables = {|
  templateId: any
|};
export type user_createFavoriteBarrierFromTemplate_MutationResponse = {|
  +userSettingMutation: ?{|
    +createFavoriteBarrierFromTemplate: ?{|
      +id: string,
      +name: ?string,
      +description: ?string,
      +category: ?{|
        +code: ?string,
        +value: ?string,
      |},
      +templateId: ?any,
      +auditMeta: ?{|
        +updated: any,
        +updatedBy: {|
          +userName: string,
          +firstName: string,
          +lastName: string,
        |},
      |},
    |}
  |}
|};
export type user_createFavoriteBarrierFromTemplate_Mutation = {|
  variables: user_createFavoriteBarrierFromTemplate_MutationVariables,
  response: user_createFavoriteBarrierFromTemplate_MutationResponse,
|};
*/


/*
mutation user_createFavoriteBarrierFromTemplate_Mutation(
  $templateId: UUID!
) {
  userSettingMutation {
    createFavoriteBarrierFromTemplate(templateId: $templateId) {
      id
      name
      description
      category {
        code
        value
        id
      }
      templateId
      auditMeta {
        updated
        updatedBy {
          userName
          firstName
          lastName
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "templateId",
    "variableName": "templateId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "templateId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "user_createFavoriteBarrierFromTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSettingMutation",
        "kind": "LinkedField",
        "name": "userSettingMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "FavoriteBarrier",
            "kind": "LinkedField",
            "name": "createFavoriteBarrierFromTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CodeTablePair",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditMeta",
                "kind": "LinkedField",
                "name": "auditMeta",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "updatedBy",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "user_createFavoriteBarrierFromTemplate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSettingMutation",
        "kind": "LinkedField",
        "name": "userSettingMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "FavoriteBarrier",
            "kind": "LinkedField",
            "name": "createFavoriteBarrierFromTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CodeTablePair",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditMeta",
                "kind": "LinkedField",
                "name": "auditMeta",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditUser",
                    "kind": "LinkedField",
                    "name": "updatedBy",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09f744ebe1670a96eca91b9e0706c2fb",
    "id": null,
    "metadata": {},
    "name": "user_createFavoriteBarrierFromTemplate_Mutation",
    "operationKind": "mutation",
    "text": "mutation user_createFavoriteBarrierFromTemplate_Mutation(\n  $templateId: UUID!\n) {\n  userSettingMutation {\n    createFavoriteBarrierFromTemplate(templateId: $templateId) {\n      id\n      name\n      description\n      category {\n        code\n        value\n        id\n      }\n      templateId\n      auditMeta {\n        updated\n        updatedBy {\n          userName\n          firstName\n          lastName\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5cd97b8a5a49907b9957bc9a251a8859';

module.exports = node;
