/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export class ComplianceTableCell extends Component {
  static propTypes = {
    children: PropTypes.node,
    handleUpdateQuery: PropTypes.func,
    department: PropTypes.string,
    facility: PropTypes.string,
    filter: PropTypes.string,
    selected: PropTypes.bool,
    handleQueryUpdate: PropTypes.func
  }

  handleClick = () => {
    this.props.handleQueryUpdate(this.props.department, this.props.filter, this.props.facility)
  }

  render() {
    const { children } = this.props
    return (
      <ClickSpan selected={this.props.selected} onClick={this.handleClick}>
        {children}
      </ClickSpan>
    )
  }
}

const ClickSpan = styled.span`
  display: inline-block;
  height: 100%;
  width: 100%;
  background-color: ${props => (props.selected ? '#ef9a9a' : 'white')};
  padding: 10px 5px 10px 5px !important;
`
