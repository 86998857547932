/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Toggle } from 'material-ui'
import Vector from './Vector'
import './jvion.scss'
import {HistoricalVectors} from './HistoricalVectors'
import moment from 'moment'
import { isJvionSetRecommendations } from '../../util/configs'
import sortBy from 'lodash.sortby'
export class VectorSection extends React.Component {
  state = {
    showHistory: false,
  }

  handleViewChange = () => {
    this.setState((prevState, props) => {
      return { showHistory: !prevState.showHistory }
    })
  }

  render() {
    const {
      jvionDataSet,
      patientVector,
      onAcceptRecommendation,
    } = this.props

    if (!patientVector) return null

    const { showHistory } = this.state
    const { conditions, patientId, encounterId, predictionDate } = patientVector
    // If isJvionSetRecommendations is false, these are needed since we can never
    // call the accept mutation
    const { id, expired } = jvionDataSet ? jvionDataSet.jvionDataSet : {}

    return (
      <div className="vector-section">
        <Header
          showHistory={showHistory}
          onToggle={this.handleViewChange}
          predictionDate={predictionDate}
        />
        {showHistory ? (
          <div className="jvion-scroll-under">
            <HistoricalVectors patientVector={patientVector}/>
          </div>
        ) : (
          <div className="jvion-scroll-under">
            <CurrentVectors
              conditions={conditions}
              patientId={patientId}
              encounterId={encounterId}
              loadId={id}
              expired={expired}
              onAcceptRecommendation={onAcceptRecommendation}
            />
          </div>
        )}
      </div>
    )
  }
}

const Header = ({ showHistory, onToggle, predictionDate }) => (
  <div className="header1">
    {showHistory ? (
      <div className="jvlabel">Accepted Recommendations</div>
    ) : (
      <div className="jvlabel">{`Current conditions predicted on ${moment(
        predictionDate
      ).format('MMM D')}`}</div>
    )}
    <div>
      {isJvionSetRecommendations() && (
        <Toggle
          onToggle={onToggle}
          label={showHistory ? 'Accepted' : 'Current'}
        />
      )}
    </div>
  </div>
)

const CurrentVectors = ({
  conditions,
  patientId,
  encounterId,
  onAcceptRecommendation,
  loadId,
  expired,
}) =>
  conditions.length === 0 ? (
    <EmptyList />
  ) : (
    <div className="paper" style={{ padding: '5px' }}>
      {sortBy(conditions, c =>
        c.riskBracket[0] === 'H' ? 1 :
        c.riskBracket[0] === 'M' ? 2 : 3, ).map(condition => (
        <div key={condition.name}>
          <Vector
            vector={condition}
            patientId={patientId}
            encounterId={encounterId}
            loadId={loadId}
            expired={expired}
            onAcceptRecommendation={onAcceptRecommendation}
          />
        </div>
      ))}
    </div>
  )

const EmptyList = () => (
  <Paper>
    <div style={{ padding: '5px' }}> No Current Conditions </div>
  </Paper>
)

VectorSection.propTypes = {
  jvionDataSet: PropTypes.shape({
    id: PropTypes.number,
    expired: PropTypes.bool,
  }),
  patientVector: PropTypes.shape({
    patientId: PropTypes.string.isRequired,
    predictionDate: PropTypes.string.isRequired,
    conditions: PropTypes.array.isRequired,
  }),
  onAcceptRecommendation: PropTypes.func.isRequired,
}


Header.propTypes = {
  showHistory: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  predictionDate: PropTypes.string.isRequired,
}

CurrentVectors.propTypes = {
  conditions: PropTypes.array.isRequired,
  patientId: PropTypes.string,
  encounterId: PropTypes.string,
  onAcceptRecommendation: PropTypes.func.isRequired,
  loadId: PropTypes.number,
  expired: PropTypes.bool,
}
