import React from 'react'
import PropTypes from 'prop-types'

import NotSetIcon from 'mdi-react/HelpCircleIcon'
import NotSetThresholdIcon from 'mdi-react/HelpIcon'
import OnTimeIcon from 'mdi-react/CheckboxBlankCircleIcon'
import AtRiskIcon from 'mdi-react/AlertCircleIcon'
import HighRiskIcon from 'mdi-react/AlertCircleIcon'

import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconButton from 'material-ui/IconButton'
import Divider from 'material-ui/Divider'
import {
  grey800 as NotSetColor,
  red500 as HighRiskColor,
  yellow500 as AtRiskColor,
  green500 as OnTimeColor,
  red200 as ThresholdColor,
  grey800 as NotSetThresholdColor,
} from 'material-ui/styles/colors'

export const ProgressionIndicator = ({
  value,
  compliance,
  iconButtonStyle,
  onChange = () => {},
  ...props
}) => {
  let buttonStyle = { ...iconButtonStyle }
  let current
  const complianceColor = compliance.noPSIssue ? ThresholdColor : NotSetColor

  switch (value) {
    case 'OnTime':
      buttonStyle = { ...buttonStyle, fill: OnTimeColor }
      current = <OnTimeIcon />
      break
    case 'AtRisk':
      buttonStyle = { ...buttonStyle, fill: AtRiskColor }
      current = <AtRiskIcon />
      break
    case 'HighRisk':
      buttonStyle = { ...buttonStyle, fill: HighRiskColor }
      current = <HighRiskIcon />
      break
    default:
      buttonStyle = { ...buttonStyle, fill: complianceColor }
      current = <NotSetIcon />
  }

  return (
    <IconMenu
      {...props}
      iconButtonElement={
        <IconButton style={{ ...buttonStyle }}>{current}</IconButton>
      }
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      targetOrigin={{ horizontal: 'left', vertical: 'top' }}
      onItemClick={(event, child) => onChange(child.props.value)}>
      <span style={{ paddingLeft: '.5em', cursor: 'default' }}>
        Progression Indicator
      </span>
      <Divider />
      <MenuItem
        value="NotSet"
        primaryText="Not Set"
        leftIcon={<NotSetIcon style={{ fill: complianceColor }} />}
      />
      <MenuItem
        value="OnTime"
        primaryText="On Time"
        leftIcon={<OnTimeIcon style={{ fill: OnTimeColor }} />}
      />
      <MenuItem
        value="AtRisk"
        primaryText="At Risk"
        leftIcon={<AtRiskIcon style={{ fill: AtRiskColor }} />}
      />
      <MenuItem
        value="HighRisk"
        primaryText="High Risk"
        leftIcon={<HighRiskIcon style={{ fill: HighRiskColor }} />}
      />
    </IconMenu>
  )
}

ProgressionIndicator.propTypes = {
  value: PropTypes.string,
  iconButtonStyle: PropTypes.object,
  compliance: PropTypes.object,
  onChange: PropTypes.func,
}
