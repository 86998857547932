import React from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import TextField from '../../common/TextField'
import classNames from 'classnames'
import styled from 'styled-components'
import { BARRIER_SOURCE_ROLE, buildKnownUserInput } from '../../../graphql/relay/queries/knownUser'
import SourceDetailConfigValueSelection from './SourceDetailConfigValueSelection'

import KnownPersonnelTypeAhead from '../knownPersonnelTypeAhead/KnownPersonnelTypeAhead'

const BarrierSource = ({ sourceType, sourceName, onSourceTypeChange, onSourceNameChange, onSearchKnownPersonnel, barrierSourceTypes, barrierSourceDetails }) => {
  const getKnownUserInput = (searchText) => {
    return buildKnownUserInput({ searchText: searchText, roles: [BARRIER_SOURCE_ROLE] })
  }

  const chooseSourceNameOrDetails = () => {
    const matchingSourceType = barrierSourceTypes.find(srcTypeConfig => srcTypeConfig.code === sourceType)

    let textfield
    if (matchingSourceType && matchingSourceType.activeDirectorySourceName) {
      textfield = (
        <KnownPersonnelTypeAhead
          label={'Barrier Source Name'}
          selectedValue={sourceName}
          isShowIrisIcon={false}
          onSearchKnownPersonnel={(searchText) => onSearchKnownPersonnel(getKnownUserInput(searchText))}
          onResultSelected={(value) => onSourceNameChange(value)} />
      )
    } else if (matchingSourceType && matchingSourceType.hasConfiguredList) {
      const selectedVal = barrierSourceDetails.find(srcDetailConfig => srcDetailConfig.code === sourceName) || null
      textfield = (
        <SourceDetailConfigValueSelection
          sourceDetailConfigValues={barrierSourceDetails}
          selectedValue={selectedVal}
          onChange={onSourceNameChange}
          fieldLabel="Barrier Source Details"
        />
      )
    } else {
      textfield = (
        <TextField
          label={'Barrier Source Details'}
          value={sourceName}
          onEnter={(value) => onSourceNameChange(value)}
          maxChars={140}
          trackDirty
          showCharacterCount
          fullWidth
        />
      )
    }

    return textfield
  }

  return barrierSourceTypes && barrierSourceTypes.length > 0 ? (
    <FlexDiv className={classNames('row')}>
      <div className={'col-xs-6'}>
        <FormControl fullWidth>
          <InputLabel>Barrier Source Type</InputLabel>
          <Select
            value={sourceType || ''}
            onChange={event => onSourceTypeChange(event.target.value)}
            fullWidth>
            <StyledMenuItem value={''} />
            {barrierSourceTypes.map(sourceType => (
              <StyledMenuItem
                key={sourceType.code + Math.random()}
                value={sourceType.code}>
                {sourceType.value}
              </StyledMenuItem>))}
          </Select>
        </FormControl>
      </div>
      <div className={'col-xs-6'}>
        {
          sourceType ?
            chooseSourceNameOrDetails() : null
        }
      </div>
    </FlexDiv>
  ) : null
}

const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
`

const StyledMenuItem = styled(MenuItem)`
  && {
    min-height: 40px;
  }
`

BarrierSource.propTypes = {
  sourceType: PropTypes.string,
  sourceName: PropTypes.string,
  onSourceTypeChange: PropTypes.func.isRequired,
  onSourceNameChange: PropTypes.func.isRequired,
  onSearchKnownPersonnel: PropTypes.func.isRequired,
  barrierSourceTypes: PropTypes.array,
  barrierSourceDetails: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
      ordinal: PropTypes.number,
      active: PropTypes.bool
    })
  )
}

export default BarrierSource
