/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField'

import { CodeTableSelect } from '../../containers'

// As of 3/17 when we created EditWorkingDCDisposition to edit WDD and Vendor in the Drawer,
// this class is no longer in use.
// Didn't delete it in case we decide we want the inline editing to come back.

export class WorkingDischargeDisposition extends Component {
  static propTypes = {
    visitId: PropTypes.number.isRequired,
    workingDischargeDisposition: PropTypes.shape({
      code: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    dischargeVendor: PropTypes.string,
    displayDischargeVendor: PropTypes.bool,
    onWorkingDischargeDispositionChange: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dischargeVendor: '',
    workingDischargeDisposition: { code: 'NotSet', value: 'NotSet' },
    displayDischargeVendor: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      dischargeVendor: this.props.dischargeVendor,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dischargeVendor != this.props.dischargeVendor) {
      this.setState({
        dischargeVendor: nextProps.dischargeVendor,
      })
    } else if (nextProps.visitId != this.props.visitId) {
      this.setState({
        dischargeVendor: this.props.dischargeVendor,
      })
    }
  }

  handleDischargeVendorBlur = () => {
    this.props.onWorkingDischargeDispositionChange(
      this.props.visitId,
      this.props.workingDischargeDisposition.code,
      this.state.dischargeVendor
    )
    this.setState({
      dischargeVendor: this.state.dischargeVendor,
    })
  }

  handleUpdate = e => {
    this.setState({ dischargeVendor: e.target.value })
  }

  handleChange = (event, index, value) => {
    this.props.onWorkingDischargeDispositionChange(
      this.props.visitId,
      value,
      this.state.dischargeVendor
    )
  }

  render() {
    // todo: refactor below
    let dischargeDiv = ''

    if (this.props.displayDischargeVendor) {
      dischargeDiv = (
        <div className="row">
          <div className="col-xs-6">
            <CodeTableSelect
              componentProps={{
                fullWidth: true,
                floatingLabelShrinkStyle: { whiteSpace: 'nowrap' },
                floatingLabelText: 'D/C Arrangements',
              }}
              codeTableType="WorkingDischargeDisposition"
              value={this.props.workingDischargeDisposition.code}
              onChange={this.handleChange}
            />
          </div>
          <div className="col-xs-6">
            <TextField
              fullWidth
              floatingLabelText="Note"
              value={this.state.dischargeVendor}
              onChange={this.handleUpdate}
              onBlur={this.handleDischargeVendorBlur}
            />
          </div>
        </div>
      )
    } else {
      dischargeDiv = (
        <CodeTableSelect
          componentProps={{
            fullWidth: true,
            floatingLabelShrinkStyle: { whiteSpace: 'nowrap' },
            floatingLabelText: 'D/C Arrangements',
          }}
          codeTableType="WorkingDischargeDisposition"
          value={this.props.workingDischargeDisposition.code}
          onChange={this.handleChange}
        />
      )
    }

    return dischargeDiv
  }
}
