import React from 'react'
import PropTypes from 'prop-types'

import * as images from './'

export const PatientInfoImage = ({type, code}) =>
  images.asTag('patient-info-image',
    `/components/image/img/${type}_${code}.png`,
    {
      title: displayValue(type, code),
      // Other Properties?
    })

export const CodeImage = ({type, code}) =>
  images.asTag('tag-image',
    `/components/image/img/${type}_${code}.png`,
    {
      title: displayValue(type, code),
      // Other Properties?
    })

export const AlertImage = ({ type, code }) =>
  images.asTag(
    'alert-image',
    `/components/image/img/PatientAlert_${type}_${code}.png`,
    {
      title: displayValue(type, code),
      // Other Properties?
    }
  )

  export const DischargeDelayImage = ({ type, code }) =>
  images.asTag('discharge-delay',
    `/components/image/img/${type}_${code}.png`,
    {
      title: displayValue(type, code),
    })

// Empty image for slotted Alerts with no tooltip
// Using Notes_No.png as convenient empty image
export const EmptyImage = () => images.asTag(images['Notes']['No'])

// Shamelessly ported from Ext
function toSpecialCamelCase(str, delimiter = ' ') {
  /*
   'ABoyC'=>'A Boy C'
   'ABC'=>'ABC'
   'ABCat'=>'AB Cat'
   'AppleBC'=>'Apple BC'
   'A Boy C'=>'A Boy C'
   'VipPatient'=>'VIP Patient'
   'O2Tank'=>'O2 Tank'
   'Two Words'=>'Two Words'
   'ATwo Words'=>'A Two Words'
   Vip Dnr Evs Npo Iv Mdr =>> convert to uppercase
   */
  // Remove any whitespace
  const noBlanks = str.replace(/\s+/g, '')
  // Add blanks after caps
  const withBlanks = noBlanks.replace(/([A-Z])/g, ' $1')
  // Split into words
  const words = withBlanks.split(/\s/)
  const uppercaseWords = 'Vip Dnr Evs Npo Iv Mdr'
  let hasNextWord,
    wordIsLong,
    nextWordIsLong,
    nextIndex,
    result = ''

  words.forEach((word, index) => {
    if (word.length > 0) {
      nextIndex = index + 1
      // Add the word to the result
      result =
        result +
        (uppercaseWords.indexOf(word) == -1 ? word : word.toUpperCase())
      // Add a blank if the next word size is greater than 1 character
      hasNextWord = nextIndex < words.length
      if (hasNextWord) {
        wordIsLong = word.length > 1
        nextWordIsLong = words[nextIndex].length > 1
        if (nextWordIsLong || wordIsLong) {
          result = result + delimiter
        }
      }
    }
  })

  return result
}

// todo: Alter this when there's a real direction on I18N
function displayValue(type, code) {
  const typeOut = toSpecialCamelCase(type)
  if (code && code !== 'nil') {
    // todo: Look up the correct display value
    return `${typeOut}: ${code}`
  } else {
    // todo: Look up the correct display value
    return typeOut
  }
}

CodeImage.propTypes = {
  type: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}
