/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import sortBy from 'lodash.sortby'

import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import FlatButton from 'material-ui/FlatButton'
import Snackbar from 'material-ui/Snackbar'
import { ListItem } from 'material-ui/List'

import { PlanTemplate } from './'
import { compose } from 'recompose'
import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import { ConfirmDialog } from '../../components/common'
import SelectableList from '../../components/SelectableList'

import { TemplateGQL, CodeTableGQL } from '../../graphql/queries'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import { planHandler_relay } from '../../graphql/component/result-handlers'
import {
  applyPlanTemplate,
} from '../../graphql/relay/queries/plan'
import { environment } from '../../graphql/relay/env'
import {handleMutation} from '../../graphql/relay/queries/mutation'

const styleSheet = createStyleSheet('ChoosePlan', theme => ({
  height100: {
    height: '100%',
  },
  categoryColumn: {
    paddingRight: '0 !important',
  },
}))

export class ChoosePlanComp extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    plan: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    admissionTime: PropTypes.string,
    targetDischargeTime: PropTypes.string,
    anticipatedDischargeTime: PropTypes.string,
    decisionTime: PropTypes.string,
    title: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    applyPlanTemplate: PropTypes.func,
    refetch: PropTypes.func,
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      codeTables: PropTypes.array,
    }),
    planTemplatesAndCategories: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      planTemplates: PropTypes.array,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      confirmOpen: false,
      confirmCloseOpen: false,
      isPlanTemplateSelected: false,
      selectedCategory: '',
      selectedTemplateId: '',
      selectedTemplateName: '',
      selectedTemplateDescription: '',
      selectedTemplateUrl: '',
      statusMessage: '',
      isSubmitting: false,
      snackBarOpen: false,
    }
  }

  handlePlanTemplateTouchTap = (name, description, id, url) => {
    this.setState({
      selectedTemplateName: name,
      selectedTemplateDescription: description,
      selectedTemplateId: id,
      selectedTemplateUrl: url,
      isPlanTemplateSelected: true,
    })
  }

  handleCategoryTouchTap = categoryName => {
    this.setState({ selectedCategory: categoryName })
  }

  handleApplyPlan = () => {
    this.setState({ isSubmitting: true })

    const variables = {
      planId: this.props.plan.id,
      templateId: this.state.selectedTemplateId,
    }

    const cb = res => {
      const payload = {
        data: planHandler_relay(res.planMutation.applyPlanTemplate),
        name: 'applyPlanTemplate',
      }
      this.props.refetch && this.props.refetch()
      this.success()
    }

    const error = () => {
      this.setState({ isSubmitting: false })
    }

    handleMutation(environment, applyPlanTemplate, variables, cb, null, error)
  }

  handleSnackBarClose = () => {
    this.setState({ snackBarOpen: false })
    // todo: decide how and what to do to close the drawer, everytime? use a callback to decide? etc.?
    this.props.onRequestClose && this.props.onRequestClose()
  }

  success() {
    this.setState({ isSubmitting: false })
    this.props.onRequestClose && this.props.onRequestClose()
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const {
      data,
      plan,
      decisionTime,
      admissionTime,
      targetDischargeTime,
      anticipatedDischargeTime,
      planTemplatesAndCategories,
    } = this.props

    const {
      snackBarOpen,
      statusMessage,
      isPlanTemplateSelected,
      selectedTemplateId,
      selectedTemplateDescription,
      selectedTemplateUrl,
      isSubmitting,
    } = this.state

    let availableCategories = []

    if (planTemplatesAndCategories.planTemplates) {
      planTemplatesAndCategories.planTemplates.forEach(planTemplate => {
        if (!availableCategories.includes(planTemplate.category.code)) {
          availableCategories.push(planTemplate.category.code)
        }
      })
    }

    const planCategories =
      data && data.codeTables
        ? sortBy(
            data.codeTables.filter(
              x => x.active && availableCategories.includes(x.code)
            ),
            ['code']
          )
        : []

    const applyDisabled = !isPlanTemplateSelected || snackBarOpen || isSubmitting

    const applyButtonStyle = !applyDisabled && {
      labelStyle: { color: 'white' },
    }

    const { title, onRequestClose } = this.props

    const { confirmCloseOpen } = this.state

    const actions = [
      <FlatButton
        label="Apply"
        key="apply"
        disabled={applyDisabled}
        {...applyButtonStyle}
        onClick={this.handleApplyPlan}
      />,
    ]

    const planId = plan.id
    const planTemplateId = selectedTemplateId

    return (
      <div className={classNames(classes.height100)}>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div className={classNames(classes.height100, 'row')}>
          <div
            className={classNames(
              classes.categoryColumn,
              'col-xs-4',
              'col-lg-3'
            )}>
            <div style={{ overflowY: 'auto', height: 510 }}>
              <SelectableList defaultValue={0}>
                {planCategories.map(c => (
                  <ListItem
                    primaryText={c.value}
                    key={c.value}
                    value={c.value}
                    primaryTogglesNestedList
                    nestedItems={
                      sortBy(
                        planTemplatesAndCategories.planTemplates.filter(
                          x => x.category.code === c.code && x.active
                        ),
                        ['name']
                      ).map(pt => (
                        <ListItem
                          primaryText={pt.name}
                          key={pt.id}
                          value={pt.id}
                          id={pt.id}
                          onClick={() => {
                            this.handlePlanTemplateTouchTap(
                              pt.name,
                              pt.description,
                              pt.id,
                              pt.progressionDocument
                                ? pt.progressionDocument.url
                                : ''
                            )
                          }}
                        />
                      )) || []
                    }
                    onClick={() => {
                      this.handleCategoryTouchTap(c.code)
                    }}
                    onNestedListToggle={() => {
                      this.handleCategoryTouchTap(c.code)
                    }}
                  />
                ))}
              </SelectableList>
            </div>
          </div>
          {planTemplateId && (
            <PlanTemplate
              decisionTime={decisionTime}
              admissionTime={admissionTime}
              targetDischargeTime={targetDischargeTime}
              anticipatedDischargeTime={anticipatedDischargeTime}
              planTemplatesAndCategories={planTemplatesAndCategories}
              selectedTemplateId={selectedTemplateId}
              selectedTemplateDescription={selectedTemplateDescription}
              selectedTemplateUrl={selectedTemplateUrl}
              planId={planId}
              planTemplateId={planTemplateId}
            />
          )}
        </div>
        <ConfirmDialog
          open={confirmCloseOpen}
          message="You have unsaved data. Do you want to proceed without applying?"
          onCancelClick={this.handleCancelConfirmClose}
          onConfirmClick={this.handleAcceptConfirmClose}
        />
        <Snackbar
          open={snackBarOpen}
          message={statusMessage}
          autoHideDuration={4000}
          onRequestClose={this.handleSnackBarClose}
        />
      </div>
    )
  }
}


export const ChoosePlan = compose(
  graphql(CodeTableGQL.queries.planCategories),
  graphql(TemplateGQL.queries.allPlanTemplates, {
    name: 'planTemplatesAndCategories',
  })
)(ChoosePlanComp)
