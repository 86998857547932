//TODO - could probably reduce these an enum and actions that take a boolean payload
// The 2 state values (OPEN/CLOSE, HIDE/SHOW) can probably be an action with a boolean payload,
// reduce all those by half
//

export const SHOW_CONFIRM_LOGOUT =
  'care-advance/views/care-advance/views/SHOW_CONFIRM_LOGOUT'
export const HIDE_CONFIRM_LOGOUT = 'care-advance/views/HIDE_CONFIRM_LOGOUT'
export const SHOW_DRAWER = 'care-advance/views/SHOW_DRAWER'
export const HIDE_DRAWER = 'care-advance/views/HIDE_DRAWER'
export const SHOW_DETAILS = 'care-advance/views/SHOW_DETAILS'
export const HIDE_DETAILS = 'care-advance/views/HIDE_DETAILS'
export const UPDATE_PLAN_FILTERS = 'care-advance/views/UPDATE_PLAN_FILTERS'
export const UPDATE_NAVIGATION_WORKSPACES =
  'care-advance/views/UPDATE_NAVIGATION_WORKSPACES'
export const UPDATE_NAVIGATION_WORKSPACES_QUERY =
  'care-advance/views/UPDATE_NAVIGATION_WORKSPACES_QUERY'
export const UPDATE_BARRIER_ESCALATION_FILTERS =
  'care-advance/views/UPDATE_BARRIER_ESCALATION_FILTERS'
export const UPDATE_COMPLIANCE_FILTERS =
  'care-advance/views/UPDATE_COMPLIANCE_FILTERS'
export const SHOW_HIDE_DISCHARGED_VISITS =
  'care-advance/views/SHOW_HIDE_DISCHARGED_VISITS'
export const SHOW_ALL_MILESTONES = 'care-advance/views/SHOW_ALL_MILESTONES'
export const SHOW_NA_MILESTONES = 'care-advance/views/SHOW_NA_MILESTONES'
export const SHOW_ALL_BARRIERS = 'care-advance/views/SHOW_ALL_BARRIERS'
export const SHOW_ALL_ACTIONS = 'care-advance/views/SHOW_ALL_ACTIONS'
export const SHOW_MILESTONE_CALLOUT =
  'care-advance/views/SHOW_MILESTONE_CALLOUT'
export const HIDE_MILESTONE_CALLOUT =
  'care-advance/views/HIDE_MILESTONE_CALLOUT'
export const UPDATE_CURRENT_ROUTE = 'care-advance/views/UPDATE_CURRENT_ROUTE'
export const SET_MILESTONE_VIEW = 'care-advance/views/SET_MILESTONE_VIEW'
export const SET_SHOW_MILESTONE_DUE_DATE =
  'care-advance/views/SET_SHOW_MILESTONE_DUE_DATE'

export const UPDATE_KPI_CAPPED = 'care-advance/views/UPDATE_KPI_CAPPED'
export const UPDATE_KPI_FILTERS = 'care-advance/views/UPDATE_KPI_FILTERS'
export const UPDATE_KPI_CHART_SELECTION =
  'care-advance/views/UPDATE_KPI_CHART_SELECTION'
export const UPDATE_KPI_SORT = 'care-advance/views/UPDATE_KPI_SORT'
export const UPDATE_KPI_FACILITY = 'care-advance/views/UPDATE_KPI_FACILITY'
export const UPDATE_KPI_FLOS_FILTER =
  'care-advance/views/UPDATE_KPI_FLOS_FILTER'
export const UPDATE_KPI_EXCESS_DAYS_FILTER =
  'care-advance/views/UPDATE_KPI_EXCESS_DAYS_FILTER'
export const UPDATE_KPI_DIMENSION = 'care-advance/views/UPDATE_KPI_DIMENSION'

export const UPDATE_SCROLL_TOP = 'care-advance/views/UPDATE_SCROLL_TOP'
export const UPDATE_SNAP_FILTER = 'care-advance/views/UPDATE_SNAP_FILTER'

export const DETAIL_TAB_INDEX = 'care-advance/views/DETAIL_TAB_INDEX'
export const DETAIL_TAB_HEIGHT = 'care-advance/views/DETAIL_TAB_HEIGHT'

const makeDefaultBarrierEscalationFilters = () => {
  return []
}

const makeDefaultComplianceFilters = () => {
  return [
    {
      id: 'SortOrder',
      section: 'SortSection',
      value: 'Ascending',
      toggled: true,
    },
    {
      id: 'Location',
      section: 'SortSection',
      value: ' ',
    },
  ]
}

const makeDefaultKpiSort = () => {
  return { sortId: 'ForecastExcessDays', sortOrder: 'Descending' }
}

const initialState = {
  currentRoute: {},
  openDrawer: null,
  drawerOptions: null,
  showingConfirmLogout: false,
  openDetails: false,
  snapshot: null,
  showDischargedVisits: false,
  planFilters: {},
  navigationWorkspaces: [],
  snapFilter: {
    filterType: 'all',
  },
  barrierEscalationFilters: makeDefaultBarrierEscalationFilters(),
  complianceFilters: makeDefaultComplianceFilters(),
  showAllMilestones: false,
  showNAMilestones: false,
  showAllBarriers: false,
  showAllActions: false,
  showMilestoneCallout: false,
  milestoneCalloutContext: null,
  milestoneView: 'timeline',
  showMilestoneDueDate: true,

  kpiFacility: null,
  kpiCapped: true,
  kpiFilters: null,
  kpiDimension: 'Unit',
  kpiChartSelection: null,
  kpiSort: makeDefaultKpiSort(),
  kpiFLOSFilter: null,
  kpiExcessDaysFilter: null,
  scrollTop: 0,

  detailTabIndex: 0,
  detailTabHeight: 0,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_DRAWER: {
      return {
        ...state,
        openDrawer: action.payload.drawer,
        drawerOptions: action.payload.options,
      }
    }

    case HIDE_DRAWER: {
      return {
        ...state,
        openDrawer: null,
        drawerOptions: null,
      }
    }

    case SHOW_CONFIRM_LOGOUT: {
      return {
        ...state,
        showingConfirmLogout: true,
      }
    }

    case HIDE_CONFIRM_LOGOUT: {
      return {
        ...state,
        showingConfirmLogout: false,
      }
    }

    case SHOW_DETAILS: {
      return {
        ...state,
        openDetails: true,
      }
    }

    case HIDE_DETAILS: {
      return {
        ...state,
        openDetails: false,
      }
    }

    case UPDATE_CURRENT_ROUTE: {
      return {
        ...state,
        currentRoute: action.payload.route,
      }
    }

    case UPDATE_PLAN_FILTERS: {
      return {
        ...state,
        planFilters: action.payload.filters,
      }
    }

    case UPDATE_NAVIGATION_WORKSPACES: {
      return {
        ...state,
        navigationWorkspaces: action.payload.workspaces,
      }
    }

    case UPDATE_NAVIGATION_WORKSPACES_QUERY: {
      return {
        ...state,
        navigationWorkspacesQuery: action.payload,
      }
    }

    case UPDATE_SNAP_FILTER: {
      return {
        ...state,
        snapFilter: action.payload.filter,
      }
    }

    case UPDATE_BARRIER_ESCALATION_FILTERS: {
      return {
        ...state,
        barrierEscalationFilters:
          action.payload.filters && action.payload.filters.length > 0
            ? action.payload.filters
            : makeDefaultBarrierEscalationFilters(),
      }
    }

    case UPDATE_COMPLIANCE_FILTERS: {
      return {
        ...state,
        complianceFilters:
          action.payload.filters && action.payload.filters.length > 0
            ? action.payload.filters
            : makeDefaultComplianceFilters(),
      }
    }

    case SHOW_HIDE_DISCHARGED_VISITS: {
      return {
        ...state,
        showDischargedVisits: action.payload.show,
      }
    }

    case SHOW_ALL_MILESTONES: {
      return {
        ...state,
        showAllMilestones: action.payload.show,
      }
    }

    case SHOW_NA_MILESTONES: {
      return {
        ...state,
        showNAMilestones: action.payload.show,
      }
    }

    case SET_MILESTONE_VIEW: {
      return {
        ...state,
        milestoneView: action.payload.view,
      }
    }

    case SET_SHOW_MILESTONE_DUE_DATE: {
      return {
        ...state,
        showMilestoneDueDate: action.payload.show,
      }
    }

    case SHOW_ALL_BARRIERS: {
      return {
        ...state,
        showAllBarriers: action.payload.show,
      }
    }

    case SHOW_ALL_ACTIONS: {
      return {
        ...state,
        showAllActions: action.payload.show,
      }
    }

    case SHOW_MILESTONE_CALLOUT: {
      return {
        ...state,
        showMilestoneCallout: true,
        milestoneCalloutContext: action.payload,
      }
    }

    case HIDE_MILESTONE_CALLOUT: {
      return {
        ...state,
        showMilestoneCallout: false,
        milestoneCalloutContext: null,
      }
    }

    case UPDATE_KPI_CAPPED: {
      return {
        ...state,
        kpiCapped: action.payload.capped,
      }
    }

    case UPDATE_KPI_FILTERS: {
      return {
        ...state,
        kpiFilters: action.payload.filters,
      }
    }

    case UPDATE_KPI_CHART_SELECTION: {
      return {
        ...state,
        kpiChartSelection: action.payload.chartSelection,
      }
    }

    case UPDATE_KPI_SORT: {
      return {
        ...state,
        kpiSort: action.payload.sort
          ? action.payload.sort
          : makeDefaultKpiSort(),
      }
    }

    case UPDATE_KPI_FACILITY: {
      return {
        ...state,
        kpiFacility: action.payload.facility,
      }
    }

    case UPDATE_KPI_FLOS_FILTER: {
      return {
        ...state,
        kpiFLOSFilter: action.payload.filter,
      }
    }

    case UPDATE_KPI_EXCESS_DAYS_FILTER: {
      return {
        ...state,
        kpiExcessDaysFilter: action.payload.filter,
      }
    }

    case UPDATE_KPI_DIMENSION: {
      return {
        ...state,
        kpiDimension: action.payload.dimension,
      }
    }

    case UPDATE_SCROLL_TOP: {
      return {
        ...state,
        scrollTop: action.payload.scrollTop,
      }
    }

    case DETAIL_TAB_INDEX: {
      return {
        ...state,
        detailTabIndex: action.payload.index,
      }
    }

    case DETAIL_TAB_HEIGHT: {
      return {
        ...state,
        detailTabHeight: action.payload.height,
      }
    }

    default:
      return state
  }
}

export function showConfirmLogout() {
  return {
    type: SHOW_CONFIRM_LOGOUT,
  }
}

export function hideConfirmLogout() {
  return {
    type: HIDE_CONFIRM_LOGOUT,
  }
}

export function showDrawer(drawerName: string, options: {}) {
  return {
    type: SHOW_DRAWER,
    payload: { drawer: drawerName, options },
  }
}

export function hideDrawer() {
  return {
    type: HIDE_DRAWER,
  }
}

export function showDetails() {
  return {
    type: SHOW_DETAILS,
  }
}

export function hideDetails() {
  return {
    type: HIDE_DETAILS,
  }
}

export function updatePlanFilters(filters) {
  return {
    type: UPDATE_PLAN_FILTERS,
    payload: { filters },
  }
}

export function updateNavigationWorkspaces(workspaces) {
  return {
    type: UPDATE_NAVIGATION_WORKSPACES,
    payload: { workspaces },
  }
}

export function updateNavigationWorkspacesQuery(query) {
  return {
    type: UPDATE_NAVIGATION_WORKSPACES_QUERY,
    payload: query,
  }
}

export function updateBarrierEscalationFilters(filters) {
  return {
    type: UPDATE_BARRIER_ESCALATION_FILTERS,
    payload: { filters },
  }
}

export function updateComplianceFilters(filters) {
  return {
    type: UPDATE_COMPLIANCE_FILTERS,
    payload: { filters },
  }
}

export function updateCurrentRoute(route) {
  return {
    type: UPDATE_CURRENT_ROUTE,
    payload: { route },
  }
}

export function showHideDischargedVisits(show) {
  return {
    type: SHOW_HIDE_DISCHARGED_VISITS,
    payload: { show },
  }
}

export function setShowAllMilestones(show) {
  return {
    type: SHOW_ALL_MILESTONES,
    payload: { show },
  }
}

export function setShowNAMilestones(show) {
  return {
    type: SHOW_NA_MILESTONES,
    payload: { show },
  }
}

export function setMilestoneView(view) {
  return {
    type: SET_MILESTONE_VIEW,
    payload: { view },
  }
}

export function setShowMilestoneDueDate(show) {
  return {
    type: SET_SHOW_MILESTONE_DUE_DATE,
    payload: { show },
  }
}

export function setShowAllBarriers(show) {
  return {
    type: SHOW_ALL_BARRIERS,
    payload: { show },
  }
}

export function setShowAllActions(show) {
  return {
    type: SHOW_ALL_ACTIONS,
    payload: { show },
  }
}

export function showMilestoneCallout(context) {
  return {
    type: SHOW_MILESTONE_CALLOUT,
    payload: context,
  }
}

export function hideMilestoneCallout() {
  return {
    type: HIDE_MILESTONE_CALLOUT,
  }
}

export function updateKpiCapped(capped) {
  return {
    type: UPDATE_KPI_CAPPED,
    payload: { capped },
  }
}

export function updateKpiFilters(filters) {
  return {
    type: UPDATE_KPI_FILTERS,
    payload: { filters },
  }
}
export function updateKpiChartSelection(chartSelection) {
  return {
    type: UPDATE_KPI_CHART_SELECTION,
    payload: { chartSelection },
  }
}

export function updateKpiSort(sort) {
  return {
    type: UPDATE_KPI_SORT,
    payload: { sort },
  }
}

export function updateKpiFacility(facility) {
  return {
    type: UPDATE_KPI_FACILITY,
    payload: { facility },
  }
}

export function updateKpiFLOSFilter(filter) {
  return {
    type: UPDATE_KPI_FLOS_FILTER,
    payload: { filter },
  }
}

export function updateKpiExcessDaysFilter(filter) {
  return {
    type: UPDATE_KPI_EXCESS_DAYS_FILTER,
    payload: { filter },
  }
}

export function updateKpiDimension(dimension) {
  return {
    type: UPDATE_KPI_DIMENSION,
    payload: { dimension },
  }
}

export function updateScrollTop(scrollTop) {
  return {
    type: UPDATE_SCROLL_TOP,
    payload: { scrollTop },
  }
}

export function updateSnapFilter(filter) {
  return {
    type: UPDATE_SNAP_FILTER,
    payload: { filter },
  }
}

export function setDetailTabIndex(index) {
  return {
    type: DETAIL_TAB_INDEX,
    payload: { index },
  }
}

export function setDetailTabHeight(height) {
  return {
    type: DETAIL_TAB_HEIGHT,
    payload: { height },
  }
}
