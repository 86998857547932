import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { showDrawer } from '../../ducks/views'
import { VisitDetails } from './VisitDetails'
import { drawers } from '../drawer-component-mapping'
import { withApplications } from '../../containers/app'
import {
  updateReadmissionRisk,
  updateReadmission,
  checkoutFromBed,
} from '../../graphql/relay/queries/visit'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'

export const VisitSectionGQL = compose(
  withApplications,

  connect(
    null,
    mapDispatchToProps
  ),
  mapProps(props => newProps(props))
)(VisitDetails)

const newProps = props => {
  const {
    actions: { showDrawer },
    workspace,
    updateDrg,

    applications,
  } = props

  const plEnabled = applications.patientLogistics.enabled

  const onSearchDRG = () => showDrawer(drawers.DRG_VIEW, workspace)

  const onReadmissionRiskChange = (visitId, readmissionRisk) => {
    const variables = { visitId: visitId, readmissionRisk: readmissionRisk }
    handleMutation(environment, updateReadmissionRisk, variables)
  }

  const onReadmissionChange = (visitId, readmission) => {
    const variables = { visitId: visitId, readmission: readmission }
    handleMutation(environment, updateReadmission, variables)
  }

  const onDRGNumberChange = (visitId, drgCode) => {
    updateDrg({ variables: { visitId: visitId, drgCode: drgCode } })
  }

  const onMoveToHoldingArea = (bed, visit) => {

    const variables = {
      visitId: visit.id,
      bedExchangeName: bed.exchangeName,
    }
    handleMutation(environment, checkoutFromBed, variables)
  }
  return {
    ...props,
    plEnabled,
    onSearchDRG,
    onReadmissionRiskChange,
    onReadmissionChange,
    onDRGNumberChange,
    onMoveToHoldingArea,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }
}
