import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { red400 } from 'material-ui/styles/colors'

// todo: later we can add a check to see if a Date instance was passed or a string.
export const DateNoTime = ({
  dateString,
  format = 'MMM D',
  defaultValue = '',
  highlightIfPast = false,
}) => {
  const now = moment()
  const momentDate = moment(dateString)
  const isToday = now.dayOfYear() === momentDate.dayOfYear()
  const past = highlightIfPast ? momentDate.isBefore(now) : false

  return (
    <span style={{ color: past ? red400 : '' }}>
      {dateString
        ? isToday
          ? 'Today'
          : momentDate.format(format)
        : defaultValue}
    </span>
  )
}

DateNoTime.propTypes = {
  dateString: PropTypes.string,
  highlightIfPast: PropTypes.bool,
  defaultValue: PropTypes.string,
  format: PropTypes.string,
}
