/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'

export class NetworkStatus extends Component {
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    connected: window.navigator.onLine,
  }

  componentDidMount() {
    this.intervalId = window.setInterval(this.handleRefresh, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalId)
    delete this.intervalId
  }

  handleRefresh = () => {
    // addEventListener for 'offline' and 'online' events didn't work on iPad, but
    // this approach does
    const connected = window.navigator.onLine

    if (connected !== this.state.connected) {
      this.setState({
        connected: connected,
      })
    }
  }

  render() {
    return (
      <Dialog
        open={!this.state.connected}
        modal
        title="No Network Connection  ">
        Functionality will be restored when the connection is re-established.
      </Dialog>
    )
  }
}
