/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MilestoneStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type Track = "Discharge" | "Progression" | "%future added value";
export type plan_updateMilestoneStatus_MutationVariables = {|
  milestoneId: any,
  status: MilestoneStatus,
|};
export type plan_updateMilestoneStatus_MutationResponse = {|
  +planMutation: ?{|
    +updateMilestoneStatus: ?{|
      +id: string,
      +appliedTemplates: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
        +description: string,
      |}>,
      +milestones: $ReadOnlyArray<?{|
        +id: string,
        +name: string,
        +description: ?string,
        +track: ?Track,
        +dueDate: ?any,
        +completedDate: ?any,
        +status: MilestoneStatus,
        +planTemplateName: string,
      |}>,
      +meta: ?{|
        +milestoneMeta: {|
          +onTime: any,
          +overdue: any,
          +completed: any,
        |}
      |},
    |}
  |}
|};
export type plan_updateMilestoneStatus_Mutation = {|
  variables: plan_updateMilestoneStatus_MutationVariables,
  response: plan_updateMilestoneStatus_MutationResponse,
|};
*/


/*
mutation plan_updateMilestoneStatus_Mutation(
  $milestoneId: UUID!
  $status: MilestoneStatus!
) {
  planMutation {
    updateMilestoneStatus(milestoneId: $milestoneId, status: $status) {
      id
      appliedTemplates {
        id
        name
        description
      }
      milestones {
        id
        name
        description
        track
        dueDate
        completedDate
        status
        planTemplateName
      }
      meta {
        milestoneMeta {
          onTime
          overdue
          completed
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "milestoneId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PlanMutation",
    "kind": "LinkedField",
    "name": "planMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "milestoneId",
            "variableName": "milestoneId"
          },
          {
            "kind": "Variable",
            "name": "status",
            "variableName": "status"
          }
        ],
        "concreteType": "Plan",
        "kind": "LinkedField",
        "name": "updateMilestoneStatus",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateInfo",
            "kind": "LinkedField",
            "name": "appliedTemplates",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Milestone",
            "kind": "LinkedField",
            "name": "milestones",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "track",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "planTemplateName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PlanMeta",
            "kind": "LinkedField",
            "name": "meta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MilestoneMeta",
                "kind": "LinkedField",
                "name": "milestoneMeta",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "onTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overdue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "plan_updateMilestoneStatus_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "plan_updateMilestoneStatus_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "dc50c4821d67a2c39e85647ad2e0a72e",
    "id": null,
    "metadata": {},
    "name": "plan_updateMilestoneStatus_Mutation",
    "operationKind": "mutation",
    "text": "mutation plan_updateMilestoneStatus_Mutation(\n  $milestoneId: UUID!\n  $status: MilestoneStatus!\n) {\n  planMutation {\n    updateMilestoneStatus(milestoneId: $milestoneId, status: $status) {\n      id\n      appliedTemplates {\n        id\n        name\n        description\n      }\n      milestones {\n        id\n        name\n        description\n        track\n        dueDate\n        completedDate\n        status\n        planTemplateName\n      }\n      meta {\n        milestoneMeta {\n          onTime\n          overdue\n          completed\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09b7f53fe28fc8e5f37e2423bacf338f';

module.exports = node;
