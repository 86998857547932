import React from 'react'
import moment from 'moment'

import NameDisplay from './NameDisplay/NameDisplay'
import MessageTypeLabel from './MessageTypeLabel/MessageTypeLabel'
import BarrierNotes from './BarrierNotes/BarrierNotes'
import PropTypes from 'prop-types'

import './BarrierHistoryRow.scss'

const BarrierHistoryRow = ({ historyData }) => {
  const timeSent = moment(historyData.timeSent)
  return (
    <div className={'BarrierHistoryRow'}>
      <div className={'InfoSection'}>
        <div className={'TimeSentSection'}>
          {timeSent.format('MM/DD/YY HH:mm')}
        </div>
        <div className={'NameDisplaySection'}>
          <NameDisplay historyData={historyData} />
        </div>
        <div className={'MessageTypeLabelSection'}>
          <MessageTypeLabel historyData={historyData} />
        </div>
      </div>
      <BarrierNotes historyData={historyData} />

    </div>
  )
}

BarrierHistoryRow.propTypes = {
  historyData: PropTypes.shape({
    timeSent: PropTypes.string.isRequired,
    recipientLastName: PropTypes.string.isRequired,
    recipientFirstName: PropTypes.string.isRequired,
    senderLastName: PropTypes.string.isRequired,
    senderFirstName: PropTypes.string.isRequired,
    messageSubType: PropTypes.string.isRequired
  }).isRequired
}

export default BarrierHistoryRow
