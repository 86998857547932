/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlatButton from "material-ui/FlatButton"
import {DrawerHeaderBar} from "../drawer"
import IconButton from "material-ui/IconButton"
import NavigationClose from "material-ui/svg-icons/navigation/close"
import {RadioButton, RadioButtonGroup } from "material-ui/RadioButton"
import Subheader from 'material-ui/Subheader'
import {
  censusReport,
  snapReport,
} from '../../util/reports'

class ReportParams extends Component {
  static propTypes = {
    reportType: PropTypes.string,
    closeDrawer: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

  }

  componentWillMount() {
    this.setState({
      Census: {
        notes: 'withNotes',
        filter: 'allPatients',
      },
      Snap: {
        notes: 'withNotes',
        filter: 'allBeds'
      }
    })
  }

  handleGenerate = () => {
    const { reportType } = this.props

    const {
      notes,
      filter
    } = this.state[reportType]

    let notesParam
    if (notes && notes === 'withNotes') {
      notesParam = 'true'
    } else {
      notesParam = 'false'
    }

    let bedParam
    if (filter && filter === 'allPatients') {
      bedParam = 1
    } else if (filter && filter === 'barrierPatients') {
      bedParam = -1
    } else {
      bedParam = 0
    }

    if(reportType === 'Census') {
      censusReport(notesParam, bedParam)
    } else if(reportType === 'Snap') {
      snapReport(notesParam, bedParam)
    }

    this.props.closeDrawer()

  }

  notesChanged = (event) => {
    const { reportType } = this.props

    this.setState({
      [reportType]: {
        ...this.state[reportType],
        notes: event.target.value
      }
    })

  }
  filterChanged = (event) => {
    const { reportType } = this.props
    this.setState({
      [reportType]: {
        ...this.state[reportType],
        filter: event.target.value
      }
    })
  }

  render() {
    const { reportType, closeDrawer } = this.props

    const title = `${reportType} Report`

    const activeState = this.state[reportType]

    const actions = [
      <FlatButton
        label="Generate Report"
        labelStyle={{ color: 'white' }}
        key="apply"
        onClick={this.handleGenerate}
      />,
    ]

    return (
      <div>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={closeDrawer}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div className="row">

          <div className="col-xs-1" />
          <div className="col-xs-4" >
            <RadioButtonGroup
              name="show"
              valueSelected={activeState.filter}>
                <RadioButton
                  value="allPatients"
                  label="All Patients"
                  onClick={this.filterChanged}
                />
                <RadioButton
                  value="barrierPatients"
                  label="Patients with Barriers"
                  onClick={this.filterChanged}
                />
                <RadioButton
                  value="allBeds"
                  label="All Beds (including Empty)"
                  onClick={this.filterChanged}
                />
            </RadioButtonGroup>
          </div>

          <div className="col-xs-1" />
          <div className="col-xs-4" onClick={this.onNotesChange}>
            <RadioButtonGroup
              name="notes"
              valueSelected={activeState.notes}>
              <RadioButton
                value="withNotes"
                label="Show Notes and To-Do's"
                onClick={this.notesChanged}
              />
              <RadioButton
                value="noNotes"
                label="Don't Show Notes and To-Do's"
                onClick={this.notesChanged}
              />
             </RadioButtonGroup>
          </div>

        </div>
      </div>

    )
  }

}

export default ReportParams
