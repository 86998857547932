import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import HistoryIcon from 'mdi-react/HistoryIcon'
import PersonName, { RECIPIENT, SENDER } from './PersonName/PersonName'
import IrisIcon from '../../../common/CustomIcons/IrisIcon'
import EmailIcon from 'mdi-react/EmailOutlineIcon'
import { APP_DELIVERY } from '../../BarrierConstants'

import './BarrierCommHistorySection.scss'

const iconSize = 18
const iconFillColor = '#4A4A4A'

const BarrierCommHistorySection = ({ history = [] }) => {
  moment.updateLocale('en', {
    relativeTime : {
      future: "in %s",
      past:   "%s ago",
      s:  "Just now",
      m:  "1 min",
      mm: "%d mins",
      h:  "1 hr",
      hh: "%d hrs",
      d:  "1 day",
      dd: "%d days",
      M:  "a month",
      MM: "%d months",
      y:  "year",
      yy: "%d year"
    }
  })

  const replaceTimestamp = (ts) => {
    return ts === 'Just now ago' ? 'Just now' : ts
  }

  return (
    <div className={'BarrierCommHistorySection'}>
      <div className={'HistoryHeader'}>
        <span className={'Icon'}>
          <HistoryIcon style={{ width: iconSize, height: iconSize, fill: iconFillColor }} />
        </span>

        <span>Sent History</span>
      </div>
      {history && history.length > 0 ?
        history.map((item, index) => (
        <div key={index} className={'HistoryRow'}>
          <div className={'CommMethodAndTime'}>
            <div className={'CommMethodIcon'}>
              {
                item.deliveryMethod === APP_DELIVERY ?
                  <IrisIcon color={'BLUE'} width={18} height={18} />
                  : <EmailIcon fill={'#4A4A4A'} style={{ width: 18, height: 18 }} />
              }
            </div>
            <div className={'TimeSent'}>{replaceTimestamp(moment(item.timeSent).fromNow())}</div>
          </div>
          <div className={'NameColumn'}>
            <PersonName nameType={RECIPIENT} displayName={`${item.recipientLastName}, ${item.recipientFirstName}`} />
          </div>
          <div className={'NameColumn'}>
            <PersonName nameType={SENDER} displayName={`${item.senderLastName}, ${item.senderFirstName}`} />
          </div>
        </div>
      ))
      : (
          <div className={'NoHistory'}>
            No history to display.
          </div>
        )}
    </div>
  )
}

BarrierCommHistorySection.propTypes = {
  history: PropTypes.array
}

export default BarrierCommHistorySection
