export const SELECT_ITEM = 'care-advance/app-selections/SELECT_ITEM'
export const DESELECT_ITEM = 'care-advance/app-selections/DESELECT_ITEM'
export const SELECT_SUB_ITEM = 'care-advance/app-selections/SELECT_SUB_ITEM'
export const DESELECT_SUB_ITEM = 'care-advance/app-selections/DESELECT_SUB_ITEM'
export const SELECT_TIME_PERIOD =
  'care-advance/app-selections/SELECT_TIME_PERIOD'

const initialState = {
  currentItem: { id: -1, __typename: '' },
  currentSubItem: { id: -1, __typename: '' },
  currentTimePeriod: 'Day7',
}

/**
 * Controls the 'selection context' within the application.
 *
 * The state is simply an object in which its key is a 'type' (defined by an item's 'type' property)
 * and the value being the item itself.
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_ITEM: {
      return {
        ...state,
        currentItem: action.payload,
        [action.payload['__typename']]: action.payload,
      }
    }
    case DESELECT_ITEM: {
      return {
        ...state,
        currentItem:
          state.currentItem.id === action.payload.id ? null : state.currentItem,
        [action.payload['__typename']]: null,
      }
    }
    case SELECT_SUB_ITEM: {
      return {
        ...state,
        currentSubItem: action.payload,
        [action.payload['__typename']]: action.payload,
      }
    }
    case DESELECT_SUB_ITEM: {
      return {
        ...state,
        currentSubItem:
          state.currentSubItem.id === action.payload.id
            ? null
            : state.currentSubItem,
        [action.payload['__typename']]: null,
      }
    }
    case SELECT_TIME_PERIOD: {
      return {
        ...state,
        currentTimePeriod: action.payload,
      }
    }
    default:
      return state
  }
}

/**
 * Creates an action which requests the state of the selected items contains
 * the item passed to the function.
 *
 * @param item An object containing a 'type' property
 * @returns {{type, payload: *, error: boolean}}
 */
export function selectItem(item) {
  return {
    type: SELECT_ITEM,
    payload: item,
    error: false,
  }
}

/**
 * Creates an action which requests the state of the selected items not contain
 * the item passed to the function.
 *
 * @param item An object containing a 'type' property
 * @returns {{type, payload: *, error: boolean}}
 */
export function deselectItem(item) {
  return {
    type: DESELECT_ITEM,
    payload: item,
    error: false,
  }
}
/**
 * Creates an action which requests the state of the selected sub items contains
 * the item passed to the function.
 *
 * @param item An object containing a 'type' property
 * @returns {{type, payload: *, error: boolean}}
 */
export function selectSubItem(item) {
  return {
    type: SELECT_SUB_ITEM,
    payload: item,
    error: false,
  }
}

/**
 * Creates an action which requests the state of the selected sub items not contain
 * the item passed to the function.
 *
 * @param item An object containing a 'type' property
 * @returns {{type, payload: *, error: boolean}}
 */
export function deselectSubItem(item) {
  return {
    type: DESELECT_SUB_ITEM,
    payload: item,
    error: false,
  }
}

/**
 * Creates an action which requests the state of the selected KPI Overview
 * Time Period items contains the item passed to the function.
 *
 * @param timePeriod An object containing timePeriod
 * @returns {{type, payload: *, error: boolean}}
 */
export function selectTimePeriod(timePeriod) {
  return {
    type: SELECT_TIME_PERIOD,
    payload: timePeriod,
    error: false,
  }
}
