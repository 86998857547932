/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import IconButton from 'material-ui/IconButton'
import FlatButton from 'material-ui/FlatButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'

import { graphql } from 'react-apollo'
import { DrgGQL } from '../../graphql/queries'

import { showDrawer } from '../../ducks/views'
import { drawers } from '../drawer-component-mapping'
import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import styled from 'styled-components'

import {
  severitiesSearch,
  dcSearch,
  mdcSearchFast,
  mdcSearch,
} from './drgFilter'
import { SeveritySearchInput } from '../../components/drg'
import { SeverityResults } from '../../components/drg'

const DRAWER_HEADER_HEIGHT = 40
const SEVERITY_NAV_HEIGHT = 36
const DRG_SEARCH_HEIGHT = 58

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showDrawer,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
@graphql(DrgGQL.queries.severities)
@graphql(DrgGQL.queries.severityPoster, { name: 'severityPoster' })
class SeverityView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
    }),

    drg: PropTypes.object.isRequired,

    data: PropTypes.shape({
      severities: PropTypes.arrayOf(
        PropTypes.shape({
          level: PropTypes.string,
          diagnosisCategory: PropTypes.string,
          secondaryDiagnosis: PropTypes.string,
        })
      ),
    }),

    severityPoster: PropTypes.shape({
      severityPoster: PropTypes.string,
    }),

    visit: PropTypes.object.isRequired,
    drgSearchFilters: PropTypes.object.isRequired,
    onSelectDrg: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    searchTerm: '',
    cc: true,
    mcc: true,
    dc: '',
  }

  componentWillReceiveProps(nextProps) {
    const family = nextProps.drg.family,
      cc = family.some(item => item.level === 'CC'),
      mcc = family.some(item => item.level === 'MCC')

    // uncheck if level not in family - there are a lot of Drgs with MCC but no CC
    this.setState({ cc: cc, mcc: mcc })
  }

  debounceId = null

  handleSearchTermChanged = e => {
    const value = e.target.value
    const textSearch = () =>
      this.setState({
        searchTerm: value,
      })

    if (this.debounceId) {
      clearTimeout(this.debounceId)
    }

    this.debounceId = setTimeout(textSearch, 400)
  }

  handleCCChanged = () => this.setState({ cc: !this.state.cc })

  handleMCCChanged = () => this.setState({ mcc: !this.state.mcc })

  handleDCChanged = (event, index, value) => {
    this.setState({
      dc: value === 'ALL' ? '' : value,
    })
  }

  handleReset = () => {
    // only set cc or mcc if in family
    let cc = false,
      mcc = false
    this.props.drg.family.map(item => {
      if (item.level === 'CC') {
        cc = true
      } else if (item.level === 'MCC') {
        mcc = true
      }
    })

    this.setState({
      searchTerm: '',
      cc: cc,
      mcc: mcc,
      dc: '',
    })
  }

  handleBack = () => {
    const { visit, drgSearchFilters, onRequestClose } = this.props

    onRequestClose()

    this.props.actions.showDrawer(drawers.DRG_VIEW, { visit, drgSearchFilters })
  }

  render() {
    const {
        drg,
        onSelectDrg,
        onRequestClose,
        data: { severities },
      } = this.props,
      filteredSeverities = severitiesSearch(severities, this.state),
      filteredDcs = dcSearch(severities, this.state)

    const posterLink = this.props.severityPoster.severityPoster

    const actions = [
      <FlatButton
        label="CC/MCC Poster"
        key="ccmcc-poster"
        labelStyle={{ color: 'white' }}
        href={posterLink}
        target="_blank"
      />,
    ]
    return (
      <StyledDiv DRAWER_HEADER_HEIGHT={DRAWER_HEADER_HEIGHT} DRG_SEARCH_HEIGHT={DRG_SEARCH_HEIGHT} SEVERITY_NAV_HEIGHT={SEVERITY_NAV_HEIGHT}>
        <DrawerHeaderBar
          title="Severity Search"
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={posterLink ? actions : []}
        />

        <SeveritySearchInput
          {...this.state}
          drg={drg}
          dcs={filteredDcs}
          onSearchTerm={this.handleSearchTermChanged}
          onDCChanged={this.handleDCChanged}
          onCCChanged={this.handleCCChanged}
          onMCCChanged={this.handleMCCChanged}
          onReset={this.handleReset}
          onBack={this.handleBack}
          onSelectDrg={onSelectDrg}
        />

        <SeverityResults
          severities={filteredSeverities}
          searchFilters={this.state}
        />
      </StyledDiv>
    )
  }
}

const StyledDiv = styled.div`
  height: 100%;

  > :last-child {
    height: calc(100% - ${props => props.DRAWER_HEADER_HEIGHT + props.DRG_SEARCH_HEIGHT + props.SEVERITY_NAV_HEIGHT}px) !important;
  }
`

export default SeverityView
