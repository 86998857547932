import React from 'react'
import PropTypes from 'prop-types'
import KnownPersonnelTypeAhead from '../../knownPersonnelTypeAhead/KnownPersonnelTypeAhead'

import './BarrierRecipient.scss'
import { buildKnownUserInput } from '../../../../graphql/relay/queries/knownUser'

const BarrierRecipient = ({ barrierRecipient, onSearchKnownPersonnel, onSelectBarrierRecipient }) => {
  const getKnownUserInput = (searchText) => {
    return buildKnownUserInput({ searchText: searchText })
  }

  return (
    <div className={'BarrierRecipient'}>
      <div className={'SendToIconWrapper'}>
        <span className={'SendToIcon'} />
      </div>
      <KnownPersonnelTypeAhead
        onSearchKnownPersonnel={async (searchText) => {
          const results = await onSearchKnownPersonnel(getKnownUserInput(searchText))
          return results.filter(i => i.emailReceiver === true || i.instantMessageReceiver === true)
        }}
        onResultSelected={onSelectBarrierRecipient}
        label="Type to choose a recipient"
        isShowIrisIcon
        selectedValue={barrierRecipient}
        showSubtext/>
    </div>
  )
}

BarrierRecipient.propTypes = {
  barrierRecipient: PropTypes.string,
  onSearchKnownPersonnel: PropTypes.func.isRequired,
  onSelectBarrierRecipient: PropTypes.func.isRequired
}

export default BarrierRecipient
