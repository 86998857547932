/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import FlatButton from 'material-ui/FlatButton'
import DatePicker from 'material-ui/DatePicker'
import TextField from 'material-ui/TextField'
import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'

import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import { ConfirmDialog } from '../../components/common'
import { Date as DateDisp } from '../../components/date'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'

import {
  removeTimeToDecision,
  updateTimeToDecisionWithOffset,
  updateTimeToDecisionWithTime,
} from '../../graphql/relay/queries/visit'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'
import { showErrorsPg } from '../../ducks/errorspg'


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showErrorsPg,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)

class EditDecisionTime extends Component {
  static propTypes = {
    visitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    admissionType: PropTypes.string,
    decisionTime: PropTypes.string,
    decisionTimeUseMidnight: PropTypes.bool,
    onRequestClose: PropTypes.func.isRequired,
    updateTimeToDecisionWithTime: PropTypes.func.isRequired,
    updateTimeToDecisionWithOffset: PropTypes.func.isRequired,
    removeTimeToDecision: PropTypes.func.isRequired,
    actions: {
      showErrorsPg: PropTypes.func
    },
    grid: PropTypes.object,
    refresh: PropTypes.func
  }

  static defaultProps = {
    decisionTime: moment().toISOString(),
  }

  constructor(props) {
    super(props)

    this.state = {
      isChanged: false,
      confirmOpen: false,
      confirmCloseOpen: false,
      hoursChange: '',
      datePickerDate: moment(props.decisionTime).toDate(),
    }
  }

  handleChangeHours = (event, hoursChange) => {
    // CA-405 had to clean hoursChange here since TextField type=number is really hosed under IE
    let cleaned = hoursChange.replace(/[^0-9-]/g, '')
    if (cleaned.length > 1) {
      cleaned = cleaned[0] + cleaned.substr(1).replace('-', '')
    }
    const maxChars = cleaned[0] === '-' ? 4 : 3
    this.setState({
      hoursChange: cleaned.substr(0, maxChars),
      isChanged: true,
    })
  }

  handleChangeDate = (event, datePickerDate) => {
    this.setState({
      datePickerDate: datePickerDate,
      isChanged: true,
    })
  }

  callback = () =>{
    this.props.onRequestClose()
    this.props.refresh ? this.props.refresh() :
      this.props.grid && this.props.grid.forceUpdateGrid()
  }

  handleSave = () => {
    const { visitId, decisionTimeUseMidnight, onRequestClose } = this.props
    const { hoursChange, datePickerDate } = this.state

    if (decisionTimeUseMidnight) {

      handleMutation(
        environment,
        updateTimeToDecisionWithTime,
        {
          visitId,
          decisionTime: moment(datePickerDate).toISOString(),
        },
        this.callback
      )
    } else {
      handleMutation(
        environment,
        updateTimeToDecisionWithOffset,
        {
          visitId,
          hoursOffset: hoursChange,
        },
        this.callback
      )
    }
  }

  handleRemove = () => {
    this.setState({
      confirmOpen: true,
    })
  }

  handleCancelConfirmRemove = () => {
    this.setState({
      confirmOpen: false,
    })
  }

  handleConfirmRemove = () => {
    this.setState({
      confirmOpen: false,
    })

    handleMutation(
      environment,
      removeTimeToDecision,
      {
        visitId: this.props.visitId,
      },
      this.callback
    )
  }

  shouldClose() {
    return new Promise((resolve, reject) => {
      this.handleAcceptConfirmClose = resolve
      this.handleCancelConfirmClose = reject
      this.setState({
        confirmCloseOpen: true,
        hoursChange: '',
      })
    })
  }

  render() {
    const {
      title,
      admissionType,
      decisionTime,
      decisionTimeUseMidnight,
      onRequestClose,
    } = this.props

    const {
      datePickerDate,
      hoursChange,
      confirmOpen,
      confirmCloseOpen,
      isChanged,
    } = this.state

    const applyButtonStyle = isChanged && {
      labelStyle: {
        color: 'white',
      },
    }

    const actions = [
      decisionTime && admissionType === 'OutPatient' && (
        <FlatButton
          label="Remove"
          key="remove"
          secondary
          onClick={this.handleRemove}
        />
      ),
      <FlatButton
        label="Apply"
        key="apply"
        {...applyButtonStyle}
        disabled={!isChanged}
        onClick={this.handleSave}
      />,
    ]
    return (
      <div>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div style={{ margin: '15px 15px 0 25px' }}>
          Current Decision Time:&nbsp;
          {decisionTime ? (
            <DateDisp dateString={decisionTime} highlightIfPast />
          ) : (
            'Not Set'
          )}
        </div>

        <div style={{ marginLeft: 25 }}>
          {decisionTimeUseMidnight ? (
            <DatePicker
              defaultDate={datePickerDate}
              minDate={new Date()}
              floatingLabelText="New Decision Date"
              onChange={this.handleChangeDate}
            />
          ) : (
            <TextField
              type="text"
              floatingLabelText={
                decisionTime ? 'Hours to add or subtract' : 'Hours from now'
              }
              onChange={this.handleChangeHours}
              value={this.state.hoursChange}
            />
          )}
        </div>

        <ConfirmDialog
          open={confirmOpen}
          message="Are you sure you want to remove the Decision Time?"
          onCancelClick={this.handleCancelConfirmRemove}
          onConfirmClick={this.handleConfirmRemove}
        />

        <ConfirmDialog
          open={confirmCloseOpen}
          message="You have unsaved data. Do you want to proceed without applying?"
          onCancelClick={this.handleCancelConfirmClose}
          onConfirmClick={this.handleAcceptConfirmClose}
        />
      </div>
    )
  }
}

export default EditDecisionTime
