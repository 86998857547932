import React from 'react'
import PropTypes from 'prop-types'

import { PlanHeader } from './PlanHeader'
import { default as BarrierSection } from '../barrier/BarrierSection'

class EscalationWorkspace extends React.Component {
  onNavigate = () => {
    this.props.onNavigate(this.props.index)
  }

  render() {
    const {
      workspace,
      routeTo,
      onQuickAddBarrier,
      onAddBarrier,
      onUpdatePinned,
      onEscalateBarrier,
      onDeescalateBarrier,
      onUpdateBarrierStatus,
      onUpdateBarrierOwner,
      onSearchKnownPersonnel,
      onCreateBarrierNote,
      onUpdateBarrierNote,
      onRemoveBarrierNote,
      onBarrierClick,
      onShowAllBarriers,
      rvlist,
      showBarriersExpanded,
      selectedBarrierSection: {
        showAllBarriers,
        selectedPlanId,
        selectedBarrierId,
        userBarriers
      },
      barrierEscalationRoles,
      deliveryMethods,
      onSendBarrierInfo,
      onLoadBarrierMessageHistory,
      onUpdateSourceType,
      onUpdateSourceName,
      onCreateBarrierFromTemplate
    } = this.props

    return (
      <div style={{ position: 'relative' }}>
        <div className="paper" style={{ flex: 'auto' }}>
          <PlanHeader
            viewType="EscalationList"
            workspace={workspace}
            routeTo={routeTo}
            onNavigate={this.onNavigate}
            rvlist={rvlist}
          />
          <BarrierSection
            showBarriersExpanded={showBarriersExpanded}
            rvlist={rvlist}
            workspace={workspace}
            showAllBarriers={showAllBarriers}
            onShowAllBarriers={onShowAllBarriers}
            selectedPlanId={selectedPlanId}
            selectedBarrierId={selectedBarrierId}
            onBarrierClick={onBarrierClick}
            onQuickAddBarrier={onQuickAddBarrier}
            onAddBarrier={onAddBarrier}
            onUpdatePinned={onUpdatePinned}
            onEscalateBarrier={onEscalateBarrier}
            onDeescalateBarrier={onDeescalateBarrier}
            onUpdateBarrierStatus={onUpdateBarrierStatus}
            onCreateBarrierFromTemplate={onCreateBarrierFromTemplate}
            onUpdateBarrierOwner={onUpdateBarrierOwner}
            onSearchKnownPersonnel={onSearchKnownPersonnel}
            onCreateBarrierNote={onCreateBarrierNote}
            onUpdateBarrierNote={onUpdateBarrierNote}
            onRemoveBarrierNote={onRemoveBarrierNote}
            userBarriers={userBarriers}
            barrierEscalationRoles={barrierEscalationRoles}
            deliveryMethods={deliveryMethods}
            onSendBarrierInfo={onSendBarrierInfo}
            onLoadBarrierMessageHistory={onLoadBarrierMessageHistory}
            onUpdateSourceType={onUpdateSourceType}
            onUpdateSourceName={onUpdateSourceName}
            renderForEscalation
          />
        </div>
      </div>
    )
  }
}

EscalationWorkspace.propTypes = {
  rvlist: PropTypes.object,
  workspace: PropTypes.shape({
    facility: PropTypes.object,
    department: PropTypes.object,
    bed: PropTypes.object.isRequired,
    visit: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
  }),
  routeTo: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  index: PropTypes.number,
  onBarrierClick: PropTypes.func.isRequired,
  showAllBarriers: PropTypes.bool,
  onShowAllBarriers: PropTypes.func.isRequired,
  onQuickAddBarrier: PropTypes.func.isRequired,
  onAddBarrier: PropTypes.func.isRequired,
  onUpdateBarrierStatus: PropTypes.func.isRequired,
  onUpdateBarrierOwner: PropTypes.func.isRequired,
  onEscalateBarrier: PropTypes.func.isRequired,
  onDeescalateBarrier: PropTypes.func.isRequired,
  onCreateBarrierNote: PropTypes.func.isRequired,
  onUpdateBarrierNote: PropTypes.func.isRequired,
  onRemoveBarrierNote: PropTypes.func.isRequired,
  onUpdatePinned: PropTypes.func.isRequired,
  selectedBarrierSection: PropTypes.object.isRequired,
  showBarriersExpanded: PropTypes.bool,
  barrierEscalationRoles: PropTypes.array,
  deliveryMethods: PropTypes.array,
  onSearchKnownPersonnel: PropTypes.func.isRequired,
  onSendBarrierInfo: PropTypes.func.isRequired,
  onLoadBarrierMessageHistory: PropTypes.func.isRequired,
  onUpdateSourceType: PropTypes.func.isRequired,
  onUpdateSourceName: PropTypes.func.isRequired,
  onCreateBarrierFromTemplate: PropTypes.func.isRequired,
}

export default EscalationWorkspace
