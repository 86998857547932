import React from 'react'
import PropTypes from 'prop-types'

import NotSetIcon from 'mdi-react/HelpIcon'
import OnTimeIcon from 'mdi-react/CheckboxBlankCircleIcon'
import AtRiskIcon from 'mdi-react/AlertCircleIcon'
import HighRiskIcon from 'mdi-react/AlertCircleIcon'

import {
  grey800 as NotSetColor,
  red500 as HighRiskColor,
  yellow500 as AtRiskColor,
  green500 as OnTimeColor,
  red200 as ComplianceColor,
} from 'material-ui/styles/colors'

export const ProgressionIndicatorIcon = ({
  value,
  complianceIssue,
  styleIcon = { verticalAlign: '-8px' },
  styleNotSetIcon = { verticalAlign: '-8px' },
}) => {
  let current
  switch (value) {
    case 'OnTime':
      current = <OnTimeIcon style={{ ...styleIcon, fill: OnTimeColor }} />
      break
    case 'AtRisk':
      current = <AtRiskIcon style={{ ...styleIcon, fill: AtRiskColor }} />
      break
    case 'HighRisk':
      current = <HighRiskIcon style={{ ...styleIcon, fill: HighRiskColor }} />
      break
    default:
      //Not set by default
      current = (
        <NotSetIcon
          style={{
            ...styleNotSetIcon,
            position: 'relative',
            top: -3,
            width: 21,
            height: 21,
            border: '1px solid grey',
            borderRadius: '3px',
            fill: NotSetColor,
            background: complianceIssue ? ComplianceColor : null,
          }}
        />
      )
  }

  return current
}

ProgressionIndicatorIcon.propTypes = {
  value: PropTypes.string,
  complianceIssue: PropTypes.boolean,
  styleIcon: PropTypes.object,
  styleNotSetIcon: PropTypes.object,
}
