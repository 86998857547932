import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Paper } from 'material-ui'
import {
  red200 as ComplianceColor,
  red600 as PastColor,
} from 'material-ui/styles/colors'

import {
  findColor,
  calcForecastWorkingOE,
} from '../../util/workspace'
import { NameFormat } from '../common'
import { BedUnitLink } from '../common'
import { CodeImage } from '../image/CodeImage'
import { ItemCount } from '../plan'
import { MilestoneSummaryStatusIcon } from '../milestone'
import { ProgressionIndicatorIcon } from '../'
import { getBedRequestStatusName } from '../../util/utils'
import { getPlannedLabel } from '../../util/configs'
import { findComplianceIssues } from '../../util/compliance'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('Workspace', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {
    item: {
      marginTop: '5px',
      padding: '5px 0 5px 0',
    },
    primaryLine: {
      marginLeft: '0 !important',
      marginRight: '0 !important',
      marginBottom: '5px',
    },
    primaryLineLast: {
      marginBottom: '0',
    },
    primaryLineInfo: {
      height: '22px',
      paddingLeft: '8px !important',
      paddingRight: '8px !important',

      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'default',
    },
    primaryLineInfoRight: {
      paddingLeft: '1px !important',
      paddingRight: '1px !important',

      overflow: 'hidden',
      whiteSpace: 'nowrap',
      cursor: 'default',
    },
    info: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      overflow: 'hidden',
    },
    clickable: {
      cursor: 'pointer',
    },
    pullRight: {
      float: 'right',
    },
    expanded: {},
    bedRequestStatus: { marginRight: '15px' },
    oeNameSpan: {
      fontWeight: 'bold',
    },
    oeValueSpan: { paddingTop: '1px' },
    oeNameValueSpan: {
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
    },
    primaryLineIcon: { height: '21px' },
    container: {
      width: '100%',
      display: 'flex',
      alignItems: 'stretch',
    },
    leftColumn: {
      width: 'calc(100% - 50px)',
    },
    rightColumn: {
      width: '50px',
      border: '1px solid lightgray',
      borderRight: 'none',
    },
    drgInfo: {},
    wddInfo: {},
    visitStatusInfo: {
      height: '22px',
      paddingTop: '1px',
    },
    icons: {},
    barrierIcon: {},
    milestoneSummaryIcon: {
      position: 'relative',
      top: '-2px',
      display: 'inline-block',
      marginLeft: '4px',
      marginRight: '4px',
    },
    compliance: {
      display: 'inline-block',
      border: '1px solid grey',
      padding: '1px 5px 1px 5px',
      borderRadius: '3px',
      marginTop: '5px',
      marginRight: '5px',
      cursor: 'default',
    },
    plannedDCContainer: {},
    noDRG: {
      position: 'relative',
      border: '1px solid grey',
      padding: '0 3px 0 3px',
      borderRadius: '3px',
    },
    noWDD: {
      position: 'relative',
      border: '1px solid grey',
      padding: '0 3px 0 3px',
      borderRadius: '3px',
    },
    plannedDC: {
      position: 'relative',
      border: '1px solid grey',
      padding: '0 3px 0 3px',
      borderRadius: '3px',
      fontSize: '14px',
      // cursor: 'pointer',
    },
    complianceIssue: {
      background: ComplianceColor,
      fontSize: '14px',
    },
    inPast: {
      color: PastColor,
    },
  }
})

const makeDRG = (classes, c3Classes, visit, compliance) => {
  const drgInfo = visit.drgAttributes ? (
    `${visit.drgAttributes.code} ${visit.drgAttributes.level}`
  ) : (
    <span
      className={classNames(
        classes.noDRG,
        compliance.noDRGIssue ? classes.complianceIssue : null
      )}>
      No DRG
    </span>
  )

  return (
    <span className={classNames(c3Classes.attrLeft, classes.drgInfo)}>
      {drgInfo}
    </span>
  )
}

const makeWDD = (classes, c3Classes, visit, compliance) => {
  const wddInfo =
    visit.workingDischargeDisposition.code === 'NotSet' ? (
      <span
        className={classNames(
          classes.noWDD,
          compliance.noWDDIssue ? classes.complianceIssue : null
        )}>
        No D/C Arrangements
      </span>
    ) : (
      visit.workingDischargeDisposition.value
    )

  return (
    <span className={classNames(c3Classes.attrLeft, classes.wddInfo)}>
      {wddInfo}
    </span>
  )
}

const makePlannedDC = (classes, visit, compliance) => {
  //noPlanDischarge planDischargeIssue planDischargeInPast
  const showRelativeTime = false
  const formatDate = date => {
    const d = moment(date)
    return (
      `${d.format('MMM D HH:mm')}` +
      (showRelativeTime ? ` (${d.fromNow()})` : '')
    )
  }

  return (
    <span
      className={classNames(
        classes.plannedDCContainer,
        compliance.planDischargeIssue ? classes.complianceIssue : null,
        compliance.planDischargeInPast ? classes.inPast : null
      )}>
      <span className={classNames(classes.plannedDC)}>
        {visit.anticipatedDischargeTime
          ? formatDate(visit.anticipatedDischargeTime)
          : `No ${getPlannedLabel()} D/C`}
      </span>
    </span>
  )
}

export const Workspace = (
  {
    item,
    capped,
    thresholds,
    expanded,
    oeThresholdCaution,
    oeThresholdDanger,
    onClick,
    onViewPlanClick,
  },
  context
) => {
  const classes = context.styleManager.render(styleSheet)
  const c3Classes = context.muiTheme.c3Classes

  const {
    department,
    bed,
    visit,
    visit: { patient },
    plan,
  } = item

  const compliance = findComplianceIssues(bed, visit, plan, thresholds)

  const dayOfText = `Day ${visit.currentDayOfStay} of ${
    visit.tlos ? visit.tlos : '?'
  }`

  const forecastWorkingOE = calcForecastWorkingOE(capped, item)

  let openBarriersCount = 0
  plan.barriers.forEach(
    barrier => (openBarriersCount += barrier.status === 'Open' ? 1 : 0)
  )

  const oeBackground = findColor(
    forecastWorkingOE,
    oeThresholdCaution,
    oeThresholdDanger
  )

  const leftColumn = (
    <span className={classNames(classes.leftColumn)}>
      <div
        className={classNames(
          'row',
          classes.primaryLine,
          classes.primaryLineInfo
        )}>
        <div className={classNames('col-xs-8', classes.info)}>
          <div>
            <BedUnitLink
              bed={bed}
              unit={department.name}
              planId={plan.id}
              visitId={visit.id}
              onClick={onViewPlanClick}
            />
            <span>
              <span className={classNames(c3Classes.spacerLeft)}>
                <CodeImage type="Gender" code={patient.gender || 'nil'} />
              </span>
              <span className={classNames(c3Classes.attrLeft)}>
                {patient.age}&nbsp;
              </span>
              <span className={classNames(c3Classes.attrLeft)}>
                <NameFormat person={patient} />
              </span>
            </span>
          </div>
        </div>
        <div className={classNames('col-xs-4', classes.info)}>
          <span className={classNames(classes.pullRight)}>
            {`${dayOfText} `}
          </span>
        </div>
      </div>
      <div
        className={classNames(
          'row',
          classes.primaryLine,
          expanded ? null : classes.primaryLineLast,
          classes.primaryLineInfo
        )}>
        <div className={classNames('col-xs-8', classes.info)}>
          <span className={classes.icons}>
            <span>
              <ProgressionIndicatorIcon
                styleIcon={{}}
                styleNotSetIcon={{}}
                value={plan.progressionIndicator}
                complianceIssue={compliance.noPSIssue}
              />
            </span>
            <span>
              <MilestoneSummaryStatusIcon
                className={classes.milestoneSummaryIcon}
                milestoneStatuses={{
                  onTime: plan.meta.milestoneMeta.onTime,
                  overdue: plan.meta.milestoneMeta.overdue,
                  completed: plan.meta.milestoneMeta.completed,
                }}
              />
            </span>
            <span
              style={{
                width: 40,
                display: 'inline-block',
              }}>
              <ItemCount
                small
                type="barrier"
                avatarStyle={{ verticalAlign: '5px' }}
                countStyle={{ verticalAlign: '8px' }}
                className={classes.barrierIcon}
                count={openBarriersCount}
              />
            </span>
          </span>
          <span style={{ verticalAlign: '8px' }}>
            {makeDRG(classes, c3Classes, visit, compliance)}&nbsp;
            {makeWDD(classes, c3Classes, visit, compliance)}
          </span>
        </div>
        <div
          className={classNames(
            'col-xs-4',
            classes.info,
            classes.visitStatusInfo
          )}>
          <span className={classNames(classes.pullRight)}>
            <span className={classNames(classes.bedRequestStatus)}>
              {getBedRequestStatusName(visit.bedRequestStatus)}
            </span>
            {makePlannedDC(classes, visit, compliance)}
          </span>
        </div>
      </div>
    </span>
  )
  const rightColumn = (
    <span
      className={classNames(classes.rightColumn)}
      style={{ background: oeBackground }}>
      <div
        className={classNames(
          classes.primaryLine,
          classes.primaryLineIcon,
          classes.primaryLineInfoRight
        )}>
        <div className={classNames(classes.info)}>
          <span
            className={classNames(
              classes.oeNameSpan,
              classes.oeNameValueSpan
            )}>{`O/E`}</span>
        </div>
      </div>
      <div
        className={classNames(
          classes.primaryLine,
          expanded ? null : classes.primaryLineLast,
          classes.primaryLineInfoRight
        )}>
        <div className={classNames(classes.info)}>
          <span
            className={classNames(
              classes.oeValueSpan,
              classes.oeNameValueSpan
            )}>{`${forecastWorkingOE}`}</span>
        </div>
      </div>
    </span>
  )
  return (
    <Paper
      className={classNames(classes.item, expanded ? classes.expanded : null)}
      onClick={onClick ? e => onClick(e, item) : () => {}}>
      <div className={classNames(classes.container)}>
        {leftColumn}
        {rightColumn}
      </div>
    </Paper>
  )
}

Workspace.contextTypes = {
  styleManager: PropTypes.object.isRequired,
  muiTheme: PropTypes.object.isRequired,
}

Workspace.propTypes = {
  item: PropTypes.object,
  capped: PropTypes.bool,
  thresholds: PropTypes.object,
  expanded: PropTypes.bool,
  oeThresholdCaution: PropTypes.number,
  oeThresholdDanger: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  onViewPlanClick: PropTypes.func,
}
