import React from 'react'
import { PatientPlan } from './PatientPlan'
import { QueryRenderer, graphql } from 'react-relay'
import { environment } from '../../graphql/relay/env'
import { PatientPlanQuery} from "./PatientPlanGQL"
import PropTypes from 'prop-types'
import { LoadingIndicator } from '../../components/loader'

/*
This supports the Epic link that will pass a VisitNumber
Query is designed to return data for any bed that user has HOS permission for,
bed need not be currently Assigned to user in CA
 */
export class PatientPlanVNGQL extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const visitNumber = this.props.match.params.id
    return (
      <QueryRenderer
        environment={environment}
        query={PatientPlanQuery}
        variables={{ id: `VisitNum:${visitNumber}` }}
        render={({ error, props, retry }) => {
          if (error) {
            return <div>Error!</div>
          }
          if (!props) {
            return <LoadingIndicator />
          }
          // handle not finding passed in VisitNumber
          if(props && !props.node) {
            return <h1 style={{marginLeft: '60px'}}>{`Visit Number: ${visitNumber} not found`}</h1>
          }

          return (<PatientPlan
            visit={props.node}
            userSetting={props.userSetting}
            barrierEscalationRoles={props.barrierEscalationRoles}
            deliveryMethods={props.deliveryMethods}
            refetch={retry}
            {...this.props}/>)
        }}
      />
    )
  }
}
PatientPlanVNGQL.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
}

