/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import FlatButton from 'material-ui/FlatButton'
import PropTypes from 'prop-types'
import { DrawerHeaderBar } from '../../components/drawer/DrawerHeaderBar'
import IconButton from 'material-ui/IconButton'
import { StatefulTextField } from '../../components/common/StatefulTextField'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import { modifyVisibilityBoardAddressEntry } from '../../graphql/relay/queries/visibilityBoardAddress'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import {environment} from "../../graphql/relay/env"
import { bindActionCreators } from 'redux'
import { showErrorsPg } from '../../ducks/errorspg'
import {connect} from 'react-redux'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        showErrorsPg
      },
      dispatch
    ),
  }
}

class EditVisibilityBoardAddressEntry extends Component {
  static propTypes = {
    data: PropTypes.exact({
      id: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      visibilityBoard: PropTypes.object.isRequired,
      boards: PropTypes.array.isRequired
    }),
    onRequestClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      showErrorsPg: PropTypes.func
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      id: this.props.data.id,
      address: this.props.data.address,
      description: this.props.data.description,
      visibilityBoardId: this.props.data.visibilityBoard.id,
      addressOriginal: this.props.data.address,
      descriptionOriginal: this.props.data.description,
      visibilityBoardIdOriginal: this.props.data.visibilityBoard.id
    }
  }

  handleError = (errKey, errCode, errMessage) => {
    this.setState({
      isSubmitting: false
    })
  }

  handleAddressUpdate = e => {
    this.setState({
      address: e.target.value,
    })
  }

  handleDescriptionUpdate = e => {
    this.setState({
      description: e.target.value,
    })
  }

  handleVisibilityBoardIdUpdate = (event, index, value)=> {
    this.setState({
      visibilityBoardId: value
    })
  }

  onApplyHandler = () => {
    this.setState({
      isSubmitting: true
    })
    const variables = {
      visibilityBoardAddressId: this.state.id,
      visibilityBoardAddressInput: {
        address: this.state.address,
        description: this.state.description,
        visibilityBoardId: this.state.visibilityBoardId
      }
    }

    handleMutation(
      environment,
      modifyVisibilityBoardAddressEntry,
      variables,
      () => {
        this.props.onRequestClose && this.props.onRequestClose()
      },
      undefined,
      this.handleError
    )
  }

  render() {
    const { title, onRequestClose, data } = this.props

    const {
      address,
      description,
      visibilityBoardId,
      addressOriginal,
      descriptionOriginal,
      visibilityBoardIdOriginal
    } = this.state

    const boardItems = data.boards.map(board =>
      <MenuItem key={board.id} value={board.id} primaryText={board.name} />,
    )

    const noValueChanged = (address === addressOriginal)
      && (description === descriptionOriginal)
      && (visibilityBoardId === visibilityBoardIdOriginal)

    const anyRequiredValueUnPopulated = !address || !visibilityBoardId || !description

    const applyDisabled = noValueChanged || anyRequiredValueUnPopulated

    const applyButtonStyle = !applyDisabled && {
      labelStyle: {
        color: 'white',
      },
    }

    const actions = [
      <FlatButton
        key="apply"
        label="Apply"
        disabled={applyDisabled}
        {...applyButtonStyle}
        onClick={this.onApplyHandler}
      />,
    ]

    return (
      <div>
        <DrawerHeaderBar
          title={title}
          iconElementLeft={
            <IconButton onClick={onRequestClose}>
              <NavigationClose />
            </IconButton>
          }
          actions={actions}
        />

        <div className="row">
          <div className="col-xs-1" />
          <div className="col-xs-10">
            <StatefulTextField
              text={address}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Address *',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Address *'
              }}
              onChange={this.handleAddressUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <StatefulTextField
              text={description}
              textFieldProps={{
                fullWidth: true,
                hintText: 'Description *',
                disabled: this.state.isSubmitting,
                floatingLabelText: 'Description *'
              }}
              onChange={this.handleDescriptionUpdate}
              maxNumberOfChars={255}
              clearOnTextEntered={false}
              showCharacterCount
            />
            <SelectField
              fullWidth
              floatingLabelShrinkStyle={{whiteSpace: 'nowrap'}}
              floatingLabelText="Visibility Board *"
              value={visibilityBoardId}
              onChange={this.handleVisibilityBoardIdUpdate}
            >
              {boardItems}
            </SelectField>
          </div>
          <div className="col-xs-1" />
        </div>
      </div>
    )
  }

}
export default connect(null, mapDispatchToProps)(EditVisibilityBoardAddressEntry)
