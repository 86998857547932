import React from 'react'
import {createFragmentContainer, graphql} from 'react-relay'
import VisibilityBoardRow from "./VisibilityBoardRow"
import PropTypes from 'prop-types'

const VisibilityBoardRowList = ({list, onEdit, onDelete, departmentMap}) => {
  return (
    <React.Fragment>
      {list.map(item => <VisibilityBoardRow key={item.__id} departmentMap={departmentMap} rowData={item} onEdit={onEdit} onDelete={onDelete}/>)}
    </React.Fragment>
  )
}

VisibilityBoardRowList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  departmentMap: PropTypes.object
}

export default createFragmentContainer(VisibilityBoardRowList, {
  list: graphql`
    fragment VisibilityBoardRowList_list on VisibilityBoard @relay(plural: true) {
      ...VisibilityBoardRow_rowData
    }
  `,
})
