/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FlatButton from 'material-ui/FlatButton'
import MenuItem from 'material-ui/MenuItem'
import DropDownMenu from 'material-ui/DropDownMenu'
import ArrowBack from 'mdi-react/ArrowLeftIcon'
import ArrowForward from 'mdi-react/ArrowRightIcon'
import { grey400 as DisabledColor } from 'material-ui/styles/colors'
import BedsSelect from './BedsSelect'

export class BedNavigator extends Component {
  static propTypes = {
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        bed: PropTypes.object,
        visit: PropTypes.object,
        plan: PropTypes.object,
      })
    ),
    bedId: PropTypes.string,
    visitId: PropTypes.string,
    onPlanNavigation: PropTypes.func,
    textColor: PropTypes.string,
  }

  state = {
    index: this.props.workspaces.findIndex(
      ws =>
        ws.visit.id === this.props.visitId &&
        ws.bed.exchangeName === this.props.bedId
    ),
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      index: nextProps.workspaces.findIndex(
        ws =>
          ws.visit.id === nextProps.visitId &&
          ws.bed.exchangeName === nextProps.bedId
      ),
    })
  }

  // CA-1312 - handle crazy "workspace" from Compliance view, no plan but
  // id is the plan.id
  getData(workspace) {
    const { plan, visit, bed } = workspace
    return [bed.exchangeName, plan ? plan.id : workspace.id, visit.id]
  }

  handleBackwardNavigate() {
    const { workspaces, onPlanNavigation } = this.props
    const { index } = this.state

    this.setState({ index: index - 1 }, () => {
      onPlanNavigation(...this.getData(workspaces[index - 1]))
    })
  }

  handleForwardNavigate() {
    const { workspaces, onPlanNavigation } = this.props
    const { index } = this.state

    this.setState({ index: index + 1 }, () => {
      onPlanNavigation(...this.getData(workspaces[index + 1]))
    })
  }

  handleSelect = bed_visit => {
    const items = bed_visit.split('_')
    const bedId = items[0]
    const visitId = items[1]
    const { workspaces, onPlanNavigation } = this.props
    // CA-2850 - now find index via bed and visit
    const index = workspaces.findIndex(ws => ws.bed.exchangeName === bedId && ws.visit.id === visitId)

    this.setState({ index: index }, () => {
      onPlanNavigation(...this.getData(workspaces[index]))
    })
  }

  render() {
    const { workspaces, textColor } = this.props
    const { index } = this.state

    const ws = workspaces[index]
    const hasPrev = index > 0
    const hasNext = index < workspaces.length - 1

    const arrayFrontColor = hasPrev ? textColor : DisabledColor
    const arrayBackColor = hasNext ? textColor : DisabledColor

    return (
      <span>
        <FlatButton
          style={{ top: -2, minWidth: 60 }}
          icon={<ArrowBack style={{ marginLeft: 0, fill: arrayFrontColor }} />}
          disabled={!hasPrev}
          onClick={() => this.handleBackwardNavigate()}
        />

        <BedsSelect
          selectedValue={ws}
          workspaces={workspaces}
          textColor={textColor}
          handleSelect={this.handleSelect}
        />

        <FlatButton
          style={{ top: -2, minWidth: 60 }}
          icon={
            <ArrowForward style={{ marginRight: 0, fill: arrayBackColor }} />
          }
          labelPosition="before"
          disabled={!hasNext}
          onClick={() => this.handleForwardNavigate()}
        />
      </span>
    )
  }
}
