import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import IconButton from 'material-ui/IconButton'
import NavigationClose from 'material-ui/svg-icons/navigation/close'
import {DrawerHeaderBar} from '../../../components/drawer'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import TextField from '../../../components/common/TextField'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { handleMutation } from '../../../graphql/relay/queries/mutation'
import {environment} from "../../../graphql/relay/env"
import isEqual from 'lodash.isequal'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import SourceDetailConfigValueSelection
  from '../../../components/barrier/BarrierSource/SourceDetailConfigValueSelection'
import {
  newEntrySourceTypeUpdater,
  updateEntrySourceTypeUpdater
} from "../../../graphql/relay/queries/barrierSourceType";

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {},
      dispatch
    ),
  }
}

const BarrierSourceTypeDrawer = (
  {
    title,
    isEdit,
    data,
    mutation,
    onRequestClose,
    onError}) => {
  const LDAP = "LDAP"
  const CONFIG_LIST = "CONFIG_LIST"
  const FREE_TEXT = "FREE_TEXT"

  const determineSelectionType = (entry) => {
    let selectionType
    if (entry.activeDirectorySourceName) {
      selectionType = LDAP
    } else if (entry.hasConfiguredList) {
      selectionType = CONFIG_LIST
    } else {
      selectionType = FREE_TEXT
    }
    return selectionType
  }

  const getSelectedSourceDetails = (sourceDetailsList) => {
    return sourceDetailsList.filter(item => item.isSelected)
  }

  const getSelectedSourceDetailCodes = (sourceDetailsList) => {
    return getSelectedSourceDetails(sourceDetailsList).map(item => item.code)
  }

  const [code, setCode] = useState(data.code)
  const [value, setValue] = useState(data.value)
  const [ordinal, setOrdinal] = useState(data.ordinal.toString())
  const [active, setActive] = useState(data.active)
  const [activeDirectorySourceName, setActiveDirectorySourceName] = useState(data.activeDirectorySourceName)
  const [selectionType, setSelectionType] = useState(determineSelectionType(data))
  const [selectedSourceDetails, setSelectedSourceDetails] = useState(getSelectedSourceDetails(data.sourceDetails))

  const handleSelectionRadioButtonChange = (event) => {
    setSelectionType(event.target.value)
    setActiveDirectorySourceName(event.target.value === LDAP)
  }

  const handleSourceDetailChange = (newValue) => {
    setSelectedSourceDetails(newValue)
  }

  const handleCodeBlur = (event) => {
    const value = event.target.value
    const trimmedValue = value.trim()

    setCode(trimmedValue)
  }

  const getFinalSourceDetailCodes = () => {
    if (selectionType === CONFIG_LIST) {
      return getSelectedSourceDetailCodes(selectedSourceDetails)
    } else {
      return []
    }
  }

  const onClickApply = () => {
    const variables = {
      barrierSourceTypeInput: {
        code,
        value,
        ordinal,
        active,
        activeDirectorySourceName,
        sourceDetailCodes: getFinalSourceDetailCodes()
      }
    }

    let updater
    if (!isEdit) {
      updater = (proxyStore) => {
        newEntrySourceTypeUpdater(proxyStore, variables)
      }
    } else {
      updater = (proxyStore) => {
        updateEntrySourceTypeUpdater(proxyStore, variables)
      }
    }

    handleMutation(
      environment,
      mutation,
      variables,
      () => {
        onRequestClose && onRequestClose()
      },
      null,
      onError,
      updater
    )
  }

  const noValueChanged = (code === data.code)
      && (value === data.value)
      && (ordinal === data.ordinal.toString())
      && (active === data.active)
      && (activeDirectorySourceName === data.activeDirectorySourceName)
      && (selectionType === determineSelectionType(data))
      && (isEqual(getSelectedSourceDetailCodes(selectedSourceDetails), getSelectedSourceDetailCodes(data.sourceDetails)))

  const anyValueUnPopulated = !code || !value || !ordinal

  const applyDisabled = isEdit ? (noValueChanged || anyValueUnPopulated) : anyValueUnPopulated

  const drawerActions = [
    <StyledApplyButton
      key="apply"
      disabled={applyDisabled}
      onClick={onClickApply}
    >Apply</StyledApplyButton>,
  ]

  return (
    <div>
      <DrawerHeaderBar
        title={title}
        iconElementLeft={
          <IconButton onClick={onRequestClose}>
            <NavigationClose />
          </IconButton>
        }
        actions={drawerActions}
      />
      <FieldSection>
        <StyledTextFieldWrapper>
          <TextField
            value={code}
            label={'Code (Required)'}
            maxChars={30}
            disabled={isEdit}
            onChange={event => setCode(event.target.value)}
            onBlur={handleCodeBlur}
            fullWidth
            showCharacterCount
            />
        </StyledTextFieldWrapper>
        <StyledTextFieldWrapper>
          <TextField
            value={value}
            label={'Description (Required)'}
            maxChars={255}
            onChange={event => setValue(event.target.value)}
            fullWidth
            showCharacterCount
          />
        </StyledTextFieldWrapper>
        <StyledTextFieldWrapper>
          <TextField
            value={ordinal}
            label={'Sort Order (Required)'}
            maxChars={3}
            onChange={event => setOrdinal(event.target.value)}
            fullWidth
            type={'number'}
          />
        </StyledTextFieldWrapper>
        <FormControlLabel
          control={<Switch color={'primary'} checked={active} onChange={event => setActive(event.target.checked)} />}
          label="Active"
        />
        <StyledSelectionTypeFormControl>
          <StyledSelectionTypeLabel>Selection Type</StyledSelectionTypeLabel>
          <StyledSelectionTypeRadioGroup aria-label="selection type" name="selectionType" value={selectionType} onChange={handleSelectionRadioButtonChange}>
            <FormControlLabel value={LDAP} control={<Radio color="primary" />} label="AD Name Lookup" />
            <FormControlLabel value={CONFIG_LIST} control={<Radio color="primary" />} label="Configured List" />
            <FormControlLabel value={FREE_TEXT} control={<Radio color="primary" />} label="Free Text" />
          </StyledSelectionTypeRadioGroup>
        </StyledSelectionTypeFormControl>

        <SourceDetailConfigValueSelection
          sourceDetailConfigValues={data.sourceDetails}
          selectedValue={selectedSourceDetails}
          onChange={handleSourceDetailChange}
          fieldLabel="Configured List Values"
          multiple
          hidden={selectionType !== CONFIG_LIST}
        />

      </FieldSection>
    </div>
  )
}

const FieldSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
  width: 60%;
`

const StyledTextFieldWrapper = styled.div`
  margin-bottom: 20px;
`

const StyledApplyButton = styled(Button)`
  && {
    font-weight: 500;
    text-transform: uppercase;

    ${props => !props.disabled && css`
      color: white;
    `}
  }
`

const StyledSelectionTypeFormControl = styled(FormControl)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

const StyledSelectionTypeLabel = styled(FormLabel)`
  && {
    width: 120px;
  }
`

const StyledSelectionTypeRadioGroup = styled(RadioGroup)`
  && {
    display: flex;
    flex-direction: row;
  }
`

BarrierSourceTypeDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    code: PropTypes.string,
    value: PropTypes.string,
    ordinal: PropTypes.number,
    active: PropTypes.bool,
    activeDirectorySourceName: PropTypes.bool,
    hasConfiguredList: PropTypes.bool,
    sourceDetails: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        value: PropTypes.string,
        ordinal: PropTypes.number,
        active: PropTypes.bool,
        isSelected: PropTypes.bool
      })
    )
  }),
  mutation: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onError: PropTypes.func
}

export default connect(null, mapDispatchToProps)(BarrierSourceTypeDrawer)
