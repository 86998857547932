import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  compose,
  mapProps,
} from 'recompose'
import { drawers } from '../../containers/drawer-component-mapping'
import { selectItem } from '../../ducks/app-selections'
import { setShowAllBarriers, showDrawer } from '../../ducks/views'
import BarrierSection from '../barrier/BarrierSection'
import {
  createBarrier,
  createBarrierFromTemplate,
  updateBarrierOwner,
  escalateBarrier,
  deescalateBarrier,
  updateBarrierStatus,
  sendBarrierInfoMessage,
  barrierHistory,
  updateBarrierSourceType,
  updateBarrierSourceName,
  barrierOwnerOptimisticResponse,
  barrierSourceNameOptimisticResponse
} from '../../graphql/relay/queries/barrier'

import { searchKnownUsers } from '../../graphql/relay/queries/knownUser'

import {
  createBarrierNote,
  updateBarrierNote,
  removeBarrierNote,
} from "../../graphql/relay/queries/note"

import {
  pinBarrier,
  unpinBarrier,
} from '../../graphql/relay/queries/pin'
import { handleMutation } from '../../graphql/relay/queries/mutation'
import { environment } from '../../graphql/relay/env'
import {fetchQuery} from 'react-relay'
export const BarrierSectionGQL = compose(

  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  mapProps(props => newProps(props))
)(BarrierSection)


const newProps = props => {
  const {

    actions: { selectItem, setShowAllBarriers, showDrawer },
    workspace,
    selected,
    showAllBarriers,
    refetch
  } = props

  const selectedPlanId = workspace.plan.id
  const selectedBarrierId = selected.currentItem.id


  const onBarrierClick = (e, item) => {
    e.stopPropagation()
    selectItem(item)
  }

  const onShowAllBarriers = () => {
    setShowAllBarriers(!props.showAllBarriers)
  }

  const onUpdatePinned = barrier => {
    const fn = barrier.pinned ? unpinBarrier : pinBarrier
    const variables = { itemId: barrier.id }
    handleMutation(environment, fn, variables)
  }

  const onAddBarrier = (planId, visit) => () => {
    showDrawer(drawers.CHOOSE_BARRIER, {
      title: `Add Barrier for ${visit.patient.lastName}, ${
        visit.patient.firstName
      }`,
      planId: planId,
      visit: visit
    })
  }

  const onQuickAddBarrier = planId => barrierInfo => {

    if (barrierInfo.item.templateId === null) {
      const variables = {
        planId: planId,
        barrier: {
          name: barrierInfo.item.name,
          description: barrierInfo.item.description,
          categoryCode: barrierInfo.item.category.code,
        },
      }
      handleMutation(
        environment,
        createBarrier,
        variables
      )
    } else {
      const variables = {
        planId: planId,
        templateId: barrierInfo.item.templateId,
        bedRequestStatus: barrierInfo.bedRequestStatus,
      }
      onCreateBarrierFromTemplate(variables)
    }
  }

  const onCreateBarrierFromTemplate = (variables, callback) => {
    handleMutation(environment, createBarrierFromTemplate, variables, (response)=>{
      refetch()
      if(callback){
        callback(response)
      }
    })
  }

  const onUpdateBarrierStatus = (barrierId, status, bedRequestStatus) => {
    const variables = { barrierId, status, bedRequestStatus }
    handleMutation(environment, updateBarrierStatus, variables, refetch)
  }

  const onUpdateBarrierOwner = (barrier, owner, knownUser) => {
    const variables = { barrierId: barrier.id, owner: owner, ownerId: knownUser?.id }
    const optimisticResponse = barrierOwnerOptimisticResponse(barrier, owner, knownUser)
    handleMutation(environment, updateBarrierOwner, variables, undefined, undefined, undefined, undefined, optimisticResponse)
  }

  const onUpdateSourceType = (barrierId, sourceTypeCode) => {
    const variables = { barrierId: barrierId, sourceTypeCode: sourceTypeCode }
    handleMutation(environment, updateBarrierSourceType, variables)
  }

  const onUpdateSourceName = (barrier, sourceName) => {
    const variables = { barrierId: barrier.id, sourceName: sourceName }
    const optimisticResponse = barrierSourceNameOptimisticResponse(barrier, sourceName)
    handleMutation(environment, updateBarrierSourceName, variables, undefined, undefined, undefined, undefined, optimisticResponse)
  }

  const onSearchKnownPersonnel = async (knownUserInput) => {
    const response = await fetchQuery(environment, searchKnownUsers, { knownUserInput })
    return response.knownUsers
  }

  const onEscalateBarrier = barrierId => {
    const variables = { barrierId: barrierId }
    handleMutation(environment, escalateBarrier, variables)
  }

  const onDeescalateBarrier = barrierId => {
    const variables = { barrierId: barrierId }
    handleMutation(environment, deescalateBarrier, variables)
  }

  const onCreateBarrierNote = (barrierId, text) => {
    const variables = { barrierId: barrierId, text: text, planId: selectedPlanId }
    handleMutation(environment, createBarrierNote, variables)
  }

  const onUpdateBarrierNote = (noteId, text) => {
    const variables = { noteId: noteId, text: text }
    handleMutation(environment, updateBarrierNote, variables)
  }

  const onRemoveBarrierNote = noteId => {
    const variables = { noteId: noteId }
    handleMutation(environment, removeBarrierNote, variables)
  }

  const onSendBarrierInfo = (e, barrierId, recipientId, message, closeModal) => {
    e.stopPropagation()
    const variables = { barrierId: barrierId, recipientId: recipientId, messageNote: message }
    handleMutation(environment, sendBarrierInfoMessage, variables, closeModal)
  }

  const onLoadBarrierMessageHistory = async (barrierId, onlySentBarrierInfo) => {
    const variables = { barrierId: barrierId, onlySentBarrierInfo: onlySentBarrierInfo }

    /*if (variables.barrierId) {
      variables.barrierId = variables.barrierId.substring(
        variables.barrierId.indexOf(':') + 1
      )
    }*/

    const response = await fetchQuery(environment, barrierHistory, variables)
    return response.node.barrierHistory
  }

  return {
    ...props,
    onBarrierClick,
    selectedPlanId,
    showAllBarriers,
    selectedBarrierId,
    onCreateBarrierNote,
    onUpdateBarrierNote,
    onRemoveBarrierNote,
    onShowAllBarriers,
    onQuickAddBarrier,
    onAddBarrier,
    onUpdateBarrierStatus,
    onUpdateBarrierOwner,
    onSearchKnownPersonnel,
    onEscalateBarrier,
    onDeescalateBarrier,
    onUpdatePinned,
    onSendBarrierInfo,
    onLoadBarrierMessageHistory,
    onUpdateSourceType,
    onUpdateSourceName,
    onCreateBarrierFromTemplate,
  }
}

function mapStateToProps(state) {
  return {
    selected: state.selectAppState,
    showAllBarriers: state.viewsAppState.showAllBarriers,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectItem,
        setShowAllBarriers,
        showDrawer,
      },
      dispatch
    ),
  }
}
