import React from 'react'
import PropTypes from 'prop-types'

import NotSetIcon from 'mdi-react/HelpCircleIcon'
import OnTimeIcon from 'mdi-react/CheckboxBlankCircleIcon'
import AtRiskIcon from 'mdi-react/AlertCircleIcon'
import HighRiskIcon from 'mdi-react/AlertCircleIcon'

import {
  grey800 as NotSetColor,
  red500 as HighRiskColor,
  yellow500 as AtRiskColor,
  green500 as OnTimeColor,
} from 'material-ui/styles/colors'

export const ProgressionIndicator = ({ value, style }) => {
  switch (value) {
    case 'OnTime':
      return <OnTimeIcon style={{ ...style, fill: OnTimeColor }} />
    case 'AtRisk':
      return <AtRiskIcon style={{ ...style, fill: AtRiskColor }} />
    case 'HighRisk':
      return <HighRiskIcon style={{ ...style, fill: HighRiskColor }} />
    default:
      return (
        <NotSetIcon
          style={{
            ...style,
            fill: NotSetColor,
          }}
        />
      )
  }
}

ProgressionIndicator.propTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.object,
}
