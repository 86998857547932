import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { print as gqlToString } from 'graphql/language/printer'

export function withMutations(mutationArgs = []) {
  return withMutationsHelper

  function withMutationsHelper(WrappedComponent) {
    return class WithMutations extends Component {
      static contextTypes = {
        client: PropTypes.object,
      }

      handleInvokeMutation = (
        mutationIn,
        mutationConfig = {},
        props = {},
        name = ''
      ) => (options = {}) => {
        const apolloConfig = { ...options }

        if (typeof mutationConfig.options === 'function') {
          Object.assign(apolloConfig, mutationConfig.options(props))
        }

        return this.context.client
          .mutate({ mutation: mutationIn, ...apolloConfig })
          .then(r => {
            const { handler } = mutationConfig

            if (handler) {
              // eslint-disable-next-line react/prop-types
              props.actions.updateGraphqlData({
                data: handler(r.data),
                name,
              })
            }

            // This is so ugly, it's temporary
            if (mutationConfig.refetchQueries) {
              mutationConfig.refetchQueries.forEach(entry => {
                const {
                  query,
                  variables = () => ({}),
                  name,
                  handler = item => item,
                } = entry
                props.graphQLClient
                  .query(gqlToString(query), variables(r))
                  .then(r => {
                    const updatedValue = handler(r.data)
                    if (updatedValue != null) {
                      props.actions.updateGraphqlData({
                        data: handler(r.data),
                        name,
                      })
                    }
                  })
              })
            }
          })
      }

      render() {
        const mutations = mutationArgs.reduce((acc, mutation) => {
          if (Array.isArray(mutation)) {
            const name = determineMutationName(mutation[0])
            acc[name] = this.handleInvokeMutation(
              mutation[0],
              mutation[1],
              this.props,
              name
            )
          } else {
            acc[determineMutationName(mutation)] = this.handleInvokeMutation(
              mutation
            )
          }
          return acc
        }, {})

        return <WrappedComponent {...this.props} {...mutations} />
      }
    }
  }
}

function determineMutationName(mutation) {
  const def = mutation.definitions.find(
    def => def.kind === 'OperationDefinition' && def.operation === 'mutation'
  )
  return def && def.name.value
}
