import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
// import TextField from '@material-ui/core/TextField'
import TextField from '../../../common/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from 'material-ui/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { findIndex, isEmpty } from 'lodash'
import { graphql } from 'react-apollo'
import { TemplateGQL } from '../../../../graphql/queries'

@graphql(TemplateGQL.queries.allBarrierTemplates, {
  name: 'barrierTemplatesAndCategories',
})

class BarrierCategoryTemplatesPicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openCustomDialog: false,
      openConfirmDialog: false,
      showCustomBarrier: false,
      customBarrierName: '',
      selectedTemplate: null,
      categoryTemplateId: (Math.random() + 1).toString(36).substring(7)
    }
    this.customTextInputFocus = this.customTextInputFocus.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.categoryId !== nextProps.categoryId) {
      this.ResetSelection()
    }
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.setState({
        selectedTemplate: null,
        customBarrierName: ''
      })
    }
  }

  // getter of barrier categories filter
  get barrierCategoryTemplatesData() {
    const { barrierTemplatesAndCategories } = this.props
    const cleanDataList = []
    if (barrierTemplatesAndCategories && !barrierTemplatesAndCategories.loading) {
      // filter the GraphQL Data
      barrierTemplatesAndCategories.barrierTemplates.forEach(item => {
        let index = findIndex(cleanDataList, {categoryId: item.category.id})
        if (index === -1) {
          cleanDataList.push({
            categoryId: item.category.id,
            templates: []
          })
          index = cleanDataList.length - 1
        }

        if (item.active) {
          cleanDataList[index].templates.push(item)
        }
      })
      // loop and add a custom field
      cleanDataList.forEach((item, index) => {
        cleanDataList[index].templates.push({
          id: null,
          name: "CUSTOM"
        })
      })
    }
    return cleanDataList
  }

  customTextInputFocus() {
    this.customTextInput.focus()
  }

  get templatesList() {
    const { categoryId } = this.props

    if (isEmpty(categoryId)) {
      return []
    }
    const index = findIndex(this.barrierCategoryTemplatesData, {'categoryId': categoryId})
    if (index < 0) {
      // console.error(`Category ID (${categoryId} is not found in the barrier category templates list`, this.barrierCategoryTemplatesData)
      return [{
        id: null,
        name: "CUSTOM"
      }]
    }
    return this.barrierCategoryTemplatesData[index].templates
  }

  OnSelectHandler = (event, value) => {
    const { onSelect } = this.props

    if (value?.id === null  && value?.name.toUpperCase() === 'CUSTOM') {
      this.setState({
        openCustomDialog: true,
        customBarrierName: '',
        selectedTemplate: value
      })
    } else if (value?.id && value?.name) {
      this.setState({
        selectedTemplate: value,
        customBarrierName: ''
      })
      onSelect({
        id: value.id,
        name: value.name
      })
    } else {
      this.setState({
        selectedTemplate: value,
        customBarrierName: ''
      })
      onSelect(null)
    }
  }

  HandleCloseCustomBarrierDialog = () => {
    const { customBarrierName, openConfirmDialog } = this.state
    if (customBarrierName.trim().length > 0) {
      this.setState({
        openConfirmDialog: true
      })
    } else {
      this.ResetSelection()
    }
  }

  HandleDoneCustomBarrierDialog = () => {
    const { selectedTemplate, customBarrierName } = this.state
    const { onSelect } = this.props

    selectedTemplate['customBarrierName'] = customBarrierName
    this.setState({
      openCustomDialog: false,
      showCustomBarrier: true
    })
    onSelect({
      id: null,
      name: customBarrierName
    })
    setTimeout(this.customTextInputFocus, 300)
  }

  HandleUpdateCustomBarrierName = (event) => {
    this.setState({customBarrierName: event.target.value})
  }

  // HandleBlurCustomBarrierName = (event) => {
  //   if (event.target.value == '') {
  //     this.ResetSelection()
  //   } else {
  //     this.HandleDoneCustomBarrierDialog()
  //   }
  // }

  ResetSelection = () => {
    const { onSelect } = this.props
    this.setState({
      categoryTemplateId: (Math.random() + 1).toString(36).substring(7),
      openCustomDialog: false,
      openConfirmDialog: false,
      showCustomBarrier: false,
      customBarrierName: '',
      selectedTemplate: null
    })
    onSelect(null)
  }

  render() {
    const { className, categoryId, hasError } = this.props
    const {
      openCustomDialog,
      openConfirmDialog,
      selectedTemplate,
      categoryTemplateId,
      customBarrierName,
      showCustomBarrier
    } = this.state

    return (
      <>
        {showCustomBarrier ? (
          <div className={className}>
          <TextField
            inputRef={(input) => { this.customTextInput = input }}
            variant="outlined"
            label="Barrier Name"
            value={customBarrierName}
            // helperText="Custom Barrier"
            size="small"
            maxChars={30}
            fullWidth
            showCharacterCount
            error={hasError}
            errorText="Required field"
            onChange={this.HandleUpdateCustomBarrierName}
            InputProps={{
              style: {
                paddingRight: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    tabIndex={-1}
                    onClick={this.ResetSelection}
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          /></div>
        ) : (

          <Autocomplete
            key={categoryTemplateId}
            size="small"
            required
            autoComplete
            autoHighlight
            // autoSelect={(this.templatesList ? (this.templatesList.length > 1) : true)}     // disabling a Tab selection
            clearOnEscape
            autoFocus
            openOnFocus
            // disabled={isEmpty(categoryId)}
            className={className}
            options={isEmpty(categoryId) ? [] : this.templatesList}
            getOptionLabel={(option) => option.name}
            onChange={this.OnSelectHandler}
            style={{opacity: isEmpty(categoryId) ? 0.5 : 1}}
            renderInput={(params) =>
              <TextField
                {...params}
                label={isEmpty(categoryId) ? "- Please select a category first -": "Barrier Name" }
                variant="outlined"
                error={hasError}
                helperText={ selectedTemplate?.description }
                FormHelperTextProps={{
                  style:{
                    // fontSize: '0.9em',
                    // fontWeight: 'bold'
                    float: 'left',
                    height: 0,
                    marginTop: 0,
                    position: 'relative',
                    top: '4px'
                  }
                }}
              />}
          />
        )}
        <Dialog
          open={openCustomDialog}
          // onClose={this.HandleCloseCustomBarrierDialog}
          style={{"fontSize": "14px"}}
          aria-labelledby="form-dialog-title"
          disableClose
        >
          <DialogTitle id="form-dialog-title">Create a new barrier</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a custom barrier, please enter a barrier name.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Enter a barrier name"
              fullWidth
              maxChars={30}
              showCharacterCount
              maxWidth="md"
              onChange={this.HandleUpdateCustomBarrierName}
              onKeyDown={(ev) => {
                console.log(ev)
                if (ev.key === 'Enter') {
                  this.HandleDoneCustomBarrierDialog()
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.HandleCloseCustomBarrierDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.HandleDoneCustomBarrierDialog}
              color="primary"
              disabled={!customBarrierName.trim().length}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openConfirmDialog}
          aria-labelledby="confirm-dialog-title"
          disableClose
        >
          <DialogTitle id="confirm-dialog-title">Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to cancel the custom barrier name input?
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              this.setState({
                openConfirmDialog: false,
                showCustomBarrier: false
              })}}>
            Go back
          </Button>
          <Button
            color="primary"
            onClick={this.ResetSelection}>
            Clear and exit
          </Button>
        </DialogActions>
        </Dialog>
      </>
    )
  }
}

BarrierCategoryTemplatesPicker.propTypes = {
  className: PropTypes.string,
  categoryId: PropTypes.string,
  hasError: PropTypes.bool,
  barrierTemplatesAndCategories: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    barrierTemplates: PropTypes.array,
  }),
  onSelect: PropTypes.func
}

export default BarrierCategoryTemplatesPicker
