import React from 'react'
import PropTypes from 'prop-types'
import {
  APP_DELIVERY,
  MESSAGE_SENT_MSG_SUB_TYPE,
  BARRIER_OWNER_ASSIGNED_SUB_TYPE,
  ACKNOWLEDGED_MSG_SUBTYPE,
  NEEDS_REASSIGNMENT_MSG_SUBTYPE,
  NEW_NOTE_MSG_SUBTYPE,
  RESOLVED_MSG_SUBTYPE,
  BARRIER_REOPENED_MSG_SUBTYPE } from '../../../BarrierConstants'

import './MessageTypeLabel.scss'

const MessageTypeLabel = ({ historyData }) => {
  let label
  let cssClass
  if (historyData.messageSubType === MESSAGE_SENT_MSG_SUB_TYPE || historyData.messageSubType === BARRIER_OWNER_ASSIGNED_SUB_TYPE) {
    const deliveryMethod = historyData.deliveryMethod === APP_DELIVERY ? 'RESOLVE' : 'EMAIL'
    label = `SENT TO ${deliveryMethod}`
    cssClass = 'Sent'
  } else if (historyData.messageSubType === ACKNOWLEDGED_MSG_SUBTYPE) {
    label = 'ACKNOWLEDGED'
    cssClass = 'Acknowledged'
  } else if (historyData.messageSubType === RESOLVED_MSG_SUBTYPE) {
    label = 'RESOLVED'
    cssClass = 'Resolved'
  } else if (historyData.messageSubType === NEW_NOTE_MSG_SUBTYPE) {
    label = 'NEW NOTE'
    cssClass = 'NewNote'
  } else if (historyData.messageSubType === NEEDS_REASSIGNMENT_MSG_SUBTYPE) {
    label = "NEEDS REASSIGNMENT"
    cssClass = 'NeedsReassignment'
  } else if (historyData.messageSubType === BARRIER_REOPENED_MSG_SUBTYPE) {
    label = "REOPENED"
    cssClass = 'Reopened'
  } else {
    label = "OTHER"
    cssClass = 'Other'
  }
  return (
    <div className={cssClass}>
      {label}
    </div>
  )
}

MessageTypeLabel.propTypes = {
  historyData: PropTypes.shape({
    messageSubType: PropTypes.string.isRequired,
    deliveryMethod: PropTypes.string
  }).isRequired
}

export default MessageTypeLabel
