/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type note_FullPlanNote$ref: FragmentReference;
declare export opaque type note_FullPlanNote$fragmentType: note_FullPlanNote$ref;
export type note_FullPlanNote = {|
  +id: string,
  +text: string,
  +auditMeta: ?{|
    +created: any,
    +createdBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
    +updated: any,
    +updatedBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
  |},
  +pinned: boolean,
  +$refType: note_FullPlanNote$ref,
|};
export type note_FullPlanNote$data = note_FullPlanNote;
export type note_FullPlanNote$key = {
  +$data?: note_FullPlanNote$data,
  +$fragmentRefs: note_FullPlanNote$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "note_FullPlanNote",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditMeta",
      "kind": "LinkedField",
      "name": "auditMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditUser",
          "kind": "LinkedField",
          "name": "createdBy",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditUser",
          "kind": "LinkedField",
          "name": "updatedBy",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinned",
      "storageKey": null
    }
  ],
  "type": "PlanNote",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '8470ff5ea0918eb1bcba53a373002e49';

module.exports = node;
