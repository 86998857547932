/*eslint-disable react/no-set-state */

import React from 'react'
import PropTypes from 'prop-types'
import OpenDrawer from 'material-ui/svg-icons/content/add-circle-outline'
import IconButton from 'material-ui/IconButton'
import BarrierIcon from 'mdi-react/GateIcon'
import FlatButton from 'material-ui/FlatButton'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

import classNames from 'classnames'
import './barrier.scss'
import { QuickAddMenu } from '../plan'
import { Barrier } from './Barrier'
import { graphql } from 'react-relay'
import SendBarrierInfoDialog from './sendBarrierInfo/SendBarrierInfoDialog/SendBarrierInfoDialog'
import BarrierCommunicationHistory from './barrierCommunicationHistory/BarrierCommunicationHistoryDialog/BarrierCommunicationHistoryDialog'
import AddNewBarrier from './addNewBarrier/AddNewBarrier'

import {
  auditCreatedCompareAscending,
  groupOpenResolved,
  escalationCompare, auditCreatedCompareDescending,
} from '../../util/sort'
import Snackbar from 'material-ui/Snackbar'
import ManageWatchersDialog from './watchers/ManageWatchersDialog'

export default class BarrierSection extends React.Component {

  state = {
    sendBarrierNoteModalOpen: false,
    sendBarrierSuccessSnackbarOpen: false,
    barrierCommunicationHistoryOpen: false,
    manageWatchersModalOpen: false,
    barrier: null,
    shouldOpenNewBarrierDialog: false,
    barrierCommunicationHistory: [],
    useNewBarrierInput: true
  }

  rerender = () => {
    this.props.rvlist?.forceUpdateGrid()
  }

  render() {
    const {
      workspace,
      selectedPlanId,
      selectedBarrierId,
      onBarrierClick,
      showAllBarriers,
      onShowAllBarriers,
      onQuickAddBarrier,
      onAddBarrier,
      onUpdateBarrierStatus,
      onUpdateBarrierOwner,
      onSearchKnownPersonnel,
      onEscalateBarrier,
      onDeescalateBarrier,
      onCreateBarrierNote,
      onUpdateBarrierNote,
      onRemoveBarrierNote,
      onUpdatePinned,
      showBarriersExpanded,
      userBarriers,
      barrierEscalationRoles,
      deliveryMethods,
      onSendBarrierInfo,
      onLoadBarrierMessageHistory,
      onUpdateSourceType,
      onUpdateSourceName,
      renderForEscalation,
      onCreateBarrierFromTemplate
    } = this.props

    const { plan, visit, bed, department } = workspace

    const planSelected = selectedPlanId === plan.id

    const activeUserBarriers = userBarriers
      ? userBarriers
          .filter(barrier => barrier.active)
          .map(barrier => ({
            item: barrier,
            text: `${barrier.category.value} : ${barrier.name}`,
          }))
      : undefined

    const quickAddElements = [
      {
        items: activeUserBarriers,
        handleQuickAddItem: onQuickAddBarrier(plan.id),
      },
    ]

    const openBarriers = plan.barriers.filter(
      barrier => barrier.status === 'Open'
    )

    const resolvedBarriers = plan.barriers.filter(
      barrier => barrier.status === 'Resolved'
    )

    const resolvedBarriersCount = resolvedBarriers.length

    const barriers =
      showAllBarriers && plan.id === selectedPlanId
        ? plan.barriers
        : openBarriers

    const sortedBarriers = groupOpenResolved(
      barriers
        .slice(0)
        // .sort(auditCreatedCompareAscending)
        .sort(auditCreatedCompareDescending)
        .sort(escalationCompare)
    )

    const handleOpenManageWatchersDialog = (e, barrier) => {
      e.stopPropagation()

      this.setState({
        manageWatchersModalOpen: true,
        barrier: barrier
      })
    }

    const handleCloseManageWatchersDialog = () => {
      this.setState({
        manageWatchersModalOpen: false
      })
    }

    const handleOpenSendBarrierNoteDialog = async (e, barrier) => {
      e.stopPropagation()
      const hist = await onLoadBarrierMessageHistory(barrier.id, true)

      this.setState({
        sendBarrierNoteModalOpen: true,
        barrier: barrier,
        barrierCommunicationHistory: hist
      })
    }

    const handleCloseSendBarrierNoteDialog = () => {
      this.setState({
        sendBarrierNoteModalOpen: false,
        barrierCommunicationHistory: []
      })
    }

    const handleSendBarrierDialogSuccess = () => {
      this.setState({
        sendBarrierNoteModalOpen: false ,
        sendBarrierSuccessSnackbarOpen: true
      })
    }

    const handleSendBarrierSnackbarClose = () => {
      this.setState({
        sendBarrierSuccessSnackbarOpen: false
      })
    }

    const handleOpenViewBarrierNoteHistoryDialog = async (e, barrier) => {
      e.stopPropagation()
      const hist = await onLoadBarrierMessageHistory(barrier.id, false)

      this.setState({
        barrierCommunicationHistoryOpen: true,
        barrier: barrier,
        barrierCommunicationHistory: hist
      })
    }

    const handleCloseBarrierCommHistoryDialog = () => {
      this.setState({
        barrierCommunicationHistoryOpen: false,
        barrierCommunicationHistory: []
      })
    }

    const renderHeadLineText = () => (
      <div className="head-line-text">
        {plan.barriers.length === 0
          ? 'No Barriers'
          : resolvedBarriersCount > 0
          ? `${resolvedBarriersCount} Resolved Barrier(s)`
          : 'Barriers'}
      </div>
    )

    const renderHeadLineDetail = () => (
      <div className={classNames('head-line', renderForEscalation ? 'escalation-head-line' : '')}>
        <div className="detail">
          <QuickAddMenu
            elements={quickAddElements}
            visit={visit}
            showMenuType={false}
            rerender={this.rerender}
          />
          {/*Adding new Barrier with the drawer*/}
          {/*<IconButton*/}
          {/*  style={{ padding: 0, width: 30, height: 30 }}*/}
          {/*  onClick={onAddBarrier(plan.id, visit, this.rerender)}>*/}
              {/* <OpenDrawer /> */}
          {/*</IconButton>*/}

          {renderForEscalation && (
            <span>
              <IconButton
                style={{ padding: 0, width: 30, height: 30 }}
                onClick={() => {
                  this.setState({shouldOpenNewBarrierDialog: true})
                }}
              >
                <OpenDrawer />
                  </IconButton>
                <Dialog
                  fullWidth
                  maxWidth="xl"
                  open={this.state.shouldOpenNewBarrierDialog}
                  aria-labelledby={`new-barrier-dialog-title-${plan.id}`}
                  onClose={(event, reason) => {
                    if (reason && reason == "backdropClick") { return }
                  }}
                >
                  <DialogTitle id={`new-barrier-dialog-title-${plan.id}`}>{bed.name} / {department.name}</DialogTitle>
                  <DialogContent>
                    <AddNewBarrier
                      onCreateBarrierFromTemplate={onCreateBarrierFromTemplate}
                      planId={plan.id}
                      visit={visit}
                      rerender={this.rerender}
                      alwaysExpand
                      onCancel={handleNewBarrierDialogClose}
                      onSave={handleNewBarrierDialogClose}
                    />
                  </DialogContent>
                </Dialog>
            </span>
            )
          }
          <BarrierIcon className="barrier-icon" />
          {renderHeadLineText()}
          {resolvedBarriersCount > 0 && (
            <FlatButton
              label={
                showAllBarriers && planSelected ? 'Show Current' : 'Show All'
              }
              labelStyle={{ textTransform: 'none' }}
              onClick={e => onShowAllBarriers(e, plan.id)}
            />
          )}
        </div>
      </div>
    )

    const handleNewBarrierDialogClose = () => {
      this.setState({shouldOpenNewBarrierDialog: false})
    }


    const renderBarriers = () => {
      return (
        <div className={renderForEscalation ? 'escalation-barrier-wrapper' : ''}>
          {sortedBarriers.map((barrier, index) => {
            return (
              <React.Fragment key={barrier.id}>
                <div className={classNames('paper', renderForEscalation ? 'escalation-barrier-card' : '')}>
                  <Barrier
                    item={barrier}
                    plan={plan}
                    visit={visit}
                    expanded={
                      showBarriersExpanded || selectedBarrierId === barrier.id
                    }
                    onUpdateBarrierStatus={onUpdateBarrierStatus}
                    onUpdateBarrierOwner={onUpdateBarrierOwner}
                    onSearchKnownPersonnel={onSearchKnownPersonnel}
                    onEscalateBarrier={onEscalateBarrier}
                    onDeescalateBarrier={onDeescalateBarrier}
                    onCreateBarrierNote={onCreateBarrierNote}
                    onUpdateBarrierNote={onUpdateBarrierNote}
                    onRemoveBarrierNote={onRemoveBarrierNote}
                    onUpdatePinned={onUpdatePinned}
                    onViewBarrierInfoHistory={event => handleOpenViewBarrierNoteHistoryDialog(event, barrier)}
                    onViewSendBarrierDialog={event => handleOpenSendBarrierNoteDialog(event, barrier)}
                    onViewManageWatchersDialog={event => handleOpenManageWatchersDialog(event, barrier)}
                    onBarrierClick={onBarrierClick}
                    rerender={this.rerender}
                    barrierEscalationRoles={barrierEscalationRoles}
                    onUpdateSourceType={onUpdateSourceType}
                    onUpdateSourceName={onUpdateSourceName}
                    deliveryMethods={deliveryMethods}
                  />
                </div>
              </React.Fragment>
            )
          })}
        </div>
      )
    }

    const renderManageWatchersDialog = () => {
      return this.state.manageWatchersModalOpen ? (
        <ManageWatchersDialog
          open={this.state.manageWatchersModalOpen}
          onClose={handleCloseManageWatchersDialog}
          onSearch={onSearchKnownPersonnel}
          barrierId={this.state?.barrier?.id}
        />
      ) : null
    }

    const renderSendBarrierNoteModal = () => {
      const sendBarrierInfoDialog = (
          <SendBarrierInfoDialog
            open={this.state.sendBarrierNoteModalOpen}
            onRequestClose={handleCloseSendBarrierNoteDialog}
            barrier={this.state.barrier}
            onLoadBarrierMessageHistory={onLoadBarrierMessageHistory}
            barrierCommunicationHistory={this.state.barrierCommunicationHistory}
            onSearchKnownPersonnel={onSearchKnownPersonnel}
            onSendBarrierInfo={(event, recipientId, message) => onSendBarrierInfo(event, this.state.barrier.id, recipientId, message, handleSendBarrierDialogSuccess)}
          />
        )

      return this.state.sendBarrierNoteModalOpen ? sendBarrierInfoDialog : null
    }

    const renderSendBarrierSuccessSnackbar = () => {
      return (
        <Snackbar
          open={this.state.sendBarrierSuccessSnackbarOpen}
          message="Barrier Information Sent"
          autoHideDuration={3000}
          onRequestClose={handleSendBarrierSnackbarClose}
          bodyStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}
        />
      )
    }

    const renderBarrierCommunicationHistory = () => {
      const barrierCommHistoryDialog = (
        <BarrierCommunicationHistory
          open={this.state.barrierCommunicationHistoryOpen}
          barrierCommunicationHistory={this.state.barrierCommunicationHistory}
          barrier={this.state.barrier}
          onRequestClose={handleCloseBarrierCommHistoryDialog}
        />
      )

      return this.state.barrierCommunicationHistoryOpen ? barrierCommHistoryDialog : null
    }

    const renderNewBarrierInput = () => {
      return !this.state.useNewBarrierInput ? null : (
        <div className={classNames(renderForEscalation ? 'escalation-add-new-barrier' : '')}>
          <AddNewBarrier
            onCreateBarrierFromTemplate={onCreateBarrierFromTemplate}
            planId={plan.id}
            visit={visit}
            rerender={this.rerender}
          />
          </div>
        )
    }

    return (
      <div className="barrier-section">
        <span>
          {renderHeadLineDetail()}
          {!renderForEscalation && renderNewBarrierInput()}
          {renderBarriers()}
          {renderManageWatchersDialog()}
          {renderSendBarrierNoteModal()}
          {renderSendBarrierSuccessSnackbar()}
          {renderBarrierCommunicationHistory()}
        </span>
      </div>
    )
  }
}

BarrierSection.propTypes = {
  rvlist: PropTypes.shape({
    forceUpdateGrid: PropTypes.func,
  }),
  workspace: PropTypes.shape({
    department: PropTypes.object.isRequired,
    bed: PropTypes.object.isRequired,
    visit: PropTypes.object.isRequired,
    plan: PropTypes.object.isRequired,
  }).isRequired,
  selectedPlanId: PropTypes.string,
  selectedBarrierId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showAllBarriers: PropTypes.bool,
  onShowAllBarriers: PropTypes.func.isRequired,
  userBarriers: PropTypes.array,
  onQuickAddBarrier: PropTypes.func.isRequired,
  onAddBarrier: PropTypes.func.isRequired,
  onUpdateBarrierStatus: PropTypes.func.isRequired,
  onUpdateBarrierOwner: PropTypes.func.isRequired,
  onSearchKnownPersonnel: PropTypes.func.isRequired,
  onEscalateBarrier: PropTypes.func.isRequired,
  onDeescalateBarrier: PropTypes.func.isRequired,
  onCreateBarrierNote: PropTypes.func,
  onUpdateBarrierNote: PropTypes.func,
  onRemoveBarrierNote: PropTypes.func,
  onUpdatePinned: PropTypes.func.isRequired,
  onBarrierClick: PropTypes.func.isRequired,
  showBarriersExpanded: PropTypes.bool,
  barrierEscalationRoles: PropTypes.array,
  deliveryMethods: PropTypes.array,
  onSendBarrierInfo: PropTypes.func.isRequired,
  onLoadBarrierMessageHistory: PropTypes.func.isRequired,
  onUpdateSourceType: PropTypes.func.isRequired,
  onUpdateSourceName: PropTypes.func.isRequired,
  renderForEscalation: PropTypes.bool,
  onCreateBarrierFromTemplate: PropTypes.func.isRequired,
}

const BarrierEscalationRoleData = graphql`
  query BarrierSection_barrierEscalationRoleData_Query {
    codeTables(codeTableType: BarrierEscalationRole) {
      id
      code
      value
      active
    }
  }
`

const UserSettingData = graphql`
  query BarrierSection_userSettingData_Query {
    viewer {
      settings(application: CA) {
        barriers {
          id
          name
          description
          active
          category {
            code
            value
          }
          templateId
          auditMeta {
            updated
          }
        }
        actions {
          id
          text
          auditMeta {
            updated
          }
        }
        notes {
          id
          text
        }
      }
    }
  }
`
