import React from 'react'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const defaultStyle = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '15%'
}

export const LoadingIndicator = ({ style = defaultStyle }) => (
  <div style={style}>
    <CircularProgress />
  </div>
)

LoadingIndicator.propTypes = {
  style: PropTypes.object
}
