import React, { Component } from 'react'
import PropTypes from 'prop-types'

const divStyle = {
  transition: 'opacity 0.5s ease',
  position: 'fixed',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,

  opacity: 1,
  backgroundColor: 'rgba(100,100,100,0.5)',

  zIndex: 1200,
}

export class Modal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
  }

  static defaultProps = {
    visible: false,
    onClick: () => {},
  }

  render() {
    const { visible, onClick, children } = this.props

    return (
      <div>
        {visible && (
          <div
            ref={el => (this.rootEl = el)}
            style={divStyle}
            onClick={event => this.rootEl === event.target && onClick(event)}
          />
        )}
        {visible && <div>{children}</div>}
      </div>
    )
  }
}
