/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VisibilityBoardAddressRow_rowData$ref = any;
export type VisibilityBoardAddressInput = {|
  address: string,
  description: string,
  visibilityBoardId: any,
|};
export type visibilityBoardAddress_modifyVisibilityBoardAddressEntry_MutationVariables = {|
  visibilityBoardAddressId: any,
  visibilityBoardAddressInput: VisibilityBoardAddressInput,
|};
export type visibilityBoardAddress_modifyVisibilityBoardAddressEntry_MutationResponse = {|
  +visibilityBoardAddressMutation: ?{|
    +modifyVisibilityBoardAddressEntry: ?{|
      +$fragmentRefs: VisibilityBoardAddressRow_rowData$ref
    |}
  |}
|};
export type visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation = {|
  variables: visibilityBoardAddress_modifyVisibilityBoardAddressEntry_MutationVariables,
  response: visibilityBoardAddress_modifyVisibilityBoardAddressEntry_MutationResponse,
|};
*/


/*
mutation visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation(
  $visibilityBoardAddressId: UUID!
  $visibilityBoardAddressInput: VisibilityBoardAddressInput!
) {
  visibilityBoardAddressMutation {
    modifyVisibilityBoardAddressEntry(visibilityBoardAddressId: $visibilityBoardAddressId, visibilityBoardAddressInput: $visibilityBoardAddressInput) {
      ...VisibilityBoardAddressRow_rowData
      id
    }
  }
}

fragment VisibilityBoardAddressRow_rowData on VisibilityBoardAddress {
  id
  address
  description
  visibilityBoard {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "visibilityBoardAddressId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "visibilityBoardAddressInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "visibilityBoardAddressId",
    "variableName": "visibilityBoardAddressId"
  },
  {
    "kind": "Variable",
    "name": "visibilityBoardAddressInput",
    "variableName": "visibilityBoardAddressInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityBoardAddressMutation",
        "kind": "LinkedField",
        "name": "visibilityBoardAddressMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "VisibilityBoardAddress",
            "kind": "LinkedField",
            "name": "modifyVisibilityBoardAddressEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VisibilityBoardAddressRow_rowData"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityBoardAddressMutation",
        "kind": "LinkedField",
        "name": "visibilityBoardAddressMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "VisibilityBoardAddress",
            "kind": "LinkedField",
            "name": "modifyVisibilityBoardAddressEntry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "VisibilityBoard",
                "kind": "LinkedField",
                "name": "visibilityBoard",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f037f8d81a1e77ef244883a3cb1b399f",
    "id": null,
    "metadata": {},
    "name": "visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation visibilityBoardAddress_modifyVisibilityBoardAddressEntry_Mutation(\n  $visibilityBoardAddressId: UUID!\n  $visibilityBoardAddressInput: VisibilityBoardAddressInput!\n) {\n  visibilityBoardAddressMutation {\n    modifyVisibilityBoardAddressEntry(visibilityBoardAddressId: $visibilityBoardAddressId, visibilityBoardAddressInput: $visibilityBoardAddressInput) {\n      ...VisibilityBoardAddressRow_rowData\n      id\n    }\n  }\n}\n\nfragment VisibilityBoardAddressRow_rowData on VisibilityBoardAddress {\n  id\n  address\n  description\n  visibilityBoard {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b2a30cc30636538bac650b86bc9d849a';

module.exports = node;
