import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import { SourceTypeGQL } from '../../../../graphql/queries'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

@graphql(SourceTypeGQL.queries.barrierSourceTypes, {
  name: 'barrierSourceTypes',
  options: ({barrierCategoryCode}) => ({
    variables: { barrierCategoryCode },
    fetchPolicy: 'no-cache',
  }),
})

class BarrierSourceTypePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sourceTypeValue: ''
    }
  }

  // getter of barrier categories filter
  get barrierSourceTypesData() {
    const {sourceTypeValue} = this.state
    const {barrierSourceTypes, onSelect} = this.props
    const data =  (barrierSourceTypes && !barrierSourceTypes.loading)
      ? barrierSourceTypes.barrierSourceTypes.filter(item => item.active)
      : []
    if (sourceTypeValue === '') {
      onSelect(data.length ? {} : null)   // send an empty object to make the barrierSourceTypeDetails field visible (null = hidden)
    }
    return data
  }

  handleValueChange = (event, sourceType) => {
    const {onSelect, barrierSourceTypes} = this.props
    if (sourceType) {
      this.setState({sourceTypeValue: sourceType?.code})
      const sourceTypeData = this.barrierSourceTypesData.find(item => item.code === sourceType.code)
      onSelect(sourceTypeData)
    } else {
      this.setState({sourceTypeValue: ''})
      onSelect({})
    }
  }

  render() {
    const {className, onSelect} = this.props
    const {sourceTypeValue} = this.state

    return (this.barrierSourceTypesData.length > 0) ? (
      <Autocomplete
        size="small"
        required
        autoComplete
        // autoSelect   // disabling a Tab selection
        autoHighlight
        clearOnEscape
        openOnFocus
        className={className}
        options={this.barrierSourceTypesData}
        getOptionLabel={(option) => option.value}
        onChange={this.handleValueChange}
        renderInput={(params) =>
          <TextField
            {...params}
            label="Barrier Source Type (Optional)"
            variant="outlined"
          />}
      />
    ) : (<div className={className}/>)
  }
}

BarrierSourceTypePicker.propTypes = {
  className: PropTypes.string,
  barrierCategoryCode: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  barrierSourceTypes: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    barrierSourceTypes: PropTypes.array,
  }),
}

export default BarrierSourceTypePicker
