import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash.sortby'
import { SelectField, MenuItem } from 'material-ui'

export const DropDownField = ({
  value,
  codeTable,
  allowBlank = false,
  onChange,
  componentProps,
}) => {
  const codeTableEntries = sortBy(codeTable.filter(entry => entry.active), [
    'ordinal',
  ])

  const valueOrDefault = value || codeTable.find(entry => entry.defaultValue)

  return (
    <SelectField
      {...componentProps}
      fullWidth
      onChange={onChange}
      value={valueOrDefault}>
      {allowBlank && <MenuItem value={null} primaryText="" />}
      {codeTableEntries.map(entry => (
        <MenuItem
          key={entry.code}
          value={entry.code}
          primaryText={entry.value}
        />
      ))}
    </SelectField>
  )
}

DropDownField.propTypes = {
  allowBlank: PropTypes.bool,
  value: PropTypes.string,
  componentProps: PropTypes.object,
  codeTable: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}
