/*
 * This is the entry point for the Care Advance UI, which is loaded by WebPack
 */
import 'react-app-polyfill/ie11'    // This must be the first line
import 'react-app-polyfill/stable'
import React from 'react'
import { render } from 'react-dom'

import { Provider } from 'react-redux'
import "core-js/stable"
import "regenerator-runtime/runtime"

import './styles/main.scss'

import { ApolloProvider } from 'react-apollo'
import { clApolloClient } from './graphql/cl-apollo-client'

import configureStore from './store/configureStore'
export const reduxStore = configureStore()

import { retrieveRefreshToken, setRefreshToken } from './auth/auth-service'
import { refreshAuthToken } from './ducks/user'

import { routeConfig } from './route'
import App from './App'

import { LogManager, Logger, LogLevel } from './log'
const logger: Logger = LogManager.getLogger('c3.index')

/* Application Initialization Process*/
const initFunctions = [
  // see if refresh token passed on url
  checkForTokenParam,

  // Load cached authentication
  initializeCachedAuth,

  //Set up Logging
  initializeLogging,

  // Load special stuff for console manipulation
  initializeConsoleVars,
]

const appInitialized = Promise.all(
  initFunctions.map(fn => Promise.resolve(fn()))
)

appInitialized.then(() => {
  //Attempt to prevent zooming on double tap (see Robert if you need to change this)
  let lastTouchEnd = 0
  document.documentElement.addEventListener(
    'touchend',
    function(event) {
      let now = new Date().getTime()
      if (now - lastTouchEnd <= 500) {
        event.preventDefault()
      }
      lastTouchEnd = now
    },
    false
  )

  //Attempt to prevent pinch-to-zoom (see Robert if you need to change this)
  document.documentElement.addEventListener(
    'touchstart',
    function(event) {
      if (event.touches.length > 1) {
        event.preventDefault()
      }
    },
    false
  )

  logger.info('App initialized successfully. Rendering...')
  render(
    <Provider store={reduxStore}>
      <ApolloProvider client={clApolloClient}>
        <App routes={routeConfig} />
      </ApolloProvider>
    </Provider>,
    document.querySelector('.care-advance-app')
  )
})

/* Startup Helper Functions */

/**
 * When invoked from HOS launcher, refresh token is a url param
 */

// used in onUserActivity to prevent setting Session cookies too much
let debounceId = null

function checkForTokenParam() {
  const dl = document.location
  // See CA-1085 for something to be careful about here
  if (dl.search.indexOf('?token=') >= 0) {
    const refreshToken = dl.search.replace('?token=', '')
    if (refreshToken) {
      // clear out token from url, use replaceState so can't back button to see token
      window.history.replaceState(null, null, dl.origin + dl.pathname)
      // Invoked from HOS Launcher
      setRefreshToken(refreshToken)
      // CA-2326 - removed code to log activity in HOS launcher cookie
    }
  }
}

/**
 * Load cached auth (if it exists)
 */
function initializeCachedAuth() {
  return retrieveRefreshToken().then(
    refreshToken => refreshToken && refreshAuthToken()(reduxStore.dispatch)
  )
}

/**
 * Setup the logging level and logging appender for this app
 */
function initializeLogging() {
  if (process.env.NODE_ENV === 'development') {
    LogManager.setLogLevel(/*/^c3\..*!/, */ LogLevel.DEBUG)
  } else {
    // todo Production grade logging?
    // LogManager.addAppender(new RemoteAppender());
  }
}

/**
 * Expose development time properties for debugging
 */
function initializeConsoleVars() {
  if (process.env.NODE_ENV === 'development') {
    const loggerName = '__DEV MODE index.js__'
    LogManager.setLogLevel(loggerName, LogLevel.DEBUG)
    const logger = LogManager.getLogger(loggerName)

    logger.info(
      "This file was built with `process.env.NODE_ENV === 'development'`, and the following dev tools are exposed:"
    )

    //NOTE Currently commented out due to the fact it breaks the hot reloading functionality
    // Remove the comments to access modules from the browser console. DO NOT CHECK IN UNCOMMENTED!
    /*
    window.modules = (function () {
      const modules = {
        react: React,
        gql: require('graphql-tag'),
      };
      modules.lookup = require.context('./', true, /^(?!\.\/components\/stories).*(?!.\.int|\.spec).{5}\.js$/);
      for (let m of modules.lookup.keys()) {
        const pathParts = m.substring(2, m.length - 3).split('/');
        let pathRoot = modules;
        while (pathParts.length > 1) {
          const pathPart = pathParts.shift();
          pathRoot = pathRoot[pathPart] || (pathRoot[pathPart] = {});
        }
        pathRoot[pathParts.shift()] = modules.lookup(m);
      }
      return modules;
    }());
    logger.debug('All code modules are available at `window.modules`');
    logger.error('This breaks all hot reloading while enabled');
*/
  }
}
