/*eslint-disable react/no-set-state */
/*eslint-disable react/no-did-mount-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { ComplianceTable, ComplianceFilter } from '../../components/compliance'
import {
  showDrawer,
  updateComplianceFilters,
  setDetailTabIndex,
  updateNavigationWorkspaces,
  updateNavigationWorkspacesQuery,
} from '../../ducks/views'
import { showErrorsPg, clearErrorPg } from '../../ducks/errorspg'
import '../views.scss'
import { QueryRenderer, graphql, fetchQuery } from 'react-relay'
import { environment } from '../../graphql/relay/env'
import { default as ComplianceListPaginationContainer } from './ComplianceListPaginationContainer'
import {Paper} from "material-ui"
import {TransactionErrorDialog} from "../../components/error"
import { LoadingIndicator } from '../../components/loader'

const SectionLabel = 'Section'
const AreaLabel = 'My Totals'
const AreaSection = AreaLabel + SectionLabel

function mapStateToProps(state) {
  return {
    complianceFilters: state.viewsAppState.complianceFilters,
    errors: state.errorStatePg.errorspg,
  }
}

function mapDispatchToProps(dispatch) {
  const actions = {
    actions: bindActionCreators(
      {
        showDrawer,
        showErrorsPg,
        clearErrorPg,
        updateComplianceFilters,
        updateNavigationWorkspaces,
        updateNavigationWorkspacesQuery,
        setDetailTabIndex,
      },
      dispatch
    ),
  }

  return actions
}

export class ComplianceList extends React.Component {
  static propTypes = {
    errors: PropTypes.array,
    actions: PropTypes.shape({
      showDrawer: PropTypes.func,
      clearErrorPg : PropTypes.func,
      updateComplianceFilters: PropTypes.func,
      setDetailTabIndex: PropTypes.func,
      updateNavigationWorkspaces: PropTypes.func,
      updateNavigationWorkspacesQuery: PropTypes.func
    }),
    complianceFilters: PropTypes.array,
    updateProgressionIndicator: PropTypes.func,
    updateAnticipatedDischargeTime: PropTypes.func,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        facility: PropTypes.object,
        department: PropTypes.object,
        bed: PropTypes.object,
        visit: PropTypes.object,
        plan: PropTypes.object,
      })
    ),
  }

  constructor(props) {
    super(props)
    this.state = {
      caAppConfigsLoading: true,
      filters: undefined,
      filter: 'drg',
      department: 'temp',
      sort: 'asc',
      orderBy: 'Location',
    }
  }

  componentDidMount() {
    if (!this.state.filters ) {
      const filterData = fetchQuery(environment, filterQuery, {}).then(value => {
        !this.isCancelled &&
        this.setState({
          filters: value.viewer.workspace.variance,
        })
      })
    }
  }

  componentDidUpdate() {
    this.props.actions.updateNavigationWorkspaces(this.filteredItems)
    const {filter, sort, orderBy, department, facility} = this.state

    this.props.actions.updateNavigationWorkspacesQuery({
      type: 'VARIANCE',
      query:
        department && filter ?
          `filter:${filter};
          ${this.getLocationParams(department, facility)}
          sort:${sort};
          orderBy:${orderBy === 'Location' ? null : 'current_los'}`
          : null,
    })
  }

  componentWillUnmount() {
    this.isCancelled = true
  }

  setComplianceSectionHeight = () => {
    setTimeout(() => {
      const complianceHeight = this.complianceHeader.clientHeight + 62
      !this.isCancelled &&
        this.setState({
          complianceSectionHeight: `calc(100% - ${complianceHeight}px)`,
        })
    }, 0)
  }

  handleDataItemClick = data => {
    this.handleFilterClick({
      section: AreaSection,
      id: 'My Totals',
      update: true,
      value: data.value,
      rowNumber: data.rowNumber,
      columnId: data.columnId,
    })
  }


  handleQueryUpdate = (department, filter, facility) => {
    this.refreshCompliance()
    this.setState({
      department,
      filter,
      facility
    })
  }

  handleSortOrderToggle = toggle => {
    this.setState({
      sort: toggle ? 'desc' : 'asc',
    })
  }

  handleFilterSectionClick = orderBy => {
    this.setState({
      orderBy: orderBy,
    })
  }

  // CA-2859 - locationtype supports rows with totals for Facility or System
  getLocationParams = (department, facility) => {
    const locationtype = department === 'System' ? 'system' :
      department === facility ? 'facility' : 'department'

    let result = `locationtype:${locationtype};`
    // don't send location when locationtype is 'system'
    if(locationtype !== 'system') {
      result += ` location:${department};`
    }

    return result
  }

  getQueryVariablesFromState = () => {
    const {department, facility} = this.state
    return {
      first: 100000,
      after: null,
      type: 'VARIANCE',
      query: this.state.department && this.state.filter ?
        `filter:${this.state.filter};
          ${this.getLocationParams(department, facility)}
          sort:${this.state.sort};
          orderBy:${this.state.orderBy === 'Location' ? null : 'current_los'}`
        : null
    }
  }


  refreshCompliance = () => {
    // Update ComplianceTable at the top
    const filterData = fetchQuery(environment, filterQuery, {}).then(
      values => {
        this.setState({filters: values.viewer.workspace.variance},
          // now update workspaces at bottom based on filters
          () => fetchQuery(environment, query, this.getQueryVariablesFromState())
        )
      }
    )
  }


  render() {
    if (!this.state.filters) {
      return <LoadingIndicator />
    }

    if(this.state.filters.length === 0)
    {
      return (
        <div style={{marginTop: '5px'}}>
          <Paper>
            <div style={{padding: '.5em'}}>
              No results. Change Bed Assignments
            </div>
          </Paper>
        </div>
      )
    }

    return (
      <React.Fragment>
        <div className="compliance-view-filter-container">
          <div
            className="compliance-header"
            ref={complianceHeader =>
              (this.complianceHeader = complianceHeader)
            }>
            >
            <ComplianceFilter
              complianceFilters={this.props.complianceFilters}
              onDataItemClick={this.handleFilterClick}
              onSortOrderToggle={this.handleSortOrderToggle}
              onFilterSectionClick={this.handleFilterSectionClick}
              orderBy={this.state.orderBy}
              sort={this.state.sort}
            />
            <ComplianceTable
              filters={this.state.filters}
              complianceFilters={this.props.complianceFilters}
              onDataItemClick={this.handleDataItemClick}
              department={this.state.department}
              filter={this.state.filter}
              handleQueryUpdate={this.handleQueryUpdate}
            />
          </div>
        </div>
        <QueryRenderer
          environment={environment}
          query={query}
          variables={this.getQueryVariablesFromState()}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>
            }
            if (!props) {
              return (
                <LoadingIndicator />
              )
            }
            return (
              <ComplianceListPaginationContainer
                refreshCompliance={this.refreshCompliance}
                search={props}
                filters={this.state.filters}
                _cache={this._cache}
                handleFilterChange={this.handleFilterChange}
                type={this.state.type}
                subtype={this.state.subtype}
                query={this.state.query}
                department={this.state.department}
                filter={this.state.filter}
                handleQueryUpdate={this.handleQueryUpdate}
                {...this.props}
              />
            )
          }}
        />
        <TransactionErrorDialog
          errors={this.props.errors}
          onRequestClose={this.props.actions.clearErrorPg}
        />
      </React.Fragment>
    )
  }
}


export const ComplianceView = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ComplianceList)

const query = graphql`
  query ComplianceListQuery(
    $first: Int
    $after: String
    $type: SearchType
    $query: String
  ) {
    ...ComplianceListPaginationContainer_search
      @arguments(first: $first, after: $after, type: $type, query: $query)
  }
`

const filterQuery = graphql`
  query ComplianceListFilterQuery {
    viewer {
      workspace {
        variance {
          facility {
            exchangeName
          }
          department {
            exchangeName
            name
          }
          totalIssues
          drgs
          progression
          plans
          dischargeArrangements
          plannedDischarges
        }
      }
    }
  }
`
