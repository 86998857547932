import React from 'react'
import PropTypes from 'prop-types'
import DayColumn from './columns/DayColumn'
import TrackColumn from './columns/TrackColumn'
import TextColumn from './columns/TextColumn'
import ActionButtonsColumn from './columns/ActionButtonsColumn'
import { getPlanDotColor } from './PlanColorDotUtil'
import ToDoInformationRow from './columns/ToDoInformationRow'
import IconSection from './iconSection/IconSection'

import './ToDoTable.scss'

const ToDoTable = ({ todos, onUpdateActionStatus, selectedItem, onTodoTextClick, onUpdateActionText, onRemoveAction, admissionType, onUpdatePinned, hasPlan, hasManualToDos, appliedTemplates }) => {
  let noToDosText
  if (!hasPlan && !hasManualToDos) {
    noToDosText = 'No plan applied. Apply a plan or manually add a To-Do.'
  } else if (!hasPlan && hasManualToDos) {
    noToDosText = 'No plan applied. Apply a plan or adjust filter to see manually added To-Do\'s.'
  } else {
    noToDosText = 'No To-Do\'s found. Adjust your search criteria to see more To-Do\'s.'
  }

  const showPlanColorDots = appliedTemplates.length > 1

  return (
    <div className="paper">
      <table className="actionSectionTable">
        <thead className="tableHeadRow">
          <tr>
            <th className="trackColumnHeader">Track</th>
            <th className="statusColumnHeader">Status</th>
            <th className={admissionType === 'Observation' ? 'dueDateObservationColumnHeader' : 'dueDateInPatientColumnHeader'}>Due Date</th>
            {showPlanColorDots ? <th className="planColorDotHeader" /> : null}
            <th className="todoColumnHeader">To-Do</th>
            <th/>
          </tr>
        </thead>
        <tbody>
        { todos.length > 0 ?
          todos.map(todo => {
            return (
              <React.Fragment
                key={todo.id}>
                <tr>
                  <TrackColumn track={todo.track} />
                  <ActionButtonsColumn
                    id={todo.id}
                    status={todo.status}
                    onUpdateActionStatus={(id, status) => onUpdateActionStatus(id, status)}
                  />
                  <DayColumn
                    dueDate={todo.dueDate}
                    admissionType={admissionType}
                  />
                  {showPlanColorDots ? <td><span className="planColorDot" style={{backgroundColor: getPlanDotColor(appliedTemplates, todo.planName)}} /></td> : null}
                  <TextColumn
                    todo={todo}
                    expanded={selectedItem.id === todo.id}
                    onUpdateActionText={onUpdateActionText}
                    onRemoveAction={onRemoveAction}
                    onTodoTextClick={onTodoTextClick ? e => onTodoTextClick(e, todo) : () => {}}
                  />
                  <IconSection
                    todo={todo}
                    onClickPin={onUpdatePinned}
                    onClickRemove={onRemoveAction}
                    showDeleteIcon={selectedItem.id === todo.id}
                  />
                </tr>
                <ToDoInformationRow
                  expanded={selectedItem.id === todo.id}
                  toDoAuditMeta={todo.auditMeta}
                  colspanLength={showPlanColorDots ? 6 : 5}
                />
              </React.Fragment>

            )
          })
          : null
        }
        </tbody>
      </table>
      {todos.length === 0 ? <div className="noTodoFoundRow">{noToDosText}</div> : null}
    </div>
  )
}

ToDoTable.propTypes = {
  todos: PropTypes.array.isRequired,
  onUpdateActionStatus: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  onTodoTextClick: PropTypes.func,
  onUpdateActionText: PropTypes.func,
  onRemoveAction: PropTypes.func,
  admissionType: PropTypes.string,
  onUpdatePinned: PropTypes.func,
  appliedTemplates: PropTypes.array,
  hasPlan: PropTypes.bool,
  hasManualToDos: PropTypes.bool
}

export default ToDoTable
