import gql, {injectForGqlFile} from '../util/gql'

export const BarrierGQL = {
  fragments: {
    FullBarrier: gql`
      fragment FullBarrier on Barrier {
        id
        name
        description
        status
        pinned
        ...CreatedUpdated
        category {
          code
          value
        }
        escalation {
          ...Created
        }
        owner
        ownerRole {
          code
          value
        }
        notes {
          ...FullBarrierNote
        }
        escalation {
          ...Created
        }
      }
    `,
  },
  queries: {},
  mutations: {
    createBarrier: gql`
      mutation createBarrier(
        $planId: UUID!
        $barrier: BarrierInput!
        $note: String
      ) {
        barrierMutation {
          createBarrier(planId: $planId, barrier: $barrier, note: $note) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
    createBarrierFromTemplate: gql`
      mutation createBarrierFromTemplate(
        $planId: UUID!
        $templateId: UUID!
        $note: String
      ) {
        barrierMutation {
          createBarrierFromTemplate(
            planId: $planId
            templateId: $templateId
            note: $note
          ) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
    escalateBarrier: gql`
      mutation escalateBarrier($barrierId: UUID!) {
        barrierMutation {
          escalateBarrier(barrierId: $barrierId) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
    deescalateBarrier: gql`
      mutation deescalateBarrier($barrierId: UUID!) {
        barrierMutation {
          deescalateBarrier(barrierId: $barrierId) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
    updateBarrierOwner: gql`
      mutation updateBarrierOwner($barrierId: UUID!, $owner: String, $ownerId: UUID) {
        barrierMutation {
          updateBarrierOwner(barrierId: $barrierId, owner: $owner, ownerId: $ownerId) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
    updateBarrierOwnerRole: gql`
      mutation updateBarrierOwnerRole($barrierId: UUID!, $roleCode: String) {
        barrierMutation {
          updateBarrierOwnerRole(barrierId: $barrierId, roleCode: $roleCode) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
    updateBarrierStatus: gql`
      mutation updateBarrierStatus($barrierId: UUID!, $status: BarrierStatus!, $bedRequestStatus: BedRequestStatus!) {
        barrierMutation {
          updateBarrierStatus(barrierId: $barrierId, status: $status, bedRequestStatus: $bedRequestStatus) {
            id
            barriers {
              ...FullBarrier
            }
          }
        }
      }
    `,
  },
}

injectForGqlFile(BarrierGQL)
