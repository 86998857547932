import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

const calculateDayNumber = (dueDate, admissionType) => {
  if (!dueDate) {
    return null
  }
  else if (admissionType === 'Observation' || admissionType === 'OutPatient') {
    return getObservationPatientDueDate(dueDate)
  } else {
    return getInPatientPatientDueDate(dueDate)
  }
}

const getObservationPatientDueDate = (dueDate) => {
  const today = moment().startOf('hour')
  const dueDateStartOfHour = dueDate.clone().startOf('hour')

  let dayDisplayObj
  if (dueDateStartOfHour.isBefore(today, 'hours')) {
    dayDisplayObj = {
      dayDisplay: dueDateStartOfHour.diff(today, 'hours') + 'h',
      dayClass: 'overdue'
    }
  } else if (dueDateStartOfHour.isSame(today, 'day')) {
    dayDisplayObj = {
      dayDisplay: 'TODAY ' + dueDateStartOfHour.format('HH:mm')
    }
  } else {
    const todayDay = moment().startOf('day')
    const dueDateStartOfDay = dueDate.clone().startOf('day')

    dayDisplayObj = {
      dayDisplay: '+' + dueDateStartOfDay.diff(todayDay, 'day') + 'd ' + dueDateStartOfHour.format('HH:mm')
    }
  }

  return dayDisplayObj
}

const getInPatientPatientDueDate = (dueDate) => {
  const today = moment().startOf('day')
  const dueDateStartOfDay = dueDate.clone().startOf('day')

  let dayDisplayObj
  if (dueDateStartOfDay.isBefore(today, 'day')) {

    dayDisplayObj = {
      dayDisplay: dueDateStartOfDay.diff(today, 'day', true) + 'd',
      dayClass: 'overdue'
    }
  } else if (dueDateStartOfDay.isSame(today, 'day')) {
    dayDisplayObj = {
      dayDisplay: 'TODAY'
    }
  } else {
    dayDisplayObj = {
      dayDisplay: '+' + dueDateStartOfDay.diff(today, 'day') + 'd'
    }
  }

  return dayDisplayObj
}

const DayColumn = ({ dueDate, admissionType }) => {
  const dayDisplayObj = calculateDayNumber(dueDate, admissionType)
  return (
    <td className={classNames('dueDateColumnCell', dayDisplayObj && dayDisplayObj.dayClass )}>{ dayDisplayObj && dayDisplayObj.dayDisplay }</td>
  )
}

DayColumn.propTypes = {
  dueDate: PropTypes.instanceOf(moment).isRequired,
  admissionType: PropTypes.string.isRequired
}

export default DayColumn
