import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Watcher = (props) => {
  const {
    firstName,
    lastName,
    title,
    isOwner,
    onRemoveWatcher
  } = props

  const renderUserDetailIcon = () => {
    if (isOwner) {
      return <OwnerIcon>Owner</OwnerIcon>
    } else {
      return (
        <StyledRemoveWatcherButton
          onClick={onRemoveWatcher}
          size={'small'}>
          <RemoveWatcherIcon/>
        </StyledRemoveWatcherButton>
      )
    }
  }

  return (
    <RowWrapper>
      <NameAndTitle>
        <UserName>{lastName}, {firstName}</UserName>
        <UserTitle>{title}</UserTitle>
      </NameAndTitle>
      <IconWidthDiv>
        <IconWrapper>
          {renderUserDetailIcon()}
        </IconWrapper>
      </IconWidthDiv>
    </RowWrapper>
  )
}

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
`

const NameAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`

const UserName = styled.span`
  font-size: 14px;
  color: #4A4A4A;
`

const UserTitle = styled.span`
  font-size: 12px;
  color: #4A4A4A;
`

const IconWidthDiv = styled.div`
  width: 48px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const OwnerIcon = styled.div`
  background-color: #2F91F4;
  color: #FFFFFF;
  border-radius: 3px;
  padding: 4px 6px;
  font-size: 12px;
  cursor: default;
`

const StyledRemoveWatcherButton = styled(IconButton)`
  padding: 6px;
`

const RemoveWatcherIcon = styled(DeleteIcon)`
  font-size: 24px;
  color: #999999;
`

Watcher.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  isOwner: PropTypes.bool,
  onRemoveWatcher: PropTypes.func.isRequired
}

export default Watcher
