/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ActionStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type action_createMilestoneAction_MutationVariables = {|
  milestoneId: any,
  text: string,
|};
export type action_createMilestoneAction_MutationResponse = {|
  +actionMutation: ?{|
    +createMilestoneAction: ?{|
      +id: string,
      +milestones: $ReadOnlyArray<?{|
        +id: string,
        +actions: $ReadOnlyArray<?{|
          +id: string,
          +status: ActionStatus,
          +pinned: boolean,
          +auditMeta: ?{|
            +updated: any,
            +updatedBy: {|
              +userName: string,
              +firstName: string,
              +lastName: string,
            |},
          |},
        |}>,
      |}>,
    |}
  |}
|};
export type action_createMilestoneAction_Mutation = {|
  variables: action_createMilestoneAction_MutationVariables,
  response: action_createMilestoneAction_MutationResponse,
|};
*/


/*
mutation action_createMilestoneAction_Mutation(
  $milestoneId: UUID!
  $text: String!
) {
  actionMutation {
    createMilestoneAction(milestoneId: $milestoneId, text: $text) {
      id
      milestones {
        id
        actions {
          id
          status
          pinned
          auditMeta {
            updated
            updatedBy {
              userName
              firstName
              lastName
              id
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "milestoneId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "milestoneId",
    "variableName": "milestoneId"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "action_createMilestoneAction_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "createMilestoneAction",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuditUser",
                            "kind": "LinkedField",
                            "name": "updatedBy",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "action_createMilestoneAction_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ActionMutation",
        "kind": "LinkedField",
        "name": "actionMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "createMilestoneAction",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Milestone",
                "kind": "LinkedField",
                "name": "milestones",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MilestoneAction",
                    "kind": "LinkedField",
                    "name": "actions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuditMeta",
                        "kind": "LinkedField",
                        "name": "auditMeta",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuditUser",
                            "kind": "LinkedField",
                            "name": "updatedBy",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec123b59d4615d1882a8826042bdabe5",
    "id": null,
    "metadata": {},
    "name": "action_createMilestoneAction_Mutation",
    "operationKind": "mutation",
    "text": "mutation action_createMilestoneAction_Mutation(\n  $milestoneId: UUID!\n  $text: String!\n) {\n  actionMutation {\n    createMilestoneAction(milestoneId: $milestoneId, text: $text) {\n      id\n      milestones {\n        id\n        actions {\n          id\n          status\n          pinned\n          auditMeta {\n            updated\n            updatedBy {\n              userName\n              firstName\n              lastName\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd43ee115ad69f2c6aba122eb4954fd2c';

module.exports = node;
