/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BarrierSection_userSettingData_QueryVariables = {||};
export type BarrierSection_userSettingData_QueryResponse = {|
  +viewer: {|
    +settings: ?{|
      +barriers: $ReadOnlyArray<{|
        +id: string,
        +name: ?string,
        +description: ?string,
        +active: ?boolean,
        +category: ?{|
          +code: ?string,
          +value: ?string,
        |},
        +templateId: ?any,
        +auditMeta: ?{|
          +updated: any
        |},
      |}>,
      +actions: $ReadOnlyArray<{|
        +id: string,
        +text: ?string,
        +auditMeta: ?{|
          +updated: any
        |},
      |}>,
      +notes: $ReadOnlyArray<{|
        +id: string,
        +text: ?string,
      |}>,
    |}
  |}
|};
export type BarrierSection_userSettingData_Query = {|
  variables: BarrierSection_userSettingData_QueryVariables,
  response: BarrierSection_userSettingData_QueryResponse,
|};
*/


/*
query BarrierSection_userSettingData_Query {
  viewer {
    settings(application: CA) {
      barriers {
        id
        name
        description
        active
        category {
          code
          value
          id
        }
        templateId
        auditMeta {
          updated
        }
      }
      actions {
        id
        text
        auditMeta {
          updated
        }
      }
      notes {
        id
        text
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "application",
    "value": "CA"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "templateId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "FavoriteAction",
  "kind": "LinkedField",
  "name": "actions",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v9/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "FavoriteNote",
  "kind": "LinkedField",
  "name": "notes",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BarrierSection_userSettingData_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "UserSetting",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FavoriteBarrier",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CodeTablePair",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": "settings(application:\"CA\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BarrierSection_userSettingData_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "UserSetting",
            "kind": "LinkedField",
            "name": "settings",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FavoriteBarrier",
                "kind": "LinkedField",
                "name": "barriers",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CodeTablePair",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": "settings(application:\"CA\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "761b0e348420065ddb5631e4dc7e48e5",
    "id": null,
    "metadata": {},
    "name": "BarrierSection_userSettingData_Query",
    "operationKind": "query",
    "text": "query BarrierSection_userSettingData_Query {\n  viewer {\n    settings(application: CA) {\n      barriers {\n        id\n        name\n        description\n        active\n        category {\n          code\n          value\n          id\n        }\n        templateId\n        auditMeta {\n          updated\n        }\n      }\n      actions {\n        id\n        text\n        auditMeta {\n          updated\n        }\n      }\n      notes {\n        id\n        text\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a46b355aba9d5aa4a4f8e505f8078f8';

module.exports = node;
