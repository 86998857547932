import React from 'react'
import PropTypes from 'prop-types'
import './common.scss'

import classNames from 'classnames'

export const BedUnitLink = (
  { bed, unit, planId, visitId, onClick, extraClasses, discharged },
  context
) => {
  const isReserved = bed.status === 'Reserved' && !discharged
  return (
    <span
      className={classNames(
        'ellipsis-text',
        extraClasses,
        onClick ? 'click-text' : ''
      )}
      onClick={() =>
        onClick ? onClick(bed.exchangeName, planId, visitId) : ''
      }>
      <span
        style={{ backgroundColor: isReserved && '#ffeb3b', padding: '3px' }}>
        {`${bed.name} / ${unit}`}
      </span>
    </span>
  )
}

BedUnitLink.propTypes = {
  bed: PropTypes.object.isRequired,
  unit: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  visitId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  extraClasses: PropTypes.string,
  discharged: PropTypes.bool
}
