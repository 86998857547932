/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateDrg_MutationVariables = {|
  visitId: any,
  drgCode: string,
|};
export type visit_updateDrg_MutationResponse = {|
  +visitMutation: ?{|
    +updateDrg: ?{|
      +id: string,
      +targetDischargeTime: ?any,
      +readmissionRisk: ?boolean,
      +readmissionRiskVerifiedBy: ?{|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +readmissionRiskUpdatedWhen: ?any,
      +tlos: ?number,
      +drgAttributes: ?{|
        +code: string,
        +title: string,
        +level: string,
        +targetLos: number,
        +modifyTargetLos: boolean,
      |},
      +drgUpdatedBy: ?{|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +drgUpdatedWhen: ?any,
      +meta: {|
        +dischargeToday: boolean,
        +tlosExceeded: boolean,
        +plannedDischargeExceeded: boolean,
        +decisionDue: boolean,
      |},
      +plan: ?{|
        +id: string,
        +milestones: $ReadOnlyArray<?{|
          +id: string,
          +dueDate: ?any,
        |}>,
      |},
    |}
  |}
|};
export type visit_updateDrg_Mutation = {|
  variables: visit_updateDrg_MutationVariables,
  response: visit_updateDrg_MutationResponse,
|};
*/


/*
mutation visit_updateDrg_Mutation(
  $visitId: Long!
  $drgCode: String!
) {
  visitMutation {
    updateDrg(visitId: $visitId, drgCode: $drgCode) {
      id
      targetDischargeTime
      readmissionRisk
      readmissionRiskVerifiedBy {
        userName
        firstName
        lastName
        id
      }
      readmissionRiskUpdatedWhen
      tlos
      drgAttributes {
        code
        title
        level
        targetLos
        modifyTargetLos
        id
      }
      drgUpdatedBy {
        userName
        firstName
        lastName
        id
      }
      drgUpdatedWhen
      meta {
        dischargeToday
        tlosExceeded
        plannedDischargeExceeded
        decisionDue
        id
      }
      plan {
        id
        milestones {
          id
          dueDate
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "drgCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "drgCode",
    "variableName": "drgCode"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetDischargeTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmissionRisk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readmissionRiskUpdatedWhen",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlos",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "level",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetLos",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modifyTargetLos",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drgUpdatedWhen",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeToday",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlosExceeded",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedDischargeExceeded",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionDue",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestones",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateDrg_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateDrg",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "readmissionRiskVerifiedBy",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DrgAttributes",
                "kind": "LinkedField",
                "name": "drgAttributes",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "drgUpdatedBy",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateDrg_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateDrg",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "readmissionRiskVerifiedBy",
                "plural": false,
                "selections": (v23/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "DrgAttributes",
                "kind": "LinkedField",
                "name": "drgAttributes",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AuditUser",
                "kind": "LinkedField",
                "name": "drgUpdatedBy",
                "plural": false,
                "selections": (v23/*: any*/),
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23845e72bd4b529ac7f452c55fede50b",
    "id": null,
    "metadata": {},
    "name": "visit_updateDrg_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateDrg_Mutation(\n  $visitId: Long!\n  $drgCode: String!\n) {\n  visitMutation {\n    updateDrg(visitId: $visitId, drgCode: $drgCode) {\n      id\n      targetDischargeTime\n      readmissionRisk\n      readmissionRiskVerifiedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      readmissionRiskUpdatedWhen\n      tlos\n      drgAttributes {\n        code\n        title\n        level\n        targetLos\n        modifyTargetLos\n        id\n      }\n      drgUpdatedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      drgUpdatedWhen\n      meta {\n        dischargeToday\n        tlosExceeded\n        plannedDischargeExceeded\n        decisionDue\n        id\n      }\n      plan {\n        id\n        milestones {\n          id\n          dueDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df0427e91348e94a37e596c4f1b60f90';

module.exports = node;
