/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BoardTemplateRow_rowData$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type BoardTemplateRowList_list$ref: FragmentReference;
declare export opaque type BoardTemplateRowList_list$fragmentType: BoardTemplateRowList_list$ref;
export type BoardTemplateRowList_list = $ReadOnlyArray<{|
  +$fragmentRefs: BoardTemplateRow_rowData$ref,
  +$refType: BoardTemplateRowList_list$ref,
|}>;
export type BoardTemplateRowList_list$data = BoardTemplateRowList_list;
export type BoardTemplateRowList_list$key = $ReadOnlyArray<{
  +$data?: BoardTemplateRowList_list$data,
  +$fragmentRefs: BoardTemplateRowList_list$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BoardTemplateRowList_list",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardTemplateRow_rowData"
    }
  ],
  "type": "BoardTemplate",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5531b4bf95e334e78969fb6ddbc05ff1';

module.exports = node;
