import React from 'react'
import PropTypes from 'prop-types'

import Paper from 'material-ui/Paper'
import Checkbox from 'material-ui/Checkbox'
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'
import MenuItem from 'material-ui/MenuItem'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back'

export const SeveritySearchInput = ({
  drg,
  cc,
  mcc,
  dc,
  dcs,
  searchTerm,
  onSearchTerm,
  onDCChanged,
  onCCChanged,
  onMCCChanged,
  onReset,
  onBack,
  onSelectDrg,
}) => {
  let searchText = null
  const doReset = () => {
    searchText.input.value = ''
    onReset()
  }

  return (
    <div className="severity-search-input">
      <div className="container-fluid">
        <div className="row" style={{ marginTop: 5 }}>
          <FlatButton
            label="Back"
            icon={<ArrowBack style={{ marginLeft: 0 }} />}
            secondary
            onClick={onBack}
            title="Go back to DRG selection"
          />

          {drg.family.map(item => {
            return (
              <RaisedButton
                key={item.code}
                style={{ marginLeft: 5, marginRight: 5 }}
                label={`${item.code} ${item.level} ${item.targetLos}`}
                title="Click to select this code"
                onClick={e => onSelectDrg(item)}
              />
            )
          })}
          <div style={{ display: 'inline-block' }}> {drg.title} </div>
        </div>

        <Paper className="row">
          <div className="col-md-1 col-sm-2">
            <div className="clearfix">
              <span onClick={onCCChanged}>
                <Checkbox label="CC" defaultChecked={cc} />
              </span>
              <span onClick={onMCCChanged}>
                <Checkbox label="MCC" defaultChecked={mcc} />
              </span>
            </div>
          </div>

          <div className="col-md-4 col-sm-10">
            <SelectField
              value={dc}
              style={{ display: 'block' }}
              onChange={onDCChanged}
              hintText="Diagnosis Category"
              fullWidth>
              {dcs.map(dc => (
                <MenuItem key={dc} value={dc} primaryText={dc} />
              ))}
            </SelectField>
          </div>

          <div className="col-md-3 col-sm-10">
            <TextField
              ref={ref => (searchText = ref)}
              type="search"
              hintText="Search Secondary Diagnosis"
              fullWidth
              onChange={onSearchTerm}
              defaultValue={searchTerm}
            />
          </div>

          <div className="col-md-1 col-sm-2">
            <FlatButton
              style={{ marginTop: '10px' }}
              label="Reset"
              title="Clear all search criteria"
              onClick={doReset}
            />
          </div>
        </Paper>
      </div>
    </div>
  )
}

SeveritySearchInput.propTypes = {
  drg: PropTypes.object,
  dcs: PropTypes.array.isRequired,
  searchTerm: PropTypes.string.isRequired,
  cc: PropTypes.bool.isRequired,
  mcc: PropTypes.bool.isRequired,
  dc: PropTypes.string,
  onSearchTerm: PropTypes.func.isRequired,
  onDCChanged: PropTypes.func.isRequired,
  onCCChanged: PropTypes.func.isRequired,
  onMCCChanged: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  onSelectDrg: PropTypes.func.isRequired,
}
