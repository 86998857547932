import React from 'react'
import PropTypes from 'prop-types'
import Subheader from 'material-ui/Subheader'

import './escalation.scss'

import { filterConstants } from '../../util/filters'
import BarrierTypeFilter from './BarrierFilters/BarrierTypeFilter'
import BarrierOwnerNameFilter from './BarrierFilters/BarrierOwnerNameFilter'
import CodeTypeDropdownFilter from './BarrierFilters/CodeTypeDropdownFilter'

const BarriersFilter = ({
  onChange,
  valueSelected,
  defaultSelected,
  onFilterBarrierCategoryChange,
  onFilterBarrierCategoryClose,
  barrierCategoryValueSelected,
  selectedBarrierOwnerRadio,
  onFilterBarrierOwnerRadioChange,
  onFilterBarrierOwnerChange,
  barrierOwnerValueSelected,
  onFilterBarrierMessageStatusChange,
  onFilterBarrierMessageStatusClose,
  barrierMessageStatusValueSelected,
}) => {
  return (
    <div className="filter-container">
        <div key="barriers1">
          <Subheader className="filter-subheader">Barrier Filters</Subheader>
          <BarrierTypeFilter
            onChange={onChange}
            valueSelected={valueSelected}
            defaultSelected={defaultSelected}
          />
          {filterConstants.barriers.withoutBarriers !==
            valueSelected.selected && (
            <div>
              <CodeTypeDropdownFilter
                onChange={onFilterBarrierCategoryChange}
                onClose={onFilterBarrierCategoryClose}
                valueSelected={barrierCategoryValueSelected}
                allSelectedText={'All Categories'}
                filterLabel={'Barrier Category'} />
              <BarrierOwnerNameFilter
                onBarrierOwnerNameChange={onFilterBarrierOwnerChange}
                selectedBarrierName={barrierOwnerValueSelected}
                selectedBarrierOwnerRadio={selectedBarrierOwnerRadio}
                onBarrierOwnerRadioChange={onFilterBarrierOwnerRadioChange}
              />
              { selectedBarrierOwnerRadio !== filterConstants.barrierOwnerRadio.noOwners.value ?
                <CodeTypeDropdownFilter
                  onChange={onFilterBarrierMessageStatusChange}
                  onClose={onFilterBarrierMessageStatusClose}
                  valueSelected={barrierMessageStatusValueSelected}
                  allSelectedText={'All Message Statuses'}
                  filterLabel={'Barrier Owner Message Status'} />
                : null
              }
            </div>
          )}
        </div>
    </div>
  )
}

BarriersFilter.propTypes = {
  onChange: PropTypes.func,
  onFilterBarrierOpenChange: PropTypes.func,
  onFilterBarrierEscalatedChange: PropTypes.func,
  valueSelected: PropTypes.object,
  defaultSelected: PropTypes.string,
  barrierCategoryValueSelected: PropTypes.array,
  onFilterBarrierCategoryChange: PropTypes.func,
  onFilterBarrierCategoryClose: PropTypes.func,
  barrierOwnerRoleValueSelected: PropTypes.array,
  onFilterBarrierRoleChange: PropTypes.func,
  selectedBarrierOwnerRadio: PropTypes.string,
  onFilterBarrierOwnerRadioChange: PropTypes.func,
  barrierOwnerValueSelected: PropTypes.array,
  onFilterBarrierOwnerChange: PropTypes.func,
  barrierMessageStatusValueSelected: PropTypes.array,
  onFilterBarrierMessageStatusChange: PropTypes.func,
  onFilterBarrierMessageStatusClose: PropTypes.func
}

export default BarriersFilter
