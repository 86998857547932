/*eslint-disable react/no-set-state */
import React from 'react'
import PropTypes from 'prop-types'
import sortBy from 'lodash.sortby'

export const InsuranceInfo = ({ insurancePlans }) => {
  const styleLabel = { fontWeight: 500 }
  const styleLine = { paddingLeft: '15px' }
  const sortedPlans = sortBy(insurancePlans, ['priority'])
  return (
    <div>
      {!insurancePlans.length ? (
        <div style={styleLine}>No Insurance Information</div>
      ) : (
        <div>
          {sortedPlans.map((item, i) => [
            <div key={`${i}-PlanName`} style={styleLine}>
              <span style={styleLabel}>{`Plan ${i + 1} Name: `}</span>
              {`${item.planName}`}
            </div>,
            <div key={`${i}-CMS`} style={styleLine}>
              <span style={styleLabel}>CMS: </span>
              {`${item.cms ? 'Yes' : 'No'}`}
            </div>,
            i < insurancePlans.length - 1 ? (
              <div key={`${i}-Separator`} style={{ height: '10px' }} />
            ) : null,
          ])}
        </div>
      )}
    </div>
  )
}

InsuranceInfo.propTypes = {
  insurancePlans: PropTypes.arrayOf(
    PropTypes.shape({
      planName: PropTypes.string,
      cms: PropTypes.bool,
    })
  ).isRequired,
}
