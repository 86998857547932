/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BarrierStatus = "Open" | "Resolved" | "%future added value";
export type OwnerMessageStatus = "ACKNOWLEDGED" | "NEEDS_REASSIGNMENT" | "UNACKNOWLEDGED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type barrier_FullBarrier$ref: FragmentReference;
declare export opaque type barrier_FullBarrier$fragmentType: barrier_FullBarrier$ref;
export type barrier_FullBarrier = {|
  +id: string,
  +name: string,
  +description: string,
  +status: BarrierStatus,
  +pinned: boolean,
  +auditMeta: ?{|
    +created: any,
    +createdBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
    +updated: any,
    +updatedBy: {|
      +userName: string,
      +firstName: string,
      +lastName: string,
    |},
  |},
  +category: {|
    +code: string,
    +value: string,
    +sourceTypes: ?$ReadOnlyArray<?{|
      +id: string,
      +code: string,
      +value: string,
      +active: boolean,
      +activeDirectorySourceName: boolean,
      +hasConfiguredList: boolean,
      +sourceDetails: ?$ReadOnlyArray<?{|
        +id: string,
        +code: string,
        +value: string,
        +active: boolean,
      |}>,
    |}>,
  |},
  +escalation: ?{|
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |}
  |},
  +ownerMessageStatus: ?OwnerMessageStatus,
  +ownerMessageStatusDate: ?any,
  +ownerKnownUser?: ?{|
    +id: string,
    +emailReceiver: boolean,
    +instantMessageReceiver: boolean,
  |},
  +ownerMessageDeliveryDate: ?any,
  +owner: ?string,
  +ownerRole: ?{|
    +code: ?string,
    +value: ?string,
  |},
  +sourceName: ?string,
  +sourceTypeCode: ?string,
  +notes: ?$ReadOnlyArray<?{|
    +id: string,
    +text: string,
    +auditMeta: ?{|
      +created: any,
      +createdBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
      +updated: any,
      +updatedBy: {|
        +userName: string,
        +firstName: string,
        +lastName: string,
      |},
    |},
    +pinned: boolean,
  |}>,
  +$refType: barrier_FullBarrier$ref,
|};
export type barrier_FullBarrier$data = barrier_FullBarrier;
export type barrier_FullBarrier$key = {
  +$data?: barrier_FullBarrier$data,
  +$fragmentRefs: barrier_FullBarrier$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditUser",
      "kind": "LinkedField",
      "name": "updatedBy",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipOwnerKnownUser"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "barrier_FullBarrier",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v1/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BarrierCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "BarrierSourceType",
          "kind": "LinkedField",
          "name": "sourceTypes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activeDirectorySourceName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasConfiguredList",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BarrierSourceDetail",
              "kind": "LinkedField",
              "name": "sourceDetails",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Escalation",
      "kind": "LinkedField",
      "name": "escalation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditMeta",
          "kind": "LinkedField",
          "name": "auditMeta",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerMessageStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerMessageStatusDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownerMessageDeliveryDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CodeTablePair",
      "kind": "LinkedField",
      "name": "ownerRole",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceTypeCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BarrierNote",
      "kind": "LinkedField",
      "name": "notes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        (v5/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "condition": "skipOwnerKnownUser",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "KnownUser",
          "kind": "LinkedField",
          "name": "ownerKnownUser",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emailReceiver",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instantMessageReceiver",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Barrier",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aceaa372a40a797fa522318ecc8024cb';

module.exports = node;
