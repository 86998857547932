/*eslint-disable react/no-set-state */
/*eslint-disable react/no-did-mount-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import Avatar from '@material-ui/core/Avatar'
import Paper from '@material-ui/core/Paper'
import { graphql, fetchQuery } from 'react-relay'
import { environment } from '../../graphql/relay/env'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'

export default class PlanListFilter extends Component {
  static propTypes = {
    handleFilterHeightChange: PropTypes.func,
    handleFilterChange: PropTypes.func,
    handleClickSubtype: PropTypes.func,
    handleClickType: PropTypes.func,
    type: PropTypes.string,
    subtype: PropTypes.string,
  }

  state = {
    findText: '',
    showSubFilters: true,
    filters: undefined
  }

  componentDidMount() {
    if(this.filterRef && this.filterRef.current) {
      this.props.handleFilterHeightChange(this.filterRef.current.clientHeight)
    }
  }

  componentDidUpdate() {
    if(this.filterRef && this.filterRef.current) {
      this.props.handleFilterHeightChange(this.filterRef.current.clientHeight)
    }
  }

  componentWillUnmount() {
    this.isCancelled = true
  }

  // CA-2530 - called by PlanList.refreshPlanList
  setFilterState = (state, findText) => {
    this.setState({filters: state, showSubFilters: true})
    // CA-2803 - hack to fix findText lost when coming back
    if(typeof findText !== 'undefined') {
      this.setState({findText: findText})
    }
  }

  getKeyFromType = type => {
    let res
    Object.keys(this.state.filters).forEach(key => {
      if (type === this.state.filters[key].type) {
        res = key
      }
    })
    return res
  }

  handleClickType = type => {
    this.setState({showSubFilters: false})
    this.props.handleFilterChange(type, null, null)
  }

  handleClickSubtype = (subtype, query) => {
    this.props.handleFilterChange(this.props.type, subtype, query)
  }

  handleClickSearch = e => {
    if (e.key === 'Enter') {
      this.props.handleFilterChange('VISIT', null, this.state.findText)
    } else if(e.key === 'Escape') {
      this.setState({ findText:''})
      this.handleClickReset()
    }
  }

  handleTextChanged = (event, text) => {
    if (text.length <= 30) {
      this.setState({ findText: text })
    }
  }

  handleClickReset = () => {
    this.handleClickType('PATIENT')
    this.setState({ findText:''})
  }

  filterRef = React.createRef()

  render() {
    const {filters, showSubFilters} = this.state
    if(!filters) return null
    return (
      <div ref={this.filterRef}>
        <StyledPaper square elevation={2}>
          <div>
            {Object.keys(filters).map((key, index) => {
              const filter = filters[key]
              return (
                <StyledChip
                  key={index}
                  selected={this.props.type == filter.type}
                  avatar={<Avatar>{filter.total}</Avatar>}
                  label={filter.name}
                  onClick={() => {
                    this.handleClickType(filter.type)
                  }}
                  badgeColorSelected={
                    this.props.subtype ? '#bdbdbd' : 'rgb(0, 188, 212)'
                  }
                />
              )
            })}
            <TextField
              style={{ bottom: -4, maxWidth: 160 }}
              fullWidth={false}
              value={this.state.findText}
              hintText="Name,Bed,Visit,MRN"
              onChange={this.handleTextChanged}
              onKeyDown={this.handleClickSearch}
            />
            <FlatButton
              label="Reset"
              style={{
                border: '1px solid lightgrey',
                lineHeight: '30px',
                maxHeight: 34,
                width: '20px',
                marginLeft: '3px',
                marginTop: '4px',
              }}
              labelStyle={{ textTransform: 'none' }}
              onClick={this.handleClickReset}
            />
          </div>

          {showSubFilters ?
            (<div>
            {(this.props.type === 'DISCHARGE' && (
              <SubFilterTitle>Discharges Due Today</SubFilterTitle>
            )) ||
            (this.props.type === 'PROGRESSION' && (
              <SubFilterTitle>Progression Indicator</SubFilterTitle>
            ))}
            {this.props.type &&
            this.props.type !== 'VISIT' &&
            Object.keys(
              filters[this.getKeyFromType(this.props.type)].sub
            ).map((key, index) => {
              let subobj = filters[
                this.getKeyFromType(this.props.type)
                ].sub[key]
              return subobj.name ? (
                subobj.separator ? (
                  <SeparatorSpan>
                    <StyledChip
                      // style={{
                      //   display:
                      //     this.props.type == filter.type
                      //       ? 'inline-flex'
                      //       : 'none',
                      // }}
                      key={`${subobj.type}_${key}`}
                      selected={this.props.subtype == `${subobj.type}_${key}`}
                      avatar={
                        <Avatar>{subobj.total ? subobj.total : 0}</Avatar>
                      }
                      label={subobj.name}
                      onClick={() => {
                        this.handleClickSubtype(
                          `${subobj.type}_${key}`,
                          subobj.type
                        )
                      }}
                      badgeColorSelected="rgb(0, 188, 212)"
                      chipcolorselected={
                        subobj.chipColor
                          ? subobj.chipColor
                          : 'rgb(224,224,224)'
                      }
                      chipcolordefault={
                        subobj.chipColor
                          ? subobj.chipColor
                          : 'rgb(224,224,224)'
                      }
                    />
                  </SeparatorSpan>
                ) : (
                  <StyledChip
                    // style={{
                    //   display:
                    //     this.props.type == filter.type
                    //       ? 'inline-flex'
                    //       : 'none',
                    // }}
                    key={`${subobj.type}_${key}`}
                    selected={this.props.subtype == `${subobj.type}_${key}`}
                    avatar={
                      <Avatar>{subobj.total ? subobj.total : 0}</Avatar>
                    }
                    label={subobj.name}
                    onClick={() => {
                      this.handleClickSubtype(
                        `${subobj.type}_${key}`,
                        subobj.type
                      )
                    }}
                    badgeColorSelected="rgb(0, 188, 212)"
                    chipcolorselected={
                      subobj.chipColor ? subobj.chipColor : 'rgb(224,224,224)'
                    }
                    chipcolordefault={
                      subobj.chipColor ? subobj.chipColor : 'rgb(224,224,224)'
                    }
                  />
                )
              ) : null
            })}
            </div>

            ) : null
          }
        </StyledPaper>
      </div>
    )
  }
}

const badgeColorSelected = '#bdbdbd',
  chipcolorselected = 'rgb(178, 235, 242)',
  badgeColorDefault = '#bdbdbd',
  chipcolordefault = 'rgb(224,224,224)'

const StyledPaper = styled(Paper)`
  padding: 10px;
  margin-bottom: 5px;
`

const StyledChip = styled(({ badgeColorSelected, ...other }) => (
  <Chip {...other} classes={{ label: 'label', avatar: 'avatar' }} />
))`
  && {
    background-color: ${props =>
      props.selected
        ? props.chipcolorselected
          ? props.chipcolorselected
          : chipcolorselected
        : props.chipcolordefault
        ? props.chipcolordefault
        : chipcolordefault};
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    user-select: none;
    white-space: nowrap;
    color: black;
    margin-right: 3px;
    margin-bottom: 5px;
  }
  &&:hover,
  &&:focus {
    background-color: ${props =>
      props.selected
        ? props.chipcolorselected
          ? props.chipcolorselected
          : chipcolorselected
        : props.chipcolordefault
        ? props.chipcolordefault
        : chipcolordefault};
  }
  && .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    user-select: none;
    white-space: nowrap;
    color: black;
  }
  && .avatar {
    background-color: ${props =>
      props.selected
        ? props.badgeColorSelected
          ? props.badgeColorSelected
          : badgeColorSelected
        : badgeColorDefault};
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    // CA-2262 - so 3 digits visible on IE11
    // padding-left: 12px;
    // padding-right: 12px;
    user-select: none;
    white-space: nowrap;
    color: black;
  }
`

const SubFilterTitle = styled.span`
  display: inline-block;
  max-width: 80px;
  position: relative;
  top: 5px;
`
const SeparatorSpan = styled.span`
  border-left: 1px solid black;
  padding-left: 10px;
  margin-left: 10px;
`
