import React, { useState } from 'react'
import PropTypes from 'prop-types'

import '../escalation.scss'

import KnownPersonnelTypeAhead from '../../barrier/knownPersonnelTypeAhead/KnownPersonnelTypeAhead'
import {fetchQuery} from 'react-relay'
import {environment} from '../../../graphql/relay/env'
import {
  searchKnownUsers,
  buildKnownUserInput,
} from '../../../graphql/relay/queries/knownUser'

import { RadioButtonGroup, RadioButton } from 'material-ui/RadioButton'
import { filterConstants } from '../../../util/filters'


const onSearchAssignedBarriersKnownPersonnel = async value => {
  const knownUserInput = buildKnownUserInput({ searchText: value, isOwner: true })
  const response = await fetchQuery(environment, searchKnownUsers, { knownUserInput })
  return response.knownUsers
}

const BarrierOwnerNameFilter = ({ onBarrierOwnerNameChange, selectedBarrierName, selectedBarrierOwnerRadio, onBarrierOwnerRadioChange }) => {
  const [focusOnSearchField, setFocusOnSearchField] = useState(false)
  const selectedBarrierNameValue = selectedBarrierName && selectedBarrierName.length > 0 ? selectedBarrierName[0] : ''

  const handleRadioButtonClicked = (event) => {
    setFocusOnSearchField(event.target.value === filterConstants.barrierOwnerRadio.search.value)
    onBarrierOwnerRadioChange(event.target.value)
  }

  return (
    <div className="filter-sub-container">
      <div className="filter-header">
        <span className="filter-label">Barrier Owner</span>
      </div>

      <div className="filter-items" style={{ paddingBottom: 0 }}>
        <RadioButtonGroup
          name={'barrierOwnerRadioGroup'}
          valueSelected={selectedBarrierOwnerRadio}>
          <RadioButton
            value={filterConstants.barrierOwnerRadio.allValue.value}
            label={filterConstants.barrierOwnerRadio.allValue.label}
            onClick={handleRadioButtonClicked}
          />
          <RadioButton
            value={filterConstants.barrierOwnerRadio.noOwners.value}
            label={filterConstants.barrierOwnerRadio.noOwners.label}
            onClick={handleRadioButtonClicked}
          />
          <RadioButton
            value={filterConstants.barrierOwnerRadio.search.value}
            label={filterConstants.barrierOwnerRadio.search.label}
            onClick={handleRadioButtonClicked}
          />
        </RadioButtonGroup>
      </div>


      <div style={{ paddingBottom: '23px' }} className="filter-items" onClick={() => onBarrierOwnerRadioChange(filterConstants.barrierOwnerRadio.search.value)}>
        <KnownPersonnelTypeAhead
          label={'Search Barrier Owners'}
          onSearchKnownPersonnel={onSearchAssignedBarriersKnownPersonnel}
          onResultSelected={onBarrierOwnerNameChange}
          isShowIrisIcon={false}
          selectedValue={selectedBarrierNameValue}
          focusOnSearchField={focusOnSearchField} />
      </div>
    </div>
  )
}

BarrierOwnerNameFilter.propTypes = {
  onBarrierOwnerNameChange: PropTypes.func,
  selectedBarrierName: PropTypes.array,
  selectedBarrierOwnerRadio: PropTypes.string,
  onBarrierOwnerRadioChange: PropTypes.func,
}

export default BarrierOwnerNameFilter
