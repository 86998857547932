/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type visit_updateTimeToDecisionWithOffset_MutationVariables = {|
  visitId: any,
  hoursOffset: number,
|};
export type visit_updateTimeToDecisionWithOffset_MutationResponse = {|
  +visitMutation: ?{|
    +updateTimeToDecisionWithOffset: ?{|
      +id: string,
      +decisionTime: ?any,
      +meta: {|
        +dischargeToday: boolean,
        +tlosExceeded: boolean,
        +plannedDischargeExceeded: boolean,
        +decisionDue: boolean,
        +timeElapsedSinceAdmission: any,
      |},
      +plan: ?{|
        +id: string,
        +milestones: $ReadOnlyArray<?{|
          +id: string,
          +dueDate: ?any,
        |}>,
      |},
    |}
  |}
|};
export type visit_updateTimeToDecisionWithOffset_Mutation = {|
  variables: visit_updateTimeToDecisionWithOffset_MutationVariables,
  response: visit_updateTimeToDecisionWithOffset_MutationResponse,
|};
*/


/*
mutation visit_updateTimeToDecisionWithOffset_Mutation(
  $visitId: Long!
  $hoursOffset: Int!
) {
  visitMutation {
    updateTimeToDecisionWithOffset(visitId: $visitId, hoursOffset: $hoursOffset) {
      id
      decisionTime
      meta {
        dischargeToday
        tlosExceeded
        plannedDischargeExceeded
        decisionDue
        timeElapsedSinceAdmission
        id
      }
      plan {
        id
        milestones {
          id
          dueDate
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hoursOffset"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "visitId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "hoursOffset",
    "variableName": "hoursOffset"
  },
  {
    "kind": "Variable",
    "name": "visitId",
    "variableName": "visitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeToday",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tlosExceeded",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plannedDischargeExceeded",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decisionDue",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeElapsedSinceAdmission",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Plan",
  "kind": "LinkedField",
  "name": "plan",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Milestone",
      "kind": "LinkedField",
      "name": "milestones",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "visit_updateTimeToDecisionWithOffset_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateTimeToDecisionWithOffset",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "visit_updateTimeToDecisionWithOffset_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisitMutation",
        "kind": "LinkedField",
        "name": "visitMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Visit",
            "kind": "LinkedField",
            "name": "updateTimeToDecisionWithOffset",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "VisitMeta",
                "kind": "LinkedField",
                "name": "meta",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d723c37a235cb8ad2d5c1a14aefe1705",
    "id": null,
    "metadata": {},
    "name": "visit_updateTimeToDecisionWithOffset_Mutation",
    "operationKind": "mutation",
    "text": "mutation visit_updateTimeToDecisionWithOffset_Mutation(\n  $visitId: Long!\n  $hoursOffset: Int!\n) {\n  visitMutation {\n    updateTimeToDecisionWithOffset(visitId: $visitId, hoursOffset: $hoursOffset) {\n      id\n      decisionTime\n      meta {\n        dischargeToday\n        tlosExceeded\n        plannedDischargeExceeded\n        decisionDue\n        timeElapsedSinceAdmission\n        id\n      }\n      plan {\n        id\n        milestones {\n          id\n          dueDate\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '239ef22ea595813fa691161fa032e7fa';

module.exports = node;
