import React from 'react'
import PropTypes from 'prop-types'

/**
 * A simple display of an AuditUser in a given format.
 * @param userName Destructured from the user prop. The user's username
 * @param emptyText The text to display if there is no user prop
 * @returns A JSX component containing the formatted name
 */
export const AuditUser = ({ user, emptyText = '' }) => {
  // See CA-1383 - there had been some crazy replaces happening when
  // firstNames contained letter l
  // got rid of the format that was never used on any calls, and obviously
  // not needed since there had been recent trivial bugs for names not being
  // displayed consistently as last, first
  return <span>{user ? `${user.lastName}, ${user.firstName}` : emptyText}</span>
}

AuditUser.propTypes = {
  format: PropTypes.string,
  emptyText: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
}
