import * as React from 'react'

export const AdmissionType = {
  InPatient: require('./img/AdmissionType_InPatient.png'),
  OutPatient: require('./img/AdmissionType_OutPatient.png'),
  Observation: require('./img/AdmissionType_Observation.png'),
}

export const Gender = {
  Female: require('./img/Gender_Female.png'),
  Male: require('./img/Gender_Male.png'),
  nil: require('./img/Gender_nil.png'),
  Other: require('./img/Gender_Other.png'),
  Unknown: require('./img/Gender_Unknown.png'),
  Genderqueer: require('./img/Gender_Genderqueer.png'),
}

export const Notes = {
  Yes: require('./img/Notes_Yes.png'),
  No: require('./img/Notes_No.png'),
}

export const Readmission = {
  Yes: require('./img/Readmission_Yes.png'),
  No: require('./img/Readmission_No.png'),
}

export const ReadmissionRisk = {
  Yes: require('./img/ReadmissionRisk_Yes.png'),
  No: require('./img/ReadmissionRisk_No.png'),
}

export const LC = {
  Save: require('./lc/save.png'),
}

export function asTag(className: string, img: string, props: Object) {
  return <img className={className} {...props} src={img} />
}

export { NamedSvgIcon } from './icon/NamedSvgIcon'
