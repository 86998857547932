import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import JVIcon from 'mdi-react/VectorPolygonIcon'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setDetailTabIndex } from '../../ducks/views'

import './jvion.scss'

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setDetailTabIndex,
      },
      dispatch
    ),
  }
}

@connect(
  null,
  mapDispatchToProps
)
class VectorSummary extends React.Component {
  render() {
    const {
      bedId,
      planId,
      visitId,
      onClick,
      patientVector,
      actions: { setDetailTabIndex },
    } = this.props

    const handleClick = () => {
      if (onClick) {
        onClick(bedId, planId, visitId)
        setDetailTabIndex(2)
      }
    }

    if (!patientVector) return null

    const highestBracket = patientVector.conditions.reduce((acc, cond) => {
      if (cond.riskBracket === 'High Risk') {
        acc = 'High Risk'
      } else if (cond.riskBracket === 'Medium Risk' && acc !== 'High Risk') {
        acc = 'Medium Risk'
      }
      return acc
    }, 'Low Risk')

    const iconClassNames = 'jvionIcon jvionIcon-' + highestBracket.charAt(0)

    return (
      <span
        style={{ cursor: onClick ? 'pointer' : 'arrow' }}
        onClick={handleClick}>
        <JVIcon className={iconClassNames} /> {patientVector.conditions.length}
      </span>
    )
  }
}

VectorSummary.propTypes = {
  bedId: PropTypes.string,
  planId: PropTypes.string,
  visitId: PropTypes.string,
  onClick: PropTypes.func,

  patientVector: PropTypes.shape({
    encounterIdId: PropTypes.string,
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        riskBracket: PropTypes.string,
      })
    ),
  }),
  actions: PropTypes.shape({
    setDetailTabIndex: PropTypes.func,
  }),
}

export default VectorSummary
