/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BoardTemplateRow_rowData$ref = any;
export type BoardTemplateInput = {|
  name: string,
  boardType: string,
  description: string,
  template: string,
|};
export type boardTemplate_modifyBoardTemplateEntry_MutationVariables = {|
  boardTemplateId: any,
  boardTemplateInput: BoardTemplateInput,
|};
export type boardTemplate_modifyBoardTemplateEntry_MutationResponse = {|
  +boardTemplateMutation: ?{|
    +modifyBoardTemplateEntry: ?{|
      +$fragmentRefs: BoardTemplateRow_rowData$ref
    |}
  |}
|};
export type boardTemplate_modifyBoardTemplateEntry_Mutation = {|
  variables: boardTemplate_modifyBoardTemplateEntry_MutationVariables,
  response: boardTemplate_modifyBoardTemplateEntry_MutationResponse,
|};
*/


/*
mutation boardTemplate_modifyBoardTemplateEntry_Mutation(
  $boardTemplateId: UUID!
  $boardTemplateInput: BoardTemplateInput!
) {
  boardTemplateMutation {
    modifyBoardTemplateEntry(boardTemplateId: $boardTemplateId, boardTemplateInput: $boardTemplateInput) {
      ...BoardTemplateRow_rowData
      id
    }
  }
}

fragment BoardTemplateRow_rowData on BoardTemplate {
  id
  name
  boardType
  description
  template
  columns {
    field
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardTemplateId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardTemplateInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "boardTemplateId",
    "variableName": "boardTemplateId"
  },
  {
    "kind": "Variable",
    "name": "boardTemplateInput",
    "variableName": "boardTemplateInput"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "boardTemplate_modifyBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTemplateMutation",
        "kind": "LinkedField",
        "name": "boardTemplateMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BoardTemplate",
            "kind": "LinkedField",
            "name": "modifyBoardTemplateEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BoardTemplateRow_rowData"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "boardTemplate_modifyBoardTemplateEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardTemplateMutation",
        "kind": "LinkedField",
        "name": "boardTemplateMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BoardTemplate",
            "kind": "LinkedField",
            "name": "modifyBoardTemplateEntry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "boardType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "template",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardColumnConfig",
                "kind": "LinkedField",
                "name": "columns",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "field",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "365ac4b6116a9028285d10031d536a72",
    "id": null,
    "metadata": {},
    "name": "boardTemplate_modifyBoardTemplateEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation boardTemplate_modifyBoardTemplateEntry_Mutation(\n  $boardTemplateId: UUID!\n  $boardTemplateInput: BoardTemplateInput!\n) {\n  boardTemplateMutation {\n    modifyBoardTemplateEntry(boardTemplateId: $boardTemplateId, boardTemplateInput: $boardTemplateInput) {\n      ...BoardTemplateRow_rowData\n      id\n    }\n  }\n}\n\nfragment BoardTemplateRow_rowData on BoardTemplate {\n  id\n  name\n  boardType\n  description\n  template\n  columns {\n    field\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ab2cd1d73f08f396b8c953d6bfb4ade';

module.exports = node;
