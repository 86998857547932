/*eslint-disable react/no-set-state, react/no-direct-mutation-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'

import { Paper } from 'material-ui'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import IconButton from 'material-ui/IconButton'
import {
  cyan100 as SelectedColor,
  grey400 as AddBarrierColor,
} from 'material-ui/styles/colors'

import Delete from 'mdi-react/DeleteForeverIcon'

import { FixedView } from './FixedView'
import { Configurable } from '../components'
import { QuickAddMenu } from '../components/plan'

import { UserGQL } from '../graphql/queries'
import { withMutations } from '../graphql/decorators'

import { selectItem } from '../ducks/app-selections'
import { is_IE } from '../util/utils'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
import styled from 'styled-components'
const styleSheet = createStyleSheet('UserDashboard', () => ({
  icon: {
    float: 'right',
    marginTop: '-7px',
    width: '33px',
    height: '33px',
    borderRadius: '50%',
  },
  iconMenuTitle: {
    position: 'relative',
    top: '5px',
  },
}))

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('c3.views.UserDashboard')

import { showDrawer } from '../ducks/views'
import { drawers } from '../containers/drawer-component-mapping'

function mapStateToProps(state) {
  return {
    selected: state.selectAppState,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectItem,
        showDrawer,
      },
      dispatch
    ),
  }
}

@graphql(UserGQL.queries.userSetting, { name: 'userSetting' })
@withMutations([
  UserGQL.mutations.updateFavoriteActions,
  UserGQL.mutations.updateFavoriteNotes,
  UserGQL.mutations.removeFavoriteBarrier,
])
@connect(
  mapStateToProps,
  mapDispatchToProps
)
class UserDashboard extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      selectItem: PropTypes.func,
      showDrawer: PropTypes.func,
    }),
    selected: PropTypes.object.isRequired,
    updateFavoriteActions: PropTypes.func,
    updateFavoriteNotes: PropTypes.func,
    removeFavoriteBarrier: PropTypes.func,
    userSetting: PropTypes.shape({
      loading: PropTypes.bool,
      refetch: PropTypes.func.isRequired,
      userSetting: PropTypes.object,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    actions: '',
    notes: '',
  }

  componentWillMount() {
    // Force the view to requery every time it mounts if it wasn't already loading/querying
    // Until we decide to poll or get push updates, this is the only way in which this view
    // can add or remove items
    if (!this.props.userSetting.loading) {
      this.props.userSetting.refetch()
    }
  }

  prepareItems(text) {
    let result = text.trim()
    result = result.split('\n')

    return result.reduce((acc, item) => {
      let itemToPush = item.trim()
      itemToPush.length > 0 && acc.push(itemToPush)
      return acc
    }, [])
  }

  handleAddBarrier = () => {
    this.props.actions.showDrawer(drawers.CHOOSE_BARRIER, {
      title: 'Add User Barrier',
      planId: '-1',
      refetch: this.props.userSetting.refetch,
    })
  }

  handleChangeActions = (event, newValue) => {
    if (newValue.length === 0) {
      this.setState({ actions: '' }, () => this.handleApplyActions())
    } else {
      this.setState({ actions: newValue })
    }
  }

  handleApplyActions = () => {
    const actions = this.prepareItems(this.state.actions)
    this.setState({ actions: this.state.actions.trim() })
    this.props.updateFavoriteActions({ variables: { actions } }).then(res => {
      this.props.userSetting.refetch()
    })
  }

  handleChangeNotes = (event, newValue) => {
    if (newValue.length === 0) {
      this.setState({ notes: '' }, () => this.handleApplyNotes())
    } else {
      this.setState({ notes: newValue })
    }
  }

  handleApplyNotes = () => {
    const notes = this.prepareItems(this.state.notes)
    this.setState({ notes: this.state.notes.trim() })
    this.props.updateFavoriteNotes({ variables: { notes } }).then(res => {
      this.props.userSetting.refetch()
    })
  }

  handleItemClick = (e, item) => {
    e.stopPropagation()
    this.handleItemSelected(item)
  }

  handleItemSelected = item => {
    this.props.actions.selectItem(item)
  }

  handleDeleteTouchTap = barrier => {
    const barrierId = barrier.id.substring(barrier.id.indexOf(':') + 1)
    this.props
      .removeFavoriteBarrier({ variables: { id: barrierId } })
      .then(res => {
        this.props.userSetting.refetch()
      })
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const {
      userSetting,
      selected: { currentItem },
    } = this.props

    const { actions, notes } = this.state
    // hack to get IE to match size of barrier paper
    const textAreaRows = is_IE ? 17 : 14

    const mapText = settings => settings.map(i => i.text).join('\n')

    if (userSetting.loading) {
      return null
    }

    let userActions: string, userNotes: string, userBarriers
    if (userSetting.loading) {
      userActions = ''
      userNotes = ''
      userBarriers = ''
    } else {
      userActions = actions.length
        ? actions
        : mapText(userSetting.userSetting.actions)
      userNotes = notes.length ? notes : mapText(userSetting.userSetting.notes)
      userBarriers = userSetting.userSetting.barriers
    }

    const disableApplyActions =
      actions.length === 0 ||
      actions === mapText(userSetting.userSetting.actions)

    const disableApplyNotes =
      notes.length === 0 || notes === mapText(userSetting.userSetting.notes)

    const config = ['Show']

    // The UI displays 14 rows in the textarea to match the height of the list, however the height of list is 1px less
    // so the below barrierStyleFirst adds 1px to the padding top, so if the sizing is adjusted and you want your
    // UI to look even, please adjust the styles below
    const barrierStyle = {
      borderBottom: '1px solid lightgrey',
      padding: '8px 5px 8px 5px',
      cursor: 'pointer',
      width: '100%',
    }
    // CA-1717 - if it doesn't have a templateId it's a custom barrier in the
    // User Settings from a pre 2.0 release - so don't show it
    // CA-2799 - Custom Barriers are back, but don't want them in Quick Add
    let barriers = userBarriers.filter(barrier => barrier.active && barrier.templateId)
    const filledSlots = barriers.length
    let index = filledSlots
    while (index < 10) {
      const id = String(index)
      barriers.push({
        id: id,
        __typename: 'FavoriteBarrier',
      })
      index++
    }
    const barrierSlots = barriers.map((barrier, ix) => {
      const selected = currentItem.id === barrier.id
      let style =
        ix == 0 ? { ...barrierStyle, padding: '9px 5px 8px 5px' } : barrierStyle
      style = selected ? { ...style, background: SelectedColor } : style
      return barrier.category ? (
        <div
          key={barrier.id}
          style={style}
          onClick={e => this.handleItemClick(e, barrier)}>
          <span>
            {barrier.category.value} : {barrier.name}
          </span>

          {selected && (
            <span className={classNames(classes.icon)}>
              <IconButton
                style={{
                  padding: '4px 4px 4px 4px',
                  marginTop: '0',
                  marginLeft: '0',
                  width: '34px',
                  height: '34px',
                }}
                onClick={() => this.handleDeleteTouchTap(barrier)}>
                <Delete />
              </IconButton>
            </span>
          )}
        </div>
      ) : (
        <div key={barrier.id} style={{ ...style, cursor: 'default' }}>
          <span style={{ color: AddBarrierColor, cursor: 'default' }}>
            Add Barrier
          </span>
        </div>
      )
    })

    const userBarriersItems = userBarriers.reduce((acc, fav) => {
      acc.push({ item: fav, text: `${fav.category.value} : ${fav.name}` })
      return acc
    }, [])

    const quickAddBarriers = (
      <span className={classNames(classes.iconMenuTitle)}>
        <QuickAddMenu
          elements={[{ items: userBarriersItems }]}
          showMenuType={false}
        />
      </span>
    )

    const quickAddActions = (
      <span className={classNames(classes.iconMenuTitle)}>
        <QuickAddMenu
          elements={[{ items: textToArray(userActions) }]}
          showMenuType={false}
        />
      </span>
    )

    const quickAddNotes = (
      <span className={classNames(classes.iconMenuTitle)}>
        <QuickAddMenu
          elements={[{ items: textToArray(userNotes) }]}
          showMenuType={false}
        />
      </span>
    )

    return (
      <FixedView
        container={
          <Configurable config={config}>
            <div className="row" style={{ margin: '0 0 5px 0' }}>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '8px', paddingRight: '5px' }}>
                {quickAddBarriers} Quick Add Barriers
              </div>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                {quickAddActions} Quick Add To Do's (top 10 show on menus)
              </div>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                {quickAddNotes} Quick Add Notes (top 10 show on menus)
              </div>
            </div>
            <div
              className="row"
              style={{ marginLeft: '0px', marginRight: '0px' }}>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '8px', paddingRight: '5px' }}>
                <Paper style={{ paddingLeft: '0', paddingRight: '0' }}>
                  {barrierSlots}
                </Paper>
              </div>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <StyledPaper>
                  <TextField
                    id="actions"
                    style={{}}
                    textareaStyle={{ whiteSpace: 'pre', overflow: 'auto' }}
                    wrap="off"
                    onChange={this.handleChangeActions}
                    value={userActions}
                    multiLine
                    fullWidth
                    rows={textAreaRows}
                    rowsMax={textAreaRows}
                  />
                </StyledPaper>
              </div>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                <StyledPaper>
                  <TextField
                    id="notes"
                    style={{}}
                    textareaStyle={{ whiteSpace: 'pre', overflow: 'auto' }}
                    wrap="off"
                    onChange={this.handleChangeNotes}
                    value={userNotes}
                    multiLine
                    fullWidth
                    rows={textAreaRows}
                    rowsMax={textAreaRows}
                  />
                </StyledPaper>
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: '10px',
                marginLeft: '0px',
                marginRight: '0px',
              }}>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '0', paddingRight: '5px' }}>
                <span style={{ float: 'right' }}>
                  <RaisedButton
                    label="Add Barrier"
                    disabled={filledSlots == 10}
                    onClick={this.handleAddBarrier}
                  />
                </span>
              </div>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '0', paddingRight: '5px' }}>
                <span style={{ float: 'right' }}>
                  <RaisedButton
                    label="Apply"
                    disabled={disableApplyActions}
                    onClick={this.handleApplyActions}
                  />
                </span>
              </div>
              <div
                className="col-xs-4"
                style={{ paddingLeft: '0', paddingRight: '5px' }}>
                <span style={{ float: 'right' }}>
                  <RaisedButton
                    label="Apply"
                    disabled={disableApplyNotes}
                    onClick={this.handleApplyNotes}
                  />
                </span>
              </div>
            </div>
          </Configurable>
        }
      />
    )
  }
}

export default UserDashboard

function textToArray(text: string) {
  return text.split('\n').filter(i => i)
}
const StyledPaper = styled(Paper)`
  padding-left: 5px;
  padding-right: 5px;
  min-height: 359px;
  @media (max-width: 1025px) {
    min-height: 370px;
  }
`
