/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type VisibilityBoardRow_rowData$ref = any;
export type VisibilityBoardLocationStatusesDisplay = "HIDDEN" | "VISIBLE" | "%future added value";
export type VisibilityBoardInput = {|
  name: string,
  description: string,
  templateId: any,
  footer?: ?string,
  scrollDuration?: ?number,
  timeHeaderFormat?: ?string,
  locationStatusesDisplay?: ?VisibilityBoardLocationStatusesDisplay,
  departmentBedConfig: string,
|};
export type visibilityBoard_addVisibilityBoardEntry_MutationVariables = {|
  visibilityBoardInput: VisibilityBoardInput
|};
export type visibilityBoard_addVisibilityBoardEntry_MutationResponse = {|
  +visibilityBoardMutation: ?{|
    +addVisibilityBoardEntry: ?{|
      +$fragmentRefs: VisibilityBoardRow_rowData$ref
    |}
  |}
|};
export type visibilityBoard_addVisibilityBoardEntry_Mutation = {|
  variables: visibilityBoard_addVisibilityBoardEntry_MutationVariables,
  response: visibilityBoard_addVisibilityBoardEntry_MutationResponse,
|};
*/


/*
mutation visibilityBoard_addVisibilityBoardEntry_Mutation(
  $visibilityBoardInput: VisibilityBoardInput!
) {
  visibilityBoardMutation {
    addVisibilityBoardEntry(visibilityBoardInput: $visibilityBoardInput) {
      ...VisibilityBoardRow_rowData
      id
    }
  }
}

fragment VisibilityBoardRow_rowData on VisibilityBoard {
  id
  name
  description
  boardTemplate {
    id
    name
  }
  departmentExchangeName
  bedExchangeNames
  footer
  scrollDuration
  timeHeaderFormat
  locationStatusesDisplay
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "visibilityBoardInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "visibilityBoardInput",
    "variableName": "visibilityBoardInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "visibilityBoard_addVisibilityBoardEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityBoardMutation",
        "kind": "LinkedField",
        "name": "visibilityBoardMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "VisibilityBoard",
            "kind": "LinkedField",
            "name": "addVisibilityBoardEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VisibilityBoardRow_rowData"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "visibilityBoard_addVisibilityBoardEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VisibilityBoardMutation",
        "kind": "LinkedField",
        "name": "visibilityBoardMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "VisibilityBoard",
            "kind": "LinkedField",
            "name": "addVisibilityBoardEntry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardTemplate",
                "kind": "LinkedField",
                "name": "boardTemplate",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "departmentExchangeName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bedExchangeNames",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "footer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scrollDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timeHeaderFormat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locationStatusesDisplay",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "021e0e7981d6baeea049ecff3794f8fe",
    "id": null,
    "metadata": {},
    "name": "visibilityBoard_addVisibilityBoardEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation visibilityBoard_addVisibilityBoardEntry_Mutation(\n  $visibilityBoardInput: VisibilityBoardInput!\n) {\n  visibilityBoardMutation {\n    addVisibilityBoardEntry(visibilityBoardInput: $visibilityBoardInput) {\n      ...VisibilityBoardRow_rowData\n      id\n    }\n  }\n}\n\nfragment VisibilityBoardRow_rowData on VisibilityBoard {\n  id\n  name\n  description\n  boardTemplate {\n    id\n    name\n  }\n  departmentExchangeName\n  bedExchangeNames\n  footer\n  scrollDuration\n  timeHeaderFormat\n  locationStatusesDisplay\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dd513cf5fa15991b64b52704f968450';

module.exports = node;
