/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type VisibilityBoardRow_rowData$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type VisibilityBoardRowList_list$ref: FragmentReference;
declare export opaque type VisibilityBoardRowList_list$fragmentType: VisibilityBoardRowList_list$ref;
export type VisibilityBoardRowList_list = $ReadOnlyArray<{|
  +$fragmentRefs: VisibilityBoardRow_rowData$ref,
  +$refType: VisibilityBoardRowList_list$ref,
|}>;
export type VisibilityBoardRowList_list$data = VisibilityBoardRowList_list;
export type VisibilityBoardRowList_list$key = $ReadOnlyArray<{
  +$data?: VisibilityBoardRowList_list$data,
  +$fragmentRefs: VisibilityBoardRowList_list$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VisibilityBoardRowList_list",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VisibilityBoardRow_rowData"
    }
  ],
  "type": "VisibilityBoard",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c02feb15354b5c7532b29d70f21e70d4';

module.exports = node;
