import { combineReducers } from 'redux'
import * as reducers from '../ducks'
import { LOGOUT } from '../ducks/user'

const appReducer = combineReducers(reducers)

// Borrowed from http://stackoverflow.com/a/37338532/1449525
const rootReducer = (state, action) => {
  // If the action is logout, we need to tell all the stores to reset themselves to their initial state
  // We do this by sending them an undefined current state
  if (action.type === LOGOUT) {
    // The only app state that persists logout is the login error
    const {
      authState: { errors },
    } = state
    state = { authState: { errors } }
  }

  // Invoke the normal app reducer
  return appReducer(state, action)
}

export default rootReducer
