/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProgressionIndicator = "AtRisk" | "HighRisk" | "NotSet" | "OnTime" | "%future added value";
export type plan_updateprogressionindicator_MutationVariables = {|
  planId: any,
  progressionIndicator: ProgressionIndicator,
|};
export type plan_updateprogressionindicator_MutationResponse = {|
  +planMutation: ?{|
    +updateProgressionIndicator: ?{|
      +id: string,
      +progressionIndicator: ProgressionIndicator,
    |}
  |}
|};
export type plan_updateprogressionindicator_Mutation = {|
  variables: plan_updateprogressionindicator_MutationVariables,
  response: plan_updateprogressionindicator_MutationResponse,
|};
*/


/*
mutation plan_updateprogressionindicator_Mutation(
  $planId: UUID!
  $progressionIndicator: ProgressionIndicator!
) {
  planMutation {
    updateProgressionIndicator(planId: $planId, progressionIndicator: $progressionIndicator) {
      id
      progressionIndicator
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "planId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "progressionIndicator"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PlanMutation",
    "kind": "LinkedField",
    "name": "planMutation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "planId",
            "variableName": "planId"
          },
          {
            "kind": "Variable",
            "name": "progressionIndicator",
            "variableName": "progressionIndicator"
          }
        ],
        "concreteType": "Plan",
        "kind": "LinkedField",
        "name": "updateProgressionIndicator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "progressionIndicator",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "plan_updateprogressionindicator_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "plan_updateprogressionindicator_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0b26f21f1ad6b1c334aa79f133c284f",
    "id": null,
    "metadata": {},
    "name": "plan_updateprogressionindicator_Mutation",
    "operationKind": "mutation",
    "text": "mutation plan_updateprogressionindicator_Mutation(\n  $planId: UUID!\n  $progressionIndicator: ProgressionIndicator!\n) {\n  planMutation {\n    updateProgressionIndicator(planId: $planId, progressionIndicator: $progressionIndicator) {\n      id\n      progressionIndicator\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd337e47d8872b250bff630f10d67656d';

module.exports = node;
