/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BoardType = "Visibility" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BoardTemplateRow_rowData$ref: FragmentReference;
declare export opaque type BoardTemplateRow_rowData$fragmentType: BoardTemplateRow_rowData$ref;
export type BoardTemplateRow_rowData = {|
  +id: string,
  +name: string,
  +boardType: BoardType,
  +description: string,
  +template: string,
  +columns: $ReadOnlyArray<{|
    +field: string
  |}>,
  +$refType: BoardTemplateRow_rowData$ref,
|};
export type BoardTemplateRow_rowData$data = BoardTemplateRow_rowData;
export type BoardTemplateRow_rowData$key = {
  +$data?: BoardTemplateRow_rowData$data,
  +$fragmentRefs: BoardTemplateRow_rowData$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardTemplateRow_rowData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boardType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "template",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardColumnConfig",
      "kind": "LinkedField",
      "name": "columns",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "field",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BoardTemplate",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '18473a84963def304706320c33065a64';

module.exports = node;
