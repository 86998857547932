import { EditDecisionTime } from './decision-time'
import { CreateBarrier } from './barrier'
import { DrgView } from './drg'
import { ChoosePlan } from './plan'
import ChooseBarrier from './plan/ChooseBarrier'
import EditWorkingDCDisposition from './plan/EditWorkingDCDisposition'
import { SeverityView } from './drg'
import ManageCareTeamMembers from './plan/ManageCareTeamMembers'
import EditBoardTemplateEntry from './boardTemplate/EditBoardTemplateEntry'
import EditVisibilityBoardEntry from './visibilityBoard/EditVisibilityBoardEntry'
import EditVisibilityBoardAddressEntry from './visibilityBoardAddress/EditVisibilityBoardAddressEntry'
import AddBoardTemplateEntry from './boardTemplate/AddBoardTemplateEntry'
import AddVisibilityBoardEntry from './visibilityBoard/AddVisibilityBoardEntry'
import AddVisibilityBoardAddressEntry from './visibilityBoardAddress/AddVisibilityBoardAddressEntry'
import ReportParams from '../components/reports/ReportParams'
import BarrierCategoryDrawer from './codeTable/barrierCategoryEditor/BarrierCategoryDrawer'
import BarrierSourceTypeDrawer from './codeTable/barrierSourceTypeEditor/BarrierSourceTypeDrawer'
import CodeTableDrawer from './codeTable/CodeTableDrawer'

/**
 * These will be passed into Redux, and therefore they should not contain the actual React components
 */
export const drawers = {
  DECISION_TIME_PICKER: 'decision-time-picker',
  CREATE_BARRIER: 'create_barrier',
  DRG_VIEW: 'drg-view',
  CHOOSE_PLAN: 'choose_plan',
  SEVERITY_VIEW: 'severity-view',
  CHOOSE_BARRIER: 'choose_barrier',
  EDIT_WORKING_DC: 'edit_working_dc',
  MANAGE_CARE_TEAM_MEMBERS: 'manage_care_team_members',
  CODE_TABLE_DRAWER: 'code_table_drawer',
  ADD_CODE_TABLE_ENTRY: 'add_code_table_entry',
  EDIT_CODE_TABLE_ENTRY: 'edit_code_table_entry',
  EDIT_BOARD_TEMPLATE_ENTRY: 'edit_board_template_entry',
  EDIT_VISIBILITY_BOARD_ENTRY: 'edit_visibility_board_entry',
  EDIT_VISIBILITY_BOARD_ADDRESS_ENTRY: 'edit_visibility_board_address_entry',
  ADD_VISIBILITY_BOARD_ENTRY: 'add_visibility_board_entry',
  ADD_VISIBILITY_BOARD_ADDRESS_ENTRY: 'add_visibility_board_address_entry',
  ADD_BOARD_TEMPLATE_ENTRY: 'add_board_template_entry',
  GENERATE_REPORT: 'generate_report',
  BARRIER_CATEGORY_DRAWER: 'barrier_category_drawer',
  BARRIER_SOURCE_TYPE_DRAWER: 'barrier_source_type_drawer'
}

/**
 * When given one of the constants above, this is the mapping to find the configuration of that drawer.
 * This is a mapping of drawer id to default configuration of the drawer. The configuration can be overridden
 * by passing a second argument to the showDrawer action, like:
 *
 *    showDrawer(drawers.SOME_DRAWER_ID, {title: "new title!"})
 *
 * Sizes: 'small', 'standard' (default), 'big', 'full'
 */
export const drawerConfigs = {
  [drawers.DECISION_TIME_PICKER]: {
    title: 'Decision Time',
    component: EditDecisionTime,
    size: 'small',
  },
  [drawers.CREATE_BARRIER]: {
    title: 'Create Barrier',
    component: CreateBarrier,
  },
  [drawers.DRG_VIEW]: {
    title: 'DRG Search',
    component: DrgView,
    size: 'big',
  },
  [drawers.SEVERITY_VIEW]: {
    title: 'Severity Search',
    component: SeverityView,
    size: 'big',
  },
  [drawers.CHOOSE_PLAN]: {
    title: 'Choose Plan',
    component: ChoosePlan,
    size: 'big',
  },
  [drawers.CHOOSE_BARRIER]: {
    title: 'Choose Barrier',
    component: ChooseBarrier,
  },
  [drawers.EDIT_WORKING_DC]: {
    title: 'Edit Working Discharge Disposition',
    component: EditWorkingDCDisposition,
    size: 'small',
  },
  [drawers.MANAGE_CARE_TEAM_MEMBERS]: {
    title: 'Manage Care Team Members',
    component: ManageCareTeamMembers,
  },
  [drawers.CODE_TABLE_DRAWER]: {
    component: CodeTableDrawer
  },
  [drawers.EDIT_BOARD_TEMPLATE_ENTRY]: {
    title: 'Edit Board Template Entry',
    component: EditBoardTemplateEntry,
  },
  [drawers.EDIT_VISIBILITY_BOARD_ENTRY]: {
    title: 'Edit Visibility Board Entry',
    component: EditVisibilityBoardEntry,
  },
  [drawers.EDIT_VISIBILITY_BOARD_ADDRESS_ENTRY]: {
    title: 'Edit Visibility Board Address Entry',
    component: EditVisibilityBoardAddressEntry,
  },
  [drawers.ADD_BOARD_TEMPLATE_ENTRY]: {
    title: 'Add Board Template Entry',
    component: AddBoardTemplateEntry,
  },
  [drawers.ADD_VISIBILITY_BOARD_ENTRY]: {
    title: 'Add Visibility Board Entry',
    component: AddVisibilityBoardEntry,
  },
  [drawers.ADD_VISIBILITY_BOARD_ADDRESS_ENTRY]: {
    title: 'Add Visibility Board Address Entry',
    component: AddVisibilityBoardAddressEntry,
  },
  [drawers.GENERATE_REPORT]: {
    title: 'Generate Report',
    component: ReportParams,
    size: 'small'
  },
  [drawers.BARRIER_CATEGORY_DRAWER]: {
    component: BarrierCategoryDrawer,
    size: 'big'
  },
  [drawers.BARRIER_SOURCE_TYPE_DRAWER]: {
    component: BarrierSourceTypeDrawer,
    size: 'big'
  },

}
