import {injectForGqlFile} from '../util/gql'
import gql from '../util/gql'

export const AppInfoGQL = {
  fragments: {},
  queries: {
    appInfo: gql`
      query appInfo {
        appInfo {
          description
          version
          name
          code
          credits
          copyright
          companyName
          customerPortalLink
        }
      }
    `,
  },
  mutations: {},
}

injectForGqlFile(AppInfoGQL)
