/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type barrier_FullBarrier$ref = any;
export type barrier_deescalateBarrier_MutationVariables = {|
  barrierId: any
|};
export type barrier_deescalateBarrier_MutationResponse = {|
  +barrierMutation: ?{|
    +deescalateBarrier: {|
      +$fragmentRefs: barrier_FullBarrier$ref
    |}
  |}
|};
export type barrier_deescalateBarrier_Mutation = {|
  variables: barrier_deescalateBarrier_MutationVariables,
  response: barrier_deescalateBarrier_MutationResponse,
|};
*/


/*
mutation barrier_deescalateBarrier_Mutation(
  $barrierId: UUID!
) {
  barrierMutation {
    deescalateBarrier(barrierId: $barrierId) {
      ...barrier_FullBarrier_3g2f0S
      id
    }
  }
}

fragment barrier_FullBarrier_3g2f0S on Barrier {
  id
  name
  description
  status
  pinned
  auditMeta {
    created
    createdBy {
      userName
      firstName
      lastName
      id
    }
    updated
    updatedBy {
      userName
      firstName
      lastName
      id
    }
  }
  category {
    code
    value
    sourceTypes {
      id
      code
      value
      active
      activeDirectorySourceName
      hasConfiguredList
      sourceDetails {
        id
        code
        value
        active
      }
    }
    id
  }
  escalation {
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
        id
      }
    }
    id
  }
  ownerMessageStatus
  ownerMessageStatusDate
  ownerMessageDeliveryDate
  owner
  ownerRole {
    code
    value
    id
  }
  sourceName
  sourceTypeCode
  notes {
    id
    text
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
        id
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
        id
      }
    }
    pinned
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "barrierId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "barrierId",
    "variableName": "barrierId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinned",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  },
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditUser",
  "kind": "LinkedField",
  "name": "createdBy",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AuditMeta",
  "kind": "LinkedField",
  "name": "auditMeta",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuditUser",
      "kind": "LinkedField",
      "name": "updatedBy",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "barrier_deescalateBarrier_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BarrierMutation",
        "kind": "LinkedField",
        "name": "barrierMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Barrier",
            "kind": "LinkedField",
            "name": "deescalateBarrier",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "skipOwnerKnownUser",
                    "value": true
                  }
                ],
                "kind": "FragmentSpread",
                "name": "barrier_FullBarrier"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "barrier_deescalateBarrier_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BarrierMutation",
        "kind": "LinkedField",
        "name": "barrierMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Barrier",
            "kind": "LinkedField",
            "name": "deescalateBarrier",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierCategory",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BarrierSourceType",
                    "kind": "LinkedField",
                    "name": "sourceTypes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "activeDirectorySourceName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasConfiguredList",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BarrierSourceDetail",
                        "kind": "LinkedField",
                        "name": "sourceDetails",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Escalation",
                "kind": "LinkedField",
                "name": "escalation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuditMeta",
                    "kind": "LinkedField",
                    "name": "auditMeta",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownerMessageStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownerMessageStatusDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownerMessageDeliveryDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "owner",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CodeTablePair",
                "kind": "LinkedField",
                "name": "ownerRole",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceTypeCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BarrierNote",
                "kind": "LinkedField",
                "name": "notes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ebfc40024902625a391c0927e7368ca",
    "id": null,
    "metadata": {},
    "name": "barrier_deescalateBarrier_Mutation",
    "operationKind": "mutation",
    "text": "mutation barrier_deescalateBarrier_Mutation(\n  $barrierId: UUID!\n) {\n  barrierMutation {\n    deescalateBarrier(barrierId: $barrierId) {\n      ...barrier_FullBarrier_3g2f0S\n      id\n    }\n  }\n}\n\nfragment barrier_FullBarrier_3g2f0S on Barrier {\n  id\n  name\n  description\n  status\n  pinned\n  auditMeta {\n    created\n    createdBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n    updated\n    updatedBy {\n      userName\n      firstName\n      lastName\n      id\n    }\n  }\n  category {\n    code\n    value\n    sourceTypes {\n      id\n      code\n      value\n      active\n      activeDirectorySourceName\n      hasConfiguredList\n      sourceDetails {\n        id\n        code\n        value\n        active\n      }\n    }\n    id\n  }\n  escalation {\n    auditMeta {\n      created\n      createdBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n    }\n    id\n  }\n  ownerMessageStatus\n  ownerMessageStatusDate\n  ownerMessageDeliveryDate\n  owner\n  ownerRole {\n    code\n    value\n    id\n  }\n  sourceName\n  sourceTypeCode\n  notes {\n    id\n    text\n    auditMeta {\n      created\n      createdBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n      updated\n      updatedBy {\n        userName\n        firstName\n        lastName\n        id\n      }\n    }\n    pinned\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a548305cef978aca768d49db4e788c49';

module.exports = node;
