import React from 'react'
import {
  green200 as GoodColor,
  yellow200 as BadColor,
  red200 as UglyColor,
  grey200 as UnknownColor,
} from 'material-ui/styles/colors'
import { branch, renderComponent } from 'recompose'
import { pushUniqueToArray } from './utils'

/*****************************************************************************************************************
 ********************************* withWorkspaces utility functions **********************************************
 *****************************************************************************************************************/

export const renderWhileLoading = (component, propName, dataName) =>
  branch(props => {
    const prop = props[propName]
    return (prop && prop.loading) || (prop && !prop[dataName])
  }, renderComponent(component))

export const renderWhileNoData = (component, propName, dataName) =>
  branch(props => {
    const prop = props[propName]
    return prop && !prop[dataName]
  }, renderComponent(component))

/**
 * Collect location map, bed exchangeName as the key.
 * @param facilities
 */
export const collectLocations = facilities => {
  const locationMap = new Map()
  facilities.forEach(facility =>
    facility.departments.forEach(department =>
      department.beds.forEach(bed => {
        locationMap.set(bed.exchangeName, {
          bed: bed,
          department: department,
          facility: facility,
        })
      })
    )
  )

  return locationMap
}

/**
 * Server returns list of beds for each workspace, this function turns a
 * workspace into a list of {visit, bed, plan}
 * @param workspace
 */
const normalizeWorkspace = workspace =>
  Array.isArray(workspace.bed)
    ? workspace.bed.map(bed => ({
        ...workspace,
        bed,
      }))
    : workspace

// const normalizeWorkspace = workspace => workspace

// TODO & FIXME this code should be able to be completely removed.
// There is no need to manually associate beds and locations as they
// are a part of the workspace query now.
/**
 * Calculate all possible workspaces.
 * Calculate locations we are interested in.
 * Filter out workpsaces that are in the locations.
 * @param facilities
 * @param workspaces
 */
export const collectWorkspaces = (facilities = [], workspaces = []) => {
  if (!facilities || !workspaces) return []

  // console.log('collectWorkspaces')
  const locationMap = collectLocations(facilities)
  const normalizedWorkspaces = workspaces.reduce(
    (acc, workspace) => acc.concat(normalizeWorkspace(workspace)),
    []
  )

  const userWorkspaces = []

  normalizedWorkspaces.forEach(workspace => {
    const foundLocation = locationMap.get(workspace.bed.exchangeName)
    if (
      foundLocation &&
      !userWorkspaces.find(
        ws =>
          ws.visit.id === workspace.visit.id &&
          ws.bed.exchangeName === foundLocation.bed.exchangeName
      )
    ) {
      userWorkspaces.push({
        ...foundLocation,
        plan: workspace.plan,
        visit: workspace.visit,
      })
    }
  })

  return userWorkspaces
}

// const onlyUnique = (value, index, self) => {
//   return self.indexOf(value) === index
// }

export const collectBeds = departments =>
  departments.reduce((acc, department) => acc.concat(department.beds), [])

export const collectDepartments = facilities =>
  facilities.reduce((acc, facility) => acc.concat(facility.departments), [])

export const collectCurrentVisitIds = facilities =>
  collectBeds(collectDepartments(facilities))
    .filter(bed => bed.visitId)
    .map(bed => bed.visitId)

export const collectDischargedVisitIds = facilities =>
  collectBeds(collectDepartments(facilities))
    .filter(bed => bed.dischargedVisitId)
    .map(bed => bed.dischargedVisitId)

export const collectCurrentAndDischargedVisitIds = facilities => {
  // console.log('collectCurrentAndDischargedVisitIds')
  let result = []
  collectBeds(collectDepartments(facilities)).forEach(bed => {
    bed.visitId ? pushUniqueToArray(bed.visitId, result) : ''
    bed.dischargedVisitId
      ? pushUniqueToArray(bed.dischargedVisitId, result)
      : ''
  })
  return result
}

export const collectConflictedVisitIds = facilities =>
  collectBeds(collectDepartments(facilities))
    .filter(bed => bed.conflictingVisitIds)
    .reduce((acc, bed) => acc.concat(bed.conflictingVisitIds), [])

export const collectCurrentVisitNumbers = workspaces => {
  // console.log('collectCurrentVisitNumbers')
  let result = []
  if (Array.isArray(workspaces)) {
    workspaces.forEach(item =>
      item && item.visit && item.visit.visitNumber
        ? pushUniqueToArray(item.visit.visitNumber, result)
        : ''
    )
  }
  return result
}

export const mapPatientVectors = patientVectors => {
  // console.log('mapPatientVectors')
  let result = {}
  patientVectors.forEach(vector => (result[vector.encounterId] = vector))
  return result
}

export const findColor = (value, oeThresholdCaution, oeThresholdDanger) => {
  return value === '?'
    ? UnknownColor
    : value <= oeThresholdCaution
    ? GoodColor
    : value > oeThresholdDanger
    ? UglyColor
    : BadColor
}

// FIXME!!! This calculation needs to be on the graph
export const calcForecastExcessDays = (capped, workspaces) => {
  let result = 0
  if (Array.isArray(workspaces)) {
    result = workspaces.reduce((acc, workspace) => {
      const fed = _calcForecastExcessDays(
        capped,
        workspace.plan.meta,
        workspace.visit.tlos
      )
      acc += fed > 0 ? fed : 0
      return acc
    }, 0)
  } else {
    result = _calcForecastExcessDays(
      capped,
      workspaces.plan.meta,
      workspaces.visit.tlos
    )
  }
  return result
}
// FIXME!!! This calculation needs to be on the graph
const _calcForecastExcessDays = (capped, meta, tlos) => {
  return capped
    ? meta.lengthOfStayMeta.cappedForecast - tlos
    : meta.lengthOfStayMeta.forecast - tlos
}

// FIXME!!! This calculation needs to be on the graph
export const calcForecastWorkingOE = (capped, workspaces) => {
  let result = 0
  if (Array.isArray(workspaces)) {
    if (workspaces.length > 0) {
      // formula (Sum of the Forecast LOS) / (Sum of Target LOS)
      let sumForecastLOS = 0
      let sumTargetLOS = 0
      workspaces.forEach(workspace => {
        let forecastLos = capped
          ? workspace.plan.meta.lengthOfStayMeta.cappedForecast
          : workspace.plan.meta.lengthOfStayMeta.forecast
        forecastLos = forecastLos ? forecastLos : 0
        sumForecastLOS += forecastLos
        sumTargetLOS += workspace.visit.tlos
      })
      const sumForecastWorkingOE = sumForecastLOS / sumTargetLOS
      result = isFinite(sumForecastWorkingOE)
        ? Math.round(sumForecastWorkingOE * 100) / 100
        : '?'
    }
  } else {
    result = _calcForecastWorkingOE(
      capped,
      workspaces.plan.meta,
      workspaces.visit.tlos
    )
  }
  return result
}

// FIXME!!! This calculation needs to be on the graph
export const _calcForecastWorkingOE = (capped, meta, tlos) => {
  let result
  const forecastLos = capped
    ? meta.lengthOfStayMeta.cappedForecast
    : meta.lengthOfStayMeta.forecast
  if (forecastLos && tlos) {
    const forecastWorkingOE = forecastLos / tlos
    result = isFinite(forecastWorkingOE)
      ? Math.round(forecastWorkingOE * 100) / 100
      : '?'
  } else {
    result = '?'
  }
  return result
}
