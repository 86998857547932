/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type MilestoneStatus = "Completed" | "NA" | "Open" | "Removed" | "%future added value";
export type Track = "Discharge" | "Progression" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type pin_MilestoneWithoutActions$ref: FragmentReference;
declare export opaque type pin_MilestoneWithoutActions$fragmentType: pin_MilestoneWithoutActions$ref;
export type pin_MilestoneWithoutActions = {|
  +id: string,
  +name: string,
  +description: ?string,
  +track: ?Track,
  +dueDate: ?any,
  +completedDate: ?any,
  +status: MilestoneStatus,
  +planTemplateName: string,
  +$refType: pin_MilestoneWithoutActions$ref,
|};
export type pin_MilestoneWithoutActions$data = pin_MilestoneWithoutActions;
export type pin_MilestoneWithoutActions$key = {
  +$data?: pin_MilestoneWithoutActions$data,
  +$fragmentRefs: pin_MilestoneWithoutActions$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pin_MilestoneWithoutActions",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "track",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planTemplateName",
      "storageKey": null
    }
  ],
  "type": "Milestone",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b372242274ed4ae27b22b2a3db5f9121';

module.exports = node;
