/*eslint-disable react/no-set-state */

import React from 'react'
import {connect} from "react-redux"
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { setNameBedQuery } from '../../ducks/escalation-filters'

import TextField from "material-ui/TextField"
import IconButton from "material-ui/IconButton/IconButton"
import Clear from "../icons/Clear"
import Person from "../icons/Person"

class QuickSearchComp extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      searchText: props.nameBedQuery,
      searchActive: props.nameBedQuery
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      searchText: nextProps.nameBedQuery,
      searchActive: nextProps.nameBedQuery
    })
  }

  handleTextChanged = (event, text) => {
    if (text.length <= 30) {
      this.setState({ searchText: text })
    }
  }

  handleClearSearch = () => {
    this.props.setNameBedQuery('')
    this.setState({
      searchActive: false,
      searchText: ''
    })
   }

  handleDoSearch = () => {
    this.props.setNameBedQuery(this.state.searchText)
    this.setState({searchActive: true})
  }

  handleClickSearch = e => {
    if (e.key === 'Enter' && this.state.searchText.length > 0) {
      this.handleDoSearch()
    } else if (e.key === 'Escape') {
      this.handleClearSearch()
    }
  }

  render() {
    const {searchActive, searchText} = this.state

    return (
      <span>
        <TextField
          style={{ top: -2, color: 'white'}}
          hintStyle={{color: 'white'}}
          inputStyle={{color: 'white'}}
          fullWidth={false}
          value={this.state.searchText}
          hintText="Name,Bed,Visit,MRN"
          onChange={this.handleTextChanged}
          onKeyDown={this.handleClickSearch}
        />

        {searchActive &&
          <IconButton
            className="iconButtonStyle"
            onClick={() => {
              this.handleClearSearch()
            }}>
            <Clear />
          </IconButton>}

        {!searchActive && searchText.length > 0 &&
          <IconButton
          className="iconButtonStyle"
          onClick={() => {
            this.handleDoSearch()

          }}>
            <Person />
        </IconButton>}

      </span>
    )
  }
}

QuickSearchComp.propTypes = {
  setNameBedQuery: PropTypes.func,
  nameBedQuery: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    nameBedQuery: state.escalationView.escalationNameBedQuery,
  }
}

export const QuickSearch = compose(
  connect(
    mapStateToProps,
    {setNameBedQuery}
  )
)(QuickSearchComp)

