import query from 'apollo-cache-inmemory/lib/fragmentMatcherIntrospectionQuery'
import {isNumber} from 'lodash'

import {
  BARRIER_OWNER_ASSIGNED_SUB_TYPE,
  ACKNOWLEDGED_MSG_SUBTYPE,
  NEEDS_REASSIGNMENT_MSG_SUBTYPE,
  UNACKNOWLEDGED_MSG_STATUS,
  ACKNOWLEDGED_MSG_STATUS,
  NEEDS_REASSIGNMENT_MSG_STATUS
} from '../components/barrier/BarrierConstants'

export const SET_FILTER = 'care-advance/escalation-filters/SET_FILTER'
export const SET_QUERY = 'care-advance/escalation-filters/SET_QUERY'
  export const SET_NAME_BED_QUERY = 'care-advance/escalation-filters/SET_NAME_BED_QUERY'
export const SET_SELECTED_BARRIER_SECTION =
  'care-advance/escalation-filters/SET_SELECTED_BARRIER_SECTION'
export const RESET_FILTER = 'care-advance/escalation-filters/RESET_FILTER'
export const SET_ESCALATION_WORKSPACES =
  'care-advance/escalation-filters/SET_ESCALATION_WORKSPACES'
import { filterConstants } from '../util/filters'
import { getBedRequestStatusName } from '../util/utils'

const initialState = {
  filter: {
    admissionType: 'all',
    losPreview: {
      value: '',
      days: 0,
      oeForecastTarget: .8,
      oeCurrentTarget: .8,
      decisionTimeDueOnly: false,
      decisionTimeDue: 0
    },
    barriers: {
      selected: 'all',
      open: false,
      escalated: false,
    },
    barrierCategories: [
      {
        code: 'ALL',
        value: 'All',
        checked: true,
      },
    ],
    barrierOwnerRoles: [
      {
        code: 'ALL',
        value: 'All',
        checked: true,
      },
    ],
    barrierOwnerRadio: {
      value: filterConstants.barrierOwnerRadio.defaultSelected.value//,
      //label: filterConstants.barrierOwnerRadio.defaultSelected.label
    },
    barrierOwners: [],
    barrierMessageStatuses: [
      {
        code: 'ALL',
        value: 'All Barriers',
        checked: true,
      },
      {
        code: ACKNOWLEDGED_MSG_SUBTYPE,
        value: 'Acknowledged',
        checked: true,
      },
      {
        code: NEEDS_REASSIGNMENT_MSG_SUBTYPE,
        value: 'Needs Reassignment',
        checked: true,
      },
      {
        code: BARRIER_OWNER_ASSIGNED_SUB_TYPE,
        value: 'Unacknowledged',
        checked: true,
      },
    ],
    units: [
      {
        type: 'Unit',
        exchangeName: 'All',
        name: 'ALL',
        checked: true,
      },
    ],
    visitStatus: 'Discharged',



    bedRequestStatuses: [
      {code: 'ALL', value: 'All', checked: true, isBedRequestStatusCode: false},
      {code: 'Anticipated,Pending,Ready,CheckedIn,IntentToAdmit', value: 'No DC or Transfer Status', checked: true, isBedRequestStatusCode: false},
      {code: 'AnticipatedUnitTransfer', value: "Potential Unit Transfer", checked: true, isBedRequestStatusCode: true},
      {code: 'PendingUnitTransfer', value: "Unit Transfer Ordered", checked: true, isBedRequestStatusCode: true},
      {code: 'ReadyForUnitTransfer', value: "Ready For Unit Transfer", checked: true, isBedRequestStatusCode: true},
      {code: 'AnticipatedDischarge', value: "Potential DC", checked: true, isBedRequestStatusCode: true},
      {code: 'DischargeOrderWithDelay', value: "Discharge Order With Delay", checked: true, isBedRequestStatusCode: true},
      {code: 'PendingDischarge', value: "DC Ordered", checked: true, isBedRequestStatusCode: true},
      {code: 'ReadyForDischarge', value: "Ready For DC", checked: true, isBedRequestStatusCode: true},
    ],

    quickFilters: [
      {code: 'none', value: "Reset", checked: false},
      {code: 'escalatedBarriers', value: "Escalated Barriers", checked: false},
      {code: 'pastLOSThreshold', value: "???", checked: false},
      {code: 'excessDays', value: "???", checked: false},
      {code: 'openNoOwner', value: "Open Barriers, No Owner", checked: false},
    ],

    sortOptions: [
      { code: 'unit_bed', value: 'Unit/Bed', type: 'all'},
      { code: 'barrier_message_status_time', value: 'Barrier Msg Status & Time', type: 'all'},
      { code: 'los_days_greater', value: 'LOS Days', type: 'all'},
      { code: 'forecast_oe', value: 'Forecast O/E', type: 'inpatient'},
      { code: 'current_oe', value: 'Current O/E', type: 'inpatient'},
      { code: 'forecast_excess', value: 'Forecast Excess Days', type: 'inpatient'}
    ],

    sortField: 'unit_bed',
    invokedAsQuickFilter: false,

  },

  escalationFilterQuery: '',  // query string for EscalationListPaginationContainer moved here

  escalationNameBedQuery: '',

  selectedBarrierSection: {
    selectedPlanId: '-1',
    selectedBarrierId: '-1',
    showAllBarriers: false,
  },
  workspaces: [],

}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_FILTER: {
      return {
        ...state,
        filter: initialState.filter,
      }
    }

    case SET_FILTER: {
      return {
        ...state,
        filter: action.payload,
      }
    }

    case SET_QUERY: {
      return {
        ...state,
        escalationFilterQuery: action.payload,
        escalationNameBedQuery: ''
      }
    }

    case SET_NAME_BED_QUERY: {
      return {
        ...state,
        escalationNameBedQuery: action.payload,
      }
    }
    case SET_SELECTED_BARRIER_SECTION: {
      return {
        ...state,
        selectedBarrierSection: action.payload,
      }
    }

    case SET_ESCALATION_WORKSPACES: {
      return {
        ...state,
        workspaces: action.payload,
      }
    }

    default:
      return state
  }
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  }
}

export function setQuery(query) {
  return {
    type: SET_QUERY,
    payload: query,
  }
}

export function setNameBedQuery(query) {
  return {
    type: SET_NAME_BED_QUERY,
    payload: query,
  }
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  }
}

export function setSelectedBarrierSection(selectedBarrierSection) {
  return {
    type: SET_SELECTED_BARRIER_SECTION,
    payload: selectedBarrierSection,
  }
}

// Helper function to create the query string called from several places
// this is not a reducer, this just seemed like a good place to put this
export function getQueryString(filter) {
  if (!filter) {
    return ''
  } else {
    let queryString = '', sortField = filter.sortField
    const admString = `admissiontype:${filter.admissionType};`
    if (!admString.toUpperCase().includes('ALL')) {
      queryString += admString
    }

    if(filter.admissionType === filterConstants.admissionType.outObsValue && isNumber(filter.losPreview.decisionTimeDue)){
      queryString += `los_hours_greater:${filter.losPreview.decisionTimeDue};`
    }

    if (filter.admissionType === 'inpatient') {
      switch (filter.losPreview.value) {
        case 'forecastExcessDayGreater':
          queryString += `forecast_excess:${filter.losPreview.days};`
          break
        case 'losDaysGreater':
          queryString += `los_days_greater:${filter.losPreview.days};`
          break
        case 'currentMore_1_00':
          queryString += `current_oe:${filter.losPreview.oeCurrentTarget};`
          break
        case 'forecastMore_1_20':
          queryString += `forecast_oe:${filter.losPreview.oeForecastTarget};`
          break
        default:
        // code block
      }
    }

    let barString = `barrier:${filter.barriers.selected};`
    if (!barString.toUpperCase().includes('ALL')) {
      queryString += barString
    }

    if (filter.barrierCategories && filter.barrierCategories.length > 0) {
      if (
        !(
          filter.barrierCategories.length == 1 &&
          filter.barrierCategories[0].value.toUpperCase() == 'ALL'
        )
      ) {
        if (!filter.barrierCategories[0].checked) {
          queryString += `barrierCategory:${filter.barrierCategories.map(
            barCat => {
              if (barCat.code !== 'ALL' && barCat.checked) {
                return barCat.code
              }
            }
          )};`
        }
      }
    }

    if (filter.barrierOwnerRoles && filter.barrierOwnerRoles.length > 0) {
      if (
        !(
          filter.barrierOwnerRoles.length == 1 &&
          filter.barrierOwnerRoles[0].value.toUpperCase() == 'ALL'
        )
      ) {
        if (!filter.barrierOwnerRoles[0].checked) {
          queryString += `barrierOwnerRole:${filter.barrierOwnerRoles.map(
            barRole => {
              if (barRole.code !== 'ALL' && barRole.checked) {
                return barRole.code
              }
            }
          )};`
        }
      }
    }

    if (filter.barrierOwnerRadio.value === filterConstants.barrierOwnerRadio.search.value && filter.barrierOwners && filter.barrierOwners.length > 0) {
      const encodedOwnerName = encodeURIComponent(filter.barrierOwners[0])
      queryString += `barrierownername:${encodedOwnerName};`
    } else if (filter.barrierOwnerRadio.value === filterConstants.barrierOwnerRadio.noOwners.value) {
      queryString += `barrierownername:none;`
    }

    if (filter.barrierMessageStatuses && filter.barrierMessageStatuses.length > 0) {
      if (
        !(
          filter.barrierMessageStatuses.length == 1 &&
          filter.barrierMessageStatuses[0].value.toUpperCase() == 'ALL'
        )
      ) {
        if (!filter.barrierMessageStatuses[0].checked) {
          queryString += `barriermessagestatus:${filter.barrierMessageStatuses.map(
            barMsgStatus => {
              if (barMsgStatus.code !== 'ALL' && barMsgStatus.checked) {
                switch (barMsgStatus.code) {
                  case NEEDS_REASSIGNMENT_MSG_SUBTYPE:
                    return NEEDS_REASSIGNMENT_MSG_STATUS
                  case BARRIER_OWNER_ASSIGNED_SUB_TYPE:
                    return UNACKNOWLEDGED_MSG_STATUS
                  case ACKNOWLEDGED_MSG_SUBTYPE:
                    return ACKNOWLEDGED_MSG_STATUS
                }
              }
            }
          )};`
        }
      }
    }

    if (filter.units && filter.units.length > 0) {
      if (!filter.units[0].checked) {
        queryString += `units:${filter.units.map(unit => {
          if (unit.exchangeName !== 'All' && unit.checked) {
            return unit.exchangeName
          }
        })};`
      }
    }

    // CA-1908 - new bedRequestStatus filters for 3 discharge coming up states
    const bedRequestStatusCodesChecked = filter.bedRequestStatuses
                                      .filter(status => status.checked)
                                      .map(status => status.code)

    //if 'ALL' is checked there is nothing to filter
    if(!bedRequestStatusCodesChecked.find(code => code == 'ALL')) {
      const commaSeparatedVals = bedRequestStatusCodesChecked.reduce((memo, code) => memo+','+code)
      queryString += 'bedrequeststatuses:'+commaSeparatedVals+';'
    }

    queryString += `order_by:${sortField}`

    return queryString
  }
}

export function getInitialState() {
  return initialState
}


