/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setFilter, setQuery, getQueryString } from '../ducks/escalation-filters'
import RaisedButton from 'material-ui/RaisedButton'

import { FixedView } from './FixedView'
import { UnitList } from '../components/location'

import { createStyleSheet } from 'jss-theme-reactor'
import { graphql, fetchQuery } from 'react-relay'

import { environment } from '../graphql/relay/env'
import { Promise } from 'es6-promise'
import { get, find, difference } from 'lodash'

import { assignBeds } from '../graphql/relay/queries/workarea'
import { handleMutation } from '../graphql/relay/queries/mutation'

import { LoadingIndicator } from '../components/loader'

const styleSheet = createStyleSheet('AssignBeds', theme => ({
  // This is just a route modal not a full app modal, so it's custom
  // If we need to reuse this in the future, best to extract it
  buttonBar: theme.c3Classes.buttonBar,
  buttonBarButton: theme.c3Classes.buttonBarButton,
}))

class AssignBeds extends Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,

    data: PropTypes.shape({
      loading: PropTypes.boolean,
      facilities: PropTypes.arrayOf(
        PropTypes.shape({
          // TODO: Replace with graphql-anywhere proptypes
          exchangeName: PropTypes.string,
          name: PropTypes.string,
          departments: PropTypes.arrayOf(
            PropTypes.shape({
              exchangeName: PropTypes.string,
              name: PropTypes.string,
              beds: PropTypes.array,
              type: PropTypes.string,
            })
          ),
        })
      ),
    }),
    filter: PropTypes.object,
    actions: PropTypes.shape({
      setFilter: PropTypes.func,
      setQuery: PropTypes.func,
    }),
  }

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  state = {
    myLocations: [],
    myNextLocations: [],
    applyDisabled: true,
    facilities: undefined,
  }

  componentDidMount() {
    if (!this.state.facilities) {
      this.fetchData(false)
    }
  }

  componentWillReceiveProps(nextProps) {
    const myLocations = []
    const myNextLocations = []
    if (nextProps.data?.facilities) {
      nextProps.data.facilities.forEach(fac =>
        fac.departments.forEach(dept =>
          dept.beds.map(bed => {
            bed.assigned && myLocations.push(bed.exchangeName)
            bed.assigned && myNextLocations.push(bed.exchangeName)
          })
        )
      )

      !this.isCancelled &&
      this.setState({ myLocations, myNextLocations, applyDisabled: true })
    }
  }

  componentWillUnmount() {
    this.isCancelled = true
  }

  setFilter = (resFacilities) => {
    const { filter, actions } = this.props
    let filterUnits = filter.units
    // Get applied units from 'my assignment'
    let filteredFacilities = resFacilities.flatMap(facility =>
      facility.departments
       .filter(department => department.beds.length > 0 && department.beds.some(bed => bed.assigned === true) )
       .map(department => ({
          name: department.name,
          exchangeName: department.exchangeName,
          type: department.type,
          checked: true
      }))
    )

    let allUnit = filterUnits[0]
    filterUnits = filterUnits.slice(1)
    const filterUnitNames = new Set(filterUnits.map(unit => unit.name))
    const facilityNames = new Set(filteredFacilities.map(facility => facility.name))
    // Add missing data in filterUnits from filteredFacilities and Remove data from filterUnits not in filteredFacilities by name field
    const updatedFilterUnits = filterUnits.concat(filteredFacilities.filter(facility => !filterUnitNames.has(facility.name)))
      .filter(unit => facilityNames.has(unit.name))
      .sort((unit1, unit2) => unit1.name.localeCompare(unit2.name))

    if(allUnit){
      allUnit.checked = updatedFilterUnits.every(item => item.checked)
    }
    // Place the 'All' unit at the beginning
    let updatedfilter = { ...filter, units: [allUnit, ...updatedFilterUnits] }
    actions.setFilter(updatedfilter)
    actions.setQuery(getQueryString(updatedfilter))
  }

  fetchData = (hasAssignmentUpdated = true) => {
    const assignedBeds = fetchQuery(environment, AllAssignedBeds, {}).then(
      data => {
        const bedArr = get(data, 'viewer.beds.nodes', [])
        return new Map(
          bedArr.map(i => [`${i.department.name}${i.name}`, true])
        )
      }
    )

    const allBeds = fetchQuery(environment, AllFacilityBeds, {}).then(data => {
      return data
    })

    Promise.all([assignedBeds, allBeds]).then(values => {
      const myLocations = []
      const myNextLocations = []
      const assignedBedsMap = values[0]
      const facilities = values[1].facilities
      let resFacilities = []
      facilities.forEach(facility => {
        let resFacility = {}
        resFacility.departments = []
        facility.departments.nodes.forEach(department => {
          let resDepartment = {}
          resDepartment.name = department.name
          resDepartment.beds = []
          department.beds.nodes.forEach(bed => {
            if (assignedBedsMap.has(`${department.name}${bed.name}`)) {
              bed = { assigned: true, ...bed }
              myLocations.push(bed.exchangeName)
              myNextLocations.push(bed.exchangeName)
            } else {
              bed = { assigned: false, ...bed }
            }
            resDepartment.beds.push(bed)
          })
          resDepartment.exchangeName = department.exchangeName
          resDepartment.includedInLosCalculation =
            department.includedInLosCalculation
          resDepartment.name = department.name
          resDepartment.type = department.type
          resFacility.departments.push(resDepartment)
        })
        resFacility.exchangeName = facility.exchangeName
        resFacility.name = facility.name
        resFacility.ordinal = facility.ordinal
        resFacilities.push(resFacility)
      })

      if (this.isCancelled) {
        this.setState({
          applyDisabled: false
        })
      } else {

        if(hasAssignmentUpdated){
          this.setFilter(resFacilities)
        }

        this.setState({
          facilities: resFacilities,
          myLocations: myLocations,
          myNextLocations: myNextLocations,
          applyDisabled: true,
        })
      }
    })
  }

  handleApply = () => {
    this.setState({
      applyDisabled: true
    })

    handleMutation(
      environment,
      assignBeds,
      { exchangeNames: this.state.myNextLocations },
      this.fetchData,
      null,
      () => {
        this.setState({
          applyDisabled: false
        })
      }
    )
  }

  handleFacilityClick = (facilityId, selectAll) => {
    const { facilities, myNextLocations } = this.state
    const facility = find(facilities, { 'exchangeName': facilityId })

    // let's add or remove all bedIds of units (departments) in the facility to this.state.myNextLocations
    let allBedIsinFacility = []
    // let's find all bedIds
    facility.departments.forEach(dept => {
      dept.beds.forEach(bed => allBedIsinFacility.push(bed.exchangeName))
    })
    // the cleanest way to add beds without duplicates to the existing array is to remove values from the array and then add
    let reassignedBedIds = difference(myNextLocations, allBedIsinFacility) // unmatched values will be ignored
    if (selectAll) {
      reassignedBedIds = reassignedBedIds.concat(allBedIsinFacility)
    }

    !this.isCancelled &&
    this.setState({ myNextLocations: reassignedBedIds, applyDisabled: false })
  }

  handleUnitClick = (unitId, selectAll) => {
    const isLocationSelected = locationRef =>
      this.state.myNextLocations.includes(locationRef)
    let assigned = []
    this.state.facilities.forEach(fac =>
      fac.departments.forEach(dept => {
        if (unitId === dept.exchangeName) {
          if (selectAll) {
            dept.beds.forEach(bed => {
              assigned.push(bed.exchangeName)
            })
          }
        } else {
          dept.beds.forEach(bed => {
            if (isLocationSelected(bed.exchangeName)) {
              assigned.push(bed.exchangeName)
            }
          })
        }
      })
    )

    !this.isCancelled &&
    this.setState({ myNextLocations: assigned, applyDisabled: false })
  }

  handleBedClick = (unitId, bedId, selected) => {
    const assigned = this.state.myNextLocations
    selected
      ? assigned.push(bedId)
      : assigned.splice(assigned.indexOf(bedId), 1)
    !this.isCancelled &&
    this.setState({ myNextLocations: assigned, applyDisabled: false })
  }

  handleSelectAll = () => {
    this.handleSelectDeselectAll(true)
  }

  handleDeselectAll = () => {
    this.handleSelectDeselectAll(false)
  }

  handleSelectDeselectAll = selected => {
    let assigned = []
    if (selected) {
      this.state.facilities.map(fac => {
        fac.departments.map(dept => {
          dept.beds.map(bed => {
            assigned.push(bed.exchangeName)
          })
        })
      })
    }
    !this.isCancelled &&
    this.setState({ myNextLocations: assigned, applyDisabled: false })
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    const facilities = this.state.facilities

    const { myLocations, myNextLocations, applyDisabled } = this.state

    const showLoading = !facilities
    const loadingSpinner = <LoadingIndicator />

    return (
      <FixedView
        container={
          <div>
            {showLoading && loadingSpinner}
            {facilities && (
              <UnitList
                facilities={facilities}
                myLocations={myLocations}
                myNextLocations={myNextLocations}
                disabled={showLoading}
                onFacilityClick={this.handleFacilityClick}
                onUnitClick={this.handleUnitClick}
                onBedClick={this.handleBedClick}
              />
            )}
          </div>
        }
        footer={
          <div className={classes.buttonBar}>
            <RaisedButton
              className={classes.buttonBarButton}
              label="Apply"
              disabled={applyDisabled}
              onClick={this.handleApply}
            />
            <RaisedButton
              className={classes.buttonBarButton}
              label="Select All"
              disabled={showLoading}
              onClick={this.handleSelectAll}
            />
            <RaisedButton
              className={classes.buttonBarButton}
              label="Deselect All"
              disabled={showLoading}
              onClick={this.handleDeselectAll}
            />
          </div>
        }
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    filter: state.escalationView.filter
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setFilter,
        setQuery
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignBeds)

const AllFacilityBeds = graphql`
  query AssignBeds_AllFacilityBeds_Query {
    facilities {
      name
      exchangeName
      ordinal
      departments(first: 10000) {
        nodes {
          type
          exchangeName
          name
          beds(first: 10000) {
            nodes {
              exchangeName
              name
              status
            }
          }
        }
      }
    }
  }
`
const AllAssignedBeds = graphql`
  query AssignBeds_AllAssignedBeds_Query {
    viewer {
      beds(first: 10000, query: "allBeds", type: PATIENT) {
        nodes {
          department {
            type
            exchangeName
            name
          }
          exchangeName
          name
          status
        }
      }
    }
  }
`
