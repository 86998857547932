import { graphql } from 'react-relay'

export const addVisibilityBoardEntry = graphql`
  mutation visibilityBoard_addVisibilityBoardEntry_Mutation($visibilityBoardInput: VisibilityBoardInput!) {
    visibilityBoardMutation {
      addVisibilityBoardEntry(visibilityBoardInput: $visibilityBoardInput) {
        ...VisibilityBoardRow_rowData
      }
    }
  }
`

export const modifyVisibilityBoardEntry = graphql`
  mutation visibilityBoard_modifyVisibilityBoardEntry_Mutation($visibilityBoardId: UUID!, $visibilityBoardInput: VisibilityBoardInput!) {
    visibilityBoardMutation {
      modifyVisibilityBoardEntry(visibilityBoardId: $visibilityBoardId, visibilityBoardInput: $visibilityBoardInput) {
        ...VisibilityBoardRow_rowData
      }
    }
  }
`

export const deleteVisibilityBoardEntry = graphql`
  mutation visibilityBoard_deleteBoardTemplateEntry_Mutation($visibilityBoardId: UUID!) {
    visibilityBoardMutation {
      deleteVisibilityBoardEntry(visibilityBoardId: $visibilityBoardId) {
        ...VisibilityBoardRowList_list
      }
    }
  }
`
