import React from 'react'
import PropTypes from 'prop-types'

import { red300 as OvertimeColor } from 'material-ui/styles/colors'

import { DateNoTime } from '../date'
import { DecisionTimePicker } from './'

export const StayInfo = ({ visit, showDischargeTime = true, style = {} }) => {
  const isInPatient = visit.admissionType === 'InPatient'
  const overtime =
    visit.currentDayOfStay && visit.currentDayOfStay >= visit.tlos

  const timeLabel = visit.drgAttributes &&
    visit.drgAttributes.code &&
    visit.targetDischargeTime && (
      <span className="attr" style={{ marginLeft: 5 }}>
        {'Target: '} <DateNoTime dateString={visit.targetDischargeTime} />
      </span>
    )

  const inPatientDays = visit.currentDayOfStay && (
    <span style={overtime ? { color: OvertimeColor } : {}}>
      Day {visit.currentDayOfStay} of {visit.tlos ? visit.tlos : '?'}
    </span>
  )

  const dayDecision = isInPatient ? (
    inPatientDays
  ) : (
    <DecisionTimePicker visit={visit} />
  )

  return (
    <span className="attr">
      {dayDecision}
      {isInPatient && showDischargeTime && timeLabel}
    </span>
  )
}

StayInfo.propTypes = {
  visit: PropTypes.object.isRequired,
  showDischargeTime: PropTypes.bool,
  style: PropTypes.object,
}
