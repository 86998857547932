import { graphql } from 'react-relay'

export const pinBarrier = graphql`
  mutation pin_pinBarrier_Mutation($itemId: UUID!) {
    pinMutation {
      pin(itemId: $itemId, itemType: Barrier) {
        barriers {
          id
          ...pin_FullBarrier
        }
      }
    }
  }
`

export const unpinBarrier = graphql`
  mutation pin_unpinBarrier_Mutation($itemId: UUID!) {
    pinMutation {
      unpin(itemId: $itemId, itemType: Barrier) {
        id
        barriers {
          id
          pinned
          ...pin_FullBarrier
        }
      }
    }
  }
`

export const pinPlanAction = graphql`
  mutation pin_pinPlanAction_Mutation($itemId: UUID!) {
    pinMutation {
      pin(itemId: $itemId, itemType: PlanAction) {
        id
        actions {
          id
          pinned
          ...pin_FullPlanAction
        }
      }
    }
  }
`

export const unpinPlanAction = graphql`
  mutation pin_unpinPlanAction_Mutation($itemId: UUID!) {
    pinMutation {
      unpin(itemId: $itemId, itemType: PlanAction) {
        id
        actions {
          id
          pinned
          ...pin_FullPlanAction
        }
      }
    }
  }
`

export const pinPlanNote = graphql`
  mutation pin_pinPlanNote_Mutation($itemId: UUID!) {
    pinMutation {
      pin(itemId: $itemId, itemType: PlanNote) {
        id
        notes {
          id
          pinned
          ...pin_FullPlanNote
        }
      }
    }
  }
`

export const unpinPlanNote = graphql`
  mutation pin_unpinPlanNote_Mutation($itemId: UUID!) {
    pinMutation {
      unpin(itemId: $itemId, itemType: PlanNote) {
        id
        notes {
          id
          pinned
          ...pin_FullPlanNote
        }
      }
    }
  }
`

export const pinMilestoneAction = graphql`
  mutation pin_pinMilestoneAction_Mutation($itemId: UUID!) {
    pinMutation {
      pin(itemId: $itemId, itemType: MilestoneAction) {
        id
        milestones {
          id
          ...pin_FullMilestone
        }
      }
    }
  }
`

export const unpinMilestoneAction = graphql`
  mutation pin_unpinMilestoneAction_Mutation($itemId: UUID!) {
    pinMutation {
      unpin(itemId: $itemId, itemType: MilestoneAction) {
        id
        milestones {
          id
          ...pin_FullMilestone
        }
      }
    }
  }
`

const FullMilestone = graphql`
  fragment pin_FullMilestone on Milestone {
    id
    name
    description
    track
    dueDate
    completedDate
    status
    planTemplateName
    actions {
      id
      text
      status
      pinned
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
      milestoneTemplateName
    }
  }
`
const MilestoneWithoutActions = graphql`
  fragment pin_MilestoneWithoutActions on Milestone {
    id
    name
    description
    track
    dueDate
    completedDate
    status
    planTemplateName
  }
`

const FullPlanAction = graphql`
  fragment pin_FullPlanAction on PlanAction {
    id
    text
    status
    pinned
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
  }
`

const FullPlanNote = graphql`
  fragment pin_FullPlanNote on PlanNote {
    id
    text
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
    pinned
  }
`

const FullBarrier = graphql`
  fragment pin_FullBarrier on Barrier {
    id
    name
    description
    status
    pinned
    auditMeta {
      created
      createdBy {
        userName
        firstName
        lastName
      }
      updated
      updatedBy {
        userName
        firstName
        lastName
      }
    }
    category {
      code
      value
    }
    escalation {
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
      }
    }
    owner
    ownerRole {
      code
      value
    }
    notes {
      id
      text
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
        updated
        updatedBy {
          userName
          firstName
          lastName
        }
      }
      pinned
    }
    escalation {
      auditMeta {
        created
        createdBy {
          userName
          firstName
          lastName
        }
      }
    }
  }
`
