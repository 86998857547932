import React from 'react'
import PropTypes from 'prop-types'

import { grey800 as TextColor } from 'material-ui/styles/colors'
import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('PlanListSection', () => ({
  section: {
    padding: '.5em',
    fontWeight: 500,
    color: TextColor,
  },
}))

export const PlanListSection = ({ department }, context) => {
  const classes = context.styleManager.render(styleSheet)

  return <div className={classNames(classes.section)}>{department.name}</div>
}

PlanListSection.contextTypes = {
  styleManager: PropTypes.object.isRequired,
}

PlanListSection.propTypes = {
  department: PropTypes.object.isRequired,
}
