import { graphql } from 'react-relay'

export const careTeamMembers = graphql`
  query careTeam_careTeamMembers_Query {
    careTeamMembers {
      id
      name
      role {
        id
        code
        value
      }
    }
  }
`

export const createCareTeamMember = graphql`
  mutation careTeam_createCareTeamMember_Mutation(
    $planId: UUID!
    $member: CareTeamMemberInput!
  ) {
    careTeamMemberMutation {
      createCareTeamMember(planId: $planId, member: $member) {
        ...careTeam_PlanCareTeam
      }
    }
  }
`
export const assignCareTeamMember = graphql`
  mutation careTeam_assignCareTeamMember_Mutation(
    $planId: UUID!,
    $memberId: UUID!) {
    careTeamMemberMutation {
      assignCareTeamMember(planId: $planId, memberId: $memberId) {
        ...careTeam_PlanCareTeam
      }
    }
  }
`

export const updateCareTeamMemberName = graphql`
  mutation careTeam_updateCareTeamMemberName_Mutation(
    $planId: UUID!
    $memberId: UUID!
    $name: String
  ) {
    careTeamMemberMutation {
      updateCareTeamMemberName(
        planId: $planId
        memberId: $memberId
        name: $name
      ) {
        ...careTeam_PlanCareTeam
      }
    }
  }
`

export const updateCareTeamMemberRole = graphql`
  mutation careTeam_updateCareTeamMemberRole_Mutation(
    $planId: UUID!
    $memberId: UUID!
    $roleCode: String!
  ) {
    careTeamMemberMutation {
      updateCareTeamMemberRole(
        planId: $planId
        memberId: $memberId
        roleCode: $roleCode
      ) {
        ...careTeam_PlanCareTeam
      }
    }
  }
`
export const removeCareTeamMember = graphql`
  mutation careTeam_removeCareTeamMember_Mutation($planId: UUID!, $memberId: UUID!) {
    careTeamMemberMutation {
      removeCareTeamMember(planId: $planId, memberId: $memberId) {
        ...careTeam_PlanCareTeam
      }
    }
  }
`

const PlanCareTeam = graphql`
  fragment careTeam_PlanCareTeam on Plan {
    id
    careTeamMembers {
      id
      name
      primary
      role {
        id
        code
        value
      }
    }
  }
`
// const StatelessCareTeamMember = graphql`
//   fragment careTeam_StatelessCareTeamMember on CareTeamMember {
//     id
//     name
//     role {
//       id
//       code
//       value
//     }
//   }
// `

