import gql, {injectForGqlFile} from '../util/gql'

export const PinGQL = {
  fragments: {},
  queries: {},
  mutations: {
    pinBarrier: gql `
      mutation pinBarrier($itemId: UUID!) {
        pinMutation {
          pin(itemId: $itemId, itemType: Barrier) {
            id
            barriers {
              id
              pinned
              ...FullBarrier
            }
          }
        }
      }
    `,

    unpinBarrier: gql `
      mutation unpinBarrier($itemId: UUID!) {
        pinMutation {
          unpin(itemId: $itemId, itemType: Barrier) {
            id
            barriers {
              id
              pinned
              ...FullBarrier
            }
          }
        }
      }
    `,
    pinMilestoneAction: gql `
      mutation pinMilestoneAction($itemId: UUID!) {
        pinMutation {
          pin(itemId: $itemId, itemType: MilestoneAction) {
            id
            milestones {
              id
              ...FullMilestone
            }
          }
        }
      }
    `,
    unpinMilestoneAction: gql `
      mutation unpinMilestoneAction($itemId: UUID!) {
        pinMutation {
          unpin(itemId: $itemId, itemType: MilestoneAction) {
            id
            milestones {
              id
              ...FullMilestone
            }
          }
        }
      }
    `,
    pinPlanAction: gql `
      mutation pinPlanAction($itemId: UUID!) {
        pinMutation {
          pin(itemId: $itemId, itemType: PlanAction) {
            id
            actions {
              id
              pinned
              ...FullPlanAction
            }
          }
        }
      }
    `,
    unpinPlanAction: gql `
      mutation unpinPlanAction($itemId: UUID!) {
        pinMutation {
          unpin(itemId: $itemId, itemType: PlanAction) {
            id
            actions {
              id
              pinned
              ...FullPlanAction
            }
          }
        }
      }
    `,
    pinPlanNote: gql `
      mutation pinPlanNote($itemId: UUID!) {
        pinMutation {
          pin(itemId: $itemId, itemType: PlanNote) {
            id
            notes {
              id
              pinned
              ...FullPlanNote
            }
          }
        }
      }
    `,
    unpinPlanNote: gql `
      mutation unpinPlanNote($itemId: UUID!) {
        pinMutation {
          unpin(itemId: $itemId, itemType: PlanNote) {
            id
            notes {
              id
              pinned
              ...FullPlanNote
            }
          }
        }
      }
    `,
  },
}

injectForGqlFile(PinGQL)
