/*eslint-disable react/no-set-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Paper } from 'material-ui'
import { TextField } from 'material-ui'
import { RaisedButton } from 'material-ui'

import { FixedView } from './FixedView'

import classNames from 'classnames'
import { createStyleSheet } from 'jss-theme-reactor'
const styleSheet = createStyleSheet('AppInfo', () => ({
  preformatted: {
    whiteSpace: 'pre-wrap',
  },
  criteria: {
    paddingLeft: '10px',
    paddingRight: '10px',
    margin: '15px',
  },
  button: {
    marginTop: '28px',
  },
}))

import { Logger, LogManager } from '../log'
const logger: Logger = LogManager.getLogger('c3.views.PatientSearch')

export class PatientSearch extends Component {
  static propTypes = {}

  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      dob: '',
      visitId: '',
      mrn: '',
      resetDisabled: true,
      nameRowDisabled: false,
      idDisabled: false,
      mrnDisabled: false,
      searchDisabled: true,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // Enable/Disable buttons based on state
    const { firstName, lastName, dob, visitId, mrn } = this.state
    // since we set state here, have to prevent loop
    if (
      firstName !== prevState.firstName ||
      lastName !== prevState.lastName ||
      dob !== prevState.dob ||
      visitId !== prevState.visitId ||
      mrn !== prevState.mrn
    ) {
      const canSearch = ((firstName || lastName) && dob) || (visitId || mrn)
      const gotChars = firstName || lastName || dob || visitId || mrn

      this.setState({
        searchDisabled: !canSearch,
        resetDisabled: !gotChars,
        nameRowDisabled: !!(visitId || mrn),
        idDisabled: !!(firstName || lastName || dob || mrn),
        mrnDisabled: !!(firstName || lastName || dob || visitId),
      })
    }
  }

  handleChangeFirstName = (event, nameChange) => {
    this.setState({ firstName: nameChange })
  }

  handleChangeLastName = (event, nameChange) => {
    this.setState({ lastName: nameChange })
  }

  handleChangeDOB = (event, dobChange) => {
    this.setState({ dob: dobChange })
  }

  handleChangeVisitId = (event, idChange) => {
    this.setState({ visitId: idChange })
  }

  handleChangeMRN = (event, mrnChange) => {
    this.setState({ mrn: mrnChange })
  }

  handleReset = () => {
    this.setState({
      lastName: '',
      firstName: '',
      dob: '',
      visitId: '',
      mrn: '',
    })
  }

  handleSearch = () => {
    // todo need a query
  }

  render() {
    const classes = this.context.styleManager.render(styleSheet)

    return (
      <FixedView
        container={
          <Paper className={classNames(classes.criteria)}>
            <div className={classNames('row')}>
              <div className={classNames('col-xs-1')} />
              <div className={classNames('col-xs-3')}>
                <TextField
                  disabled={this.state.nameRowDisabled}
                  floatingLabelText="First Name"
                  onChange={this.handleChangeFirstName}
                  value={this.state.firstName}
                />
              </div>
              <div className={classNames('col-xs-3')}>
                <TextField
                  disabled={this.state.nameRowDisabled}
                  floatingLabelText="Last Name"
                  onChange={this.handleChangeLastName}
                  value={this.state.lastName}
                />
              </div>
              <div className={classNames('col-xs-3')}>
                <TextField
                  type="date"
                  disabled={this.state.nameRowDisabled}
                  floatingLabelText="Date of Birth"
                  floatingLabelFixed
                  onChange={this.handleChangeDOB}
                  value={this.state.dob}
                />
              </div>

              <div className={classNames('col-xs-2', classes.button)}>
                <RaisedButton
                  label="Reset"
                  disabled={this.state.resetDisabled}
                  onClick={this.handleReset}
                />
              </div>
            </div>

            <div className={classNames('row')}>
              <div className={classNames('col-xs-1')} />
              <div className={classNames('col-xs-4')}>
                <TextField
                  disabled={this.state.idDisabled}
                  floatingLabelText="Visit ID"
                  onChange={this.handleChangeVisitId}
                  value={this.state.visitId}
                />
              </div>
              <div className={classNames('col-xs-4')}>
                <TextField
                  disabled={this.state.mrnDisabled}
                  floatingLabelText="MRN"
                  onChange={this.handleChangeMRN}
                  value={this.state.mrn}
                />
              </div>

              <div className={classNames('col-xs-1')} />
              <div className={classNames('col-xs-2', classes.button)}>
                <RaisedButton
                  label="Search"
                  disabled={this.state.searchDisabled}
                  onClick={this.handleSearch}
                />
              </div>
            </div>
          </Paper>
        }
      />
    )
  }
}
