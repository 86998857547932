import {fetchQuery} from 'react-relay'
import { caAppConfigs } from '../graphql/relay/queries/config'
import { environment  } from '../graphql/relay/env'
import moment from 'moment'

export const queryAndSetConfigs = (stateObj, callback) => {
  fetchQuery(environment, caAppConfigs, {}).then(data => {
    configs = data.configs
    alerts = data.alertIconConfig
    languageResources = data.languageResources

    stateObj && stateObj._isMounted && stateObj.setState(
      {caAppConfigsLoading: false}, callback ?
        callback.call(stateObj) : () => ''
    )
  })
}

let configs = []
let alerts = []
let languageResources = []

const devMode = process.env.NODE_ENV === 'development'

export const getStatusOverrides = resourceKey => {
  let result = languageResources.find(config => config.resourceKey === resourceKey)
  return result ? result.resourceValue : ''
}

export const getAlertsFor = unitName => {
  let result = alerts.find(config => config.key === unitName)
  if(result){
    return result.value
  } else {
    result = alerts.find(config => config.key === '*')
    return result ? result.value : []
  }
}

export const getConfig = name => {
  // debug code to detect race conditions
  if(devMode && configs.length === 0) {
    // eslint-disable-next-line no-console
    console.log( "caAppConfigs EMPTY!! FOR - " + name)
  }

  let config = configs.find(item => item.name === name)
  return config ? config.value : ''
}

export const getConfigBoolean = name => {
  return getConfig(name) === 'true'
}

export const getPlannedLabel = () => getDischargeLabelPrefix()

export const isJvionEnabled = () =>
  getConfig('ca.jvion.enabled.boolean') === 'true'

export const isJvionSetRecommendations = () =>
  getConfig('ca.jvion.set.recommendations.boolean') === 'true'

export const getPageSize = () => {
  return parseInt(getConfig('ca.cursor.ui.size.integer')) || 20
}

export const getInitialPageSize = () => {
  return parseInt(getConfig('ca.cursor.initial.ui.size.integer')) || 20
}

export const parseTimeValue = timeString => {
  let result = 0

  const unit = timeString.substr(timeString.length - 1)
  switch (unit) {
    case 's':
      result = parseInt(timeString) * 1000
      break
    case 'm':
      result = parseInt(timeString) * 60 * 1000
      break
    case 'h':
      result = parseInt(timeString) * 60 * 60 * 1000
      break
  }

  return result
}

export const computeThresholdMinutes = (value) => {
  const config = value || '0m'
  const units = config.substr(config.length - 1, 1)
  let result = config.substr(0, config.length - 1)

  switch (units) {
    case 'd':
      result = result * 60 * 24
      break
    case 'h':
      result = result * 60
      break
  }

  return result
}

export const hasEstimatedTransferDateIssue = (visit) => {
  const estimatedTransferTime = visit.anticipatedTransferTime
  if (estimatedTransferTime) {
    if(visit.anticipatedTransferTimeAutoPopulated && moment(estimatedTransferTime).format('L') === moment().format('L') ){
      return false
    }

    const pastEstimatedTransferDateThreshold = computeThresholdMinutes(getConfig('unit.transfer.estimated.transfer.date.past.time'))

    if (moment(estimatedTransferTime).isBefore(moment().subtract(pastEstimatedTransferDateThreshold, 'minutes'))) {
      return true
    }
  }
  return false
}

export const getDischargeLabelPrefix = () => {
  return getConfig('ca.expected.discharge.label').split(' ')[0]
}

export const getDischargeLabelAcronym= () => {
  return makeAcronym(getConfig('ca.expected.discharge.label'))
}

export const getTransferLabelPrefix = () => {
  return getConfig('ca.expected.transfer.label').split(' ')[0]
}

export const getTransferLabelAcronym = () => {
  return makeAcronym(getConfig('ca.expected.transfer.label'))
}

function makeAcronym(sentance) {
  let result = ''
  let words = sentance.split(' ')
  for (const letter of words) {
    result = result + letter[0]
  }
  return result
}
