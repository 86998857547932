/*eslint-disable react/no-set-state */
/*eslint-disable no-prototype-builtins */


import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'

import {
  updatePlanFilters,
  updateNavigationWorkspaces,
  showDrawer,
  setDetailTabIndex,
} from '../../ducks/views'
import {
  getBedRequestStatusName,
  getNumToLoad,
  getPaginationsFromIndex,
  getScrollToIndex
} from '../../util/utils'
import { updateNavigationWorkspacesQuery } from '../../ducks/views'
import {QueryRenderer, graphql, fetchQuery} from 'react-relay'
import { default as PlanListPaginationContainer } from './PlanListPaginationContainer'
import { environment } from '../../graphql/relay/env'
import PlanListFilter from './PlanListFilter'
import { TransactionErrorDialog } from '../../components/error'
import { clearErrorPg } from '../../ducks/errorspg'
import { withApplications } from '../../containers/app'
import {getConfig, getDischargeLabelPrefix} from '../../util/configs'
import { LoadingIndicator } from '../../components/loader'

require('es6-promise').polyfill()
require('isomorphic-fetch')

function mapStateToProps(state) {
  return {
    errors: state.errorStatePg.errorspg,
    planFilters: state.viewsAppState.planFilters,
    currentRoute: state.viewsAppState.currentRoute,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearErrorPg,
        showDrawer,
        updatePlanFilters,
        updateNavigationWorkspaces,
        setDetailTabIndex,
        updateNavigationWorkspacesQuery,
      },
      dispatch
    ),
  }
}

class PlanList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      filterHeight: 57,
      filterLoading: true,
    }
  }

  componentDidMount() {
    this.paginationLoads = 1
    this.buildFilterState()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      planFilters: {type, query}
    } = this.props

    this.props.actions.updateNavigationWorkspacesQuery({
      type: type || 'PATIENT',   // see CA-2323 for why we use 'PATIENT'
      query: query,
    })
  }


  filtersRef = React.createRef()

  incrementPaginationLoads = () => {
    this.paginationLoads++
  }
  clearPaginationLoads = () => this.paginationLoads = 1

  buildFilterState = () => {
    const {plEnabled} = this.props
    const currentOE = getConfig('ca.oe.current.filter.double')
    const forecastOE = getConfig('ca.oe.forecast.filter.double')
    const planCfg = getDischargeLabelPrefix()
    const planLabel = `Past ${planCfg} DC`

    // A little hack for CA-2258 - yet another 1.2 item broken by 1.3
    let patientProps = {
      name: 'Patients',
      type: 'PATIENT',
      total: 0,
      sub: {
        title: '',
        census: {
          name: 'Census',
          type: 'census',
          total: 0,
        },
        reserved: {
          name: 'Reserved',
          type: 'reserved',
          total: 0,
        },
        observationOrIn: {
          name: 'Obs/In',
          type: 'observationOrIn',
          total: 0,
        },
        observationOrOut: {
          name: 'Obs/Out',
          type: 'observationOrOut',
          total: 0,
        },
        transfersOut: {
          name: 'Transfers Out',
          type: 'transfersOut',
          total: 0,
        },
        available: {
          name: 'Available',
          type: 'available',
          total: 0,
        },
      }
    }

    if(!plEnabled) {
      delete patientProps.sub.reserved
      delete patientProps.sub.transfersOut
    }

    this.setState({
      // see refreshPlanList method for how barriers are filled in
      // based on Barrier Categories with barriers
      // ideally every thing should work this way - just render what server
      // gives, but we'd need Server PlanListFilter query to supply names for
      // every item
      filterState: {
        barriers: {
          name: 'Barriers',
          type: 'BARRIER',
          total: 0,
          sub: {
            title: '',
          },
        },
        losReview: {
          name: 'LOS',
          type: 'LOS_REVIEW',
          total: 0,
          sub: {
            title: '',
            currentOE: {
              name: `Current O/E > ${currentOE}`,
              type: 'current',
            },
            forecastOE: {
              name: `Forecast O/E > ${forecastOE}`,
              type: 'forecast',
            },
            noTargetLengthOfStay: {
              name: 'No TLOS',
              type: 'no_tlos',
            },
            pastPlannedDischarge: {
              name: planLabel,
              type: 'passed_planned_discharge',
            },
            decisionDue: {
              name: 'Decision Due',
              type: 'decision_due',
            },
          },
        },
        progression: {
          name: 'Progress',
          type: 'PROGRESSION',
          total: 0,
          sub: {
            title: 'Progression Indicator',
            notSet: {
              name: 'Not Set',
              type: 'notSet',
              total: 0,
              chipColor: 'rgb(158, 158, 158)',
            },
            onTime: {
              name: 'On Time',
              type: 'onTime',
              total: 0,
              chipColor: 'rgb(76, 175, 80)',
            },
            atRisk: {
              name: 'At Risk',
              type: 'atRisk',
              total: 0,
              chipColor: 'rgb(255, 235, 59)',
            },
            highRisk: {
              name: 'High Risk',
              type: 'highRisk',
              total: 0,
              chipColor: 'rgb(244, 67, 54)',
            },
          },
        },
        admits: {
          name: 'Admits',
          type: 'ADMITS',
          total: 0,
          sub: {
            title: '',
            notSet: {
              name: 'Not Set',
              type: 'unknown',
              total: 0,
              chipColor: 'rgb(158, 158, 158)',
            },
            noAdmission: {
              name: 'No Admission',
              type: 'no',
              total: 0,
            },
            toBeAdmitted: {
              name: 'To Be Admitted',
              type: 'yes',
              total: 0,
            },
          },
        },
        discharges: {
          name: 'D/C',
          type: 'DISCHARGE',
          total: 0,
          sub: {
            title: 'Discharges Due Today',
            notSet: {
              name: 'Not Set',
              type: 'NotSet',
              total: 0,
              chipColor: 'rgb(158, 158, 158)',
            },
            anticipated: {
              name: getBedRequestStatusName('AnticipatedDischarge'),
              type: 'AnticipatedDischarge',
              total: 0,
              chipColor: 'rgb(154, 167, 206)',
            },
            dischargeOrderWithDelay:{
              name: getBedRequestStatusName('DischargeOrderWithDelay'),
              type: 'DischargeOrderWithDelay',
              total: 0,
              chipColor: 'rgb(195, 177, 225)',
            },
            pending: {
              name: getBedRequestStatusName('PendingDischarge'),
              type: 'PendingDischarge',
              total: 0,
              chipColor: 'rgb(209, 237, 204)',
            },
            ready: {
              name: getBedRequestStatusName('ReadyForDischarge'),
              type: 'ReadyForDischarge',
              total: 0,
              chipColor: 'rgb(160, 206, 133)',
            },
            recent: {
              name: 'Recent DC',
              type: 'Recent',
              total: 0,
              separator: true,
            },
          },
        },
        attention: {
          name: 'Attention',
          type: 'ATTENTION',
          total: 0,
          sub: {
            readmission: {
              name: 'Readmission',
              type: 'readmission',
              total: 0,
            },
            readmissionRisk: {
              name: 'Readmission Risk',
              type: 'readmissionRisk',
              total: 0,
            }
          }
        },

        patients: patientProps,
      }
    },
      // now that we've built filter state, do initial query to fill it
      () => {
        this.refreshPlanList(true)
      }
    )
  }

  handleFilterHeightChange = newHeight => {
    if (newHeight !== this.state.filterHeight) {
      this.setState({ filterHeight: newHeight })
    }
  }

  handleFilterChange = (type, subtype, query) => {
    const filterChanged =
      type !== this.props.planFilters.type ||
      subtype!== this.props.planFilters.subtype ||
      query !== this.props.planFilters.query

    if(filterChanged) {
      this.clearPaginationLoads()
    }
    this.props.actions.updatePlanFilters({
      type: type,
      subtype: subtype,
      query: query,
    })
    // CA-2450 - force refreshPlanList to do both queries if they clicked
    // on same filter again - that won't change props and cause listQuery to run
    this.refreshPlanList(filterChanged, type === 'VISIT' ? query : '')
  }

  refreshPlanList = (filterOnly, findText) => {

    const {
      planFilters: {type, query}
    } = this.props

    // CA-2803 when going back from PatientPlan to previous PlanList state
    // we need this shameless hack to restore the findText
    if(typeof findText === 'undefined' && type === 'VISIT') {
      findText = query
    }

    // Rerun the query for the QueryRenderer
    const reloadList = () => {
      if(filterOnly) return
      const index = getScrollToIndex(this.props.history)
      const numToLoad = getNumToLoad(index, this.paginationLoads)
      this.paginationLoads = getPaginationsFromIndex(index, this.paginationLoads)

      fetchQuery(environment, listQuery, {
        first: type === 'PATIENT' && query === 'available' ? 50 :
          numToLoad,
        after: null,
        type: type,
        query: query,
      })
    }


    if(!filterOnly) {
      reloadList()
    }

    // rerun query to get filter chip counts
    fetchQuery(environment, filterQuery, {}).then(values => {
      let dataClone = { ...this.state.filterState }
      const fetchData = values.viewer.workspace
      Object.keys(dataClone).forEach(key => {
        dataClone[key].total =
          fetchData[key] && fetchData[key].total ? fetchData[key].total : 0
        if (dataClone[key].sub && key !== 'barriers') {
          Object.keys(dataClone[key].sub).forEach(subkey => {
            if (subkey !== 'title' && fetchData[key]) {
              dataClone[key].sub[subkey].total = fetchData[key][subkey]
                ? fetchData[key][subkey]
                : 0
            }
          })
        } else if (key === 'barriers') {
          let barrierCountMap = fetchData.barriers.barriers.reduce(
            (map, value) => {
              let code = value.code
              map[code] = (map[code] || 0) + 1
              return map
            },
            {}
          )

          // CA-2265 support any Barrier Category
          let prop = ''
          // CA-2265 quick fix to get Description of Barrier codes which are currently
          // only available via apollo
          const barrierCodeName = {
            ADL: "ADL Needs",
            DCORDERS: "DC Orders",
            DCPlacement: "DC Placement",
            DCPrep: "DC Prep",
            CL: "Clinical",
          }

          for(prop in barrierCountMap) {
            if(barrierCountMap.hasOwnProperty(prop)) {
              dataClone.barriers.sub[prop] = {
                name: barrierCodeName[prop] || prop,
                type: prop,
                total: barrierCountMap[prop]
              }
            }
          }
        }
      })

      // CA-2530 - avoid random scroll back to top after mutation
      // CA-2803 - supply findText in query if type is 'Visit'
      this.filtersRef.current &&
        this.filtersRef.current.setFilterState(dataClone, findText)
    })
  }

  render() {
    const stillLoading = (
      <LoadingIndicator />
    )
    const {
      actions: { clearErrorPg },
      planFilters: {type, subtype, query},
      history,
    } = this.props

    const index = getScrollToIndex(history)
    const first = type === 'PATIENT' && query === 'available' ? 50 :
      getNumToLoad(index, this.paginationLoads)
    this.paginationLoads = getPaginationsFromIndex(index, this.paginationLoads)

    return (
      <React.Fragment>
        <PlanListFilter
          handleFilterChange={this.handleFilterChange}
          type={type || 'PATIENT'}
          subtype={subtype}
          query={query}
          handleFilterHeightChange={this.handleFilterHeightChange}
          ref={this.filtersRef}
        />
        <QueryRenderer
          environment={environment}
          query={listQuery}
          variables={{
            first: first,
            after: null,
            type: type,
            query: query,
          }}
          render={({ error, props }) => {
            if (error) {
              return <div>Error!</div>
            }
            if (!props) {
              return stillLoading
            }
            return (
              <PlanListPaginationContainer
                incrementPaginationLoads={this.incrementPaginationLoads}
                search={props}
                filterHeight={this.state.filterHeight}
                type={type}
                query={query}
                refreshPlanList={this.refreshPlanList}
                {...this.props}
              />
            )
          }}
        />
        <TransactionErrorDialog
          errors={this.props.errors}
          onRequestClose={clearErrorPg}
        />
      </React.Fragment>
    )
  }
}

PlanList.propTypes = {
  currentRoute: PropTypes.shape({
    viewType: PropTypes.oneOf(['Plan', 'Snap', 'PlanList']).isRequired,
  }),
  actions: PropTypes.shape({
    clearErrorPg: PropTypes.func,
    showDrawer: PropTypes.func,
    updatePlanFilters: PropTypes.func,
    updateNavigationWorkspaces: PropTypes.func,
    setDetailTabIndex: PropTypes.func.isRequired,
    updateNavigationWorkspacesQuery: PropTypes.func,
  }),
  planFilters: PropTypes.object,
  errors: PropTypes.array,
  updateProgressionIndicator: PropTypes.func,
  updateToBeAdmitted: PropTypes.func,
  updatePatientProblem: PropTypes.func,
  updateTargetLos: PropTypes.func,
  updateWorkingDischargeDisposition: PropTypes.func,
  updateBedRequestStatus: PropTypes.func,
  updateAnticipatedDischargeTime: PropTypes.func,

  pinBarrier: PropTypes.func,
  unpinBarrier: PropTypes.func,
  pinPlanAction: PropTypes.func,
  unpinPlanAction: PropTypes.func,
  pinMilestoneAction: PropTypes.func,
  unpinMilestoneAction: PropTypes.func,
  pinPlanNote: PropTypes.func,
  unpinPlanNote: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  client: PropTypes.shape({
    query: PropTypes.func.isRequired,
  }),
  patientVectorMap: PropTypes.object,
  plEnabled: PropTypes.bool,
}

const newProps = props => {
  const { applications } = props
  const plEnabled = applications.patientLogistics.enabled

  return { ...props,  plEnabled }
}

export const PlanListView = compose(
  withApplications,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  mapProps(props => newProps(props))
)(PlanList)

const listQuery = graphql`
  query PlanListQuery(
    $first: Int
    $after: String
    $type: SearchType
    $query: String
  ) {
    ...PlanListPaginationContainer_search
      @arguments(first: $first, after: $after, type: $type, query: $query)
  }
`

const filterQuery = graphql`
  query PlanListFilterQuery {
    viewer {
      workspace {
        admits {
          notSet
          noAdmission
          toBeAdmitted
          total
        }
        barriers {
          total
          barriers {
            code
            value
          }
        }
        discharges {
          anticipated
          dischargeOrderWithDelay
          notSet
          pending
          ready
          recent
          total
        }
        losReview {
          decisionDue
          currentOE
          forecastOE
          noTargetLengthOfStay
          pastPlannedDischarge
          total
        }
        patients {
          available
          census
          observationOrIn
          observationOrOut
          reserved
          transfersOut
          total
        }
        attention {
          readmission
          readmissionRisk
          total
        }
        progression {
          notSet
          onTime
          atRisk
          highRisk
          total
        }
      }
    }
  }
`
