import React from 'react'
import PropTypes from 'prop-types'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

const FilterSortDropdown = ({ onChange, sortOptions, selectedValue, admissionType }) => {
  const filteredSortOptions = admissionType !== 'inpatient' ? sortOptions.filter(option => option.type !== 'inpatient') : sortOptions

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ marginRight: '10px' }}>
        Sort:
      </div>
      <SelectField
        value={selectedValue}
        onChange={onChange}>
        {
          filteredSortOptions.map(option => (
            <MenuItem key={option.code} value={option.code} primaryText={option.value} />
          ))
        }
      </SelectField>
    </div>
  )
}

FilterSortDropdown.propTypes = {
  onChange: PropTypes.func,
  sortOptions: PropTypes.array,
  selectedValue: PropTypes.string,
  admissionType: PropTypes.string
}

export default FilterSortDropdown
