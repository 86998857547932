/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DepartmentType = "AdmissionSource" | "Unit" | "%future added value";
export type AssignBeds_AllFacilityBeds_QueryVariables = {||};
export type AssignBeds_AllFacilityBeds_QueryResponse = {|
  +facilities: $ReadOnlyArray<{|
    +name: string,
    +exchangeName: string,
    +ordinal: ?any,
    +departments: {|
      +nodes: ?$ReadOnlyArray<{|
        +type: DepartmentType,
        +exchangeName: string,
        +name: string,
        +beds: {|
          +nodes: $ReadOnlyArray<{|
            +exchangeName: string,
            +name: string,
            +status: string,
          |}>
        |},
      |}>
    |},
  |}>
|};
export type AssignBeds_AllFacilityBeds_Query = {|
  variables: AssignBeds_AllFacilityBeds_QueryVariables,
  response: AssignBeds_AllFacilityBeds_QueryResponse,
|};
*/


/*
query AssignBeds_AllFacilityBeds_Query {
  facilities {
    name
    exchangeName
    ordinal
    departments(first: 10000) {
      nodes {
        type
        exchangeName
        name
        beds(first: 10000) {
          nodes {
            exchangeName
            name
            status
            id
          }
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exchangeName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ordinal",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignBeds_AllFacilityBeds_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "facilities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "DepartmentConnection",
            "kind": "LinkedField",
            "name": "departments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Department",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v1/*: any*/),
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "BedConnection",
                    "kind": "LinkedField",
                    "name": "beds",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Bed",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v0/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "beds(first:10000)"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "departments(first:10000)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AssignBeds_AllFacilityBeds_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Facility",
        "kind": "LinkedField",
        "name": "facilities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "DepartmentConnection",
            "kind": "LinkedField",
            "name": "departments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Department",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v1/*: any*/),
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "BedConnection",
                    "kind": "LinkedField",
                    "name": "beds",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Bed",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v0/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "beds(first:10000)"
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "departments(first:10000)"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03ebe17508cdebd9acc3c939ac2be472",
    "id": null,
    "metadata": {},
    "name": "AssignBeds_AllFacilityBeds_Query",
    "operationKind": "query",
    "text": "query AssignBeds_AllFacilityBeds_Query {\n  facilities {\n    name\n    exchangeName\n    ordinal\n    departments(first: 10000) {\n      nodes {\n        type\n        exchangeName\n        name\n        beds(first: 10000) {\n          nodes {\n            exchangeName\n            name\n            status\n            id\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '799b70b76a113106bc765ba626540c52';

module.exports = node;
