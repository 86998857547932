import React from 'react'

import { Chip } from 'material-ui'

export class ClChip extends Chip {
  constructor(props) {
    super(props)
  }

  handleMouseUp = event => {
    this.setState({ clicked: false, hovered: false, focused: false })
    this.props.onMouseUp(event)
  }

  render() {
    return super.render()
  }
}
