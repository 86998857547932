import React from 'react'
import PropTypes from 'prop-types'
import './kpi.scss'

import Toggle from 'material-ui/Toggle'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

import { findColor } from '../../util/workspace'

export class HeaderSection extends React.Component {
  static propTypes = {
    caAppConfigs: PropTypes.array,
    facilityStats: PropTypes.array,
    currentFacility: PropTypes.object,
    workspaces: PropTypes.array,
    capped: PropTypes.bool,
    oeThresholdCaution: PropTypes.number,
    oeThresholdDanger: PropTypes.number,
    onFacilityChange: PropTypes.func,
    onCappedToggle: PropTypes.func,
  }

  render() {
    const {
      capped,
      facilityStats,
      currentFacility,
      onFacilityChange,
      onCappedToggle,
      oeThresholdCaution,
      oeThresholdDanger,
    } = this.props

    const multiFacilities = facilityStats.length > 1

    const stats = capped ? currentFacility.capped : currentFacility.uncapped

    const workingOEBackground = findColor(
      stats.workingOE,
      oeThresholdCaution,
      oeThresholdDanger
    )
    const mtdOEBackground = findColor(
      stats.mtdOE,
      oeThresholdCaution,
      oeThresholdDanger
    )
    const monthEndOEBackground = findColor(
      stats.monthEndOE,
      oeThresholdCaution,
      oeThresholdDanger
    )

    return (
      <div className="kpi-header-section">
        <div className="row row1">
          {facilityStats.length <= 1 && (
            <div className="col-md-3 col-sm-3 col-xs-3 facility-col">
              <span style={{ marginRight: 5 }}>Facility:</span>
              {facilityStats[0].name}
            </div>
          )}
          {multiFacilities && (
            <div className="col-md-3 col-sm-3 col-xs-3 facilities-col">
              <SelectField
                fullWidth
                style={{ marginTop: '-22px' }}
                floatingLabelText="Facility"
                value={currentFacility.name}
                onChange={onFacilityChange}>
                {facilityStats.map(facility => (
                  <MenuItem
                    key={facility.name}
                    value={facility.name}
                    primaryText={facility.name}
                  />
                ))}
              </SelectField>
            </div>
          )}
          <div
            style={{ top: multiFacilities ? 13 : 0 }}
            className="col-md-9 col-sm-9 col-xs-9 oe-column">
            <span style={{ marginRight: 5 }}>Working O/E</span>
            <span
              className="oe-box"
              style={{ background: workingOEBackground }}>
              {stats.workingOE}
            </span>
            <span style={{ marginRight: 5 }}>MTD O/E</span>
            <span className="oe-box" style={{ background: mtdOEBackground }}>
              {stats.mtdOE}
            </span>
            <span style={{ marginRight: 5 }}>Month End O/E</span>
            <span
              className="oe-box"
              style={{ background: monthEndOEBackground }}>
              {stats.monthEndOE}
            </span>
          </div>
        </div>
        <div className="row row2">
          <div className="col-md-3 col-sm-9 col-xs-9 capped-col">
            <span className="capped-label">Outliers</span>
            <span
              className="capped"
              onClick={() => onCappedToggle(null, !capped)}>
              <Toggle
                labelPosition="right"
                label={capped ? 'Capped' : 'Not Capped'}
                toggled={capped}
              />
            </span>
          </div>
        </div>
      </div>
    )
  }
}
