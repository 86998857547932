/*eslint-disable react/no-set-state */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'

import { white as textColor } from 'material-ui/styles/colors'
import { createStyleSheet } from 'jss-theme-reactor'
import { BedNavigator } from './BedNavigator'
import {
  calcNewFilter,
  findPersonnels,
  findPersonnelWorkspaces,
} from '../util/personnelWorkspaceFilter'

const styleSheet = createStyleSheet('PersonnelNavigator', theme => {
  const { typography, palette, transitions, shadows, c3 } = theme

  return {}
})

const styles = {
  filterTypeDropDownMenuStyle: {
    whiteSpace: 'nowrap',
    lineHeight: '36px',
    height: '36px',
    width: 150,
    verticalAlign: 'middle',
  },
  filterTypeDropDownMenuIconStyle: { right: 0, top: -8, fill: textColor },
  filterTypeDropDownMenuLabelStyle: {
    fontSize: 14,
    fontWeight: 500,
    height: '20px',
    lineHeight: '20px',
    top: 6,
    color: textColor,
  },

  personnelRoleDropDownMenuStyle: {
    whiteSpace: 'nowrap',
    lineHeight: '36px',
    height: '36px',
    width: 170,
    verticalAlign: 'middle',
  },
  personnelRoleDropDownMenuIconStyle: { right: 0, top: -8, fill: textColor },

  personnelRoleDropDownMenuLabelStyle: {
    fontSize: 14,
    fontWeight: 500,
    height: '20px',
    lineHeight: '20px',
    top: 6,
    color: textColor,
  },
}

export class PersonnelNavigation extends Component {
  static propTypes = {
    workspaces: PropTypes.arrayOf(
      PropTypes.shape({
        bed: PropTypes.object,
        visit: PropTypes.object,
        plan: PropTypes.object,
      })
    ),
    visitId: PropTypes.string.isRequired,
    bedId: PropTypes.string.isRequired,
    onSnapNavigation: PropTypes.func,
    snapFilter: PropTypes.shape({
      filterType: PropTypes.string,
      selectedPersonnelId: PropTypes.string || PropTypes.number,
    }),
    updateSnapFilter: PropTypes.func,
  }
  static contextTypes = {
    styleManager: PropTypes.object.isRequired,
  }

  findWorkspace = filter => {
    const { workspaces, visitId, bedId } = this.props

    const filteredWorkspaces = findPersonnelWorkspaces(
      filter.filterType,
      filter.selectedPersonnelId,
      workspaces
    )

    const currentWS = filteredWorkspaces.find(
      ws => ws.visit.id === visitId && ws.bed.exchangeName === bedId
    )

    const firstWorkspace =
      filteredWorkspaces.length > 0 && filteredWorkspaces[0]

    return currentWS || firstWorkspace
  }

  handleFilterTypeChange = (event, index, value) => {
    const {
      updateSnapFilter,
      onSnapNavigation,
      workspaces,
      snapFilter,
    } = this.props

    const { selectedPersonnelId } = snapFilter

    const newFilter = calcNewFilter(value, selectedPersonnelId, workspaces)

    updateSnapFilter(newFilter)

    const workspace = this.findWorkspace(newFilter)

    workspace &&
      onSnapNavigation(
        workspace.bed.exchangeName,
        workspace.plan.id,
        workspace.visit.id
      )
  }

  handlePersonnelChange = (event, index, value) => {
    const {
      updateSnapFilter,
      onSnapNavigation,
      workspaces,
      snapFilter,
    } = this.props

    const { filterType } = snapFilter

    const newFilter = calcNewFilter(filterType, value, workspaces)

    updateSnapFilter(newFilter)

    const workspace = this.findWorkspace(newFilter)

    workspace &&
      onSnapNavigation(
        workspace.bed.exchangeName,
        workspace.plan.id,
        workspace.visit.id
      )
  }

  personnelName(personnel) {
    if (personnel.firstName) {
      return `${personnel.lastName} ${personnel.firstName}`
    } else {
      return personnel.name
    }
  }

  renderPersonnelMenuItems(personnelRoles) {
    const items = []

    personnelRoles.forEach(personnel =>
      items.push(
        <MenuItem
          key={personnel.id}
          value={personnel.id}
          primaryText={this.personnelName(personnel)}
        />
      )
    )
    return items
  }

  render() {
    const {
      onSnapNavigation,
      workspaces,
      snapFilter,
      bedId,
      visitId,
    } = this.props

    const { filterType, selectedPersonnelId } = snapFilter

    const personnelRoles = findPersonnels(filterType, workspaces)

    const personnelWorkspaces = filterType !== 'all' && selectedPersonnelId ?
      findPersonnelWorkspaces(
      filterType,
      selectedPersonnelId,
      workspaces
    ) : workspaces

    return (
      <span>
        <DropDownMenu
          style={styles.filterTypeDropDownMenuStyle}
          iconStyle={styles.filterTypeDropDownMenuIconStyle}
          labelStyle={styles.filterTypeDropDownMenuLabelStyle}
          underlineStyle={{ display: 'none' }}
          value={filterType}
          onChange={this.handleFilterTypeChange}>
          <MenuItem value={'all'} primaryText={'All'} />
          <MenuItem value={'attending'} primaryText={'Attending'} />
          <MenuItem value={'nurse'} primaryText={'RN'} />
        </DropDownMenu>
        <DropDownMenu
          style={styles.personnelRoleDropDownMenuStyle}
          iconStyle={styles.personnelRoleDropDownMenuIconStyle}
          labelStyle={styles.personnelRoleDropDownMenuLabelStyle}
          underlineStyle={{ display: 'none' }}
          value={selectedPersonnelId}
          disabled={personnelRoles && personnelRoles.length === 0}
          onChange={this.handlePersonnelChange}>
          {this.renderPersonnelMenuItems(personnelRoles)}
        </DropDownMenu>
        <BedNavigator
          workspaces={personnelWorkspaces}
          bedId={bedId}
          visitId={visitId}
          textColor={textColor}
          onPlanNavigation={onSnapNavigation}
        />
      </span>
    )
  }
}
