import React from 'react'
import PropTypes from "prop-types"
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const SourceDetailConfigValueSelection = ({
  multiple,
  hidden,
  sourceDetailConfigValues,
  selectedValue,
  onChange,
  fieldLabel}) => {

  const handleSourceDetailChange = (event, newValue) => {
    if (multiple) {
      handleMultipleValuesSourceDetailChange(newValue)
    } else {
      handleSingleValueSourceDetailChange(newValue)
    }
  }

  const handleSingleValueSourceDetailChange = (newValue) => {
    onChange(newValue?.code || "")
  }

  const handleMultipleValuesSourceDetailChange = (newValue) => {
    const newValsSelected = newValue.map(val => ({
      ...val,
      isSelected: true
    }))
    onChange(newValsSelected)
  }

  const getOptionLabel = (option) => {
    let label = option?.value
    if (!option?.active) {
      label += ' - Deactivated'
    }
    return label
  }

  const getOptionStyle = (option) => {
    return (!option?.active) ? { color: 'red' } : {}
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={sourceDetailConfigValues}
      getOptionLabel={(option) => getOptionLabel(option)}
      getOptionSelected={(option, value) => option?.code === value?.code}
      value={selectedValue}
      hidden={hidden}
      onChange={handleSourceDetailChange}
      renderOption={(option) => (
        <React.Fragment><span style={getOptionStyle(option)}>{getOptionLabel(option)}</span></React.Fragment>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} style={getOptionStyle(option)} label={getOptionLabel(option)} />
        ))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={fieldLabel}
        />
      )}
    />
  )
}

SourceDetailConfigValueSelection.propTypes = {
  multiple: PropTypes.bool,
  hidden: PropTypes.bool,
  sourceDetailConfigValues: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
      ordinal: PropTypes.number,
      active: PropTypes.bool,
      isSelected: PropTypes.bool
    })
  ).isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.string,
      ordinal: PropTypes.number,
      active: PropTypes.bool,
      isSelected: PropTypes.bool
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        value: PropTypes.string,
        ordinal: PropTypes.number,
        active: PropTypes.bool,
        isSelected: PropTypes.bool
      })
    )
  ]),
  onChange: PropTypes.func.isRequired,
  fieldLabel: PropTypes.string.isRequired
}

export default SourceDetailConfigValueSelection
