/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BarrierSection_barrierEscalationRoleData_QueryVariables = {||};
export type BarrierSection_barrierEscalationRoleData_QueryResponse = {|
  +codeTables: ?$ReadOnlyArray<?{|
    +id: string,
    +code: string,
    +value: string,
    +active: boolean,
  |}>
|};
export type BarrierSection_barrierEscalationRoleData_Query = {|
  variables: BarrierSection_barrierEscalationRoleData_QueryVariables,
  response: BarrierSection_barrierEscalationRoleData_QueryResponse,
|};
*/


/*
query BarrierSection_barrierEscalationRoleData_Query {
  codeTables(codeTableType: BarrierEscalationRole) {
    id
    code
    value
    active
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "codeTableType",
        "value": "BarrierEscalationRole"
      }
    ],
    "concreteType": "CodeTable",
    "kind": "LinkedField",
    "name": "codeTables",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
      }
    ],
    "storageKey": "codeTables(codeTableType:\"BarrierEscalationRole\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BarrierSection_barrierEscalationRoleData_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BarrierSection_barrierEscalationRoleData_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6df6d6f26f0fde4d4ec18cb69f673f8f",
    "id": null,
    "metadata": {},
    "name": "BarrierSection_barrierEscalationRoleData_Query",
    "operationKind": "query",
    "text": "query BarrierSection_barrierEscalationRoleData_Query {\n  codeTables(codeTableType: BarrierEscalationRole) {\n    id\n    code\n    value\n    active\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b40664ba8314c3c13f00de0df141b58f';

module.exports = node;
